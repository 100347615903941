import store from '../store';
import { getConfig } from '../Reducers/ConfigReducer';

export const mapReportStatsSourceName = (reportsData = {}) => {
  const state = store.getState();
  const userConfig = getConfig(state);
  const rows = (reportsData.Rows || []).map(item => {
    if (item.Source === 'Active' && userConfig?.ActiveChannelAlias) {
      const modifiedSource = userConfig?.ActiveChannelAlias;
      return { ...item, Source: modifiedSource };
    }
    return item;
  });
  return { ...reportsData, Rows: rows };
};

export const modifyDisplayName = (data = []) => {
  const state = store.getState();
  const userConfig = getConfig(state);
  return data.map(item => {
    if (item.DisplayName.includes('Active') && userConfig?.ActiveChannelAlias) {
      const modifiedName = userConfig?.ActiveChannelAlias;
      const modifiedDisplayName = item.DisplayName.replace('Active', modifiedName);
      return { ...item, DisplayName: modifiedDisplayName };
    }
    return item;
  });
};
