import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon, Avatar, Popover, Divider, Badge } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import qs from 'query-string';

import ConnectNotification from '../../Components/ConnectNotification/ConnectNotification';
import SettingsMenu from '../../Components/SettingsMenu/SettingsMenu';
import { getConfiguration } from '../../Components/Collaborators/Collaborators';
import { getPlanDetailsByProductVariantId, getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';

import signOutAction from '../../Actions/AuthActions';
import * as PayOrderActions from '../../Actions/AryaPayOrderActions';
import * as NotificationActions from '../../Actions/NotificationActions';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import * as AryaNotifyActionCreator from '../../Actions/ActionCreators/AryaNotifyActionCreator';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as JobActions from '../../Actions/JobActions';
import fetchJobSourcingStatsAction from '../../Actions/SourcingStatsActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as ClientActions from '../../Actions/ClientActions';
import {
  getConfig,
  getWhiteLabelInformation,
  getFetchUserConfigApiStatus,
  getNavbarRenderFlag,
} from '../../Reducers/ConfigReducer';
import { setupSocketConnections, closeSocketConnection } from '../../Events/SocketConnectionManager';
import Config from '../../Config/Config';
import {
  getConnectNotificationsCount,
  getPersonsById,
  getConversationPersonMapping,
  getNewNotificationsCount,
} from '../../Reducers/ConnectReducer';
import {
  getNotifications,
  getUnviewedNotificationsCount,
  getNotificationsTotalCount,
  getNewNotificationStatus,
} from '../../Reducers/AryaNotifyReducer';
import { getCurrentUser, getLoadCurrentUserApiStatus } from '../../Reducers/UserSessionReducer';
import { getJobGuidToIdMapping, getJobsById, getJobListTabName } from '../../Reducers/JobReducer';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { SystemIcon, ConnectNotificationIcon } from '../../Icons/AryaIcons';
import OfferBanner from './OfferBanner';
import './Navbar.scss';
import messages from './messages';
import AryaNotifications from '../../Components/AryaNotifications/AryaNotifications';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getTotalJobCount, getSampleCandidatesDisplayFlag, getNonDraftJobCount } from '../../Reducers/JobStatusReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getUserType, isPulseUser } from '../../Utils/ConfigUtils';
import { getAccountTypes as _fetchAccountTypes } from '../../Actions/OrganizationActions';
import { getMaskingConfig as _fetchMaskingConfig } from '../../Actions/ConfigActions';
import { getAccountType } from '../../Utils/OrganizationUtils';
import { getAccountTypes } from '../../Reducers/OrganizationsReducer';
import DowntimeAnnouncement from '../DowntimeAnnouncement/DowntimeAnnouncement';

function getActiveTab(location) {
  const { pathname, search } = location;
  const { isAssigned } = qs.parse(search);
  if (pathname === '/jobs' && isAssigned === 'false') {
    return 'unassignedJobs';
  }
  if (pathname.includes('/jobs') || pathname === '/') {
    return 'jobs';
  }
  if (
    pathname === '/connect' ||
    pathname === '/connect?tab=templates' ||
    pathname === '/connect?tab=conversations' ||
    pathname === '/connect?tab=workflows'
  ) {
    return 'connect';
  }

  if (pathname === '/calendar') {
    return 'calendar';
  }
  if (pathname === '/reports') {
    return 'reports';
  }
  if (pathname === '/research') {
    return 'research';
  }
  if (pathname === '/help') {
    return 'help';
  }

  if (pathname === '/buy-credit') {
    return 'buyCredits';
  }
  if (pathname.includes('/segments')) {
    return 'segments';
  }
  return null;
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  userConfig: getConfig(state) || {},
  notifications: getNotifications(state),
  role: getConfig(state) ? getConfig(state).Role : 'Recruiter',
  connectNotificationsCount: getConnectNotificationsCount(state),
  personsById: getPersonsById(state),
  conversationPersonMapping: getConversationPersonMapping(state),
  newConnectNotificationsCount: getNewNotificationsCount(state),
  connectNotifications: getNotifications(state, 'Connect'),
  connectNotificationsTotalCount: getNotificationsTotalCount(state, 'Connect'),
  connectNotificationsUnviewedCount: getUnviewedNotificationsCount(state, 'Connect'),
  newConnectNotificationStatus: getNewNotificationStatus(state, 'Connect'),
  newAryaNotificationStatus: getNewNotificationStatus(state, 'Arya'),
  aryaNotifications: getNotifications(state, 'Arya'),
  aryaNotificationsTotalCount: getNotificationsTotalCount(state, 'Arya'),
  aryaNotificationsUnviewedCount: getUnviewedNotificationsCount(state, 'Arya'),
  jobGuidToIdMapping: getJobGuidToIdMapping(state),
  jobsById: getJobsById(state),
  userByGuId: getUsersByGuId(state),
  featureToggleList: getFeatureToggleList(state),
  fetchUserConfigApiStatus: getFetchUserConfigApiStatus(state),
  fetchLoadCurrentUserApiStatus: getLoadCurrentUserApiStatus(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  totalJobCount: getTotalJobCount(state),
  jobListStatus: getApiStatus(state, 'jobListStatus'),
  jobCountByStatusesApiStatus: getApiStatus(state, 'jobCountByStatusesApiStatus'),
  jobListTabName: getJobListTabName(state),
  accountTypes: getAccountTypes(state),
  showSampleCandidates: getSampleCandidatesDisplayFlag(state),
  planDetailsByProductVariantId: getPlanDetailsByProductVariantId(state),
  planDetailsByProductVariantIdApiStatus: getApiStatus(state, 'fetchPlanDetailsByProductVariantIdApiStatus'),
  productVariantsById: getProductVariantsById(state),
  nonDraftJobCount: getNonDraftJobCount(state),
  navbarRenderFlag: getNavbarRenderFlag(state),
});

const mapDispatchToProps = {
  setMessagePopupVisibility: NotificationActions.setMessagePopupVisibility,
  signOut: signOutAction,
  getPersonDetails: ConnectActions.getPersonDetails,
  updateConnectViewedTime: ConnectActions.updateConnectViewedTime,
  setHelpTabTimesamp: ConnectActions.setHelpTabTimesamp,
  updateNewNotificationStatus: AryaNotifyActions.updateNewNotificationStatus,
  createSocketConnection: setupSocketConnections,
  fetchConnectNofitications: AryaNotifyActions.fetchConnectNofitications,
  fetchAryaNotifications: AryaNotifyActions.fetchAryaNotifications,
  markConnectNotificationsAsViewed: AryaNotifyActions.markConnectNotificationsAsViewed,
  markAryaNotificationsAsViewed: AryaNotifyActions.markAryaNotificationsAsViewed,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
  markSingleNotificationAsRead: AryaNotifyActions.markSingleNotificationAsRead,
  saveRecentReadNotificationInfo: AryaNotifyActions.saveRecentReadNotificationInfo,
  toggleAddClientDrawer: ClientActions.toggleAddClientDrawer,
  setJobListPageNumber: JobActions.setJobListPageNumber,
  setDefaultHomePageRenderFlag: JobActions.setDefaultHomePageRenderFlag,
  setCurrentJobStatusId: JobStatusActions.setCurrentJobStatusId,
  setJobListSearchTerm: JobActions.setJobListSearchTerm,
  updateFilterTags: JobActions.updateFilterTags,
  setJobListTabName: JobActions.setJobListTabName,
  fetchJobDetails: JobActions.fetchJobDetails,
  fetchJobSourcingStats: fetchJobSourcingStatsAction,
  fetchOrgActivatedJobsCount: JobActions.fetchOrgActivatedJobsCount,
  setNotificationFetchEnabled: AryaNotifyActionCreator.setNotificationFetchEnabled,
  fetchAccountTypes: _fetchAccountTypes,
  fetchMaskingConfig: _fetchMaskingConfig,
  resetOrderDetails: PayOrderActions.resetOrderDetails,
  resetOrderDetailsWithoutTax: PayOrderActions.resetOrderDetailsWithoutTax,
  fetchPlanDetailsByProductVariantId: PayOrderActions.fetchPlanDetailsByProductVariantId,
  setJobCandidateTabActiveSourceName: JobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setJobCandidateActiveTab: JobCandidatesTabActions.setJobCandidateTabActiveTab,
};

class NavbarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      visiblePopover: 'none',
    };
    const { createSocketConnection } = props;
    this.onClickConnectNotificationItem = this.onClickConnectNotificationItem.bind(this);
    this.onClickSystemNotificationItem = this.onClickSystemNotificationItem.bind(this);
    this.stopImpersonation = this.stopImpersonation.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.signout = this.signout.bind(this);
    document.addEventListener('visibilitychange', createSocketConnection, false);
    document.addEventListener('focus', createSocketConnection, false);
    document.addEventListener('blur', createSocketConnection, false);
  }

  static getDerivedStateFromProps(props) {
    const { newConnectNotificationStatus, newAryaNotificationStatus, updateNewNotificationStatus, currentUser } = props;

    // let newNotificationAudio;
    if (
      newConnectNotificationStatus &&
      newConnectNotificationStatus.showNotification &&
      currentUser.sub !== newConnectNotificationStatus.senderId
    ) {
      // TODO: Add mute option, then we can enable tone for notifications
      // newNotificationAudio = new Audio(`${process.env.PUBLIC_URL}/static/Audio/new-notification.wav`);
      // newNotificationAudio.play();
      setTimeout(() => updateNewNotificationStatus(false, 'Connect', null), 2000);
    }

    if (
      newAryaNotificationStatus &&
      newAryaNotificationStatus.showNotification &&
      currentUser.sub !== newAryaNotificationStatus.senderId
    ) {
      // TODO: Add mute option, then we can enable tone for notifications
      // newNotificationAudio = new Audio(`${process.env.PUBLIC_URL}/static/Audio/new-notification.wav`);
      // newNotificationAudio.play();
      setTimeout(() => updateNewNotificationStatus(false, 'Arya', null), 2000);
    }

    return {
      activeTab: getActiveTab(props.location),
    };
  }

  componentDidMount() {
    const {
      fetchConnectNofitications,
      userConfig,
      fetchAryaNotifications,
      createSocketConnection,
      location,
      setJobListTabName,
      setNotificationFetchEnabled,
      fetchAccountTypes,
      fetchMaskingConfig,
      fetchPlanDetailsByProductVariantId,
    } = this.props;
    const { pathname, search } = location;
    const { isAssigned } = qs.parse(search);
    setNotificationFetchEnabled(true);
    fetchAccountTypes();
    fetchMaskingConfig();
    fetchPlanDetailsByProductVariantId();
    if (!userConfig.IsAryaUser) {
      createSocketConnection();
      fetchConnectNofitications();
      fetchAryaNotifications();
    }
    if (pathname === '/jobs' && isAssigned === 'false') {
      setJobListTabName('unAssigned');
    }
  }

  onClickConnectNotificationItem(conversationId, personId, notificationId, messageType, personNumber, jobId, payload) {
    const {
      setMessagePopupVisibility,
      getPersonDetails,
      history,
      markSingleNotificationAsRead,
      saveRecentReadNotificationInfo,
      markNotificationAsRead,
    } = this.props;
    if (messageType === 'Email' && payload) {
      history.push('/connect');
      saveRecentReadNotificationInfo('Connect', { notificationId, ...payload });
      markNotificationAsRead({
        Source: 'Connect',
        Types: [messageType],
        RefId: conversationId,
      });
    } else if (messageType === 'ConsentStatus') {
      history.push(`/jobs/${jobId}/candidates?status=connected`);
      markSingleNotificationAsRead(notificationId, {
        Source: 'Connect',
        Types: messageType,
      });
    } else {
      getPersonDetails(personId, conversationId);
      setMessagePopupVisibility(conversationId, personId, true, messageType, personNumber);
    }
    this.setState({
      visiblePopover: 'none',
    });
  }

  async onClickSystemNotificationItem(notification) {
    const {
      history,
      markNotificationAsRead,
      markSingleNotificationAsRead,
      location,
      fetchJobDetails,
      fetchJobSourcingStats,
      featureToggleList,
    } = this.props;
    const isAdvanceSearchV2Enable = featureToggleList?.AdvanceSearchV2?.IsEnabled;
    const notificationPayload = notification.Payload;
    const notificationType = notification.Type;
    const { pathname: currentPath } = location;
    const pathToCandidateList = isAdvanceSearchV2Enable
      ? `/jobs/${notificationPayload.JobId}/candidates?status=ranked`
      : `/jobs/${notificationPayload.JobId}/candidates`;
    const currentLocation = `/segments/${notificationPayload.JobId}/candidates?status=sourced`;
    if (
      notificationType === 'BulkContactFetched' ||
      notificationType === 'Sourcing' ||
      notificationType === 'CandidatesPublished' ||
      notificationType === 'OrderPlaced'
    ) {
      if (pathToCandidateList !== currentPath) {
        if (notificationType === 'CandidatesPublished') {
          await fetchJobDetails(notificationPayload.JobId);
          fetchJobSourcingStats([notificationPayload.JobId]);
        }
        history.push(pathToCandidateList);
      } else history.go(0);
    }
    if (notificationType === 'FindingCandidates') {
      history.push(`/jobs/${notificationPayload.JobId}`);
    }
    if (notificationType === 'JobShare') {
      history.push(`/jobs/${notificationPayload.JobId}`);
    }
    if (notificationType === 'PortalCredentialFailed') {
      history.push(`/settings/app`);
    }
    if (notificationType === 'SegmentShare') {
      if (currentLocation !== currentPath) {
        await fetchJobDetails(notificationPayload.JobId);
        fetchJobSourcingStats([notificationPayload.JobId]);
        history.push(`/segments/${notificationPayload.JobId}/candidates?status=shortlisted`);
      } else history.go(0);
    }

    if (notification.RefId) {
      markNotificationAsRead({
        Source: 'Arya',
        RefId: notification.RefId,
        Types: [notificationType],
      });
    } else {
      markSingleNotificationAsRead(notification.Id, {
        Source: 'Arya',
        Types: [notificationType],
      });
    }

    this.setState({
      visiblePopover: 'none',
    });
  }

  goToHome() {
    const {
      history,
      setJobListPageNumber,
      setDefaultHomePageRenderFlag,
      setCurrentJobStatusId,
      setJobListSearchTerm,
      updateFilterTags,
      jobListTabName,
    } = this.props;
    const pathName = window.location.pathname;
    const jobListRoute = jobListTabName === 'unAssigned' ? '/jobs?isAssigned=false' : '/jobs';
    if (pathName === '/' || pathName === '/jobs') {
      setJobListPageNumber(1);
      setCurrentJobStatusId(undefined);
      setJobListSearchTerm('', 'job');
      setDefaultHomePageRenderFlag(true);
      updateFilterTags([]);
      history.push('/jobs');
    } else history.push(jobListRoute);
  }

  handlePopoversVisibility(visible, popoverName) {
    const { markConnectNotificationsAsViewed, markAryaNotificationsAsViewed } = this.props;
    if (visible) {
      this.setState({
        visiblePopover: popoverName,
      });
      if (popoverName === 'ConnectNotification') markConnectNotificationsAsViewed();
      if (popoverName === 'SystemNotification') markAryaNotificationsAsViewed();
    } else {
      this.setState({
        visiblePopover: 'none',
      });
    }
  }

  signout() {
    const { signOut } = this.props;
    closeSocketConnection();
    signOut();
  }

  // eslint-disable-next-line class-methods-use-this
  stopImpersonation() {
    localStorage.removeItem('ManagementUser');
    localStorage.removeItem('Impersonate');
    localStorage.removeItem('ImpersonatedUser');
    window.location.reload();
  }

  getProductLogo = () => {
    const userType = getUserType();
    return userType === 'pulse' ? (
      <img
        src={`${process.env.PUBLIC_URL}/static/Images/pulse_text_white_with_arya.svg`}
        alt="Arya Pulse"
        style={{ height: '28px' }}
      />
    ) : (
      <img src={`${process.env.PUBLIC_URL}/static/Images/aryalogo.svg`} alt="Arya" />
    );
  };

  goToJob = () => {
    const { setJobListPageNumber, setJobListTabName } = this.props;
    const { activeTab } = this.state;
    setJobListTabName('assigned');
    if (activeTab !== 'jobs') setJobListPageNumber(1);
  };

  goToSegment = () => {
    const { setJobListPageNumber } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== 'segments') setJobListPageNumber(1);
  };

  handleHelpTabClick = () => {
    const { setHelpTabTimesamp } = this.props;
    setHelpTabTimesamp(new Date());
  };

  render() {
    const { activeTab, visiblePopover } = this.state;
    const { currentUser, toggleAddClientDrawer, setJobListTabName } = this.props;
    let email;
    let firstName;
    let lastName;
    if (currentUser) {
      // eslint-disable-next-line prefer-destructuring
      email = currentUser.email;
      firstName = currentUser.given_name;
      lastName = currentUser.family_name;
    }
    const {
      aryaNotifications,
      aryaNotificationsTotalCount,
      aryaNotificationsUnviewedCount,
      fetchAryaNotifications,
      // signOut,
      role,
      history,
      newConnectNotificationStatus,
      newAryaNotificationStatus,
      fetchConnectNofitications,
      connectNotifications,
      connectNotificationsTotalCount,
      connectNotificationsUnviewedCount,
      jobGuidToIdMapping,
      jobsById,
      userConfig,
      match,
      userByGuId,
      featureToggleList,
      whiteLabelInfo,
      totalJobCount,
      jobListStatus,
      jobCountByStatusesApiStatus,
      fetchOrgActivatedJobsCount,
      fetchUserConfigApiStatus,
      fetchLoadCurrentUserApiStatus,
      accountTypes,
      navbarRenderFlag,
      setJobCandidateTabActiveSourceName,
      setJobCandidateActiveTab,
    } = this.props;
    const IsPulsePromotionalOffer = featureToggleList.PulsePromotionalOffer.IsEnabled;
    const IsConnectEnabled = featureToggleList.Connect.IsEnabled;
    const IsCalendarEnabled = featureToggleList.Calendar.IsEnabled;
    const IsNotificationEnabled = featureToggleList.Notification.IsEnabled;
    const isJobListEnabled = featureToggleList.JobList.IsEnabled;
    const isCandidateCRMEnabled = featureToggleList.CandidateCRM.IsEnabled;
    const isKnowledgeBaseEnabled = featureToggleList.KnowledgeBase.IsEnabled;
    const isPulse = isPulseUser();
    const IsProfileEnabled = featureToggleList.Profile.IsEnabled;
    const isResearchTabEnabled =
      featureToggleList.SalesDashboardReports.IsEnabled || featureToggleList.CareerProjectionReport.IsEnabled;
    const isReportPortalEnabled = featureToggleList.ReportingPortal.IsEnabled;
    const userType = getUserType();
    if (match.url === '/unauthorized' || !currentUser || !navbarRenderFlag) {
      return null;
    }
    const accountTypeId = userConfig.AccountTypeId;
    const accountType = getAccountType(accountTypeId, accountTypes);

    if (
      ['SUCCESS', 'FAILED'].includes(fetchUserConfigApiStatus) &&
      ['SUCCESS', 'FAILED'].includes(fetchLoadCurrentUserApiStatus) &&
      ((userType === 'non-pulse' && ['aryapulse', 'whitegloveservice'].includes(accountType?.toLowerCase())) ||
        (userType === 'pulse' && !['aryapulse', 'whitegloveservice'].includes(accountType?.toLowerCase())) ||
        (userConfig.IsAryaUser === false && currentUser.profile !== 'Pulse'))
    ) {
      history.push('/unauthorized');
      return null;
    }

    const showConnectNotification =
      newConnectNotificationStatus &&
      newConnectNotificationStatus.showNotification &&
      currentUser.sub !== newConnectNotificationStatus.senderId;

    const showAryaNotification =
      newAryaNotificationStatus &&
      newAryaNotificationStatus.showNotification &&
      currentUser.sub !== newAryaNotificationStatus.senderId;

    const connectNotificationComponent = connectNotifications.length ? (
      <div className="connect-notifications">
        <InfiniteScroll
          useWindow={false}
          initialLoad={false}
          loadMore={page => fetchConnectNofitications(page * 10, 10)}
          hasMore={connectNotifications.length < connectNotificationsTotalCount}
          loader={
            <div key="loader" style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
              <Icon style={{ fontSize: 20 }} type="loading" spin />
            </div>
          }
          threshold={220}
        >
          {connectNotifications.map(connectNotification => {
            const jobGuid = _.get(connectNotification, ['Payload', 'RefId'], undefined);
            return (
              <ConnectNotification
                notification={connectNotification}
                key={connectNotification.Id}
                onClick={this.onClickConnectNotificationItem}
                currentUser={currentUser}
                jobDetails={jobGuid ? _.get(jobsById, [jobGuidToIdMapping[jobGuid]], {}) : {}}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    ) : (
      <div className="connect-notification-empty">
        <div className="connect-notification-empty-icon-wrapper">
          <ConnectNotificationIcon className="connect-notification-icon" />
        </div>
        <div className="connect-notification-empty-title">No connect notification found</div>
        <div className="connect-notification-empty-description">
          All the replies from the candidates will appear here
        </div>
      </div>
    );

    const connectNotificationFooter = connectNotifications.length ? (
      <div>
        <Divider style={{ margin: 0 }} />
        <div className="go-to-connect">
          <Link to="/connect" style={{ color: '#7F8FA5', fontSize: '14px', fontWeight: 'bold' }}>
            Go To Connect
          </Link>
        </div>
      </div>
    ) : null;

    const popoverParams = {
      trigger: 'click',
      placement: 'bottomRight',
      overlayClassName: 'fixed-popover-menu',
    };
    const configuration = getConfiguration([firstName, lastName].join(' '));
    const managementUser = localStorage.getItem('ManagementUser');
    const impersonate = localStorage.getItem('Impersonate');
    let impersonatedUser = localStorage.getItem('ImpersonatedUser');
    impersonatedUser = (impersonatedUser && JSON.parse(impersonatedUser)) || {};
    const client = localStorage.getItem('Client');

    return (
      <div id="navbar-wrapper" style={{ backgroundColor: `${_.get(whiteLabelInfo, ['NavbarColor'], '#1f2730')}` }}>
        <div className="navbar">
          <div className="logo" role="presentation" onClick={isJobListEnabled ? this.goToHome : () => {}}>
            {client ? (
              <img src={`${Config.urls.getWhiteLabelResourceUrl(client)}/logo.png`} alt="WhiteLabel" />
            ) : (
              this.getProductLogo()
            )}
          </div>

          {role === 'Service Rep' ? (
            <Link to="/jobs?isAssigned=false" className="navbar-jobs-tab">
              <div
                className={`navbar-menu-tab-item ${activeTab === 'unassignedJobs' && 'active'}`}
                role="presentation"
                onClick={() => setJobListTabName('unAssigned')}
              >
                <FormattedMessage {...messages.unassignedJobs} />
                {activeTab === 'unassignedJobs' &&
                jobListStatus !== 'INPROGRESS' &&
                jobCountByStatusesApiStatus !== 'INPROGRESS'
                  ? `( ${totalJobCount} )`
                  : null}
              </div>
            </Link>
          ) : null}

          {isJobListEnabled ? (
            <Link
              to="/jobs"
              role="presentation"
              className={role !== 'Service Rep' ? 'navbar-jobs-tab' : null}
              onClick={this.goToJob}
            >
              <div className={`navbar-menu-tab-item ${activeTab === 'jobs' && 'active'}`} role="presentation">
                <FormattedMessage {...messages.jobs} />
              </div>
            </Link>
          ) : null}
          {isCandidateCRMEnabled && !isPulse ? (
            <Link to="/segments" role="presentation" onClick={this.goToSegment}>
              <div className={`navbar-menu-tab-item ${activeTab === 'segments' && 'active'}`} role="presentation">
                <FormattedMessage {...messages.segments} />
              </div>
            </Link>
          ) : null}
          {IsConnectEnabled ? (
            <Link to="/connect">
              <div className={`navbar-menu-tab-item ${activeTab === 'connect' && 'active'}`} role="presentation">
                <FormattedMessage {...messages.connect} />
              </div>
            </Link>
          ) : null}
          {IsCalendarEnabled ? (
            <Link to="/calendar">
              <div className={`navbar-menu-tab-item ${activeTab === 'calendar' && 'active'}`} role="presentation">
                <FormattedMessage {...messages.calendar} />
              </div>
            </Link>
          ) : null}
          {userType !== 'pulse' && isResearchTabEnabled ? (
            <Link to="/research">
              <div className={`navbar-menu-tab-item ${activeTab === 'research' && 'active'}`} role="presentation">
                <FormattedMessage {...messages.research} />
              </div>
            </Link>
          ) : null}
          {userType !== 'pulse' && isReportPortalEnabled ? (
            <Link to="/reports">
              <div className={`navbar-menu-tab-item ${activeTab === 'reports' && 'active'}`} role="presentation">
                <FormattedMessage {...messages.reports} />
              </div>
            </Link>
          ) : null}
          {userType !== 'pulse' && isKnowledgeBaseEnabled ? (
            <Link to="/help">
              <div
                onClick={this.handleHelpTabClick}
                className={`navbar-menu-tab-item last ${activeTab === 'help' && 'active'}`}
                role="presentation"
              >
                <FormattedMessage {...messages.help} />
              </div>
            </Link>
          ) : null}
          {IsConnectEnabled ? (
            <Popover
              title="Connect Notification"
              visible={visiblePopover === 'ConnectNotification'}
              content={
                <div>
                  {connectNotificationComponent}
                  {connectNotificationFooter}
                </div>
              }
              {...popoverParams}
              onVisibleChange={visible => this.handlePopoversVisibility(visible, 'ConnectNotification')}
            >
              <div>
                <span className="navbar-menu-item-icon">
                  <span className="badged-icon">
                    <Badge count={connectNotificationsUnviewedCount} className="connect">
                      <ConnectNotificationIcon
                        style={{ cursor: 'pointer' }}
                        className={`connect-notification-icon ${showConnectNotification ? 'wiggle' : ''}`}
                      />
                    </Badge>
                  </span>
                </span>
              </div>
            </Popover>
          ) : null}
          {IsNotificationEnabled ? (
            <Popover
              title={<h3 className="system-notifcation-popup-title">System Notifications</h3>}
              visible={visiblePopover === 'SystemNotification'}
              placement="bottom"
              content={
                aryaNotificationsTotalCount ? (
                  <AryaNotifications
                    aryaNotifications={aryaNotifications}
                    aryaNotificationsTotalCount={aryaNotificationsTotalCount}
                    fetchAryaNotifications={fetchAryaNotifications}
                    onClick={this.onClickSystemNotificationItem}
                    userByGuId={userByGuId}
                    aryaVersion={_.get(userConfig, 'SubscriptionType')}
                    whiteLabelInfo={whiteLabelInfo}
                    setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
                    setJobCandidateActiveTab={setJobCandidateActiveTab}
                  />
                ) : (
                  <div className="system-notification-empty">
                    <div className="system-notification-empty-icon-wrapper">
                      <SystemIcon className="arya-notification-icon" />
                    </div>
                    <div className="system-notification-empty-title">No system notification found</div>
                    <div className="system-notification-empty-description">
                      All system related notifications will appear here
                    </div>
                  </div>
                )
              }
              {...popoverParams}
              onVisibleChange={visible => this.handlePopoversVisibility(visible, 'SystemNotification')}
            >
              <div>
                <span className="navbar-menu-item-icon">
                  <span className="badged-icon">
                    <Badge count={aryaNotificationsUnviewedCount} className="arya">
                      <SystemIcon
                        style={{ cursor: 'pointer' }}
                        className={`arya-notification-icon ${showAryaNotification ? 'wiggle' : ''}`}
                      />
                    </Badge>
                  </span>
                </span>
              </div>
            </Popover>
          ) : null}

          {IsProfileEnabled ? (
            <Popover
              content={
                <SettingsMenu
                  email={email}
                  firstName={firstName}
                  lastName={lastName}
                  role={role}
                  signOut={this.signout}
                  history={history}
                  hidePopover={() => this.handlePopoversVisibility(false, 'SettingsMenu')}
                  toggleClientAddDrawer={toggleAddClientDrawer}
                  featureToggleList={featureToggleList}
                  visible={visiblePopover === 'SettingsMenu'}
                  fetchOrgActivatedJobsCount={fetchOrgActivatedJobsCount}
                  userConfig={userConfig}
                />
              }
              {...popoverParams}
              visible={visiblePopover === 'SettingsMenu'}
              onVisibleChange={visible => this.handlePopoversVisibility(visible, 'SettingsMenu')}
            >
              <div>
                <span className="navbar-menu-item-icon last">
                  <Avatar style={{ color: '#ffffff', backgroundColor: configuration.color }}>
                    {configuration.text}{' '}
                  </Avatar>
                  <Icon type="down" className="arrow-icon" />
                </span>
              </div>
            </Popover>
          ) : null}
        </div>
        {managementUser && impersonate ? (
          <div className="impersonate-banner">
            <span>{`You are impersonating ${impersonatedUser.FullName} (${impersonatedUser.Email}) of ${impersonatedUser.OrgName} (${impersonatedUser.OrgId})`}</span>
            <Icon type="close-circle" theme="filled" className="stop-impersonation" onClick={this.stopImpersonation} />
          </div>
        ) : null}
        {IsPulsePromotionalOffer && isPulse ? (
          <OfferBanner
            isDisplayIcon={false}
            content={
              <>
                Special Offer: Purchase 1 job credit & get 1 job credit for FREE. Offer valid from Dec 01 - 15, 2022.
                Click &nbsp;
                <a target="blank" href="https://leoforce.com/offer-terms-conditions">
                  here
                </a>
                &nbsp; for more info.
              </>
            }
          />
        ) : null}
        <DowntimeAnnouncement />
      </div>
    );
  }
}
NavbarContainer.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
};

NavbarContainer.defaultProps = {
  notifications: [],
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarContainer));
export { NavbarContainer as NavbarContainerWithoutContainer };
