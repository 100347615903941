import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import styles from './CandidatePastExperience.module.scss';
import { highlightKeyWords } from '../../../Utils/KeywordHighlightUtil';
import { setElementMaxHeightByLines } from '../../../Utils/CandidateCardUtils';

function CandidatePastExperience(props) {
  const {
    candidateExperiences = [],
    combinedKeywordsToHighlight = [],
    candidate,
    maxLinesToBeDisplayed,
    secondaryInfoStyle,
    shouldShowTooltip = true,
    experienceRef,
    keywordHighlightKey,
  } = props;

  const textRef = React.useRef(null);

  const highlightCandidateCard = () => {
    if (candidateExperiences.length && combinedKeywordsToHighlight.length) {
      const domSelectors = [`div#candidate-experiences-${keywordHighlightKey}`];
      setTimeout(() => {
        highlightKeyWords(domSelectors, combinedKeywordsToHighlight);
      }, 100);
    }
  };

  React.useEffect(() => {
    highlightCandidateCard();
    if (textRef) setElementMaxHeightByLines(textRef, maxLinesToBeDisplayed, experienceRef);
  }, [combinedKeywordsToHighlight.length, candidateExperiences.length, candidate.Id]);

  const displayedCandidateExperiences = candidateExperiences.map(
    entry =>
      `${entry.Title ? _.capitalize(entry.Title) : ''} ${entry.Company && entry.Title ? 'at' : ''} ${
        entry.Company ? entry.Company : ''
      }`
  );

  const onTooltipVisibleChange = visible => {
    if (visible) {
      const domSelectors = [`div#candidate-experiences-tooltip-${keywordHighlightKey}`];
      highlightKeyWords(domSelectors, combinedKeywordsToHighlight);
    }
  };

  return (
    <div
      className={styles.candidateExperiencesContainer}
      style={secondaryInfoStyle}
      id={`candidate-experiences-${keywordHighlightKey}`}
      ref={textRef}
    >
      {shouldShowTooltip ? (
        <Tooltip
          title={displayedCandidateExperiences.join(', ')}
          id={`candidate-experiences-tooltip-${keywordHighlightKey}`}
          overlayClassName={styles.pastExperienceTooltip}
          onVisibleChange={onTooltipVisibleChange}
          mouseEnterDelay={0.5}
        >
          <div className={styles.candidateCardExperinces}>
            <div className={styles.candidateExperiences} style={{ maxWidth: `${textRef.current?.clientWidth - 10}px` }}>
              {displayedCandidateExperiences.map((experience, index) => (
                <span style={{ minWidth: 'fit-content', marginRight: '6px' }}>
                  {experience}
                  {index === displayedCandidateExperiences.length - 1 ? '' : ','}
                </span>
              ))}
            </div>
          </div>
        </Tooltip>
      ) : (
        displayedCandidateExperiences.join(', ')
      )}
    </div>
  );
}

export default CandidatePastExperience;
