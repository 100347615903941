import React, { useCallback } from 'react';
import _ from 'lodash';
import { Affix } from 'antd';
import { initialFilter } from '../../Reducers/JobCandidatesTabReducer';
import JobCandidateList from '../JobCandidateList/JobCandidateList';
import AutoRejectAlert from '../AutoRejectAlert/AutoRejectAlert';
import CandidateFilters from '../CandidateFilters/CandidateFilters';
import WarningIcon from '../../Icons/WarningIcon';
import CandidateBulkActionsContainer from '../CandidateBulkActionsContainer/CandidateBulkActionsContainer';
import isEuropeanCountry from '../../Utils/GDPRcountry';
import CreditInfoNotEnough from '../../Components/CreditInfo/CreditInfoNotEnough';
import { FREE_TRIAL } from '../../Utils/PlanSelectionUtils';
import { generateUpdatedFilterPayload } from '../../Utils/AdvanceFiltersJsonGenerationUtils';
import UpgradePlanModal from '../../Components/Payment/UpgradePlanModal';
import CandidateSegmentFilters from '../CandidateFilters/CandidateSegmentFilters';
import ScoringInProgressMessage from '../../Components/CandidateList/ScoringInProgressMessage/ScoringInProgressMessage';

function AryaRecommendedCandidatesTab(props) {
  const {
    candidatesById,
    totalCandidate,
    candidateListStatus,
    candidateAggregationsApiStatus,
    featureToggleList,
    currentPipelineFilter,
    currentJobDetails,
    activeTab,
    jobId,
    candidateContext = 'job',
    status,
    maskingConfig = {},
    currentUser,
    showIntelBanners,
    atsJobCandidateListProps,
    connectionStatus,
    currPage,
    pageSize,
    version,
    onStatusChange,
    fetchCandidates,
    onPageChange,
    scrollToRefineIntel,
    onCloseIdealCandidateIntelPopup,
    defaultFilter = {},
    setJobCandidateTabPagination,
    filter,
    setJobCandidateTabFilter,
    publishedStatus,
    setSelectedCandidates,
    setSelectedActiveCandidateCount,
    currentSelectedAllCandidates,
    selectedActiveCandidateCount,
    history,
    jobGuid,
    planName,
    bulkOptionsOffestTop,
    openSipCallWindowsApp,
    isNonInternalPortalForSegmentEnabled,
    fetchBulkCandidateViewStatus,
    setCurrentUserCandidateViewedFlag,
    currentUserViewedStatusFlag,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isAryaRankedTab,
    smartRecruiterVersion,
    activeSourceName,
    from,
    filterContextId,
    setFrom,
    sampleCandidateCount,
    appliedBucketScoringStatus,
  } = props;

  const [creditNotEnoughInfoVisibility, setCreditNotEnoughInfoVisibility] = React.useState(false);
  const [isClearFilterApplied, setClearFilterApplied] = React.useState(false);

  const getIsDiversityAttributesVisible = () => {
    const genderDiversityEnabled = currentJobDetails?.GenderPreferences?.find(val => val && val?.IsRequired) ?? false;
    const categoryDiversityEnabled =
      currentJobDetails?.CategoryPreferences?.find(val => val && val?.IsRequired) ?? false;
    if (featureToggleList.Diversity.IsEnabled && (genderDiversityEnabled || categoryDiversityEnabled)) {
      if (maskingConfig?.IsActive) {
        return !(activeTab && activeTab === 'sourced');
      }
      return true;
    }
    return false;
  };

  const getUpgradePlanModal = () => {
    const isPaidJobService = featureToggleList.PaidJobService.IsEnabled;
    const lastPageNo = totalCandidate / pageSize + (totalCandidate % pageSize === 0 ? 0 : 1);
    const isUpgradePlanModalVisible =
      Math.floor(lastPageNo) === currPage &&
      currentJobDetails?.OrderInfo?.ProductVariantName === FREE_TRIAL &&
      totalCandidate !== 0 &&
      isPaidJobService &&
      candidateListStatus !== 'INPROGRESS';
    return isUpgradePlanModalVisible ? <UpgradePlanModal jobDetails={currentJobDetails} history={history} /> : null;
  };

  const updateCurrentPipelineFilter = modifiedFilters => {
    const updatedCurrentPipelineFilter = { ...currentPipelineFilter, ...modifiedFilters };
    const clonedFilter = _.cloneDeep(filter);
    clonedFilter[status || connectionStatus || publishedStatus] = updatedCurrentPipelineFilter;
    setJobCandidateTabFilter({ filter: clonedFilter });
  };

  const onSearchChange = event => {
    updateCurrentPipelineFilter({ SearchQuery: event.target.value });
  };

  const isDiversityAttributesVisible = getIsDiversityAttributesVisible();

  const applyCandidateFilter = async ({
    modifiedFilters,
    isFilterModified,
    isCurrentCandidateViewedFlag,
    currentCandidateViewedFormValue,
    invokationContext = 'aggregationFilter',
  }) => {
    const candidatesFetchPayload = { ...defaultFilter, ...modifiedFilters, From: 0 };
    setFrom(0);
    updateCurrentPipelineFilter(modifiedFilters);
    await fetchCandidates({
      filter: generateUpdatedFilterPayload(candidatesFetchPayload, activeTab, filterContextId),
      invokationContext,
      showLoader: true,
      isFilterModified,
      isCurrentCandidateViewedFlag,
      currentCandidateViewedFormValue,
    });
    setJobCandidateTabPagination({ currPage: 1 });
  };

  const clearAllFilterDependency = candidateContext === 'segment' ? null : Object.keys(defaultFilter);

  const clearAllFilter = useCallback(() => {
    const candidatesFetchPayload = { ...defaultFilter, ...initialFilter, From: 0 };
    updateCurrentPipelineFilter(initialFilter);
    setClearFilterApplied(true);
    fetchCandidates({
      filter: candidatesFetchPayload,
      invokationContext: 'aggregationFilter',
      showLoader: true,
      isFilterModified: true,
    });
  }, [clearAllFilterDependency]);

  const resetMultiSelect = () => {
    setSelectedCandidates({ candidateType: 'Recommended', selectedCandidates: [] });
    setSelectedActiveCandidateCount({ candidateType: 'Recommended', selectedActiveCandidateCount: 0 });
  };

  const getCurrentPageCandidateIds = () => {
    return Object.values(candidatesById)
      .map(candidate => candidate.Id)
      .filter(Id => Id);
  };

  const currentPageCandidateIds = getCurrentPageCandidateIds().slice(from, from + pageSize);
  const allPageCandidateIds =
    candidateContext === 'segment' ? getCurrentPageCandidateIds() : currentJobDetails.CandidateIds ?? [];

  const setCurrentSelectedAllCandidates = candidateIds => {
    setSelectedCandidates({ candidateType: 'Recommended', selectedCandidates: candidateIds });
  };

  const onCreditInfoNotEnoughModalToggle = visibility => {
    setCreditNotEnoughInfoVisibility(visibility);
  };

  const creditInfoNotEnoughCallback = {
    onNotEnoughCredit: onCreditInfoNotEnoughModalToggle,
  };
  const {
    PaidJobService: { IsEnabled: isPaidJobServiceEnabled },
    BulkContactFetch: { IsEnabled: isBulkContactFetchEnabled },
    EmailWrite: { IsEnabled: isEmailWriteEnabled },
    MessageWrite: { IsEnabled: isMessageWriteEnabled },
    CandidateBulkSelectionInput: { IsEnabled: isCandidateBulkSelectionInputEnabled },
    BulkSms: { IsEnabled: isBulkMessageEnabled },
  } = featureToggleList;

  const isBulkActionEnabled =
    !isPaidJobServiceEnabled && (isBulkContactFetchEnabled || isEmailWriteEnabled || isMessageWriteEnabled);

  const upgradePlanModal = getUpgradePlanModal();

  const getSourcingInProgressBanner = () => {
    return <ScoringInProgressMessage />;
  };

  const getBulkOptions = () => {
    const isCandidateListLoading = candidateListStatus === 'INPROGRESS';
    const isBulkAddAvailable = candidateContext === 'segment' && (activeTab === 'sourced' || activeTab === 'rejected');
    const isBulkDeleteAvailable =
      candidateContext === 'segment' && (activeTab === 'sourced' || activeTab === 'shortlisted');
    const isBulkMoveAvailable = candidateContext === 'segment';
    const isBulkTransferAvailable = candidateContext === 'segment' && activeTab === 'shortlisted';
    const _isCandidateBulkSelectionInputEnabled =
      candidateContext === 'segment' && isCandidateBulkSelectionInputEnabled;
    const bulkOptions = (
      <CandidateBulkActionsContainer
        visiblityFlag
        featureToggleList={featureToggleList}
        activeTab={activeTab}
        isBulkEmailEnabled
        isBulkMessageEnabled={isBulkMessageEnabled}
        isBulkContactPullAllowed
        resetMultiSelect={resetMultiSelect}
        jobId={jobId}
        candidateContext={candidateContext}
        allPageCandidates={allPageCandidateIds}
        currentPageCandidates={currentPageCandidateIds}
        selectedCandidates={currentSelectedAllCandidates}
        setCurrentSelectedAllCandidates={setCurrentSelectedAllCandidates}
        isConsentRequired={isEuropeanCountry(currentJobDetails.CountryCode ?? '')}
        _currentSelectedAllCandidates={currentSelectedAllCandidates}
        _allPageCandidates={allPageCandidateIds}
        onStatusChange={onStatusChange}
        setSelectedActiveCandidateCount={setSelectedActiveCandidateCount}
        selectedActiveCandidateCount={selectedActiveCandidateCount}
        eventCallback={creditInfoNotEnoughCallback}
        selectedCandidatesCount={currentSelectedAllCandidates?.length}
        isBulkRecommendEnabled={featureToggleList.BulkRecommend.IsEnabled}
        isCandidateBulkSelectionInputEnabled={_isCandidateBulkSelectionInputEnabled}
        candidateListType="aryarecommended"
        isSelectThisPageDisabled={isCandidateListLoading}
        isBulkSelectionDisabled={isCandidateListLoading}
        isBulkAddAvailable={isBulkAddAvailable}
        isBulkDeleteAvailable={isBulkDeleteAvailable}
        isBulkMoveAvailable={isBulkMoveAvailable}
        isBulkTransferAvailable={isBulkTransferAvailable}
        version={version}
        jobDetails={currentJobDetails}
        totalCandidate={totalCandidate}
        sampleCandidateCount={sampleCandidateCount}
      />
    );
    const offsetTop = currentSelectedAllCandidates?.length ? bulkOptionsOffestTop : 0;
    return <Affix offsetTop={offsetTop}>{bulkOptions}</Affix>;
  };

  const {
    IsActive: isMaskingActive,
    IsNameMasked: isNameMasked,
    IsUniversityMasked: isUniversityMasked,
  } = maskingConfig;
  const isShowMaskWarning = isMaskingActive && (isNameMasked || isUniversityMasked);

  return (
    <>
      {candidateContext === 'job' ? (
        <CandidateFilters
          onChange={onSearchChange}
          applyCandidateFilter={applyCandidateFilter}
          candidates={Object.values(candidatesById)}
          jobId={jobId}
          isCandidateListLoading={candidateListStatus === 'INPROGRESS'}
          isCandidateAggregationsLoading={candidateAggregationsApiStatus === 'INPROGRESS'}
          featureToggleList={featureToggleList}
          currentPipelineFilter={currentPipelineFilter}
          currentJobDetails={currentJobDetails}
          maskingConfig={maskingConfig}
          isDiversityAttributesVisible={isDiversityAttributesVisible}
          showIntelBanners={showIntelBanners}
          activeTab={activeTab}
          totalCandidate={totalCandidate}
          setClearFilterApplied={setClearFilterApplied}
          isClearFilterApplied={isClearFilterApplied}
          resetMultiSelect={resetMultiSelect}
          currentPageCandidateIds={currentPageCandidateIds}
          fetchBulkCandidateViewStatus={fetchBulkCandidateViewStatus}
          setCurrentUserCandidateViewedFlag={setCurrentUserCandidateViewedFlag}
          currentUserViewedStatusFlag={currentUserViewedStatusFlag}
          isAryaRankedTab={isAryaRankedTab}
          smartRecruiterVersion={smartRecruiterVersion}
          activeSourceName={activeSourceName}
          onStatusChange={onStatusChange}
        />
      ) : null}
      {candidateContext === 'segment' && Object.values(candidatesById).length > 0 ? (
        <CandidateSegmentFilters
          onChange={onSearchChange}
          currentJobDetails={currentJobDetails}
          candidates={Object.values(candidatesById)}
          isSearchFilterVisible={featureToggleList?.CandidateSearch?.IsEnabled}
          isAggregationFilterVisible={featureToggleList.CandidateFilter.IsEnabled}
          isCandidateListLoading={candidateListStatus === 'INPROGRESS'}
          applyCandidateFilter={applyCandidateFilter}
          currentPipelineFilter={currentPipelineFilter}
          totalCandidate={totalCandidate}
          setClearFilterApplied={setClearFilterApplied}
          isClearFilterApplied={isClearFilterApplied}
          activeTab={activeTab}
          featureToggleList={featureToggleList}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
          resetMultiSelect={resetMultiSelect}
          currentPageCandidateIds={currentPageCandidateIds}
          fetchBulkCandidateViewStatus={fetchBulkCandidateViewStatus}
          setCurrentUserCandidateViewedFlag={setCurrentUserCandidateViewedFlag}
          currentUserViewedStatusFlag={currentUserViewedStatusFlag}
          jobId={jobId}
          sampleCandidateCount={sampleCandidateCount}
        />
      ) : null}
      <CreditInfoNotEnough
        visible={creditNotEnoughInfoVisibility}
        onCreditInfoNotEnoughModalOk={onCreditInfoNotEnoughModalToggle}
      />
      {isBulkActionEnabled && candidateContext === 'job' ? getBulkOptions() : null}
      {Object.values(candidatesById).length > 0 && candidateContext === 'segment' ? getBulkOptions() : null}
      {isShowMaskWarning && activeTab === 'sourced' ? (
        <div>
          <WarningIcon /> The identity of the Candidates are masked by admin and will be revealed upon shortlisting
        </div>
      ) : null}
      {activeTab === 'applied' && appliedBucketScoringStatus && getSourcingInProgressBanner()}
      <AutoRejectAlert
        onStatusChange={onStatusChange}
        fetchCandidates={fetchCandidates}
        defaultFilter={defaultFilter}
        jobId={jobId}
        status={status}
      />
      <JobCandidateList
        {...atsJobCandidateListProps}
        status={status}
        connectionStatus={connectionStatus}
        isBulkActionEnabled={isBulkActionEnabled}
        currentJobDetails={currentJobDetails}
        jobId={jobId}
        candidateContext={candidateContext}
        currPage={currPage}
        pageSize={pageSize}
        defaultFilter={defaultFilter}
        fetchCandidates={fetchCandidates}
        isFilterApplied={!_.isEqual(initialFilter, currentPipelineFilter)}
        onStatusChange={onStatusChange}
        clearAllFilter={clearAllFilter}
        onPageChange={onPageChange}
        scrollToRefineIntel={scrollToRefineIntel}
        activeTab={activeTab}
        onCloseIdealCandidateIntelPopup={onCloseIdealCandidateIntelPopup}
        version={version}
        isDiversityAttributesVisible={isDiversityAttributesVisible}
        publishedStatus={publishedStatus}
        jobGuid={jobGuid}
        planName={planName}
        openSipCallWindowsApp={openSipCallWindowsApp}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        from={from}
      />
      {upgradePlanModal}
    </>
  );
}

export default AryaRecommendedCandidatesTab;
