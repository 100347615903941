import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Divider } from 'antd';
import './ReviewCommentDetails.scss';
import config from '../../Config/Config';

function ReviewCommentDetails(props) {
  const { onClose, jobId, AppName } = props;
  return (
    <div>
      <div className="arya-review">
        <div className="review-data">
          <div>
            <div className="review-comment-review-job-info-div">
              <div className="review-comment-review-job-info">Review job Info</div>
              <div className="review-comment-edit-job">
                <Link to={`/jobs/${jobId}/edit`}>
                  <div className="edit-job">Edit Job </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            There may be too many Must Haves in the Job Description
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            Try increasing the search radius around the Job location
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            There may not be enough candidates in the industry at the location selected
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            Please verify Job Title and Job Description are complete and accurate
          </div>
          <div className="review-comment-divider">
            <Divider orientation="left">OR</Divider>
          </div>
          <div className="review-comment-try-quick-search-div">
            <div className="review-comment-try-quick-search">Try quick search</div>
            <div className="review-comment-refine-arya-intel">
              <Link to={`/jobs/${jobId}/quick-search`}>
                <div className="refine-arya-intel">{`Refine ${AppName} Intel`} </div>
              </Link>
            </div>
          </div>
          <div className="review-comment-divider">
            <Divider orientation="left">OR</Divider>
          </div>
          <div className="review-comment-support-div">
            <div className="review-comment-support">If problem persists try reaching out to</div>
            <div className="review-comment-support-link">
              <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
            </div>
          </div>
        </div>
        <div className="review-comment-close-div">
          <div className="review-comment-close" onClick={onClose} onKeyPress={onClose} role="presentation">
            Close
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCommentDetails;
