import React from 'react';
import moment from 'moment';
import Candidate360MessageCard from '../../Candidate360MessageCard/Candidate360MessageCard';

const getDeliveryStatusWithIcon = ({ DeliveryStatus: deliveryStatus, IsByPerson: isByPerson }) => {
  if (isByPerson) return 'Received';
  if (!deliveryStatus) {
    return 'Pending';
  }

  let displayDeliveryStatus = deliveryStatus;
  if (deliveryStatus.toLowerCase() === 'success') {
    if (isByPerson) displayDeliveryStatus = 'Received';
    else displayDeliveryStatus = 'Delivered';
  }
  if (deliveryStatus.toLowerCase() === 'failed') {
    displayDeliveryStatus = 'Failed';
  }
  return displayDeliveryStatus;
};

const Candidate360MessageStack = props => {
  const { messages, openComposeTextModal, setPhoneNumber, setFieldsValue, sendMessageApiStatus } = props;

  return (
    messages &&
    messages.map(message => {
      const sentTime = moment.utc(message.CreatedTime).local().format('hh:mm A ');
      const messageText = message.Body;
      const status = getDeliveryStatusWithIcon(message);
      const {
        To,
        unreadSmsCount,
        IsByPerson,
        ConversationId: conversationId,
        CreatedByName: createdByName,
        From,
        pendingState,
      } = message;
      const data = {
        to: To,
        sentTime,
        message: messageText,
        status,
        unreadSmsCount,
        IsByPerson,
        conversationId,
        createdByName,
        from: From,
        sendMessageApiStatus,
        pendingState,
      };
      return (
        <Candidate360MessageCard
          data={data}
          setFieldsValue={setFieldsValue}
          openComposeTextModal={openComposeTextModal}
          setPhoneNumber={setPhoneNumber}
          data-testid="message-card"
          key={To}
        />
      );
    })
  );
};
export default Candidate360MessageStack;
