/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import uuid from 'uuid';
import _ from 'lodash';

import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Icon,
  Checkbox,
  Tag,
  InputNumber,
  Affix,
  Menu,
  Dropdown,
  Tooltip,
  Alert,
  Radio,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import RichTextEditor from '../Editor/RichTextEditor';
import CollaboratorsContainer from '../../Containers/CollaboratorsContainer/CollaboratorsContainer';
import JobResume from '../JobResume/JobResume';
import ClientSelect from './ClientSelect';
import './JobFormComponent.scss';
import SourceLimitSlider from './SourceLimitSlider/SourceLimitSlider';
import SelectDropDownContent from '../Utils/SelectDropDownContent/SelectDropDownContent';
import { purifySelectedOptions } from '../../Utils/SelectComponentUtility';
import { validateZipCode, validateMinExperience, validateMaxExperience } from '../../Utils/FormValidators';
import {
  getIsJobActivationLimitExceeded,
  getIsJobActivationAllowed,
  getJobLimitExceededAlertMessage,
  getFormattedInput,
  validateInputField,
} from '../../Utils/JobUtils';
import validateUrl from '../../Utils/UrlValidator';
import { ProgressSteps } from '../../Containers/PlanSelection/PlanSelectionModules';
import JobLocationInput from './JobLocationInput/JobLocationInput';
import PulseMilesInput from './PulseMilesInput/PulseMilesInput';
import MilesInput from '../Common/MilesInput/MilesInput';
import JobMultiLocationInputContainer from '../../Containers/JobMultiLocationInputContainer/JobMultiLocationInputContainer';
import { getSourcingLocations, parseLocation, getSourcingLocationsOnFeatureToggle } from '../../Utils/LocationUtils';
import styles from './JobFormComponent.module.scss';
import ClientAddition from './ClientAddition';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import store from '../../store';
import { isPulseUser } from '../../Utils/ConfigUtils';
import { getConfig } from '../../Reducers/ConfigReducer';
import EnhancedJobMultiLocationInputContainer from '../../Containers/JobMultiLocationInputContainer/EnhancedJobMultiLocationInputContainer';
import RemoteJob from '../../CompoundComponents/LocationWrapper/RemoteJob/RemoteJob';
import LocationWrapper from '../../CompoundComponents/LocationWrapper/LocationWrapper';

const FormItem = Form.Item;
const { Option } = Select;

export function validateJobTitle(rule, value, callback) {
  const title = value && value.trim();
  if (title && title.length > 150) {
    callback('Job Title should be lesser than 150 characters');
  } else if (title) {
    callback();
  } else {
    callback('Please enter the Job Title');
  }
}

export function validateViewUrl(rule, value, callback) {
  const applyUrl = value?.trim();
  if (applyUrl?.length > 0) {
    const isValidUrl = validateUrl(applyUrl);
    if (!isValidUrl) {
      callback('Please add a valid URL format');
    }
  }
  callback();
}

const diversityOptionTooltip =
  'When you select “Promote,” Arya will aim to expand the pool of qualified candidates from an underrepresented category to provide you with a more diverse pool from which to shortlist.';

export function validateTextArea(rule, value, callback) {
  const textAreaContent = value && value.trim();
  if (textAreaContent && textAreaContent.length > 2000) {
    callback('TextArea should be lesser than 2000 characters');
  } else {
    callback();
  }
}

class JobForm extends React.Component {
  constructor(props) {
    super(props);
    this.isDiversityInitialised = false;
    this.isDefaultLocationTypeSet = false;
    this.previousRange = { location: undefined, multiLocation: undefined };
    this.state = {
      isSubmitButtonClicked: false,
      synonyms: [],
      synonymVisibility: false,
      uploadedResumeFiles: [],
      removedResumeIds: [],
      initialTagsCount: 0,
      initialGender: null,
      initialVeteran: null,
      initialMinority: null,
      isDiversityAcknowledgedVisible: false,
      isDiversityOptionChanged: false,
      diversityOptions: [],
      isLocationChecked: true,
      validationStatus: '',
      validationStatusErrorMessage: '',
    };
    this.pageRecords = {
      Company: 1,
    };
    this.callForTitleSynonymsAfterDelay = debounce(this.callForTitleSynonyms.bind(this), 600);
    this.callForSkillSetAfterDelay = debounce(this.callForSkillSet.bind(this), 600);
    this.callForTitleSynonyms = this.callForTitleSynonyms.bind(this);
    this.callForSkillSet = this.callForSkillSet.bind(this);
    this.fetchTagsAfterDelay = debounce(this.fetchTagsAfterDelay.bind(this), 600);
    this.handleClick = this.handleClick.bind(this);
    this.addToTagSynonym = this.addToTagSynonym.bind(this);
    this.addToTagIndustry = this.addToTagIndustry.bind(this);
    this.checkSynonymToTagAdd = this.checkSynonymToTagAdd.bind(this);
    this.checkIndustryToTagAdd = this.checkIndustryToTagAdd.bind(this);
    this.child = React.createRef();
    this.addSynonymToTag = this.addSynonymToTag.bind(this);
    this.handleSynonymVisibility = this.handleSynonymVisibility.bind(this);
    this.hideSynonymVisibility = this.hideSynonymVisibility.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateDescription = this.validateDescription.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.filterIndustry = debounce(this.filterIndustry, 600);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.uploadJobResume = this.uploadJobResume.bind(this);
    this.removeJobResume = this.removeJobResume.bind(this);
    this.updateDescriptionContent = this.updateDescriptionContent.bind(this);
    this.updateJobSummaryContent = this.updateJobSummaryContent.bind(this);
    this.validateJobSummary = this.validateJobSummary.bind(this);
    this.validateZipCode = debounce(validateZipCode, 600);
    this.zipCodeValidator = this.zipCodeValidator.bind(this);
    this.fetchClientsInstantly = _.debounce(this.fetchClientsInstantly, 150);
    this.fetchClientsDelayed = _.debounce(this.fetchClientsInstantly, 600);
    this.validateSourcingLocation = this.validateSourcingLocation.bind(this);
    this.setValidationStatus = this.setValidationStatus.bind(this);
  }

  componentDidMount() {
    const {
      fetchCountries,
      fetchClients,
      /* fetchClient, */ fetchJobStatus,
      fetchTagsForJobForm,
      featureToggleList,
      setSampleCandidatesDisplayFlag,
    } = this.props;
    setSampleCandidatesDisplayFlag();
    const isJobTagEnabled = _.get(featureToggleList, ['JobTag', 'IsEnabled'], false);
    fetchCountries();
    fetchClients({
      From: 0,
      Size: 10,
      IsActive: true,
    });
    if (isJobTagEnabled) fetchTagsForJobForm({ from: 0, size: 10, searchTerm: '' });
    fetchJobStatus();
    this.filterIndustry('');
  }

  componentDidUpdate(prevProps) {
    const { fetchTagsForJobForm, form, jobDetails, featureToggleList } = this.props;
    const isAdvanceSearchV2 = featureToggleList?.AdvanceSearchV2?.IsEnabled;
    const prevIsJobTagEnabled = _.get(prevProps, ['featureToggleList', 'JobTag', 'IsEnabled'], false);
    const currentIsJobTagEnabled = _.get(this.props, ['featureToggleList', 'JobTag', 'IsEnabled'], false);
    if (prevIsJobTagEnabled !== currentIsJobTagEnabled && currentIsJobTagEnabled) {
      fetchTagsForJobForm({ from: 0, size: 10, searchTerm: '' });
    }

    if (!this.isDefaultLocationTypeSet && jobDetails) {
      if (isAdvanceSearchV2) {
        form.setFieldsValue({
          LocationType: 'MultiLocation',
        });
      } else {
        form.setFieldsValue({
          LocationType: jobDetails.IsMultiLocationJob ? 'MultiLocation' : 'Location',
        });
      }
      this.isDefaultLocationTypeSet = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    const { titleSynonyms, countries, clients, industries, tags } = nextProps.utilities;
    const { jobDetails } = this.props;
    const { initialTagsCount } = this.state;
    const { jobDetails: oldjobDetails } = this.props;
    const { state } = this;
    if (!state.jobDetails || !_.isEqual(jobDetails, nextProps.jobDetails)) {
      const newDetails = _.cloneDeep(nextProps.jobDetails);
      let synonyms = [];
      if (jobDetails.Synonyms) {
        synonyms = jobDetails.Synonyms.filter(synonym => synonym !== '');
      }
      newDetails.Synonyms = synonyms.slice();
      this.setState({
        jobDetails: newDetails,
      });
      if (state.countryOptions && state.isCountryWideSearchEnabled) {
        const isCountryWideSearchEnabled =
          countries.find(country => country.Iso2Code === jobDetails.CountryCode).StatsLevel === 'Country';
        this.setState({
          isCountryWideSearchEnabled,
        });
      }
      if (!this.isDiversityInitialised) {
        this.initializeDiversityState(jobDetails);
        this.isDiversityInitialised = true;
      }
    }
    if (titleSynonyms) {
      const synonyms = titleSynonyms.map(titleSynonym => {
        const synonym = {};
        synonym.synonym = titleSynonym;
        synonym.select = false;
        return synonym;
      });
      this.setState({
        synonyms,
      });
    }
    if (countries) {
      this.setState({
        countryOptions: countries.sort((c1, c2) => {
          if (c1.Name.toLowerCase() < c2.Name.toLowerCase()) {
            return -1;
          }
          return 1;
        }),
      });
      if (oldjobDetails.CountryCode && state.isCountryWideSearchEnabled === undefined) {
        this.setState({
          isCountryWideSearchEnabled:
            countries.find(country => country.Iso2Code === oldjobDetails.CountryCode).StatsLevel === 'Country',
        });
      }
    }
    if (clients) {
      this.setState({
        companyOptions: clients.clients || [],
      });
    }
    if (tags) {
      this.setState({
        tagOptions: _.get(tags, ['Tags'], []),
        totalTagsCount: _.get(tags, ['Total'], 0),
      });
    }
    if (initialTagsCount <= _.get(tags, ['Total'], 0)) {
      this.setState({ initialTagsCount: _.get(tags, ['Total'], 0) });
    }

    if (industries) {
      this.setState({
        industryOptions: industries,
      });
    }
  }

  componentWillUnmount() {
    const { clearZipCodeError, featureToggleList } = this.props;
    const isJobListEnabled = _.get(featureToggleList, ['JobList', 'IsEnabled'], false);
    if (isJobListEnabled) clearZipCodeError();
  }

  zipCodeValidator(rule, value, callback) {
    const { utilities } = this.props;
    if (!utilities.isZipCodeValid) {
      callback('Invalid Postal code');
    } else {
      callback();
    }
  }

  validateSourcingLocation(rule, value, callback, isRemoteJob, isJobRemoteJob) {
    const activeLocations = value.filter(x => x.isActive);
    if (activeLocations?.length === 0 && !isRemoteJob) {
      this.setState({ validationStatus: 'error' });
      this.setState({ validationStatusErrorMessage: 'Please click on Add Location' });
      callback('Please click on Add Location');
    } else if (activeLocations.every(x => x.Region !== null && x.Region !== undefined) && !isRemoteJob) {
      this.setState({ validationStatus: 'error' });
      this.setState({ validationStatusErrorMessage: 'Please add any Location as well' });
      callback('Please add any Location as well');
    } else {
      this.setState({ validationStatus: 'success' });
      this.setState({ validationStatusErrorMessage: '' });
      callback();
    }
  }

  setValidationStatus(value) {
    const { form } = this.props;
    let validationStatus = '';
    let validationStatusErrorMessage = '';
    const activeLocations = form.getFieldValue('SourcingLocations').filter(x => x.isActive);
    if (value) {
      validationStatus = 'success';
    } else if (activeLocations?.length === 0) {
      validationStatus = 'error';
      validationStatusErrorMessage = 'Please click on Add Location';
    } else if (activeLocations.every(x => x.Region !== null && x.Region !== undefined)) {
      validationStatus = 'error';
      validationStatusErrorMessage = 'Please add any Location as well';
    }

    this.setState({ validationStatus, validationStatusErrorMessage });
  }

  handleClick() {
    this.child.current.showInput();
  }

  addSynonymToTag(synonym) {
    const { jobDetails, synonyms } = this.state;
    const index = jobDetails.Synonyms.findIndex(element => element === synonym.synonym);
    const newState = synonyms;

    if (index === -1) {
      jobDetails.Synonyms.push(synonym.synonym);
      this.setState({
        jobDetails,
      });
    } else {
      jobDetails.Synonyms.splice(index, 1);
      this.setState({
        jobDetails,
      });
    }
    this.setState({ synonyms: newState });
  }

  checkSynonymToTagAdd(tags, tag) {
    const { jobDetails, synonyms } = this.state;
    const index = synonyms.findIndex(element => element.synonym === tag);
    const newState = synonyms;
    if (index !== -1) {
      newState[index].select = false;
      this.setState({ synonyms: newState });
    }
    jobDetails.Synonyms = tags;
    this.setState({
      jobDetails,
    });
  }

  checkIndustryToTagAdd(tags) {
    const { jobDetails } = this.state;
    jobDetails.Industries = tags;
    this.setState({
      jobDetails,
    });
  }

  addToTagSynonym(tag) {
    const { jobDetails } = this.state;
    const newState = jobDetails;
    newState.Synonyms = jobDetails.Synonyms.slice();
    newState.Synonyms.push(tag);
    this.setState({
      jobDetails: newState,
    });
  }

  addToTagIndustry(tag) {
    const { jobDetails } = this.state;
    const newState = jobDetails;
    newState.Industries.push(tag);
    this.setState({
      jobDetails: newState,
    });
  }

  callForTitleSynonyms() {
    const { form, fetchTitleSynonyms } = this.props;
    const title = form.getFieldValue('JobTitle');
    if (title !== this.lastSynonymCallTitle) {
      fetchTitleSynonyms(title);
      this.lastSynonymCallTitle = title;
    }
  }

  callForSkillSet() {
    const { form, fetchSkills } = this.props;
    const title = form.getFieldValue('JobTitle');
    if (title !== this.lastSkillCallTitle) {
      fetchSkills(title);
      this.lastSkillCallTitle = title;
    }
  }

  handleTagSearch = value => {
    this.setState({ tagSearchTerm: value });
    this.fetchTagsAfterDelay({ searchTerm: value, from: 0, size: 10 });
  };

  fetchTagsAfterDelay(filter) {
    const { fetchTagsForJobForm } = this.props;
    fetchTagsForJobForm(filter);
  }

  handleTagDropdownScroll = e => {
    const { tagSearchTerm, tagOptions, totalTagsCount } = this.state;

    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      tagOptions.length < totalTagsCount
    ) {
      this.fetchTagsAfterDelay({ searchTerm: tagSearchTerm, from: tagOptions.length, size: 10 });
    }
  };

  handleTagDropdownSelect = () => {
    const { initialTagsCount, totalTagsCount } = this.state;
    const { updateTotalTagsCount } = this.props;
    if (initialTagsCount !== totalTagsCount) updateTotalTagsCount(initialTagsCount);
    this.setState({ tagSearchTerm: '' });
  };

  handleSynonymVisibility() {
    this.setState({
      synonymVisibility: true,
    });
    this.callForTitleSynonyms();
  }

  hideSynonymVisibility() {
    this.setState({
      synonymVisibility: false,
    });
  }

  initializeDiversityState = jobDetails => {
    const femaleChecked = jobDetails?.GenderPreferences?.find(val => val?.Gender === 'Female')?.IsRequired ?? null;
    const veteranChecked =
      jobDetails?.CategoryPreferences?.find(val => val?.Category === 'Veteran')?.IsRequired ?? null;
    const minorityChecked =
      jobDetails?.CategoryPreferences?.find(val => val?.Category === 'Minority')?.IsRequired ?? null;

    this.setState({
      initialGender: femaleChecked,
      initialVeteran: veteranChecked,
      initialMinority: minorityChecked,
    });
  };

  handleDiversityOptionsChange = (formKey, event) => {
    const { form } = this.props;
    form.setFieldsValue({ [formKey]: event?.target?.checked });
  };

  clearDiversitySelection = () => {
    const { form } = this.props;
    form.setFieldsValue({
      Gender: null,
      Minority: null,
      Veteran: null,
      DiversityDescription: null,
    });
  };

  toggleDiversityAcknowledgement = () => {
    const { isDiversityAcknowledgedVisible } = this.state;
    this.setState({
      isDiversityAcknowledgedVisible: !isDiversityAcknowledgedVisible,
    });
  };

  validateDiversityEnabledCountry = selectedCountry => {
    const { featureToggleList } = this.props;
    return featureToggleList.Diversity.AllowedCountryCodes?.find(country => country === selectedCountry) ?? false;
  };

  isZipcodeInvalid = () => {
    const { form, utilities } = this.props;
    const { zipCodeOptions } = this.state;
    const zipCode = form.getFieldValue('Zipcode');
    return utilities.isZipCodeInValid && !(zipCodeOptions || []).includes(zipCode);
  };

  getDefaultRange = () => {
    const { featureToggleList, userConfig } = this.props;
    if (this.previousRange.multiLocation) return this.previousRange.multiLocation;
    return featureToggleList.PaidJobService.IsEnabled ? 100 : userConfig?.Distance?.Distance ?? 50;
  };

  getUpdatedFormValues = (values, foundClient, isPaidJobServiceEnabled) => {
    const { countryOptions, jobDetails } = this.state;
    const { featureToggleList } = this.props;
    const isAdvanceSearchV2 = featureToggleList?.AdvanceSearchV2?.IsEnabled;
    const formValues = _.cloneDeep(values);
    formValues.MinExperience = formValues.MinExperience ? formValues.MinExperience : null;
    formValues.MaxExperience = formValues.MaxExperience ? formValues.MaxExperience : null;
    if (formValues.MaxExperience && !formValues.MinExperience) {
      formValues.MinExperience = 0;
    }
    if (values?.LocationType === 'MultiLocation') {
      const updatedSourcingLocations = getSourcingLocationsOnFeatureToggle(
        formValues.SourcingLocations,
        formValues.IsRemoteJob,
        isAdvanceSearchV2
      );
      const sourcingLocations = getSourcingLocations(updatedSourcingLocations, formValues.Range);
      const primaryLocation = sourcingLocations.find(x => x.IsPrimaryLocation);
      formValues.SourcingLocations = sourcingLocations;
      formValues.IsMultiLocationJob = true;
      formValues.Location = primaryLocation.Location;
      formValues.CountryCode = primaryLocation.CountryCode;
      formValues.Zipcode = undefined;
    } else {
      const { Location } = formValues;
      const _location = parseLocation(Location);
      formValues.Location = _location;
      formValues.SourcingLocations = undefined;
      formValues.IsMultiLocationJob = false;
    }
    if (formValues.IsRemoteJob) formValues.Range = undefined;
    if (formValues.CountryCode !== jobDetails.CountryCode) {
      const newCountryOption = countryOptions.find(option => option.Iso2Code === formValues.CountryCode);
      formValues.Country = newCountryOption.Name;
    }
    if (isPaidJobServiceEnabled) {
      formValues.Openings = 1;
      if (foundClient) {
        formValues.ClientId = foundClient.Id;
      } else {
        formValues.ClientName = formValues.selectedClient;
      }
    } else {
      formValues.ClientId = formValues.selectedClientId;
    }
    return formValues;
  };

  saveJobDiversityChanges = (
    isDiversityOptionChanged,
    selectedDiversityOptions,
    deSelectedDiversityOptions,
    unTouchedDiversityOptions,
    upsertedJobDetails
  ) => {
    const { jobDetails } = this.state;
    const { type, saveJobDiversityChanges } = this.props;

    if (isDiversityOptionChanged) {
      saveJobDiversityChanges({
        SelectedDiversityOptions: selectedDiversityOptions,
        UnselectedDiversityOptions: deSelectedDiversityOptions,
        UntouchedDiversityOptions: unTouchedDiversityOptions,
        JobId: type === 'create' ? upsertedJobDetails?.data?.JobId : jobDetails?.JobId,
      });
    }
  };

  handleSubmit = submitAction => {
    const { form, handleSubmit, featureToggleList } = this.props;

    form.validateFieldsAndScroll({ scroll: { offsetTop: 150 } }, async (err, values) => {
      if (!err && !this.isZipcodeInvalid()) {
        const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
        const { jobDetails, removedResumeIds, uploadedResumeFiles, companyOptions } = this.state;
        const foundClient = _.find(companyOptions, client => client.Name === values?.selectedClient);
        const newJobDetails = _.cloneDeep(jobDetails);
        newJobDetails.CategoryPreferences = newJobDetails.CategoryPreferences ?? [];
        newJobDetails.GenderPreferences = newJobDetails.GenderPreferences ?? [];
        newJobDetails.Summary = getFormattedInput(jobDetails?.Summary);
        if (values.Synonyms) _.set(newJobDetails, ['Synonyms'], values.Synonyms);
        const updatedFormValues = this.getUpdatedFormValues(values, foundClient, isPaidJobServiceEnabled);
        if (isPaidJobServiceEnabled && !foundClient) {
          delete newJobDetails.ClientId;
        }
        newJobDetails.GenderPreferences = [
          {
            Gender: 'Female',
            IsRequired: values?.Gender ?? null,
          },
        ];
        newJobDetails.CategoryPreferences = [
          {
            Category: 'Veteran',
            IsRequired: values?.Veteran ?? null,
          },
          {
            Category: 'Minority',
            IsRequired: values?.Minority ?? null,
          },
        ];
        const diversityOptionsChanged = this.isDiversityOptionsChanged();
        const { isDiversityOptionChanged, diversityOptions } = diversityOptionsChanged;
        const { selectedDiversityOptions, deSelectedDiversityOptions, unTouchedDiversityOptions } =
          this.diversityOptionChanges(diversityOptions);
        delete updatedFormValues.Gender;
        delete updatedFormValues.Veteran;
        delete updatedFormValues.Minority;
        const upsertedJobDetails = await handleSubmit(
          newJobDetails,
          updatedFormValues,
          { uploadedResumeFiles, removedResumeIds },
          submitAction
        );

        this.saveJobDiversityChanges(
          isDiversityOptionChanged,
          selectedDiversityOptions,
          deSelectedDiversityOptions,
          unTouchedDiversityOptions,
          upsertedJobDetails
        );
      }
    });
  };

  diversityOptionChanges = diversityOptions => {
    const selectedDiversityOptions = [];
    const deSelectedDiversityOptions = [];
    const unTouchedDiversityOptions = [];

    diversityOptions.forEach(val => {
      if (val?.oldValue === false && val?.newValue) {
        selectedDiversityOptions.push(val?.key);
      } else if (val?.oldValue && val?.newValue === false) {
        deSelectedDiversityOptions.push(val?.key);
      } else {
        unTouchedDiversityOptions.push(val?.key);
      }
    });
    return { selectedDiversityOptions, deSelectedDiversityOptions, unTouchedDiversityOptions };
  };

  isDiversityOptionsChanged = () => {
    const { form, jobDetails, type } = this.props;

    let prevFemaleSelection = false;
    let prevVeteranSelection = false;
    let prevMinoritySelection = false;

    if (type !== 'create') {
      prevFemaleSelection = jobDetails?.GenderPreferences?.find(val => val?.Gender === 'Female')?.IsRequired ?? false;
      prevVeteranSelection =
        jobDetails?.CategoryPreferences?.find(val => val?.Category === 'Veteran')?.IsRequired ?? false;
      prevMinoritySelection =
        jobDetails?.CategoryPreferences?.find(val => val?.Category === 'Minority')?.IsRequired ?? false;
    }

    const currentFemaleSelection = form.getFieldValue('Gender') ?? false;
    const currentVeteranSelection = form.getFieldValue('Veteran') ?? false;
    const currentMinoritySelection = form.getFieldValue('Minority') ?? false;

    const isDiversityOptionChanged =
      prevFemaleSelection !== currentFemaleSelection ||
      prevVeteranSelection !== currentVeteranSelection ||
      prevMinoritySelection !== currentMinoritySelection;

    return {
      isDiversityOptionChanged,
      diversityOptions: [
        {
          key: 'Female',
          oldValue: prevFemaleSelection,
          newValue: currentFemaleSelection,
        },
        {
          key: 'Veteran',
          oldValue: prevVeteranSelection,
          newValue: currentVeteranSelection,
        },
        {
          key: 'Minority',
          oldValue: prevMinoritySelection,
          newValue: currentMinoritySelection,
        },
      ],
    };
  };

  handleJobSubmit = () => {
    const { form } = this.props;
    const diversityOptionsChanged = this.isDiversityOptionsChanged();
    this.setState({ isSubmitButtonClicked: true });
    if (diversityOptionsChanged?.isDiversityOptionChanged) {
      const diversityOptionsChanges = this.diversityOptionChanges(diversityOptionsChanged?.diversityOptions);
      form.validateFieldsAndScroll(err => {
        if (!err && !this.isZipcodeInvalid()) {
          if (diversityOptionsChanges?.selectedDiversityOptions?.length > 0) {
            this.setState({ isDiversityAcknowledgedVisible: true });
          } else {
            this.handleSubmit('SaveJob');
          }
        }
      });
    } else {
      this.handleSubmit('SaveJob');
    }
  };

  filterIndustry(value) {
    const { fetchIndustries } = this.props;
    fetchIndustries({
      SearchTerm: value,
      Size: 200,
    });
  }

  updateJobSummaryContent({ htmlContent, plainTextContent }) {
    const { jobDetails } = this.state;
    const { setTrynowFormData, trynowFormData } = this.props;
    if (setTrynowFormData && !_.isEmpty(trynowFormData)) {
      setTrynowFormData({
        Summary: htmlContent,
      });
    }
    jobDetails.Summary = htmlContent;
    this.setState({
      jobDetails,
      content: htmlContent,
      plainTextContent,
    });
  }

  updateDescriptionContent({ htmlContent, plainTextContent }) {
    const { jobDetails } = this.state;
    const { setTrynowFormData, trynowFormData } = this.props;
    if (setTrynowFormData && !_.isEmpty(trynowFormData)) {
      setTrynowFormData({
        Description: htmlContent,
      });
    }
    jobDetails.Description = htmlContent;
    this.setState({
      jobDetails,
      content: htmlContent,
      plainTextContent,
    });
  }

  validateJobSummary = (rule, value, callback) => {
    const { jobDetails } = this.state;
    const summary = jobDetails.Summary;
    const formattedInput = validateInputField(summary);
    if (formattedInput?.length > 1500) {
      callback('Job Summary cannot be more than 1500 characters');
    } else {
      callback();
    }
  };

  validateDescription(rule, value, callback) {
    const { jobDetails } = this.state;
    if (value?.htmlContent === undefined) {
      if (!jobDetails.Description) {
        callback('Please enter description');
      } else {
        callback();
      }
    } else if (!value?.plainTextContent.trim()) {
      callback('Please enter description');
    } else {
      callback();
    }
  }

  handleCountryChange(value) {
    const { featureToggleList } = this.props;
    if (!value) {
      this.setState({
        isCountryWideSearchEnabled: false,
      });
    }
    if (!featureToggleList.Diversity.AllowedCountryCodes.includes(value)) {
      this.clearDiversitySelection();
    }
  }

  fetchClientsInstantly = filter => {
    const { fetchClients } = this.props;
    fetchClients(filter);
  };

  selectCountry(value) {
    const { countryOptions } = this.state;
    this.setState({
      isCountryWideSearchEnabled: countryOptions.find(country => country.Iso2Code === value).StatsLevel === 'Country',
    });
  }

  uploadJobResume(jobId, file) {
    const { uploadedResumeFiles } = this.state;
    uploadedResumeFiles.push(file);
    this.setState({
      uploadedResumeFiles,
    });
  }

  removeJobResume(jobId, resumeId) {
    const { uploadedResumeFiles, removedResumeIds } = this.state;
    const index = uploadedResumeFiles.findIndex(file => file.uid === resumeId);
    if (index !== -1) {
      uploadedResumeFiles.splice(index, 1);
      this.setState({
        uploadedResumeFiles,
      });
    } else {
      removedResumeIds.push(resumeId);
      this.setState({
        removedResumeIds,
      });
    }
  }

  validateMaxTagLength = (rule, value, callback) => {
    if (value.length && value[value.length - 1].length > 100) {
      callback('Tag length should be less than 100 characters.');
    } else callback();
  };

  handleClientListScroll = e => {
    const { companyOptions } = this.state;
    const { utilities, form } = this.props;
    const searchTerm = form.getFieldValue('slectedClient');
    const clientTotal = _.get(utilities, 'clients.total', undefined);
    const clients = companyOptions || [];
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 && clients.length < clientTotal) {
      // scrollToEnd, do something!!!
      this.fetchClientsInstantly({ SearchTerm: searchTerm, From: clients.length, IsActive: true });
    }
  };

  getJobSummaryComponent = () => {
    const { jobDetails } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    if (this.getIsJobSummaryEnabled()) {
      return (
        <div className="job-form-job-Summary">
          <FormItem label={this.getFormItemLabelText('Job Summary')} colon={false} className="job-form-label-header">
            {getFieldDecorator('Summary', {
              rules: [{ validator: this.validateJobSummary }],
            })(
              <RichTextEditor
                showToolbar
                editorContent={{ htmlContent: jobDetails.Summary }}
                onChange={this.updateJobSummaryContent}
                imageSupport={false}
                className="richEditor-job-summary"
              />
            )}
          </FormItem>
        </div>
      );
    }
    return null;
  };

  getIsJobSummaryEnabled = () => {
    const state = store.getState();
    const userConfig = getConfig(state);
    return !(userConfig.Role !== 'Service Rep' && isPulseUser());
  };

  addClientListScollEvent = visible => {
    const clientMenuNode = document.querySelector('.client-menu-list');
    if (clientMenuNode) {
      if (visible) {
        clientMenuNode.addEventListener('scroll', this.handleClientListScroll);
      } else {
        clientMenuNode.removeEventListener('scroll', this.handleClientListScroll);
      }
    }
  };

  getFormItemLabelText = defaultLabel => {
    const { featureToggleList } = this.props;
    const { PaidJobService } = featureToggleList;
    const mandatoryLabelMark = <span className="form-label-mandatory-mark">*</span>;
    const labelTextWithMark = (
      <span>
        {mandatoryLabelMark}
        {defaultLabel}
      </span>
    );
    if (PaidJobService.IsEnabled) {
      return labelTextWithMark;
    }
    return defaultLabel;
  };

  getLatestHiringCompany = () => {
    const companyOptions = _.get(this.state, 'companyOptions', []);
    const companyOptionsDescOrder = _.orderBy(companyOptions, ['CreatedDate'], ['desc']);
    return _.get(companyOptionsDescOrder, [0, 'Name']);
  };

  getViewUrlLabel = () => {
    return (
      <span>
        External Job Posting URL
        <Tooltip title="URL added here, by default, gets mapped to the Job URL merge tag">
          <span>
            <Icon type="info-circle" className="apply-url-label-icon" />
          </span>
        </Tooltip>
      </span>
    );
  };

  onLocationTabChange = key => {
    const { form } = this.props;
    if (key.target.value === 'MultiLocation') {
      this.previousRange.location = form.getFieldValue('Range');
      form.setFieldsValue({ Range: this.previousRange.multiLocation ?? this.getDefaultRange() });
      this.setState({ isLocationChecked: false });
    } else {
      this.previousRange.multiLocation = form.getFieldValue('Range');
      this.setState({ isLocationChecked: true });
      if (this.previousRange.location) form.setFieldsValue({ Range: this.previousRange.location });
    }
  };

  getSaveDraftButton = (isLoading, isJobLimitExceeded) => {
    const { featureToggleList } = this.props;
    const { isSubmitButtonClicked } = this.state;
    const { PaidJobService, JobList } = featureToggleList;
    const isPaidJobServiceEnabled = PaidJobService.IsEnabled;
    const isJobListEnabled = JobList.IsEnabled;
    return isPaidJobServiceEnabled && isJobListEnabled ? (
      <div className="job-create-draft">
        <FormItem>
          <Button
            loading={isLoading && !isSubmitButtonClicked}
            className="job-create-draft-button"
            shape="round"
            disabled={isJobLimitExceeded || isSubmitButtonClicked}
            onClick={() => this.handleSubmit('SaveDraft')}
          >
            Save Draft
          </Button>
        </FormItem>
      </div>
    ) : null;
  };

  getSubmitButton = isLoading => {
    const { isSubmitButtonClicked } = this.state;
    const { featureToggleList } = this.props;
    const { PaidJobService } = featureToggleList;
    const submitButtonText = PaidJobService.IsEnabled ? 'Submit Job' : 'Submit';
    return (
      <Button
        type="primary"
        loading={isLoading && isSubmitButtonClicked}
        disabled={!isSubmitButtonClicked && isLoading}
        onClick={this.handleJobSubmit}
        shape="round"
      >
        {submitButtonText}
      </Button>
    );
  };

  getCancelButton = () => {
    const { handleBack, handleClose } = this.props;
    const { featureToggleList } = this.props;
    const { PaidJobService } = featureToggleList;
    return !PaidJobService.IsEnabled ? (
      <Button onClick={PaidJobService.IsEnabled ? handleBack : handleClose} shape="round">
        Cancel
      </Button>
    ) : null;
  };

  getMultiLocationCountryCode() {
    const { form } = this.props;
    const sourcingLocations = form.getFieldValue('SourcingLocations');
    return sourcingLocations[0]?.CountryCode;
  }

  render() {
    const {
      form,
      jobStatus,
      defaultStatusId,
      collaborators,
      toShareJob,
      fetchClients,
      userConfig,
      utilities,
      handleClose,
      apiStatus,
      isConfigLoaded,
      fetchJobConfig,
      toggleSettingsDrawer,
      isAutoEmailEnabled,
      isAutomationConfigured,
      sourceLimit,
      changeSourceLimit,
      downloadJobResume,
      titleSynonymStatusUpdate,
      tagsFetchStatusUpdate,
      featureToggleList,
      minSourceValue,
      handleBack,
      type,
      tryNowCandidateApiStatus,
      showSampleCandidates,
      role,
      currentClientId,
      currentClientName,
      AppName,
    } = this.props;
    const { getFieldDecorator } = form;
    const isLoading = apiStatus === 'INPROGRESS' || tryNowCandidateApiStatus === 'INPROGRESS';
    let industryMenuList = null;
    let tagMenuList = null;
    let titleSynonymList = null;
    const selectedCountry =
      form.getFieldValue('LocationType') === 'MultiLocation'
        ? this.getMultiLocationCountryCode()
        : form.getFieldValue('CountryCode');
    const isJobTagEnabled = _.get(featureToggleList, ['JobTag', 'IsEnabled'], false);
    const { PaidJobService } = featureToggleList;
    const isJobSharingEnabled = _.get(featureToggleList, ['ShareJob', 'IsEnabled'], false);
    const isJobStatusEnabled = _.get(featureToggleList, ['JobStatus', 'IsEnabled'], false);
    const isCustomizeJobUrlEnabled = featureToggleList?.CustomizeJobUrl?.IsEnabled;
    const initialRange = PaidJobService.IsEnabled ? 100 : _.get(userConfig, 'Distance.Distance', 50);
    const isCountryDiversityEnabled = this.validateDiversityEnabledCountry(selectedCountry);
    const isDiversityOptionsChanged = this.isDiversityOptionsChanged();
    const isDiversityDescriptionEnabed = isDiversityOptionsChanged?.diversityOptions?.find(
      val => val?.newValue === true
    );

    const {
      jobDetails,
      countryOptions,
      companyOptions,
      industryOptions,
      tagOptions,
      synonyms,
      isCountryWideSearchEnabled,
      removedResumeIds,
      uploadedResumeFiles,
      initialGender,
      initialVeteran,
      initialMinority,
      isDiversityAcknowledgedVisible,
      isLocationChecked,
    } = this.state;
    const isMultiLocationJob = jobDetails?.IsMultiLocationJob;
    const isMultiLocationVisible = isMultiLocationJob || featureToggleList.MultiLocationSourcing.IsEnabled;
    const isAdvanceSearchV2 = featureToggleList?.AdvanceSearchV2?.IsEnabled;
    const isPaidService = _.get(PaidJobService, ['IsEnabled'], false);
    const autoExpand = form.getFieldValue('AutoExpand');
    const isRemoteJob = form.getFieldValue('IsRemoteJob');
    const isMilesTooltipVisible =
      !autoExpand && !isCountryWideSearchEnabled && !(isRemoteJob && form.getFieldValue('LocationType') === 'Location');
    const locationType = form.getFieldValue('LocationType');
    const isDistanceDisabled = isCountryWideSearchEnabled || (isRemoteJob && locationType !== 'MultiLocation');
    const uploadButton = (
      <div>
        <div className="job-form-upload-button">
          <Icon type="upload" className="job-form-upload-icon" />
          <div className="job-form-upload-text">Upload Resume</div>
        </div>
        <div className="job-form-upload-hint-textcolor" style={{ fontSize: '12px' }}>
          Supported: doc .docx .pdf, text, html
        </div>
        <div className="job-form-upload-hint-textcolor" style={{ fontSize: '12px' }}>
          Max 1MB size | Files Limit 4
        </div>
      </div>
    );

    const menuList = jobStatus.map(status => (
      <Option value={status.Id} key={status.Name}>
        {status.Name}
      </Option>
    ));
    const jobStatusMenu = <Select className="job-form-status">{menuList}</Select>;

    if (tagOptions) {
      tagMenuList = tagOptions.map((tagOption, index) => (
        <Option value={tagOption.Name} key={`tag${index.toString()}`}>
          {tagOption.Name}
        </Option>
      ));
    }

    if (synonyms) {
      titleSynonymList = synonyms.map((synonym, index) => (
        <Option value={synonym.synonym} key={`tag${index.toString()}`}>
          {synonym.synonym}
        </Option>
      ));
    }

    const titleSynonymSelect = (
      <Select
        mode="tags"
        key="tag"
        placeholder="Select or Add title synonyms"
        className="select-dropdown"
        onSelect={() => purifySelectedOptions(form, 'Synonyms')}
        onFocus={this.handleSynonymVisibility}
        notFoundContent=" "
        dropdownRender={menuItems => {
          return (
            <SelectDropDownContent
              menuItems={menuItems}
              isLoading={titleSynonymStatusUpdate === 'INPROGRESS'}
              text="loading auto suggestions..."
            />
          );
        }}
        maxTagTextLength={20}
      >
        {titleSynonymList}
      </Select>
    );

    const tagSelect = (
      <Select
        mode="tags"
        key="tag"
        placeholder="Select or Add Tags"
        className="select-dropdown tag-select"
        onSelect={() => {
          purifySelectedOptions(form, 'Tags');
          this.handleTagDropdownSelect();
        }}
        onSearch={value => this.handleTagSearch(value)}
        onPopupScroll={this.handleTagDropdownScroll}
        maxTagTextLength={20}
        notFoundContent=" "
        dropdownRender={menuItems => (
          <SelectDropDownContent
            menuItems={menuItems}
            isLoading={tagsFetchStatusUpdate === 'INPROGRESS'}
            text="loading tags..."
          />
        )}
      >
        {tagMenuList}
      </Select>
    );

    if (industryOptions) {
      industryMenuList = industryOptions.map((industryOption, index) => (
        <Option value={industryOption} key={`industry${index.toString()}`}>
          {industryOption}
        </Option>
      ));
    }

    const industrySelect = (
      <Select mode="multiple" key="Industry" placeholder="Add Industry" className="select-dropdown industry-tags">
        {industryMenuList}
      </Select>
    );

    const clientMenuList =
      (companyOptions || []).length > 0 ? (
        <Menu style={{ maxHeight: '264px', overflowY: 'auto' }} className="client-menu-list">
          {companyOptions.map(client => (
            <Menu.Item key={client.Id} onClick={() => form.setFieldsValue({ selectedClient: client.Name })}>
              {client.Name}
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <div />
      );
    const isJobLimtExceeded = userConfig?.JobsCreated >= userConfig?.JobLimit;
    const clientAddition = role !== 'Recruiter' ? <ClientAddition /> : null;

    if (jobDetails) {
      const isJobActivationAllowed =
        type === 'create'
          ? getIsJobActivationLimitExceeded(userConfig)
          : getIsJobActivationAllowed(userConfig, jobDetails.HadActivated);
      return (
        <div className={PaidJobService.IsEnabled ? 'isPaidJob' : ''}>
          {isConfigLoaded && !isJobActivationAllowed ? (
            <div className="user-alert-wrapper-jobform">
              <Alert message={getJobLimitExceededAlertMessage(userConfig.JobLimit, false)} type="info" showIcon />
            </div>
          ) : null}
          <div>
            {PaidJobService.IsEnabled && handleBack && (
              <div className="job-form-progress-step">
                <ProgressSteps current={0} showSampleCandidates={showSampleCandidates} />
              </div>
            )}
            <Form className="form-div-class" hideRequiredMark autoComplete="off">
              <div className="form-body">
                <span role="presentation" onClick={PaidJobService.IsEnabled ? handleBack : handleClose}>
                  <Icon type="close" theme="outlined" className="close-icon" />
                </span>
                <FormItem label={this.getFormItemLabelText('Job Title')} colon={false} required="true">
                  {getFieldDecorator('JobTitle', {
                    rules: [{ required: true, validator: validateJobTitle }],
                    initialValue: jobDetails.JobTitle,
                  })(<Input placeholder="Enter Job Title" onKeyUp={this.callForTitleSynonymsAfterDelay} />)}
                </FormItem>
                <FormItem label="Job Title Synonyms" colon={false} className="job-synonyms-header">
                  {getFieldDecorator('Synonyms', {
                    initialValue: jobDetails.Synonyms
                      ? _.uniq(jobDetails.Synonyms.filter(synonym => synonym !== ''))
                      : [],
                  })(titleSynonymSelect)}
                </FormItem>

                {isJobTagEnabled ? (
                  <FormItem
                    label={this.getFormItemLabelText(
                      <span className={styles.tagsAndInfoIcon}>
                        <span>Tags</span>
                        <InfoIconWithTooltip tooltipTitle="Tag length can not exceed 100 characters" />
                      </span>
                    )}
                    colon={false}
                    className="job-form-label-header"
                  >
                    {getFieldDecorator('Tags', {
                      rules: [{ validator: this.validateMaxTagLength }],
                      initialValue: jobDetails.Tags ? _.uniq(jobDetails.Tags.filter(TagItem => TagItem !== '')) : [],
                    })(tagSelect)}
                  </FormItem>
                ) : null}
                {isJobSharingEnabled || isJobStatusEnabled ? (
                  <Row gutter={20} className="collaborators-jobstatus job-form-label-header">
                    <Col span={10}>
                      {isJobSharingEnabled ? (
                        <FormItem label={this.getFormItemLabelText('Share')} colon={false}>
                          <CollaboratorsContainer
                            collaborators={collaborators}
                            jobId={jobDetails.JobId}
                            parent="job-form"
                            toShareJob={toShareJob}
                          />
                        </FormItem>
                      ) : null}
                    </Col>
                    <Col span={6}>
                      {isJobStatusEnabled ? (
                        <FormItem label={this.getFormItemLabelText('Job Status')} colon={false}>
                          {getFieldDecorator('StatusId', {
                            initialValue: jobDetails.StatusId ? jobDetails.StatusId : defaultStatusId,
                          })(jobStatusMenu)}
                        </FormItem>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
                {featureToggleList?.JobSummary.IsEnabled ? this.getJobSummaryComponent() : null}
                <div className="job-form-job-description">
                  <FormItem
                    label={this.getFormItemLabelText('Job Description')}
                    colon={false}
                    className="job-form-label-header"
                  >
                    {getFieldDecorator('Description', {
                      rules: [{ validator: this.validateDescription }],
                    })(
                      <RichTextEditor
                        showToolbar
                        editorContent={{ htmlContent: jobDetails.Description }}
                        onChange={this.updateDescriptionContent}
                        imageSupport={false}
                        className="richEditor-job-description"
                      />
                    )}
                  </FormItem>
                </div>

                {isCustomizeJobUrlEnabled ? (
                  <FormItem colon={false} label={this.getViewUrlLabel()}>
                    {getFieldDecorator('ViewUrl', {
                      initialValue: type === 'create' ? null : jobDetails.ViewUrl,
                      rules: [{ validator: validateViewUrl }],
                    })(<Input placeholder="Add URL" />)}
                  </FormItem>
                ) : null}

                <Row className="job-form-section-header">
                  <div className="job-form-section-header-div">
                    <Col span={12} style={{ flex: 1 }}>
                      {!PaidJobService.IsEnabled ? (
                        <FormItem
                          label={this.getFormItemLabelText(
                            <span className={styles.jobFormClientDataInfo}>
                              <span> Client</span>

                              <InfoIconWithTooltip tooltipTitle="Client data is the first layer of insight for Arya and must be created before a job for that client can be built." />
                            </span>
                          )}
                          colon={false}
                        >
                          {getFieldDecorator('selectedClientId', {
                            initialValue: jobDetails.ClientId,
                            rules: [{ required: true, message: 'Please select company' }],
                          })(
                            <ClientSelect
                              clients={companyOptions || []}
                              clientTotal={_.get(utilities, 'clients.total', undefined)}
                              fetchClients={fetchClients}
                              selectedClientId={currentClientId ?? jobDetails.ClientId}
                              initialClientName={currentClientName ?? jobDetails.Client}
                              clientAddition={clientAddition}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem
                          label={this.getFormItemLabelText('Hiring Company')}
                          style={{ width: '250px' }}
                          colon={false}
                        >
                          <Dropdown
                            overlay={clientMenuList}
                            trigger={['click']}
                            onVisibleChange={this.addClientListScollEvent}
                            forceRender
                          >
                            {getFieldDecorator('selectedClient', {
                              initialValue: jobDetails.Client || this.getLatestHiringCompany(),
                              rules: [{ required: true, message: 'Please select Hiring Company' }],
                            })(
                              <Input
                                placeholder="Select a company"
                                allowClear
                                onChange={e => {
                                  this.fetchClientsDelayed({ SearchTerm: e.target.value, From: 0 });
                                }}
                              />
                            )}
                          </Dropdown>
                        </FormItem>
                      )}

                      <div className={styles.jobFormLabelHeader} style={{ display: 'flex', gap: '10px' }}>
                        <div className={styles.experience}>
                          <FormItem label="Min Overall Exp" className="job-createfillter" colon={false}>
                            {getFieldDecorator('MinExperience', {
                              initialValue: jobDetails.MinExperience !== -1 ? jobDetails.MinExperience : undefined,
                              rules: [
                                {
                                  validator: (rule, value, callback) =>
                                    validateMinExperience(form, value, callback, isPaidService),
                                },
                              ],
                            })(<InputNumber placeholder="0 years" min={0} max={50} label="Min Overall Exp" />)}
                          </FormItem>
                        </div>

                        <div className={styles.experience}>
                          <FormItem label="Max Overall Exp" className="job-createfillter" colon={false}>
                            {getFieldDecorator('MaxExperience', {
                              initialValue: jobDetails.MaxExperience !== -1 ? jobDetails.MaxExperience : undefined,
                              rules: [
                                {
                                  validator: (rule, value, callback) =>
                                    validateMaxExperience(form, value, callback, isPaidService),
                                },
                              ],
                            })(<InputNumber placeholder="0 years" min={0} max={50} label="Max Overall Exp." />)}
                          </FormItem>
                        </div>
                        {!PaidJobService.IsEnabled ? (
                          <div className={styles.totalOpenings}>
                            <FormItem
                              label={this.getFormItemLabelText('Total Openings')}
                              className="job-createfillter"
                              colon={false}
                            >
                              {getFieldDecorator('Openings', {
                                initialValue: jobDetails.Openings,
                              })(<InputNumber placeholder="0" min={0} label="Total Openings" />)}
                            </FormItem>
                          </div>
                        ) : null}
                      </div>
                      <div className={styles.experienceNote}>(Not role specific)</div>
                    </Col>

                    {/* {!PaidJobService.IsEnabled ? (
                      <Col className="job-resume-col">
                        <FormItem label={this.getFormItemLabelText('Upload Resume')} colon={false}>
                          <JobResume
                            uploadButton={uploadButton}
                            jobId={jobDetails.JobId}
                            resumes={[
                              ...(jobDetails.Resumes
                                ? jobDetails.Resumes.filter(resume => !removedResumeIds.includes(resume.Id))
                                : []
                              ).map(resume => ({
                                uid: resume.Id,
                                name: resume.Name,
                                type: resume.MediaType,
                                status: 'done',
                              })),
                              ...uploadedResumeFiles,
                            ]}
                            uploadJobResume={this.uploadJobResume}
                            removeJobResume={this.removeJobResume}
                            downloadJobResume={downloadJobResume}
                          />
                        </FormItem>
                      </Col>
                    ) : null} */}
                  </div>
                </Row>

                <div className={styles.locationWrapper}>
                  <div className={styles.locationTitle}>Sourcing Details</div>
                  <div className={styles.locationDescription}>
                    {`These details will be used by ${AppName} to source the right candidates`}
                  </div>{' '}
                  {!isPaidService && isAdvanceSearchV2 ? (
                    <>
                      <div className={styles.locationLabel}>Location</div>
                      <LocationWrapper
                        form={form}
                        layers="neighbourhood,borough,locality,localadmin,county,macrocounty"
                      >
                        <div className={styles.remoteJobInput}>
                          <RemoteJob
                            initialValue={jobDetails.IsRemoteJob}
                            setValidationStatus={this.setValidationStatus}
                            isAdvanceSearchV2
                          />

                          <span className={styles.remoteJobInfo}>
                            <span className={styles.remoteJob}>Remote Job</span>
                            <InfoIconWithTooltip tooltipTitle={`${AppName} will source throughout the country`} />
                          </span>
                        </div>
                      </LocationWrapper>
                    </>
                  ) : null}
                  {isAdvanceSearchV2 ? (
                    <>
                      {form.getFieldDecorator('LocationType', { initialValue: 'MultiLocation' })(<div></div>)}
                      <FormItem
                        colon={false}
                        validateStatus={this.state.validationStatus}
                        help={this.state.validationStatusErrorMessage}
                      >
                        {form.getFieldDecorator('SourcingLocations', {
                          initialValue: [{ Id: uuid.v4(), CountryCode: 'US', IsPrimaryLocation: true }],
                          rules: [
                            {
                              required: true,
                              validator: (rule, value, callback) =>
                                this.validateSourcingLocation(
                                  rule,
                                  value,
                                  callback,
                                  isRemoteJob,
                                  jobDetails.IsRemoteJob
                                ),
                            },
                          ],
                        })(
                          <EnhancedJobMultiLocationInputContainer
                            form={form}
                            countryOptions={countryOptions}
                            defaultSourcingLocations={jobDetails.SourcingLocations}
                            featureToggleList={featureToggleList}
                            AppName={AppName}
                            initialRange={jobDetails.Range || initialRange}
                            isDistanceDisabled={isRemoteJob}
                          />
                        )}
                      </FormItem>
                    </>
                  ) : (
                    <>
                      {getFieldDecorator('LocationType')(
                        <Radio.Group onChange={this.onLocationTabChange}>
                          <Radio
                            value="Location"
                            key="Location"
                            className={isMultiLocationVisible ? styles.locations : styles.locationsHide}
                          >
                            <span className={isLocationChecked ? styles.selectedLocation : null}>Location</span>
                          </Radio>
                          {isMultiLocationVisible ? (
                            <Radio value="MultiLocation" key="MultiLocation" className={styles.locations}>
                              <span className={!isLocationChecked ? styles.selectedLocation : null}>Multilocation</span>
                            </Radio>
                          ) : null}
                        </Radio.Group>
                      )}
                      <JobLocationInput
                        getFormItemLabelText={this.getFormItemLabelText}
                        countryOptions={countryOptions}
                        form={form}
                        jobDetails={jobDetails}
                        isCountryWideSearchEnabled={isCountryWideSearchEnabled}
                        isPaidJobServiceEnabled={isPaidService}
                        callbacks={{
                          selectCountryCallback: this.selectCountry,
                          countryChangeCallback: this.handleCountryChange,
                        }}
                        AppName={AppName}
                      />
                      {getFieldDecorator('SourcingLocations')(
                        <JobMultiLocationInputContainer
                          form={form}
                          countryOptions={countryOptions}
                          defaultSourcingLocations={jobDetails.SourcingLocations}
                        />
                      )}
                    </>
                  )}
                  {isPaidService === true ? (
                    <PulseMilesInput
                      form={form}
                      label={this.getFormItemLabelText('Specify the maximum miles for sourcing')}
                      initialRange={jobDetails.Range || initialRange}
                      isSliderDisabled={autoExpand || isDistanceDisabled}
                      isAutoExpandDisabled={isDistanceDisabled}
                      isTooltipVisible={isMilesTooltipVisible}
                    />
                  ) : (
                    <>
                      {!isAdvanceSearchV2 ? (
                        <MilesInput
                          form={form}
                          label={this.getFormItemLabelText('Specify the maximum miles for sourcing')}
                          initialRange={jobDetails.Range || initialRange}
                          maxAllowedRange={999}
                          isDistanceDisabled={isDistanceDisabled}
                          distanceFieldName="Range"
                          inputSize="default"
                        />
                      ) : null}
                    </>
                  )}
                </div>

                <FormItem label="Industry" colon={false} className="job-form-section-header">
                  {getFieldDecorator('Industries', {
                    initialValue: jobDetails.Industries
                      ? jobDetails.Industries.filter(industry => industry !== '')
                      : [],
                  })(industrySelect)}
                </FormItem>
                {/* {PaidJobService.IsEnabled || role === 'Service Rep' ? (
                  <div className="job-form-component-must-have">
                    <FormItem label={this.getFormItemLabelText('Must Have Skills')} colon={false}>
                      {getFieldDecorator('MustHaves', {
                        initialValue: jobDetails.MustHaves
                          ? jobDetails.MustHaves.split(',').filter(mustHave => mustHave !== '')
                          : [],
                      })(mustHaveSkillsSelect)}
                    </FormItem>
                  </div>
                ) : null} */}
                {/* {PaidJobService.IsEnabled || role === 'Service Rep' ? (
                  <div className="job-form-component-nice-have">
                    <FormItem label={this.getFormItemLabelText('Nice to Have Skills')} colon={false}>
                      {getFieldDecorator('NiceToHaves', {
                        initialValue: jobDetails.NiceToHaves
                          ? jobDetails.NiceToHaves.split(',').filter(niceToHave => niceToHave !== '')
                          : [],
                      })(niceToHaveSkillsSelect)}
                    </FormItem>
                  </div>
                ) : null} */}
                {/* {PaidJobService.IsEnabled || role === 'Service Rep' ? (
                  <div className="job-form-component-qualification">
                    <FormItem label={this.getFormItemLabelText('Educational Qualification')} colon={false}>
                      {getFieldDecorator('EducationalQualifications', {
                        initialValue: jobDetails.EducationalQualifications
                          ? jobDetails.EducationalQualifications.split(',').filter(
                              qualification => qualification !== ''
                            )
                          : [],
                      })(educationQualificationsSelect)}
                    </FormItem>
                  </div>
                ) : null} */}
                {PaidJobService.IsEnabled ? (
                  <p>
                    By clicking Submit Job, I agree that Leoforce may publish and/or distribute my job advertisement on
                    its site and through its distribution partners if Full-Service is selected, or if subsequent upgrade
                    to Full-Service is authorized by me.
                  </p>
                ) : null}
                {_.get(featureToggleList, ['JobConfiguration', 'IsEnabled'], false) ? (
                  <div className="job-config-container">
                    <div className="connect-configuration">
                      <FormItem label="Connect Configuration" colon={false}>
                        <div className="connect-config-container ">
                          <div className="connect-config">
                            <div className="connect-config-email-label">Email Automation</div>
                            <div className="automation-status-tag">
                              <Tag key="autoEmailStatusTag" color={isAutoEmailEnabled ? '#13C26B' : null}>
                                {isAutoEmailEnabled ? 'ON' : 'OFF'}
                              </Tag>
                            </div>
                          </div>
                          {/* <div className="connect-config">
                          <div className="connect-config-message-label">Text Automation</div>
                          <div className="automation-status-tag">
                            <Tag key="autoMessageStatusTag" color={isAutoMessageEnabled ? '#13C26B' : null}>
                              {isAutoMessageEnabled ? 'ON' : 'OFF'}
                            </Tag>
                          </div>
                        </div> */}
                          <Button shape="round" className="edit-config-button" onClick={toggleSettingsDrawer}>
                            <div className="edit-config-button-text">
                              <span>{isAutomationConfigured ? 'Edit Configuration' : 'Configure Automation'}</span>
                            </div>
                          </Button>
                        </div>
                      </FormItem>
                    </div>
                    <FormItem
                      className="job-form-label-header"
                      label={this.getFormItemLabelText(
                        <span>
                          Single list candidate source limit
                          <span className="job-form-sourcelimit-info">
                            <InfoIconWithTooltip tooltipTitle="This represent the number of candidates that you will see in the single list" />
                          </span>
                        </span>
                      )}
                      colon={false}
                    >
                      <SourceLimitSlider
                        minSourceValue={minSourceValue}
                        form={form}
                        jobSourceLimit={sourceLimit}
                        defaultSourceLimit={_.get(userConfig, 'DefaultSourceLimit', undefined)}
                        maxLimit={_.get(userConfig, 'MaxSourceLimit', undefined)}
                        fetchJobConfig={fetchJobConfig}
                        jobId={jobDetails.JobId}
                        changeSourceLimit={changeSourceLimit}
                      />
                    </FormItem>
                  </div>
                ) : null}
                {featureToggleList?.Diversity.IsEnabled ? (
                  <div className={styles.diversityBlock}>
                    <span className={styles.diversityHeader}>
                      Diversity Options
                      <span className="job-form-sourcelimit-info">
                        <InfoIconWithTooltip
                          overlayClassName="diversity-ack-info"
                          tooltipTitle="Diversity is a business management concept under which employers voluntarily promote an inclusive workplace. Many employers have concluded that a diverse workforce makes a company stronger, more profitable, and a better place to work, and they implement diversity initiatives, subject to equal employment opportunity (EEO) laws, for competitive reasons rather than in response to discrimination, although such initiatives may also help to avoid discrimination. EEO means freedom from discrimination on the basis of protected classes such as race, color, sex (including pregnancy, transgender status, and sexual orientation), national origin, religion, age, disability or genetic information. EEO rights for all employees and job applicants are guaranteed by federal and state fair employment laws and are enforced by the Equal Employment Opportunity Commission (EEOC) and its state counterparts."
                        />
                      </span>
                    </span>
                    <div className={styles.diversityLabelDescription}>
                      (Diversity promotion only available for{' '}
                      {featureToggleList.Diversity.AllowedCountryCodes?.join(',')})
                    </div>
                    <div className={styles.diversityPreferences}>
                      <div className={styles.diversityPreference}>
                        <div className={styles.diversityOptions}>
                          <FormItem colon={false}>
                            {getFieldDecorator('Gender', {
                              initialValue: initialGender,
                            })(
                              <div className={styles.options}>
                                <div className={styles.diversityLabel}>Female</div>
                                <div>
                                  <Tooltip title={diversityOptionTooltip} overlayClassName="diversity-tooltip-text">
                                    <Checkbox
                                      checked={form.getFieldValue('Gender')}
                                      onChange={event => this.handleDiversityOptionsChange('Gender', event)}
                                      disabled={!isCountryDiversityEnabled}
                                    >
                                      Promote
                                    </Checkbox>
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          </FormItem>
                        </div>
                      </div>
                      <div className={styles.diversityPreference}>
                        <div className={styles.diversityOptions}>
                          <FormItem colon={false}>
                            {getFieldDecorator('Veteran', {
                              initialValue: initialVeteran,
                            })(
                              <div className={styles.options}>
                                <div className={styles.diversityLabel}>Veteran</div>
                                <div>
                                  <Tooltip title={diversityOptionTooltip} overlayClassName="diversity-tooltip-text">
                                    <Checkbox
                                      checked={form.getFieldValue('Veteran')}
                                      onChange={event => this.handleDiversityOptionsChange('Veteran', event)}
                                      disabled={!isCountryDiversityEnabled}
                                    >
                                      Promote
                                    </Checkbox>
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          </FormItem>
                        </div>
                      </div>
                      <div className={styles.diversityMinorityPreference}>
                        <div className={styles.diversityOptions}>
                          <FormItem colon={false}>
                            {getFieldDecorator('Minority', {
                              initialValue: initialMinority,
                            })(
                              <div className={styles.options}>
                                <div className={styles.minorityPreference}>
                                  <div className={styles.diversityLabel}>Minority</div>
                                  <div className={styles.diversityLabelDescription}>
                                    (African American, Latinx, etc. )
                                  </div>
                                </div>
                                <div>
                                  <Tooltip title={diversityOptionTooltip} overlayClassName="diversity-tooltip-text">
                                    <Checkbox
                                      checked={form.getFieldValue('Minority')}
                                      onChange={event => this.handleDiversityOptionsChange('Minority', event)}
                                      disabled={!isCountryDiversityEnabled}
                                    >
                                      Promote
                                    </Checkbox>
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          </FormItem>
                        </div>
                      </div>
                    </div>
                    <div className={styles.diversityPolicyBlock}>
                      <FormItem label="Diversity, Equity and Inclusion Policy Description (Optional)">
                        {getFieldDecorator('DiversityDescription', {
                          rules: [{ validator: validateTextArea }],
                          initialValue: jobDetails.DiversityDescription,
                        })(
                          <Input.TextArea
                            placeholder="Type or paste your content here"
                            disabled={!isCountryDiversityEnabled || !isDiversityDescriptionEnabed}
                          />
                        )}
                      </FormItem>
                    </div>
                  </div>
                ) : null}
              </div>
              {ReactDOM.createPortal(
                <Affix offsetBottom={0} className={styles.jobFormFooterAffix}>
                  <div className={styles.jobformBottomAffix}>
                    <div className="job-create-submit-draft-button">
                      {this.getSaveDraftButton(isLoading, isJobLimtExceeded)}
                      <div className={styles.cancelButton}>
                        {this.getCancelButton()}
                        <FormItem>
                          {this.getSubmitButton(isLoading)}
                          <Modal
                            title="U.S. EQUAL EMPLOYMENT OPPORTUNITY"
                            centered
                            visible={isDiversityAcknowledgedVisible}
                            onOk={() => {
                              this.setState({ isDiversityAcknowledgedVisible: false });
                              this.handleSubmit('SaveJob');
                            }}
                            onCancel={this.toggleDiversityAcknowledgement}
                          >
                            <p>
                              I acknowledge that the law prohibits employers from treating persons differently in the
                              recruitment and hiring process based on their protected characteristics (i.e., race,
                              color, sex, national origin, religion, age, disability, or genetic information). I
                              understand and agree that I will not use selection criteria that have a significant
                              discriminatory effect without being able to prove that the criteria are job-related and
                              consistent with business necessity.
                            </p>
                          </Modal>
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </Affix>,
                document.getElementById('body-content')
              )}
            </Form>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

JobForm.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})),
  jobDetails: PropTypes.shape({
    Synonyms: PropTypes.arrayOf(PropTypes.string),
    Industries: PropTypes.arrayOf(PropTypes.string),
    Location: PropTypes.string,
    CountryCode: PropTypes.string,
    Range: PropTypes.number,
    StatusId: PropTypes.number,
    Resumes: PropTypes.arrayOf(PropTypes.shape({})),
    GenderPreferences: PropTypes.arrayOf(PropTypes.shape({})),
    CategoryPreferences: PropTypes.arrayOf(PropTypes.shape({})),
  }),

  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
  }).isRequired,
  utilities: PropTypes.shape({
    titleSynonyms: PropTypes.arrayOf(PropTypes.string),
  }),
  defaultStatusId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchTitleSynonyms: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchClients: PropTypes.func.isRequired,
  fetchIndustries: PropTypes.func.isRequired,
};

JobForm.defaultProps = {
  jobDetails: {
    Title: '',
    Synonyms: undefined,
    Description: '',
    Company: '',
    Openings: '',
    MinExperience: '',
    MaxExperience: '',
    StatusId: undefined,
    Resumes: [],
    Zipcode: '',
    ClientId: '',
    CountryCode: undefined,
    Industries: [],
    GenderPreferences: [],
    CategoryPreferences: [],
    Summary: '',
  },
  utilities: {
    titleSynonyms: undefined,
    countries: [],
    locations: [],
    zipCodes: [],
    clients: [],
  },
  jobStatus: [],
};
const JobFormComponent = Form.create()(JobForm);

export default JobFormComponent;
