import _ from 'lodash';
import uuid from 'uuid';

export function getIsCountryWideSearchEnabled(countries, countryCode) {
  const currentCountryStatsLevel = countries.find(country => country.Iso2Code === countryCode)?.StatsLevel;
  return currentCountryStatsLevel?.toLowerCase() === 'country';
}

export const locationMenuList = currentLocations => {
  if (!currentLocations?.length) return currentLocations;
  return _.uniq(
    currentLocations.map(locationOption => {
      let optionValue = locationOption.City ? `${locationOption.City}, ` : '';
      optionValue = locationOption.State ? optionValue + locationOption.State : optionValue;
      optionValue = _.trim(optionValue, ', ');
      return optionValue;
    })
  );
};
export const locationMenuListWithStateCode = currentLocations => {
  if (!currentLocations?.length) return currentLocations;
  const filteredLocationList = _.uniq(
    currentLocations.map(locationOption => {
      let optionValue =
        locationOption.City && locationOption.StateCode ? `${locationOption.City}, ${locationOption.StateCode} ` : '';
      optionValue = _.trim(optionValue, ', ');
      return optionValue;
    })
  );
  return filteredLocationList.filter(location => {
    return location !== '';
  });
};

export const getActiveSourcingLocations = sourcingLocations => {
  if (!sourcingLocations?.length) return [];
  return sourcingLocations;
};

export const getSourcingLocations = (sourcingLocations, distance) => {
  if (!sourcingLocations?.length) return [];
  const result = [];
  sourcingLocations.forEach(sourcingLocation => {
    if (sourcingLocation?.Region?.Locations) {
      sourcingLocation.Region.Locations.forEach(location => {
        result.push({
          CountryCode: sourcingLocation.CountryCode,
          RegionId: sourcingLocation.Region.RegionId,
          RegionName: sourcingLocation.Region.RegionName,
          Location: location.Location,
          DistanceUnit: 'Miles',
          Distance: distance ?? sourcingLocation.Distance,
        });
      });
    } else if (sourcingLocation.CountryCode && sourcingLocation.isStateWideSearchChecked) {
      result.push({
        State: sourcingLocation.Location,
        CountryCode: sourcingLocation.CountryCode,
        IsPrimaryLocation: sourcingLocation.IsPrimaryLocation,
        DistanceUnit: 'Miles',
        Distance: sourcingLocation.Distance ?? distance,
      });
    } else if (sourcingLocation.CountryCode) {
      result.push({
        Location: sourcingLocation.Location,
        State: sourcingLocation.State,
        PostalCode: sourcingLocation.PostalCode ?? sourcingLocation.Postalcode,
        CountryCode: sourcingLocation.CountryCode,
        IsPrimaryLocation: sourcingLocation.IsPrimaryLocation,
        DistanceUnit: 'Miles',
        Distance: distance ?? sourcingLocation.Distance,
      });
    }
  });
  return _.uniqBy(result, location => {
    const clonedLocation = { ...location };
    delete clonedLocation.IsPrimaryLocation;
    return JSON.stringify(clonedLocation);
  });
};

export const getGroupedLocations = (sourcingLocations = []) => {
  const groupedRegions = {};
  const locations = sourcingLocations
    .filter(sourcingLocation => !sourcingLocation.RegionId)
    .map(sourcingLocation => ({ ...sourcingLocation, Region: undefined, Id: uuid.v4() }));
  sourcingLocations
    .filter(sourcingLocation => sourcingLocation.RegionId)
    .forEach(sourcingLocation => {
      if (!groupedRegions[sourcingLocation.RegionId]) {
        groupedRegions[sourcingLocation.RegionId] = {
          Id: uuid.v4(),
          CountryCode: sourcingLocation.CountryCode,
          Region: {
            RegionId: sourcingLocation.RegionId,
            RegionName: sourcingLocation.RegionName,
            Locations: [{ Location: sourcingLocation.Location }],
          },
          Distance: sourcingLocation.Distance,
          DistanceUnit: 'Miles',
        };
      } else {
        groupedRegions[sourcingLocation.RegionId].Region.Locations.push({
          Location: sourcingLocation.Location,
        });
      }
    });
  const resultArray = Object.values(groupedRegions).concat(locations);
  const updatedResultArray = resultArray.map(item => ({
    ...item,
    isActive: true,
  }));
  return updatedResultArray;
};

export const getStateSuggestions = locations => {
  if (!locations?.length) return locations;
  return _.uniq(locations.map(location => location.state?.name).filter(x => x));
};
export const getOptionValueFromValue = value => {
  let _value = value.city ? `${value.city}, ` : '';
  _value = value.state ? _value + value.state : _value;
  _value = value.zipcode ? `${_value}, ${value.zipcode}` : _value;
  _value = _.trim(_value, ', ');
  return _value;
};
export const getLocationOptions = (currentLocations, isAdvanceSearchV2Enabled = false) => {
  return Array.from(
    new Set(
      currentLocations
        ?.map(locationOption => {
          const optionValue = {
            city: locationOption?.city,
            state: locationOption.state?.name,
            ...(!isAdvanceSearchV2Enabled ? {} : { zipcode: locationOption?.zipcode }),
          };
          const optionLabel = [];
          if (locationOption.city) optionLabel.push(locationOption.city);
          if (locationOption.state) optionLabel.push(locationOption.state?.name);
          if (locationOption?.zipcode && isAdvanceSearchV2Enabled) optionLabel.push(locationOption.zipcode);
          return { label: optionLabel.join(', '), value: JSON.stringify(optionValue) };
        })
        .map(JSON.stringify)
    )
  ).map(JSON.parse);
};

export const getDefaultLocationOption = location => {
  try {
    const parsedLocation = JSON.parse(location);
    const optionLabel = [];
    if (parsedLocation.city) optionLabel.push(parsedLocation.city);
    if (parsedLocation.state) optionLabel.push(parsedLocation.state);
    if (parsedLocation.zipcode) optionLabel.push(parsedLocation.zipcode);
    return { label: optionLabel.join(', '), value: location };
  } catch {
    return { label: location, value: location };
  }
};

export const getDisplayedLocations = ({ currentLocation, locationSuggestions }) => {
  const locationOptions = getLocationOptions(locationSuggestions);
  const defaultLocationOption = currentLocation ? [getDefaultLocationOption(currentLocation)] : [];
  return _.uniqBy([...locationOptions, ...defaultLocationOption], 'label');
};

export const parseLocation = location => {
  try {
    return location.includes('city') && location.includes('state')
      ? `${JSON.parse(location).city}${', '}${JSON.parse(location).state}`
      : JSON.parse(location).state;
  } catch (e) {
    return location;
  }
};

export const getSourcingLocationsOnFeatureToggle = (sourcingLocations, isRemoteLocation, isAdvanceSearchV2) => {
  let modifiedSourcingLocations;
  if (isAdvanceSearchV2 && isRemoteLocation) {
    const modifiedSourcingLocation =
      sourcingLocations.find(x => x.IsPrimaryLocation) ??
      sourcingLocations.find(x => x.Region === null || x.Region === undefined);
    modifiedSourcingLocations = [{ ...modifiedSourcingLocation, IsPrimaryLocation: true }];
  } else if (isAdvanceSearchV2) {
    modifiedSourcingLocations = sourcingLocations.filter(x => x.isActive);
  } else {
    modifiedSourcingLocations = sourcingLocations;
  }
  return modifiedSourcingLocations;
};
