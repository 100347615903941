import React from 'react';
import { Tooltip, Button, Popover } from 'antd';
import { connect } from 'react-redux';
import { getConfig } from '../../../Reducers/ConfigReducer';
import {
  generateAryaActivatePayloadWithoutPersonalInfo,
  aryaRankingDisabledPopover,
} from '../../../Utils/ManualSearchUtils';
import { getIsReactivationAllowed, getManualSearchCriteria } from '../../../Reducers/ManualSearchReducer';
import ActivationDialog from '../../ActivationDialog/ActivationDialog';
import styles from './ReactivateArya.module.scss';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer';
import { InfoIconV2 } from '../../../Icons/AryaIcons';

const mapStateToProps = (state, props) => ({
  userConfig: getConfig(state),
  unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
  isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
  featureToggleList: getFeatureToggleList(state),
});

function ReactivateArya(props) {
  const {
    jobId,
    currentJobDetails = {},
    userConfig,
    unsavedCriteria,
    isReactivationAllowed,
    AppName,
    featureToggleList,
    smartRecruiterVersion,
    isDragging = false,
    onStatusChange,
    activeTab,
  } = props;
  const [activationDialogVisibility, setActivationDialogVisibility] = React.useState(false);

  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  let reactivateArya = `Reactivate ${AppName}`;
  let reactivationNotAllowedTooltipContent =
    'After criteria has been adjusted, an Advanced Search must be performed before reactivating.';
  if (isAdvancedSearchV2Enabled || smartRecruiterVersion === 'v2') {
    reactivationNotAllowedTooltipContent =
      'After criteria has been adjusted, an Advanced Search must be performed before score and rank';
    reactivateArya = 'Score and Rank';
  }

  const unsavedCriteriaWithoutPersonalInfo = generateAryaActivatePayloadWithoutPersonalInfo({
    criteria: unsavedCriteria,
  });

  const closeActivationDialog = () => {
    setActivationDialogVisibility(false);
  };

  const onReactivateAryaClick = e => {
    if (isReactivationAllowed && !isDragging) {
      setActivationDialogVisibility(true);
    }
  };

  let showReactivateAryaButton = true;

  const { IsAllowed: isAryaRankingAllowed, IsEnabled: isAryaRankingEnabled } = featureToggleList?.AryaRanking || {};
  if (isAdvancedSearchV2Enabled) {
    showReactivateAryaButton = isAryaRankingEnabled || smartRecruiterVersion === 'v2';
  }

  let isReactivateAryaButtonDisabled = !isReactivationAllowed;
  if (isAdvancedSearchV2Enabled && !isAryaRankingAllowed) {
    isReactivateAryaButtonDisabled = smartRecruiterVersion !== 'v2';
  }

  const buttonClassNames = [
    styles.reactivateAryaButton,
    isAdvancedSearchV2Enabled ? styles.draggableReactivateButton : '',
    isReactivateAryaButtonDisabled ? styles.disabled : '',
  ]
    .filter(Boolean)
    .join(' ');

  const buttonTextClassNames = [
    styles.reactivateAryaButtonText,
    isAdvancedSearchV2Enabled ? styles.draggableReactivateButtonText : '',
  ].join(' ');

  const reactivateAryaButton = (
    <div className={styles.reactivateArya} style={{ display: showReactivateAryaButton ? 'block' : 'none' }}>
      <Tooltip
        title={isReactivationAllowed ? '' : reactivationNotAllowedTooltipContent}
        mouseEnterDelay={0.5}
        defaultOpen
        overlayStyle={{ position: 'fixed' }}
      >
        <Button
          className={buttonClassNames}
          type="secondary"
          shape="round"
          onClick={onReactivateAryaClick}
          disabled={isReactivateAryaButtonDisabled}
        >
          <span className={buttonTextClassNames}>{reactivateArya}</span>
          {isAdvancedSearchV2Enabled ? (
            <span className={styles.infoIcon}>
              <InfoIconV2 style={{}} />
            </span>
          ) : null}
        </Button>
      </Tooltip>
    </div>
  );

  let showPopover = isAdvancedSearchV2Enabled && !isAryaRankingAllowed;
  if (smartRecruiterVersion === 'v2') showPopover = false;

  const reactivateAryaButtonWithPopover = showPopover ? (
    <Popover
      trigger="hover"
      overlayStyle={{ width: '450px', height: 'fit-content', position: 'fixed' }}
      placement="left"
      content={aryaRankingDisabledPopover}
    >
      {reactivateAryaButton}
    </Popover>
  ) : (
    reactivateAryaButton
  );

  return (
    <>
      {reactivateAryaButtonWithPopover}
      <ActivationDialog
        jobId={jobId}
        visible={activationDialogVisibility}
        handleCancel={closeActivationDialog}
        job={currentJobDetails}
        aryaVersion={userConfig?.SubscriptionType}
        searchCriteria={unsavedCriteriaWithoutPersonalInfo}
        type="manualSearch"
        featureToggleList={featureToggleList}
        smartRecruiterVersion={smartRecruiterVersion}
        onStatusChange={onStatusChange}
        activeTab={activeTab}
      />
    </>
  );
}

export default connect(mapStateToProps)(ReactivateArya);
export { ReactivateArya as ReactivateAryaWithoutStore };
