import React from 'react';
import DOMPurify from 'dompurify';
import { highlightKeyWords } from '../../Utils/KeywordHighlightUtil';

class TextViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createCleanMarkup = this.createCleanMarkup.bind(this);
  }

  componentDidMount() {
    const { highlights, className } = this.props;
    if (className) {
      setTimeout(() => {
        highlightKeyWords(['div.text-resume-viewer'], highlights, 'mark');
      }, 0);
    }
  }

  createCleanMarkup() {
    const { text } = this.props;
    const clean = DOMPurify.sanitize(text);
    return { __html: clean };
  }

  render() {
    const { className } = this.props;
    /* eslint-disable react/no-danger */
    return (
      <div className={`${className || ''} text-resume-viewer`}>
        <pre style={{ fontFamily: 'inherit' }} dangerouslySetInnerHTML={this.createCleanMarkup()} />
      </div>
    );
  }
}

export default TextViewer;
