import React from 'react';
import CandidateCardPersonalInfo from './CandidateCardPersonalInfo';
import NewCandidateShortlistReject from './NewCandidateShortlistReject/NewCandidateShortlistReject';
import styles from './CandidatePersonalInfoWithShortlistReject.module.scss';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';
import CandidateCardConnectOptions from './CandidateCardConnectOptions';

function CandidatePersonalInfoWithShortlistReject(props) {
  const {
    candidate = {},
    isPaidJobServiceEnabled,
    activeTab,
    featureToggleList,
    onCandidateStatusChange,
    candidateContext,
    isShortlistIconEnabled,
    candidateStatusUpdate,
    size,
    onCandidateReject,
    jobId,
    candidateId,
    userConfig,
    whiteLabelInfo,
    candidateType,
    updateCandidateBookmarkStatus,
    candidateBookmarkApiStatus,
    version,
    isCandidateViewIconVisible,
    openCandidateView,
    isCandidateGroupHead,
    duplicateCandidateIds,
    indexKey,
    connectStatusLoaded,
    showCandidateDetail,
    setComposeEmailType,
    currentJobDetails,
    handleContactPull,
    showConnectWidget,
    showExpandedCandidateCard,
  } = React.useContext(CandidateCardContext);

  const { candidateCardTertiaryContent, showCandidateQualificationSummary, isScoreVisible } = props;

  const candidateStatus = candidate.Status;
  const isAddVisible =
    (candidateContext === 'job' && isShortlistIconEnabled) ||
    (candidateContext === 'segment' &&
      (!candidateStatus ||
        candidateStatus?.toLowerCase() === 'rejected' ||
        activeTab === 'rejected' ||
        candidateStatus?.toLowerCase() === 'sourced'));

  const isDeleteVisible =
    candidateContext === 'job' ||
    (activeTab !== 'rejected' &&
      candidateContext === 'segment' &&
      (!candidateStatus ||
        candidateStatus?.toLowerCase() === 'shortlisted' ||
        candidateStatus?.toLowerCase() === 'sourced'));

  const candidateCardConnectOptions = (
    <CandidateCardConnectOptions
      featureToggleList={featureToggleList}
      connectStatusLoaded={connectStatusLoaded}
      showCandidateDetail={showCandidateDetail}
      setComposeEmailType={setComposeEmailType}
      candidate={candidate}
      indexKey={indexKey}
      currentJobDetails={currentJobDetails}
      handleContactPull={handleContactPull}
      showConnectWidget={showConnectWidget}
      candidateContext={candidateContext}
      isCandidateViewIconVisible={isCandidateViewIconVisible}
    />
  );

  return (
    <div className={styles.personalInfoShortlistWrapper}>
      <CandidateCardPersonalInfo
        candidate={candidate}
        userConfig={userConfig}
        whiteLabelInfo={whiteLabelInfo}
        featureToggleList={featureToggleList}
        candidateContext={candidateContext}
        candidateType={candidateType}
        updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
        jobId={jobId}
        candidateBookmarkApiStatus={candidateBookmarkApiStatus}
        version={version}
        isCandidateViewIconVisible={isCandidateViewIconVisible}
        openCandidateView={openCandidateView}
        isCandidateGroupHead={isCandidateGroupHead}
        duplicateCandidateIds={duplicateCandidateIds}
        showExpandedCandidateCard={showExpandedCandidateCard}
      />
      <div>
        <NewCandidateShortlistReject
          isPaidJobServiceEnabled={isPaidJobServiceEnabled}
          activeTab={activeTab}
          featureToggleList={featureToggleList}
          onCandidateStatusChange={onCandidateStatusChange}
          candidateContext={candidateContext}
          isShortlistIconEnabled={isShortlistIconEnabled}
          candidateStatus={candidateStatus}
          candidateStatusUpdate={candidateStatusUpdate}
          candidate={candidate}
          size={size}
          onCandidateReject={onCandidateReject}
          jobId={jobId}
          candidateId={candidateId}
          isAddVisible={isAddVisible}
          isDeleteVisible={isDeleteVisible}
          candidateCardTertiaryContent={candidateCardTertiaryContent}
          showCandidateQualificationSummary={showCandidateQualificationSummary}
          isScoreVisible={isScoreVisible}
          version={version}
          showExpandedCandidateCard={showExpandedCandidateCard}
        />
        {!showExpandedCandidateCard ? candidateCardConnectOptions : null}
      </div>
    </div>
  );
}

export default CandidatePersonalInfoWithShortlistReject;
