import _ from 'lodash';

function getMediaObject(source) {
  let mediaObject = null;
  const name = _.get(source, 'Name', '').toLowerCase();
  const url = _.get(source, 'Url', '');
  if (_.includes(name, 'linkedin')) {
    mediaObject = { type: 'linkedin', url };
  } else if (_.includes(name, 'xing')) {
    mediaObject = { type: 'xing', url };
  } else if (_.includes(name, 'aboutme')) {
    mediaObject = { type: 'aboutme', url };
  } else if (_.includes(name, 'stack-overflow')) {
    mediaObject = { type: 'stack-overflow', url };
  } else if (_.includes(name, 'github')) {
    mediaObject = { type: 'github-logo', url };
  } else if (_.includes(name, 'behance')) {
    mediaObject = { type: 'behance', url };
  } else if (_.includes(name, 'viadeo')) {
    mediaObject = { type: 'viadeo-logo', url };
  } else if (_.includes(name, 'beyond')) {
    mediaObject = { type: 'beyond', url };
  } else if (_.includes(name, 'indeed')) {
    mediaObject = { type: 'indeed-small', url };
  } else if (_.includes(name, 'quora')) {
    mediaObject = { type: 'quora', url };
  } else if (_.includes(name, 'healthgrades')) {
    mediaObject = { type: 'healthgrades', url };
  } else if (_.includes(name, 'ieee')) {
    mediaObject = { type: 'IEEE', url };
  } else if (_.includes(name, 'practo')) {
    mediaObject = { type: 'practo', url };
  } else if (_.includes(name, 'uspto')) {
    mediaObject = { type: 'uspto', url };
  } else if (_.includes(name, 'doximity')) {
    mediaObject = { type: 'doximity', url };
  } else {
    mediaObject = { type: 'Social', url };
  }
  return mediaObject;
}

export default getMediaObject;
