import React, { Component } from 'react';
import EmailEditor from 'react-email-editor';
import EmptyTemplate from './EmptyTemplate.json';
import { handleUploadImage } from './ReactEmailEditorHandlers';
import styles from './ReactEmailEditor.module.scss';

class ReactEmailEditor extends Component {
  constructor(props) {
    super(props);
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
    this.state = {
      isOnReadyCalled: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.setInitialValues();
  }

  onLoad = () => {
    if (this.state.isOnReadyCalled) return;
    this.setState({ isOnReadyCalled: true });
    this.isEditorLoaded = true;
    this.setInitialValues();
  };

  setInitialValues = () => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;
    const { editorRef, currentTemplateDesign, updateAdvancedHtmlContent, mergeTags } = this.props;
    const { current: editorCurrentRef } = editorRef;
    if (editorCurrentRef) {
      editorCurrentRef.addEventListener('design:updated', () => {
        editorCurrentRef.exportHtml(data => {
          const { design, html } = data;
          updateAdvancedHtmlContent({ design, html });
        });
      });
      if (mergeTags?.length) {
        editorCurrentRef.setMergeTags(mergeTags);
      }
      editorCurrentRef.loadDesign(currentTemplateDesign ?? EmptyTemplate.design);
      editorCurrentRef.registerCallback('image', handleUploadImage);
    }
  };

  render() {
    const { editorRef } = this.props;
    return (
      <div className={styles.reactEmailEditorWrapper}>
        {' '}
        <EmailEditor
          ref={editorRef}
          onReady={this.onLoad}
          options={{ displayMode: 'email', features: { smartMergeTags: false } }}
        />
      </div>
    );
  }
}

export default ReactEmailEditor;
