import React from 'react';
import EditableTagGroup from '../EditableTagGroup/EditableTagGroup';
import './BooleanDisplay.scss';

export default function BooleanDisplay(props) {
  const {
    selectedTags,
    suggestedTags,
    currentIndex,
    deSelectSkill,
    selectSkill,
    inputVisibility,
    setInputVisibilityKey,
    inputValue,
    setInputValue,
    readOnly,
    selectedTagClassName,
    showAnd = true,
    isMustHaveSection,
  } = props;

  return (
    <div className="boolean-string-display-component-wrapper">
      <div className="boolean-string-display-component">
        <EditableTagGroup
          selectedTags={selectedTags}
          suggestedTags={suggestedTags}
          currentIndex={currentIndex}
          deSelectTag={deSelectSkill}
          selectTag={selectSkill}
          selectedTagClassName={`boolean-display-selected-tag ${selectedTagClassName}`}
          suggestedTagClassName="boolean-display-suggested-tag"
          inputVisibility={inputVisibility}
          setInputVisibilityKey={setInputVisibilityKey}
          inputValue={inputValue}
          setInputValue={setInputValue}
          readOnly={readOnly}
          isMustHaveSection={isMustHaveSection}
        />
      </div>
      {showAnd ? <div className="boolean-string-component-and-text">AND</div> : null}
    </div>
  );
}
