import React from 'react';
import classNames from 'classnames';
import { Form } from 'antd';
import uuid from 'uuid';
import { connect } from 'react-redux';
import _ from 'lodash';
import { setValidationStatusCallback } from '../../../../Utils/FormValidators';
import * as regionActions from '../../../../Actions/RegionActions';
import * as locationSearchActions from '../../../../Actions/LocationSearchActions';
import { getGroupedLocations, getIsCountryWideSearchEnabled } from '../../../../Utils/LocationUtils';
import { getCountries, getCurrentJobDetails } from '../../../../Reducers/JobReducer';
import { getFeatureToggleList } from '../../../../Reducers/FeatureToggleReducer.ts';
import { getConfig, getWhiteLabelInformation } from '../../../../Reducers/ConfigReducer';
import styles from './EnhancedLocationSection.module.scss';
import EnhancedLocationWrapperInput from './EnhancedLocationWrapperInput/EnhancedLocationWrapperInput';
import {
  getLocationValidationStatus,
  getLocationValidationErrorMessage,
} from '../../../../Reducers/LocationSearchReducer';

const FormItem = Form.Item;
const mapStateToProps = state => ({
  userConfig: getConfig(state) ?? {},
  countries: getCountries(state),
  featureToggleList: getFeatureToggleList(state),
  currentJobDetails: getCurrentJobDetails(state) ?? {},
  whiteLabelInfo: getWhiteLabelInformation(state),
  validationStatus: getLocationValidationStatus(state),
  validationErrorMessage: getLocationValidationErrorMessage(state),
});

const mapDispatchToProps = {
  fetchRegionsByCountryCode: regionActions.fetchRegionsByCountryCode,
  setValidationStatus: locationSearchActions.setValidationError,
};

export const previousRange = {
  location: undefined,
  multiLocation: undefined,
};

function EnhancedLocationSection(props) {
  const {
    form,
    featureToggleList,
    currentJobDetails,
    countries,
    defaultFormValues,
    whiteLabelInfo,
    candidateContext,
    isManualSearchFormMinimized,
    fetchRegionsByCountryCode,
    setValidationStatus,
    validationErrorMessage,
    validationStatus,
  } = props;

  const isRemoteJobChecked = form.getFieldValue('IsRemoteJob');
  React.useEffect(() => {
    previousRange.location = undefined;
    previousRange.multiLocation = undefined;
    setValidationStatus({ error: '', errorMessage: '' });
  }, []);

  function validateSourcingLocation(rule, value, callback, isRemoteJob, isJobRemoteJob) {
    const activeLocations = value.filter(x => x.isActive);
    if (activeLocations?.length === 0 && !isRemoteJob) {
      setValidationStatus({ error: 'error', errorMessage: 'Please click on Add Location' });
      callback('Please click on Add Location');
    } else if (activeLocations.every(x => x.Region !== null && x.Region !== undefined) && !isRemoteJob) {
      setValidationStatus({ error: 'error', errorMessage: 'Please add any Location as well' });
      callback('Please add any Location as well');
    } else {
      setValidationStatus({ error: 'success', errorMessage: '' });
      callback();
    }
  }

  const setValidationStatusCallbackFn = value => {
    setValidationStatusCallback(value, form, setValidationStatus);
  };

  const isCountryWideSearchEnabled = getIsCountryWideSearchEnabled(countries, currentJobDetails.CountryCode);
  const locationType = form.getFieldValue('LocationType');
  const isMultiLocationSectionVisible = locationType === 'MultiLocation';
  let multiLocationInitialValue = React.useMemo(
    () => getGroupedLocations(defaultFormValues.SourcingLocations),
    [defaultFormValues.SourcingLocations]
  );

  return (
    <div className={styles.locationSectionWrapper}>
      <div className={styles.locationLabel}>Location</div>
      <div
        className={classNames(styles.locationSection, {
          [styles.minimizedLocationSection]: isManualSearchFormMinimized,
        })}
      >
        {' '}
        {form.getFieldDecorator('LocationType', { initialValue: 'MultiLocation' })(<div></div>)}
        <FormItem colon={false} validateStatus={validationStatus} help={validationErrorMessage}>
          <div className={styles.enhancedLocationWrapper}>
            <div className={styles.locationTabs}>
              <div
                style={{
                  display: isMultiLocationSectionVisible ? 'flex' : 'none',
                  flexDirection: 'column',
                  gap: '20px',
                  width: 'fit-content',
                }}
              >
                {form.getFieldDecorator('SourcingLocations', {
                  initialValue: [
                    ...multiLocationInitialValue,
                    { Id: uuid.v4(), CountryCode: defaultFormValues.SourcingLocations[0].CountryCode },
                  ],
                  rules: [
                    {
                      required: true,
                      validator: (rule, value, callback) =>
                        validateSourcingLocation(
                          rule,
                          value,
                          callback,
                          isRemoteJobChecked,
                          defaultFormValues.IsRemoteLocation
                        ),
                    },
                  ],
                })(
                  <EnhancedLocationWrapperInput
                    form={form}
                    defaultMilesObject={defaultFormValues.Range}
                    defaultLocationObject={defaultFormValues.Location}
                    defaultIsStateWideSearchChecked={defaultFormValues.IsStateWideSearch}
                    defaultIsRemoteLocation={defaultFormValues.IsRemoteLocation}
                    isCountryWideSearchEnabled={isCountryWideSearchEnabled}
                    isPaidJobServiceEnabled={featureToggleList.PaidJobService.IsEnabled}
                    isStateWideSearchEnabled={featureToggleList.StateWideSearch.IsEnabled}
                    countryOptions={countries}
                    AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
                    candidateContext={candidateContext}
                    isManualSearchFormMinimized={isManualSearchFormMinimized}
                    fetchRegionsByCountryCode={fetchRegionsByCountryCode}
                    setValidationStatusCallback={setValidationStatusCallbackFn}
                  />
                )}
              </div>
            </div>
          </div>
        </FormItem>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedLocationSection);
export { EnhancedLocationSection as EnhancedLocationSectionWithoutStore };
