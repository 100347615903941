import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { Tooltip, Tag, Icon } from 'antd';
import { getHighlightedKeyWords, getCleanedHighlights, highlightKeyWords } from '../../Utils/KeywordHighlightUtil';
import {
  getMatchingJobRedirectionLink,
  getMatchingJobCodeText,
  getCandidateJobMatchingLocation,
} from '../../Utils/CandidateJobMatchingUtils';
import { getInternalSourceWithCandidateId, getPushedAtsSource } from '../../Utils/SourceUtils';
import styles from './JobMatchingListItem.module.scss';

const MAX_SKILL_DISPLAY_COUNT = 20;

export const getJobMatchingSkillsEllipsedText = (processedSkills, candidateJobMatchingCardSkills) =>
  processedSkills.length - candidateJobMatchingCardSkills.length > 0 ? (
    <span className={styles.jobMatchingSkillsEllipsedText}>...</span>
  ) : null;

export const JobMatchingModalListItem = props => {
  const {
    matchingJob,
    matchingJobHighlights = [],
    candidate,
    recommendCandidate,
    setRecommendCandidateMatchingJobsFetch,
    sourceCandidateStatusUpdate,
    recommendCandidateMatchingJobsFetch,
    getMatchingJobs,
    version,
    countries = [],
    currentUser,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    pageNumber,
    pageSize,
  } = props;
  const { JobId: jobId } = matchingJob;

  useEffect(() => {
    let keywordsToHighlight;
    if (matchingJobHighlights.length >= MAX_SKILL_DISPLAY_COUNT) {
      keywordsToHighlight = matchingJobHighlights;
    } else {
      keywordsToHighlight = _.uniq([
        ...matchingJobHighlights,
        ...(candidate.Skills || []),
        ...(candidate.Keywords || []),
      ]);
    }
    const domSelectors = [`div.jobSkillContent-${jobId}`];
    highlightKeyWords(domSelectors, keywordsToHighlight);
  }, []);

  const isJobPreferSkills = matchingJob.Derived?.Keywords?.Prefers?.length > 0;
  const jobPreferSkills = isJobPreferSkills ? matchingJob.Derived.Keywords.Prefers : [];
  let processedSkills = [];
  let unprocessedSkills = [];
  let highlightedSkillsLength;
  let matchingJobCodeText = '';
  if (version === 'ats') {
    matchingJobCodeText = getMatchingJobCodeText(matchingJob.JobCode);
  }

  const getAllJobKeywords = ({ mustHaves, niceToHaves, currentJobDetails }) => {
    const jobTitle = currentJobDetails.Title.Value;
    const jobSynonyms = currentJobDetails.Title.Synonyms;
    return [..._.flatten(mustHaves), ..._.flatten(niceToHaves), jobTitle || '', ...(jobSynonyms || [])];
  };
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  function minMaxExp() {
    let minMaxExpText;
    if (matchingJob.MinExperience && matchingJob.MaxExperience) {
      minMaxExpText = `${matchingJob.MinExperience} - ${matchingJob.MaxExperience} yrs`;
    } else if (matchingJob.MaxExperience) {
      minMaxExpText = `upto ${matchingJob.MaxExperience} yrs`;
    } else {
      minMaxExpText = `${matchingJob.MinExperience}+ yrs`;
    }
    return minMaxExpText;
  }

  const getRearrangedSkills = skills => {
    const jobKeywords = getAllJobKeywords({
      mustHaves: [], // Need to be Handled
      niceToHaves: jobPreferSkills,
      currentJobDetails: matchingJob,
    }).filter(onlyUnique);
    const keywordsToHighlight = _.uniq([...(skills || []), ...(candidate.Keywords || [])]);
    const { highlightedSkills: highlightedJobKeywords } = getHighlightedKeyWords(jobKeywords, keywordsToHighlight);
    const reorderedJobKeywords = _.uniq([...highlightedJobKeywords, ...jobKeywords]);
    return { reorderedJobKeywords, highlightedJobKeywords };
  };

  if (candidate.Skills) {
    const rearrangedSkills = getRearrangedSkills(candidate.Skills);
    unprocessedSkills = rearrangedSkills.reorderedJobKeywords;
    highlightedSkillsLength = rearrangedSkills.highlightedJobKeywords.length;
  } else {
    unprocessedSkills = getAllJobKeywords({
      mustHaves: [],
      niceToHaves: jobPreferSkills,
      currentJobDetails: matchingJob,
    });
  }

  processedSkills = _.uniq(matchingJobHighlights.concat(getCleanedHighlights(unprocessedSkills)));
  highlightedSkillsLength += matchingJobHighlights.length;
  const candidateJobMatchingCardSkills = processedSkills.slice(0, MAX_SKILL_DISPLAY_COUNT);
  const highlightedSkillList = candidateJobMatchingCardSkills.slice(0, highlightedSkillsLength);
  const nonHighlightedSkillList = candidateJobMatchingCardSkills.slice(highlightedSkillsLength);

  const highlightedSkillListTags = highlightedSkillList.map(skill => {
    const trimSkill = skill.trim();
    if (trimSkill) {
      return (
        <Tag className={styles.jobCardSkillTag} key={trimSkill}>
          {trimSkill}
        </Tag>
      );
    }
    return null;
  });
  const nonHighlightedSkillListTags = nonHighlightedSkillList.map(skill => {
    const trimSkill = skill.trim();
    if (trimSkill) {
      return (
        <Tag className={styles.jobCardSkillTag} key={trimSkill}>
          {trimSkill}
        </Tag>
      );
    }
    return null;
  });
  const onJobClick = () => {
    const candidatePayLoad = {
      ...candidate,
      status: 'Shortlisted',
      jobId: matchingJob.JobId,
      candidateId: candidate.Id,
    };
    if (version === 'ats' && onClickAddJobCallBack) {
      const candidateSources = candidate.Sources;
      const isAtsVersion = version === 'ats';
      const candidateSource = isAtsVersion
        ? getInternalSourceWithCandidateId(candidateSources)
        : getPushedAtsSource(candidateSources);
      onClickAddJobCallBack(matchingJob?.JobCode, candidateSource?.CandidateId);
    }
    recommendCandidate(candidatePayLoad, 'segment');
    setRecommendCandidateMatchingJobsFetch(true);
  };

  const shouldFetchMatchingJobs =
    sourceCandidateStatusUpdate?.[candidate.Id]?.[matchingJob.JobId]?.Shortlisted === 'COMPLETED' &&
    recommendCandidateMatchingJobsFetch;

  React.useEffect(() => {
    if (shouldFetchMatchingJobs) {
      getMatchingJobs(pageNumber, pageSize, '', false);
      setRecommendCandidateMatchingJobsFetch(false);
    }
  }, [shouldFetchMatchingJobs]);

  const handleOpenJob = () => {
    if (version !== 'ats') {
      window.open(`/jobs/${matchingJob.JobId}`);
    } else if (version === 'ats' && matchingJob.JobCode?.trim().length > 0) {
      openJobViewInNewTabCallBack(matchingJob.JobCode);
    }
  };

  const jobCountryName = countries.find(country => country.Iso2Code === matchingJob.CountryCode)?.Name;

  const industryValue = matchingJob.Industries?.[0] ? matchingJob.Industries.join(', ') : 'NA';

  const jobMatchingCardIndustryField =
    version !== 'ats' ? (
      <div>
        <div>Industry:&nbsp;</div>
        <Tooltip title={industryValue}>{industryValue}</Tooltip>
      </div>
    ) : null;

  const matchingJobLocation = getCandidateJobMatchingLocation(matchingJob.Location, jobCountryName);

  const isAdminOrJobAssignedToCurrentUser =
    version === 'ats'
      ? matchingJob.IsJobAssignedToCurrentUser
      : matchingJob.IsJobAssignedToCurrentUser || currentUser?.IsAdmin;

  const jobMatchingCardLocationField = (
    <div>
      <div>Location:&nbsp;</div>
      <Tooltip title={matchingJobLocation}>
        <span className={styles.jobMatchingLocation}>{matchingJobLocation}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className={styles.jobCardWrapper}>
      <div className={styles.jobCard}>
        <div className={styles.jobCardInfo}>
          <div className={styles.jobCardContent}>
            <div className={styles.jobCardPrimaryContent} role="presentation">
              <div role="button" tabIndex={0} onClick={handleOpenJob} onKeyPress={handleOpenJob}>
                <div className={styles.jobCardPersonalInfo}>
                  <div className={styles.jobCardCandidateName}>
                    <Tooltip title={<div>{matchingJob.Title.Value}</div>}>
                      <span className={styles.matchingJobTitle}>{matchingJob.Title.Value} </span>
                    </Tooltip>
                    <span>|</span>
                    <Tooltip title={<div>{matchingJob.Client}</div>}>
                      <span> {matchingJob.Client}</span>
                    </Tooltip>
                  </div>
                </div>
                <div className={styles.jobCardTitle}>
                  <Tooltip title={matchingJob.Modifieddate}>
                    <div className={styles.jobCardLastUpdatedinfo}>
                      ID:&nbsp;{matchingJob.JobId}&nbsp;|{matchingJobCodeText}&nbsp;Last Updated :&nbsp;
                      {moment.utc(matchingJob.ModifiedDate).local().format('MMM DD, YYYY')}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.features}>
            <div className={styles.experience}>
              <div>
                <div>Experience:&nbsp;</div>
                <Tooltip title={matchingJob.MaxExperience || matchingJob.MinExperience ? minMaxExp() : 'NA'}>
                  {matchingJob.MaxExperience || matchingJob.MinExperience ? minMaxExp() : 'NA'}
                </Tooltip>
              </div>
              {jobMatchingCardIndustryField}
              {jobMatchingCardLocationField}
            </div>
            <div className={styles.jobCardSecondaryContent}>
              <div className={styles.jobCardSkills}>
                <Tooltip title={(processedSkills || []).join(', ')}>
                  <div className={`jobCardSkillsContent jobSkillContent-${jobId}`}>
                    {highlightedSkillListTags}
                    {nonHighlightedSkillListTags}
                    {getJobMatchingSkillsEllipsedText(processedSkills, candidateJobMatchingCardSkills)}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <Tooltip title={!matchingJob.IsJobAssignedToCurrentUser ? 'Job is not shared with you.' : ''}>
          <div
            className={classnames(styles.jobCardActions, {
              [styles.disabled]: !isAdminOrJobAssignedToCurrentUser,
            })}
            role="button"
            tabIndex={0}
            onClick={isAdminOrJobAssignedToCurrentUser && onJobClick}
            onKeyPress={isAdminOrJobAssignedToCurrentUser && onJobClick}
          >
            <div
              className={classnames(styles.jobCardCandidateSelect, {
                [styles.disabled]: !isAdminOrJobAssignedToCurrentUser,
              })}
              key={_.get(matchingJob, 'JobId')}
            >
              {sourceCandidateStatusUpdate?.[candidate.Id]?.[matchingJob.JobId]?.Shortlisted === 'INPROGRESS' ? (
                <div key="loader" className={styles.addButtonLoaderIconDiv}>
                  <Icon className={styles.addButtonLoaderIcon} type="loading" spin />
                </div>
              ) : (
                'Add'
              )}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
