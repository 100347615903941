import * as React from 'react';
import { Icon } from 'antd';

const CandidateViewSvgV2 = props => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M6.99165 13.9989C5.37273 13.9989 3.75338 14.002 2.13446 13.998C1.05387 13.9954 0.215262 13.2911 0.025884 12.2341C0.00396531 12.1114 0.00133507 11.9838 0.00133507 11.8585C1.9948e-05 8.62028 -0.0008568 5.3825 0.00133507 2.14428C0.00221182 1.05304 0.707555 0.212047 1.77193 0.0257911C1.88941 0.00519348 2.01172 0.00300224 2.13139 0.00300224C4.1992 0.00168749 6.26658 0.00168749 8.33438 0.00256399C8.78723 0.00256399 9.09979 0.290493 9.09935 0.700255C9.09935 1.06707 8.81353 1.37165 8.44749 1.39356C8.38217 1.39751 8.31641 1.39663 8.25066 1.39663C6.24334 1.39663 4.23559 1.39663 2.22827 1.39663C1.66277 1.39663 1.39536 1.6644 1.39536 2.23149C1.39536 5.40923 1.39536 8.58741 1.39536 11.7651C1.39536 12.3406 1.66146 12.6048 2.24055 12.6048C5.41306 12.6048 8.58557 12.6048 11.7581 12.6048C12.3367 12.6048 12.6037 12.3401 12.6037 11.7656C12.6037 9.7308 12.6037 7.69601 12.6041 5.66166C12.6041 5.26943 12.7997 5.00517 13.1438 4.92015C13.5528 4.81935 13.964 5.11911 13.9942 5.54071C13.9982 5.59505 13.9982 5.64983 13.9982 5.70461C13.9982 7.75035 13.9999 9.79609 13.9977 11.8414C13.9969 12.8748 13.3691 13.7022 12.4007 13.9367C12.2065 13.9836 12.0005 13.9963 11.7997 13.9967C10.197 14.0015 8.59434 13.9993 6.99165 13.9993V13.9989Z"
      fill="#83878C"
    />
    <path
      d="M12.6049 2.38856C12.1582 2.83426 11.7478 3.24227 11.3388 3.65159C10.075 4.91638 8.81073 6.18072 7.54909 7.44814C7.33824 7.66025 7.09538 7.75316 6.80517 7.6734C6.32515 7.54104 6.14016 6.95511 6.45053 6.56331C6.49437 6.50766 6.54697 6.45901 6.59695 6.40861C8.21411 4.79323 9.83127 3.17828 11.4506 1.56509C11.4949 1.52083 11.5637 1.50155 11.6212 1.47043C11.6027 1.44545 11.5843 1.42047 11.5659 1.39549C11.4423 1.39549 11.3191 1.39856 11.1955 1.39505C10.7935 1.38278 10.502 1.08609 10.5042 0.693856C10.5064 0.309513 10.7966 0.00843628 11.1898 0.00493029C11.8951 -0.00164343 12.6005 -0.00164343 13.3058 0.00493029C13.7021 0.00843628 13.991 0.294174 13.9949 0.689036C14.002 1.39418 14.002 2.09976 13.9949 2.8049C13.991 3.20677 13.6863 3.49864 13.2944 3.49601C12.9104 3.49382 12.6167 3.20283 12.6044 2.81016C12.6005 2.68613 12.604 2.56211 12.604 2.38944L12.6049 2.38856Z"
      fill="#83878C"
    />
  </svg>
);
const CandidateViewIconV2 = props => (
  <Icon component={customProps => <CandidateViewSvgV2 {...props} {...customProps} />} {...props} />
);
export default CandidateViewIconV2;
