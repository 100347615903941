import { apiV3Client, advanceSearchActivityClient } from './BaseRepository';

export function monitorUserEmailActivity(payload) {
  return apiV3Client.post(`UserActivity/_emailclick`, payload);
}

export function saveAdvanceSearchActivity(payload) {
  const { jobId, orgId, Pagename, booleanUsage, email, Producttype } = payload;
  return advanceSearchActivityClient.post(`/jobs/_aryasearchactivites`, {
    aryajobid: jobId,
    aryaorgid: orgId,
    pagename: Pagename,
    usedboolean: booleanUsage,
    useremailid: email,
    producttype: Producttype,
  });
}
