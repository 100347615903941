import _ from 'lodash';
import * as candidateRepo from '../Repository/CandidateRepository';
import * as quickSearchRepository from '../Repository/QuickSearchRepository';
import * as connectRepository from '../Repository/ConnectRepository';
import * as noteRepository from '../Repository/NoteRepository';
import {
  setCandidateNotes,
  setCandidateNotesCount,
  setCallNotes,
  setCallNotesCount,
} from './ActionCreators/CandidateActions';
import { fetchUsers } from './UserActions';
import { getCandidateApplicationInfo } from '../Utils/SourceUtils';

export async function getMergedCandidateDetails(filter, isQuickSearchCandidate) {
  let response;
  if (isQuickSearchCandidate) {
    const candidateDetailPayload = {
      CountryCode: filter.Country,
      JobId: filter.jobId,
    };
    response = await quickSearchRepository.fetchCandidateDetails(candidateDetailPayload, filter.Id);
    response = response.data;
    response.IsQuickSearchCandidate = isQuickSearchCandidate;
  } else {
    response = await candidateRepo.fetchCandidateDetails(filter);
    const { Candidate, Recommended, ApplicationInfo, PublishedInfo } = response.data;
    response = {
      ...Candidate,
      ...Recommended,
      ...getCandidateApplicationInfo(ApplicationInfo),
      ...(PublishedInfo || {}),
      IsQuickSearchCandidate: isQuickSearchCandidate,
    };
  }
  return response;
}

export function fetchCandidateNotes(filter) {
  return dispatch => {
    noteRepository
      .getNotes({ jobId: filter.jobId, candidateId: filter.candidateId })
      .then(response => {
        dispatch(setCandidateNotes(filter.candidateId, _.get(response, ['data', 'Notes'], [])));
        dispatch(setCandidateNotesCount(filter.candidateId, _.get(response, ['data', 'Notes', 'length'], 0)));
        const userIds = response.data.Notes.filter(note => note.CreatedBy).map(note => note.CreatedBy);
        if (userIds.length) {
          const uniqueUserIds = [...new Set(userIds)];
          dispatch(fetchUsers({ userIds: uniqueUserIds }));
        }
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
      });
  };
}

export function fetchCallNotes(candidateId, conversationId, from, size) {
  return dispatch => {
    if (conversationId) {
      connectRepository
        .fetchCallNotes(conversationId, from, size)
        .then(response => {
          const notesList = response.data.Notes;
          const newNotes = notesList.map(note => ({
            Id: note.Id,
            Content: note.Content,
            CreatedDate: note.CreatedTime,
            CreatedBy: note.CreatedBy,
          }));

          const userIds = notesList.map(note => note.CreatedBy);
          if (userIds.length) {
            const uniqueUserIds = [...new Set(userIds)];
            dispatch(
              fetchUsers({
                userGuids: uniqueUserIds,
              })
            );
          }
          dispatch(setCallNotes(candidateId, newNotes));
          dispatch(setCallNotesCount(candidateId, newNotes.length));
        })
        .catch(error => {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              message: error,
              timeStamp: new Date(),
            },
          });
        });
    }
  };
}
