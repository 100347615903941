import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateScheduledEmailsStatus } from '../../../Actions/ConnectActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getDripStats } from '../../../Reducers/ConnectReducer';
import EndDripIcon from '../../../Icons/EndDripIcon';
import './DripConfigSwitch.scss';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';

export default function DripConfigSwitch({ conversationId, selectedFromEmail, isVisible, candidate }) {
  const [isDripActive, setIsDripActive] = useState(false);
  const [isDripConfirmModalVisible, setIsDripConfirmModalVisible] = useState(false);

  const dripConfigStatus = useSelector(state => getApiStatus(state, 'dripConfigApiStatus'));
  const dripStatsApiStatus = useSelector(state => getApiStatus(state, 'dripStatsApiStatus'));
  const dripStats = useSelector(state => getDripStats(state));

  const dispatch = useDispatch();
  const conversationDripStats = dripStats?.[conversationId];
  const scheduledEmailsCount = conversationDripStats?.reduce((totalScheduledDripCount, currentTemplateDripStats) => {
    return totalScheduledDripCount + currentTemplateDripStats.ScheduledEmailsCount;
  }, 0);

  React.useEffect(() => {
    setIsDripActive(scheduledEmailsCount > 0);
  }, [scheduledEmailsCount]);

  React.useEffect(() => {
    if (dripConfigStatus === 'FAILED') {
      setIsDripActive(currentDripStatus => !currentDripStatus);
    }
  }, [dripConfigStatus]);

  const toggleCancelDripConfirmationModal = () => {
    setIsDripConfirmModalVisible(x => !x);
  };

  return isVisible ? (
    <div className="drip-config-container">
      <Button
        type="danger"
        ghost
        onClick={toggleCancelDripConfirmationModal}
        disabled={!isDripActive}
        shape="round"
        sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.cancelDripEmail, candidate)}
      >
        <EndDripIcon
          className="drip-email-end-icon"
          sk-event-name={getEventNameByCandidateStatus(
            eventTypes.candidate.candidateConnect.cancelDripEmail,
            candidate
          )}
        />
        Cancel Drip Emails
      </Button>
      <Modal
        title="Are you sure you want to end drips?"
        onOk={async () => {
          if (isDripActive) {
            setIsDripActive(currentDripStatus => !currentDripStatus);
            await dispatch(updateScheduledEmailsStatus(conversationId, selectedFromEmail, 'Cancelled'));
            toggleCancelDripConfirmationModal();
          }
        }}
        okText="Confirm"
        confirmLoading={dripConfigStatus === 'INPROGRESS' || dripStatsApiStatus === 'INPROGRESS'}
        onCancel={toggleCancelDripConfirmationModal}
        visible={isDripConfirmModalVisible}
        zIndex={4000}
        okButtonProps={{
          'sk-event-name': getEventNameByCandidateStatus(
            eventTypes.candidate.candidateConnect.confirmDripEmailCancellation,
            candidate
          ),
        }}
        cancelButtonProps={{
          'sk-event-name': getEventNameByCandidateStatus(
            eventTypes.candidate.candidateConnect.cancelDripEmailCancellation,
            candidate
          ),
        }}
      >
        This action cannot be undone. By ending drips you will have to restart future drips for this candidate.
      </Modal>
    </div>
  ) : null;
}
