import React from 'react';
import { InputNumber, Form } from 'antd';
import { validateDistance } from '../../../Utils/FormValidators';
import MultiLocationContext from '../MultiLocationContext';

const MileSelect = props => {
  const {
    isRemoteJob,
    isCountryWideSearchEnabled,
    initialRange,
    isMultiLocationSectionVisible,
    isManualSearchFormMinimized,
    shouldHideManualSearchMiles,
    candidateContext = 'job',
    currentKey,
  } = props;

  const { isStateWideSearchChecked, onMilesChange, Distance, setMilesInputValidationError, milesInputValidationError } =
    React.useContext(MultiLocationContext);
  const getInitialRange = () => {
    if (initialRange) return Math.min(initialRange, 999);
    if (candidateContext === 'segment') return 100;
    return 5;
  };
  const inputStyle = { width: isMultiLocationSectionVisible || isManualSearchFormMinimized ? '122px' : '204px' };
  const isMilesDisabled = isCountryWideSearchEnabled || isRemoteJob || isStateWideSearchChecked(currentKey);

  const onMileChange = value => {
    if (value === 0) return;
    validateDistance(undefined, value, setMilesInputValidationError, isMilesDisabled, true);
    onMilesChange(value);
  };

  if ((Distance > 5 && Distance < 999) || Distance === undefined) {
    setMilesInputValidationError();
  }

  return (
    <div className="manualSearchMiles" style={{ display: shouldHideManualSearchMiles ? 'none' : 'block' }}>
      <Form.Item
        validateStatus={milesInputValidationError ? 'error' : 'success'}
        help={milesInputValidationError}
        initialValue={getInitialRange()}
      >
        <InputNumber
          value={Distance === 0 ? undefined : Distance}
          defaultValue={getInitialRange()}
          style={inputStyle}
          min={5}
          max={999}
          placeholder={isManualSearchFormMinimized ? 'Maximum miles' : 'Maximum miles for sourcing'}
          disabled={isMilesDisabled}
          onChange={onMileChange}
          allowClear
        />
      </Form.Item>
    </div>
  );
};

export default MileSelect;
