import React from 'react';
import { Modal, Form, Popover, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import RichTextEditor from '../Editor/RichTextEditor';
import config from '../../Config/Config';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import './MessageComposer.scss';
import languageSupportmessages from './messages';
import ConnectTemplateSelect from '../JobSettings/ConnectTemplateSelect';
import CreditInfoAlert from '../CreditInfo/CreditInfoAlert';
import { UserAlertWithWrapper } from '../UserAlerts/UserAlerts';

class MessageComposer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.addTags = this.addTags.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { visible, fetchMessageTemplates } = this.props;

    const prevVisibility = _.get(prevProps, 'visible', false);
    if (prevVisibility === false && visible === true) {
      fetchMessageTemplates({ From: 0, Size: 10 });
    }
  }

  addTags(tag) {
    this.myRef.current.addTags(tag, false);
  }

  render() {
    const {
      visible,
      onCancel,
      eventCallbacks,
      form,
      currentValues,
      mergeTags = [],
      // consentRequire = false,
      messageTemplateIds,
      messageTemplatesById,
      messageTemplatesCount,
      fetchMessageTemplates,
      selectedMessageTemplateId,
      selectedActiveCandidateCount,
      currentUser,
      credits,
      onCloseCreditInfoAlert,
      isPaidJobServiceEnabled,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      communicationDisabledMessage,
      availableCredits,
      featureToggleList,
      userConfig
    } = this.props;

    // const isConsentMessage = true;
    const isConsentMessage = false;
    const { validateFields } = form;
    const { isConsent } = currentValues;
    const { charactersPerMessage } = config;
    const regex = /\n$/gi;
    let isDisable = true;
    const selectedMessageTemplateName = _.get(messageTemplatesById, [selectedMessageTemplateId, 'Name'], null);

    const handleSubmit = event => {
      event.preventDefault();
      validateFields(err => {
        if (!err) {
          const text = this.myRef.current.quillRef.getText().trim().replace(regex, '');
          eventCallbacks.onSend(text);
        }
      });
    };

    const popoverContent = (
      <div className="tags-popover-content">
        {mergeTags.map(mergeTag => (
          <div className="merge-tag" onClick={() => this.addTags(mergeTag)} role="presentation" key={mergeTag.Key}>
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const addMergeTags = (
      <Popover content={popoverContent} placement="right" trigger="click">
        <div className="message-composer-merge-tags"> Merge Tags </div>
      </Popover>
    );

    if (this.myRef.current) {
      isDisable = this.myRef.current.quillRef.getText().trim().replace(regex, '').length === 0;
    }
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreationEnabled },
    } = featureToggleList;
    const isEditorBodyDisabled = isConsentMessage || !messageConsentTemplateCreationEnabled;

    return (
      <div>
        <Modal
          title="Send Text"
          visible={visible}
          onOk={handleSubmit}
          onCancel={onCancel}
          okText="Send"
          className="bulkMessageModalBody"
          okButtonProps={{ disabled: isConsentMessage ? false : isDisable }}
        >
          {communicationDisabledMessage ? (
            <UserAlertWithWrapper
              header={communicationDisabledMessage.header}
              content={communicationDisabledMessage.body}
            />
          ) : (
            <Form onSubmit={handleSubmit} className="connect-message-form">
              <div id="muti-select-message-window ">
                <CreditInfoAlert
                  credits={credits}
                  onCloseCreditInfoAlert={onCloseCreditInfoAlert}
                  selectedActiveCandidateCount={selectedActiveCandidateCount}
                  currentUser={currentUser}
                  isPaidJobServiceEnabled={isPaidJobServiceEnabled}
                  availableCredits={availableCredits}
                  userConfig={userConfig}
                />
                <Alert message="Consent must be received before messages can be sent." type="info" showIcon />
                <div className="multi-select-message-window-flex-items">
                  <ConnectTemplateSelect
                    cssClassname="candidate-multi-select-message-template-select"
                    templatesById={messageTemplatesById}
                    templateIds={messageTemplateIds}
                    templatesCount={messageTemplatesCount}
                    fetchTemplates={fetchMessageTemplates}
                    selectedTemplateId={selectedMessageTemplateId}
                    selectedTemplateName={selectedMessageTemplateName}
                    placeholderValue="Select template"
                    onChange={eventCallbacks.onTemplateSelect}
                    isDisabled={isConsentMessage}
                    isClearFieldAllowed
                  />
                  {isConsentMessage || isEditorBodyDisabled ? null : addMergeTags}
                  {/* <img
            id="connect-aryacredit-icon"
            src={`${process.env.PUBLIC_URL}/static/images/aryacredit.svg`}
            alt="Arya credit"
          />
          <span>
            Credits Remaining: <b>534</b>
          </span> */}
                </div>

                <div className="muti-select-message-window-flex-items">
                  <div className="message-textarea">
                    <RichTextEditor
                      className="mail-editor"
                      editorContent={{
                        htmlContent: isConsentMessage ? config.consentTemplateBody : currentValues.messageInputValue,
                      }}
                      onChange={eventCallbacks.onUpdateMessage}
                      mergeTags={mergeTags}
                      ref={this.myRef}
                      showToolbar={false}
                      disabled={isEditorBodyDisabled}
                      readonly={isEditorBodyDisabled}
                    />
                    <div className="message-character-count">
                      {charactersPerMessage - ((currentValues.messageInputValue || '').length % charactersPerMessage)}/
                      {Math.floor((currentValues.messageInputValue || '').length / charactersPerMessage)}
                      &nbsp; &nbsp;
                    </div>
                  </div>
                </div>
                {isConsent ? (
                  <div>
                    <Alert
                      message="Below text will be appended to the end of every consent text message."
                      type="info"
                      showIcon
                    />
                    <div className="message-window-template-consent-text">
                      <FormattedMessage {...languageSupportmessages.textConsentMessage} />
                    </div>
                  </div>
                ) : null}
              </div>
              <BulkCandidatesDownloadFilterInfo
                candidateDownloadFilterConfig={candidateDownloadFilterConfig}
                candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
              />
            </Form>
          )}
        </Modal>
      </div>
    );
  }
}

export default Form.create()(MessageComposer);
