import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Icon, Table, Menu, Dropdown, Modal, Button, Radio, Checkbox, Spin, Empty, message } from 'antd';
import styles from './ScoutingAgentDrawer.module.scss';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { setSelectedCriteriaId } from '../../../Actions/ManualSearchActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  getScoutingAgentofJob,
  getScoutingAgentDrawerStatus,
  getScoutingAgentConfig,
  getScoutingAgentsofEnabledJobs,
} from '../../../Reducers/ScoutingAgentReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';

const drawerStyle = {};

const mapDispatchToProps = {
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
  setScoutingAgentModalVisibilityStatus: ScoutingAgentActions.setScoutingAgentModalVisibility,
  deleteScoutingAgentAction: ScoutingAgentActions.deleteScoutingAgentAction,
  startScoutingAgentAction: ScoutingAgentActions.startScoutingAgentAction,
  stopScoutingAgentAction: ScoutingAgentActions.stopScoutingAgentAction,
  setSelectedScoutingAgentId: ScoutingAgentActions.setSelectedScoutingAgentIdAction,
  setLastScoutingAgentDeletedId: ScoutingAgentActions.setLastScoutingAgentDeletedId,
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
  setAgentIdViewCriteria: setSelectedCriteriaId,
  fetchScoutingAgentsByJobIds: ScoutingAgentActions.fetchScoutingAgentsByJobIds,
  setScoutingAgentSearchCriteriaModalVisibility: ScoutingAgentActions.setScoutingAgentSearchCriteriaModalVisibility,
};

const mapStateToProps = (state, props) => ({
  scoutingAgents: getScoutingAgentofJob(state, props.jobId),
  activeScoutingAgentCount: getScoutingAgentsofEnabledJobs(state, props.jobId),
  scoutingAgentDrawerStatus: getScoutingAgentDrawerStatus(state),
  jobScoutingAgentJobApiStatus: getApiStatus(state, 'scoutingAgentApiStatus'),
  deleteScoutingAgentApiStatus: getApiStatus(state, 'deleteScoutingAgentApiStatus'),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
});

const generateMenu = (setVisible, data, menuItems) => {
  return (
    <Menu>
      {menuItems.map(({ title, onClick }) => (
        <Menu.Item
          key={title}
          onClick={() => {
            if (title === 'Delete' || title === 'Stop') setVisible(true);
            if (typeof onClick === 'function') onClick(data.Id, data.Name, title);
          }}
        >
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const DeleteModal = props => {
  const {
    visible,
    setVisible,
    agentData,
    deleteScoutingAgentAction,
    setScoutingAgentDrawerVisibility,
    deleteScoutingAgentApiStatus,
    jobScoutingAgentJobApiStatus,
    modalData,
    fetchDrawerCandidates,
    fetchScoutingAgentsByJobIds,
    setLastScoutingAgentDeletedId,
    jobId,
  } = props;
  const [deleteData, setDeleteData] = React.useState({});
  const { title, buttonText, content, onClickAction, context } = modalData;
  const handleDelete = async () => {
    const queryString = `Retain=${deleteData.retain}&DeleteRejected=${deleteData.deleteRejected}`;
    await deleteScoutingAgentAction(agentData.id, queryString);
    await fetchScoutingAgentsByJobIds([jobId]);
    setVisible(false);
    fetchDrawerCandidates();
    setScoutingAgentDrawerVisibility(false);
    setLastScoutingAgentDeletedId(agentData.id);
  };
  const handleChange = (e, id) => {
    setDeleteData({
      ...deleteData,
      [id]: id === 'retain' ? e.target.value : e.target.checked,
    });
  };

  React.useEffect(() => {
    setDeleteData({ retain: 'KeepAll', deleteRejected: true });
  }, [agentData.id]);

  const handleStop = async () => {
    await onClickAction(agentData.id, agentData.name);
    await fetchScoutingAgentsByJobIds([jobId]);
    setVisible(false);
    fetchDrawerCandidates();
  };
  const onActionButtonClick = context === 'Delete' ? handleDelete : handleStop;

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={() => setVisible(!visible)}
      onCancel={() => setVisible(!visible)}
      zIndex={2048}
      width="fit-content"
      bodyStyle={{ width: '533px' }}
      footer={[
        <Button key="back" shape="round" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          loading={deleteScoutingAgentApiStatus === 'INPROGRESS' || jobScoutingAgentJobApiStatus === 'INPROGRESS'}
          key="submit"
          shape="round"
          type="danger"
          className={styles.deleteButton}
          onClick={onActionButtonClick}
        >
          {buttonText}
        </Button>,
      ]}
    >
      <p>
        {content} <b>‘{agentData.name}’</b>?
      </p>
      {context === 'Delete' && (
        <Radio.Group id="retain" onChange={e => handleChange(e, 'retain')} value={deleteData.retain}>
          <Radio value="KeepAll">Keep all</Radio>
          <Radio value="KeepShortlisted">Keep shortlisted</Radio>
          <Radio value="DeleteAll">Delete all</Radio>
          {deleteData.retain === 'DeleteAll' && (
            <Checkbox
              id="deleteRejected"
              onChange={e => handleChange(e, 'deleteRejected')}
              value={deleteData.deleteRejected}
            >
              Delete rejected also
            </Checkbox>
          )}
        </Radio.Group>
      )}
    </Modal>
  );
};

const ScoutingAgentDrawer = props => {
  const [visible, setVisible] = React.useState(false);
  const [agentData, setAgentData] = React.useState({});
  const {
    scoutingAgentDrawerStatus,
    setScoutingAgentDrawerVisibility,
    setScoutingAgentModalVisibilityStatus,
    jobScoutingAgentJobApiStatus,
    scoutingAgents,
    startScoutingAgentAction,
    stopScoutingAgentAction,
    setSelectedScoutingAgentId,
    deleteScoutingAgentAction,
    deleteScoutingAgentApiStatus,
    fetchJobScoutingAgent,
    fetchScoutingAgentsByJobIds,
    jobId,
    featureToggleList,
    scoutingAgentConfig,
    history,
    setLastScoutingAgentDeletedId,
    activeScoutingAgentCount,
    setAgentIdViewCriteria,
    candidateContext,
    setScoutingAgentSearchCriteriaModalVisibility,
  } = props;
  const [scoutingAgentsData, setScoutingAgentsData] = React.useState(scoutingAgents);
  const [modalData, setModalData] = React.useState({
    title: '',
    buttonText: '',
    apiStatus: '',
    onClickAction: '',
    content: '',
  });

  React.useEffect(() => {
    if (featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && jobId) {
      fetchJobScoutingAgent(jobId);
    }
  }, [jobId, featureToggleList.ScoutingAgent.IsEnabled, fetchJobScoutingAgent, scoutingAgentConfig]);

  React.useEffect(() => {
    setScoutingAgentsData(scoutingAgents.filter(agent => !agent.IsDeleted));
  }, [scoutingAgents]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(history?.location?.search || null);
    const params = Object.fromEntries(urlSearchParams.entries());
    const scoutingAgentId = params.scoutingAgent;
    if (scoutingAgentId && jobScoutingAgentJobApiStatus === 'COMPLETED') {
      openScoutingAgentModal(scoutingAgentId);
      removeQueryParam('scoutingAgent');
    }
  }, [jobId, jobScoutingAgentJobApiStatus]);

  const handleViewEditAgentCriteria = agentId => {
    const { pathname } = history.location;
    if (!pathname.includes(jobId) && !pathname.includes('Leo')) {
      history.push(`/${candidateContext}/${jobId}`);
    }
    setAgentIdViewCriteria({ jobId, criteriaId: agentId });
    setSelectedScoutingAgentId(agentId);
    setScoutingAgentDrawerVisibility(false);
    setScoutingAgentSearchCriteriaModalVisibility(true);
  };
  const handleViewEditAgent = agentId => {
    setSelectedScoutingAgentId(agentId);
    setScoutingAgentModalVisibilityStatus(true, 'update');
  };

  function removeQueryParam(queryParam) {
    const urlObj = new URL(window.location.href);
    const { searchParams } = urlObj;
    searchParams.delete(queryParam);
    const newUrl = `${urlObj.pathname}?${searchParams.toString()}${urlObj.hash}`;
    history?.replace(newUrl);
  }

  const openScoutingAgentModal = scoutingAgentId => {
    setScoutingAgentDrawerVisibility(true);
    handleViewEditAgent(scoutingAgentId);
  };

  const fetchDrawerCandidates = () => fetchJobScoutingAgent(jobId);

  const setWarningModal = type => {
    const tempModalData = {};
    if (type === 'Delete') {
      tempModalData.title = 'Delete scouting agent';
      tempModalData.buttonText = 'Delete Agent';
      tempModalData.content = 'What do you want to do with the candidate(s) sourced by';
      tempModalData.context = 'Delete';
    } else {
      tempModalData.title = 'Stop scouting agent';
      tempModalData.buttonText = 'Stop Agent';
      tempModalData.content = 'Are you sure to Stop';
      tempModalData.onClickAction = stopScoutingAgentAction;
      tempModalData.context = 'Stop';
    }
    setModalData(tempModalData);
  };

  const saveAgentData = (id, name, context) => {
    setAgentData({ id, name });
    setWarningModal(context);
  };
  const handleStart = async (id, name) => {
    if (activeScoutingAgentCount >= 5) {
      message.error('More than 5 scouting agents are not allowed');
    } else {
      await startScoutingAgentAction(id, name);
      await fetchJobScoutingAgent(jobId);
      fetchDrawerCandidates();
    }
  };
  const activeAgentMenu = [
    { title: 'View / Edit Agent Criteria', onClick: handleViewEditAgentCriteria },
    { title: 'View / Edit Agent', onClick: handleViewEditAgent },
    { title: 'Stop', onClick: saveAgentData },
    { title: 'Delete', onClick: saveAgentData },
  ];
  const inActiveAgentMenu = [
    { title: 'View / Edit Agent Criteria', onClick: handleViewEditAgentCriteria },
    { title: 'View / Edit Agent', onClick: handleViewEditAgent },
    { title: 'Start', onClick: handleStart },
    { title: 'Delete', onClick: saveAgentData },
  ];
  function formateDate(date) {
    const endDate = new Date(date);
    const day = endDate.getDate();
    const month = endDate.getMonth() + 1;
    const year = endDate.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }

  const columns = [
    {
      title: 'AGENT TITLE',
      dataIndex: 'Name',
      key: 'Name',
      className: styles.header,
      render: text => <p style={{ fontWeight: 500 }}>{text}</p>,
    },
    {
      title: 'CREATED BY',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
      className: styles.header,
      render: text => <p>{text}</p>,
    },
    {
      title: 'END DATE',
      dataIndex: 'EndDate',
      key: 'EndDate',
      className: styles.header,
      render: date => <p>{formateDate(date)}</p>,
    },
    {
      title: 'CANDIDATES',
      dataIndex: 'CandidateFetchedCount',
      key: 'CandidateFetchedCount',
      className: styles.header,
      render: (fetchCount, data) => (
        <p>
          {fetchCount}/{data.MaxCandidateCount}
        </p>
      ),
    },
    {
      title: 'STATUS',
      key: 'Status',
      dataIndex: 'status',
      className: styles.header,
      render: (_, data) => (
        <p style={data.IsActive ? { color: '#389E0D' } : { color: '#FA541C' }}>
          {data.IsActive ? 'Active' : 'Stopped'}
        </p>
      ),
    },
    {
      key: 'Options',
      dataIndex: 'IsActive',
      render: (isActive, data) => {
        const menuItems = isActive ? activeAgentMenu : inActiveAgentMenu;
        return (
          <Dropdown overlay={generateMenu(setVisible, data, menuItems)} overlayStyle={{ zIndex: 2004 }}>
            <Icon type="more" style={{ cursor: 'pointer' }} />
          </Dropdown>
        );
      },
    },
  ];

  const customLocale = {
    emptyText: (
      <Empty
        image={
          <svg width="100" height="100" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g fillRule="nonzero" stroke="#D9D9D9">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#FAFAFA"
                ></path>
              </g>
            </g>
          </svg>
        }
        imageStyle={{ height: 100 }}
        description={
          <p className={styles.emptyText}>
            List of ‘scouting agents’ for this job will appear here. You haven’t created any scouting agent.
          </p>
        }
      />
    ),
  };
  return (
    <Drawer
      title={
        <div className={styles.titleContainer}>
          <h2>Scouting Agents</h2>
          <Icon
            type="close"
            onClick={() => setScoutingAgentDrawerVisibility(false)}
            style={{ color: '#B4B7BA', cursor: 'pointer' }}
          />
        </div>
      }
      zIndex={2003}
      placement="right"
      closable={false}
      onClose={() => setScoutingAgentDrawerVisibility(false)}
      visible={scoutingAgentDrawerStatus}
      drawerStyle={drawerStyle}
      width={700}
    >
      <Spin spinning={jobScoutingAgentJobApiStatus === 'INPROGRESS'}>
        <div className={styles.scoutingAgentTableDisplay}>
          <Table columns={columns} dataSource={scoutingAgentsData} pagination={false} locale={customLocale} />
        </div>
        <DeleteModal
          visible={visible}
          setVisible={setVisible}
          agentData={agentData}
          setScoutingAgentDrawerVisibility={setScoutingAgentDrawerVisibility}
          deleteScoutingAgentAction={deleteScoutingAgentAction}
          modalData={modalData}
          deleteScoutingAgentApiStatus={deleteScoutingAgentApiStatus}
          jobScoutingAgentJobApiStatus={jobScoutingAgentJobApiStatus}
          fetchDrawerCandidates={fetchDrawerCandidates}
          fetchScoutingAgentsByJobIds={fetchScoutingAgentsByJobIds}
          setLastScoutingAgentDeletedId={setLastScoutingAgentDeletedId}
          jobId={jobId}
        />
      </Spin>
    </Drawer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoutingAgentDrawer);

export { ScoutingAgentDrawer as ScoutingAgentDrawerWithoutStore };
