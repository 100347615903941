import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getRelativeTime } from '../../Utils/RelativeTimeCalculator';
import './CallHistoryItem.scss';

function getIconColor(status) {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Answered':
      return 'green';
    case 'InProgress':
      return 'green';
    case 'Failed':
      return 'red';
    case 'Unanswered':
      return 'red';
    case 'Canceled':
      return 'red';
    default:
      return 'grey';
  }
}

function CallHistoryItem({ callDetails, currentUserEmailId, createdUser }) {
  const { To, From, CreatedTime, AnsweredTime, EndedTime } = callDetails;
  let { ConversationStatus } = callDetails;
  const iconColor = getIconColor(ConversationStatus);
  let duration = '';
  if (ConversationStatus === 'Completed') {
    const secs = moment.utc(EndedTime).diff(moment.utc(AnsweredTime)) / 1000;
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60);
    ConversationStatus = 'Connected:';
    duration = `${hours ? `${hours} hrs` : ''} ${minutes ? `${minutes} min` : ''} ${Math.ceil(secs % 60)} sec`;
  }
  return (
    <div className="call-history-item">
      <div className={`icon ${iconColor}`} />
      <div className="call-history-content">
        <div className="call-history-with">
          <div className="phone-number">{To}</div>
          <div className="time">{getRelativeTime(CreatedTime)}</div>
        </div>
        <div className="call-history-status">
          <div className="call-status">
            {ConversationStatus} {duration}
          </div>
          <div className="from">
            {From ? `Dialed from : ${From} | ` : null}
            {currentUserEmailId === createdUser.Email ? 'By You' : `By ${createdUser.FirstName || 'You'}`}{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

CallHistoryItem.propTypes = {
  callDetails: PropTypes.shape({
    To: PropTypes.string,
    CreatedTime: PropTypes.string,
    ConversationStatus: PropTypes.string,
    Caller: PropTypes.string,
  }).isRequired,
};

export default CallHistoryItem;
