const reportsDashboard = {};
const recruiterReports = {
  view: 'VIEW_RECRUITER_REPORT',
  export: 'EXPORT_RECRUITER_REPORT',
  saveQuery: 'SAVE_RECRUITER_REPORT_QUERY',
  editQuery: 'EDIT_RECRUITER_REPORT_QUERY',
  deleteQuery: 'DELETE_RECRUITER_REPORT_QUERY',
  shareQuery: 'SHARE_RECRUITER_REPORT_QUERY',
};
const candidateReports = {
  view: 'VIEW_CANDIDATE_REPORT',
  export: 'EXPORT_CANDIDATE_REPORT',
  saveQuery: 'SAVE_CANDIDATE_REPORT_QUERY',
  editQuery: 'EDIT_CANDIDATE_REPORT_QUERY',
  deleteQuery: 'DELETE_CANDIDATE_REPORT_QUERY',
  shareQuery: 'SHARE_CANDIDATE_REPORT_QUERY',
};
const jobReports = {
  view: 'VIEW_JOB_REPORT',
  export: 'EXPORT_JOB_REPORT',
  saveQuery: 'SAVE_JOB_REPORT_QUERY',
  editQuery: 'EDIT_JOB_REPORT_QUERY',
  deleteQuery: 'DELETE_JOB_REPORT_QUERY',
  shareQuery: 'SHARE_JOB_REPORT_QUERY',
};
const reportDownload = {};

const reportEventTypes = {
  candidateReports,
  jobReports,
  recruiterReports,
  reportDownload,
  reportsDashboard,
};

export default reportEventTypes;
