import React from 'react';
import { useDispatch } from 'react-redux';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import JobCandidateList from '../../Containers/JobCandidateList/JobCandidateList';
import QuickSearchCandidateList from '../../Containers/QuickSearch/QuickSearchCandidateList/QuickSearchCandidateList';
import AppToastNotification from '../../Containers/AppNotification/AppToastNotification';
import { fetchJobDetails } from '../../Actions/JobActions';
import { setCandidateId, setIsQuickSearchCandidate } from '../../Actions/ActionCreators/CandidateActions';

export default function CandidateView(props) {
  const {
    candidateId,
    jobId,
    version,
    candidateViewVersion,
    highlights,
    src,
    portal,
    openAtsView,
    notesContainer,
    callNotesContainer,
    postAtsCallNotes,
    dialingTone,
    ringingTone,
    dtmfTone,
    candidateContext = 'job',
    openSipCallWindowsApp,
    history,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
  } = props;
  const [currentJobDetails, setCurrentJobDetails] = React.useState();
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async function _fetchJobDetails() {
      const _currentJobDetails = await dispatch(fetchJobDetails(jobId));
      setCurrentJobDetails(_currentJobDetails);
    })();
  }, []);

  React.useEffect(() => {
    if (currentJobDetails) {
      dispatch(setCandidateId(candidateId));
      dispatch(setIsQuickSearchCandidate({ candidateId, isQuickSearchCandidate: src === 'qs' }));
    }
  }, [currentJobDetails]);

  if (!currentJobDetails) return <LoadingScreen loading active />;
  if (src === 'qs') {
    const quickSearchProps = {
      jobId,
      candidateId,
      openInNewTab: true,
      version,
      keywordsToHighlight: (highlights && highlights.split(',')) || [],
      openAtsView,
      notesContainer,
      callNotesContainer,
      postAtsCallNotes,
      dialingTone,
      ringingTone,
      dtmfTone,
      candidateViewVersion,
      currentJobDetails,
      candidateContext,
      openSipCallWindowsApp,
      history,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
    };
    if (version === 'ats') {
      return (
        <>
          {' '}
          <AppToastNotification />
          <QuickSearchCandidateList {...quickSearchProps} />
        </>
      );
    }
    return (
      <>
        {' '}
        <AppToastNotification />
        <QuickSearchCandidateList {...quickSearchProps} />
      </>
    );
  }

  return (
    <>
      <AppToastNotification />
      <JobCandidateList
        jobId={jobId}
        openInNewTab
        version={version}
        candidateViewVersion={candidateViewVersion}
        portal={portal}
        candidateId={candidateId}
        openAtsView={openAtsView}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postAtsCallNotes}
        dialingTone={dialingTone}
        ringingTone={ringingTone}
        dtmfTone={dtmfTone}
        currentJobDetails={currentJobDetails}
        candidateContext={candidateContext}
        openSipCallWindowsApp={openSipCallWindowsApp}
        keywordsToHighlight={highlights && highlights.split(',')}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
      />
    </>
  );
}
