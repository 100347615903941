import React from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import ListDetailHeader from '../../../Components/ListDetailHeader/ListDetailHeader';
import styles from './ListDetailContainer.module.scss';
import * as JobActions from '../../../Actions/JobActions';
import { getJobDetails } from '../../../Reducers/JobReducer';
import JobCandidatesTab from '../../JobCandidatesTab/JobCandidatesTab';
import ManualSearchWrapperV1 from '../../ManualSearch/ManualSearchWrapper/ManualSearchWrapper';
import ManualSearchWrapperV2 from '../../ManualSearchV2/ManualSearchWrapper/ManualSearchWrapper';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import JobNotFound from '../../../Components/JobNotFound/JobNotFound';
import JobForbidden from '../../../Components/JobForbidden/JobForbidden';
import JobSettings from '../../JobSettings/JobSettings';
import { getUsersById } from '../../../Reducers/UserReducer';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { getScoutingAgentConfig } from '../../../Reducers/ScoutingAgentReducer';

const mapStateToProps = (state, props) => ({
  currentJobDetails: getJobDetails(state, parseInt(props.segmentId, 10)),
  jobDetailsStatus: getApiStatus(state, 'jobDetailsStatus'),
  featureToggleList: getFeatureToggleList(state),
  usersById: getUsersById(state),
  editSegmentApiStatus: getApiStatus(state, 'putEditSegmentApiStatus'),
  scoutingAgentConfig: getScoutingAgentConfig(state),
});
const mapDispatchToProps = {
  fetchJobDetails: JobActions.fetchJobDetails,
  updateJobConfig: JobActions.updateJobConfig,
  fetchJobConfig: JobActions.fetchJobConfig,
  editSegment: JobActions.putEditSegment,
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
  fetchActivationStatus: JobActions.fetchActivationStatus,
};

export function ListDetailContainer(props) {
  const [settingsDrawerVisbility, setSettingDrawerVisbility] = React.useState(false);
  const [campaignDrawerVisbility, setCampaignDrawerVisbility] = React.useState(false);
  const {
    segmentId,
    currentJobDetails = {},
    fetchJobDetails,
    jobDetailsStatus,
    location,
    featureToggleList,
    updateJobConfig,
    fetchJobConfig,
    usersById,
    form,
    editSegment,
    editSegmentApiStatus,
    onSegmentCreationCallback,
    setStatus,
    setConnectionStatus,
    setPublishedStatus,
    status,
    connectionStatus,
    publishedStatus,
    changeTabStatus,
    setUrl,
    openAryaCandidateView,
    version,
    openAtsView,
    openWorkflowinNewTab,
    openSipCallWindowsApp,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    openListOfList,
    fetchJobScoutingAgent,
    setScoutingAgentDrawerVisibility,
    fetchActivationStatus,
  } = props;

  const toggleSettingsDrawer = () => {
    setSettingDrawerVisbility(!settingsDrawerVisbility);
  };

  const toggleCampaignDrawer = () => {
    setCampaignDrawerVisbility(!campaignDrawerVisbility);
  };

  React.useEffect(() => {
    fetchJobDetails(parseInt(segmentId, 10));
    fetchJobConfig(parseInt(segmentId, 10));
  }, []);

  const jobId = parseInt(segmentId, 10);
  const { Description, JobTitle, ErrorCode: jobErrorCode } = currentJobDetails;
  const isCandidateCRMEnabled = featureToggleList.CandidateCRM.IsEnabled;
  if (jobErrorCode === 404 || jobErrorCode === 400)
    return (
      <JobNotFound
        jobId={segmentId}
        location={location}
        context="segment"
        isCandidateCRMEnabled={isCandidateCRMEnabled}
        openListOfList={openListOfList}
      />
    );
  if (jobErrorCode === 403) return <JobForbidden context="segment" openListOfList={openListOfList} />;
  const emailAutomationConfiguration = currentJobDetails.AutomationConfiguration?.Email;
  const workflowAutomationConfiguration = currentJobDetails.AutomationConfiguration?.Workflows?.[0];
  const isAutomationEnabled = emailAutomationConfiguration?.IsEnabled || workflowAutomationConfiguration?.IsEnabled;

  const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;
  const ManualSearchWrapper = isAdvancedSearchV2Enabled ? ManualSearchWrapperV2 : ManualSearchWrapperV1;

  return (
    <div className={styles.listDetails}>
      <JobSettings
        drawerVisibility={settingsDrawerVisbility || campaignDrawerVisbility}
        campaignDrawerVisbility={campaignDrawerVisbility}
        toggleSettingsDrawer={campaignDrawerVisbility ? toggleCampaignDrawer : toggleSettingsDrawer}
        jobId={jobId}
        jobDetails={currentJobDetails}
        SourcingConfiguration={currentJobDetails.SourcingConfiguration}
        AutomationConfiguration={currentJobDetails.AutomationConfiguration}
        AryaRejectionConfiguration={
          currentJobDetails.AryaRejectionConfiguration ?? {
            IsEnabled: false,
          }
        }
        updateJobConfig={updateJobConfig}
        isSourcingAllowed={false}
        isCandidateAutoRejectAllowed={false}
        isSendToOptionAllowed={false}
        isEmailAutomationNoteAllowed={false}
        currentJobDetails={currentJobDetails}
        candidateContext="segment"
        openWorkflowinNewTab={openWorkflowinNewTab}
        version={version}
      />
      <ListDetailHeader
        featureToggleList={featureToggleList}
        toggleSettingsDrawer={toggleSettingsDrawer}
        toggleCampaignDrawer={toggleCampaignDrawer}
        description={Description}
        segmentTitle={JobTitle}
        jobDetailsStatus={jobDetailsStatus}
        isAutomationEnabled={isAutomationEnabled}
        jobId={jobId}
        currentJobDetails={currentJobDetails}
        usersById={usersById}
        form={form}
        editSegment={editSegment}
        fetchJobDetails={fetchJobDetails}
        editSegmentApiStatus={editSegmentApiStatus}
        onSegmentCreationCallback={onSegmentCreationCallback}
        fetchJobScoutingAgent={fetchJobScoutingAgent}
        setScoutingAgentDrawerVisibility={setScoutingAgentDrawerVisibility}
        fetchActivationStatus={fetchActivationStatus}
      />
      {currentJobDetails?.JobGuid ? (
        <ManualSearchWrapper jobId={jobId}>
          <JobCandidatesTab
            jobId={jobId}
            setUrl={setUrl}
            currentJobDetails={currentJobDetails}
            candidateContext="segment"
            jobGuid={currentJobDetails?.JobGuid}
            status={status}
            connectionStatus={connectionStatus}
            publishedStatus={publishedStatus}
            setStatus={setStatus}
            setConnectionStatus={setConnectionStatus}
            setPublishedStatus={setPublishedStatus}
            location={location}
            changeTabStatus={changeTabStatus}
            openAtsView={openAtsView}
            openAryaCandidateView={openAryaCandidateView}
            version={version}
            openSipCallWindowsApp={openSipCallWindowsApp}
            openSegmentAtsView={openSegmentAtsView}
            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            onClickAddJobCallBack={onClickAddJobCallBack}
          />
        </ManualSearchWrapper>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ListDetailContainer));
export { ListDetailContainer as ListDetailContainerWithoutStore };
