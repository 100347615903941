/* eslint-disable no-nested-ternary */
/* eslint-disable no-continue */
import React from 'react';
import { Tooltip, Popover } from 'antd';
import { EllipsedText } from '../TextUtils';
import PersonalEmailIcon from '../../Icons/EmailTypeIcons/PersonalEmailIcon';
import ProfessionalEmailIcon from '../../Icons/EmailTypeIcons/ProfessionalEmailIcon';
import styles from './EmailUtils.module.scss';
import { validateStatus } from '../ContactUtils';

const getSortedEmailsArray = array => {
  return array.sort((a, b) => a.EmailAddress.localeCompare(b.EmailAddress));
};

const mapEmailTypeToIcon = ({ emailType }) => {
  if (!emailType) {
    return null;
  }
  const lowerCasedEmailType = emailType?.toLowerCase();
  if (lowerCasedEmailType === 'personal') {
    return (
      <Tooltip title="Personal email">
        <PersonalEmailIcon />
      </Tooltip>
    );
  }
  if (lowerCasedEmailType === 'professional') {
    return (
      <Tooltip title="Work email">
        <ProfessionalEmailIcon />
      </Tooltip>
    );
  }
  return null;
};

const getSortedEmailsByEmailTypes = ({ emails }) => {
  if (!emails?.length) {
    return emails;
  }
  const invalidSpamEmails = [];
  const validEmails = [];
  const userAddedEmails = [];
  const personalEmails = [];
  const workEmails = [];
  for (let i = 0; i < emails.length; i += 1) {
    const email = emails[i];
    const isEmailInvalid = validateStatus(email.ValidityStatus);
    const isEmailInvalidOrRefunded = isEmailInvalid || email.IsRefunded;
    if (isEmailInvalidOrRefunded) {
      invalidSpamEmails.push(email);
      continue;
    }
    if (email.IsAddedByUser) {
      userAddedEmails.push(email);
      continue;
    }
    if (!email.Type) {
      validEmails.push(email);
      continue;
    }
    if (email.Type === 'Personal') {
      personalEmails.push(email);
    } else workEmails.push(email);
  }
  let sortedEmails = [];
  if (userAddedEmails.length) sortedEmails = [...getSortedEmailsArray(userAddedEmails)];
  if (validEmails.length) sortedEmails = [...sortedEmails, ...getSortedEmailsArray(validEmails)];
  if (personalEmails.length) sortedEmails = [...sortedEmails, ...getSortedEmailsArray(personalEmails)];
  if (workEmails.length) sortedEmails = [...sortedEmails, ...getSortedEmailsArray(workEmails)];
  if (invalidSpamEmails) sortedEmails = [...sortedEmails, ...getSortedEmailsArray(invalidSpamEmails)];
  return sortedEmails;
};

const getEmailWithPopover = ({ popoverContent, email }) => {
  const { EmailAddress: emailAddress } = email;
  return !email.IsRefunded && popoverContent ? (
    <Popover mouseEnterDelay={0.2} placement="top" overlayStyle={{ paddingBottom: '0px' }} content={popoverContent}>
      {emailAddress}
    </Popover>
  ) : (
    emailAddress
  );
};

const getEmailAddressWithEmailTypeIcon = ({ email, maxEmailLength, isEllipsedTextForManualEmails, popoverContent }) => {
  const { Type: emailType, EmailAddress: emailAddress } = email;
  return (
    <span>
      {(emailType || isEllipsedTextForManualEmails) && maxEmailLength ? (
        <EllipsedText text={emailAddress} maxTextLength={maxEmailLength} />
      ) : (
        getEmailWithPopover({ popoverContent, email })
      )}
      {emailType ? <span className={styles.emailTypeIcon}>{mapEmailTypeToIcon({ emailType })}</span> : null}
    </span>
  );
};

export const getPreHeaderValidationStatus = preHeaderRef => {
  const preHeaderText = preHeaderRef?.current?.quillRef.getText().trim();
  if (preHeaderText) {
    return preHeaderText.length > 100 ? 'error' : '';
  }
  return '';
};

export const getEmailValidationStatus = Contact => {
  const { Emails } = Contact;
  if (Emails && Emails.length > 0) {
    const inValidEmails = Emails.filter(
      email => !email.IsRefunded && (email.ValidityStatus === 'Spam' || email.ValidityStatus === 'Invalid')
    );
    const notRefundedAndValidEmails = Emails.filter(
      email =>
        !email.IsRefunded &&
        (email.EmailSubscriptionStatus === 'Subscribed' ||
          email.ValidityStatus === 'Valid' ||
          email.ValidityStatus === 'Unknown')
    );
    return !(
      (notRefundedAndValidEmails.length === 0 && inValidEmails.length > 0) ||
      notRefundedAndValidEmails.length === 0
    );
  }
  return false;
};

export { mapEmailTypeToIcon, getSortedEmailsByEmailTypes, getEmailAddressWithEmailTypeIcon };
