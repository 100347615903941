import React from 'react';
import { Tag, Icon, Input } from 'antd';
import debounce from 'lodash/debounce';
import { EllipsedText } from '../../Utils/TextUtils';
import { getFormattedMustHaves } from '../../CompoundComponents/MustHavesWrapper/MustHavesWrapper';
import { changeCursorPosition } from '../../Utils/ManualSearchUtils';

export const CurrentTags = ({ tags, deSelectTag, selectTag, currentIndex, closable, className, readOnly }) => {
  if (!tags?.length) return null;
  return tags?.map((tag, index) => (
    <React.Fragment key={`${tag} - ${index}`}>
      <Tag
        className={className}
        closable={!readOnly && closable}
        onClose={e => {
          if (closable) {
            e.preventDefault();
            deSelectTag(tag, currentIndex);
          }
        }}
        onClick={() => {
          if (!closable) selectTag(tag, currentIndex);
        }}
      >
        {!(readOnly || closable) ? <Icon type="plus" /> : null}
        <EllipsedText text={tag} maxTextLength={20} />
      </Tag>
      {!(index === tags.length - 1 && readOnly) ? <span className="boolean-string-or-text"> OR </span> : null}
    </React.Fragment>
  ));
};

export default function EditableTagGroup(props) {
  const {
    selectedTags,
    suggestedTags,
    currentIndex,
    deSelectTag,
    selectTag,
    selectedTagClassName,
    suggestedTagClassName,
    inputVisibility,
    setInputVisibilityKey,
    inputValue,
    setInputValue,
    readOnly,
    isMustHaveSection,
  } = props;

  const [isDoubleQuotesAllowed, setIsDoubleQuotesAllowed] = React.useState(true);

  const onMustHavesChange = value => {
    if (isMustHaveSection) {
      const formattedMustHave = getFormattedMustHaves(
        value,
        inputValue,
        isDoubleQuotesAllowed,
        setIsDoubleQuotesAllowed
      );
      setInputValue(formattedMustHave);
    } else setInputValue(value);
  };

  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputVisibility) {
      inputRef.current.focus();
    }
  }, [inputVisibility]);

  React.useEffect(() => {
    if (isMustHaveSection && inputVisibility) {
      const inputField = inputRef.current?.input;
      changeCursorPosition(inputField, inputValue, isDoubleQuotesAllowed);
    }
  }, [inputValue, isDoubleQuotesAllowed, inputVisibility]);

  const hideInput = () => {
    setInputVisibilityKey('');
    setInputValue('');
  };
  const handleInputConfirm = () => {
    if (inputValue.trim()) selectTag(inputValue, currentIndex);
    hideInput();
  };

  const onInputBlur = debounce(hideInput, 500);

  const addMoreInputTag = () =>
    !inputVisibility ? (
      <Tag
        className="add-more-tag"
        onClick={() => {
          setInputVisibilityKey(currentIndex);
        }}
      >
        <Icon type="plus" /> Add more
      </Tag>
    ) : (
      <Input
        ref={inputRef}
        size="small"
        className="add-more-input"
        value={inputValue}
        onChange={e => onMustHavesChange(e.target.value)}
        onPressEnter={handleInputConfirm}
        onBlur={onInputBlur}
      />
    );

  return (
    <div>
      <CurrentTags
        deSelectTag={deSelectTag}
        tags={selectedTags}
        currentIndex={currentIndex}
        closable
        className={selectedTagClassName}
        readOnly={readOnly}
      />
      <CurrentTags
        selectTag={selectTag}
        tags={suggestedTags}
        currentIndex={currentIndex}
        className={suggestedTagClassName}
      />
      {!readOnly ? addMoreInputTag() : null}
    </div>
  );
}
