import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import * as regionActions from '../../Actions/RegionActions';
import { getRegions } from '../../Reducers/RegionReducer';
import useSetDefaultSourcingLocations from '../../Hooks/useSetDeafultSourcingLocations';
import { getLocationSectionConfig } from '../../Utils/MultiLocationUtils';
import EnhancedJobMultiLocationInput from '../../Components/JobForm/JobMultiLocationInput/EnhancedJobMultiLocationInput';
import LocationWrapper from '../../CompoundComponents/LocationWrapper/LocationWrapper';

const mapStateToProps = state => {
  return {
    regions: getRegions(state),
  };
};

const mapDispatchToProps = {
  fetchRegionsByCountryCode: regionActions.fetchRegionsByCountryCode,
};

function EnhancedJobMultiLocationInputContainer(props) {
  const {
    initialRange,
    regions,
    fetchRegionsByCountryCode,
    value,
    onChange,
    defaultSourcingLocations,
    form,
    countryOptions,
    isDistanceDisabled,
  } = props;

  React.useEffect(() => {
    form.setFieldsValue({ SourcingLocations: [{ Id: uuid.v4(), CountryCode: 'US', IsPrimaryLocation: true }] });
  }, []);

  const displayedLocations = value.filter(
    location =>
      location?.Location ||
      location?.Region ||
      location?.State ||
      location?.isVisible ||
      (!location?.isVisible && location?.CountryCode)
  );

  useSetDefaultSourcingLocations({
    defaultSourcingLocations,
    fetchRegionsByCountryCode,
    value,
    form,
    regions,
    isPrimaryLocation: true,
    isAdvanceSearchV2: true,
  });
  const { isRegionSelectionLimitReached, isLocationSelectionLimitReached, isCountryWideSearchEnabledCountry } =
    getLocationSectionConfig(displayedLocations, countryOptions, true);
  return (
    <div>
      <LocationWrapper {...props}>
        <EnhancedJobMultiLocationInput
          locations={value}
          onChange={onChange}
          regions={regions}
          form={form}
          isLocationSelectionLimitReached={isLocationSelectionLimitReached}
          isRegionSelectionLimitReached={isRegionSelectionLimitReached}
          countryOptions={countryOptions}
          isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
          defaultMilesObject={initialRange}
          isDistanceDisabled={isDistanceDisabled}
        />
      </LocationWrapper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedJobMultiLocationInputContainer);
export { EnhancedJobMultiLocationInputContainer as EnhancedJobMultiLocationInputContainerWithoutStore };
