import { defineMessages } from 'react-intl';

const messages = defineMessages({
  jobBoardIntegration: {
    id: 'ApplicationSettings.Title.JobBoardIntegration',
    defaultMessage: 'Job Board Integration',
  },
  languageChange: {
    id: 'ApplicationSettings.Title.LanguageChange',
    defaultMessage: 'Language Change',
  },
  defaultDistance: {
    id: 'ApplicationSettings.Title.DefaultDistance',
    defaultMessage: 'Default Distance',
  },
  aryaBotSettings: {
    id: 'ApplicationSettings.Title.ChatbotSettings',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Chatbot Settings',
  },
  emailConfiguration: {
    id: 'ApplicationSettings.Title.EmailConfiguration',
    defaultMessage: 'Email Configuration',
  },
  emailConfigurationMessage: {
    id: 'ApplicationSettings.Message.EmailConfiguration',
    defaultMessage:
      'Email Configuration is set by default, but you can override default configuration for sending emails through your email providers mail server',
  },
  jobBoardIntegrationMessage: {
    id: 'ApplicationSettings.Message.JobBoardIntegration',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Please sign in to a job board account to link with Arya',
  },
  languageChangeMessage: {
    id: 'ApplicationSettings.Message.LanguageChange',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Please select language to be used across Arya',
  },
  defaultDistanceMessage: {
    id: 'ApplicationSettings.Message.DefaultDistance',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Input default distance you wish for Arya to source candidates',
  },
  submitButtonText: {
    id: 'ApplicationSettings.SubmitButton.Text',
    defaultMessage: 'Save Changes',
  },
});

export default messages;
