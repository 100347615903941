import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input } from 'antd';
import classNames from 'classnames';
import WorkflowReportFilter from '../WorkflowReportFilter/WorkflowReportFilter';
import styles from './WorkflowDetailsContainer.module.scss';

const FormItem = Form.Item;

export default function WorfklowDetailsHeader(props) {
  const {
    validationStatus,
    validationMessage,
    handleInputChange,
    workflowName,
    workflowId,
    displayMode,
    isWorkflowEditPreview,
    workflowReportFilterData,
    setWorkflowReportEndNodesData,
    setWorkflowReportData,
    isCandidateCRMFeatureEnabled,
    isDraft,
    location,
    version,
    onEdit,
  } = props;
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (!workflowId) {
      inputRef.current.focus();
    }
  }, []);

  const { pathname } = location;

  const redirectionStateValue = (displayMode === 'preview' || displayMode === 'create') && !isWorkflowEditPreview;

  const onEditOrUseItRedirectionPath = redirectionStateValue
    ? '?tab=workflows&type=create'
    : `?tab=workflows&workflowId=${workflowId}`;

  const onEditOrUseitRedirectionState = redirectionStateValue ? { workflowId } : {};

  const editOrUseItButtonText = displayMode === 'preview' && isWorkflowEditPreview ? 'Edit' : 'Use it';

  return (
    <div className={classNames(styles.workflowDetailsHeader, { [styles.workflowDetailsHeaderAts]: version === 'ats' })}>
      <div className={styles.workflowDetailsHeaderContent}>
        <div className={styles.workflowDetailsHeaderLeftContent}>
          <div className={styles.workflowDetailsHeaderInput}>
            <Link
              to={{
                pathname,
                search: '?tab=workflows',
                state: { chooseDefaultWorkflow: redirectionStateValue },
              }}
            >
              <div className={styles.backToListWrapper}>
                <Icon type="left" />
                <span className={styles.backToListText}>Back</span>
              </div>
            </Link>
            <FormItem validateStatus={validationStatus} help={validationMessage}>
              <Input
                placeholder="Enter Workflow Name"
                ref={inputRef}
                style={{ width: '400px' }}
                defaultValue={workflowName}
                value={workflowName}
                onChange={handleInputChange}
                disabled={displayMode === 'preview'}
              />
            </FormItem>
          </div>
          {!displayMode || displayMode === 'create' ? null : (
            <Link
              to={{
                pathname,
                search: onEditOrUseItRedirectionPath,
                state: onEditOrUseitRedirectionState,
              }}
            >
              <Button type="primary" shape="round" onClick={onEdit}>
                {editOrUseItButtonText}
              </Button>
            </Link>
          )}
          <div className={styles.workflowReportNote}>Note: The metrics are determined by the previous events</div>
        </div>
      </div>
      <div className={styles.closeWorkflowIcon}>
        <Link
          to={{
            pathname,
            search: '?tab=workflows',
            state: { chooseDefaultWorkflow: redirectionStateValue },
          }}
        >
          <Icon type="close" style={{ fontSize: '16px', color: '#83878C' }} />
        </Link>
      </div>
      {displayMode === 'preview' && !isDraft && (
        <WorkflowReportFilter
          setWorkflowReportData={setWorkflowReportData}
          workflowReportFilterData={workflowReportFilterData}
          setWorkflowReportEndNodesData={setWorkflowReportEndNodesData}
          workflowId={workflowId}
          isCandidateCRMFeatureEnabled={isCandidateCRMFeatureEnabled}
          version={version}
        />
      )}
    </div>
  );
}
