import _ from 'lodash';
import moment from 'moment';

export function getActualSourcingStats(allSourcingStas, isPaidJobServiceEnabled) {
  const sourcingStats = isPaidJobServiceEnabled ? allSourcingStas.PublishedStats : allSourcingStas;
  const sourcedCount = _.get(sourcingStats, ['Sourced'], 0);
  const shortlistedCount = _.get(sourcingStats, ['Shortlisted'], 0);
  const scoutingAgentCandidateCount = _.get(sourcingStats, ['ScoutingAgentCandidateCount'], 0);
  const connectedCount = _.get(sourcingStats, ['Connected'], 0);
  const appliedCount = _.get(sourcingStats, ['Applied'], 0);
  const engagedCount = _.get(sourcingStats, ['Engaged'], 0);
  let rejectedCount = _.get(sourcingStats, ['Rejected'], 0);
  rejectedCount = rejectedCount > 99 ? '99+' : rejectedCount;
  const exactRejectedCount = _.get(sourcingStats, ['Rejected'], 0);

  const allCandidatesCount =
    sourcedCount + shortlistedCount + connectedCount + appliedCount + engagedCount + rejectedCount;

  return {
    sourcedCount,
    shortlistedCount,
    connectedCount,
    appliedCount,
    engagedCount,
    rejectedCount,
    allCandidatesCount,
    exactRejectedCount,
    scoutingAgentCandidateCount,
  };
}

export function isJobSourcingCompleted(recentSourcedTime, lastChangeTime) {
  const recentSourced = moment.utc(recentSourcedTime).local();
  const lastChange = moment.utc(lastChangeTime).local();
  const lastSourcedTime = recentSourcedTime ? moment.max(recentSourced, lastChange) : lastChange;
  const timeDiff = moment().diff(lastSourcedTime, 'days');
  return timeDiff > 2;
}
