import { Checkbox, Icon, Skeleton } from 'antd';
import React from 'react';
import eventTypes from '../../../../../Analytics/EventTypes';
import styles from '../SkillsSection.module.scss';

const getSelectedSkill = ({ name }) => (
  <span className={styles.selectedSkill}>
    <Icon type="check-circle" style={{ fontSize: 14, color: '#13C26B' }} theme="filled" />
    {name}
  </span>
);

const getUnSelectedSkill = ({ name, type }) => (
  <span className={styles.selectedSkill}>
    {type === 'alias' ? <Icon type="check-circle" style={{ fontSize: 14 }} /> : '+'} {name}
  </span>
);

const SkillsBox = ({ skills, header, addNewSkill, id, skEventName, type }) => (
  <div className={styles.skillsBox}>
    <div className={styles.skillsBoxHeader}>{header}</div>
    <div className={styles.skillsList}>
      {skills.map(skill => {
        const { name } = skill;
        return (
          <div
            key={name}
            className={`${styles.additionalSkillTag} ${skill.isSelected ? styles.selected : ''}`}
            role="button"
            tabIndex={0}
            onClick={() => addNewSkill(name, id)}
            onKeyPress={() => addNewSkill(name, id)}
            sk-event-name={skEventName}
          >
            {skill.isSelected ? getSelectedSkill({ name }) : getUnSelectedSkill({ name, type })}
          </div>
        );
      })}
    </div>
  </div>
);

const getTitleHeader = ({ isTitlesExists }) => {
  if (!isTitlesExists) return 'Please add/select at least one Title for us to suggest Aliases / Alternate Keywords';
  return "Sorry! Couldn't find any Aliases / Alternate Keywords";
};

const AdditionalSkillsContent = ({
  aliasSkills = [],
  alternateSkills = [],
  addNewSkill,
  handleAliasSkillSelection,
  handleAliasSkillSectionCheckboxClick,
  skillIntelApiStatus,
  isTitlesExists,
  id,
}) => {
  const isSkillsExists = aliasSkills.length > 0 || alternateSkills.length > 0;
  const getSkillSuggestions = () => {
    const isSelectAllChecked = aliasSkills.filter(x => x.isSelected).length === aliasSkills.length;
    return (
      <div className={styles.skillSuggestionsWrapper}>
        {aliasSkills.length ? (
          <SkillsBox
            skills={aliasSkills}
            header={
              <span className={styles.aliasSkillsHeader}>
                <span>Aliases / Similar Keywords</span>
                <Checkbox
                  className={styles.selectAllCheckbox}
                  checked={isSelectAllChecked}
                  onChange={event => handleAliasSkillSectionCheckboxClick(id, event.target.checked)}
                >
                  Select All
                </Checkbox>
              </span>
            }
            addNewSkill={handleAliasSkillSelection}
            id={id}
            skEventName={eventTypes.job.manualSearch.selectAcronyms}
            type="alias"
          />
        ) : null}
        {alternateSkills.length ? (
          <div>
            <div className={styles.additionalSkillsHeader}>Choose a keyword to add to your selected list</div>
            <SkillsBox
              skills={alternateSkills}
              header="Alternate Keywords"
              addNewSkill={addNewSkill}
              id={id}
              skEventName={eventTypes.job.manualSearch.selectSimilarSkills}
              type="alternate"
            />
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div className={styles.additionalSkillsContent}>
      <Skeleton active loading={skillIntelApiStatus === 'INPROGRESS'} paragraph={{ rows: 3 }}>
        {isSkillsExists ? (
          getSkillSuggestions()
        ) : (
          <div className={styles.additionalSkillsHeader}>{getTitleHeader({ isSkillsExists, isTitlesExists })}</div>
        )}
      </Skeleton>
    </div>
  );
};

export default AdditionalSkillsContent;
