import React from 'react';
import { connect } from 'react-redux';
import AvailableContactInformation from '../../Components/CandidateContacts/AvailableContactInformation';
import { getCandidatesConnectInfo, getCandidateConnectStatus } from '../../Reducers/ConnectReducer';
import * as connectActions from '../../Actions/ConnectActions';

const mapStateToProps = (state, props) => ({
  connectInfo: getCandidatesConnectInfo(state),
  candidateConnectStatus: getCandidateConnectStatus(state, props.candidate?.PersonId),
});

const mapDispatchToProps = {
  addContact: connectActions.addContact,
  getRefundForInvalidContacts: connectActions.getRefundForInvalidContacts,
};

function ContactInformationContainer(props) {
  const {
    candidate,
    connectInfo,
    addContact,
    jobGuid,
    creditsRefunded,
    getRefundForInvalidContacts,
    candidateConnectStatus,
    currentJobDetails,
    mailRef,
    smsRef,
    callRef,
    onSubTabClick,
    setEmailComposeModalVisibility,
    setMessageComposeModalVisibility,
    setCurrentSelectedEmail,
  } = props;
  return (
    <div>
      <AvailableContactInformation
        candidate={candidate}
        connectInfo={connectInfo}
        addContact={addContact}
        jobGuid={jobGuid}
        creditsRefunded={creditsRefunded}
        getRefundForInvalidContacts={getRefundForInvalidContacts}
        candidateConnectStatus={candidateConnectStatus}
        currentJobDetails={currentJobDetails}
        mailRef={mailRef}
        smsRef={smsRef}
        callRef={callRef}
        onSubTabClick={onSubTabClick}
        setEmailComposeModalVisibility={setEmailComposeModalVisibility}
        setMessageComposeModalVisibility={setMessageComposeModalVisibility}
        setCurrentSelectedEmail={setCurrentSelectedEmail}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformationContainer);
export { ContactInformationContainer as ContactInformationContainerWithoutStore };
