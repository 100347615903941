import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Drawer, Form, Popover, Icon, Button, Switch, Alert, Tooltip } from 'antd';
import BaseTemplateForm from '../BaseTemplateForm';
import './CreateTextTemplate.scss';
import RichTextEditor from '../../../Editor/RichTextEditor';
import { validateTemplate } from '../../../../Utils/Validators';
import messages from './messages';
import eventTypes from '../../../../Analytics/EventTypes';
import InfoCircleIcon from '../../../../Icons/SuggestedIcons/InfoCircleIcon';
import { MergeTagIcon } from '../../../../Icons/AryaIcons';

function createRequestBody({ values, textBody, isConsent, initialValues }) {
  return {
    Name: values['template-name'],
    Body: textBody,
    IsConsent: isConsent,
    IsPublic: initialValues?.IsPublic ?? false,
  };
}

class CreateTextTemplate extends BaseTemplateForm {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.addTags = this.addTags.bind(this);
    this.state = { initalTextBody: undefined };
    this.saveTemplate = this.saveTemplate.bind(this);
    this.testTemplate = this.testTemplate.bind(this);
    this.addTags = this.addTags.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickConsentSwitch = this.onClickConsentSwitch.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = _.cloneDeep(state);
    if (props.initialValues.Body !== state.initalTextBody) {
      newState.textBody = props.initialValues.Body;
      newState.initalTextBody = props.initialValues.Body;
      newState.isConsent = props.initialValues.IsConsent;
    }
    return newState;
  }

  addTags(tag) {
    this.myRef.current.addTags(tag, false);
  }

  validateTextTemplate = ({ textBody }) => {
    const { mergeTags } = this.props;
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    return validateTemplate({ body: textBody, bodyMergeTags: mergeTagsForMessage });
  };

  saveTemplate(event) {
    const { form, onSubmit, initialValues, setInvalidTemplateNotification } = this.props;
    const { isConsent } = this.state;
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const textBody = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
        if (!this.validateTextTemplate({ textBody })) {
          setInvalidTemplateNotification();
          return;
        }
        onSubmit(createRequestBody({ values, textBody, isConsent, initialValues }));
        this.setState({ textBody: '' });
        form.resetFields();
      }
    });
  }

  onClickConsentSwitch(value) {
    this.setState({ isConsent: value });
  }

  onChange({ htmlContent, plainTextContent }) {
    this.setState({ textBody: htmlContent, body: plainTextContent });
  }

  testTemplate() {
    const { testTextTemplate, setInvalidTemplateNotification } = this.props;
    const { isConsent } = this.state;
    const textBody = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
    if (!this.validateTextTemplate({ textBody })) {
      setInvalidTemplateNotification();
      return;
    }
    testTextTemplate(textBody, isConsent);
  }

  render() {
    const {
      title,
      onClose,
      visible,
      initialValues,
      mergeTags,
      testTemplateStatus,
      viewOnly,

      featureToggleList,
    } = this.props;
    let { textBody } = this.state;
    const { isConsent } = this.state;
    textBody = (textBody || '').replace(/\n/g, '<br />');
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreationEnabled },
    } = featureToggleList;

    const isConsentCreationSwitchHidden =
      Object.keys(initialValues).length === 0 && !messageConsentTemplateCreationEnabled;

    const isConsentCreationDisabled = viewOnly || !messageConsentTemplateCreationEnabled;

    let isDisable = true;
    if (this.myRef.current) {
      isDisable = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n').length === 0;
    }

    const isTestBtnEnabled = (!this.myRef.current && initialValues.Body) || !isDisable;
    let buttonText = 'Test Text';
    if (testTemplateStatus === 'INPROGRESS') {
      buttonText = 'Testing';
    } else if (testTemplateStatus === 'COMPLETED') {
      buttonText = (
        <div className="test-email-wrapper ">
          <div>
            <Icon className="success-icon" type="check-circle" />
          </div>
          <div className="test-email-text"> Test Text </div>
        </div>
      );
    } else if (testTemplateStatus === 'FAILED') {
      buttonText = (
        <div className="test-email-wrapper ">
          <div>
            <Icon className="failed-icon" type="close-circle" theme="filled" />
          </div>
          <div className="test-email-text">Test Text</div>
        </div>
      );
    }

    const popoverContent = (
      <div className="tags-popover-content">
        {mergeTagsForMessage.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => {
              this.addTags(mergeTag);
            }}
            role="presentation"
          >
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const addMergeTags = (
      <Popover content={popoverContent} placement="left" trigger="click">
        <Tooltip zIndex={2223} title="Merge Tags">
          <span>
            <MergeTagIcon />
          </span>
        </Tooltip>
      </Popover>
    );

    return (
      <Drawer
        className="create-template text drawer"
        destroyOnClose
        title={title}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={650}
      >
        <Form onSubmit={this.saveTemplate}>
          <div className="row-one">
            {this.getTemplateNameItem('Text Template Name', 'Please input the template name', initialValues.Name)}

            {!viewOnly ? (
              <Tooltip
                title={
                  <div>
                    Select &lsquo;Test Text&rsquo; to have this message sent to your mobile <br /> device.The test text
                    will be sent to the phone number listed <br /> in your profile. Message and data rates may apply.
                  </div>
                }
                placement="topRight"
              >
                <span>
                  <Button
                    shape="round"
                    className="create-template test"
                    onClick={this.testTemplate}
                    loading={testTemplateStatus === 'INPROGRESS'}
                    disabled={!isTestBtnEnabled}
                  >
                    {buttonText}
                  </Button>
                </span>
              </Tooltip>
            ) : null}
          </div>
          {!isConsentCreationSwitchHidden ? (
            <div className="create-text-tempate-consent-wrapper">
              <span className="create-template-consent-template"> Consent Template</span>
              <span className="switchAndInfoIcon">
                <Switch
                  checkedChildren="On"
                  onClick={this.onClickConsentSwitch}
                  checked={isConsent}
                  disabled={isConsentCreationDisabled}
                />
                <Tooltip
                  title={
                    <div>
                      SMS engagement messages may be sent after recipients have given prior
                      <br /> consent. (Opt-in) Toggle this button &lsquo;ON&rsquo; if this template will be used for the{' '}
                      <br />
                      purpose of collecting &lsquo;Consent to Contact&rsquo; from candidates.
                    </div>
                  }
                  placement="topLeft"
                  overlayClassName="consentInfoTooltip"
                >
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            </div>
          ) : null}
          {!viewOnly ? addMergeTags : null}
          <div className="create-template-editor">
            <RichTextEditor
              editorContent={{ htmlContent: textBody }}
              onChange={this.onChange}
              mergeTags={mergeTags}
              ref={this.myRef}
              showToolbar={false}
              disabled={viewOnly}
            />
            {isConsent ? (
              <div>
                <Alert
                  message="Below text will be appended to the end of every consent text message."
                  type="info"
                  showIcon
                />
                <div className="create-template-consent-text">
                  <FormattedMessage {...messages.textConsentMessage} />
                </div>
              </div>
            ) : null}
          </div>
          <br />
          <div className="footer">
            {!viewOnly ? this.getSaveButton('Save Template', isDisable, eventTypes.connect.smsTemplates.save) : null}
            {!viewOnly ? this.getCancelButton('Cancel', onClose, eventTypes.connect.smsTemplates.cancel) : null}
          </div>
        </Form>
      </Drawer>
    );
  }
}

CreateTextTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textTemplateName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

CreateTextTemplate.defaultProps = {
  initialValues: {},
};

export default Form.create()(CreateTextTemplate);
export { CreateTextTemplate as CreateTextTemplateWithoutForm };
