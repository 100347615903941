import { connectV1Client } from './BaseRepository';

export function fetchEmailConfig(isAdmin) {
  return connectV1Client.get(`/_config/email?isAdmin=${isAdmin}`);
}

export function getEmailConfig(providerId) {
  return connectV1Client.get(`/_config/email/${providerId}`);
}

export function updateEmailSmtpConfig(config, isAdmin) {
  return connectV1Client.post(`/_config/email?isAdmin=${isAdmin}`, config);
}

export function deleteEmailConfig(providerId, isAdmin) {
  return connectV1Client.delete(`/_config/email/${providerId}/?isAdmin=${isAdmin}`);
}

export function updateManualSmtpConfig(config, providerId) {
  return connectV1Client.post(`/_config/email/${providerId}/_update`, config);
}

export function updateOAuthSmtpConfig(config, providerId) {
  return connectV1Client.post(`/_config/email/${providerId}/_update`, {
    Name: config.Name,
    AuthScheme: 'OAuth',
  });
}

export function fetchCallerIds() {
  return connectV1Client.get('/_config/audio');
}

export function updateCallerIds(updateActions) {
  return connectV1Client.post('/_config/audio', {
    CallerIds: updateActions,
  });
}

export function updateEmailSmtpSettings(emailSmtpSettings) {
  const { IsThirdPartyEmailConfigurationAllowed, IsMultipleSenderAddressConfigurationAllowed } = emailSmtpSettings;
  return connectV1Client.post('/settings/email', {
    IsThirdPartyEmailConfigurationAllowed,
    IsMultipleSenderAddressConfigurationAllowed,
  });
}

export function fetchContactType() {
  return connectV1Client.get('_config/Contact');
}

export function updateContactType(contactType) {
  return connectV1Client.put('_config/Contact', {
    ContactType: contactType,
  });
}

export function fetchContactProviders() {
  return connectV1Client.post('/serviceproviders/_search', { Type: 'Contact' });
}

export function bulkSubscribeContactProviders(providers) {
  return connectV1Client.put('ServiceProviders/_subscribe/bulk', {
    SubscribeProviders: providers,
  });
}

export function fetchBotDetails() {
  return connectV1Client.get('/BotContexts/botdetails');
}

export function updateBotDetails(botDetails) {
  return connectV1Client.put('/BotContexts/botdetails', botDetails);
}

export function authenticateContactProvider({ providerId, payload }) {
  return connectV1Client.put(`/serviceproviders/_config/${providerId}`, payload);
}

export function disconnectContactProvider({ providerId }) {
  return connectV1Client.delete(`/serviceproviders/_config/${providerId}`);
}

export function testSmtpEmail(emailId, providerId, senderName) {
  return connectV1Client.post(`/_config/email/${providerId}/_emails`, { EmailId: emailId, SenderName: senderName });
}

export function deleteSmtpEmail(emailId, providerId) {
  return connectV1Client.delete(`/_config/email/${providerId}/_emails`, { data: { EmailId: emailId } });
}
