import React from 'react';
import { Input, Form } from 'antd';
import PersonalInformationContext from './PersonalInformationContext';

const { Item } = Form;

function Name(props) {
  const { initialValue } = props;
  const { form } = React.useContext(PersonalInformationContext);

  return (
    <Item colon={false}>
      {form.getFieldDecorator('Name', {
        initialValue,
      })(<Input style={{ overflow: 'hidden' }} placeholder="Enter Name" />)}
    </Item>
  );
}

export default Name;
