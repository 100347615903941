import React from 'react';
import _ from 'lodash';
import { Tabs } from 'antd';
import styles from './EnhancedCandidate360SecondaryInfoSection.module.scss';
import CandidateSkillsSection from '../CandidateCard/CandidateCardQualificationsSummary/CandidateSkillsSection';
import CandidateEducationSection from '../CandidateCard/CandidateCardQualificationsSummary/CandidateEducationSection';
import CandidateCardMetaData from '../CandidateCard/CandidateCardMetaData/CandidateCardMetaData';
import CandidateTagsContainer from '../../Containers/CandidateTags/CandidateTagsContainer';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import { getCandidateMetadataActionEventName } from '../../Analytics/Candidate/CandidateEventUtils';
import { modules } from '../../Analytics/EventTypes';

const { TabPane } = Tabs;

function EnhancedCandidate360SecondaryInfoSection(props) {
  const {
    activeSourceName,
    activeTab,
    candidate = {},
    candidateContext,
    candidateDetailsStatus,
    isDiversityAttributesVisible,
    jobId,
    keywordsToHighlight,
  } = props;
  const [activeSecondaryInfoTab, setSecondaryActiveInfoTab] = React.useState('candidate-skills');

  const candidateSkills = candidate.Skills;
  const candidateEducation = candidate.Educations;
  const candidateKeywords =
    activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || [];
  const combinedKeywordsToHighlight = _.uniq([...(keywordsToHighlight || []), ...(candidateKeywords || [])]);

  const onTabChange = activeKey => {
    setSecondaryActiveInfoTab(activeKey);
    logSmartKarrotEvent(getCandidateMetadataActionEventName(activeKey), {
      Module: modules.candidate.candidateActions,
    });
  };

  const candidateTagStyle = { maxHeight: '150px', overflow: 'auto' };
  const secondaryInfoStyle = { padding: '0px 10px', maxHeight: '150px', overflow: 'auto' };

  return (
    <div className={styles.enhanced360SecondaryInfo}>
      <Tabs
        defaultActiveKey="candidate-skills"
        onChange={onTabChange}
        activeKey={activeSecondaryInfoTab}
        className={styles.candidate360SecondaryTabs}
      >
        <TabPane tab="Skills" key="candidate-skills">
          {candidateSkills?.length ? (
            <CandidateSkillsSection
              skills={candidateSkills}
              candidate={candidate}
              keywordsToHighlight={keywordsToHighlight}
              combinedKeywordsToHighlight={combinedKeywordsToHighlight}
              secondaryInfoStyle={secondaryInfoStyle}
              keywordHighlightKey=""
              shouldShowTooltip={false}
              tooltipPlacement="bottom"
            />
          ) : (
            <div className={styles.emptyTabScreenMessage}>No Data found</div>
          )}
        </TabPane>
        <TabPane tab="Education" key="candidate-education">
          {candidateEducation?.length ? (
            <CandidateEducationSection
              candidateEducation={candidateEducation}
              secondaryInfoStyle={secondaryInfoStyle}
              shouldShowTooltip={false}
            />
          ) : (
            <div className={styles.emptyTabScreenMessage}>No Data found</div>
          )}
        </TabPane>
        <TabPane tab="Others" key="candidate-metadata">
          <CandidateCardMetaData
            candidate={candidate}
            isDiversityAttributesVisible={isDiversityAttributesVisible}
            secondaryInfoStyle={{ padding: '0px 10px' }}
          />
        </TabPane>
        <TabPane tab="Tags" key="candidate-tags">
          <CandidateTagsContainer
            candidate={candidate}
            candidateContext={candidateContext}
            candidateDetailsStatus={candidateDetailsStatus}
            jobId={jobId}
            candidateTagStyle={candidateTagStyle}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default EnhancedCandidate360SecondaryInfoSection;
