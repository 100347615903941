import React from 'react';

function SelectedCandidatesText(props) {
  const { candidatesCount, candidateContext } = props;
  let showText;
  if (candidateContext === 'segment') {
    if (candidatesCount > 0) {
      showText = `${candidatesCount} Candidates found after appyling filters`;
    } else {
      showText = null;
    }
  } else if (candidatesCount > 1) {
    showText = `${candidatesCount} Candidates`;
  } else {
    showText = `${candidatesCount} Candidate`;
  }
  return <span style={{ color: '#83878c', fontSize: '14px' }}>{showText}</span>;
}

export default SelectedCandidatesText;
