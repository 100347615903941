import React from 'react';
import { Col, Input } from 'antd';
import OutputLocations from '../../../CompoundComponents/MultiLocationWrapper/OutputLocations/OutputLocations';
import MultiLocationWrapper from '../../../CompoundComponents/MultiLocationWrapper/MultiLocationWrapper';
import LocationRegionSelectDropdown from '../../../CompoundComponents/LocationWrapper/LocationRegionSelectDropdown/LocationRegionSelectDropdown';
import CountrySelect from '../../../CompoundComponents/MultiLocationWrapper/CountrySelect/CountrySelect';
import LocationSelect from '../../../CompoundComponents/MultiLocationWrapper/LocationSelect/LocationSelect';
import MileSelect from '../../../CompoundComponents/MultiLocationWrapper/MileSelect/MileSelect';
import RegionSelect from '../../../CompoundComponents/MultiLocationWrapper/RegionSelect/RegionSelect';
import EnhancedAddLocationButton from '../../../CompoundComponents/MultiLocationWrapper/EnhancedAddLocationButton/EnhancedAddLocationButton';
import LocationWrapper from '../../../CompoundComponents/LocationWrapper/LocationWrapper';

function EnhancedJobMultiLocationInput(props) {
  const {
    locations,
    onChange,
    isLocationSelectionLimitReached,
    isRegionSelectionLimitReached,
    defaultMilesObject,
    countryOptions,
    isCountryWideSearchEnabledCountry,
    form,
    isDistanceDisabled,
  } = props;
  const InputGroup = Input.Group;
  const key = locations?.[locations.length - 1]?.Id || null;
  const isFirstELement = locations?.length === 1;
  const location = locations?.[locations.length - 1] || null;
  const locationContext = form.getFieldValue('LocationContext');
  const isDisable = !isFirstELement || isDistanceDisabled;
  return (
    <MultiLocationWrapper
      onChange={onChange}
      currentKey={key}
      locations={locations}
      currentLocation={location}
      isLocationSelectionLimitReached={isLocationSelectionLimitReached}
      isRegionSelectionLimitReached={isRegionSelectionLimitReached}
      isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
      countryOptions={countryOptions}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '35px', width: 'max-content' }}>
        <div style={{ width: '140px' }}>
          <LocationWrapper form={form} layers="neighbourhood,borough,locality,localadmin,county,macrocounty">
            <LocationRegionSelectDropdown
              size="large"
              initialValue="Location"
              isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
            />
          </LocationWrapper>
        </div>

        {locationContext === 'Location' ? (
          <InputGroup compact style={{ width: '650px', display: 'flex', flexDirection: 'row' }}>
            <div style={{ minWidth: '120px', width: 'max-content' }}>
              {' '}
              <CountrySelect isDisabled={isDisable} />
            </div>
            <div style={{ width: 'max-content', minWidth: '240px' }}>
              <LocationSelect placeholder="City, State, ZIP Code/Postal Code" isRemoteJobChecked={isDistanceDisabled} />
            </div>
            <div style={{ paddingLeft: '20px', width: 'max-content', minWidth: '10px', maxWidth: '200px' }}>
              <MileSelect
                locationType="MultiLocation"
                isCountryWideSearchEnabled={isCountryWideSearchEnabledCountry}
                initialRange={defaultMilesObject}
                isRemoteJob={isDistanceDisabled}
                currentKey={key}
              />
            </div>
          </InputGroup>
        ) : (
          // <Row gutter="16">
          <>
            {' '}
            <InputGroup compact style={{ width: '', maxWidth: 'fit-content' }}>
              <div style={{ minWidth: '120px', width: 'max-content' }}>
                {' '}
                <CountrySelect isDisabled={isDisable} />
              </div>
              <div style={{ width: '200px' }}>
                <RegionSelect isRemoteJobChecked={isDistanceDisabled || isCountryWideSearchEnabledCountry} />
              </div>
              <Col span={7}>
                <div style={{ paddingLeft: '20px', width: '' }}>
                  {' '}
                  <MileSelect
                    locationType="MultiLocation"
                    isCountryWideSearchEnabled={isCountryWideSearchEnabledCountry}
                    initialRange={defaultMilesObject}
                    isRemoteJob={isDistanceDisabled}
                    currentKey={key}
                  />
                </div>
              </Col>
            </InputGroup>
            {/* </Row> */}
          </>
        )}
        <EnhancedAddLocationButton isRemoteJobChecked={isDistanceDisabled} locationContext={locationContext} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          margin: '18px 0px 18px',
          width: '900px',
          flexWrap: 'wrap',
        }}
      >
        <OutputLocations isRemoteJobChecked={isDistanceDisabled} form={form} isAdvanceSearch />
      </div>
    </MultiLocationWrapper>
  );
}

export default EnhancedJobMultiLocationInput;
