/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Button, Tooltip, Empty } from 'antd';
import _ from 'lodash';
import * as d3 from 'd3';
import MapData from '../../Data/UsStatesMap.json';
import './JobIntel.scss';
import { EmptyOccupationIcon, EmptyKeywordsIcon, EmptyIndustryIcon, EmptyCompanyIcon } from '../../Icons/AryaIcons';

function classes(root) {
  const classArray = root.map(node => ({
    className: node.name,
    value: node.size,
  }));
  return { children: classArray };
}

class JobIntel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyListView: false,
      industryListView: false,
      heatMapListView: false,
      keywordListView: false,
    };

    this.getCompaniesDataElement = this.getCompaniesDataElement.bind(this);
    this.getIndustriesDataElement = this.getIndustriesDataElement.bind(this);
    this.getKeywordsDataElement = this.getKeywordsDataElement.bind(this);
    this.getHeatMapElement = this.getHeatMapElement.bind(this);
  }

  getCompaniesDataElement(title, description, companies, values) {
    if (!_.get(companies, [('companyNames', 'length')], false)) {
      return (
        <div className="content">
          <div className="title">{title}</div>
          <Empty
            description={
              <span>
                <b>No companies found</b>
                <br />
                Please update the job info to see companies.
              </span>
            }
            image={
              // <img
              //   src={`${process.env.PUBLIC_URL}/static/Images/empty-company.svg`}
              //   height="128px"
              //   width="144px"
              //   alt=""
              // />
              <EmptyCompanyIcon style={{ fontSize: '100px' }} />
            }
          />
        </div>
      );
    }

    const sum = values.reduce((a, b) => a + b);
    const topFive = [];

    for (let index = 0; index < Math.min(5, companies.length); index += 1) {
      topFive.push(
        <div className="top-company" key={companies[index]}>
          <div>{companies[index]}</div>
          <Progress percent={((100 * values[index]) / sum || 0).toFixed(2)} size="small" strokeLinecap="square" />
        </div>
      );
    }

    const { companyListView } = this.state;
    return (
      <div className="companies">
        {!companyListView ? (
          <React.Fragment>
            <div className="content">
              <div className="title">
                {`${title} (${companies.length})`}
                <Button
                  onClick={() => {
                    this.setState({ companyListView: true });
                  }}
                >
                  See List
                </Button>
              </div>
              <div className="description">
                {description}
                <div className="topFive">Top 5</div>
                {topFive}
              </div>
            </div>
            <div className="charts">{this.createCompanyBarChart(companies.slice(0, 10), values.slice(0, 10))}</div>
          </React.Fragment>
        ) : (
          <div className="list-view">
            <div className="title">
              {title}
              <Button
                onClick={() => {
                  this.setState({ companyListView: false });
                }}
              >
                X
              </Button>
            </div>
            <div className="list">
              {companies.map((company, index) => {
                return (
                  <div className="list-item">
                    <span className="percent">{((100 * values[index]) / sum || 0).toFixed(2)}% </span>
                    <span className="identifier">{company}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  getIndustriesDataElement(title, description, industries, values) {
    if (!_.get(industries, [('industryNames', 'length')], false)) {
      return (
        <div className="content">
          <div className="title">{title}</div>
          <Empty
            description={
              <span>
                <b>No industries found</b>
                <br />
                Please update the job info to see industries.
              </span>
            }
            image={
              // <img
              //   src={`${process.env.PUBLIC_URL}/static/Images/empty-industry.svg`}
              //   height="128px"
              //   width="144px"
              //   alt=""
              // />
              <EmptyIndustryIcon style={{ fontSize: '100px' }} />
            }
          />
        </div>
      );
    }

    const topFive = [];
    const sum = values.reduce((a, b) => a + b);

    for (let index = 0; index < Math.min(5, industries.length); index += 1) {
      topFive.push(
        <div className="top-industry" key={industries[index]}>
          <div>{industries[index]}</div>
          <Progress percent={((100 * values[index]) / sum || 0).toFixed(2)} size="small" strokeLinecap="square" />
        </div>
      );
    }

    const chartConfig = {
      width: 600,
      height: 300,
      padding: 10,
      backgroundColor: '#FFFFFF',
      strokeWidth: '3px',
      stroke: '#13C26B',
    };

    const data = industries.map((industry, index) => {
      return { name: industry, size: values[index] };
    });

    const { industryListView } = this.state;

    return (
      <div className="industries">
        {!industryListView ? (
          <React.Fragment>
            <div className="content">
              <div className="title">
                {`${title} (${industries.length})`}
                <Button
                  onClick={() => {
                    this.setState({ industryListView: true });
                  }}
                >
                  See List
                </Button>
              </div>
              <div className="description">
                {description}
                <div className="topFive">Top 5</div>
                {topFive}
              </div>
            </div>
            <div className="charts">{this.createBubbleChart(data, chartConfig, true)}</div>
          </React.Fragment>
        ) : (
          <div className="list-view">
            <div className="title">
              {title}
              <Button
                onClick={() => {
                  this.setState({ industryListView: false });
                }}
              >
                X
              </Button>
            </div>
            <div className="list">
              {industries.map((industry, index) => {
                return (
                  <div className="list-item">
                    <span className="percent">{((100 * values[index]) / sum || 0).toFixed(2)}% </span>
                    <span className="identifier">{industry}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  getKeywordsDataElement(title, description, data) {
    const { keywordListView } = this.state;
    const chartConfig = {
      width: 200,
      height: 250,
      padding: 5,
    };

    if (!(data.mustHaves.length || data.relevant.length || data.niceToHaves.length)) {
      return (
        <div className="content">
          <div className="title">{title}</div>
          <Empty
            description={
              <span>
                <b>No keywords found</b>
                <br />
                Please update the job info to see keywords.
              </span>
            }
            image={
              // <img
              //   src={`${process.env.PUBLIC_URL}/static/Images/empty-keywords.svg`}
              //   height="128px"
              //   width="144px"
              //   alt=""
              // />
              <EmptyKeywordsIcon style={{ fontSize: '100px' }} />
            }
          />
        </div>
      );
    }

    return (
      <div className="keywords">
        {!keywordListView ? (
          <React.Fragment>
            <div className="content">
              <div className="title">
                {title}
                <Button
                  onClick={() => {
                    this.setState({ keywordListView: true });
                  }}
                >
                  See List
                </Button>
              </div>
              <div className="description">
                {description}
                <div className="keyword-list">
                  <div>
                    <svg height="16" width="16">
                      <circle cx="8" cy="8" r="8" fill="#1991EB" />
                    </svg>
                    Must Haves
                  </div>
                  <div>
                    <svg height="16" width="16">
                      <circle cx="8" cy="8" r="8" fill="#13C26B" />
                    </svg>
                    Relevant
                  </div>
                  <div>
                    <svg height="16" width="16">
                      <circle cx="8" cy="8" r="8" fill="#DADBDD" />
                    </svg>
                    Nice to Haves
                  </div>
                </div>
              </div>
            </div>
            <div className="charts">
              {data.mustHaves.length
                ? this.createBubbleChart(data.mustHaves, {
                    ...chartConfig,
                    backgroundColor: 'rgba(25, 145, 235, 0.2)',
                    showCount: false,
                  })
                : null}
              {data.relevant.length
                ? this.createBubbleChart(data.relevant, {
                    ...chartConfig,
                    backgroundColor: 'rgba(19, 194, 107, 0.2)',
                    showCount: false,
                  })
                : null}
              {data.niceToHaves.length
                ? this.createBubbleChart(data.niceToHaves, {
                    ...chartConfig,
                    backgroundColor: '#E6E7E8',
                    showCount: false,
                  })
                : null}
            </div>
          </React.Fragment>
        ) : (
          <div className="list-view">
            <div className="title">
              {title}
              <Button
                onClick={() => {
                  this.setState({ keywordListView: false });
                }}
              >
                X
              </Button>
            </div>
            <div className="list">
              {data.mustHaves.map(mustHave => {
                return (
                  <div className="list-item">
                    <span className="identifier must-have">{mustHave.name}</span>
                  </div>
                );
              })}
              {data.relevant.map(relevantSkill => {
                return (
                  <div className="list-item">
                    <span className="identifier relevant">{relevantSkill.name}</span>
                  </div>
                );
              })}
              {data.niceToHaves.map(niceToHave => {
                return (
                  <div className="list-item">
                    <span className="identifier nice-to-have">{niceToHave.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  getJobFunctionsDataElement(title, description, data) {
    return _.get(data, ['children', 'length'], false) ? (
      <div className="job-functions">
        <div className="content">
          <div className="title">{`${title} (${data.children.length})`}</div>
          <div className="description">{description}</div>
        </div>
        <div className="charts" style={{ overflowY: 'scroll', height: 320 }}>
          {this.createTreeMap(data)}
        </div>
      </div>
    ) : (
      <div className="content">
        <div className="title">{title}</div>
        <Empty
          description={
            <span>
              <b>No occupations found</b>
              <br />
              Please update the job info to see occupations.
            </span>
          }
          image={
            // <img
            //   src={`${process.env.PUBLIC_URL}/static/Images/empty-occupation.svg`}
            //   height="128px"
            //   width="144px"
            //   alt=""
            // />
            <EmptyOccupationIcon style={{ fontSize: '100px' }} />
          }
        />
      </div>
    );
  }

  getHeatMapElement(title, description, data) {
    const { heatMapListView } = this.state;
    return (
      <div className="heat-map">
        {!heatMapListView ? (
          <React.Fragment>
            <div className="content">
              <div className="title">
                {title}
                <Button
                  onClick={() => {
                    this.setState({ heatMapListView: true });
                  }}
                >
                  See List
                </Button>
              </div>
              <div className="description">{description}</div>
            </div>
            <div className="charts">{this.createHeatMap(data)}</div>
          </React.Fragment>
        ) : (
          <div className="list-view">
            <div className="title">
              {title}
              <Button
                onClick={() => {
                  this.setState({ heatMapListView: false });
                }}
              >
                X
              </Button>
            </div>
            <div className="list">
              {Object.keys(MapData).map(stateName => {
                return (
                  <div className="list-item">
                    <span className="percent">{_.get(data, [stateName, 'Count'], 0)} </span>
                    <span className="identifier">{stateName}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  getHeatmapOpacity(candidatesCount) {
    let fillOpacity = 0.4;
    if (candidatesCount > 800) {
      fillOpacity = 1;
    } else if (candidatesCount > 600) {
      fillOpacity = 0.85;
    } else if (candidatesCount > 400) {
      fillOpacity = 0.7;
    } else if (candidatesCount > 200) {
      fillOpacity = 0.55;
    }

    return fillOpacity;
  }

  createHeatMap(data) {
    const graph = (
      <svg height="400" width="500" preserveAspectRatio="xMinYMin">
        <g transform="matrix(0.45,0,0,0.45,0,0)">
          {Object.keys(MapData).map(stateName => {
            const candidatesCount = _.get(data, [stateName, 'Count'], 0);
            const fillOpacity = this.getHeatmapOpacity(candidatesCount);
            return (
              <Tooltip title={`${stateName} ${candidatesCount}`}>
                <path d={MapData[stateName]} fill="#13C26B" fillOpacity={fillOpacity} key={stateName} />
              </Tooltip>
            );
          })}
        </g>
      </svg>
    );

    return graph;
  }

  createCompanyBarChart(companies, values) {
    const width = 592;
    const height = 320;
    const maxValue = Math.max(...values);

    const svg = (
      <svg width={592} height={height}>
        {values.map((dataPoint, index) => (
          <Tooltip key={`key${index.toString()}`} title={`${dataPoint} Profiles`}>
            <rect
              className="bar"
              height={240 * (dataPoint / maxValue) || 0}
              width={24}
              x={index * 24 + (index + 1) * 32}
              y={240 * (1 - dataPoint / maxValue)}
              fill="#13C26B"
            />
          </Tooltip>
        ))}
        {companies.map((company, index) => (
          <Tooltip key={`key${index.toString()}`} title={company}>
            <text fill="rgba(7,16,26,0.7)" transform={`translate(${20 + index * 56}, 300)rotate(-45)`}>
              {company.length > 10 ? `${company.substr(0, 7)}...` : company}
            </text>
          </Tooltip>
        ))}
        <line x1="0" y1={240} x2={width} y2={240} stroke="#DADBDD" />
      </svg>
    );

    return svg;
  }

  createBubbleChart(data, chartConfig, showCount) {
    const { width, height, padding, backgroundColor, stroke, strokeWidth } = chartConfig;

    const bubble = d3
      .pack()
      .size([width, height])
      .padding(padding);

    const root = d3
      .hierarchy(classes(data))
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);

    bubble(root);

    const svg = (
      <svg className="bubble" width={width} height={height}>
        {root.children.map((dataPoint, index) => (
          <Tooltip
            key={`key${index.toString()}`}
            title={`${dataPoint.data.className}${showCount ? ` (${data[index].size} Profiles)` : ''}`}
          >
            <g className="node" transform={`translate(${dataPoint.x},${dataPoint.y})`}>
              <circle r={dataPoint.r.toString()} fill={backgroundColor} stroke={stroke} strokeWidth={strokeWidth} />
              <text dy=".3em" textAnchor="middle">
                {dataPoint.data.className.substr(0, Math.round(dataPoint.r / 4))}
              </text>
            </g>
          </Tooltip>
        ))}
      </svg>
    );

    return svg;
  }

  createTreeMap(data) {
    const root = d3.hierarchy(data);
    const diagonal = d3
      .linkHorizontal()
      .x(d => d.y)
      .y(d => d.x);
    const tree = d3.tree().nodeSize([20, 240]);
    tree(root);

    const treeNodes = [];
    const paths = [];

    let minY = 1000;
    let maxY = -1000;

    const nodes = root.descendants();
    nodes.forEach(node => {
      if (node.data.Name !== 'Job Functions')
        treeNodes.push(
          <g
            key={node.data.Name || node.data.Title}
            transform={`translate(${node.y},${node.x})`}
            fillOpacity={1}
            strokeOpacity={1}
          >
            <circle r={5} fill="#555" />
            <Tooltip title={node.data.Name || node.data.Title}>
              <text
                dy="0.31em"
                x={_.get(node, ['parent', 'data', 'Name'], '') === 'Job Functions' ? -6 : 6}
                textAnchor={_.get(node, ['parent', 'data', 'Name'], '') === 'Job Functions' ? 'end' : 'start'}
                fontSize="14px"
              >
                {_.truncate(node.data.Name || node.data.Title, { length: 24 })}
              </text>
            </Tooltip>
          </g>
        );

      if (node.parent && node.parent.data.Name !== 'Job Functions') {
        paths.push(
          <path
            d={diagonal({
              source: { x: node.parent.x, y: node.parent.y },
              target: { x: node.x, y: node.y },
            })}
          />
        );
      }

      if (minY > node.x) minY = node.x;
      if (maxY < node.x) maxY = node.x;
    });

    const margin = { left: 120, top: minY };
    const height = maxY - minY + 40;
    const width = 480;

    const treeMap = (
      <svg
        width="100%"
        height={height}
        viewBox={[margin.left, margin.top - 20, width, height]}
        style={{
          font: '10px sans-serif',
          userSelect: 'none',
        }}
      >
        <g fill="none" stroke="#555" strokeOpacity={0.4} strokeWidth={1.5}>
          {paths.map(node => node)}
        </g>
        <g>{treeNodes.map(node => node)}</g>
      </svg>
    );

    return treeMap;
  }

  render() {
    const { intelData, intelLocationData, countryCode, AppName } = this.props;
    const { keywordSetData, companies, industries, jobFunctionsData } = intelData;

    const keywordSetDescription = `Keywords related to your search that help ${AppName} finding relevant candidates.`;
    const companiesDescription = 'Companies related to your search that may be helpful in finding similar candidates.';
    const industriesDescription =
      'Industries related to your search that may be helpful in finding similar candidates.';
    const jobFunctionsDescription =
      'Occupations related to your search that help you find candidates with relevant experience.';
    const heatMapDescription = 'This map shows related candidates located in a specific area or locality.';

    return (
      <div className="job-intel">
        {this.getCompaniesDataElement(
          'Companies',
          companiesDescription,
          companies.companyNames,
          companies.companyValues
        )}
        {this.getIndustriesDataElement(
          'Industries',
          industriesDescription,
          industries.industryNames,
          industries.industryValues
        )}
        {this.getKeywordsDataElement('Keyword Sets', keywordSetDescription, keywordSetData)}
        {this.getJobFunctionsDataElement('Occupations', jobFunctionsDescription, jobFunctionsData)}
        {countryCode === 'US' ? this.getHeatMapElement('Heat Map', heatMapDescription, intelLocationData) : null}
      </div>
    );
  }
}

JobIntel.propTypes = {
  intelData: PropTypes.shape({
    blsData: PropTypes.shape({
      countryData: {
        EmploymentChange: PropTypes.number,
        WorkExperience: PropTypes.number,
        MedianPay: PropTypes.number,
        TotalEmployment: PropTypes.number,
        ProjectedGrowth: PropTypes.string,
        EntryEducation: PropTypes.string,
      },
      metroData: {
        MedianPay: PropTypes.number,
        LocationQuotient: PropTypes.number,
        EmploymentChange: PropTypes.number,
      },
    }),
    companies: {
      companyValues: PropTypes.arrayOf(PropTypes.number),
      companyNames: PropTypes.arrayOf(PropTypes.string),
    },
    industries: {
      industryValues: PropTypes.arrayOf(PropTypes.number),
      industryNames: PropTypes.arrayOf(PropTypes.string),
    },
    keywordSetData: PropTypes.shape({
      mustHaves: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          size: PropTypes.number,
        })
      ),
      relevant: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          size: PropTypes.number,
        })
      ),
      niceToHaves: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          size: PropTypes.number,
        })
      ),
    }),
    jobFunctionsData: PropTypes.shape({}),
  }),
  intelLocationData: PropTypes.shape({}),
  countryCode: PropTypes.string.isRequired,
};

JobIntel.defaultProps = {
  intelData: {
    blsData: {},
    companies: {
      companyValues: [],
      companyNames: [],
    },
    industries: {
      industryValues: [],
      industryNames: [],
    },
    keywordSetData: {
      mustHaves: [],
      relevant: [],
      niceToHaves: [],
    },
    jobFunctionsData: {
      name: 'Job Functions',
    },
  },
  intelLocationData: {},
};

export default JobIntel;
