import React from 'react';
import moment from 'moment';
import { Badge } from 'antd';
import { EmailIcon } from '../../../Icons/AryaIcons';
import './EmailNotification.scss';

function getIcon() {
  return (
    <EmailIcon
      style={{ fontSize: 20, padding: 10, color: '#13C26B', backgroundColor: '#DCF6E9', borderRadius: '50%' }}
    />
  );
}
function EmailNotification(props) {
  const { notification, jobDetails, onClick } = props;
  const { Id, Type, UnreadCount } = notification;
  const { CreatedTime, ConversationId, PersonId, PersonName } = notification.Payload;
  return (
    <div
      className="connect-notification"
      onClick={() => onClick(ConversationId, PersonId, Id, Type, null, jobDetails.JobId, notification.Payload)}
      role="presentation"
    >
      <div className="icon">{getIcon()}</div>
      <div className="content">
        <div className="sender-name">Email received from {PersonName}</div>
        <Badge dot={UnreadCount > 0}>
          <div className="message">
            {jobDetails.JobTitle ? (
              <span>
                for {jobDetails.JobTitle} {jobDetails.JobId}
              </span>
            ) : null}
          </div>
        </Badge>
        <div className="since">
          {moment
            .utc(CreatedTime)
            .local()
            .fromNow()}
        </div>
      </div>
    </div>
  );
}

export default EmailNotification;
