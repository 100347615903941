import React from 'react';
import { Empty } from 'antd';
import _ from 'lodash';
import './CandidateList.scss';
import { EmptyCandidatesIcon } from '../../Icons/AryaIcons';
import { isJobSourcingCompleted } from '../../Utils/JobSourcingStatsUtil';
import { isSourcingDeactivated } from '../../Utils/SourceUtils';
import { getCandidateStatusForSegment } from '../../Utils/CandidateListUtils';
import CandidateCardContainer from '../CandidateCard/CandidateCardContainer';
import { getIsDeDuplicationAllowed } from '../../Utils/DeDuplicationUtils';
import CandidateListEmptyScreen from '../../Containers/ManualSearchV2/ManualSearchForm/CandidateListEmptyScreen';

function CandidateList(props) {
  const {
    candidates,
    onCandidateStatusChange,
    showCandidateDetail,
    setComposeEmailType,
    connectStatusLoaded,
    onCandidateCheckBoxClick,
    status,
    publishedStatus,
    connectionStatus,
    isFilterApplied,
    onClickAtsPush,
    candidateType,
    clearAllFilter,
    size,
    isCandidateChecked,
    showCandidateCheckboxSelection,
    version,
    keywordsToHighlight,
    featureToggleList,
    openCandidateView,
    activeTab,
    candidateCount,
    dilatedCandidateStart,
    jobId,
    candidateContext = 'job',
    currentJobDetails,
    customCheckBoxClassName,
    handleContactPull,
    config,
    isCheckboxDisabled,
    showShortlistIconReject,
    isDiversityAttributesVisible,
    showConnectWidget,
    activeSourceName,
  } = props;

  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  // !! TODO: Move this empty screen logic to respective containers
  if (candidates.length === 0 && candidateType !== 'quick-search') {
    const { recentSourcedTime, lastChangeTime } = currentJobDetails;
    const userConfiguredSources = config?.PortalSources ?? [];
    const jobConfiguredSources = currentJobDetails?.aryaState ?? {};
    const deactivated = isSourcingDeactivated(userConfiguredSources, jobConfiguredSources);

    const showSourcingInprogress =
      (activeTab?.toLowerCase() === 'sourced' || activeTab?.toLowerCase() === 'ranked') &&
      activeSourceName !== 'scoutingAgent' &&
      !isSourcingDeactivated(userConfiguredSources, jobConfiguredSources) &&
      !isJobSourcingCompleted(recentSourcedTime, lastChangeTime) &&
      !isPaidJobServiceEnabled;
    const pipelineStatus = connectionStatus || (isPaidJobServiceEnabled ? publishedStatus : status);

    let emptyCandidateStatus = '';

    if (candidateContext === 'job') {
      emptyCandidateStatus = pipelineStatus?.toLowerCase();
    } else if (candidateContext === 'segment') {
      emptyCandidateStatus = getCandidateStatusForSegment(pipelineStatus);
    }
    let noCandidatesFoundMessage = '';
    if (candidateContext === 'job') {
      noCandidatesFoundMessage = `All${showSourcingInprogress ? ' newly' : ''
        } ${emptyCandidateStatus?.toLowerCase()} candidates will appear here`;
    } else {
      noCandidatesFoundMessage =
        pipelineStatus === 'Shortlisted'
          ? 'All selected candidates will appear here'
          : 'Removed candidates will appear here';
    }
    if (activeSourceName === 'scoutingAgent') {
      noCandidatesFoundMessage = 'No candidates found till now';
    }
    const { AdvanceSearchV2, AryaAutoSourcing } = featureToggleList || {};
    const advanceSearchV2 = AdvanceSearchV2?.IsEnabled;
    const aryaAutoSourcing = AryaAutoSourcing?.IsEnabled;
    const IfV2IsEnableAndActiveTabIsRanked = (advanceSearchV2 && activeTab?.toLowerCase() === 'ranked');
    return (
      !IfV2IsEnableAndActiveTabIsRanked ? (
        <Empty
          image={<EmptyCandidatesIcon className="candidate-empty-list" />}
          description={
            <div>
              <div className="candidate-list-no-candidates-found">
                {!isFilterApplied && showSourcingInprogress ? 'Sourcing is in progress...' : 'No candidates Found'}
              </div>
              {!isFilterApplied ? (
                <div className="candidate-list-no-candidates-found-message">{noCandidatesFoundMessage}</div>
              ) : (
                <div
                  className="candidate-list-remove-filter"
                  role="button"
                  tabIndex={0}
                  onClick={clearAllFilter}
                  onKeyPress={clearAllFilter}
                >
                  Clear Filter
                </div>
              )}
            </div>
          }
        />
      ) : (
        <CandidateListEmptyScreen
          clearAllFilter={clearAllFilter}
          isFilterApplied={isFilterApplied}
          showSourcingInprogress={showSourcingInprogress}
          AryaAutoSourcing={aryaAutoSourcing}
          deactivated={deactivated}
        />
      )
    );
  }

  const getCandidateListItem = (candidate, index) => {
    const candidateIndex = getIsDeDuplicationAllowed(activeSourceName) ? candidate.candidateIndex : index;

    return (
      <CandidateCardContainer
        isCandidateChecked={isCandidateChecked(candidate, index)}
        key={candidate.Id}
        candidateId={candidate.Id}
        candidateContext={candidateContext}
        isDiversityAttributesVisible={isDiversityAttributesVisible}
        activeTab={activeTab}
        indexKey={candidateIndex}
        dilatedCandidateStart={dilatedCandidateStart}
        customCheckBoxClassName={customCheckBoxClassName}
        candidateCount={candidateCount}
        showCandidateCheckboxSelection={showCandidateCheckboxSelection}
        version={version}
        onCandidateCheckBoxClick={onCandidateCheckBoxClick}
        isCheckboxDisabled={isCheckboxDisabled}
        showCandidateDetail={showCandidateDetail}
        setComposeEmailType={setComposeEmailType}
        status={status}
        onCandidateStatusChange={onCandidateStatusChange}
        connectStatusLoaded={connectStatusLoaded}
        onClickAtsPush={onClickAtsPush}
        keywordsToHighlight={keywordsToHighlight}
        candidateType={candidateType}
        size={size}
        openCandidateView={openCandidateView}
        handleContactPull={handleContactPull}
        showShortlistIconReject={showShortlistIconReject}
        jobId={jobId}
        showConnectWidget={showConnectWidget}
      />
    );
  };

  return (
    <>
      {candidates
        .filter(candidate => !_.isEmpty(candidate))
        .map((candidate, index) => getCandidateListItem(candidate, index))}
    </>
  );
}

export default CandidateList;
