/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Tooltip } from 'antd';
import './Pipeline.scss';
import { UnlockIcon } from '../../Icons/AryaIcons';

export const Pipeline = ({ children, activePipelineItem, size }) => {
  return (
    <div className="pipeline">
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            isActive: activePipelineItem ? activePipelineItem === child.props.name : child.props.isActive,
            size,
          });
        }
        return null;
      })}
    </div>
  );
};

export const PipelineItem = ({
  tooltipContent,
  className,
  children,
  activatedClassName,
  isActive,
  onClick,
  size,
  dry,
  disabled,
  tooltipPlacement = 'top',
  overlayClassName = '',
  content,
  skEventName,
  smartRecruiterVersion,
  isLocked = false,
}) => {
  const pipelineItemClassName = `pipeline-item ${size === 'small' ? 'pipeline-item-small' : ''} ${
    disabled || dry ? 'pipeline-item-disabled' : className
  } ${isActive ? activatedClassName : ''}`;
  return (content === 'Arya Ranked' && smartRecruiterVersion !== 'v2') || isLocked ? (
    <Popover
      trigger="hover"
      overlayStyle={{ width: '450px', height: 'fit-content' }}
      placement="top"
      content={
        <div className="tooltipUnlockWrapper">
          <div className="tooltipUnlockHeader">Unlock Arya Ranked</div>
          <div className="toolTipUnlockBody">
            <span>
              {' '}
              Upgrade to Arya Auto plan to access candidate scoring, ranking and proactive candidate sourcing matching
              to job criteria.<a href="mailto:support@leoforce.com"> Contact Support</a>
            </span>
          </div>
        </div>
      }
    >
      <Tooltip
        overlayClassName={overlayClassName}
        placement={tooltipPlacement}
        title={tooltipContent}
        visible={!isLocked}
      >
        <div style={{ position: 'relative', width: '100%' }} className="noselect">
          <div
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            className={pipelineItemClassName}
            onClick={disabled ? null : onClick}
            onKeyDown={disabled ? null : onClick}
            role="presentation"
          >
            <div className="pipeline-item-title" sk-event-name={skEventName}>
              {children}
              {isLocked ? (
                <div className="unlock-icon">
                  <UnlockIcon style={{}} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Tooltip>
    </Popover>
  ) : (
    <Tooltip overlayClassName={overlayClassName} placement={tooltipPlacement} title={tooltipContent}>
      <div style={{ position: 'relative', width: '100%' }} className="noselect">
        <div
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          className={pipelineItemClassName}
          onClick={disabled ? null : onClick}
          onKeyDown={disabled ? null : onClick}
          role="presentation"
        >
          <div className="pipeline-item-title" sk-event-name={skEventName}>
            {children}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

PipelineItem.propTypes = {
  tooltipContent: PropTypes.string,
  className: PropTypes.string.isRequired,
};

PipelineItem.defaultProps = {
  tooltipContent: '',
};
