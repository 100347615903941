import React from 'react';
import { Drawer, Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import DripsDisplayContainer from '../../../../Containers/DripsDisplayContainer/DripsDisplayContainer';
import './CreateDripTemplate.scss';

function CreateDripTemplate(props) {
  const {
    visible,
    onClose,
    viewOnly,
    initialValues,
    form,
    onSubmit,
    mergeTags,
    fetchDripTemplateApiStatus,
    type,
    version,
    jobDetails,
  } = props;
  const showLoadingScreen = type === 'edit' && !['COMPLETED', 'FAILED'].includes(fetchDripTemplateApiStatus);
  return (
    <Drawer
      className="create-template drip drawer dripemailhead"
      destroyOnClose
      title="Drip Template"
      visible={visible}
      onClose={onClose}
      width={650}
    >
      {showLoadingScreen ? (
        <Skeleton active paragraph={{ rows: 12 }} loading />
      ) : (
        <DripsDisplayContainer
          initialValues={initialValues}
          viewOnly={viewOnly}
          form={form}
          onSubmit={onSubmit}
          mergeTags={mergeTags}
          onClose={onClose}
          version={version}
          jobDetails={jobDetails}
          isCreateDripTemplate
        />
      )}
    </Drawer>
  );
}

CreateDripTemplate.propTypes = {
  initialValues: PropTypes.shape({}),
};

CreateDripTemplate.defaultProps = {
  initialValues: {},
};

export default Form.create()(CreateDripTemplate);
export { CreateDripTemplate as CreateDripTemplateWithoutForm };
