import _ from 'lodash';
import cleanSet from 'clean-set';
import { createSelector } from 'reselect';
import deepEqual from 'fast-deep-equal';

import {
  SET_MANUAL_SEARCH_CANDIDATES_FETCH_API_STATUS_BY_SOURCE,
  SET_CANDIDATES_BY_SOURCE,
  SET_CANDIDATES_COUNT_BY_SOURCE,
  SET_SECONDS_ELAPSED_TO_FETCH_CANDIDATES_BY_SOURCE,
  SET_ALL_TAB_CANDIDATE_IDS,
  SET_CANDIDATES_FETCHED_COUNT_BY_SOURCE,
  SET_ALL_TAB_TOTAL_CANDIDATES_COUNT,
  CLEAR_MANUAL_SEARCH_CANDIDATES,
  SET_CANDIDATE_GROUPS,
  SET_CANDIDATE_GROUPS_INFO,
} from '../Actions/ActionCreators/ManualSearchCandidateActionCreators';
import { allowedDeDuplicationSources } from '../Utils/CandidateListUtils';

const defaultEmptyObject = {};

function ManualSearchCandidateReducer(state = {}, action = {}) {
  let newState;
  let payload;
  switch (action.type) {
    case SET_MANUAL_SEARCH_CANDIDATES_FETCH_API_STATUS_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, source, status } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'BySource', source, 'ApiStatus'], status);
      return newState;
    }

    case SET_CANDIDATES_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, candidates = [], source, candidateAggregations } = payload;
      if (!candidates.length) return state;
      const candidateDetails = state?.ByJobId[jobId]?.BySource?.[source];
      const candidatesById = {};
      const candidatesIndexVsId = {};
      const candidatesByJobIdBySource = {};
      const batchesFetchedSoFar = _.get(state, ['ByJobId', jobId, 'BatchesFetched'], 0);
      candidates.forEach((candidate, index) => {
        const _index = batchesFetchedSoFar * 25 + index;
        candidatesById[candidate.Id] = { ...candidate, Index: _index };
        candidatesIndexVsId[_index] = { Id: candidate.Id, Index: _index, Source: source };
        candidatesByJobIdBySource[candidate.Id] = { Id: candidate.Id, ...candidate, Index: _index };
      });
      const existingCandidatesById = _.get(state, ['ByJobId', jobId, 'CandidatesById'], {});
      const existingCandidatesIndexVsId = _.get(
        state,
        ['ByJobId', jobId, 'BySource', source, 'CandidatesIndexVsId'],
        {}
      );
      const existingCandidatesByJobIdBySource = _.get(
        state,
        ['ByJobId', jobId, 'BySource', source, 'CandidatesById'],
        {}
      );
      const newCandidateDetails = {
        ...candidateDetails,
        CandidatesIndexVsId: { ...existingCandidatesIndexVsId, ...candidatesIndexVsId },
        CandidatesById: { ...existingCandidatesByJobIdBySource, ...candidatesByJobIdBySource },
        CandidateAggregations: candidateAggregations,
      };
      newState = cleanSet(state, ['ByJobId', jobId, 'BySource', source], newCandidateDetails);
      newState = cleanSet(newState, ['ByJobId', jobId, 'CandidatesById'], {
        ...existingCandidatesById,
        ...candidatesById,
      });
      return newState;
    }
    case SET_CANDIDATES_FETCHED_COUNT_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, count, source, isMore } = payload;
      const existingFetchedCount = _.get(state, ['ByJobId', jobId, 'BySource', source, 'FetchedCount'], 0);
      const newFetchedCount = isMore ? existingFetchedCount + count : count;
      newState = cleanSet(state, ['ByJobId', jobId, 'BySource', source, 'FetchedCount'], newFetchedCount);
      return newState;
    }

    case SET_CANDIDATES_COUNT_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, count, source } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'BySource', source, 'TotalCount'], count);
      return newState;
    }

    case SET_SECONDS_ELAPSED_TO_FETCH_CANDIDATES_BY_SOURCE: {
      ({ payload } = action);
      const { jobId, source, secondsElapsed } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'BySource', source, 'SecondsElapsed'], secondsElapsed);
      return newState;
    }

    case SET_ALL_TAB_TOTAL_CANDIDATES_COUNT: {
      ({ payload } = action);
      const { jobId, count } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'AllTabTotalCandidatesCount'], count);
      return newState;
    }

    case SET_ALL_TAB_CANDIDATE_IDS: {
      ({ payload } = action);
      const { jobId, sources } = payload;
      const allTabCandidateIds = [];
      const candidatesBySource = _.get(state, ['ByJobId', jobId, 'BySource'], {});
      Object.values(candidatesBySource).forEach(x => {
        if (x?.CandidatesIndexVsId) allTabCandidateIds.push(...Object.values(x.CandidatesIndexVsId));
      });
      const sortedAllTabCandidateIds = allTabCandidateIds.sort((x, y) => {
        if (x.Index === y.Index) return sources[x.Source] - sources[y.Source];
        return x.Index - y.Index;
      });
      const batchesFetchedSoFar = _.get(state, ['ByJobId', jobId, 'BatchesFetched'], 0);
      const jobManualSearchState = _.get(state, ['ByJobId', jobId]);
      const newJobManualSearchState = {
        ...jobManualSearchState,
        AllTabCandidates: sortedAllTabCandidateIds,
        AllTabFetchedCandidatesCount: sortedAllTabCandidateIds.length,
        BatchesFetched: batchesFetchedSoFar + 1,
      };
      newState = cleanSet(state, ['ByJobId', jobId], newJobManualSearchState);
      return newState;
    }
    case SET_CANDIDATE_GROUPS: {
      ({ payload } = action);
      const { jobId, candidateGroupInfo } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'CandidateGroups'], candidateGroupInfo);
      return newState;
    }
    case SET_CANDIDATE_GROUPS_INFO: {
      ({ payload } = action);
      const { jobId, storedCandidatesGroupInfo = [] } = payload;
      newState = { ...state };
      storedCandidatesGroupInfo.forEach(candidate => {
        const { candidateId, CandidateGroup } = candidate;
        if (_.get(state, ['ByJobId', jobId, 'CandidatesById', candidateId])) {
          const newSelectedCandidate = {
            ...state.ByJobId[jobId].CandidatesById[candidateId],
            CandidateGroup,
          };
          newState = cleanSet(newState, ['ByJobId', jobId, 'CandidatesById', candidateId], newSelectedCandidate);
        }
      });
      return newState;
    }
    case CLEAR_MANUAL_SEARCH_CANDIDATES: {
      ({ payload } = action);
      const { jobId } = payload;
      newState = cleanSet(state, ['ByJobId', jobId], defaultEmptyObject);
      return newState;
    }

    default:
      break;
  }

  return state;
}

function getManualSearchCandidatesFetchApiStatus(state, { jobId, source }) {
  return state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.BySource?.[source]?.ApiStatus;
}

const getManualSearchCandidatesFetchApiStatuses = createSelector(
  [state => state.ManualSearchCandidateReducer.ByJobId, (state, jobId) => jobId],
  (jobsById, jobId) => {
    const sources = jobsById?.[jobId]?.BySource ?? {};
    const apiStatusesBySource = {};
    Object.keys(sources).forEach(source => {
      apiStatusesBySource[source] = sources[source]?.ApiStatus;
    });
    return apiStatusesBySource;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: deepEqual,
    },
  }
);

function getManualSearchCandidatesFetchApiSecondsElapsed(state, { jobId, source }) {
  return state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.BySource?.[source]?.SecondsElapsed ?? 0;
}

function getAllTabFetchedCandidatesCount(state, { jobId }) {
  return state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.AllTabFetchedCandidatesCount ?? 0;
}

function getFetchedCandidatesCountBySource(state, { jobId, source }) {
  return state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.BySource?.[source]?.TotalCount ?? 0;
}

const getFetchedCandidatesCountBySources = createSelector(
  [state => state.ManualSearchCandidateReducer.ByJobId, (state, jobId) => jobId],
  (jobsById, jobId) => {
    const sources = jobsById?.[jobId]?.BySource ?? {};
    const fetchedCountsBySource = {};
    Object.keys(sources).forEach(source => {
      fetchedCountsBySource[source] = sources[source]?.TotalCount;
    });
    return fetchedCountsBySource;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: deepEqual,
    },
  }
);

function getAllTabTotalCandidatesCount(state, { jobId }) {
  return state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.AllTabTotalCandidatesCount ?? 0;
}

function getCandidatesBySource(state, { jobId }) {
  return state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.BySource ?? defaultEmptyObject;
}

function getManualSearchCandidates(state, jobId) {
  return state?.ManualSearchCandidateReducer?.ByJobId?.[jobId]?.CandidatesById ?? defaultEmptyObject;
}

function getAllDuplicateCandidates(state, jobId, sourceName) {
  if (sourceName !== 'All') return state?.CandidateDeDuplicationReducer?.ByJobId?.[jobId]?.CandidateGroups ?? {};
  return state?.ManualSearchCandidateReducer?.ByJobId?.[jobId]?.CandidateGroups ?? {};
}

function getAllNonGroupHeadDuplicateCandidateIds(state, jobId, sourceName) {
  const candidateGroups = state?.ManualSearchCandidateReducer?.ByJobId?.[jobId]?.CandidateGroups ?? {};
  if (allowedDeDuplicationSources.filter(source => source !== 'All').includes(sourceName)) {
    const candidateGroupBySource = state?.CandidateDeDuplicationReducer?.ByJobId?.[jobId]?.CandidateGroups ?? {};
    return Object.values(candidateGroupBySource[sourceName] || {}).flatMap(group => {
      const { candidateIds, GroupHead: groupHead } = group;
      return candidateIds.filter(candidateId => candidateId !== groupHead);
    });
  }
  return Object.values(candidateGroups).flatMap(group => {
    const { candidateIds, GroupHead: groupHead } = group;
    return candidateIds.filter(candidateId => candidateId !== groupHead);
  });
}

export function getAllTabCandidates(state, jobId) {
  return state?.ManualSearchCandidateReducer?.ByJobId?.[jobId]?.AllTabCandidates ?? [];
}

export function getIsCandidateDuplicate(state, jobId, candidateId, sourceName) {
  return getAllNonGroupHeadDuplicateCandidateIds(state, jobId, sourceName).includes(candidateId);
}

export {
  ManualSearchCandidateReducer,
  getManualSearchCandidatesFetchApiStatus,
  getManualSearchCandidatesFetchApiStatuses,
  getManualSearchCandidatesFetchApiSecondsElapsed,
  getAllTabFetchedCandidatesCount,
  getAllTabTotalCandidatesCount,
  getCandidatesBySource,
  getManualSearchCandidates,
  getFetchedCandidatesCountBySource,
  getFetchedCandidatesCountBySources,
  getAllDuplicateCandidates,
  getAllNonGroupHeadDuplicateCandidateIds,
};
