import React, { Component } from 'react';
import queryString from 'query-string';
import BotConfigDrawerContainer from '../BotConfigDrawerContainer/BotConfigDrawerContainer';
import CandidateDrawer from '../CandidateDrawer/CandidateDrawer';

export default class CandidateDrawerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchTryNowCandidateDetails, tryNowData, candidateId } = this.props;
    if (fetchTryNowCandidateDetails) {
      fetchTryNowCandidateDetails(candidateId, tryNowData?.CountryCode);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchTryNowCandidateDetails,
      tryNowData,
      candidateId,
      candidate,
      history,
      keywordsToHighlight,
      jobId,
      openInNewTab,
      candidateType,
      candidateContext,
      openAryaCandidateView,
    } = this.props;
    if (fetchTryNowCandidateDetails && prevProps?.candidateId !== candidateId) {
      fetchTryNowCandidateDetails(candidateId, tryNowData?.CountryCode);
    }
    if (
      openInNewTab &&
      candidateType === 'quick-search' &&
      history &&
      prevProps?.candidate?.RecommendationId !== candidate?.RecommendationId
    ) {
      const stringifiedHighlights = queryString.stringify(
        { highlights: keywordsToHighlight ?? [] },
        { arrayFormat: 'comma' }
      );
      if (openAryaCandidateView) {
        openAryaCandidateView(jobId, candidate.Id, null, stringifiedHighlights?.replace('highlights=', ''), '3');
      } else if (candidateContext === 'job') {
        history.replace(`/jobs/${jobId}/candidates/${candidate?.Id}?${stringifiedHighlights}&v=3`);
      } else history.replace(`/segments/${jobId}/candidates/${candidate?.Id}?${stringifiedHighlights}&v=3`);
    }
  }

  toggleBotDrawerRenderFlag = () => {
    const { botDrawerRenderFlag } = this.state;
    this.setState({ botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigDrawer = () => {
    const { showBotConfigDrawer, botDrawerRenderFlag } = this.state;
    const { openInNewTab, visible, onClose } = this.props;
    if (visible && !openInNewTab) onClose();
    if (showBotConfigDrawer) this.setState({ showBotConfigDrawer: !showBotConfigDrawer });
    else this.setState({ showBotConfigDrawer: !showBotConfigDrawer, botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  render() {
    const {
      jobId,
      whiteLabelInfo,
      currentJobDetails = {},
      isDiversityAttributesVisible,
      visible,
      candidate,
      openSipCallWindowsApp,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
    } = this.props;
    const { showBotConfigDrawer, botDrawerRenderFlag } = this.state;
    const AppName = whiteLabelInfo?.AppName ?? 'Arya';
    return (
      <>
        <BotConfigDrawerContainer
          jobTitle={currentJobDetails.JobTitle}
          visible={showBotConfigDrawer}
          botDrawerRenderFlag={botDrawerRenderFlag}
          toggleBotDrawerRenderFlag={this.toggleBotDrawerRenderFlag}
          onClose={this.toggleBotConfigDrawer}
          jobId={jobId}
          AppName={AppName}
        />
        <CandidateDrawer
          {...this.props}
          toggleBotConfigDrawer={this.toggleBotConfigDrawer}
          isDiversityAttributesVisible={isDiversityAttributesVisible}
          visible={visible}
          candidate={candidate}
          openSipCallWindowsApp={openSipCallWindowsApp}
          openSegmentAtsView={openSegmentAtsView}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          onClickAddJobCallBack={onClickAddJobCallBack}
        />
      </>
    );
  }
}
