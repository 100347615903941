import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import {
  EmailIcon,
  CallIcon,
  TextIcon,
  ContactPullIcon,
  EmailSuccessIcon,
  SMSConsentSuccessIcon,
  EmailFailIcon,
  CallSuccessIcon,
  CallFailedIcon,
  CallDisabledIcon,
  SMSFailedIcon,
  SMSOptedInIcon,
  SMSOptedOutIcon,
  EmailUnsubscribedIcon,
  EmailConsentPendingIcon,
} from '../../../Icons/AryaIcons';
import './ConnectMediaWidget.scss';
import FetchContactWidget from '../FetchContactWidget/FetchContactWidget';
import * as ConnectUtils from '../../../Utils/ConnectUtils';
import { getLastRefundTime } from '../../../Utils/ContactUtils';
import { onFetchContact } from '../../../Actions/ContactFetchActions';
import eventTypes from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';

const contactPullStatusText = {
  All: 'All contacts available',
  None: 'No contacts available',
  Email: 'Only Email address available',
  InValidEmail: 'Only Invalid Email address available',
  Phone: 'Only Phone number available',
  NotPulled: 'No contacts pulled',
};

const consentStatusText = {
  Pending: 'Consent requested',
  Denied: 'Consent rejected',
  Failed: 'Consent request failed',
  Approved: 'Consent approved',
};

export function getContactPullStatus(Contact, ContactRequestStatus) {
  let emailFound = false;
  let phoneFound = false;
  let inValidEmail = false;
  if (ContactRequestStatus === null) {
    return 'NotPulled';
  }
  if (ContactRequestStatus === 'Failed') {
    return 'None';
  }
  if (ContactRequestStatus === 'Success') {
    if (Contact && Contact.Emails && Contact.Emails.length > 0) {
      if (
        Contact.Emails.filter(
          email =>
            !email.IsRefunded &&
            (email.EmailSubscriptionStatus === 'Subscribed' ||
              email.ValidityStatus === 'Valid' ||
              email.ValidityStatus === 'Unknown')
        ).length > 0
      ) {
        emailFound = true;
      }
    }
    if (Contact && Contact.Phones && Contact.Phones.length > 0) {
      if (
        Contact.Phones.filter(
          phone =>
            !phone.IsRefunded &&
            (phone.MessageConsentStatus === 'Approved' ||
              phone.ValidityStatus === 'Valid' ||
              phone.ValidityStatus === 'Unknown')
        ).length > 0
      ) {
        phoneFound = true;
      }
    }

    if (Contact && Contact.Emails && Contact.Emails.length > 0) {
      if (
        Contact.Emails.filter(
          email => !email.IsRefunded && (email.ValidityStatus === 'Invalid' || email.ValidityStatus === 'Spam')
        ).length > 0
      ) {
        inValidEmail = true;
      }
    }

    if (phoneFound && emailFound) {
      return 'All';
    }
    if (!phoneFound && emailFound) {
      return 'Email';
    }
    if (phoneFound && !emailFound) {
      return 'Phone';
    }
    if (inValidEmail && !phoneFound) {
      return 'InValidEmail';
    }
    return 'None';
  }
  return 'None';
}

function getContactPullIcon(
  Contact,
  ContactRequestStatus,
  size = 'normal',
  fetchContactStatus,
  featureToggleList,
  candidate,
  currentJobDetails
) {
  const dispatch = useDispatch();
  if (!featureToggleList.ContactFetch.IsEnabled) {
    return null;
  }
  const { AvailableProviders } = Contact;
  const contactPullStatus = getContactPullStatus(Contact, ContactRequestStatus);
  const sizeClass = `connect-media-icon-${size}`;
  const isDisabled = AvailableProviders === 0 ? 'connect-media-icon-disabled' : '';
  if (contactPullStatus === 'NotPulled') {
    // return <div>NoContactsPulled</div>
    if (fetchContactStatus === 'InProgress') {
      return <Icon type="loading" className="connect-media-icon" />;
    }
    return (
      <ContactPullIcon
        onClick={AvailableProviders ? () => dispatch(onFetchContact({ candidate, currentJobDetails })) : null}
        className={`connect-media-icon ${sizeClass} ${isDisabled} `}
        sk-event-name={getEventNameByCandidateStatus(
          eventTypes.candidate.candidateContactPull.pullMoreContact,
          candidate
        )}
      />
    );
  }
  if (
    contactPullStatus === 'Phone' ||
    contactPullStatus === 'Email' ||
    contactPullStatus === 'All' ||
    contactPullStatus === 'InValidEmail'
  ) {
    // return <div>ContactFound</div>
    if (fetchContactStatus === 'InProgress') {
      return <Icon type="loading" className="connect-media-icon" />;
    }
    return (
      <ContactPullIcon
        onClick={AvailableProviders ? () => dispatch(onFetchContact({ candidate, currentJobDetails })) : null}
        className={`connect-media-icon connect-media-icon-success ${sizeClass} ${isDisabled} `}
        sk-event-name={getEventNameByCandidateStatus(
          eventTypes.candidate.candidateContactPull.pullMoreContact,
          candidate
        )}
      />
    );
  }
  // return <div>NoContactsFound</div>
  if (fetchContactStatus === 'InProgress') {
    return <Icon type="loading" className="connect-media-icon" />;
  }
  return (
    <ContactPullIcon
      onClick={AvailableProviders ? () => dispatch(onFetchContact({ candidate, currentJobDetails })) : null}
      className={`connect-media-icon connect-media-icon-failed ${sizeClass} ${isDisabled}`}
      sk-event-name={getEventNameByCandidateStatus(
        eventTypes.candidate.candidateContactPull.pullMoreContact,
        candidate
      )}
    />
  );
}

function getCallIcon(Phones = [], AudioConnectStatus = {}, skEventName = null) {
  const isPhoneCommunicationAllowed = !Phones.some(phone => !phone.IsCommunicationAllowed);
  const { Status: AudioConversationStatus } = AudioConnectStatus;
  if (Phones && Phones.length > 0) {
    const notRefundedAndValidPhones = Phones.filter(
      phone => !phone.IsRefunded && (phone.ValidityStatus === 'Valid' || phone.ValidityStatus === 'Unknown')
    );
    if (!isPhoneCommunicationAllowed)
      return (
        <CallDisabledIcon
          className="connect-media-icon connect-media-icon-with-addon-call"
          sk-event-name={skEventName}
        />
      );
    if (notRefundedAndValidPhones.length === 0) {
      // return <div>NoMobileFound</div>
      return <CallIcon className="connect-media-icon connect-media-icon-call-disabled" sk-event-name={skEventName} />;
    }
    if (AudioConversationStatus === 'InProgress' || AudioConversationStatus === 'Completed') {
      return (
        <CallSuccessIcon
          className="connect-media-icon connect-media-icon-with-addon-call"
          sk-event-name={skEventName}
        />
      );
    }
    if (
      AudioConversationStatus === 'Unanswered' ||
      AudioConversationStatus === 'Canceled' ||
      AudioConversationStatus === 'Failed'
    ) {
      // return <div>CalledUnsuccessful</div>
      return (
        <CallFailedIcon className="connect-media-icon connect-media-icon-with-addon-call" sk-event-name={skEventName} />
      );
    }
    // return <div>MobileFound</div>
    return <CallIcon className="connect-media-icon" sk-event-name={skEventName} />;
  }
  // return <div>NoMobileFound</div>
  return <CallIcon className="connect-media-icon connect-media-icon-call-disabled" sk-event-name={skEventName} />;
}

function getMessageIcon(Phones = [], SmsConnectStatus = {}, skEventName = null, smsIconStyle) {
  const isPhoneCommunicationAllowed = !Phones.some(phone => !phone.IsCommunicationAllowed);
  const { RequestStatus: MessageRequestStatus, ConsentStatus: MessageConsentStatus } = SmsConnectStatus;
  if (Phones && Phones.length > 0) {
    const notRefundedAndValidPhones = Phones.filter(
      phone =>
        !phone.IsRefunded &&
        (phone.MessageConsentStatus === 'Approved' ||
          phone.ValidityStatus === 'Valid' ||
          phone.ValidityStatus === 'Unknown')
    );
    if (!isPhoneCommunicationAllowed)
      return (
        <SMSOptedOutIcon
          className="connect-media-icon connect-media-icon-with-addon-message"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );

    if (notRefundedAndValidPhones.length === 0) {
      // return <div>NoMobileFound</div>
      return (
        <TextIcon
          className="connect-media-icon connect-media-icon-message-disabled"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );
    }
    if (MessageConsentStatus === 'Pending') {
      // return <div>OptInSmsSent</div>
      return (
        <SMSConsentSuccessIcon
          className="connect-media-icon connect-media-icon-with-addon-message"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );
    }
    if (MessageConsentStatus === 'Denied') {
      // return <div>OptedOut</div>
      return (
        <SMSOptedOutIcon
          className="connect-media-icon connect-media-icon-with-addon-message"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );
    }
    if (MessageConsentStatus === 'Approved') {
      // return <div>OptedIn</div>
      return (
        <SMSOptedInIcon
          className="connect-media-icon connect-media-icon-with-addon-message"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );
    }
    if (!MessageConsentStatus && (MessageRequestStatus === 'Pending' || MessageRequestStatus === 'Success')) {
      // return <div>OptInSmsSent</div>
      return (
        <SMSConsentSuccessIcon
          className="connect-media-icon connect-media-icon-with-addon-message"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );
    }
    if (MessageRequestStatus === 'Failed') {
      // return <div>SmsSentError</div>
      return (
        <SMSFailedIcon
          className="connect-media-icon connect-media-icon-with-addon-message"
          sk-event-name={skEventName}
          style={smsIconStyle}
        />
      );
    }
    // return <div>MobileFound</div>
    return <TextIcon className="connect-media-icon-message" sk-event-name={skEventName} style={smsIconStyle} />;
  }
  // return <div>NoMobileFound</div>
  return (
    <TextIcon
      className="connect-media-icon connect-media-icon-message-disabled"
      sk-event-name={skEventName}
      style={smsIconStyle}
    />
  );
}

function getMailIcon(Emails = [], EmailConnectStatus = {}, consentStatus = null, skEventName = null) {
  const isEmailCommunicationAllowed = !Emails.some(email => !email.IsCommunicationAllowed);
  const { RequestStatus: EmailRequestStatus, SubscriptionStatus } = EmailConnectStatus;
  if (Emails && Emails.length > 0) {
    const notRefundedAndValidEmails = Emails.filter(
      email =>
        !email.IsRefunded &&
        (email.EmailSubscriptionStatus === 'Subscribed' ||
          email.ValidityStatus === 'Valid' ||
          email.ValidityStatus === 'Unknown')
    );

    const inValidEmails = Emails.filter(
      email => !email.IsRefunded && (email.ValidityStatus === 'Spam' || email.ValidityStatus === 'Invalid')
    );

    if (!isEmailCommunicationAllowed) {
      return (
        <EmailUnsubscribedIcon
          className="connect-media-icon connect-media-icon-with-addon-email email-communication-disabled-icon"
          sk-event-name={skEventName}
        />
      );
    }

    if (notRefundedAndValidEmails.length === 0 && inValidEmails.length > 0) {
      return (
        <EmailFailIcon className="connect-media-icon connect-media-icon-with-addon-email" sk-event-name={skEventName} />
      );
    }

    if (notRefundedAndValidEmails.length === 0) {
      // return <div>EmailNotFound</div>
      return (
        <EmailIcon className="connect-media-icon-email connect-media-icon-mail-disabled" sk-event-name={skEventName} />
      );
    }
    if (consentStatus === 'Pending') {
      return (
        <EmailConsentPendingIcon
          className="connect-media-icon connect-media-icon-with-addon-email"
          sk-event-name={skEventName}
        />
      );
    }
    if (SubscriptionStatus === 'Unsubscribed') {
      return (
        <EmailUnsubscribedIcon
          className="connect-media-icon connect-media-icon-with-addon-email"
          sk-event-name={skEventName}
        />
      );
    }
    if (EmailRequestStatus === 'Failed') {
      // return <div>EmailSendFailed</div>
      return (
        <EmailFailIcon className="connect-media-icon connect-media-icon-with-addon-email" sk-event-name={skEventName} />
      );
    }
    if (EmailRequestStatus === 'Success' || EmailRequestStatus === 'Pending') {
      // return <div>EmailSendSuccess</div>
      return (
        <EmailSuccessIcon
          className="connect-media-icon connect-media-icon-with-addon-email"
          sk-event-name={skEventName}
        />
      );
    }
    // return <div>EmailFound</div>
    return <EmailIcon className="connect-media-icon-email" sk-event-name={skEventName} />;
  }
  // return <div>EmailNotFound</div>
  return <EmailIcon className=" connect-media-icon-mail-disabled" sk-event-name={skEventName} />;
}

export function getConsentStatus(contact) {
  const { ConsentStatus: consentStatus } = contact;
  if (consentStatus === 'Denied')
    return (
      <div className="consent-status-widget">
        <div className="consent-status-info consent-denied">
          <Icon type="close-circle" theme="filled" />
          Consent Rejected
        </div>
        {/* <div className="consent-status-message">
          <span>Consent rejected</span>
        </div> */}
      </div>
    );
  if (consentStatus === 'Failed')
    return (
      <div className="consent-status-widget">
        <div className="consent-status-info consent-denied">
          <Icon type="close-circle" theme="filled" />
          Consent Request Failed
        </div>
        {/* <div className="consent-status-message">
          <span>Consent request failed | Invalid email</span>
        </div> */}
      </div>
    );
  if (consentStatus === 'Pending')
    return (
      <div className="consent-status-widget">
        <div className="consent-status-info consent-pending">
          <Icon type="clock-circle" theme="filled" />
          Consent Requested
        </div>
        {/* <div className="consent-status-message">
          <span>Consent requested</span>
        </div> */}
      </div>
    );
  return null;
}

function ConnectMediaWidget({
  candidate,
  onIconClick,
  fetchContactStatus,
  ConnectStatus = {},
  featureToggleList,
  indexKey,
  currentJobDetails,
  isEmailReadVisible,
  isMessageReadVisible,
  isAudioReadVisible,
  connectMediaIconGroupStyle = {},

  isCandidateNotesAvailable,
}) {
  let { Contact } = ConnectStatus;
  Contact = Contact || {};
  let contactRequestStatus = Contact.RequestStatus;
  if ((Contact.Emails && Contact.Emails.length > 0) || (Contact.Phones && Contact.Phones.length > 0)) {
    contactRequestStatus = 'Success';
  }
  if (!contactRequestStatus) {
    return featureToggleList.ContactFetch.IsEnabled ? (
      <FetchContactWidget
        fetchContactStatus={fetchContactStatus}
        candidate={candidate}
        currentJobDetails={currentJobDetails}
      />
    ) : null;
  }
  const { ConsentStatus: consentStatus } = Contact;
  if (consentStatus && consentStatus !== 'Approved' && !ConnectUtils.isValidContact(Contact)) {
    return getConsentStatus(Contact);
  }

  const contactPullStatus = getContactPullStatus(Contact, contactRequestStatus);
  const lastRefundTime = getLastRefundTime(ConnectStatus);

  const contactPullText = contactPullStatusText[contactPullStatus];

  let contactsText =
    consentStatus && consentStatusText[consentStatus]
      ? `${consentStatusText[consentStatus]} | ${contactPullText}`
      : contactPullText;
  if (contactPullStatus.toLowerCase() === 'none' && lastRefundTime)
    contactsText = `${contactPullText} | Credit refunded`;

  const handleIconClick = key => {
    onIconClick(candidate, key, indexKey);
  };

  const smsIconStyle = isCandidateNotesAvailable ? { marginLeft: '4px' } : {};

  return (
    <>
      <div className="connect-media-icon-group" style={connectMediaIconGroupStyle}>
        {isEmailReadVisible ? (
          <div
            onClick={() => handleIconClick('email')}
            onKeyPress={() => handleIconClick('email')}
            role="presentation"
            className="connect-mail"
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToMailWindow,
              candidate
            )}
          >
            {getMailIcon(
              Contact.Emails,
              ConnectStatus.EmailStatus,
              consentStatus,
              getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToMailWindow, candidate)
            )}
          </div>
        ) : null}
        {isMessageReadVisible ? (
          <div
            onClick={() => handleIconClick('message')}
            onKeyPress={() => handleIconClick('message')}
            role="presentation"
            className="connect-message"
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToSmsWindow,
              candidate
            )}
          >
            {getMessageIcon(
              Contact.Phones,
              ConnectStatus.SmsStatus,
              getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToSmsWindow, candidate),
              smsIconStyle
            )}
          </div>
        ) : null}

        {isAudioReadVisible ? (
          <div
            onClick={() => handleIconClick('call')}
            onKeyPress={() => handleIconClick('call')}
            role="presentation"
            className="connect-call"
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToCallWindow,
              candidate
            )}
          >
            {getCallIcon(
              Contact.Phones,
              ConnectStatus.AudioStatus,
              getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToCallWindow, candidate)
            )}
          </div>
        ) : null}
        <div>
          {getContactPullIcon(
            Contact,
            contactRequestStatus,
            'normal',
            fetchContactStatus,
            featureToggleList,
            candidate,
            currentJobDetails
          )}
        </div>
      </div>
      <div className="contact-found">
        <b>{contactsText}</b>
      </div>
    </>
  );
}

export default ConnectMediaWidget;
