import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import cleanSet from 'clean-set';
import { Modal, Button, Form, Input, Select, DatePicker, Checkbox, Switch, Spin, InputNumber, message } from 'antd';
import styles from './ScoutingAgent.module.scss';
import { getSourceName } from '../../../Utils/SourceUtils';
import { getIsJobActivationAllowed, getClickedSources, updateIsClicked } from '../../../Utils/JobUtils';
import { getJobDetails } from '../../../Reducers/JobReducer';
import JobPortals from './JobPortals';
import { getConfig, getWhiteLabelInformation, getConfiguredSources } from '../../../Reducers/ConfigReducer';
import * as scoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  getScoutingAgentModalVisiblityStatus,
  getCurrentScoutingAgent,
  getSelectedScoutingAgent,
  getScoutingAgentWarningModalVisiblityStatus,
  getScoutingAgentConfig,
  getScoutingAgentsofEnabledJobs,
} from '../../../Reducers/ScoutingAgentReducer';

const { Item } = Form;
const { Option } = Select;

const mapDispatchToProps = {
  setScoutingAgentModalVisibilityStatus: scoutingAgentActions.setScoutingAgentModalVisibility,
  setScoutingAgentWarningModalVisibilityStatus: scoutingAgentActions.setScoutingAgentWarningModalVisibility,
  fetchScoutingAgentFromAgentId: scoutingAgentActions.fetchScoutingAgentFromAgentId,
  setSelectedScoutingAgentId: scoutingAgentActions.setSelectedScoutingAgentIdAction,
  createScoutingAgent: scoutingAgentActions.createScoutingAgentAction,
  updateScoutingAgent: scoutingAgentActions.updateScoutingAgentAction,
  getScoutingAgentConfig: scoutingAgentActions.getScoutingAgentConfigAction,
  fetchJobScoutingAgent: scoutingAgentActions.fetchJobScoutingAgent,
};

const mapStateToProps = (state, props) => ({
  userConfig: getConfig(state),
  sources: getConfiguredSources(state),
  showVaultName: _.get(getConfig(state), ['ShowVaultName'], false),
  whiteLabelInfo: getWhiteLabelInformation(state),
  authorizedPortals: _.get(getConfig(state), ['AuthorizedPortals'], []),
  scoutingAgentModalVisibilityStatus: getScoutingAgentModalVisiblityStatus(state),
  scoutingAgentWarningModalVisibilityStatus: getScoutingAgentWarningModalVisiblityStatus(state),
  selectedAgentId: getSelectedScoutingAgent(state),
  currentScoutingAgentData: getCurrentScoutingAgent(state),
  getScoutingAgentCreateApiStatus: getApiStatus(state, 'createScoutingAgentApi'),
  getScoutingAgentCreateLoadApiStatus: getApiStatus(state, 'scoutingAgentFromAgentIdApiStatus'),
  currentJobDetails: getJobDetails(state, props.jobId),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  activeScoutingAgentCount: getScoutingAgentsofEnabledJobs(state, props.jobId),
});

const ScoutingAgentModal = props => {
  const {
    sources,
    showVaultName,
    whiteLabelInfo,
    authorizedPortals,
    currentJobDetails: job,
    userConfig,
    scoutingAgentModalVisibilityStatus: modalStatus,
    setScoutingAgentModalVisibilityStatus: setModalStatus,
    setScoutingAgentWarningModalVisibilityStatus: setWarningModalStatus,
    updateScoutingAgent,
    jobId,
    getScoutingAgentCreateApiStatus,
    currentScoutingAgentData,
    fetchScoutingAgentFromAgentId,
    createScoutingAgent,
    getScoutingAgentCreateLoadApiStatus,
    setSelectedScoutingAgentId,
    selectedAgentId,
    getCriteria,
    candidateContext,
    scoutingAgentConfig,
    activeScoutingAgentCount,
    fetchJobScoutingAgent,
  } = props;

  const [validationData, setValidationData] = React.useState({
    MinScoutingAgentLimit: 0,
    MaxScoutingAgentLimit: 0,
    MinScoutingAgentDaysLimit: 0,
    MaxScoutingAgentDaysLimit: 0,
  });
  const minimumDaysLimit = validationData.MinScoutingAgentDaysLimit;
  const defaultMaxCandidateLimitValue = validationData.MinScoutingAgentLimit;
  const minimumScoutingAgentDateLimit = moment().add(minimumDaysLimit + 1, 'days');
  const [scoutingAgentForm, setScoutingAgentForm] = React.useState({
    name: '',
    maxCandidate: defaultMaxCandidateLimitValue,
    frequencyPerDay: 1,
    endDate: minimumScoutingAgentDateLimit,
    NotifySharedUsers: true,
    AutoDownload: false,
  });
  const [portalStatusChangeCount, setPortalStatusChangeCount] = React.useState(0);
  const [jobActivationStatus, setJobActivation] = React.useState({});

  const [apiData, setApiData] = React.useState({});
  const [agentId, setAgentId] = React.useState(null);
  const currentJobActivationStatus = _.get(job, ['aryaState'], {});
  React.useEffect(() => {
    setValidationData(scoutingAgentConfig);
  }, [scoutingAgentConfig]);

  React.useEffect(() => {
    if (Object.keys(currentScoutingAgentData).length > 0 && modalStatus?.mode === 'update') {
      const endDate = moment(currentScoutingAgentData.EndDate);
      const { Sources } = currentScoutingAgentData;
      const updatedJobActivationStatus = updateIsClicked(jobActivationStatus, Sources);
      setJobActivation(updatedJobActivationStatus);
      setApiData({
        agentName: currentScoutingAgentData.AgentName,
        endDate,
        maxCandidate: currentScoutingAgentData.CandidateMaxLimit,
        NotifySharedUsers: currentScoutingAgentData.NotifySharedUsers,
        AutoDownloadCandidates: currentScoutingAgentData.AutoDownloadCandidates,
        frequencyPerDay: currentScoutingAgentData.FrequencyPerDay,
        createdTime: currentScoutingAgentData.CreatedTime,
      });
      updateScoutingAgentForm(currentScoutingAgentData);
    }
    if (modalStatus?.mode === 'new') updateScoutingAgentForm();
  }, [currentScoutingAgentData, selectedAgentId, modalStatus?.mode]);

  React.useEffect(() => {
    if (!agentId) updateScoutingAgentForm();
    if (selectedAgentId) {
      fetchScoutingAgentFromAgentId(selectedAgentId);
      setAgentId(selectedAgentId);
      setSelectedScoutingAgentId(null);
    } else {
      setApiData({});
    }
  }, [selectedAgentId, job, fetchScoutingAgentFromAgentId, setSelectedScoutingAgentId, agentId]);

  React.useEffect(() => {
    const tempJobActivationStatus = { ...currentJobActivationStatus };
    if (Object.keys(tempJobActivationStatus).length > 0) {
      Object.values(tempJobActivationStatus).forEach(sourceActivationStatus => {
        const sourceName = getSourceName(sourceActivationStatus.Source, false).toLowerCase();
        tempJobActivationStatus[sourceName] = cleanSet(tempJobActivationStatus[sourceName], ['isClicked'], true);
      });
      setJobActivation(tempJobActivationStatus);
    }
  }, [currentJobActivationStatus]);

  const updateScoutingAgentForm = formData => {
    const tempScoutingAgentForm = { ...scoutingAgentForm };
    if (typeof formData === 'object') {
      const endDate = moment(formData.EndDate);
      tempScoutingAgentForm.name = formData.AgentName;
      tempScoutingAgentForm.maxCandidate = formData.CandidateMaxLimit;
      tempScoutingAgentForm.frequencyPerDay = formData.FrequencyPerDay;
      tempScoutingAgentForm.endDate = endDate;
      tempScoutingAgentForm.NotifySharedUsers = formData.NotifySharedUsers;
      tempScoutingAgentForm.AutoDownload = formData.AutoDownloadCandidates;
    } else {
      tempScoutingAgentForm.name = '';
      tempScoutingAgentForm.maxCandidate = defaultMaxCandidateLimitValue;
      tempScoutingAgentForm.frequencyPerDay = 1;
      tempScoutingAgentForm.endDate = minimumScoutingAgentDateLimit;
      tempScoutingAgentForm.NotifySharedUsers = true;
      tempScoutingAgentForm.AutoDownload = false;
    }
    setScoutingAgentForm(tempScoutingAgentForm);
  };

  const handlePortalSelect = sourceName => {
    let tempPortalStatuCount = portalStatusChangeCount;
    jobActivationStatus[sourceName].isClicked = !jobActivationStatus[sourceName].isClicked;
    if (jobActivationStatus[sourceName].isClicked === jobActivationStatus[sourceName].IsActivated) {
      tempPortalStatuCount -= 1;
    } else {
      tempPortalStatuCount += 1;
    }
    setJobActivation(jobActivationStatus);
    setPortalStatusChangeCount(tempPortalStatuCount);
  };

  const selectedPortals = () => {
    return Object.values(jobActivationStatus).filter(item => item.isClicked);
  };

  const onChangeForm = element => {
    const { value, id } = element;
    if (id) {
      let saveId = id;
      if (id === 'scouting_agent_name') saveId = 'name';
      if (id === 'scouting_agent_maxCandidate') saveId = 'maxCandidate';
      setScoutingAgentForm({ ...scoutingAgentForm, [saveId]: value });
    }
  };

  const getSources = () => getClickedSources(jobActivationStatus);

  const generateScoutingAgentPayload = () => {
    const { name, endDate, maxCandidate, frequencyPerDay, AutoDownload, NotifySharedUsers } = scoutingAgentForm;
    endDate.utcOffset(0).set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
    const returnObj = {
      JobId: jobId,
      AgentName: name,
      EndDate: endDate.toISOString(),
      CandidateMaxLimit: maxCandidate,
      NotifySharedUsers,
      AutoDownloadCandidates: AutoDownload,
      FrequencyPerDay: frequencyPerDay,
    };
    if (getCriteria) {
      const Criteria = getCriteria();
      Criteria.Sources = getSources();
      returnObj.CandidateSearchCriteria = Criteria;
    }
    if (agentId) returnObj.Id = agentId;
    return returnObj;
  };

  const onSaveAndScout = async () => {
    const payload = generateScoutingAgentPayload();
    const userSelectedSources = selectedPortals();
    if (activeScoutingAgentCount >= 5 && modalStatus?.mode !== 'update') {
      setWarningModalStatus(true);
      message.error('More than 5 scouting agents are not allowed');
    } else if (userSelectedSources && userSelectedSources.length === 0)
      message.error('please select a portal before scout');
    else if (modalStatus?.mode === 'update') {
      await updateScoutingAgent(payload);
    } else if (userSelectedSources && userSelectedSources.length > 0) {
      await createScoutingAgent(jobId, payload);
    }
    if (userSelectedSources && userSelectedSources.length > 0) {
      setModalStatus(false);
      if (modalStatus?.mode === 'update') await fetchJobScoutingAgent(jobId);
    }
  };
  const bodyStyle = {
    padding: '10px 0 16px 0',
  };

  const isJobActivationAllowed = getIsJobActivationAllowed(userConfig, job?.HadActivated);
  return (
    <Modal
      destroyOnClose
      visible={modalStatus.show}
      className={styles.scoutingModal}
      bodyStyle={bodyStyle}
      zIndex={2005}
      onCancel={() => {
        setModalStatus(false);
      }}
      title={<p className={styles.header}>Save Criteria & Set A Scouting Agent</p>}
      footer={false}
      width={686}
      closable={false}
    >
      <Spin
        spinning={
          getScoutingAgentCreateApiStatus === 'INPROGRESS' || getScoutingAgentCreateLoadApiStatus === 'INPROGRESS'
        }
      >
        <div className={styles.modalContainer}>
          <WrappedScoutingAgentForm
            onChangeForm={onChangeForm}
            scoutingAgentForm={scoutingAgentForm}
            sources={sources}
            showVaultName={showVaultName}
            jobActivationStatus={jobActivationStatus}
            whiteLabelInfo={whiteLabelInfo}
            handlePortalSelect={handlePortalSelect}
            authorizedPortals={authorizedPortals}
            isJobActivationAllowed={isJobActivationAllowed}
            setModalStatus={setModalStatus}
            getScoutingAgentCreateApiStatus={getScoutingAgentCreateApiStatus}
            onSaveAndScout={onSaveAndScout}
            apiData={apiData}
            setApiData={setApiData}
            candidateContext={candidateContext}
            validationData={validationData}
            userConfig={userConfig}
          />
        </div>
      </Spin>
    </Modal>
  );
};

const ScoutingAgentForm = props => {
  const {
    onChangeForm,
    scoutingAgentForm,
    sources,
    showVaultName,
    jobActivationStatus,
    whiteLabelInfo,
    handlePortalSelect,
    authorizedPortals,
    isJobActivationAllowed,
    form,
    setModalStatus,
    onSaveAndScout,
    getScoutingAgentCreateApiStatus,
    apiData,
    candidateContext,
    validationData,
    userConfig,
  } = props;
  const dateFormat = 'DD/MM/YYYY';
  const { name, endDate, maxCandidate } = scoutingAgentForm;
  const { getFieldDecorator, setFieldsValue } = form;
  const { agentName, endDate: apiEndDate, maxCandidate: CandidateMaxLimit, createdTime } = apiData;
  React.useEffect(() => {
    setFieldsValue({
      name,
      frequencyEndDate: endDate,
      maxCandidate,
    });
  }, [name, endDate, maxCandidate, setFieldsValue]);
  React.useEffect(() => {
    if (Object.keys(apiData).length > 0 && agentName && apiEndDate && CandidateMaxLimit) {
      setFieldsValue({
        name: agentName,
        frequencyEndDate: apiEndDate,
        maxCandidate: CandidateMaxLimit,
      });
    }
  }, [CandidateMaxLimit, agentName, apiData, apiEndDate, setFieldsValue]);
  const handleSaveAndScout = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSaveAndScout();
      }
    });
  };

  const handleChange = e => {
    const { id, value } = e.target;
    if (id === 'scouting_agent_name') {
      setFieldsValue({
        name: value,
      });
    }
    if (id === 'scouting_agent_maxCandidate') {
      setFieldsValue({
        maxCandidate: value,
      });
    } else onChangeForm(e.target);
  };
  const disabledDate = current => {
    const createdDate = new Date(Date.now());
    const minimumDate = new Date();
    const maxDate = new Date();
    minimumDate.setDate(createdDate.getDate() + validationData.MinScoutingAgentDaysLimit);
    maxDate.setDate(createdDate.getDate() + validationData.MaxScoutingAgentDaysLimit);
    return current < minimumDate || current > maxDate;
  };

  return (
    <Form id="scoutingAgentForm" layout="inline" onSubmit={handleSaveAndScout}>
      <Item className={styles.formItemFullWidth} required>
        {getFieldDecorator('name', {
          rules: [
            { required: true, message: 'Please enter Agent Name!' },
            {
              validator: (_, value) => {
                if (value.length > 50) return Promise.reject(new Error(`Agent name is too big`));
                return Promise.resolve();
              },
            },
          ],
        })(<Input id="name" onChange={handleChange} placeholder="Scouting Agent Name" />)}
      </Item>
      <p className={styles.heading} style={{ paddingLeft: '24px' }}>
        How frequently do you want to search candidates?
      </p>
      <div className={styles.frequencyContainer}>
        <div className={styles.selectDay}>
          <Select
            onChange={val => onChangeForm({ id: 'frequencyPerDay', value: val })}
            value={scoutingAgentForm.frequencyPerDay}
            getPopupContainer={node => node.parentNode}
            disabled={candidateContext === 'segment'}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
        </div>
        <div className={styles.frequencyText}>Time(s) per day untill</div>
        <div className={styles.selectFrequent}>
          <Item>
            {getFieldDecorator('frequencyEndDate', {
              rules: [{ required: true, message: 'Please enter end date!' }],
            })(
              <DatePicker
                getCalendarContainer={node => node.parentNode}
                disabledDate={disabledDate}
                style={{ maxWidth: 120 }}
                allowClear={false}
                format={dateFormat}
                showToday={false}
                onChange={date => onChangeForm({ id: 'endDate', value: date })}
              />
            )}
          </Item>
        </div>
      </div>
      <div className={styles.maxCandidate}>
        <p className={styles.heading}>Set max number of candidates for this agent</p>
        <Item>
          {getFieldDecorator('maxCandidate', {
            rules: [
              { required: true, message: 'Please enter Maximum Candidate!' },
              {
                validator: (_, value) => {
                  const val = parseInt(value, 10);

                  if (val > validationData.MaxScoutingAgentLimit)
                    return Promise.reject(
                      new Error(`max candidate cannot be greater than ${validationData.MaxScoutingAgentLimit}`)
                    );
                  if (val < validationData.MinScoutingAgentLimit)
                    return Promise.reject(
                      new Error(`max candidate cannot be smaller than ${validationData.MinScoutingAgentLimit}`)
                    );
                  return Promise.resolve();
                },
              },
            ],
          })(
            <InputNumber
              id="maxCandidate"
              style={{ width: '100px' }}
              onChange={value => onChangeForm({ id: 'maxCandidate', value })}
            />
          )}
        </Item>
      </div>
      <div style={{ paddingLeft: '24px' }}>
        <JobPortals
          sources={sources}
          userConfig={userConfig}
          sourceTitle="Which Portals?"
          showVaultName={showVaultName}
          currentJobActivationStatus={jobActivationStatus}
          whiteLabelInfo={whiteLabelInfo}
          handlePortalSelect={handlePortalSelect}
          authorizedPortals={authorizedPortals}
          isJobActivationAllowed={isJobActivationAllowed}
        />
      </div>

      <div className={styles.notifyUser}>
        <h3>Notify to shared users on candidate sourcing</h3>
        <Switch defaultChecked onChange={checked => onChangeForm({ id: 'NotifySharedUsers', value: checked })} />
      </div>
      <Checkbox
        style={{ paddingLeft: '24px' }}
        defaultChecked={candidateContext === 'segment'}
        disabled={candidateContext === 'segment' || !validationData.AutoDownloadEnabled}
        onChange={e => onChangeForm({ id: 'AutoDownload', value: e.target.checked })}
        checked={scoutingAgentForm.AutoDownload}
      >
        Auto download candidates
      </Checkbox>
      {candidateContext === 'segment' ? (
        <div style={{ paddingLeft: '24px', paddingTop: '8px' }}>
          All scouting agent auto downloaded candidates will be added to ‘Included Candidates’ list
        </div>
      ) : null}
      <div className={styles.divider}></div>
      <div className={styles.actionButtons}>
        <Button shape="round" key="back" onClick={() => setModalStatus(false)}>
          Cancel
        </Button>
        <Button
          loading={getScoutingAgentCreateApiStatus === 'INPROGRESS'}
          shape="round"
          type="primary"
          htmlType="submit"
        >
          Save & Scout
        </Button>
      </div>
    </Form>
  );
};

const WrappedScoutingAgentForm = Form.create({ name: 'scouting_agent' })(ScoutingAgentForm);
export default connect(mapStateToProps, mapDispatchToProps)(ScoutingAgentModal);

export { ScoutingAgentModal as ScoutingAgentModalWithoutStore };
