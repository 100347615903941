import cleanSet from 'clean-set';
import _ from 'lodash';
import { klona as cloneDeep } from 'klona';
import {
  APPEND_ORG_LEVEL_CONVERSATIONS,
  APPEND_ORG_LEVEL_PERSON_DETAILS,
  SET_ORG_LEVEL_CONVERSATIONS,
  SET_ORG_LEVEL_CONVERSATIONS_TOTAL_COUNT,
  SET_ORG_LEVEL_PERSON_DETAILS,
} from '../Actions/ActionCreators/ConnectActions';

const initialState = {
  emailTemplates: [],
  emailTemplateDetails: [],
  messageTemplateDetails: [],
  messageTemplates: [],
  consentMessageTemplates: [],
  nonConsentMessageTemplates: [],
  personsById: {},
  OrgLevelPersonDetailsById: {},
  OrgLevelConversationsById: {},
  OrgLevelConversationsCount: 0,
  conversationPersonMapping: {},
  connectNotifications: [],
  connectNotificationsCount: 0,
  newNotificationsCount: 0,
  conversationsById: {},
  newNotificationStatus: {
    senderId: null,
    showNotification: false,
  },
  ConnectStatuses: {},
  refundCredits: {},
  dripStats: {},
  helpTabTimestamp: '',
};

function mergeContacts(contact1, contact2) {
  if (!contact2) return contact1;
  if (!contact1) return contact2;
  const emails = contact1.Emails || [];
  const phones = contact1.Phones || [];
  return {
    Emails: _.uniqBy([...emails, ...(contact2.Emails || [])], 'EmailAddress'),
    Phones: _.uniqBy([...phones, ...(contact2.Phones || [])], 'Number'),
    RequestStatus: 'Success',
    AvailableProvider: 1,
  };
}

export function ConnectReducer(state = initialState, action) {
  let newState;
  let existingContacts;
  let connectStatuses;
  const sanitizedConnectInfo = {};
  let connectStatusIds;
  let connectInfo;
  let sanitizedEmails;
  let currentConnectInfo;
  let currentEmails;
  switch (action.type) {
    case 'SET_CANDIDATE_CONNECT_INFO':
      newState = cloneDeep(state);
      connectInfo = cloneDeep(action.payload.connectInfo);
      connectStatusIds = Object.keys(action.payload.connectInfo);
      connectStatusIds.forEach(connectStatusId => {
        currentConnectInfo = connectInfo[connectStatusId];
        currentEmails = _.get(currentConnectInfo, ['Contact', 'Emails'], []);
        if (currentEmails.length) {
          sanitizedEmails = currentEmails.map(email => {
            return {
              ...email,
              EmailAddress: _.trim(email.EmailAddress, "' .-"),
            };
          });
          _.set(currentConnectInfo, ['Contact', 'Emails'], sanitizedEmails);
        }
        sanitizedConnectInfo[connectStatusId] = currentConnectInfo;
      });
      newState = {
        ...newState,
        ConnectStatuses: {
          ...state.ConnectStatuses,
          ...sanitizedConnectInfo,
        },
      };
      return newState;
    case 'ADD_CANDIDATE_CONNECT_INFO':
      existingContacts = state[action.payload.PersonId] ? cloneDeep(state[action.payload.PersonId].Contact) : undefined;
      connectStatuses = action.payload.ConnectInfo;
      connectStatuses[action.payload.PersonId].Contact = mergeContacts(
        existingContacts,
        action.payload.ConnectInfo.Contact
      );
      return cleanSet(state, 'ConnectStatuses', {
        ...state.connectStatuses,
        ...connectStatuses,
      });
    case 'SET_EMAIL_TEMPLATES':
      return {
        ...state,
        emailTemplates: action.payload,
      };

    case 'SET_HELP_TAB_TIMESTAMP':
      return {
        ...state,
        helpTabTimestamp: action.payload,
      };

    case 'SET_MESSAGE_TEMPLATES': {
      const { payload } = action;
      const consentMessageTemplates = [];
      const nonConsentMessageTemplates = [];
      payload.forEach(message => {
        if (message.IsConsent) {
          consentMessageTemplates.push(message);
        } else {
          nonConsentMessageTemplates.push(message);
        }
      });
      return {
        ...state,
        messageTemplates: payload,
        consentMessageTemplates,
        nonConsentMessageTemplates,
      };
    }
    case 'SET_CONSENT_MESSAGE_TEMPLATES':
      return {
        ...state,
        consentMessageTemplates: action.payload,
      };

    case 'SET_NONCONSENT_MESSAGE_TEMPLATES':
      return {
        ...state,
        nonConsentMessageTemplates: action.payload,
      };

    case SET_ORG_LEVEL_CONVERSATIONS: {
      const conversationsById = {};
      action.payload.forEach(conversation => {
        conversationsById[conversation.Id] = conversation;
      });
      return { ...state, OrgLevelConversationsById: conversationsById };
    }

    case SET_ORG_LEVEL_CONVERSATIONS_TOTAL_COUNT:
      return { ...state, OrgLevelConversationsCount: action.payload };

    case APPEND_ORG_LEVEL_CONVERSATIONS: {
      const conversationsById = {
        ...state.OrgLevelConversationsById,
      };
      action.payload.forEach(conversation => {
        if (!conversationsById[conversation.Id]) {
          conversationsById[conversation.Id] = conversation;
        }
      });
      return { ...state, OrgLevelConversationsById: conversationsById };
    }

    case SET_ORG_LEVEL_PERSON_DETAILS: {
      const personDetailsById = {};
      action.payload.forEach(person => {
        personDetailsById[person.Id] = person;
      });
      return { ...state, OrgLevelPersonDetailsById: personDetailsById };
    }

    case APPEND_ORG_LEVEL_PERSON_DETAILS: {
      const personDetailsById = {
        ...state.OrgLevelPersonDetailsById,
      };
      action.payload.forEach(person => {
        if (!personDetailsById[person.Id]) {
          personDetailsById[person.Id] = person;
        }
      });
      return { ...state, OrgLevelPersonDetailsById: personDetailsById };
    }

    case 'ADD_PERSON_DETAILS':
      newState = cleanSet(state, ['personsById', action.payload.Id], action.payload);
      return newState;

    case 'ADD_CONVERSATION_PERSON_MAPPING':
      newState = cleanSet(state, ['conversationPersonMapping', action.payload.conversationId], action.payload.personId);
      return newState;

    case 'SET_CONNECT_NOTIFICATIONS':
      return {
        ...state,
        connectNotifications: [...action.payload],
      };

    case 'ADD_CONNECT_NOTIFICATIONS':
      return {
        ...state,
        connectNotifications: [...state.connectNotifications, ...action.payload],
      };

    case 'SET_CONNECT_NOTIFICATIONS_COUNT':
      return {
        ...state,
        connectNotificationsCount: action.payload,
      };

    case 'SET_NEW_NOTIFICATIONS_COUNT':
      return {
        ...state,
        newNotificationsCount: action.payload,
      };

    case 'UPDATE_CONVERSATION_DETAILS':
      newState = cleanSet(state, ['conversationsById', action.payload.Id, action.payload.type], 0);
      return newState;

    case 'ADD_CONVERSATION_DETAILS': {
      newState = { ...state };
      action.payload.forEach(conversation => {
        if (conversation) {
          const existingConversationDetails = newState.conversationsById?.[conversation?.ConversationId] ?? {};
          const updatedConversationDetails = { ...existingConversationDetails, ...conversation };
          newState = cleanSet(newState, ['conversationsById', conversation.ConversationId], updatedConversationDetails);
        }
      });
      return newState;
    }

    case 'SET_JOB_UNREAD_CONVERSATION_COUNT': {
      let jobUnreadConversationCount = cloneDeep(state.jobUnreadConversationCount);
      jobUnreadConversationCount = _.merge(jobUnreadConversationCount, action.payload.jobUnreadConversationCount);
      return {
        ...state,
        jobUnreadConversationCount,
      };
    }

    case 'SET_CURRENT_REFUND_CREDITS':
      return {
        ...state,
        refundCredits: {
          ...state.refundCredits,
          ...action.payload,
        },
      };
    case 'SET_DRIP_EMAIL_STATS':
      return {
        ...state,
        dripStats: action.payload,
      };
    default:
      return state;
  }
}

export function getCandidatesConnectInfo(state) {
  return state.ConnectReducer;
}

export function getCandidateConnectStatus(state, personId) {
  return state.ConnectReducer.ConnectStatuses?.[personId];
}

export function getEmailTemplates(state) {
  return state.ConnectReducer.emailTemplates;
}

export function getMessageTemplates(state) {
  return state.ConnectReducer.messageTemplates;
}

export function getConsentMessageTemplates(state) {
  return state.ConnectReducer.consentMessageTemplates;
}

export function getNonConsentMessageTemplates(state) {
  return state.ConnectReducer.nonConsentMessageTemplates;
}

export function getPersonsById(state) {
  return state.ConnectReducer.personsById;
}

export function getConversationPersonMapping(state) {
  return state.ConnectReducer.conversationPersonMapping;
}

export function getConnectNotifications(state) {
  return state.ConnectReducer.connectNotifications;
}

export function getConnectNotificationsCount(state) {
  return state.ConnectReducer.connectNotificationsCount;
}

export function getNewNotificationsCount(state) {
  return state.ConnectReducer.newNotificationsCount;
}

export function getConversationsById(state) {
  return state.ConnectReducer.conversationsById;
}

export function getJobsUnreadConversationCount(state) {
  return state.ConnectReducer.jobUnreadConversationCount;
}

export function getCreditsRefunded(state) {
  return state.ConnectReducer.refundCredits;
}

export function getDripStats(state) {
  return state.ConnectReducer.dripStats;
}

export const getOrglevelConversationsById = state => {
  return state?.ConnectReducer?.OrgLevelConversationsById;
};

export const getOrglevelConversationsCount = state => {
  return state?.ConnectReducer?.OrgLevelConversationsCount;
};

export const getOrgLevelPersonDetailsById = state => {
  return state?.ConnectReducer?.OrgLevelPersonDetailsById;
};

export const getHelpTabTimestamp = state => {
  return state?.ConnectReducer?.helpTabTimestamp;
};
