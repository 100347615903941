import { apiV3Client } from './BaseRepository';

export function getReportStats(payload, reportStatsCategory) {
  return apiV3Client.post(`reports/${reportStatsCategory}`, payload);
}

export function getReportSummary(payload) {
  return apiV3Client.post('reports/stats', payload);
}

export function generateReport(payload, reportStatsCategory) {
  return apiV3Client.post(`reports/_generate/${reportStatsCategory}`, payload);
}

export function saveReportQuery(query) {
  return apiV3Client.post(`reports/reportQuery`, query);
}

export function fetchSavedQueries(filter) {
  return apiV3Client.post(`reports/reportQueries`, filter);
}

export function deleteSavedQuery(queryId) {
  return apiV3Client.delete(`reports/${queryId}`);
}

export function shareSavedQuery({ shareWith, unshareWith, isShareWithAll, isUnshareWithAll, reportId }) {
  return apiV3Client.post(`reports/${reportId}/_share`, {
    ShareWith: shareWith,
    UnshareWith: unshareWith,
    IsShareWithAll: isShareWithAll,
    IsUnshareWithAll: isUnshareWithAll,
  });
}

export function getReportColumnHeaders(reportType) {
  return apiV3Client.get('reports/headers', {
    params: {
      reportType,
    },
  });
}

export function getOrgReportColumnHeaders(filter) {
  return apiV3Client.post('reports/orgHeaders', filter);
}

export function getJobs(payload) {
  return apiV3Client.post('reports/_jobsByUserId', payload);
}

export function downloadReport(fileName) {
  return apiV3Client.get(`reports/_download/${fileName}`, {
    responseType: 'blob',
    params: {
      download: true,
    },
  });
}

export function fetchOrganizations(filter) {
  return apiV3Client.post(`reports/_organizationSearch`, filter);
}
