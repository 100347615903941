import React from 'react';
import { Icon } from 'antd';

export function EndDripEmailSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <path
        fill="#07101A"
        fillOpacity="0.2"
        fillRule="evenodd"
        d="M.707 16.97L0 16.264l3.552-3.552 3.936-3.935v-.001l2.897-2.897-.003.004 2.614-2.614v-.001L16.263 0l.708.707-6.768 6.767 3.97-2.946.002.02v6.68c0 .77-.493 1.406-1.122 1.478l-.119.007-3.694-.001v1.142H10.8L7.962 17.29l-2.836-3.435H6.58v-1.142l-1.615-.001-4.259 4.26zm1.06-12.442l4.91 3.645-4.319 4.32c-.323-.237-.548-.647-.586-1.122l-.006-.143v-6.69l.002-.01zm9.815-1.26L7.514 7.336 2.305 3.464c.145-.097.306-.164.479-.187l.131-.009h8.667zm-8.515-1.51l1.164.88H3.065l.001-.872v-.009zM11.912.75c.14 0 .272.038.392.103l.087.054-2.288 1.73H5.777L3.49.908a.88.88 0 01.376-.15L3.968.75h7.944zm.902 1.007v.279l-.602.602h-.564l1.166-.88z"
      ></path>
    </svg>
  );
}

const EndDripEmailIcon = props => (
  <Icon component={customProps => <EndDripEmailSvg {...props} {...customProps} />} {...props} />
);

export default EndDripEmailIcon;
