import React from 'react';
import { connect } from 'react-redux';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCandidateAdvanceFilterFields } from '../../Reducers/CandidateAdvanceFiltersReducer';
import CandidateAggregationFilter from './CandidateAggregationFilter/CandidateAggregationFilter';
import styles from './CandidateSegmentFilters.module.scss';
import SelectedCandidatesText from './SelectedCandidatesText';
import CandidateSourceFilter from './CandidateSourceFilter/CandidateSourceFilter';

const mapStateToProps = (state, props) => ({
  whiteLabelInfo: getWhiteLabelInformation(state),
  config: getConfig(state),
  filterFields: getCandidateAdvanceFilterFields(state),
});

function CandidateSegmentFilters(props) {
  const {
    applyCandidateFilter,
    currentPipelineFilter,
    currentJobDetails,
    candidates,
    config,
    whiteLabelInfo,
    isCandidateListLoading,
    totalCandidate,
    isClearFilterApplied,
    setClearFilterApplied,
    activeTab,
    featureToggleList,
    isNonInternalPortalForSegmentEnabled,
    resetMultiSelect,
    currentPageCandidateIds,
    fetchBulkCandidateViewStatus,
    setCurrentUserCandidateViewedFlag,
    currentUserViewedStatusFlag,
    jobId,
    filterFields,
    sampleCandidateCount,
    scoutingAgents,
    candidateContext = 'segment',
  } = props;

  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const { candidateCountBySource, CandidateIds: selectedCandidates, CandidateAggs: candidateAggs } = currentJobDetails;
  const candidateAggregations = candidateAggs ?? [];
  const isCountVisible = !isCandidateListLoading && isFilterApplied;
  React.useEffect(() => {
    if (isClearFilterApplied) {
      setIsFilterApplied(false);
      if (setClearFilterApplied) setClearFilterApplied(false);
    }
    resetMultiSelect();
  }, [isClearFilterApplied, isCountVisible]);

  return (
    <div className="candidate-filter-row">
      <div className="candidate-source-filter-wrapper">
        <div className={styles.candidateSourceFilter}>
          {featureToggleList.SourceFilter.IsEnabled &&
          isNonInternalPortalForSegmentEnabled &&
          candidateContext !== 'segment' ? (
            <CandidateSourceFilter
              applyCandidateFilter={applyCandidateFilter}
              candidateCountBySource={candidateCountBySource}
              whiteLabelInfo={whiteLabelInfo}
              selectedSources={currentPipelineFilter.Portals}
              sourcesConfig={config?.Sources}
              sourceConfigsByName={config?.SourceConfigsByName ?? []}
              portalSources={config?.PortalSources ?? []}
              showVaultName={config?.ShowVaultName}
              jobCountryCode={currentJobDetails.CountryCode}
              featureToggleList={featureToggleList}
            />
          ) : null}
        </div>
        <div className="candidate-filter-selected-portal-text">
          {isCountVisible ? (
            <SelectedCandidatesText
              candidatesCount={totalCandidate}
              sampleCandidateCount={sampleCandidateCount}
              candidateContext={candidateContext}
            />
          ) : null}
        </div>
      </div>
      <div className="candidate-control-icons">
        <div className="candidate-filter-icon">
          <CandidateAggregationFilter
            applyCandidateFilter={applyCandidateFilter}
            currentPipelineFilter={currentPipelineFilter}
            setIsFilterApplied={setIsFilterApplied}
            candidateAggs={candidateAggregations}
            whiteLabelInfo={whiteLabelInfo}
            isFilterApplied={isFilterApplied}
            showVaultName={config?.ShowVaultName}
            candidates={candidates}
            isEmailStatsVisible={false}
            candidateContext={candidateContext}
            totalCandidate={totalCandidate}
            emailStats={candidateAggregations.EmailStatus}
            activeTab={activeTab}
            currentPageCandidateIds={currentPageCandidateIds}
            fetchBulkCandidateViewStatus={fetchBulkCandidateViewStatus}
            jobId={jobId}
            currentJobDetails={currentJobDetails}
            setCurrentUserCandidateViewedFlag={setCurrentUserCandidateViewedFlag}
            currentUserViewedStatusFlag={currentUserViewedStatusFlag}
            filterFields={filterFields}
            scoutingAgents={scoutingAgents}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(CandidateSegmentFilters);
export { CandidateSegmentFilters as CandidateSegmentFiltersWithoutStore };
