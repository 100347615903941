import React from 'react';
import _ from 'lodash';
import { Button, Form, Icon, Radio, Tooltip } from 'antd';
import { UserAlertWithWrapper } from '../UserAlerts/UserAlerts';
import ConnectTemplateSelect from '../JobSettings/ConnectTemplateSelect';
import RichTextEditor from '../Editor/RichTextEditor';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import styles from './EmailAutomation.module.scss';

function EmailAutomation(props) {
  const {
    consentComplianceInfo,
    emailTemplateIds,
    emailTemplatesById,
    emailTemplatesCount,
    selectedDripTemplate,
    fetchEmailTemplates,
    isPulse,
    selectedEmailTemplateId,
    selectedEmailTemplateName,
    setDefaultEmailTemplateValues,
    emailTemplates,
    onChangeEmailTemplate,
    emailTemplateContentVisibility,
    toggleEmailContentVisibility,
    selectedEmailTemplateSubject,
    selectedEmailPreHeader,
    ref,
    updateBodyContent,
    selectedEmailTemplateBody,
    selectedEmailTemplateSendTo,
    onEmailSendToChange,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    sendEmailTo,
    isSendToOptionAllowed,
    isEmailAutomationNoteAllowed,
    templateSelectorClassname,
    candidateContext,
    featureToggleList,
  } = props;

  const linkButtonName = emailTemplateContentVisibility ? 'Hide Content' : 'See Content';
  const isSelectedDripTemplate = selectedDripTemplate && selectedDripTemplate.Drops;
  const isMultipleEmailDrip = isSelectedDripTemplate && selectedDripTemplate.Drops.length > 1;

  const {
    AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled } = {},
    AryaRanking: { IsAllowed: isAryaRankingAllowed } = {},
  } = featureToggleList || {};

  const getEmaiAutomationNote = () => {
    return isEmailAutomationNoteAllowed ? (
      <BulkCandidatesDownloadFilterInfo
        candidateDownloadFilterConfig={candidateDownloadFilterConfig}
        candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
      />
    ) : null;
  };
  const linkButton = selectedEmailTemplateName ? (
    <Button type="link" onClick={toggleEmailContentVisibility}>
      <u>{linkButtonName}</u>
    </Button>
  ) : null;
  const multipleEmailDripMessage = isMultipleEmailDrip ? (
    <span>{`+${selectedDripTemplate.Drops.length - 1} more emails in the drip`}</span>
  ) : null;
  const emailPreHeader =
    selectedEmailPreHeader && !selectedDripTemplate ? (
      <div className={styles.connectTemplateSubject}>
        <Form.Item
          label={
            <span>
              <span style={{ fontWeight: '500' }}>Preheader</span>&nbsp;
              <Tooltip title="A preheader is the short summary text that follows the subject line when viewing an email from the inbox">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <RichTextEditor
            className="mail-subject-editor connect-template-subject"
            editorContent={{ htmlContent: selectedEmailPreHeader }}
            showToolbar={false}
            ref={ref}
            disabled
          />
        </Form.Item>
      </div>
    ) : null;
  const getEmailTemplateContent = () => {
    return emailTemplateContentVisibility ? (
      <div>
        <div className={styles.connectTemplateSubject}>
          <Form.Item label={<span style={{ fontWeight: '500' }}>Subject</span>}>
            <RichTextEditor
              className="mail-subject-editor connect-template-subject"
              editorContent={{ htmlContent: selectedEmailTemplateSubject }}
              showToolbar={false}
              ref={ref}
              disabled
            />
          </Form.Item>
        </div>
        {emailPreHeader}
        <div className={styles.connectTemplateBody}>
          <Form.Item label={<span style={{ fontWeight: '500' }}>Body</span>}>
            <RichTextEditor
              editorContent={{ htmlContent: selectedEmailTemplateBody }}
              onChange={updateBodyContent}
              showAdditionalOptions
              showToolbar={false}
              disabled
            />
          </Form.Item>
        </div>
        <div className={styles.dripInfo}>{multipleEmailDripMessage}</div>
      </div>
    ) : null;
  };
  const getSendToOption = () => {
    return isSendToOptionAllowed ? (
      <div id="auto-email-send-to-container" className={styles.sendToContainer}>
        <div className={styles.sendToLabel}>Send to</div>
        <Radio.Group value={selectedEmailTemplateSendTo} onChange={onEmailSendToChange}>
          <Radio value={sendEmailTo.shortlistedIdentifier}>Shortlisted Candidates</Radio>
          <Radio value={sendEmailTo.allIdentifier}>All</Radio>
          {isAdvanceSearchV2Enabled && !isAryaRankingAllowed ? null : (
            <Radio value={sendEmailTo.userGivenIdentifier}>User Given with shortlisted</Radio>
          )}
        </Radio.Group>
      </div>
    ) : null;
  };
  return (
    <div>
      {consentComplianceInfo ? (
        <UserAlertWithWrapper header={consentComplianceInfo.header} content={consentComplianceInfo.body} />
      ) : (
        <div>
          <div>
            <div style={{ display: 'flex' }}>
              <ConnectTemplateSelect
                cssClassname={templateSelectorClassname}
                templateIds={emailTemplateIds}
                templatesById={emailTemplatesById}
                templatesCount={emailTemplatesCount}
                dripTemplate={selectedDripTemplate}
                fetchTemplates={fetchEmailTemplates}
                isPusle={isPulse}
                selectedTemplateId={selectedEmailTemplateId}
                selectedTemplateName={selectedEmailTemplateName}
                setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
                emailTemplates={emailTemplates}
                placeholderValue="Select template (required)"
                onChange={onChangeEmailTemplate}
                candidateContext={candidateContext}
              />
              {linkButton}
            </div>
            {getEmailTemplateContent()}
            {getSendToOption()}
            {getEmaiAutomationNote()}
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailAutomation;
