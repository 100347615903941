import React from 'react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TooltipReportData from '../../WorkflowReport/WorkflowReportUtils/TooltipReportData';
import * as WorkflowReportReducer from '../../../Reducers/WorkflowReportReducer';
import * as WorkflowReducer from '../../../Reducers/WorkflowReducer';
import * as WorkflowReportAction from '../../../Actions/WorkflowReportActions';
import * as WorkflowAction from '../../../Actions/WorkflowActions';

const mapStateToProps = state => {
  return {
    workflowReportData: WorkflowReportReducer.getWorkflowReportData(state),
    workflowNodeConnections: WorkflowReducer.getWorkflowNodeConnections(state),
    isWorkflowPublished: WorkflowReportReducer.getWorkflowPublishedStatus(state),
    workflowEndNodeData: WorkflowReportReducer.getWorkflowReportEndNodesData(state),
    workflowReportFetchApiStatus: WorkflowReportReducer.getWorkflowReportApiStatus(state),
  };
};

const mapDispatchToProps = {
  setWorkflowReportEndNodesData: WorkflowReportAction.setWorkflowReportEndNodesData,
  setWorkflowNodeConnections: WorkflowAction.setWorkflowNodeConnections,
};

const ConditionalEdgeTooltip = props => {
  const {
    sourceX,
    targetX,
    label,
    targetType,
    target,
    source,
    id,
    workflowReportData,
    setWorkflowReportEndNodesData,
    setWorkflowNodeConnections,
    isWorkflowPublished,
    workflowEndNodeData,
    workflowReportFetchApiStatus,
  } = props;
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const [tooltipText, setTooltipText] = React.useState('');
  const { ActivitiesStats: activityStats } = workflowReportData;
  const activityStat = activityStats?.find(activity => activity.ActivityId === source);
  const { isRendered } = workflowEndNodeData || false;

  React.useEffect(() => {
    if (Array.isArray(activityStats) && !isRendered) {
      const tooltipTextNode = TooltipReportData({
        activityStats,
        elementId: source,
        outComeLabel: label,
        elementType: 'edge',
        targetType,
        target,
        setWorkflowReportEndNodesData,
        isRendered,
      });
      if (tooltipTextNode && isWorkflowPublished) {
        setTooltipText(tooltipTextNode);
        setTooltipVisible(true);
      }
    }
  }, [JSON.stringify(activityStat)]);

  React.useEffect(() => {
    if (workflowReportFetchApiStatus) {
      setTooltipVisible(false);
    }
  }, [workflowReportFetchApiStatus]);

  React.useEffect(() => {
    setWorkflowNodeConnections({ connections: [{ [target]: label }] });
  }, []);

  return (
    <span
      className={classNames('condition-edge-text', { rightTop: sourceX > targetX }, { leftTop: sourceX < targetX })}
      style={{ fontSize: '12px' }}
    >
      {sourceX > targetX ? (
        <Tooltip
          getPopupContainer={node => node.parentNode}
          visible={tooltipVisible}
          overlayClassName={`ConditionalEdgeTooltip-${id}-${label[0]} ConditionalTooltipEdge`}
          placement="left"
          title={tooltipText}
          autoAdjustOverflow
        >
          {label}
        </Tooltip>
      ) : (
        <Tooltip
          getPopupContainer={node => node.parentNode}
          overlayClassName={`ConditionalEdgeTooltip-${id}-${label[0]} ConditionalTooltipEdge`}
          visible={tooltipVisible}
          placement="right"
          title={tooltipText}
          autoAdjustOverflow
        >
          {label}
        </Tooltip>
      )}
    </span>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalEdgeTooltip);
export { ConditionalEdgeTooltip as ConditionalEdgeTooltipWithoutStore };
