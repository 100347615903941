import React from 'react';
import { Tabs, Badge } from 'antd';
import classNames from 'classnames';
import NetworkIssueErrorBoundary from '../../ErrorBoundary/NetworkIssue/NetworkIssue';
import { EmailIcon, CallIcon, TextIcon, AryaBotIcon } from '../../../Icons/AryaIcons';
import './ContentPaneBody.scss';
import MessageWindowContainer from '../../../Containers/MessageWindow/MessageWindow';
import MailWindowContainer from '../../../Containers/MailWindow/MailWindow';
import CallWindowContainer from '../../../Containers/CallWindow/CallWindow';
import ChatWindowContainer from '../../../Containers/ChatWindow/ChatWindow';

const { TabPane } = Tabs;

const ContentPaneBody = ({
  jobId,
  jobGuid,
  candidate,
  availableProviders,
  smsCount,
  chatCount,
  showBotConfigDrawer,
  isBotEnabled,
  FromEmailAddress,
  jobCountryCode,
  currentJobDetails,
  version,
  openSipCallWindowsApp,
  isComposeEmailModalVisible,
  setEmailComposeModalVisibility,
  isComposeMessageModalVisible,
  setMessageComposeModalVisibility,
  onConnectContentTabClick,
  activeConnectContentTab,
  connectMessageRef,
  connectMailRef,
}) => {
  const messageTabPaneName = <Badge dot={smsCount}>Text</Badge>;
  const emailTabPaneName = <Badge>Email</Badge>;
  const callTabPaneName = <Badge>Phone</Badge>;
  const botTabPaneName = <Badge dot={chatCount}>Chatbot</Badge>;

  return (
    <div className={classNames('connectTabs', 'connectTabs360View')}>
      <Tabs
        defaultActiveKey={FromEmailAddress ? 'email' : ''}
        tabBarStyle={{ marginBottom: '0px' }}
        className="connect-icons-tab"
        onChange={onConnectContentTabClick}
        activeKey={activeConnectContentTab}
      >
        <TabPane tab={messageTabPaneName} key="message">
          <div className="master-profile-tab-content">
            {candidate ? (
              <NetworkIssueErrorBoundary>
                <MessageWindowContainer
                  jobId={jobId}
                  jobGuid={jobGuid}
                  conversationId={candidate.ConversationId}
                  candidate={candidate}
                  availableProviders={availableProviders}
                  smsCount={smsCount}
                  currentJobDetails={currentJobDetails}
                  version={version}
                  isComposeMessageModalVisible={isComposeMessageModalVisible}
                  setMessageComposeModalVisibility={setMessageComposeModalVisibility}
                  ref={connectMessageRef}
                  isConnectContentPane
                />
              </NetworkIssueErrorBoundary>
            ) : null}
          </div>
        </TabPane>
        <TabPane tab={emailTabPaneName} key="email">
          <div className="master-profile-tab-content">
            {candidate ? (
              <NetworkIssueErrorBoundary>
                <MailWindowContainer
                  jobId={jobId}
                  conversationId={candidate.ConversationId}
                  candidate={candidate}
                  availableProviders={availableProviders}
                  timeLineClassName="connect-window-display-drip-template"
                  FromEmailAddress={FromEmailAddress}
                  currentJobDetails={currentJobDetails}
                  version={version}
                  isComposeEmailModalVisible={isComposeEmailModalVisible}
                  setEmailComposeModalVisibility={setEmailComposeModalVisibility}
                  ref={connectMailRef}
                  isConnectContentPane
                />
              </NetworkIssueErrorBoundary>
            ) : null}
          </div>
        </TabPane>
        <TabPane tab={callTabPaneName} key="call">
          <div className="master-profile-tab-content">
            {candidate ? (
              <NetworkIssueErrorBoundary>
                <CallWindowContainer
                  jobId={jobId}
                  conversationId={candidate.ConversationId}
                  candidate={candidate}
                  portal={candidate.Portal}
                  candidateId={candidate.Id}
                  availableProviders={availableProviders}
                  jobCountryCode={jobCountryCode}
                  currentJobDetails={currentJobDetails}
                  openSipCallWindowsApp={openSipCallWindowsApp}
                />
              </NetworkIssueErrorBoundary>
            ) : null}
          </div>
        </TabPane>
        {isBotEnabled ? (
          <TabPane tab={botTabPaneName} key="bot">
            <div className="master-profile-tab-content">
              {candidate ? (
                <NetworkIssueErrorBoundary>
                  <ChatWindowContainer
                    jobId={jobId}
                    jobGuid={jobGuid}
                    conversationId={candidate.ConversationId}
                    personId={candidate.PersonId}
                    showBotConfigDrawer={showBotConfigDrawer}
                    candidate={candidate}
                    isCandidate360View
                  />
                </NetworkIssueErrorBoundary>
              ) : null}
            </div>
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
};
export default ContentPaneBody;
