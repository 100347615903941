import { setApiStatus } from '../ApiStatusActions';

const SET_MANUAL_SEARCH_CANDIDATES_FETCH_API_STATUS_BY_SOURCE =
  'SET_MANUAL_SEARCH_CANDIDATES_FETCH_API_STATUS_BY_SOURCE';
const SET_CANDIDATES_BY_SOURCE = 'SET_CANDIDATES_BY_SOURCE';
const SET_CANDIDATES_COUNT_BY_SOURCE = 'SET_CANDIDATES_COUNT_BY_SOURCE';
const SET_SECONDS_ELAPSED_TO_FETCH_CANDIDATES_BY_SOURCE = 'SET_SECONDS_ELAPSED_TO_FETCH_CANDIDATES_BY_SOURCE';
const SET_ALL_TAB_CANDIDATE_IDS = 'SET_ALL_TAB_CANDIDATE_IDS';
const SET_ALL_TAB_TOTAL_CANDIDATES_COUNT = 'SET_ALL_TAB_TOTAL_CANDIDATES_COUNT';
const SET_CANDIDATES_FETCHED_COUNT_BY_SOURCE = 'SET_CANDIDATES_FETCHED_COUNT_BY_SOURCE';
const CLEAR_MANUAL_SEARCH_CANDIDATES = 'CLEAR_MANUAL_SEARCH_CANDIDATES';
const SET_CANDIDATE_GROUPS = 'SET_CANDIDATE_GROUPS';
const SET_CANDIDATE_GROUPS_INFO = 'SET_CANDIDATE_GROUPS_INFO';

const setAllTabCandidatesFetchApiStatus = status => setApiStatus({ apiName: 'allTabCandidatesFetchApi', status });

const setfetchManualSearchCandidatesBySourceApiStatus = ({ jobId, source, status }) => ({
  type: SET_MANUAL_SEARCH_CANDIDATES_FETCH_API_STATUS_BY_SOURCE,
  payload: { jobId, source, status },
});

const setCandidatesBySource = ({ jobId, candidates, source, startIndex, candidateAggregations }) => ({
  type: SET_CANDIDATES_BY_SOURCE,
  payload: { jobId, candidates, source, startIndex, candidateAggregations },
});

const setCandidatesCountBySource = ({ jobId, count, source }) => ({
  type: SET_CANDIDATES_COUNT_BY_SOURCE,
  payload: { jobId, count, source },
});

const setCandidatesFetchedCountBySource = ({ jobId, count, source, isMore }) => ({
  type: SET_CANDIDATES_FETCHED_COUNT_BY_SOURCE,
  payload: { jobId, count, source, isMore },
});

const setSecondsElapsedToFetchCandidatesBySource = ({ jobId, source, secondsElapsed }) => ({
  type: SET_SECONDS_ELAPSED_TO_FETCH_CANDIDATES_BY_SOURCE,
  payload: { jobId, secondsElapsed, source },
});

const setAllTabCandidateIds = ({ sources, jobId }) => ({
  type: SET_ALL_TAB_CANDIDATE_IDS,
  payload: { jobId, sources },
});

const setAllTabCandidatesTotalCount = ({ jobId, count }) => ({
  type: SET_ALL_TAB_TOTAL_CANDIDATES_COUNT,
  payload: { jobId, count },
});

const clearManualSearchCandidates = ({ jobId }) => ({
  type: CLEAR_MANUAL_SEARCH_CANDIDATES,
  payload: { jobId },
});

export const setCandidateGroups = ({ jobId, candidateGroupInfo }) => ({
  type: SET_CANDIDATE_GROUPS,
  payload: { jobId, candidateGroupInfo },
});
export const setStoredCandidatesGroupInfo = ({ jobId, storedCandidatesGroupInfo }) => ({
  type: SET_CANDIDATE_GROUPS_INFO,
  payload: { jobId, storedCandidatesGroupInfo },
});
export {
  SET_MANUAL_SEARCH_CANDIDATES_FETCH_API_STATUS_BY_SOURCE,
  SET_CANDIDATES_BY_SOURCE,
  SET_CANDIDATES_COUNT_BY_SOURCE,
  SET_SECONDS_ELAPSED_TO_FETCH_CANDIDATES_BY_SOURCE,
  SET_ALL_TAB_CANDIDATE_IDS,
  SET_CANDIDATES_FETCHED_COUNT_BY_SOURCE,
  SET_ALL_TAB_TOTAL_CANDIDATES_COUNT,
  CLEAR_MANUAL_SEARCH_CANDIDATES,
  SET_CANDIDATE_GROUPS,
  SET_CANDIDATE_GROUPS_INFO,
  setfetchManualSearchCandidatesBySourceApiStatus,
  setCandidatesBySource,
  setCandidatesCountBySource,
  setSecondsElapsedToFetchCandidatesBySource,
  setAllTabCandidatesFetchApiStatus,
  setAllTabCandidateIds,
  setCandidatesFetchedCountBySource,
  setAllTabCandidatesTotalCount,
  clearManualSearchCandidates,
};
