/* eslint-disable no-param-reassign */
import _ from 'lodash';
import {
  setfetchManualSearchCandidatesBySourceApiStatus,
  setAllTabCandidatesFetchApiStatus,
  setCandidatesBySource,
  setCandidatesCountBySource,
  setSecondsElapsedToFetchCandidatesBySource,
  setAllTabCandidateIds,
  setCandidatesFetchedCountBySource,
  setAllTabCandidatesTotalCount,
  clearManualSearchCandidates as _clearManualSearchCandidates,
  setCandidateGroups,
  setStoredCandidatesGroupInfo,
} from './ActionCreators/ManualSearchCandidateActionCreators';
import * as manualSearchRepository from '../Repository/ManualSearchRepository';
import { mergeCandidateData, getSourceName } from '../Utils/SourceUtils';
import { reorderManualSearchSources } from './ManualSearchActions';
import { sortDuplicateCandidates, getGroupHeadByCandidateId } from '../Utils/DeDuplicationUtils';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { allowedDeDuplicationSources } from '../Utils/CandidateListUtils';
import {
  setCandidatesForDeDuplicationBySource,
  setCandidateGroupsBySource,
  setCandidateGroupsInfoBySource,
} from './ActionCreators/CandidateDeDuplicationActionCreators';
import { setCandidates } from './ActionCreators/CandidateActions';

const INPROGRESS = 'INPROGRESS';
const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';

export function generateDeDuplicationKey(candidate) {
  const { Name: candidateName } = candidate;
  const lowerCaseCandidateName = candidateName?.toLowerCase();
  const nameParts = lowerCaseCandidateName.split(' ');
  const firstName = nameParts?.length > 0 ? nameParts[0] : '';
  const lastName = nameParts?.length > 1 ? nameParts.slice(1).join(' ') : '';
  return firstName < lastName ? `${firstName} ${lastName}` : `${lastName} ${firstName}`;
}

export const getSortedGroupedCandidates = (allCandidates, candidatesArray, revealActiveChannelSourceName) => {
  Object.keys(candidatesArray).forEach(key => {
    candidatesArray[key].candidateIds = sortDuplicateCandidates(
      candidatesArray[key].candidateIds,
      allCandidates,
      revealActiveChannelSourceName
    );
    const { candidateIds } = candidatesArray[key];
    const [GroupHead] = candidateIds;
    candidatesArray[key].GroupHead = GroupHead;
  });

  return candidatesArray;
};

export const getShouldGroupCandidate = candidateName => {
  const trimmedCandidateName = candidateName?.trim();
  return trimmedCandidateName && trimmedCandidateName.split(' ').length > 1;
};

export function groupCandidates(storedCandidates, nonStoredCandidates, store, sourceName) {
  let groupedCandidates = {};
  Object.values(storedCandidates).forEach(candidate => {
    const shouldGroupCandidate = getShouldGroupCandidate(candidate.Name);
    if (shouldGroupCandidate) {
      const key = generateDeDuplicationKey(candidate);
      if (groupedCandidates[key]) {
        groupedCandidates[key].candidateIds.push(candidate.Id);
      } else {
        const groupId = key;
        groupedCandidates[key] = {
          GroupHead: candidate.Id,
          candidateIds: [candidate.Id],
          groupId,
        };
      }
    }
  });
  nonStoredCandidates.forEach(candidate => {
    const shouldGroupCandidate = getShouldGroupCandidate(candidate.Name);
    if (shouldGroupCandidate) {
      const key = generateDeDuplicationKey(candidate);
      if (groupedCandidates[key]) {
        groupedCandidates[key].candidateIds.push(candidate.Id);
      } else {
        const groupId = key;
        groupedCandidates[key] = {
          GroupHead: candidate.Id,
          candidateIds: [candidate.Id],
          groupId,
        };
      }
    }
  });
  groupedCandidates = Object.values(groupedCandidates);
  const groupedDuplicates = groupedCandidates.filter(candidate => candidate.candidateIds.length > 1);
  const groupedDuplicatesWithUuid = {};
  const storedCandidatesGroupInfo = groupedDuplicates.flatMap(candidate => {
    const nonNewCandidateIds = candidate.candidateIds.filter(
      candidateId => !(candidateId in Object.values(nonStoredCandidates).map(item => item.Id))
    );
    return nonNewCandidateIds.map(candidateId => {
      return {
        candidateId,
        CandidateGroup: candidate.groupId,
      };
    });
  });
  groupedDuplicates.forEach(candidate => {
    groupedDuplicatesWithUuid[candidate.groupId] = candidate;
    delete candidate.groupId;
  });
  nonStoredCandidates.forEach(candidate => {
    const candidateGroupId = Object.keys(groupedDuplicatesWithUuid).find(key =>
      groupedDuplicatesWithUuid[key].candidateIds.includes(candidate.Id)
    );
    if (candidateGroupId) {
      candidate.CandidateGroup = candidateGroupId;
    }
  });
  const featureToggleList = getFeatureToggleList(store);
  const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
  const allCandidates = [...nonStoredCandidates, ...Object.values(storedCandidates)];
  const candidateGroupInfo = getSortedGroupedCandidates(
    allCandidates,
    groupedDuplicatesWithUuid,
    revealActiveChannelSourceName
  );
  return { candidateGroupInfo, nonStoredCandidates, storedCandidatesGroupInfo };
}

export const mergeCandidates = ({ candidates, storedCandidates, mergeProperty }) => {
  const mergedCandidates = [...storedCandidates];
  candidates.forEach(candidate => {
    const existingCandidate = mergedCandidates.find(
      storedCandidate => storedCandidate[mergeProperty] === candidate[mergeProperty]
    );
    if (existingCandidate) {
      Object.assign(existingCandidate, candidate);
    } else {
      mergedCandidates.push(candidate);
    }
  });
  return mergedCandidates;
};

export const getMergedDeDuplicatedCandidates = mergedCandidatesPayload => {
  const { candidates, candidateGroupInfo, storedCandidates = [] } = mergedCandidatesPayload;
  const totalCurrentCandidates = mergeCandidates({ candidates, storedCandidates, mergeProperty: 'Id' });
  const groupHeads = candidates.map(candidate => {
    return getGroupHeadByCandidateId(candidateGroupInfo, candidate.Id);
  });
  const uniqueGroupHeads = _.uniq(groupHeads);
  const groupHeadCandidates = uniqueGroupHeads.map((Id, index) => {
    return {
      ...totalCurrentCandidates.find(candidate => candidate.Id === Id),
      IsQuickSearchCandidate: true,
      candidateIndex: index,
    };
  });
  const filteredCandidates = candidates.filter(candidate => {
    return !groupHeadCandidates.some(groupCandidate => groupCandidate.Id === candidate.Id);
  });
  const nonGroupHeadCandidates = filteredCandidates.map(filteredCandidate => {
    return {
      ...totalCurrentCandidates.find(candidate => candidate.Id === filteredCandidate.Id),
      IsQuickSearchCandidate: true,
    };
  });
  const allCandidates = [...groupHeadCandidates, ...nonGroupHeadCandidates];
  return _.uniqBy(allCandidates, 'Id');
};

export const groupDuplicatesBySource = ({ candidates, jobId, source, shouldSetCandidates }) => {
  return async (dispatch, getState) => {
    const store = getState();
    const storedCandidates = store.CandidateDeDuplicationReducer.ByJobId[jobId]?.BySource?.[source] || [];
    const newCandidates = _.differenceBy(candidates, storedCandidates, 'Id');
    let updatedCandidates = candidates;
    if (newCandidates.length > 0) {
      const { candidateGroupInfo, nonStoredCandidates, storedCandidatesGroupInfo } = groupCandidates(
        storedCandidates,
        newCandidates,
        store,
        source
      );
      dispatch(setCandidatesForDeDuplicationBySource({ jobId, candidates: nonStoredCandidates, sourceName: source }));
      dispatch(setCandidateGroupsBySource({ jobId, sourceName: source, candidateGroupInfo }));
      dispatch(setCandidateGroupsInfoBySource({ jobId, sourceName: source, storedCandidatesGroupInfo }));
      if (shouldSetCandidates) {
        const mergedCandidatesPayload = {
          candidates,
          candidateGroupInfo,
          storedCandidates,
        };
        updatedCandidates = getMergedDeDuplicatedCandidates(mergedCandidatesPayload);
      }
    } else if (shouldSetCandidates) {
      const candidateGroupBySource = store?.CandidateDeDuplicationReducer?.ByJobId?.[jobId]?.CandidateGroups ?? {};
      const candidateGroupInfo = candidateGroupBySource[source] || {};
      updatedCandidates = getMergedDeDuplicatedCandidates({
        candidates,
        candidateGroupInfo,
        storedCandidates,
      });
    }
    if (shouldSetCandidates) dispatch(setCandidates(updatedCandidates));
  };
};

function fetchManualSearchCandidates({
  manualCriteria,
  jobId,
  isMore,
  allowReordering,
  allowSetFetchCandidateApiStatus,
}) {
  return async (dispatch, getState) => {
    const source = getSourceName(manualCriteria.Sources[0]);
    try {
      if (allowSetFetchCandidateApiStatus)
        dispatch(setfetchManualSearchCandidatesBySourceApiStatus({ status: INPROGRESS, source, jobId }));
      const start = Date.now();
      const response = await manualSearchRepository.fetchManualSearchCandidates({
        criteria: manualCriteria,
        from: manualCriteria.From,
        size: manualCriteria.Size,
        jobId,
      });
      const secondsElapsed = Math.floor((Date.now() - start) / 1000);
      const responseData = response.data[0];
      const candidatesObject = responseData.Candidates || [];
      const count = responseData.Total || 0;
      const mergedcandidates = mergeCandidateData({ candidatesObject, isQuickSearchCandidate: true });
      const candidateAggregations = responseData.Aggregations;
      const store = getState();
      const manualSearchCandidates = store.ManualSearchCandidateReducer.ByJobId[jobId].CandidatesById || {};
      const { candidateGroupInfo, nonStoredCandidates, storedCandidatesGroupInfo } = groupCandidates(
        manualSearchCandidates,
        mergedcandidates,
        store
      );
      const candidates = nonStoredCandidates;
      if (allowedDeDuplicationSources.includes(source))
        dispatch(groupDuplicatesBySource({ candidates, jobId, source }));
      if (!_.isEmpty(candidateGroupInfo)) {
        dispatch(setCandidateGroups({ jobId, candidateGroupInfo }));
        dispatch(setStoredCandidatesGroupInfo({ jobId, storedCandidatesGroupInfo }));
      }
      dispatch(setCandidatesBySource({ source, jobId, candidates, candidateAggregations }));
      dispatch(setCandidatesCountBySource({ source, jobId, count }));
      dispatch(setCandidatesFetchedCountBySource({ source, jobId, count: candidates.length, isMore }));
      dispatch(setSecondsElapsedToFetchCandidatesBySource({ source, jobId, secondsElapsed }));
      if (allowSetFetchCandidateApiStatus)
        dispatch(setfetchManualSearchCandidatesBySourceApiStatus({ status: COMPLETED, source, jobId }));
      if (allowReordering) dispatch(reorderManualSearchSources({ jobId }));
    } catch (errorResponse) {
      dispatch(setfetchManualSearchCandidatesBySourceApiStatus({ status: FAILED, source, jobId }));
    }
  };
}

function fetchManualSearchCandidatesBySources({
  manualCriteria,
  jobId,
  isMore,
  allowReordering,
  allowSetFetchCandidateApiStatus = true,
}) {
  return async (dispatch, getState) => {
    const sources = manualCriteria.Sources.filter(x => x.Portal !== 'All');
    try {
      if (allowSetFetchCandidateApiStatus) dispatch(setAllTabCandidatesFetchApiStatus(INPROGRESS));
      await Promise.all(
        sources.map(source =>
          dispatch(
            fetchManualSearchCandidates({
              manualCriteria: { ...manualCriteria, Sources: [source] },
              jobId,
              isMore,
              allowReordering,
              allowSetFetchCandidateApiStatus,
            })
          )
        )
      );
      const sourceNames = sources.map(source => getSourceName(source));
      dispatch(setAllTabCandidateIds({ jobId, sources: sourceNames }));
      if (!isMore) {
        const store = getState();
        const bySource = store.ManualSearchCandidateReducer.ByJobId[jobId].BySource;
        let count = 0;
        const updatedBySource = Object.keys(bySource)
          .filter(key => key !== 'All')
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: bySource[key] });
          }, {});
        Object.values(updatedBySource).forEach(x => {
          count += x.TotalCount ?? 0;
        });
        dispatch(setAllTabCandidatesTotalCount({ jobId, count }));
      }
      if (allowSetFetchCandidateApiStatus) dispatch(setAllTabCandidatesFetchApiStatus(COMPLETED));
    } catch (error) {
      dispatch(setAllTabCandidatesFetchApiStatus('FAILED'));
    }
  };
}

function fetchNextBatchCandidates({ manualCriteria, jobId, allowSetFetchCandidateApiStatus }) {
  return (dispatch, getState) => {
    const batchesFetchedSoFar = _.get(
      getState(),
      ['ManualSearchCandidateReducer', 'ByJobId', jobId, 'BatchesFetched'],
      0
    );
    dispatch(
      fetchManualSearchCandidatesBySources({
        manualCriteria: { ...manualCriteria, From: 25 * batchesFetchedSoFar, Size: 25 },
        jobId,
        isMore: true,
        allowSetFetchCandidateApiStatus,
      })
    );
  };
}

function clearManualSearchCandidates({ jobId }) {
  return _clearManualSearchCandidates({ jobId });
}

const resetAllTabCandidatesFetchApiStatus = () => {
  return setAllTabCandidatesFetchApiStatus(undefined);
};

export {
  fetchManualSearchCandidates,
  fetchManualSearchCandidatesBySources,
  fetchNextBatchCandidates,
  clearManualSearchCandidates,
  resetAllTabCandidatesFetchApiStatus,
};
