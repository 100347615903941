import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Skeleton, message, Spin } from 'antd';
import WorkflowCreationContainer from '../WorkflowCreationContainer/WorkflowLayoutContainer/WorkflowLayoutContainer';
import * as WorkflowActions from '../../Actions/WorkflowActions';
import { initialElements } from '../WorkflowCreationContainer/Data/InitialElements';
import * as WorkflowReducer from '../../Reducers/WorkflowReducer';
import * as EmailTemplateActions from '../../Actions/EmailTemplates';
import * as SmsActions from '../../Actions/TextTemplates';
import { isPulseUser } from '../../Utils/ConfigUtils';
import WorfklowDetailsHeader from './WorfklowDetailsHeader';
import {
  generateWorkflowPayload,
  getStartActivityId,
  isCreatedWorkflowValid,
} from '../WorkflowCreationContainer/WorkflowUtils/WorkflowElementUtils';
import { generatePayload } from '../WorkflowCreationContainer/WorkflowUtils/PayloadGenerationUtility';
import WorkflowDetailsFooter from './WorkflowDetailsFooter';
import styles from './WorkflowDetailsContainer.module.scss';
import * as EmailTemplatesReducer from '../../Reducers/EmailTemplates';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import * as WorkflowReportActions from '../../Actions/WorkflowReportActions';
import * as WorkflowReprotReducer from '../../Reducers/WorkflowReportReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTags } from '../../Utils/MergeTagsUtils';

const emptyWorkflowNameErrorMessage = 'Please enter a workflow name';
const maxCharLimitExceededErrorMessage = 'Name cannot exceed 100 characters';

const mapStateToProps = (state, props) => {
  const { workflowId, type } = props;
  const templateDetails =
    (workflowId && !type) || (workflowId && type === 'preview') || (type === 'create' && workflowId)
      ? WorkflowReducer.getWorkflowDripTemplatesById(state, workflowId)
      : null;

  return {
    initialValues: templateDetails || initialElements,
    workflowApiStatus: WorkflowReducer.getWorkflowApiStatusById(state, workflowId),
    emailTemplatesById: EmailTemplatesReducer.getEmailTemplatesById(state),
    mergeTags: MergeTagsReducer.getMergeTags(state),
    featureToggleList: getFeatureToggleList(state),
    workflowReportFilterData: WorkflowReprotReducer.getWorkflowReportFilterData(state),
    workflowReportApiStatus: WorkflowReprotReducer.getWorkflowReportApiStatus(state),
    isWorkflowReportModalVisible: WorkflowReprotReducer.getWorkflowReportModalVisibility(state),
  };
};

const mapDispatchToProps = {
  setWorkflowTemplateFetchApiStatus: WorkflowActions.setWorkflowTemplateFetchApiStatus,
  searchEmailTemplates: EmailTemplateActions.searchEmailTemplates,
  duplicateBulkEmailTemplates: EmailTemplateActions.duplicateBulkEmailTemplates,
  fetchSmsTemplates: SmsActions.searchTextTemplates,
  saveWorkflowTemplate: WorkflowActions.saveWorkflowTemplate,
  setWorkflowReportData: WorkflowReportActions.setWorkflowReportData,
  setWorkflowReportFilterData: WorkflowReportActions.setWorkflowReportFilterData,
  setWorkflowReportModalVisibility: WorkflowReportActions.setWorkflowReportModalVisibility,
  fetchWorkflowTemplateById: WorkflowActions.fetchWorkflowTemplateById,
  setWorkflowReportEndNodesData: WorkflowReportActions.setWorkflowReportEndNodesData,
  setWorkflowPublishedStatus: WorkflowReportActions.setWorkflowPublishedStatus,
  setWorkflowNodeConnections: WorkflowActions.setWorkflowNodeConnections,
};

const WorkflowDetailsContainer = props => {
  const [workflowName, setWorkflowName] = React.useState();
  const [validationMessage, setValidationMessage] = React.useState();
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [elements, setElements] = React.useState([]);

  const {
    type,
    workflowId,
    setUrl,
    initialValues,
    saveWorkflowTemplate,
    fetchWorkflowTemplateById,
    setWorkflowReportData,
    workflowApiStatus,
    setWorkflowTemplateFetchApiStatus,
    workflowReportFilterData,
    isWorkflowReportModalVisible,
    setWorkflowReportFilterData,
    setWorkflowReportModalVisibility,
    setWorkflowNodeConnections,
    setWorkflowReportEndNodesData,
    setWorkflowPublishedStatus,
    workflowReportApiStatus,
    searchEmailTemplates,
    fetchSmsTemplates,
    mergeTags,
    featureToggleList,
    version,
    jobDetails,
    location,
  } = props;

  const isWorkflowApiStatusCompleted = workflowApiStatus === 'Completed';
  const isWorkflowEditPreview = _.get(location, ['state', 'isWorkflowEditPreview'], false);
  const isDraft = _.get(location, ['state', 'isDraft'], false);
  const isDraftOrNotPublished = isDraft || !initialValues?.IsPublished;
  const filteredMergeTags = getFilteredMergeTags(
    mergeTags,
    {
      CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
      JobSummary: featureToggleList.JobSummary,
      CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
    },
    version,
    jobDetails
  );

  const isWorkflowLive = workflowReportFilterData.Jobs?.length;
  const onClose = () => {
    setWorkflowTemplateFetchApiStatus({ workflowId, status: null });
  };

  React.useEffect(() => {
    if (workflowId) {
      fetchWorkflowTemplateById(workflowId);
    }
    searchEmailTemplates({ From: 0, Size: 10, IncludeSystemTemplate: !isPulseUser(), ExcludeTags: [] });
    fetchSmsTemplates({ From: 0, Size: 10 });
    return () => {
      onClose();
    };
  }, []);

  React.useEffect(() => {
    if (type === 'preview' && !isDraft && initialValues?.IsPublished) {
      const data = {
        id: workflowId,
        aggregationIds: [workflowId],
      };
      setWorkflowReportData(data);
      setWorkflowReportFilterData(workflowId);
      setWorkflowPublishedStatus(initialValues?.IsPublished);
    } else setWorkflowPublishedStatus(false);
    return () => {
      setWorkflowPublishedStatus(false);
    };
  }, [workflowId, type, initialValues?.IsPublished]);

  React.useEffect(() => {
    if (type === 'preview' && !isDraft && initialValues?.Connections?.length > 0) {
      setWorkflowNodeConnections({ connections: initialValues.Connections });
    }
    return () => {
      setWorkflowNodeConnections({ reinitiate: true });
    };
  }, [initialValues?.Connections]);

  React.useEffect(() => {
    if (isWorkflowApiStatusCompleted && type !== 'create') setWorkflowName(initialValues?.Name);
  }, [isWorkflowApiStatusCompleted]);

  React.useEffect(() => {
    if (initialValues?.IsPublished) setWorkflowReportFilterData(workflowId);
  }, [initialValues?.IsPublished]);

  const loadingStatus = workflowApiStatus !== 'Completed' && workflowId;

  const handleInputChange = event => {
    const name = event.target.value;
    setWorkflowName(name);
    if (name) setValidationMessage('');
    else if (!name?.trim()) setValidationMessage(emptyWorkflowNameErrorMessage);
    else if (name?.length > 100) setValidationMessage(maxCharLimitExceededErrorMessage);
  };

  const validationStatus = validationMessage ? 'error' : '';

  const scrollToInput = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onSaveClickCallback = () => {
    if (!workflowName) {
      scrollToInput();
      setValidationMessage(emptyWorkflowNameErrorMessage);
    } else if (isWorkflowLive) {
      if (initialValues?.Name === workflowName) setValidationMessage('Please enter different name');
    }
  };

  const onEdit = () => {
    const _elements = elements.map(x => ({
      ...x,
      data: { ...x.data, type: undefined },
    }));
    setElements(_elements);
  };

  const saveWorkflowDetails = async ({ isPublish }) => {
    onSaveClickCallback();
    if (!workflowName) {
      message.error('Please enter workflow name');
      return;
    }
    if (isWorkflowLive) {
      if (initialValues?.Name.trim() === workflowName?.trim()) {
        message.error('Please enter different name');
        return;
      }
    }

    const isNewWorkFlow = type === 'create' || isWorkflowLive;
    const paylaod = generatePayload({ elements });
    const { nodes, edges } = paylaod;
    const startActivityId = getStartActivityId(nodes, edges);
    const saveDraftPaylaod = generateWorkflowPayload({
      nodes,
      edges,
      workflowName,
      startActivityId,
      publish: isPublish,
      isCreate: isNewWorkFlow,
      editWorkflowDefinitionId: workflowId,
    });
    const { pathname } = location;
    try {
      setLoadingFlag(true);
      await saveWorkflowTemplate(saveDraftPaylaod);
      setUrl(`${pathname}?tab=workflows`);
      setWorkflowName('');
    } catch {
      setLoadingFlag(false);
    }
  };

  const onSaveWorkflow = () => {
    saveWorkflowDetails({ isPublish: true });
  };

  const onSaveDraftWorkflow = () => {
    saveWorkflowDetails({ isPublish: false });
    localStorage.setItem('DraftSaved', true);
  };

  const saveButtonTooltipMessage = isCreatedWorkflowValid(elements);
  const isSaveDisabled = saveButtonTooltipMessage || !!validationStatus;
  const isSaveDraftDisabled = elements?.length <= 3 || !!validationStatus;
  const isDisabled = type === 'preview';

  const displayWorkflowFooter = isDisabled ? null : (
    <WorkflowDetailsFooter
      onSaveWorkflow={onSaveWorkflow}
      isCreate={type === 'create'}
      isDraft={isDraftOrNotPublished}
      isWorkflowLive={isWorkflowLive}
      loadingFlag={loadingFlag}
      isSaveDisabled={isSaveDisabled}
      saveButtonTooltipMessage={saveButtonTooltipMessage}
      onSaveDraftWorkflow={onSaveDraftWorkflow}
      isSaveDraftDisabled={isSaveDraftDisabled}
      onCancelWorkflow={onClose}
      version={version}
    />
  );

  return (
    <div className={styles.workflowDetailsContainer}>
      <Spin spinning={workflowReportApiStatus}>
        <Skeleton loading={loadingStatus}>
          <WorfklowDetailsHeader
            setUrl={setUrl}
            isWorkflowEditPreview={isWorkflowEditPreview}
            handleInputChange={handleInputChange}
            validationStatus={validationStatus}
            displayMode={type}
            validationMessage={validationMessage}
            workflowName={workflowName}
            workflowId={workflowId}
            setWorkflowReportData={setWorkflowReportData}
            workflowReportFilterData={workflowReportFilterData}
            setWorkflowReportEndNodesData={setWorkflowReportEndNodesData}
            onCancelWorkflow={onClose}
            isDraft={isDraftOrNotPublished}
            isWorkflowLive={isWorkflowLive}
            isCandidateCRMFeatureEnabled={featureToggleList.CandidateCRM.IsEnabled}
            location={location}
            version={version}
            onEdit={onEdit}
          />

          <WorkflowCreationContainer
            workflowName={workflowName}
            setWorkflowName={setWorkflowName}
            initialElements={initialValues}
            saveWorkflowTemplate={saveWorkflowTemplate}
            editWorkflowDefinitionId={workflowId}
            setUrl={setUrl}
            onCancelWorkflow={onClose}
            validationStatus={validationStatus}
            onSaveClickCallback={onSaveClickCallback}
            elements={elements}
            displayMode={type}
            isWorkflowReportModalVisible={isWorkflowReportModalVisible}
            setWorkflowReportModalVisibility={setWorkflowReportModalVisibility}
            setElements={setElements}
            mergeTags={filteredMergeTags}
            isDisabled={isDisabled}
            version={version}
            workflowType={type}
            workflowReportApiStatus={workflowReportApiStatus}
          />
          {displayWorkflowFooter}
        </Skeleton>
      </Spin>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDetailsContainer);
export { WorkflowDetailsContainer as WorkflowContainerWithoutStore };
