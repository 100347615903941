import * as React from 'react';
import { Icon } from 'antd';

const CreateCampaignSvg = props => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4062 2.65625H1.59375C1.2999 2.65625 1.0625 2.89365 1.0625 3.1875V13.8125C1.0625 14.1063 1.2999 14.3438 1.59375 14.3438H15.4062C15.7001 14.3438 15.9375 14.1063 15.9375 13.8125V3.1875C15.9375 2.89365 15.7001 2.65625 15.4062 2.65625ZM14.0648 4.46416L8.82705 8.53984C8.69756 8.64111 8.5166 8.64111 8.38711 8.53984L3.14766 4.46416C3.12791 4.44892 3.11342 4.42788 3.10623 4.40399C3.09904 4.3801 3.0995 4.35456 3.10755 4.33094C3.11561 4.30733 3.13085 4.28683 3.15114 4.27231C3.17143 4.2578 3.19575 4.25 3.2207 4.25H13.9918C14.0167 4.25 14.0411 4.2578 14.0614 4.27231C14.0817 4.28683 14.0969 4.30733 14.1049 4.33094C14.113 4.35456 14.1135 4.3801 14.1063 4.40399C14.0991 4.42788 14.0846 4.44892 14.0648 4.46416Z"
      fill="#13C26B"
    />
  </svg>
);
const CreateCampaignIcon = props => (
  <Icon component={customProps => <CreateCampaignSvg {...props} {...customProps} />} {...props} />
);
export default CreateCampaignIcon;
