import React from 'react';
import { Button } from 'antd';
import styles from './SkillsSection.module.scss';
import BooleanSearch from './BooleanSearch/BooleanSearch';
import AryaAssistedSkills from './AryaAssistedSkills/AryaAssistedSkills';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import { setUserSkillSearchPreference } from '../../../../Utils/LocalStorageUtils';
import eventTypes from '../../../../Analytics/EventTypes';
import { mapSkillSuggestions } from '../../../../Utils/ManualSearchUtils';
import MoreSkillsSection from './MoreSkillsSection/MoreSkillsSection';

export const onClearFormValues = ({ form, value, isMinimizedManualSearch }) => {
  if (value === 'AI') {
    const currentSkills = form.getFieldValue('PreferredSkills');
    const updatedSkills = currentSkills.map(skill => {
      return {
        ...skill,
        isProminent: false,
        isPreferred: false,
        aliasSkills: mapSkillSuggestions(skill.aliasSkills, false),
      };
    });
    form.setFieldsValue({ PreferredSkills: updatedSkills });
  } else {
    form.setFieldsValue({ QueryString: '' });
  }
  if (!isMinimizedManualSearch) form.setFieldsValue({ ExcludedSkills: [], MustHaves: {} });
};

export const ActiveSearchStringButton = ({
  value,
  onChange,
  visible,
  form,
  currentUserDetails,
  isMinimizedManualSearch,
  excludeClearButton,
}) => {
  const toggleActiveSearchString = () => {
    const newValue = value === 'Manual' ? 'AI' : 'Manual';
    onChange(newValue);
    setUserSkillSearchPreference(newValue, currentUserDetails?.UserGuid);
  };

  const toggleButton = (
    <div
      className={styles.skillsSectionLabel}
      role="button"
      tabIndex={0}
      onClick={toggleActiveSearchString}
      onKeyPress={toggleActiveSearchString}
    >
      {value === 'AI' ? 'Use Boolean Search String' : 'Use Arya’s Assistance'}
    </div>
  );

  const onClear = () => {
    onClearFormValues({ form, value, isMinimizedManualSearch });
  };

  if (isMinimizedManualSearch) {
    return (
      <div className={styles.manualSearchPopoverFooter}>
        <Button className={styles.clearButton} onClick={onClear} type="link">
          Clear
        </Button>
      </div>
    );
  }

  if (excludeClearButton) {
    return toggleButton;
  }

  return (
    <div className={styles.clearAllButton} style={{ display: visible ? 'flex' : 'none' }}>
      {toggleButton}
      <div>|</div>
      <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearSkillsFilter} />
    </div>
  );
};

export default function SkillsSection(props) {
  const {
    isManualSearchFormMinimized,
    form,
    defaultSkills = {},
    defaultQueryString,
    fetchSkillIntel,
    defaultActiveSearchString,
    currentUserDetails,
    jobId,
    booleanSearchStrings,
    fetchBooleanSearchStrings,
  } = props;

  const [searchStringValues, setSearchStringValues] = React.useState([]);
  const isBooleanSearch = form.getFieldValue('ActiveSearchString') === 'Manual';
  const {
    Prefers: defaultPrefers = [],
    Keywords: keywords = [],
    Musts: defaultMustHaves,
    Excludes: defaultExcludes,
  } = defaultSkills;

  const isAryaAssistanceTabVisible = form.getFieldValue('ActiveSearchString') === 'AI';

  React.useEffect(() => {
    if (booleanSearchStrings) setSearchStringValues(booleanSearchStrings?.map(x => x.Value));
  }, [booleanSearchStrings]);

  return (
    <>
      <div className={`${isManualSearchFormMinimized ? styles.popoverKeywordSectionContainer : null}`}>
        <div className={`${isManualSearchFormMinimized ? styles.showSection : styles.hideSection}`}>
          {' '}
          <div className={styles.popoverActiveStringToggleButton}>
            {form.getFieldDecorator('ActiveSearchString', { initialValue: defaultActiveSearchString })(
              <ActiveSearchStringButton
                excludeClearButton
                visible
                form={form}
                currentUserDetails={currentUserDetails}
              />
            )}
          </div>
        </div>
        <div
          className={`${styles.skillSelection} ${!isManualSearchFormMinimized ? styles.expanded : styles.popoverView}`}
        >
          <div className={`${isManualSearchFormMinimized ? styles.hideSection : styles.showSection}`}>
            {form.getFieldDecorator('ActiveSearchString', { initialValue: defaultActiveSearchString })(
              <ActiveSearchStringButton
                visible={!isManualSearchFormMinimized}
                form={form}
                currentUserDetails={currentUserDetails}
              />
            )}
          </div>
          {isManualSearchFormMinimized ? null : (
            <div className={styles.skillsLabel} style={{ display: 'block' }}>
              Keywords
            </div>
          )}
          <div
            className={`${styles.aryaAssistedSkills}`}
            style={{ display: isAryaAssistanceTabVisible ? 'block' : 'none' }}
          >
            <AryaAssistedSkills
              form={form}
              defaultPrefers={defaultPrefers}
              defaultMustHaves={defaultMustHaves}
              defaultExcludes={defaultExcludes}
              fetchSkillIntel={fetchSkillIntel}
              isManualSearchFormMinimized={isManualSearchFormMinimized}
            />
          </div>
          <div className={styles.booleanSkills} style={{ display: isAryaAssistanceTabVisible ? 'none' : 'block' }}>
            <BooleanSearch
              jobId={jobId}
              isBooleanSearch={isBooleanSearch}
              searchStringValues={searchStringValues}
              fetchBooleanSearchStrings={fetchBooleanSearchStrings}
              form={form}
              keywords={keywords}
              defaultQueryString={defaultQueryString}
              isManualSearchFormMinimized={isManualSearchFormMinimized}
            />
          </div>
          <div>
            <MoreSkillsSection
              form={form}
              defaultMustHaves={defaultMustHaves}
              defaultExcludes={defaultExcludes}
              isManualSearchFormMinimized={isManualSearchFormMinimized}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={`${isManualSearchFormMinimized ? styles.showSection : styles.hideSection}`}>
          {form.getFieldDecorator('ActiveSearchString', { initialValue: defaultActiveSearchString })(
            <ActiveSearchStringButton isMinimizedManualSearch form={form} currentUserDetails={currentUserDetails} />
          )}
        </div>
      </div>
    </>
  );
}
