import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { message } from 'antd';
import AryaSip from '../../AryaSIPML';
import config from '../../Config/Config';
import { getJobsById, getCurrentJobId } from '../../Reducers/JobReducer';

import CallDialog from '../../Components/Connect/CallDialog/CallDialog';

import * as ConnectActions from '../../Actions/ConnectActions';
import * as CallDialogActions from '../../Actions/CallDialogActions';

import { getCandidateCallNotes } from '../../Reducers/CandidateReducer';
import { getCallDialogState, getCallDialogNotesViewState } from '../../Reducers/CallDialogReducer';
import { getOngoingCallId, getCallStatus } from '../../Reducers/CallConversationsReducer';
import { getUsersById, getConnectUsersById } from '../../Reducers/UserReducer';
import { getCurrentUserOrgGuid, getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getInternalSourceWithCandidateId } from '../../Utils/SourceUtils';

const mapStateToProps = state => {
  const callDialogState = getCallDialogState(state);
  const ongoingCallId = getOngoingCallId(state, callDialogState.conversationId);
  const jobsById = getJobsById(state);
  const currentJobId = getCurrentJobId(state);
  const atsJobCode = _.get(jobsById, [currentJobId, 'JobCode'], 0);
  const notesViewState = getCallDialogNotesViewState(state);
  return {
    isVisible: callDialogState.isVisible,
    isCallInitiated: callDialogState.isCallInitiated,
    conversationId: callDialogState.conversationId,
    displayName: callDialogState.callerId,
    personName: callDialogState.personName,
    phoneNumber: callDialogState.phoneNumber,
    portal: callDialogState.portal,
    candidateId: callDialogState.candidateId,
    jobId: callDialogState.jobId,
    callerId: callDialogState.callerId,
    isSipRegistered: callDialogState.isSipRegistered,
    ongoingCallId,
    atsJobCode,
    notesViewState,
    callStatus: getCallStatus(state, callDialogState.conversationId, ongoingCallId),
    notes: getCandidateCallNotes(state, callDialogState.candidateId),
    candidate: callDialogState.candidate,
    usersById: getUsersById(state),
    connectUsersById: getConnectUsersById(state),
    currentUserOrgGuid: getCurrentUserOrgGuid(state),
    currentUser: getCurrentUser(state),
  };
};

const mapDispatchToProps = {
  addNewCallToConversation: ConnectActions.addNewCallToConversation,
  postCallNotes: ConnectActions.postCallNotes,
  unsetOngoingCallId: ConnectActions.unsetOngoingCallId,
  resetCallDialogState: CallDialogActions.resetCallDialogState,
  setCallDialogVisibility: CallDialogActions.setCallDialogVisibility,
  setSipRegistrationStatus: CallDialogActions.setSipRegistrationStatus,
  setCallStatus: CallDialogActions.setCallStatus,
  updateCallStatus: ConnectActions.updateCallStatus,
};

class CallDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onCall = this.onCall.bind(this);
    this.registerSip = this.registerSip.bind(this);
    this.onRegistered = this.onRegistered.bind(this);
    this.onConnecting = this.onConnecting.bind(this);
    this.onConnected = this.onConnected.bind(this);
    this.onRejected = this.onRejected.bind(this);
    this.onFailed = this.onFailed.bind(this);
    this.onCompleted = this.onCompleted.bind(this);
    this.onClose = this.onClose.bind(this);
    this.updateCallStatus = this.updateCallStatus.bind(this);
    this.onHangup = this.onHangup.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.toggleHold = this.toggleHold.bind(this);
    this.toggleCallRecord = this.toggleCallRecord.bind(this);
    this.sendDtmf = this.sendDtmf.bind(this);
    this.postAtsCallNotes = this.postAtsCallNotes.bind(this);
    this.dialingTone = props.dialingTone;
    this.ringingTone = props.ringingTone;
    this.dtmfTone = props.dtmfTone;
  }

  componentDidMount() {
    const { isCallInitiated } = this.props;
    const { callStarted } = this.state;
    if (isCallInitiated && !callStarted) {
      this.onCall();
    }
  }

  componentDidUpdate(prevProps) {
    const { callStatus, isCallInitiated, conversationId, phoneNumber, currentUserOrgGuid } = this.props;
    const { isRinging, callStarted } = this.state;
    if (isCallInitiated && !callStarted && conversationId && phoneNumber) {
      this.onCall();
    }
    if (callStatus !== 'Ringing' && isRinging) {
      this.ringingTone.pause();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isRinging: false,
      });
    }
    if (!prevProps.currentUserOrgGuid && currentUserOrgGuid) {
      this.registerSip();
    }
  }

  pauseDialTone = () => {
    this.dialingTone.pause();
    this.ringingTone.pause();
  };

  makeCall = () => {
    const { phoneNumber, callerId } = this.props;
    this.aryaSip.call(phoneNumber, callerId);
  };

  onCall() {
    const { conversationId, phoneNumber, addNewCallToConversation, resetCallDialogState, callerId } = this.props;
    const { isDailing } = this.state;
    const call = {
      To: phoneNumber,
      From: callerId,
    };
    addNewCallToConversation(conversationId, call);
    this.setState({
      callStarted: true,
      userCanceled: false,
    });
    if (!isDailing) {
      this.dialingTone.play();
      this.dialingTone.loop = true;
      this.setState({
        isDailing: true,
      });
    }
    resetCallDialogState({ isCallInitiated: true });
    if (this.aryaSip.isRegistered()) this.makeCall();
    else this.registerSip(true);
  }

  onCompleted() {
    const { userCanceled } = this.state;
    const { callStatus, resetCallDialogState } = this.props;
    if (callStatus === 'Answered') {
      this.updateCallStatus('Completed');
    } else if ((callStatus === 'Initiated' || callStatus === 'Ringing') && userCanceled) {
      this.updateCallStatus('Canceled');
    } else if ((callStatus === 'Initiated' || callStatus === 'Ringing') && !userCanceled) {
      this.updateCallStatus('Unanswered');
    } else {
      this.updateCallStatus(callStatus);
    }
    resetCallDialogState({ isCallInitiated: false });
    this.setState({
      callStarted: false,
    });
  }

  onConnecting() {
    const { isRinging } = this.state;
    if (!isRinging) {
      this.ringingTone.play();
      this.dialingTone.pause();
      this.ringingTone.loop = true;
      this.setState({
        isRinging: true,
        isDailing: false,
      });
    }
    this.updateCallStatus('Ringing');
  }

  onConnected() {
    this.updateCallStatus('Answered');
  }

  onClose() {
    const { resetCallDialogState, unsetOngoingCallId, conversationId } = this.props;
    this.setState({
      callStarted: false,
      displayCallStatus: null,
    });
    resetCallDialogState({ isCallInitiated: false, isVisible: false });
    unsetOngoingCallId(conversationId);
  }

  onFailed() {
    const { resetCallDialogState } = this.props;
    this.updateCallStatus('Failed');
    resetCallDialogState({ isCallInitiated: false });
    this.setState({
      callStarted: false,
    });
    this.onClose();
  }

  onHangup() {
    this.setState(
      {
        userCanceled: true,
        isDailing: false,
        isRinging: false,
      },
      this.aryaSip.hangup
    );
    this.dialingTone.pause();
    this.ringingTone.pause();
  }

  onRegistered(isTriedToRegisterAgain) {
    const { setSipRegistrationStatus } = this.props;
    setSipRegistrationStatus({ isSipRegistered: true });
    if (isTriedToRegisterAgain) this.makeCall();
  }

  onRejected() {
    const { userCanceled } = this.state;
    const { callStatus, resetCallDialogState } = this.props;
    let currentStatus;
    if (callStatus === 'Ringing' && !userCanceled) {
      currentStatus = 'Unanswered';
    } else {
      currentStatus = 'Canceled';
    }
    this.updateCallStatus(currentStatus);
    resetCallDialogState({ isCallInitiated: false });
    this.setState({
      callStarted: false,
    });
  }

  toggleMute(isMuted) {
    this.aryaSip.toggleMute(isMuted);
  }

  toggleHold(isOnHold) {
    if (isOnHold) {
      this.aryaSip.hold();
    } else {
      this.aryaSip.unhold();
    }
  }

  sendDtmf(tone) {
    this.aryaSip.sendDTMF(tone);
    this.dtmfTone.play();
  }

  toggleCallRecord(isRecording) {
    this.aryaSip.sendDTMF(isRecording ? 'a' : 'b');
  }

  onUnregistered = isTriedToRegisterAgain => {
    if (isTriedToRegisterAgain) {
      this.onHangup();
      this.onClose();
      message.error(
        'Your internet connection is too slow to initiate the call, please make sure you have a better internet connection'
      );
    }
  };

  registerSip(isTriedToRegisterAgain) {
    const { currentUserOrgGuid, currentUser } = this.props;
    const currentUserEmailId = currentUser.email;
    if (!this.aryaSip || !this.aryaSip.isRegistered()) {
      this.aryaSip = new AryaSip({
        ...config.sipml,
        currentUserOrgGuid,
        currentUserEmailId,
        eventCallbacks: {
          onRegistered: () => {
            this.onRegistered(isTriedToRegisterAgain);
          },
          onUnregistered: () => {
            this.onUnregistered(isTriedToRegisterAgain);
          },
          onConnected: this.onConnected,
          onConnecting: this.onConnecting,
          onFailed: this.onFailed,
          onEnded: this.onCompleted,
          onRejected: this.onRejected,
          pauseDialTone: this.pauseDialTone,
        },
      });
    }
  }

  updateCallStatus(callStatus) {
    const { updateCallStatus, conversationId, ongoingCallId } = this.props;
    this.setState(
      {
        displayCallStatus: callStatus,
      },
      updateCallStatus(conversationId, ongoingCallId, callStatus)
    );
  }

  postAtsCallNotes(note) {
    const { postAtsCallNotes, candidate } = this.props;

    const sources = _.get(candidate, ['Sources'], []);
    const internalSource = getInternalSourceWithCandidateId(sources);

    postAtsCallNotes(note, _.get(internalSource, ['CandidateId'], 0));
  }

  render() {
    const {
      phoneNumber,
      candidateId,
      notes,
      callStatus,
      personName,
      conversationId,
      postCallNotes,
      ongoingCallId,
      isVisible,
      callNotesContainer,
      candidate,
      usersById,
      connectUsersById,
      jobId,
      setCallDialogVisibility,
      atsJobCode,
      notesViewState,
      openSipCallWindowsApp,
    } = this.props;

    const { displayCallStatus } = this.state;
    if (!isVisible) {
      return null;
    }

    return (
      <CallDialog
        phoneNumber={phoneNumber}
        candidateName={personName}
        callStatus={callStatus || displayCallStatus}
        onCall={this.onCall}
        onHangup={this.onHangup}
        onClose={this.onClose}
        onDtmfTone={this.sendDtmf}
        onToggleHold={this.toggleHold}
        onToggleMute={this.toggleMute}
        onToggleCallRecord={this.toggleCallRecord}
        postCallNotes={postCallNotes}
        postAtsCallNotes={this.postAtsCallNotes}
        conversationId={conversationId}
        notes={notes}
        notesViewState={notesViewState}
        audioConversationId={ongoingCallId}
        candidateId={candidateId}
        callNotesContainer={callNotesContainer}
        atsJobCode={atsJobCode}
        usersById={usersById}
        connectUsersById={connectUsersById}
        atsCandidateId={_.get(getInternalSourceWithCandidateId(_.get(candidate, ['Sources'], [])), ['CandidateId'])}
        jobId={jobId}
        setCallDialogVisibility={setCallDialogVisibility}
        candidate={candidate}
        openSipCallWindowsApp={openSipCallWindowsApp}
      />
    );
  }
}
export { CallDialogContainer as CallDialogContainerWithoutStore };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallDialogContainer));
