import * as React from 'react';
import { Icon } from 'antd';

const DeleteSvgV2 = props => {
  const { fillColor, iconHeight } = props;
  const height = iconHeight ?? 29;
  return fillColor ? (
    <svg width="27" height={height} viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.30469 3.28906H8.03125C8.18164 3.28906 8.30469 3.16602 8.30469 3.01562V3.28906H18.6953V3.01562C18.6953 3.16602 18.8184 3.28906 18.9688 3.28906H18.6953V5.75H21.1562V3.01562C21.1562 1.80908 20.1753 0.828125 18.9688 0.828125H8.03125C6.82471 0.828125 5.84375 1.80908 5.84375 3.01562V5.75H8.30469V3.28906ZM25.5312 5.75H1.46875C0.86377 5.75 0.375 6.23877 0.375 6.84375V7.9375C0.375 8.08789 0.498047 8.21094 0.648438 8.21094H2.71289L3.55713 26.0869C3.61182 27.2524 4.57568 28.1719 5.74121 28.1719H21.2588C22.4277 28.1719 23.3882 27.2559 23.4429 26.0869L24.2871 8.21094H26.3516C26.502 8.21094 26.625 8.08789 26.625 7.9375V6.84375C26.625 6.23877 26.1362 5.75 25.5312 5.75ZM20.9956 25.7109H6.0044L5.17725 8.21094H21.8228L20.9956 25.7109Z"
        fill="#83878C"
      />
    </svg>
  ) : (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.25 4H14.25V2.125C14.25 1.29766 13.5773 0.625 12.75 0.625H5.25C4.42266 0.625 3.75 1.29766 3.75 2.125V4H0.75C0.335156 4 0 4.33516 0 4.75V5.5C0 5.60313 0.084375 5.6875 0.1875 5.6875H1.60312L2.18203 17.9453C2.21953 18.7445 2.88047 19.375 3.67969 19.375H14.3203C15.1219 19.375 15.7805 18.7469 15.818 17.9453L16.3969 5.6875H17.8125C17.9156 5.6875 18 5.60313 18 5.5V4.75C18 4.33516 17.6648 4 17.25 4ZM12.5625 4H5.4375V2.3125H12.5625V4Z"
        fill="#13C26B"
      />
    </svg>
  );
};
const DeleteIconV2 = props => (
  <Icon component={customProps => <DeleteSvgV2 {...props} {...customProps} />} {...props} />
);
export default DeleteIconV2;
