import React from 'react';
import queryString from 'query-string';
import ListDetailContainer from './ListDetailContainer';

export function getActiveKeyAndStatus(match, location) {
  let activeKey;
  let status;
  let connectionStatus;
  let publishedStatus;
  const activeKeyAndStatus = {};
  switch (match.params.tab) {
    case 'candidates':
      activeKey = 'candidates';
      break;
    case 'notes':
      activeKey = 'notes';
      break;
    default:
      activeKey = 'candidates';
  }
  activeKeyAndStatus.activeKey = activeKey;
  const values = queryString.parse(location.search);
  if (activeKey === 'candidates') {
    switch (values.status) {
      case 'shortlisted':
        status = 'Shortlisted';
        connectionStatus = null;
        publishedStatus = 'Shortlisted';
        break;
      case 'rejected':
        status = 'Rejected';
        connectionStatus = null;
        publishedStatus = 'Rejected';
        break;
      default:
        status = 'Sourced';
        connectionStatus = null;
        publishedStatus = 'Sourced';
    }
    activeKeyAndStatus.status = status;
    activeKeyAndStatus.connectionStatus = connectionStatus;
    activeKeyAndStatus.publishedStatus = publishedStatus;
  } else if (activeKey === 'notes') {
    activeKeyAndStatus.status = 'notes';
  }
  return activeKeyAndStatus;
}

function SegmentDetailsContainer(props) {
  const { segmentId, onSegmentCreationCallback, history, location, match, openListOfList } = props;
  const [status, setStatus] = React.useState();
  const [connectionStatus, setConnectionStatus] = React.useState();
  const [publishedStatus, setPublishedStatus] = React.useState();
  const setUrl = url => {
    history.push(url);
  };

  React.useEffect(() => {
    setStatus(location?.search?.replace('?status=', ''));
  }, [location?.search]);
  React.useEffect(() => {
    const activeKeyAndStatus = getActiveKeyAndStatus(match, location);
    setStatus(activeKeyAndStatus.status);
    setConnectionStatus(activeKeyAndStatus.connectionStatus);
    setPublishedStatus(activeKeyAndStatus.publishedStatus);
  }, [match, location]);

  return (
    <ListDetailContainer
      segmentId={segmentId}
      onSegmentCreationCallback={onSegmentCreationCallback}
      location={location}
      setStatus={setStatus}
      setConnectionStatus={setConnectionStatus}
      setPublishedStatus={setPublishedStatus}
      status={status}
      connectionStatus={connectionStatus}
      publishedStatus={publishedStatus}
      setUrl={setUrl}
      openListOfList={openListOfList}
    />
  );
}

export default SegmentDetailsContainer;
