import React from 'react';

import { connect } from 'react-redux';
import CandidateProfileContactsTab from '../../Components/CandidateContacts/CandidateProfileContactsTab';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as connectActions from '../../Actions/ConnectActions';

const mapStateToProps = state => ({
  connectInfo: getCandidatesConnectInfo(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  postCallNotes: connectActions.postCallNotes,
};

function CandidateProfileContactsContainer(props) {
  const {
    connectInfo,
    candidate,
    jobId,
    currentJobDetails,
    jobGuid,
    featureToggleList,
    jobCountryCode,
    postCallNotes,
    toggleBotConfigDrawer,
    candidateContext,
    activeSubTab,
    onSubTabClick,
    version,
    mailRef,
    smsRef,
    callRef,
    isCandidateViewHeaderVisible,
    isComposeEmailModalVisible,
    setEmailComposeModalVisibility,
    isComposeMessageModalVisible,
    setMessageComposeModalVisibility,
    currentSelectedEmail,
    setCurrentSelectedEmail,
    phoneNumber,
    setPhoneNumber,
    isMailCommunicationAllowed,
    isMessageCommunicationAllowed,
    openSipCallWindowsApp,
    isContactTabRedirection,
    setCandidateViewHeaderVisibility,
    isConnectIconsEnabled,
    isAudioReadVisible,
    isMessageReadVisible,
    isEmailReadVisible,
    composeEmailType,
    setComposeEmailType,
    contactsTabRef,
  } = props;
  return (
    <div>
      <CandidateProfileContactsTab
        connectInfo={connectInfo}
        candidate={candidate}
        jobId={jobId}
        currentJobDetails={currentJobDetails}
        jobGuid={jobGuid}
        featureToggleList={featureToggleList}
        jobCountryCode={jobCountryCode}
        postCallNotes={postCallNotes}
        toggleBotConfigDrawer={toggleBotConfigDrawer}
        candidateContext={candidateContext}
        activeSubTab={activeSubTab}
        onSubTabClick={onSubTabClick}
        version={version}
        mailRef={mailRef}
        smsRef={smsRef}
        callRef={callRef}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        isComposeEmailModalVisible={isComposeEmailModalVisible}
        setEmailComposeModalVisibility={setEmailComposeModalVisibility}
        isComposeMessageModalVisible={isComposeMessageModalVisible}
        setMessageComposeModalVisibility={setMessageComposeModalVisibility}
        currentSelectedEmail={currentSelectedEmail}
        setCurrentSelectedEmail={setCurrentSelectedEmail}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        isMessageCommunicationAllowed={isMessageCommunicationAllowed}
        isMailCommunicationAllowed={isMailCommunicationAllowed}
        openSipCallWindowsApp={openSipCallWindowsApp}
        isContactTabRedirection={isContactTabRedirection}
        setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
        isConnectIconsEnabled={isConnectIconsEnabled}
        isEmailReadVisible={isEmailReadVisible}
        isAudioReadVisible={isAudioReadVisible}
        isMessageReadVisible={isMessageReadVisible}
        composeEmailType={composeEmailType}
        setComposeEmailType={setComposeEmailType}
        contactsTabRef={contactsTabRef}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfileContactsContainer);
