import _ from 'lodash';
import { setPostAnswersApiStatus, setPostQuestionsApiStatus } from './ActionCreators/AryabotContextActionCreators';
import * as AryabotContextRepository from '../Repository/AryabotContextRepository';
import { patchEditJob } from '../Repository/JobRepository';

export function fetchFrequentQuestions() {
  return dispatch => {
    AryabotContextRepository.fetchFrequentlyAnsweredQuestions().then(response => {
      dispatch({
        type: 'SET_FREQUENT_QUESTIONS',
        payload: response.data,
      });
    });
  };
}

export function createContext(type, contextEntityId, jobId, generateQuestions, answers) {
  return async dispatch => {
    try {
      const response = await AryabotContextRepository.createContext(type, contextEntityId, generateQuestions, answers);
      patchEditJob(jobId, [{ op: 'replace', path: '/BotContextId', value: response.data.Id }]);
      return response.data.Id;
    } catch (error) {
      let Id = null;
      switch (_.get(error, ['response', 'status'], null)) {
        case 409:
          Id = _.get(error, ['response', 'data', 'Error', 'Details', 'Id'], null);
          dispatch({
            type: 'SET_BOT_CONTEXT_ID',
            payload: {
              BotContextId: Id,
              JobId: jobId,
            },
          });
          return Id;
        default:
          break;
      }
      throw error;
    }
  };
}

export function getContext(contextId) {
  return dispatch => {
    AryabotContextRepository.getContext(contextId).then(response => {
      dispatch({
        type: 'SET_CONTEXT',
        payload: response.data,
      });
    });
  };
}

export function getQuestions(contextId) {
  return dispatch => {
    dispatch({
      type: 'GET_JOB_QUESTIONS_API_STATUS',
      payload: 'INPROGRESS',
    });
    AryabotContextRepository.getQuestions(contextId)
      .then(response => {
        dispatch({
          type: 'SET_JOB_QUESTIONS',
          payload: {
            contextId,
            questions: response.data,
          },
        });
        dispatch({
          type: 'GET_JOB_QUESTIONS_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'GET_JOB_QUESTIONS_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

export function postQuestions(contextId, questions) {
  return async dispatch => {
    dispatch(setPostQuestionsApiStatus('INPROGRESS'));
    try {
      await AryabotContextRepository.postQuestions(contextId, questions);
      dispatch(setPostQuestionsApiStatus('COMPLETED'));
      dispatch(getQuestions(contextId));
      return true;
    } catch (error) {
      dispatch(setPostQuestionsApiStatus('FAILED'));
      return false;
    }
  };
}

export function deleteQuestion(contextId, questionId) {
  return () => {
    AryabotContextRepository.deleteQuestion(contextId, questionId);
  };
}

export function getAnswers(contextId) {
  return dispatch => {
    AryabotContextRepository.getAnswers(contextId).then(response => {
      dispatch({
        type: 'SET_JOB_ANSWERS',
        payload: {
          contextId,
          answers: response.data,
        },
      });
    });
  };
}

export function postAnswers(contextId, answers) {
  return async dispatch => {
    dispatch(setPostAnswersApiStatus('INPROGRESS'));
    try {
      await AryabotContextRepository.postAnswers(contextId, answers);
      dispatch(setPostAnswersApiStatus('COMPLETED'));
      dispatch(getAnswers(contextId));
      return true;
    } catch (error) {
      dispatch(setPostAnswersApiStatus('FAILED'));
      return false;
    }
  };
}

export function putAnswers(contextId, answers) {
  return () => {
    AryabotContextRepository.putAnswers(contextId, answers);
  };
}
