import React from 'react';
import CandidatePersonalInfoWithShortlistReject from './CandidatePersonalInfoWithShortlistReject';
import CandidateScore from './CandidateScore/CandidateScore';
import styles from './CandidateCardInfo.module.scss';
import CandidateQualificationSummary from './CandidateCardQualificationsSummary/CandidateCardQualificationSummary';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';
import CandidateCardTertiaryContent from './CandidateCardTertiaryContent';

function CandidateCardInfo(props) {
  const {
    candidate,
    userConfig,
    status,
    whiteLabelInfo,
    onCandidateCardClick,
    maskingConfig,
    candidateContext,
    featureToggleList,
    connectStatusLoaded,
    showCandidateDetail,
    setComposeEmailType,
    indexKey,
    currentJobDetails,
    handleContactPull,
    showConnectWidget,

    isCandidateViewIconVisible,
    candidateType,
    version,
  } = React.useContext(CandidateCardContext);
  const { showExpandedCandidateCard } = props;
  const showCandidateScore = userConfig.IsCandidateScoreVisible;
  const isScoreVisible = candidateContext === 'job' && showCandidateScore && candidate.Score;

  const candidateScore = isScoreVisible ? (
    <CandidateScore
      candidate={candidate}
      status={status}
      whiteLabelInfo={whiteLabelInfo}
      onCandidateCardClick={onCandidateCardClick}
      featureToggleList={featureToggleList}
      candidateContext={candidateContext}
      candidateType={candidateType}
      userConfig={userConfig}
    />
  ) : null;

  const candidateCardTertiaryContent = (
    <CandidateCardTertiaryContent
      onCandidateCardClick={onCandidateCardClick}
      candidate={candidate}
      maskingConfig={maskingConfig}
      featureToggleList={featureToggleList}
      connectStatusLoaded={connectStatusLoaded}
      showCandidateDetail={showCandidateDetail}
      setComposeEmailType={setComposeEmailType}
      indexKey={indexKey}
      currentJobDetails={currentJobDetails}
      handleContactPull={handleContactPull}
      showConnectWidget={showConnectWidget}
      candidateContext={candidateContext}
      candidateType={candidateType}
      version={version}
      isCandidateViewIconVisible={isCandidateViewIconVisible}
    />
  );
  const candidateExperiences = candidate.Experiences;
  const candidateEducation = candidate.Educations;
  const candidateSkills = candidate.Skills;

  const showCandidateQualificationSummary =
    (candidateEducation?.length || candidateExperiences?.length || candidateSkills?.length) &&
    showExpandedCandidateCard;

  return (
    <div className={styles.candidateCardInfo}>
      <div>
        <CandidatePersonalInfoWithShortlistReject
          candidateCardTertiaryContent={candidateCardTertiaryContent}
          showCandidateQualificationSummary={showCandidateQualificationSummary}
          isScoreVisible={isScoreVisible}
        />
      </div>
      {showCandidateQualificationSummary ? (
        <div>
          <CandidateQualificationSummary />
          {!isScoreVisible ? candidateCardTertiaryContent : null}
        </div>
      ) : null}
      {isScoreVisible && showExpandedCandidateCard ? (
        <div className={styles.candidateScoreWithWidgets}>
          {candidateScore}
          {candidateCardTertiaryContent}
        </div>
      ) : null}
    </div>
  );
}

export default CandidateCardInfo;
