import _ from 'lodash';
import { Icon } from 'antd';
import React from 'react';
import { getSourceDisplayName } from './SourceUtils';
import './CandidateApiErrorResponseMapper.scss';
import store from '../store';
import { getCurrentUser } from '../Reducers/UserSessionReducer';

function mapErrorCodes(error) {
  const source = _.get(error, ['response', 'data', 'Error', 'Info']) || {};
  const state = store.getState();
  const userConfig = state?.ConfigReducer?.UserConfig ?? {};
  const displayName = getSourceDisplayName(source,userConfig);
  let showErrorMessageForGroup = false;
  const isGroup = Boolean(source.Group);
  let errorData = {
    Message: 'Oops, something just went wrong',
    ErrorDescription: 'Please try again later or contact support@leoforce.com if this issue persists',
  };
  const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
  const statusCode = _.get(error, ['response', 'status']);
  if (isGroup && statusCode) {
    if (appCode.includes('ARYA_CREDIT_EXHAUSTED')) {
      showErrorMessageForGroup = true;
    }
  }

  if (statusCode && (!isGroup || showErrorMessageForGroup)) {
    const portal = source.Portal;
    switch (statusCode) {
      case 503:
        if (appCode.includes('GATEWAY_ERROR') || appCode.includes('SOURCE_UNAVAILABLE_AT_THE_MOMENT'))
          errorData = {
            Message: `We are experiencing a problem with ${displayName} at the moment`,
            ErrorDescription: 'Please try again later or contact support@leoforce.com if this issue persists',
          };
        break;
      case 404:
        if (appCode.includes('CANDIDATE_NOT_FOUND'))
          errorData = {
            Message: 'Profile information is not available',
            ErrorDescription: 'This may be because the candidate has removed their profile from the portal',
          };
        else if (appCode.includes(`CREDENTIALS_NOT_FOUND`))
          errorData = {
            ...errorData,
            Message: `${portal} is Disconnected!`,
            ErrorDescription: `Your ${displayName} account is currently signed out from Arya due to either expired or invalid credentials. To see candidates from this source, please sign back with your valid credentials in Application Settings.`,
            showMessageAsWarning: true,
            showSettingsLink: true,
          };
        else if (appCode.includes('SOURCE_CANDIDATE_RESUME_UPDATED'))
          errorData = {
            ...errorData,
            Message: 'Profile information is not available',
            ErrorDescription: 'This may be because the candidate has updated their profile in portal',
          };
        else if (appCode.includes('SOURCE_CANDIDATE_RESUME_NOT_AVAILABLE'))
          errorData = {
            ...errorData,
            Message: 'Resume information is not available',
            ErrorDescription: 'Job board is unable to provide the required information',
          };
        break;
      case 403:
        if (appCode.includes('SOURCE_FORBIDDEN'))
          errorData = {
            Message: "You don't have access to this profile information",
            ErrorDescription: 'This may be because the candidate profile is private',
          };
        else if (appCode.includes('SOURCE_REQUEST_FAILED'))
          errorData = {
            ...errorData,
            Message: `We are experiencing a problem with ${displayName} at the moment`,
          };
        else if (appCode.includes(`CREDENTIALS_NOT_SUPPORTED`))
          errorData = {
            ...errorData,
            Message: `${portal} is Disconnected!`,
            ErrorDescription: `Your ${displayName} account is currently signed out from Arya due to either expired or invalid credentials. To see candidates from this source, please sign back with your valid credentials in Application Settings.`,
            showMessageAsWarning: true,
            showSettingsLink: true,
          };
        break;
      case 401:
        if (appCode.includes('INVALID_SOURCE_CREDENTIALS'))
          errorData = {
            Message: `${displayName} is disconnected!`,
            ErrorDescription: `Your ${displayName} account is currently signed out from Arya due to either expired or invalid credentials. To see candidates from this source, please sign back with your valid credentials in Application Settings`,
            showMessageAsWarning: true,
            showSettingsLink: true,
          };
        break;
      case 402:
        if (appCode.includes('SOURCE_CREDIT_EXHAUSTED'))
          errorData = {
            ...errorData,
            Message: `${displayName} has insufficient views remaining`,
            ErrorDescription: 'Please contact your admin or support@leoforce.com if this issue persists',
          };
        else if (appCode.includes('ARYA_CREDIT_EXHAUSTED')) {
          const currentStore = store.getState();
          const currentUser = getCurrentUser(currentStore);
          errorData = {
            ...errorData,
            Message: `Hey ${currentUser.given_name}, your credits are very low`,
            ErrorDescription:
              'It looks like you do not have sufficient credits remaining in your account. You no longer can perform actions which require these credits. Please send an email to support@leoforce.com for further help',
            showMessageAsWarning: true,
          };
        }
        break;
      case 406:
        if (appCode.includes('SOURCE_NOT_CONFIGURED'))
          errorData = {
            Message: 'Oops, something just went wrong',
            ErrorDescription: `You are unable to view this candidate because ${displayName} is not enabled for you in Arya at present. To enable contact support@leoforce.com`,
            showMessageAsWarning: true,
          };
        break;
      case 429:
        if (appCode.includes('SOURCE_LIMIT_EXCEEDED'))
          errorData = {
            Message: 'Unable to get profile information',
            ErrorDescription:
              'This portal has a limitation of 60 api calls/min. You have exceeded the limit. Please wait and try again later or contact support@leoforce.com if this issue persists.',
            showMessageAsWarning: true,
          };
        break;
      case 500:
      default:
        break;
    }
  } else if (!statusCode) {
    // eslint-disable-next-line no-console
    console.error(error);
    errorData = null;
  }
  return errorData;
}

function getCommunicationTabsErrorMessage(status) {
  const { candidateStatus, candidateName, style } = status;
  if (candidateStatus) {
    return (
      <div className="consent-status-info-message consent-denied candidate-communication-disabled-banner" style={style}>
        <Icon type="info-circle" theme="filled" />
        <span>{`With "${candidateStatus}" status you won't be able to communicate with ${candidateName}`}</span>
      </div>
    );
  }

  return (
    <div className="consent-status-info-message consent-denied candidate-communication-disabled-banner" style={style}>
      <Icon type="info-circle" theme="filled" />
      <span>{`Communication to ${candidateName} is disabled!`}</span>
    </div>
  );
}

export { getCommunicationTabsErrorMessage, mapErrorCodes };
