import React from 'react';
import _ from 'lodash';

/**
 *
 * @callback tagsClassName
 * @param {boolean} isTagSelected Indicated whether the tag is selected or not
 * @returns {string} classname applied to the tag
 */

/**
 *
 * @callback getSkEventName
 * @param {boolean} isTagSelected Indicated whether the tag is selected or not
 * @returns {string} sk-event-name applied to the tag
 */

/**
 *
 * @param {Object} props
 * @param {Object[]} props.tags Array of tags
 * @param {string|number} props.tags.id Unique id of each tag
 * @param {Boolean} props.tags.isSelected Indicated whether the tag is selcted or not
 * @param {string} props.tags.name Tag name
 * @param {tagsClassName} props.tagsClassName - Function to apply classname based on tag selection status
 * @param {getSkEventName} props.getSkEventName - Function to apply sk-event-name based on tag selection status
 * @returns
 */

const CustomCheckableTags = props => {
  const { tags, onChange, className, tagsClassName, getSkEventName } = props;

  const handleTagClick = (id, isSelected) => {
    const index = tags.findIndex(x => x.id === id);
    const clonedValue = _.cloneDeep(tags);
    clonedValue[index].isSelected = isSelected;
    onChange(clonedValue);
  };

  return (
    <div className={className}>
      {tags.map(tag => (
        <div
          key={tag.id}
          role="button"
          tabIndex={0}
          className={tagsClassName ? tagsClassName(tag.isSelected) : null}
          onClick={() => handleTagClick(tag.id, !tag.isSelected)}
          onKeyPress={() => handleTagClick(tag.id, !tag.isSelected)}
          sk-event-name={getSkEventName ? getSkEventName(tag.isSelected) : null}
        >
          {tag.name}
        </div>
      ))}
    </div>
  );
};

export default CustomCheckableTags;
