import React from 'react';
import classNames from 'classnames';
import { Table, Modal, message, Menu, Dropdown, Icon } from 'antd';
import styles from './EmailConfigurationList.module.scss';
import { getProviderDisplayName } from '../../../Utils/SmtpUtils';
import InfoIconWithTooltip from '../../Common/InfoIconWithTooltip/InfoIconWithTooltip';

const Manual = 'Manual';
const OAuth2 = 'OAuth2';
const Verified = 'Verified';
const Pending = 'Pending';
const Failed = 'Failed';
const TestedSuccessfully = 'Tested Successfully';
const TestingFailed = 'Testing Failed';

export default function EmailConfigurationList(props) {
  const {
    currentUser,
    userEmails,
    orgDefaultEmail,
    addUserEmail,
    testSmtpEmail,
    isAdmin,
    emailProviders,
    deleteUserEmail,
    onEditConfiguration,
    deleteEmailConfig,
  } = props;

  const getCurrentUserName = () => {
    return `${currentUser.given_name} ${currentUser.family_name ? currentUser.family_name : ''}`;
  };

  const getUserEmailsMergedWithProviders = () => {
    return userEmails.map(userEmail => {
      let providerConfiguration = emailProviders.find(emailProvider => {
        const providerIndex = (emailProvider.Emails ?? []).findIndex(
          email => email.Email.EmailId?.toLowerCase() === userEmail.EmailId?.toLowerCase()
        );
        return providerIndex !== -1;
      });
      if (!providerConfiguration)
        providerConfiguration = emailProviders.find(
          emailProvider => emailProvider.ProviderName?.toLowerCase() === Manual.toLowerCase()
        );
      const { SenderName: senderName } = userEmail;
      const emailConfiguration = {
        ...userEmail,
        SenderName: senderName || getCurrentUserName(),
        ProviderConfiguration: providerConfiguration,
      };
      const linkedEmail = providerConfiguration?.Emails?.find(x => x.Email.EmailId === userEmail.EmailId);
      if (linkedEmail)
        emailConfiguration.TestingStatus = linkedEmail.IsTestEmailSuccessfull ? TestedSuccessfully : TestingFailed;
      return emailConfiguration;
    });
  };

  const onClickEditIcon = email => {
    onEditConfiguration(email);
  };

  const onTestEmail = email => {
    if (email.ProviderConfiguration?.Id)
      testSmtpEmail({
        emailId: email.EmailId,
        providerId: email.ProviderConfiguration.Id,
        isAdmin: email.ProviderConfiguration.AuthScheme === Manual,
        showTestEmailNotification: true,
      });
    else message.error(`${email.EmailId} is not linked to any SMTP configuration. Please link and try again!`);
  };

  const onDeleteConfirmButtonClick = email => {
    deleteUserEmail(email.Id);
    if (email.ProviderConfiguration?.AuthScheme === OAuth2)
      deleteEmailConfig(email.ProviderConfiguration.Id, { isAdmin });
  };

  const onDeleteButtonClick = email => {
    const modalOptions = {
      title: 'Are you sure you want to delete the email address?',
      content: `You will not be able to send emails to candidates using ${email.EmailId} if you choose to delete it now.`,
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      okText: 'Delete',
      onOk: () => onDeleteConfirmButtonClick(email),
    };
    Modal.confirm(modalOptions);
  };

  const onVerifyEmail = email => {
    addUserEmail(email, true);
  };

  const getActionsColumn = email => {
    const { VerificationStatus: verificationStatus } = email;
    const actionsMenu = (
      <Menu>
        <Menu.Item onClick={() => onClickEditIcon(email)}>Edit</Menu.Item>
        {verificationStatus !== 'Verified' ? (
          <Menu.Item onClick={() => onVerifyEmail(email.EmailId)}>Verify</Menu.Item>
        ) : null}
        {orgDefaultEmail.EmailId !== email.EmailId && verificationStatus === 'Verified' ? (
          <Menu.Item onClick={() => onTestEmail(email)}>Test</Menu.Item>
        ) : null}
        {orgDefaultEmail.EmailId !== email.EmailId && currentUser.email !== email.EmailId ? (
          <Menu.Item onClick={() => onDeleteButtonClick(email)}>
            <span className={styles.deleteButton}>Delete</span>
          </Menu.Item>
        ) : null}
      </Menu>
    );
    return (
      <Dropdown overlay={actionsMenu}>
        <Icon type="ellipsis" style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} />
      </Dropdown>
    );
  };

  const getClassnameForStatus = email =>
    classNames(
      styles.status,
      { [styles.success]: email.VerificationStatus === Verified || email.TestingStatus === TestedSuccessfully },
      { [styles.failed]: email.VerificationStatus === Failed || email.TestingStatus === TestingFailed },
      { [styles.pending]: email.VerificationStatus === Pending }
    );

  const userEmailsMergedWithProviders = getUserEmailsMergedWithProviders();
  const dataSource = userEmailsMergedWithProviders.map(email => {
    const providerDisplayName =
      email.EmailId === orgDefaultEmail.EmailId ? 'Default' : getProviderDisplayName(email.ProviderConfiguration);
    return {
      key: email.EmailId,
      senderName: email.SenderName,
      email: email.EmailId,
      provider: providerDisplayName ?? 'None',
      status: (
        <div style={{ display: 'flex', gap: '5px' }}>
          <div className={getClassnameForStatus(email)} />
          {email.VerificationStatus === Pending ? (
            <span className={styles.pendingStatus}>
              <span>Verification Pending</span>
              <InfoIconWithTooltip
                tooltipTitle={<div>Verification is pending. Please check your email to verify.</div>}
              />
            </span>
          ) : (
            email.TestingStatus ?? email.VerificationStatus
          )}
        </div>
      ),
      action: getActionsColumn(email),
    };
  });

  const columns = [
    {
      title: <span className={styles.smtpTableColumnTitle}>NAME</span>,
      dataIndex: 'senderName',
      key: 'senderName',
      className: styles.smtpTableColumn,
      width: 170,
      ellipsis: true,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}>FROM ADDRESS</span>,
      dataIndex: 'email',
      key: 'email',
      className: styles.smtpTableColumn,
      width: 250,
      ellipsis: true,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}>EMAIL PROVIDER</span>,
      dataIndex: 'provider',
      key: 'provider',
      className: styles.smtpTableColumn,
      width: 160,
      ellipsis: true,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}>STATUS</span>,
      dataIndex: 'status',
      key: 'status',
      className: styles.smtpTableColumn,
      width: 200,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}>ACTION</span>,
      dataIndex: 'action',
      key: 'action',
      className: styles.smtpTableColumn,
      align: 'center',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className={styles.emailTable}
      rowClassName={styles.smtpTableRow}
    />
  );
}

export { EmailConfigurationList as EmailConfigurationListWithoutStore };
