import React from 'react';
import { Select, Icon, Input, Button } from 'antd';
import ChatPane from '../ChatPane/ChatPane';
import './MessagePopup.scss';
import { formatTextMessage } from '../../../Utils/TextUtils';

const { TextArea } = Input;
const { Option } = Select;
const { MessageBubble } = ChatPane;

//! TODO: move to util? Or a component?
function getConsentStatusInfoBanner(messageConsentStatus, candidateName) {
  const nameParts = (candidateName || '').split(' ');
  const firstName = nameParts && nameParts.length > 0 ? nameParts[0] : '';
  if (!messageConsentStatus) {
    return (
      <div className="consent-status-info-message">
        <Icon type="info-circle" theme="filled" />
        <span>{`${firstName}'s consent must be received before messages can be sent.`}</span>
      </div>
    );
  }
  if (messageConsentStatus === 'Denied') {
    return (
      <div className="consent-status-info-message consent-denied">
        <Icon type="close-circle" theme="filled" />
        <span>{`We're sorry, but ${firstName} has declined consent. You may no longer communicate on this number via Arya.`}</span>
      </div>
    );
  }
  if (messageConsentStatus === 'Pending') {
    return (
      <div className="consent-status-info-message consent-pending">
        <Icon type="clock-circle" theme="filled" />
        <span>{`Consent is pending. Communication will be enabled when ${firstName} gives consent.`}</span>
      </div>
    );
  }
  return null;
}

export default class MessagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messageText: '' };

    this.onSend = this.onSend.bind(this);
  }

  onSend(e) {
    e.preventDefault();
    const { sendMessage, selectedPhone } = this.props;
    const { messageText } = this.state;
    sendMessage(messageText, selectedPhone);
    this.setState({ messageText: '' });
  }

  render() {
    const {
      className,
      onClose,
      conversationId,
      messages,
      personDetails,
      messageType,
      connectInfo,
      consentStatus,
      phones,
      onChangePhone,
      selectedPhone,
      headerSubText,
    } = this.props;
    const { messageText } = this.state;

    const isSendMessageAllowed = messageType === 'Chat' || consentStatus === 'Approved';
    return (
      <div className={`popup-container ${className}`}>
        <div className="popup-header">
          <div className="popup-name-close">
            <div className="popup-name-wrapper">
              <div className="popup-name">{personDetails.Name}</div>
              <div className="popup-job-details">{headerSubText}</div>
            </div>
            <div className="popup-close">
              <Icon type="close" onClick={() => onClose(conversationId)} />
            </div>
          </div>
          {messageType === 'SMS' ? (
            <div className="popup-phones">
              <Select
                dropdownClassName="popup-message-dropdown"
                defaultValue={selectedPhone}
                className="phone-select"
                value={selectedPhone}
                onChange={onChangePhone}
              >
                {phones.map(phone => (
                  <Option key={phone.Id} value={phone.Number}>
                    {phone.Number}
                  </Option>
                ))}
              </Select>
            </div>
          ) : null}
        </div>
        <div className="popup-body">
          <ChatPane className="popup-chat-pane">
            {messages.map(message =>
              message.Body ? (
                <MessageBubble
                  key={message.Id}
                  message={formatTextMessage(message.Body)}
                  align={message.IsByPerson ? 'left' : 'right'}
                  timestamp={message.CreatedTime}
                  status={message.Status}
                />
              ) : null
            )}
          </ChatPane>
        </div>
        <div className="popup-footer">
          {isSendMessageAllowed ? (
            <div className="popup-chat-textarea">
              <TextArea
                placeholder="Type a message"
                style={{ height: '50px', border: '0px', resize: 'none' }}
                autosize={{ maxRows: 3 }}
                value={messageText}
                onPressEnter={this.onSend}
                onChange={event => this.setState({ messageText: event.target.value })}
              />
              <Button type="primary" id="popup-chat-send-button" onClick={this.onSend}>
                <Icon type="arrow-right" />
              </Button>
            </div>
          ) : (
            <>{connectInfo.Contact ? getConsentStatusInfoBanner(consentStatus, personDetails.Name) : null}</>
          )}
        </div>
      </div>
    );
  }
}
