function validateSegmentTitle(rule, value, callback) {
  const maxCharactersAllowedTitle = 150;
  const title = value && value.trim();
  if (title && title.length > maxCharactersAllowedTitle) {
    callback(`Segment Title should be lesser than ${maxCharactersAllowedTitle} characters`);
  } else if (title) {
    callback();
  } else {
    callback('Please enter the Segment title');
  }
}

function validateSegmentDescription(rule, value, callback) {
  const maxCharactersAllowedDescription = 200;
  const textAreaContent = value && value.trim();
  if (textAreaContent && textAreaContent.length > maxCharactersAllowedDescription) {
    callback(`Segment description should be lesser than ${maxCharactersAllowedDescription} characters`);
  } else {
    callback();
  }
}

export { validateSegmentDescription, validateSegmentTitle };
