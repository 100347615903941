import { Button, Tooltip } from 'antd';
import React from 'react';
import styles from './WorkflowDetailsFooter.module.scss';

export default function WorkflowDetailsFooter(props) {
  const {
    onSaveWorkflow,
    loadingFlag,
    isSaveDisabled,
    onSaveDraftWorkflow,
    isSaveDraftDisabled,
    isCreate,
    saveButtonTooltipMessage,
    isDraft,
    isWorkflowLive,
  } = props;
  let saveButtonText = '';
  const workflowFooterText = 'job/segment';
  if (isCreate) saveButtonText = 'Create';
  else if (!isDraft && isWorkflowLive) saveButtonText = 'Save As';
  else saveButtonText = 'Save';
  const footerNote =
    !isDraft && isWorkflowLive ? (
      <p className={styles.workflowFooterNote}>
        Note: Since this workflow is ‘Live’ with {workflowFooterText}, you can only perform ‘Save As’
      </p>
    ) : (
      ''
    );
  return (
    <div className={styles.workflowDetailsFooter}>
      {footerNote}
      <Button shape="round" onClick={onSaveDraftWorkflow} loading={loadingFlag} disabled={isSaveDraftDisabled}>
        Save Draft
      </Button>
      <Tooltip title={saveButtonTooltipMessage}>
        <Button type="primary" shape="round" onClick={onSaveWorkflow} loading={loadingFlag} disabled={isSaveDisabled}>
          {saveButtonText}
        </Button>
      </Tooltip>
    </div>
  );
}
