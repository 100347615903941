import React from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import SelectDropDownContent from '../../Utils/SelectDropDownContent/SelectDropDownContent';

function SelectDropdownWithPagination(props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const {
    totalDropdownOptionsCount,
    fetchDropdownOptions,
    isLoading,
    placeholder,
    onSelect,
    className,
    mode,
    key,
    showArrow,
    allowClear,
    onChange,
    value,
    showSearch,
    currentDropdownOptionsCount,
    children,
    defaultActiveFirstOption,
    filterOption,
    optionFilterProp,
    isDisabled,
    dropdownClassName,
    inlineStyles,
  } = props;

  const fetchDropdownOptionsWithDelay = debounce(fetchDropdownOptions, 150);
  const handleDropdownScroll = e => {
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      currentDropdownOptionsCount < totalDropdownOptionsCount
    ) {
      fetchDropdownOptionsWithDelay({
        searchTerm,
        from: currentDropdownOptionsCount,
        size: 10,
      });
    }
  };

  const handleSearch = searchKey => {
    setSearchTerm(searchKey);
    fetchDropdownOptions({
      searchTerm: searchKey,
      from: 0,
      size: 10,
    });
  };
  const handleSearchWithDelay = debounce(handleSearch, 600);

  const dropdownRender = menuItems => {
    return <SelectDropDownContent menuItems={menuItems} isLoading={isLoading} text="loading..." />;
  };

  const onDropdownVisibleChange = visible => {
    if (visible === false) {
      handleSearchWithDelay('');
    }
  };

  return (
    <Select
      mode={mode}
      key={key}
      style={inlineStyles}
      placeholder={placeholder}
      className={className}
      onSelect={onSelect}
      onChange={onChange}
      showSearch={showSearch}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSearch={handleSearchWithDelay}
      onPopupScroll={handleDropdownScroll}
      maxTagTextLength={20}
      notFoundContent=" "
      dropdownRender={dropdownRender}
      value={value}
      showArrow={showArrow}
      allowClear={allowClear}
      filterOption={filterOption}
      optionFilterProp={optionFilterProp}
      getPopupContainer={node => node.parentNode}
      defaultActiveFirstOption={defaultActiveFirstOption}
      dropdownClassName={dropdownClassName}
      disabled={isDisabled}
    >
      {children}
    </Select>
  );
}

export default SelectDropdownWithPagination;

SelectDropdownWithPagination.defaultProps = {
  filterOption: false,
  optionFilterProp: 'value',
};
