import React from 'react';
import { Popover, Button } from 'antd';
import { UserBulbIcon, InfoIcon } from '../../Icons/AryaIcons';
import './CandidateRejectReasonsPopover.scss';
import { getCandidateRejectReasonWarningMessage } from '../../Utils/CandidateRejectReasonsUtils';

const CandidateRejectReasonsPopover = props => {
  const {
    content,
    children,
    title,
    showWarningMessage = false,
    onApplyRejectReason,
    onClearRejectReason,
    onResetRejectReason,
    rejectReasonKey,
    size,
    okButtonText,
    cancelButtonText,
  } = props;
  const [popoverVisibility, setPopoverVisibility] = React.useState(false);
  return (
    <Popover
      overlayClassName={`candidate-reject-reasons-popover-overlay ${size}`}
      overlayStyle={{ zIndex: 2223 }}
      visible={popoverVisibility}
      title={
        <div className="candidate-reject-reasons-popover-title-wrapper">
          <div className="candidate-reject-reasons-popover-title-intel-icon">
            <UserBulbIcon />
          </div>
          <div className="candidate-reject-reasons-popover-title-text">{title}</div>
        </div>
      }
      onVisibleChange={visibility => {
        if (!visibility) onResetRejectReason(rejectReasonKey);
        setPopoverVisibility(visibility);
      }}
      content={
        <div className="candidate-reject-reason-content-wrapper">
          {content}
          {showWarningMessage ? (
            <div className="reject-reason-warning-message">
              <div className="reject-reasons-popover-info-icon">
                <InfoIcon />
              </div>
              {getCandidateRejectReasonWarningMessage(rejectReasonKey)}
            </div>
          ) : null}
          <div className="candidate-reject-reasons-popover-footer">
            <div className="candidate-reject-reasons-footer-buttons">
              <Button
                size="small"
                onClick={() => {
                  onClearRejectReason(rejectReasonKey);
                  setPopoverVisibility(false);
                }}
              >
                {cancelButtonText}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  onApplyRejectReason(rejectReasonKey);
                  setPopoverVisibility(false);
                }}
              >
                {okButtonText}
              </Button>
            </div>
          </div>
        </div>
      }
      trigger="click"
      autoAdjustOverflow
      placement="bottom"
    >
      {children}
    </Popover>
  );
};

export default CandidateRejectReasonsPopover;
