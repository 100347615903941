import React from 'react';
import { Form, Select } from 'antd';
import LocationContext from '../LocationContext';
import styles from './LocationRegionSelectDropdown.module.scss';

const FormItem = Form.Item;
const { Option } = Select;

const LocationRegionSelectDropdown = props => {
  const { label, initialValue, isCountryWideSearchEnabledCountry } = props;
  const { form } = React.useContext(LocationContext);
  const locationContextOptions = ['Location', 'Region'];

  const locationContext = (
    <Select
      key="Context"
      optionFilterProp="children"
      defaultActiveFirstOption
      className={styles.locationContextSelect}
      dropdownClassName={styles.locationDropdown}
      allowClear={false}
      disabled={isCountryWideSearchEnabledCountry}
    >
      {locationContextOptions?.map(_locationContext => (
        <Option value={_locationContext} key={_locationContext}>
          {_locationContext}
        </Option>
      ))}
    </Select>
  );
  return (
    <div className="wrapper">
      <FormItem label={label} colon={false}>
        {form.getFieldDecorator('LocationContext', {
          initialValue,
          rules: [{ required: true, message: 'Please select either Location or Region' }],
        })(locationContext)}
      </FormItem>
    </div>
  );
};

export default LocationRegionSelectDropdown;
