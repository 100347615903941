import React, { useCallback, useEffect } from 'react';
import { Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import CandidateRejectReasonsPopover from '../../CandidateRejectReasonsPopover/CandidateRejectReasonsPopover';
import RejectReasonsContentMapper from '../../RejectReasonsContentMapper/RejectReasonsContentMapper';
import {
  v2RejectReasonKeysToDisplayNameMapper,
  rejectReasonKeysToTitleMapper,
  getIsCurrentRejectReasonSelected,
  getIsWarningMessageVisible,
  getIsRejectReasonValuesChanged,
  getMergedRejectReasonValues,
  getRejectReasonsInputValues,
  missingMandatorySkills,
} from '../../../Utils/CandidateRejectReasonsUtils';
import { getSuggestions } from '../../../Reducers/SuggestionsReducer';
import { getJobsById } from '../../../Reducers/JobReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { MenuIcon } from '../../../Icons/AryaIcons';
import { fetchSuggestions, clearSuggestions } from '../../../Actions/SuggestionsActions';
import '../../CandidateRejectCard/CandidateRejectCard.scss';

function CandidateRejectReasonsV2(props) {
  const {
    candidateStatusReasons,
    onApplyRejectReason,
    onClearRejectReason,
    onResetRejectReason,
    negativeValues,
    jobId,
    candidateId,
    size,
    form,
    candidateConnectionStatus,
  } = props;

  const dispatch = useDispatch();
  const suggestions = useSelector(state => getSuggestions(state, candidateId));
  useEffect(() => {
    return () => {
      dispatch(clearSuggestions(candidateId));
    };
  }, []);

  const suggestionsFetchApiStatus = useSelector(state => getApiStatus(state, 'suggestionsFetchApiStatus'));
  const jobsById = useSelector(state => getJobsById(state));
  const currentJobDetails = jobsById[jobId] || {};
  const { MinExperience, MaxExperience } = currentJobDetails;
  const formValues = form.getFieldsValue();

  const applyRejectReason = useCallback(
    rejectReasonKey => {
      const rejectReasonInputKey = `${rejectReasonKey}-Input`;
      const formRejectReasonInputValue = form.getFieldValue(rejectReasonInputKey);
      if (formRejectReasonInputValue?.trim()) {
        const existingRejectReasonValues = form.getFieldValue(rejectReasonKey);
        const rejectReasonInputValues = getRejectReasonsInputValues(rejectReasonKey, formRejectReasonInputValue);
        const mergedRejectReasonValues = getMergedRejectReasonValues(
          rejectReasonKey,
          existingRejectReasonValues,
          rejectReasonInputValues
        );
        const updatedFormValues = { [rejectReasonInputKey]: '', [rejectReasonKey]: mergedRejectReasonValues };
        form.setFieldsValue({ ...updatedFormValues });
        if (rejectReasonKey === missingMandatorySkills)
          dispatch(
            fetchSuggestions({
              jobId,
              candidateId,
              skills: rejectReasonInputValues,
              suggestionsType: rejectReasonKey,
            })
          );
      }
      onApplyRejectReason(rejectReasonKey);
    },
    [form]
  );

  return Object.keys(v2RejectReasonKeysToDisplayNameMapper).map(rejectReasonKey => {
    const isRejectReasonChanged = getIsRejectReasonValuesChanged(formValues, candidateStatusReasons, rejectReasonKey);
    if (rejectReasonKey === 'OPT_OUT' && (!candidateConnectionStatus || candidateConnectionStatus === 'NotConnected')) {
      return null;
    }
    return (
      <CandidateRejectReasonsPopover
        content={
          <RejectReasonsContentMapper
            {...props}
            suggestions={suggestions}
            rejectReasonKey={rejectReasonKey}
            suggestionsFetchApiStatus={suggestionsFetchApiStatus}
            experienceRange={{ MinExperience, MaxExperience }}
          />
        }
        showWarningMessage={getIsWarningMessageVisible(rejectReasonKey, negativeValues)}
        title={rejectReasonKeysToTitleMapper[rejectReasonKey]}
        key={rejectReasonKey}
        rejectReasonKey={rejectReasonKey}
        onApplyRejectReason={applyRejectReason}
        onClearRejectReason={onClearRejectReason}
        onResetRejectReason={onResetRejectReason}
        size={size}
        okButtonText={isRejectReasonChanged ? 'Apply' : 'Done'}
        cancelButtonText="Clear"
      >
        <Tag
          className={`reject-reason-tag ${
            getIsCurrentRejectReasonSelected(rejectReasonKey, candidateStatusReasons)
              ? 'selected-reject-reason'
              : 'unselected-reject-reason'
          }`}
          key={rejectReasonKey}
        >
          {rejectReasonKey === 'MORE_REASONS' ? <MenuIcon /> : null}
          {v2RejectReasonKeysToDisplayNameMapper[rejectReasonKey]}
        </Tag>
      </CandidateRejectReasonsPopover>
    );
  });
}

export default CandidateRejectReasonsV2;
