import React from 'react';
import { connect } from 'react-redux';
import { Select, Form } from 'antd';
import debounce from 'lodash/debounce';
import styles from './CandidateTransfer.module.scss';
import { removeCommaFromEnd } from '../../Utils/TextUtils';
import * as JobActions from '../../Actions/JobActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import EnhancedCandidateTransferModal from './EnhancedCandidateTransferModal';
import { validateCandidateTransfer } from '../../Utils/FormValidators';

const { Option } = Select;

const mapDispatchToProps = {
  fetchJobs: JobActions._fetchJobs,
  bulkCandidateTransfer: CandidateActions.bulkCandidateTransfer,
};
function CandidateTransfer(props) {
  const {
    candidateTransferIconClassName,
    onClickTransferIcon,
    isCandidateTransferModalVisible,
    fetchJobs,
    candidateIds,
    jobId,
    bulkCandidateTransfer,
    setCandidateTransferModalVisibility,
    form,
    tooltipTitle,
    invalidCandidateCallbackMessage,
    transferType,
    fillColor,
    version,
    isEnableCustomSelect,
    customSelectNumber,
    filterContextId,
    isAryaRecommendedSelectAllChecked,
  } = props;

  const [actionName, setActionName] = React.useState('Copy');
  const [destinationName, setDestinationName] = React.useState('Job');
  const [options, setOptions] = React.useState([]);
  const [candidateTranserApiStatus, setCandidateTranserApiStatus] = React.useState();
  const [fetchJobsApiStatus, setFetchJobsApiStatus] = React.useState();

  const selectPlaceholder = 'Enter a destination';

  const fetchJobsOrLists = async (searchTerm, _destinationName) => {
    const payload =
      _destinationName === 'Segment'
        ? {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            EntityType: 'Segment',
            isAssigned: true,
          }
        : {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            isAssigned: true,
          };
    const result = await fetchJobs(payload);
    const updateResult = (result?.Jobs ?? []).filter(job => job.JobId !== jobId);
    setOptions(
      (updateResult ?? []).map(job => ({
        value: job.JobId,
        text: `${job.JobTitle} (Id: ${version !== 'ats' || destinationName === 'Segment' ? job.JobId : job.JobCode}) ${
          destinationName === 'Job' ? '|' : ''
        } ${
          destinationName === 'Segment' || job.Location === undefined || job.Location === ''
            ? ''
            : removeCommaFromEnd(job.Location)
        } ${destinationName === 'Segment' || job.Location === undefined || job.Location === '' ? '' : ','} ${
          destinationName === 'Job' ? job.CountryCode : ''
        } `,
      }))
    );
  };

  const onSearch = debounce(async (value, _destinationName) => {
    if (value.length) {
      setFetchJobsApiStatus('INPROGRESS');
      await fetchJobsOrLists(value, _destinationName);
      setFetchJobsApiStatus('COMPLETED');
    }
  }, 600);
  const handleBulkTranfer = async _actionName => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setCandidateTranserApiStatus('INPROGRESS');
        const filter = { candidateIds, jobId, destinationJobId: values.destinationJob, actionName: _actionName };
        if (isAryaRecommendedSelectAllChecked) {
          filter.filterContextId = filterContextId;
          filter.candidateIds = undefined;
        } else if (isEnableCustomSelect && customSelectNumber) {
          filter.filterContextId = filterContextId;
          filter.ApplyCount = customSelectNumber;
          filter.candidateIds = undefined;
        }
        await bulkCandidateTransfer(filter, transferType);
        setCandidateTranserApiStatus('COMPLETED');
        setCandidateTransferModalVisibility(false);
        form.setFieldsValue({ destinationJob: undefined });
      }
    });
  };

  const handleOnClose = () => {
    form.setFieldsValue({ destinationJob: undefined });
    setCandidateTransferModalVisibility(false);
  };
  const dropDownStatus = () => {
    return fetchJobsApiStatus === 'INPROGRESS' ? 'Loading...' : `No ${destinationName} found`;
  };
  const notFoundContent = dropDownStatus();

  const selectComponentWithForm = (
    <Form.Item rules={[{ required: true, message: 'Please enter Job' }]}>
      {form?.getFieldDecorator('destinationJob', {
        rules: [
          {
            validator: (rule, value, callback) =>
              validateCandidateTransfer(
                value,
                callback,
                candidateIds,
                isEnableCustomSelect,
                customSelectNumber,
                invalidCandidateCallbackMessage
              ),
          },
        ],
      })(
        <Select
          allowClear
          notFoundContent={notFoundContent}
          showSearch
          filterOption={false}
          showArrow={false}
          className={styles.select}
          dropdownStyle={{ zIndex: 2200 }}
          placeholder={selectPlaceholder}
          onSearch={value => onSearch(value, destinationName)}
          disabled={!destinationName}
        >
          {options?.map(option => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );

  return (
    <EnhancedCandidateTransferModal
      selectComponentWithForm={selectComponentWithForm}
      tooltipTitle={tooltipTitle}
      onClickTransferIcon={onClickTransferIcon}
      candidateTransferIconClassName={candidateTransferIconClassName}
      isCandidateTransferModalVisible={isCandidateTransferModalVisible}
      setCandidateTransferModalVisibility={setCandidateTransferModalVisibility}
      handleOnClose={handleOnClose}
      handleBulkTranfer={handleBulkTranfer}
      actionName={actionName}
      candidateTranserApiStatus={candidateTranserApiStatus}
      setActionName={setActionName}
      setOptions={setOptions}
      form={form}
      setDestinationName={setDestinationName}
      destinationName={destinationName}
      transferType={transferType}
      fillColor={fillColor}
    />
  );
}

export default connect(null, mapDispatchToProps)(Form.create()(CandidateTransfer));
export { CandidateTransfer as CandidateTransferWithoutStore };
