import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import * as regionActions from '../../../../../Actions/RegionActions';
import { getRegions } from '../../../../../Reducers/RegionReducer';
import { getLocationSectionConfig } from '../../../../../Utils/MultiLocationUtils';
import MultiLocationWrapper from '../../../../../CompoundComponents/MultiLocationWrapper/MultiLocationWrapper';
import CountrySelect from '../../../../../CompoundComponents/MultiLocationWrapper/CountrySelect/CountrySelect';
import RegionSelect from '../../../../../CompoundComponents/MultiLocationWrapper/RegionSelect/RegionSelect';
import LocationSelect from '../../../../../CompoundComponents/MultiLocationWrapper/LocationSelect/LocationSelect';
import DeleteLocationButton from '../../../../../CompoundComponents/MultiLocationWrapper/DeleteLocationButton/DeleteLocationButton';
import AddLocationButton from '../../../../../CompoundComponents/MultiLocationWrapper/AddLocationButton/AddLocationButton';
import styles from './ManualSearchMultiLocation.module.scss';

const mandatoryMarkStyle = {
  display: 'inline-block',
  color: '#f5222d',
  fontSize: '14px',
  fontFamily: 'SimSun, sans-serif',
  lineHeight: '1',
};

const MandatoryMark = () => <span style={mandatoryMarkStyle}>*</span>;

function MultilocationItem(props) {
  const { isLastElement, isDisabled, regionColValue, countryColumnValue, locationColumnValue } = props;
  return (
    <Row className={styles.multiLocationItem} gutter="16">
      <MultiLocationWrapper {...props}>
        <Col span={countryColumnValue}>
          <CountrySelect isDisabled={isDisabled} />
        </Col>
        <Col span={regionColValue}>
          <RegionSelect />
        </Col>
        <Col span={locationColumnValue}>
          <LocationSelect placeholder="City, State or State" />
        </Col>
        <Col span={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
          <DeleteLocationButton />
          {isLastElement ? <AddLocationButton /> : null}
        </Col>
      </MultiLocationWrapper>
    </Row>
  );
}

const mapStateToProps = state => {
  return {
    regions: getRegions(state),
  };
};

const mapDispatchToProps = {
  fetchRegionsByCountryCode: regionActions.fetchRegionsByCountryCode,
};

function ManualSearchMultiLocation(props) {
  const {
    fetchRegionsByCountryCode,
    value,
    onChange,
    countryOptions,
    candidateContext,
    regionColValue,
    countryColumnValue,
    locationColumnValue,
  } = props;

  const countryCode = value?.[0]?.CountryCode;
  React.useEffect(() => {
    if (countryCode) fetchRegionsByCountryCode({ countryCode });
  }, [countryCode]);

  const { isRegionSelectionLimitReached, isLocationSelectionLimitReached, isCountryWideSearchEnabledCountry } =
    getLocationSectionConfig(value, countryOptions);
  const isDisabled = candidateContext === 'job';
  return (
    <>
      <Row className={styles.multiLocationLabelsWrapper}>
        <Col span={countryColumnValue}>
          <div className={styles.label}>
            <MandatoryMark /> Country
          </div>
        </Col>
        <Col span={regionColValue}>
          <div className={styles.label}>Region</div>
        </Col>
        <Col span={locationColumnValue}>
          <div className={styles.label}>Location</div>
        </Col>
      </Row>
      {value?.map((location, index) => (
        <MultilocationItem
          onChange={onChange}
          key={location.Id}
          currentKey={location.Id}
          locations={value}
          currentLocation={location}
          isLocationSelectionLimitReached={isLocationSelectionLimitReached}
          isRegionSelectionLimitReached={isRegionSelectionLimitReached}
          isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
          isLastElement={index === value.length - 1}
          countryOptions={countryOptions}
          isDisabled={isDisabled}
          regionColValue={regionColValue}
          countryColumnValue={countryColumnValue}
          locationColumnValue={locationColumnValue}
        />
      ))}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualSearchMultiLocation);
export { ManualSearchMultiLocation as ManualSearchMultiLocationWithoutStore };
