import React from 'react';
import { Icon } from 'antd';
import InputSearch from '../../Components/InputSearch/InputSearch';
import MustHavesContext from './MustHavesContext';

export default function MustHavesInput(props) {
  const { shouldAddClassname, disabledButtonClassname, enabledButtonClassname } = props;
  const { addMustHave, mustHaveInputValue, onMustHavesChange, mustHaveInputRef } = React.useContext(MustHavesContext);

  const mustHaveClassname = !mustHaveInputValue.trim() ? disabledButtonClassname : enabledButtonClassname;
  const additionalSkillInputClassname = shouldAddClassname ? mustHaveClassname : '';

  return (
    <InputSearch
      mustHaveInputRef={mustHaveInputRef}
      placeholder="+ Add Keywords"
      value={mustHaveInputValue}
      enterButton={<Icon type="plus" style={{ display: 'flex' }} />}
      onSearch={addMustHave}
      onChange={onMustHavesChange}
      id="must-haves-input-field"
      shouldAddClassname={shouldAddClassname}
      additionalSkillInputClassname={additionalSkillInputClassname}
    />
  );
}
