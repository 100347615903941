import React from 'react';
import { connect } from 'react-redux';
import ReportStatsTabComponent from '../../../Components/ReportPortal/ReportStatsTab/ReportStatsTabComponent';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';

const mapDispatchToProps = {
  clearFetchReportStatsApiStatus: ReportPortalActions.clearFetchReportStatsApiStatus,
  clearRecruitersForDropdownLists: ReportPortalActions.clearRecruitersForDropdownLists,
  clearClientsForDropdownLists: ReportPortalActions.clearClientsForDropdownLists,
  clearReportStats: ReportPortalActions.clearReportStats,
};

export function ReportStatsTabContainer(props) {
  const {
    clearFetchReportStatsApiStatus,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
    clearReportStats,
  } = props;
  return (
    <ReportStatsTabComponent
      clearFetchReportStatsApiStatus={clearFetchReportStatsApiStatus}
      clearRecruitersForDropdownLists={clearRecruitersForDropdownLists}
      clearClientsForDropdownLists={clearClientsForDropdownLists}
      clearReportStats={clearReportStats}
    />
  );
}

export default connect(null, mapDispatchToProps)(ReportStatsTabContainer);
