/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'dompurify';
import { getChatTextHistoryRelativeTime } from '../../../Utils/RelativeTimeCalculator';
import linkify from '../../../Utils/HyperLinkUtils';
import './ChatTextHistory.scss';

export default class ChatTextHistory extends React.Component {
  static MessageBubble = ({ message, align, timestamp, className, type, DeliveryStatus, readOnly, createdByName }) => {
    const isPending = DeliveryStatus === 'Pending';
    const finalMessage = readOnly
      ? `<div><b>${createdByName || ''}</b></div>${message}`
      : `<div><b>${createdByName || ''}</b></div>${linkify(message)}`;

    if (type === 'title')
      return (
        <div className="chat-pane-title-wrapper">
          <span className="chat-pane-title"> {message} </span>
        </div>
      );

    if (type === 'date')
      return (
        <div className="connect-message-date-wrapper">
          <div className="connect-message-date">{message}</div>
        </div>
      );

    if (align === 'right')
      return (
        <div className={`connect-message-bubble-wrapper ${align}`}>
          <div
            className={`connect-message-bubble  ${className} ${isPending ? 'pending' : ''}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(finalMessage, { ALLOWED_ATTR: ['target', 'href'] }),
            }}
          ></div>
          {DeliveryStatus === 'Pending' ? (
            <div className={`connect-message-bubble-time ${align}`}>
              <span style={{ color: '#1F2730', fontSize: '12px', fontStyle: 'italic' }}>Pending</span>{' '}
              <small>{getChatTextHistoryRelativeTime(timestamp)}</small>
            </div>
          ) : null}
          {DeliveryStatus === 'Success' ? (
            <div className={`connect-message-bubble-time ${align}`}>
              <span style={{ color: '#1F2730', fontSize: '12px', fontStyle: 'italic' }}>Delivered</span>{' '}
              <small>{getChatTextHistoryRelativeTime(timestamp)}</small>
            </div>
          ) : null}
          {DeliveryStatus === 'Failed' ? (
            <div className={`connect-message-bubble-time ${align} failed`}>
              <span style={{ color: ' #eb4334', fontSize: '12px' }}>Failed</span>{' '}
              <small>{getChatTextHistoryRelativeTime(timestamp)}</small>
            </div>
          ) : null}
          {!DeliveryStatus ? (
            <div className={`connect-message-bubble-time ${align} failed`}>
              <span style={{ color: '#1F2730', fontSize: '12px', fontStyle: 'italic' }}>Pending</span>
              <small>{getChatTextHistoryRelativeTime(timestamp)}</small>
            </div>
          ) : null}
        </div>
      );

    return (
      <div className={`connect-message-bubble-wrapper ${align}`}>
        <div
          className={`connect-message-bubble ${className} ${isPending ? 'pending' : ''}`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(finalMessage, { ALLOWED_ATTR: ['target', 'href'] }),
          }}
        ></div>
        <div className={`connect-message-bubble-time ${align}`}>{getChatTextHistoryRelativeTime(timestamp)}</div>
      </div>
    );
  };

  constructor(props) {
    super(props);
    this.messagesWrapper = React.createRef();
    this.scrollChatToBottom = this.scrollChatToBottom.bind(this);
  }

  componentDidUpdate() {
    this.scrollChatToBottom();
  }

  componentDidMount() {
    this.scrollChatToBottom();
  }

  scrollChatToBottom() {
    const messageContainer = document.querySelector('.inner-messages-wrapper');
    if (messageContainer.hasChildNodes()) {
      const lastMessage = messageContainer.lastElementChild;
      lastMessage.scrollIntoView();
    }
  }

  render() {
    const { children, className } = this.props;
    return (
      <div ref={this.messagesWrapper} className={`connect-message-window-flex-items messages-wrapper ${className}`}>
        <div className="inner-messages-wrapper">{children}</div>
      </div>
    );
  }
}
