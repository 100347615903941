import { getUsersById } from '../Reducers/UserReducer';
import * as jobActivityRepository from '../Repository/JobActivityRepository';
import { fetchJobActivityStatus, setJobActivity } from './ActionCreators/JobActivityActionCreator';
import { fetchUsers } from './UserActions';

const INPROGRESS = 'INPROGRESS';
const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';

function fetchJobActivity({ from, size, jobId }) {
  return async (dispatch, getState) => {
    try {
      const eventTypes = [
        'CandidateSearchCriteria.Created',
        'CandidateSearchCriteria.Updated',
        'CandidateSearchCriteria.Activated',
        'AryaSuggestedCandidateSearchCriteria.Created',
        'AryaSuggestedCandidateSearchCriteria.Updated',
      ];
      dispatch(fetchJobActivityStatus(INPROGRESS));
      const response = await jobActivityRepository.fetchJobActivity({ from, size, jobId, eventTypes });
      dispatch(
        setJobActivity({
          jobId,
          activity: response.data.Activity,
          totalCount: response.data.TotalCount,
        })
      );
      const userIds = response.data.Activity?.map(criteria => criteria.CreatedBy).filter(x => x);
      if (userIds?.length) {
        const existingUsersInStore = getUsersById(getState());
        const unAvailableUserIdsInStore = userIds.filter(id => !existingUsersInStore[id]);
        const uniqueUserIds = [...new Set(unAvailableUserIdsInStore)];
        if (uniqueUserIds.length)
          await dispatch(
            fetchUsers({
              userGuids: uniqueUserIds,
            })
          );
      }
      dispatch(fetchJobActivityStatus(COMPLETED));
    } catch {
      dispatch(fetchJobActivityStatus(FAILED));
    }
  };
}

export { fetchJobActivity };
