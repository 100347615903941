import React, { useState } from 'react';
import { Form, Select, DatePicker, Avatar, Tooltip } from 'antd';
import TimeRangeSelect, {
  getDateRange,
} from '../../../ReportStatsTab/ReportView/ReportViewFilter/TimeRangeSelect/TimeRangeSelect';
import { getLocalDateFormat } from '../../../../../Utils/RelativeTimeCalculator';
import { disabledDate } from '../../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import styles from './SummaryFilter.module.scss';
import { CalendarIcon } from '../../../../../Icons/AryaIcons';
import SelectDropdownWithPagination from '../../../../Common/SelectDropdownWithPagination/SelectDropdownWithPagination';
import { getCurrentUserOrg } from '../../../../../Utils/CurrentUserUtils';
import { getOrgsWithCurrentUserOrg } from '../../../../../Utils/ReportPortalUtils/OptionUtils';

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
export default function SummaryFilter(props) {
  const {
    form,
    onRecruiterSearch,
    recruiters,
    onRecruiterScroll,
    recruiterMaxCount,
    currentUserId,
    isAdmin,
    fetchRecruiterApiStatus,
    orgs,
    orgMaxCount,
    fetchOrgs,
    canUserImpersonate,
    currentUserDetails,
  } = props;

  const [recruiterCount, setRecruiterCount] = useState(10);
  const recruiterLoadingStatus = fetchRecruiterApiStatus?.status?.toUpperCase() === 'INPROGRESS';
  const localDateFormat = getLocalDateFormat();
  const changeDateRange = dateRange => {
    form.setFieldsValue({
      dateRange,
    });
  };

  const handleOnSearch = value => {
    onRecruiterSearch(value, 0, 10);
  };

  const handleRecruiterScroll = e => {
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 20 &&
      recruiterCount <= recruiterMaxCount
    ) {
      onRecruiterScroll(recruiterCount, 10);
      setRecruiterCount(recruiterCount + 10);
    }
  };

  const fetchOrgDropdownOptions = filter => {
    const { searchTerm, from = 0, size = 10 } = filter;
    const pageToFetch = from / size + 1;
    fetchOrgs({ page: pageToFetch, searchTerm });
  };

  const currentUserOrg = getCurrentUserOrg(currentUserDetails);
  const orgsWithCurrentUserOrg = getOrgsWithCurrentUserOrg(orgs?.Organizations, currentUserOrg);

  const selectedOrgId = form?.getFieldValue('orgId');

  const onOrgChange = () => {
    form.setFieldsValue({ recruiterId: 'ALL' });
  };

  const getMyReportOption = () => {
    const myReportOption = (
      <Option key="My Report" label="My Report" value={currentUserId}>
        <Avatar size="small">MR</Avatar> My Report
      </Option>
    );
    if (!canUserImpersonate) {
      return myReportOption;
    }
    if (canUserImpersonate && selectedOrgId === currentUserDetails?.OrgId) {
      return myReportOption;
    }
    return null;
  };

  return (
    <Form form={form}>
      <div className={styles.summaryFilterFormItems}>
        {canUserImpersonate ? (
          <Form.Item>
            {form.getFieldDecorator(`orgId`, {
              initialValue: currentUserDetails?.OrgId,
              rules: [{ required: true, message: 'Please select organization' }],
            })(
              <SelectDropdownWithPagination
                placeholder="Select organization"
                showSearch
                className={styles.reportUserSelect}
                fetchDropdownOptions={fetchOrgDropdownOptions}
                totalDropdownOptionsCount={orgMaxCount}
                currentDropdownOptionsCount={orgs?.Organizations?.length}
                onChange={onOrgChange}
                defaultActiveFirstOption={false}
                filterOption
                optionFilterProp="label"
              >
                {orgsWithCurrentUserOrg?.map(org => (
                  <Option key={org.Id} label={org.Name} value={org.Id}>
                    <Tooltip title={org.Id}>
                      {org.Name} ({org.Id})
                    </Tooltip>
                  </Option>
                ))}
              </SelectDropdownWithPagination>
            )}
          </Form.Item>
        ) : null}
        <Form.Item>
          <div className="select-recruiters">
            {form.getFieldDecorator(`recruiterId`, {
              initialValue: currentUserId,
            })(
              <Select
                placeholder="Select User"
                showSearch
                filterOption
                loading={recruiterLoadingStatus}
                notFoundContent={recruiterLoadingStatus ? 'Loading' : null}
                onSearch={handleOnSearch}
                optionFilterProp="label"
                optionLabelProp="label"
                className={styles.reportUserSelect}
                onPopupScroll={handleRecruiterScroll}
                disabled={!isAdmin}
              >
                {getMyReportOption()}
                <Option key="All Reports" label="All Reports" value="ALL">
                  <Avatar size="small">AR</Avatar> All Reports
                </Option>

                <OptGroup key="Shared Reports" label="Shared Reports">
                  {recruiters
                    ?.filter(recruiter => recruiter?.Id !== currentUserId)
                    ?.map(recruiter => (
                      <Option key={recruiter.Id} label={recruiter.FullName} value={recruiter.Id}>
                        <Tooltip title={recruiter?.Email}>
                          <Avatar size="small">{recruiter.FullName.substring(0, 2).toUpperCase()}</Avatar>{' '}
                          {recruiter.FullName}
                        </Tooltip>
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            )}
          </div>
        </Form.Item>
        <TimeRangeSelect
          fieldDecoratorValue="dateRangeKey"
          form={form}
          className={styles.formTimeRangeSelect}
          changeDateRange={changeDateRange}
          initialValue="month"
        />
        <Form.Item>
          <div className="time-range-date-picker">
            {form.getFieldDecorator(`dateRange`, {
              initialValue: getDateRange('month'),
              rules: [{ required: true, message: 'Please select the date range' }],
            })(
              <RangePicker
                allowClear={false}
                disabled={form.getFieldValue('dateRangeKey')?.toLowerCase() !== 'custom'}
                disabledDate={disabledDate}
                className={styles.dateRangePicker}
                format={localDateFormat}
                suffixIcon={<CalendarIcon />}
              />
            )}
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}
