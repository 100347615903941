import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import * as jobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as manualSearchCandidateActions from '../../Actions/ManualSearchCandidateActions';
import * as candidateActions from '../../Actions/ActionCreators/CandidateActions';
import CandidateListPagination from '../../Components/CandidateListPagination/CandidateListPagination';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getLatestCandidateSearchSuccessPage,
  getCandidateCount,
  getCandidateListType,
  getSupportedPageSizes,
  getSampleCandidateCount,
  getAppliedBucketScoringStatus,
} from '../../Reducers/CandidateReducer';
import {
  getActiveSourceName,
  getCurrentPage,
  getFilterQueries,
  getManualSearchActiveSource,
  getPageSize,
  getActiveTab,
} from '../../Reducers/JobCandidatesTabReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getManualSearchPayload } from '../../Reducers/ManualSearchReducer';
import {
  getAllTabFetchedCandidatesCount,
  getCandidatesBySource,
  getAllTabTotalCandidatesCount,
  getFetchedCandidatesCountBySource,
} from '../../Reducers/ManualSearchCandidateReducer';
import JobCandidatesTabMapper from './JobCandidatesTabMapper';
import { getSourceName } from '../../Utils/SourceUtils';

const mapStateToProps = (state, props) => {
  const activeSourceName = getActiveSourceName(state);
  return {
    candidateListStatus: getApiStatus(state, 'candidateListStatus'),
    allTabCandidateFetchApiStatus: getApiStatus(state, 'allTabCandidatesFetchApi'),
    currPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    totalCandidate:
      activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent'
        ? getCandidateCount(state)
        : getFetchedCandidatesCountBySource(state, { jobId: props.jobId, source: activeSourceName }),
    activeSourceName,
    sampleCandidateCount: getSampleCandidateCount(state),
    manualSearchActiveSource: getManualSearchActiveSource(state),
    manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
    allTabFetchedCandidatesCount: getAllTabFetchedCandidatesCount(state, { jobId: props.jobId }),
    candidatesBySource: getCandidatesBySource(state, { jobId: props.jobId }),
    filterQueries: getFilterQueries(state, activeSourceName),
    activeTab: getActiveTab(state),
    previousSuccessfulCandidateFetchedPage: getLatestCandidateSearchSuccessPage(state, {
      jobId: props.jobId,
      source: activeSourceName,
    }),
    featureToggleList: getFeatureToggleList(state),
    allTabTotalCandidatesCount: getAllTabTotalCandidatesCount(state, { jobId: props.jobId }),
    candidateListType: getCandidateListType(state),
    supportedPageSizes: getSupportedPageSizes(state, props.candidateContext),
    appliedBucketScoringStatus: getAppliedBucketScoringStatus(state, { jobId: props.jobId }),
  };
};

const mapDispatchToProps = {
  setJobCandidateTabPagination: jobCandidatesTabActions.setJobCandidateTabPagination,
  setManualSearchActiveSource: jobCandidatesTabActions.setManualSearchActiveSource,
  setCandidateListApiStatus: candidateActions.setCandidateListApiStatus,
  fetchNextBatchCandidates: manualSearchCandidateActions.fetchNextBatchCandidates,
};

const scrollToCandidatesTab = offsetTop =>
  window.scrollTo({
    top: offsetTop,
    left: 0,
    behavior: 'smooth',
  });

function JobCandidateListTabs(props) {
  const {
    candidateListStatus,
    currPage,
    pageSize,
    totalCandidate,
    currentPipelineFilter,
    jobId,
    candidateContext = 'job',
    currentJobDetails,
    status,
    showIntelBanners,
    atsJobCandidateListProps,
    connectionStatus,
    version,
    idealCandidateIntelPopupVisibility,
    defaultFilter,
    fetchAryaRecommendedCandidates,
    fetchManualSearchCandidates,
    setJobCandidateTabPagination,
    sourcedCount,
    onStatusChange,
    scrollToRefineIntel,
    onCloseIdealCandidateIntelPopup,
    activeSourceName,
    manualSearchPayload = {},
    manualSearchActiveSource,
    history,
    publishedStatus,
    jobGuid,
    planName,
    allTabFetchedCandidatesCount,
    fetchNextBatchCandidates,
    allTabCandidateFetchApiStatus,
    candidatesBySource,
    filterQueries,
    previousSuccessfulCandidateFetchedPage,
    featureToggleList,
    allTabTotalCandidatesCount,
    isNonInternalPortalForSegmentEnabled,
    openSipCallWindowsApp,
    candidateListType,
    supportedPageSizes,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isAryaRankedTab,
    smartRecruiterVersion,
    sampleCandidateCount,
    scoutingAgentCandidateCount,
    appliedBucketScoringStatus,
  } = props;

  const candidateListTabsRef = React.useRef();
  const [from, setFrom] = React.useState(0);

  const isSegmentCandidateSearchLimitFeatureEnabled = featureToggleList.SegmentCandidateSearchLimit.IsEnabled;
  const segmentCandidateSearchMaxLimit = 50000;

  const onPageChangeSegment = (page, size, callback) => {
    const updatedPage = totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
    const updatedFrom = (updatedPage - 1) * size;
    setFrom(updatedFrom);
    scrollToCandidatesTab(candidateListTabsRef.current?.offsetTop);
    setJobCandidateTabPagination({
      currPage: page,
      pageSize: size,
    });
  };

  const onPageChange = useCallback(
    async (page, size, callback) => {
      const updatedPage = totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
      const from = (updatedPage - 1) * size;
      const updatedPagination = { From: from, Size: size };
      scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
      setJobCandidateTabPagination({
        currPage: page,
        pageSize: size,
      });
      if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') {
        const candidatesFetchPayload = { ...defaultFilter, ...updatedPagination };
        await fetchAryaRecommendedCandidates({
          filter: candidatesFetchPayload,
          isDefaultFetch: true,
          showLoader: !callback,
        });
        if (callback) {
          callback();
        }
      } else {
        const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
        const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
        const activeSource =
          manualSearchActiveSource ||
          manualSearchPayload.Sources.find(source => getSourceName(source) === activeSourceName);
        const updatedSources =
          candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
            ? manualSearchPayload.Sources.filter(x => x.Portal !== 'All')
            : [activeSource];
        const newManualSearchPayload = {
          ...manualSearchPayload,
          PortalQueries: { [activeSourceName]: updatedPortalQueries },
          Sources: updatedSources,
          ...updatedPagination,
          page: updatedPage,
        };
        fetchManualSearchCandidates({
          manualSearchPayload: newManualSearchPayload,
          setPrevCandidateCallback: callback,
        });
        if (activeSourceName === 'All' && allTabCandidateFetchApiStatus !== 'INPROGRESS') {
          const upperIndex = from + size;
          if (allTabFetchedCandidatesCount - upperIndex < 30) {
            const sources = manualSearchPayload.Sources.filter(x => x.Portal !== 'All');
            const nonExhaustedSources = sources.filter(source => {
              const sourceName = getSourceName(source);
              const totalCount = candidatesBySource[sourceName]?.TotalCount;
              const fetchedCount = candidatesBySource[sourceName]?.FetchedCount;
              return fetchedCount < totalCount;
            });
            if (
              nonExhaustedSources.length &&
              (candidateContext !== 'segment' || isNonInternalPortalForSegmentEnabled)
            ) {
              await fetchNextBatchCandidates({
                manualCriteria: { ...manualSearchPayload, Sources: nonExhaustedSources },
                jobId,
                candidateContext,
                from,
                size,
                allowSetFetchCandidateApiStatus: false,
              });

              scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
            }
          }
        }
      }
    },
    [
      totalCandidate,
      previousSuccessfulCandidateFetchedPage,
      activeSourceName,
      defaultFilter,
      manualSearchPayload,
      filterQueries,
      manualSearchActiveSource,
      allTabCandidateFetchApiStatus,
      allTabFetchedCandidatesCount,
      candidateContext,
      candidatesBySource,
      isNonInternalPortalForSegmentEnabled,
      fetchNextBatchCandidates,
      fetchManualSearchCandidates,
      fetchAryaRecommendedCandidates,
      setJobCandidateTabPagination,
    ]
  );

  const getUpdatedPage = ({ page }) => {
    if (activeSourceName === 'All') return 1;
    return totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
  };

  const onShowSizeChangeCallBack = useCallback(
    ({ page, size }) => {
      const updatedPage = getUpdatedPage({ page });
      const from = (updatedPage - 1) * size;
      const updatedPagination = { From: from, Size: size };
      if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') {
        const candidatesFetchPayload = { ...defaultFilter, ...updatedPagination };
        fetchAryaRecommendedCandidates({ filter: candidatesFetchPayload });
      } else {
        const activeSource =
          manualSearchActiveSource ||
          manualSearchPayload.Sources.find(source => getSourceName(source) === activeSourceName);
        const updatedSources =
          candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
            ? manualSearchPayload.Sources.filter(x => x.Portal !== 'All')
            : [activeSource];
        const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
        const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
        const newManualSearchPayload = {
          ...manualSearchPayload,
          PortalQueries: { [activeSourceName]: updatedPortalQueries },
          Sources: updatedSources,
          ...updatedPagination,
          page: updatedPage,
        };
        fetchManualSearchCandidates({ manualSearchPayload: newManualSearchPayload });
      }
      setJobCandidateTabPagination({
        currPage: updatedPage,
        pageSize: size,
      });
      scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
    },
    [
      totalCandidate,
      previousSuccessfulCandidateFetchedPage,
      activeSourceName,
      defaultFilter,
      manualSearchActiveSource,
      manualSearchPayload,
      filterQueries,
      isNonInternalPortalForSegmentEnabled,
      fetchManualSearchCandidates,
      fetchAryaRecommendedCandidates,
      setJobCandidateTabPagination,
    ]
  );

  const getTotalCandidatesCount = () => {
    if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') return totalCandidate;
    if (isSegmentCandidateSearchLimitFeatureEnabled && candidateContext === 'segment')
      return Math.min(totalCandidate, segmentCandidateSearchMaxLimit);
    if (candidateContext === 'segment') return Math.min(totalCandidate, 1000);
    if (activeSourceName === 'All') return Math.min(allTabFetchedCandidatesCount, 1000);
    return Math.min(totalCandidate, 1000);
  };

  const getTotalCandidatesDisplayCount = () => {
    if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') return totalCandidate;
    if (isSegmentCandidateSearchLimitFeatureEnabled && candidateContext === 'segment')
      return Math.min(totalCandidate, segmentCandidateSearchMaxLimit);
    if (activeSourceName === 'All') return Math.min(allTabTotalCandidatesCount, 1000);
    return Math.min(totalCandidate, 1000);
  };

  const totalCandidatesCount = getTotalCandidatesCount();

  const totalCandidatesDisplayCount = getTotalCandidatesDisplayCount();

  const getManualSearchPayloadSource = () => {
    return manualSearchPayload?.Sources?.find(source => getSourceName(source) === activeSourceName);
  };

  const generateManualSearchPayload = () => {
    const from = (currPage - 1) * pageSize;
    const updatedPagination = { From: from, Size: pageSize };
    const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
    const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
    return { updatedPagination, updatedPortalQueries };
  };

  const fetchManualSearchCandidateList = () => {
    const sources = getManualSearchPayloadSource();
    const { updatedPagination, updatedPortalQueries } = generateManualSearchPayload();
    const newManualSearchPayload = {
      ...manualSearchPayload,
      PortalQueries: { [activeSourceName]: updatedPortalQueries },
      Sources: [sources],
      ...updatedPagination,
    };
    const isAllTabCandidateFetchRequestAllowed = activeSourceName === 'All';
    fetchManualSearchCandidates({ manualSearchPayload: newManualSearchPayload, isAllTabCandidateFetchRequestAllowed });
  };

  const isAllTabCandidateFetchAllowed = () => {
    return (
      (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled) &&
      activeSourceName === 'All' &&
      allTabCandidateFetchApiStatus === 'COMPLETED'
    );
  };

  const isAryaRecommendedOrAllTabActive =
    activeSourceName === 'AryaRecommended' || activeSourceName === 'All' || activeSourceName === 'scoutingAgent';

  React.useEffect(() => {
    if (!isAryaRecommendedOrAllTabActive && (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled)) {
      fetchManualSearchCandidateList();
    }
  }, [activeSourceName, totalCandidatesCount]);

  React.useEffect(() => {
    if (isAllTabCandidateFetchAllowed()) {
      fetchManualSearchCandidateList();
    }
  }, [allTabCandidateFetchApiStatus, totalCandidatesCount]);

  const paginationId =
    candidateContext === 'segment' && candidateListType === 'SourcedCandidateList' ? 'segment' : 'job';
  const shouldRemovePagination = candidateContext === 'segment' && (status === 'Shortlisted' || status === 'Rejected');

  return (
    <div ref={candidateListTabsRef}>
      <JobCandidatesTabMapper
        onPageChange={shouldRemovePagination ? onPageChangeSegment : onPageChange}
        currentPipelineFilter={currentPipelineFilter}
        jobId={jobId}
        candidateContext={candidateContext}
        sourcedCount={sourcedCount}
        scoutingAgentCandidateCount={scoutingAgentCandidateCount}
        currentJobDetails={currentJobDetails}
        status={status}
        showIntelBanners={showIntelBanners}
        atsJobCandidateListProps={atsJobCandidateListProps}
        connectionStatus={connectionStatus}
        version={version}
        defaultFilter={defaultFilter}
        fetchAryaRecommendedCandidates={fetchAryaRecommendedCandidates}
        fetchManualSearchCandidates={fetchManualSearchCandidates}
        manualSearchActiveSource={manualSearchActiveSource}
        onStatusChange={onStatusChange}
        scrollToRefineIntel={scrollToRefineIntel}
        onCloseIdealCandidateIntelPopup={onCloseIdealCandidateIntelPopup}
        history={history}
        publishedStatus={publishedStatus}
        jobGuid={jobGuid}
        planName={planName}
        totalCandidate={totalCandidate}
        filterQueries={filterQueries}
        isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        openSipCallWindowsApp={openSipCallWindowsApp}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        isAryaRankedTab={isAryaRankedTab}
        smartRecruiterVersion={smartRecruiterVersion}
        from={from}
        setFrom={setFrom}
        sampleCandidateCount={sampleCandidateCount}
        appliedBucketScoringStatus={appliedBucketScoringStatus}
      />
      {candidateContext === 'job' || totalCandidatesCount ? (
        <CandidateListPagination
          totalCandidate={shouldRemovePagination ? sampleCandidateCount : totalCandidatesCount || 0}
          totalCandidatesDisplayCount={totalCandidatesDisplayCount}
          onChange={shouldRemovePagination ? onPageChangeSegment : onPageChange}
          onShowSizeChangeCallBack={onShowSizeChangeCallBack}
          disabled={candidateListStatus === 'INPROGRESS'}
          pageSize={pageSize}
          status={status}
          currentPage={currPage}
          inlineStyles={idealCandidateIntelPopupVisibility ? { paddingBottom: '120px' } : null}
          onlyPrevNext={
            activeSourceName === 'All' && (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled)
          }
          shouldRemovePagination={shouldRemovePagination}
          candidateContext={candidateContext}
          previousSuccessfulCandidateFetchedPage={previousSuccessfulCandidateFetchedPage}
          pageSizeOptions={supportedPageSizes.map(x => x.toString())}
          paginationId={paginationId}
        />
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCandidateListTabs);
export { JobCandidateListTabs as JobCandidateListTabsWithoutStore };
