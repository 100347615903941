import moment from 'moment';
import _ from 'lodash';
import { timeDay, timeWeek, timeMonth, timeYear } from 'd3';
import { fetchMinMaxInList } from '../ListUtils';
import { getDateRange } from '../../Components/ReportPortal/ReportStatsTab/ReportView/ReportViewFilter/TimeRangeSelect/TimeRangeSelect';

export function getJobListForDropdown(jobs) {
  return jobs?.map(val => {
    const value = _.get(val, ['JobId'], 0);
    const label = `${_.get(val, ['JobTitle'], '')} (${value})`;

    return { label, value };
  });
}

export function getRecruiterId(filter, currentUserId) {
  const payloadFilter = _.cloneDeep(filter);

  switch (payloadFilter?.recruiterId) {
    case 'MY':
      payloadFilter.recruiterId = currentUserId;
      break;

    case 'ALL':
      payloadFilter.recruiterId = null;
      break;

    default:
      payloadFilter.recruiterId = payloadFilter.recruiterId ?? currentUserId;
  }

  return payloadFilter;
}

export function getDateDiff(filter) {
  const fromDate = filter?.dateRange?.[0] ?? moment();
  const toDate = filter?.dateRange?.[1] ?? moment();
  const diff = toDate.diff(fromDate, 'days');
  let axisTimeFormat;
  let tickCount;

  if (diff <= 7) {
    axisTimeFormat = '%a %Y/%b/%d';
    tickCount = timeDay.every(1);
  } else if (diff > 7 && diff <= 14) {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeDay.every(1);
  } else if (diff > 14 && diff <= 90) {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeWeek.every(1);
  } else if (diff > 90 && diff < 180) {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeWeek.every(2);
  } else if (diff >= 180 && diff <= 366) {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeMonth.every(1);
  } else if (diff > 366 && diff <= 365 * 2) {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeMonth.every(2);
  } else if (diff > 365 * 2 && diff <= 365 * 5) {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeMonth.every(6);
  } else {
    axisTimeFormat = '%Y/%b/%d';
    tickCount = timeYear.every(1);
  }
  return { axisTimeFormat, tickCount };
}

export function disabledDate(current) {
  return current && (current > moment().endOf('day') || moment().diff(current, 'year') >= 1);
}

export function allSelectionFilter(values) {
  let allSelectionFilterValue = values;
  if (values?.find(value => typeof value === 'string' && value?.toUpperCase() === 'ALL')) {
    allSelectionFilterValue = null;
  }

  return allSelectionFilterValue;
}
export function getDate(filter) {
  const fromDate = moment
    .utc(_.get(filter, ['dateRange', 0], moment()))
    .local()
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');
  const toDate = moment
    .utc(_.get(filter, ['dateRange', 1], moment()))
    .local()
    .endOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');
  return { fromDate, toDate };
}

export function refineReportFilter(filter) {
  let clients = filter?.clients;
  if (clients?.find(client => typeof client === 'string' && client?.toUpperCase() === 'ALL')) {
    clients = null;
  }
  let recruiters = filter?.recruiters;
  if (recruiters?.find(recruiter => typeof recruiter === 'string' && recruiter?.toUpperCase() === 'ALL')) {
    recruiters = null;
  }
  const { fromDate, toDate } = getDate(filter);
  return { clients, recruiters, fromDate, toDate };
}

export function refineJobIds(filter) {
  const jobIds = filter?.jobIds;
  const selectedJobIds = jobIds?.filter(jobId => jobId);
  return selectedJobIds?.length ? selectedJobIds : null;
}

export function savedQueryMapper(savedQuery = {}, dynamicReportColumns = []) {
  const {
    FromDate,
    ToDate,
    RecruiterIds,
    ClientIds,
    JobIds,
    OrgId,
    DateRangeType,
    Columns = [],
    DateFormat,
  } = savedQuery;

  const dateRangeType = DateRangeType?.toString()?.toLowerCase() || 'custom';
  return {
    recruiters: RecruiterIds || ['ALL'],
    clients: ClientIds || ['ALL'],
    dateRangeKey: dateRangeType,
    dateRange: dateRangeType !== 'custom' ? getDateRange(dateRangeType) : [moment(FromDate), moment(ToDate)],
    jobIds: JobIds,
    orgId: OrgId,
    columns: Columns?.concat(dynamicReportColumns),
    dateFormat: DateFormat,
  };
}

export function getReportType(type) {
  switch (type?.toLowerCase()) {
    case 'recruiters':
      return 'Recruiter';
    case 'candidates':
      return 'Candidate';
    case 'jobs':
      return 'Job';
    default:
      return type;
  }
}

export function getReportViewType(type) {
  switch (type?.toLowerCase()) {
    case 'recruiter':
      return 'recruiters';
    case 'candidate':
      return 'candidates';
    case 'job':
      return 'jobs';
    default:
      return type;
  }
}

export function fetchReportPayload(fetchPayloadCriteria) {
  const { filter, page, pageSize, reportKey } = fetchPayloadCriteria;
  const { clients, recruiters, fromDate, toDate } = refineReportFilter(filter);
  const jobIds = reportKey?.toLowerCase() === 'candidates' ? refineJobIds(filter) : null;
  return {
    FromDate: fromDate,
    ToDate: toDate,
    RecruiterIds: recruiters?.filter(recruiter => recruiter),
    ClientIds: clients?.filter(client => client),
    From: page >= 1 ? (page - 1) * pageSize : 0,
    Size: pageSize,
    JobIds: jobIds,
    OrgId: filter?.orgId,
    Columns: filter?.columns,
    DateFormat: filter?.dateFormat,
  };
}

export function getExportReportPayload(fetchPayloadCriteria) {
  const { filter, reportKey } = fetchPayloadCriteria;
  const { clients, recruiters, fromDate, toDate } = refineReportFilter(filter);
  const jobIds = reportKey?.toLowerCase() === 'candidates' ? refineJobIds(filter) : null;
  return {
    From: 0,
    Size: 0,
    FromDate: fromDate,
    ToDate: toDate,
    RecruiterIds: recruiters?.filter(recruiter => recruiter),
    ClientIds: clients?.filter(client => client),
    JobIds: jobIds,
    OrgId: filter?.orgId,
    Columns: filter?.columns,
    DateFormat: filter?.dateFormat,
  };
}

export function fetchReportStatsPayload(filter) {
  const { fromDate, toDate } = getDate(filter);
  return {
    FromDate: fromDate,
    ToDate: toDate,
    RecruiterId: filter?.recruiterId,
    OrgId: filter?.orgId,
  };
}

export function fetchFilteredJobsPayload(filters) {
  return {
    From: filters.from,
    Size: filters.size,
    FromDate: filters.fromDate,
    ToDate: filters.toDate,
    RecruiterIds: filters.recruiterIds,
    ClientIds: filters.clientIds,
    SearchKeyword: filters.searchKeyword,
    OrgId: filters.orgId,
  };
}

export function parseLineChartData(data, lineKey, lineValue, lineName, isDate) {
  const isVisible = true;

  const points = data.map(val => {
    const key = _.get(val, lineKey, '');
    const value = _.get(val, [lineValue], 0);
    const toUTC = moment.utc(key);
    const localDate = toUTC.local().startOf('day').toDate();
    const abscissa = moment(localDate).format('YYYY/MMM/DD');
    return isDate ? { key: localDate, value, abscissa } : { key, value, abscissa };
  });

  let pointsValues = [];
  points.forEach(element => {
    const newData = _.get(element, ['value']);
    pointsValues = [...pointsValues, newData];
  });

  const minMaxYScale = fetchMinMaxInList(pointsValues);

  return { lineName, isVisible, points, minMaxYScale };
}

export function cumulativeReportSum(data, field) {
  let totalSum = 0;
  data.forEach(val => {
    totalSum += _.get(val, [field], 0);
  });

  return totalSum;
}

export function customizeObject(keyName, valueName, keyData, valueData) {
  return { [keyName]: keyData, [valueName]: valueData };
}

export function insightCardInput(titleText, stats, count, performanceGrowth, infoText) {
  return { titleText, stats, count, performanceGrowth, infoText };
}

export function getJobStats(jobStats) {
  const jobsCreated = parseLineChartData(jobStats, 'AggrDate', 'JobSharedAssigned', 'Shared', true);
  const jobsActivated = parseLineChartData(jobStats, 'AggrDate', 'JobActivated', 'Activated', true);
  const jobsReactivated = parseLineChartData(jobStats, 'AggrDate', 'JobReactivated', 'Reactivated', true);

  return [jobsCreated, jobsActivated, jobsReactivated];
}

export function getCandidateStats(candidateStats) {
  const candidateSourced = parseLineChartData(candidateStats, 'AggrDate', 'CandidateSourced', 'Sourced', true);
  const candidateDeleted = parseLineChartData(candidateStats, 'AggrDate', 'CandidateSourcedDeleted', 'Deleted', true);
  const candidateViewed = parseLineChartData(candidateStats, 'AggrDate', 'CandidateViewed', 'Viewed', true);
  const candidateShortlisted = parseLineChartData(
    candidateStats,
    'AggrDate',
    'CandidateShortlisted',
    'Shortlisted',
    true
  );
  const candidateIgnored = parseLineChartData(candidateStats, 'AggrDate', 'CandidateRejected', 'Rejected', true);
  const candidatePushed = parseLineChartData(candidateStats, 'AggrDate', 'CandidatePushed', 'Pushed', true);

  return [candidateSourced, candidateDeleted, candidateViewed, candidateShortlisted, candidateIgnored, candidatePushed];
}

export function getEmailStats(reportStats) {
  const emailAppliedCount = cumulativeReportSum(reportStats, 'EmailApplied');
  const emailClickedCount = cumulativeReportSum(reportStats, 'EmailClicked');
  const emailSentCount = cumulativeReportSum(reportStats, 'EmailSent');
  const emailViewedCount = cumulativeReportSum(reportStats, 'EmailViewed');

  return [
    customizeObject('Name', 'Count', 'Sent', emailSentCount),
    customizeObject('Name', 'Count', 'Viewed', emailViewedCount),
    customizeObject('Name', 'Count', 'Clicked', emailClickedCount),
    customizeObject('Name', 'Count', 'Applied', emailAppliedCount),
  ];
}

export function getMsgStats(reportStats) {
  const messageSentCount = cumulativeReportSum(reportStats, 'MessageSent');
  const messageRepliedCount = cumulativeReportSum(reportStats, 'MessageReplied');

  const othersCount = messageSentCount - messageRepliedCount;

  return {
    total: messageSentCount,
    data: [
      customizeObject('Name', 'Value', 'Replies', messageRepliedCount),
      customizeObject('Name', 'Value', 'Others', othersCount),
    ],
  };
}

export function getCallStats(reportStats) {
  const totalCallCount = cumulativeReportSum(reportStats, 'TotalCall');
  const connectedCallCount = cumulativeReportSum(reportStats, 'ConnectedCall');
  const rejectedCallCount = cumulativeReportSum(reportStats, 'RejectedCall');

  const othersCount = totalCallCount - connectedCallCount - rejectedCallCount;

  return {
    total: totalCallCount,
    data: [
      customizeObject('Name', 'Value', 'Connected', connectedCallCount),
      customizeObject('Name', 'Value', 'Rejected', rejectedCallCount),
      customizeObject('Name', 'Value', 'Others', othersCount),
    ],
  };
}

export function getBotStats(reportStats) {
  const totalBotSessionCount = cumulativeReportSum(reportStats, 'TotBotSession');
  const completeBotSessionCount = cumulativeReportSum(reportStats, 'BotCompleteSession');
  const InCompleteBotSessionCount = cumulativeReportSum(reportStats, 'BotIncompleteSession');

  const othersCount = totalBotSessionCount - completeBotSessionCount - InCompleteBotSessionCount;

  return {
    total: totalBotSessionCount,
    data: [
      customizeObject('Name', 'Value', 'Complete', completeBotSessionCount),
      customizeObject('Name', 'Value', 'Incomplete', InCompleteBotSessionCount),
      customizeObject('Name', 'Value', 'Others', othersCount),
    ],
  };
}

export function parseReportStatsSummary(reportSummary) {
  const reportStats = _.get(reportSummary, ['ReportStats'], []);
  const jobStatsInfo = _.get(reportSummary, ['JobStats'], []);
  const candidateStatsInfo = _.get(reportSummary, ['CandidateStats'], []);
  const reportsStatsSummary = _.get(reportSummary, ['Summary'], []);

  const jobStats = getJobStats(jobStatsInfo);
  const candidateStats = getCandidateStats(candidateStatsInfo);
  const emailStats = getEmailStats(reportStats);
  const textStats = getMsgStats(reportStats);
  const callStats = getCallStats(reportStats);
  const botStats = getBotStats(reportStats);

  const candidateConnected = _.get(reportsStatsSummary, ['CandidateConnected'], 0);
  const candidateApplied = _.get(reportsStatsSummary, ['CandidateApplied'], 0);

  const candidateAppliedPercent = _.get(reportsStatsSummary, ['CandidateAppliedPercent'], 0);
  const candidateConnectedPercent = _.get(reportsStatsSummary, ['CandidateConnectedPercent'], 0);

  const candidateSourcedCount = _.get(reportsStatsSummary, ['CandidateSourced'], 0);
  const candidateSourcedPercent = _.get(reportsStatsSummary, ['CandidateSourcedPercent'], 0);

  const candidateShortlistedCount = _.get(reportsStatsSummary, ['CandidateShortlisted'], 0);
  const candidateShortlistedPercent = _.get(reportsStatsSummary, ['CandidateShortlistedPercent'], 0);

  const candidateRejectedCount = _.get(reportsStatsSummary, ['CandidateRejected'], 0);
  const candidateRejectedPercent = _.get(reportsStatsSummary, ['CandidateRejectedPercent'], 0);

  const jobActivated = _.get(reportsStatsSummary, ['JobActivated'], 0);
  const jobCreated = _.get(reportsStatsSummary, ['JobSharedAssigned'], 0);
  const jobReactivated = _.get(reportsStatsSummary, ['JobReactivated'], 0);

  const jobCreatedPercent = _.get(reportsStatsSummary, ['JobSharedAssignedPercent'], 0);
  const jobActivatedPercent = _.get(reportsStatsSummary, ['JobActivatedPercent'], 0);
  const jobReactivatedPercent = _.get(reportsStatsSummary, ['JobReactivatedPercent'], 0);
  // ! important
  const jobInsights = insightCardInput(
    'Shared | Activated | Reactivated',
    [
      { label: 'Shared', count: jobCreated, percent: jobCreatedPercent },
      { label: 'Activated', count: jobActivated, percent: jobActivatedPercent },
      { label: 'Reactivated', count: jobReactivated, percent: jobReactivatedPercent },
    ],
    [jobCreated, jobActivated, jobReactivated],
    [jobCreatedPercent, jobActivatedPercent, jobReactivatedPercent],
    'Job insights include Shared (no. of jobs created or assigned to you), Activated (no. of times Arya was first activated), Reactivated (no. of times Arya was reactivated) all for the chosen date range.'
  );

  const candidateSourcingInsights = insightCardInput(
    'Sourced | Shortlisted | Rejected',
    [
      { label: 'Sourced', count: candidateSourcedCount, percent: candidateSourcedPercent },
      { label: 'Shortlisted', count: candidateShortlistedCount, percent: candidateShortlistedPercent },
      { label: 'Rejected', count: candidateRejectedCount, percent: candidateRejectedPercent },
    ],
    [candidateSourcedCount, candidateShortlistedCount, candidateRejectedCount],
    [candidateSourcedPercent, candidateShortlistedPercent, candidateRejectedPercent],
    'Candidate insights include the no. of Sourced, Shortlisted and Rejected candidates for the chosen date range.'
  );

  const candidateInsights = insightCardInput(
    'Connected | Applied',
    [
      { label: 'Connected', count: candidateConnected, percent: candidateConnectedPercent },
      { label: 'Applied', count: candidateApplied, percent: candidateAppliedPercent },
    ],
    [candidateConnected, candidateApplied],
    [candidateConnectedPercent, candidateAppliedPercent],
    'Connect insights include Connected (no. of candidates users connected with through the Arya) and Applied (no. of candidates who applied to jobs, including applications received from external candidates) for the chosen date range.'
  );

  return {
    jobStats,
    candidateStats,
    emailStats,
    textStats,
    callStats,
    botStats,
    jobInsights,
    candidateSourcingInsights,
    candidateInsights,
  };
}

export function getDefaultReportColumnsByAryaName(reportColumns) {
  const reportColumnsByAryaName = {};
  (reportColumns ?? []).forEach(column => {
    const newColumn = { ...column, IsVisible: true };
    _.setWith(reportColumnsByAryaName, [column?.AryaName], newColumn, Object);
  });
  return reportColumnsByAryaName;
}
