import React from 'react';
import uuid from 'uuid';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { getLocationOptions, getOptionValueFromValue } from '../../Utils/LocationUtils';
import { getLocationSectionTooltipContent } from '../../Utils/MultiLocationUtils';
import CountryChangeModal from '../../Containers/MultiLocationContainer/CountryChangeModal';
import MultiLocationContext from './MultiLocationContext';
import * as locationSearchActions from '../../Actions/LocationSearchActions';
import { getRegions } from '../../Reducers/RegionReducer';

const getLocationSectionDisabledStatus = ({
  isCountryWideSearchEnabledCountry,
  isPrimaryLocation,
  isRegionSelectionLimitReached,
  region,
  location,
  isAdvanceSearch = false,
}) => {
  const isRegionDisabled =
    (isPrimaryLocation && !isAdvanceSearch) || (isRegionSelectionLimitReached && !region) || !!location;
  const isLocationDisabled = !!region || isCountryWideSearchEnabledCountry;
  const isPrimaryLocationRadioDisabled = !!region;
  return { isRegionDisabled, isLocationDisabled, isPrimaryLocationRadioDisabled };
};

const mapStateToProps = state => {
  return {
    regions: getRegions(state),
  };
};

const mapDispatchToProps = {
  fetchLocations: locationSearchActions._fetchCityAndStateSuggestions,
};

function MultiLocationWrapper(props) {
  const {
    children,
    fetchLocations,
    currentLocation,
    currentKey,
    regions,
    isLocationSelectionLimitReached,
    isRegionSelectionLimitReached,
    onChange,
    locations,
    countryOptions,
    isCountryWideSearchEnabledCountry,
    layers,
    isAdvanceSearch,
  } = props;
  const [locationSuggestions, setLocationSuggestions] = React.useState([]);
  const [isCountryChangeModalVisible, setIsCountryChangeModalVisible] = React.useState(false);
  const [updatedCountryCode, setUpdatedCountryCode] = React.useState('');
  const [locationApiStatus, setLocationApiStatus] = React.useState([]);
  const [locationInput, setLocationInput] = React.useState('');
  const [milesInputValidationError, setMilesInputValidationError] = React.useState(undefined);

  const addLocation = () => {
    onChange([...locations, { Id: uuid.v4(), CountryCode: locations[0].CountryCode }]);
  };

  const isObjectNotInArray = (array, incomingObject) => {
    return !array.some(element =>
      Object.keys(incomingObject).every(key => {
        if (key === 'Region' || key === 'Location' || key === 'State' || key === 'CountryCode') {
          return element[key] === incomingObject[key];
        }
        return true;
      })
    );
  };

  const addLocationEnhanced = () => {
    const id = uuid.v4();
    const toBeAddedLocationIndex = locations?.length - 1;
    const toBeAddedLocation = locations[toBeAddedLocationIndex];
    const currentPrimaryLocationIndex = locations.findIndex(
      location => location?.IsPrimaryLocation && location?.isActive
    );
    const isVisible = !(toBeAddedLocation?.State || toBeAddedLocation?.Region || toBeAddedLocation?.Location);
    const updatedLocations = locations.map((item, index) => {
      if (index === toBeAddedLocationIndex) {
        return {
          ...item,
          isVisible,
          isActive: true,
          IsPrimaryLocation: currentPrimaryLocationIndex === -1 && !toBeAddedLocation?.Region,
        };
      }
      return item;
    });
    if (isObjectNotInArray(updatedLocations.slice(0, -1) ?? [], toBeAddedLocation)) {
      onChange([...updatedLocations, { Id: id, CountryCode: locations[0].CountryCode }]);
    } else {
      const removedDuplicateLocations = locations.map((item, index) => {
        if (index === updatedLocations?.length - 1) {
          return { Id: id, CountryCode: locations[0].CountryCode };
        }
        return item;
      });
      onChange([...removedDuplicateLocations]);
    }
  };

  const getPrimaryLocationId = value => {
    return value.IsPrimaryLocation;
  };

  const isStateWideSearchChecked = id => {
    const location = locations.filter(location => location.Id === id);
    return location[0]?.isStateWideSearchChecked || location[0]?.State;
  };

  const changeLocation = newLocation => {
    const index = locations.findIndex(location => location.Id === currentKey);
    const clonedLocations = _.cloneDeep(locations);
    clonedLocations[index] = newLocation;
    onChange(clonedLocations);
  };

  const changePrimaryLocation = id => {
    const clonedLocations = _.cloneDeep(locations);
    const currentPrimaryLocationIndex = clonedLocations.findIndex(location => location.IsPrimaryLocation);
    const newPrimaryLocationIndex = clonedLocations.findIndex(location => location.Id === id);
    if (currentPrimaryLocationIndex !== -1) clonedLocations[currentPrimaryLocationIndex].IsPrimaryLocation = false;
    if (newPrimaryLocationIndex !== -1) clonedLocations[newPrimaryLocationIndex].IsPrimaryLocation = true;
    onChange(clonedLocations);
  };

  const deleteLocation = () => {
    const updatedLocations = locations.filter(location => location.Id !== currentKey);
    onChange(updatedLocations);
  };

  const deleteMultiLocation = Id => {
    let latestUpdatedLocation;
    const id = uuid.v4();
    const isToBeDeletedLocationPrimary = locations.filter(location => location.Id === Id)?.[0]?.IsPrimaryLocation;
    const updatedLocations = locations.filter(location => location.Id !== Id);
    const anyOtherActiveLocation = locations.filter(location => location.Id !== Id && location.isActive);
    if (updatedLocations?.length === 0) {
      onChange([
        ...updatedLocations,
        { Id: id, CountryCode: locations[0].CountryCode, isPrimaryLocation: true, isActive: true },
      ]);
    } else {
      latestUpdatedLocation = updatedLocations;
      if (isToBeDeletedLocationPrimary) {
        latestUpdatedLocation = updatedLocations.map((item, index) => {
          if (item?.isActive && !item?.Region) {
            return { ...item, IsPrimaryLocation: true };
          }
          if (index === updatedLocations?.length - 1 && anyOtherActiveLocation?.length === 0 && !item?.Region) {
            return { ...item, IsPrimaryLocation: true };
          }
          return item;
        });
      }
      onChange([...latestUpdatedLocation]);
    }
  };

  const isFirstLocation = locations?.length === 1;

  const existingLocations = locations?.map(x => x.Location)?.filter(x => x);

  const {
    Region,
    State,
    Location,
    CountryCode = 'US',
    IsPrimaryLocation: isPrimaryLocation,
    Distance,
  } = currentLocation;

  const onChangeCountry = value => {
    let updatedLocation = {};
    if (!value) {
      onChange(updatedLocation);
    } else if (locations.length === 1 && !currentLocation.Location) {
      updatedLocation = {
        ...currentLocation,
        CountryCode: value,
        Region: undefined,
        State: undefined,
      };
      onChange([updatedLocation]);
    } else {
      setUpdatedCountryCode(value);
      setIsCountryChangeModalVisible(true);
    }
  };

  const onChangeRegion = regionId => {
    let updatedLocation;
    if (!regionId) {
      updatedLocation = { ...currentLocation, Region: undefined };
    } else {
      const currentRegion = regions.find(region => region.RegionId === regionId);
      updatedLocation = { ...currentLocation, Region: currentRegion };
    }
    changeLocation(updatedLocation);
  };

  const onChangeLocation = value => {
    let updatedLocation;
    let isStateWideSearchChecked;
    if (!value) updatedLocation = { ...currentLocation, Location: value, State: value, isStateWideSearchChecked };
    else {
      const parsedValue = JSON.parse(value);
      if (parsedValue?.state && !parsedValue?.city) {
        const _value = getOptionValueFromValue(parsedValue);
        isStateWideSearchChecked = true;
        updatedLocation = { ...currentLocation, Location: _value, isStateWideSearchChecked, State: parsedValue?.state };
      } else if (parsedValue?.city) {
        const _value = getOptionValueFromValue(parsedValue);
        isStateWideSearchChecked = false;
        delete currentLocation?.State;
        updatedLocation = { ...currentLocation, Location: _value, isStateWideSearchChecked };
      }
    }
    changeLocation(updatedLocation);
  };

  const onMilesChange = value => {
    const updatedLocation = { ...currentLocation, Distance: !value && value !== 0 ? undefined : value };
    changeLocation(updatedLocation);
  };

  const onEditMultiLocation = Id => {
    const editLocation = locations.filter(location => location.Id === Id);
    const isToBeEditedLocationPrimary = locations.filter(location => location.Id === Id)?.[0]?.IsPrimaryLocation;
    let updatedLocations = locations.filter(location => location.Id !== Id && location.isActive);
    if (isToBeEditedLocationPrimary) {
      updatedLocations = updatedLocations.map((item, index) => {
        if (item?.isActive && !item?.Region) {
          return { ...item, IsPrimaryLocation: true };
        }
        return item;
      });
    }
    const updatedEditLocation = editLocation.map(item => ({
      ...item,
      isActive: false,
    }));
    updatedLocations.push(...updatedEditLocation);
    onChange(updatedLocations);
  };

  const debouncedSearchLocation = debounce(async (country, searchTerm) => {
    setLocationApiStatus('INPROGRESS');
    setLocationInput(searchTerm);
    const _locationSuggestions = await fetchLocations({ country, searchTerm, layers });
    setLocationSuggestions(_locationSuggestions);
    setLocationApiStatus('COMPLETED');
  }, 600);

  const onSearchLocation = searchTerm => {
    if (searchTerm) debouncedSearchLocation(CountryCode, searchTerm);
  };
  const locationOptions = getLocationOptions(locationSuggestions, true);
  const filteredLocations = _.difference(locationOptions, existingLocations);

  const { isRegionDisabled, isLocationDisabled, isPrimaryLocationRadioDisabled } = getLocationSectionDisabledStatus({
    isPrimaryLocation,
    isRegionSelectionLimitReached,
    region: Region,
    location: Location ?? State,
    isCountryWideSearchEnabledCountry,
    isAdvanceSearch,
  });

  const { Region: regionTooltipContent, Location: locationTooltipContent } = getLocationSectionTooltipContent({
    isPrimaryLocation,
    isRegionSelectionLimitReached,
    isRegionSelected: !!Region,
    isLocationSelected: !!Location,
    isCountryWideSearchEnabledCountry,
  });

  let locationDropDownStatus;
  if (locationInput.length) {
    locationDropDownStatus = locationApiStatus === 'INPROGRESS' ? 'Loading...' : 'No location found';
  } else {
    locationDropDownStatus = 'Type to search';
  }

  return (
    <>
      <MultiLocationContext.Provider
        value={{
          CountryCode,
          countryOptions,
          Region,
          isCountryWideSearchEnabledCountry,
          isRegionDisabled,
          regions,
          locations,
          Location,
          State,
          Distance,
          isLocationDisabled,
          locationApiStatus,
          locationDropDownStatus,
          filteredLocations,
          currentKey,
          isPrimaryLocation,
          isPrimaryLocationRadioDisabled,
          isLocationSelectionLimitReached,
          locationTooltipContent,
          regionTooltipContent,
          isFirstLocation,
          isAdvanceSearch,
          milesInputValidationError,
          onChangeCountry,
          onChangeRegion,
          onChangeLocation,
          onSearchLocation,
          changePrimaryLocation,
          addLocation,
          deleteLocation,
          getPrimaryLocationId,
          addLocationEnhanced,
          onMilesChange,
          onEditMultiLocation,
          deleteMultiLocation,
          isStateWideSearchChecked,
          setMilesInputValidationError,
        }}
      >
        {children}
      </MultiLocationContext.Provider>
      <CountryChangeModal
        setIsCountryChangeModalVisible={setIsCountryChangeModalVisible}
        isCountryChangeModalVisible={isCountryChangeModalVisible}
        onChange={onChange}
        currentLocation={currentLocation}
        updatedCountryCode={updatedCountryCode}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiLocationWrapper);
export { MultiLocationWrapper as MultiLocationWrapperWithoutStore };
