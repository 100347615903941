import React from 'react';
import ConnectContainer from './Connect';
import WorkflowDetailsContainer from '../WorkflowDetailsContainer/WorkflowDetailsContainer';

export function ConnectUrlMapper(props) {
  const { setUrl, type, tab, location, workflowId, createWorkflowId, atsProps } = props;
  const { version } = atsProps;

  if (tab === 'workflows' && (type || workflowId)) {
    if (!type) {
      return <WorkflowDetailsContainer location={location} setUrl={setUrl} workflowId={workflowId} version={version} />;
    }
    if (type === 'preview') {
      return (
        <WorkflowDetailsContainer
          location={location}
          setUrl={setUrl}
          type="preview"
          workflowId={workflowId}
          version={version}
        />
      );
    }
    return (
      <WorkflowDetailsContainer
        location={location}
        setUrl={setUrl}
        type="create"
        workflowId={createWorkflowId}
        version={version}
      />
    );
  }
  return tab ? (
    <ConnectContainer location={location} setUrl={setUrl} defaultActiveTab={tab} {...{ ...atsProps }} />
  ) : (
    <ConnectContainer location={location} setUrl={setUrl} {...{ ...atsProps }} />
  );
}

export default ConnectUrlMapper;
