import _ from 'lodash';

const { SIPml } = window;

class AryaSIPML {
  constructor(options) {
    this._options = options;
    this.callDetails = {
      callDuration: 0,
      startedRinging: false,
    };
    this.createSipStack = this.createSipStack.bind(this);
    try {
      this.createSipStack();
    } catch (e) {
      this.errorCallback(e);
    }

    this.oConfigCall = {
      audio_remote: document.getElementById('audio_remote'),
      video_local: this.viewVideoLocal,
      video_remote: this.viewVideoRemote,
      screencast_window_id: 0x00000000, // entire desktop
      bandwidth: { audio: undefined, video: undefined },
      video_size: { minWidth: undefined, minHeight: undefined, maxWidth: undefined, maxHeight: undefined },
      events_listener: { events: '*', listener: this.onSipEventSession },
      sip_caps: [{ name: '+g.oma.sip-im' }, { name: 'language', value: `"en,fr"` }],
    };
  }

  errorCallback = e => {
    // eslint-disable-next-line no-console
    console.error(`Failed to initialize the engine: ${e.message}`);
  };

  uiCallTerminated = () => {
    this.oSipSessionCall = null;
    if (this.oNotifICall) {
      this.oNotifICall.cancel();
      this.oNotifICall = null;
    }
  };

  createSipStack() {
    const {
      iceServers,
      password,
      displayName,
      wsServers,
      realm,
      publicIdentity,
      privateIdentity,
      currentUserOrgGuid,
      currentUserEmailId,
    } = this._options;
    this.oSipStack = new SIPml.Stack({
      realm,
      impi: privateIdentity,
      impu: publicIdentity,
      password,
      display_name: displayName,
      websocket_proxy_url: wsServers,
      outbound_proxy_url: null,
      ice_servers: iceServers,
      enable_rtcweb_breaker: true,
      events_listener: { events: '*', listener: this.onSipEventStack },
      enable_early_ims: true, // Must be true unless you're using a real IMS network
      enable_media_stream_cache: false,
      bandwidth: { audio: 64 }, // could be redefined a session-level
      video_size: null, // could be redefined a session-level
      sip_headers: [
        { name: 'User-Agent', value: 'IM-client/OMA1.0 sipML5-v1.2016.03.04' },
        { name: 'Organization', value: 'Leoforce' },
        { name: 'orgId', value: currentUserOrgGuid?.toUpperCase() },
        { name: 'AryaUserName', value: currentUserEmailId },
      ],
    });
    this.oSipStack.start();
  }

  onSipEventSession = e => {
    switch (e.type) {
      case 'connected': {
        if (e.session === this.oSipSessionCall) {
          const {
            eventCallbacks: { onConnected },
          } = this._options;
          if (onConnected) {
            onConnected();
          }
          if (this.oNotifICall) {
            this.oNotifICall.cancel();
            this.oNotifICall = null;
          }
        }
        break;
      }
      case 'terminating':
        break;
      case 'terminated': {
        const {
          eventCallbacks: { onEnded, pauseDialTone },
        } = this._options;
        onEnded();
        pauseDialTone();
        if (e.session === this.oSipSessionRegister) {
          this.oSipSessionCall = null;
          this.oSipSessionRegister = null;
        } else if (e.session === this.oSipSessionCall) {
          this.uiCallTerminated('disconnected');
        }
        break;
      }
      case 'm_stream_audio_local_added':
      case 'm_stream_audio_local_removed':
      case 'm_stream_audio_remote_added':
      case 'm_stream_audio_remote_removed': {
        break;
      }

      case 'i_ect_new_call': {
        this.oSipSessionTransferCall = e.session;
        break;
      }

      case 'i_ao_request': {
        const iSipResponseCode = e.getSipResponseCode();
        if (e.session === this.oSipSessionCall) {
          if (iSipResponseCode === 180 || iSipResponseCode === 183) {
            const {
              eventCallbacks: { onConnecting },
            } = this._options;
            this.callDetails.startedRinging = true;
            if (onConnecting) {
              onConnecting();
            }
          }
        }
        break;
      }

      case 'm_early_media': {
        if (e.session === this.oSipSessionCall) {
          const {
            eventCallbacks: { pauseDialTone },
          } = this._options;
          pauseDialTone();
        }
        break;
      }

      case 'm_local_hold_ok': {
        if (e.session === this.oSipSessionCall) {
          if (this.oSipSessionCall.bTransfering) {
            this.oSipSessionCall.bTransfering = false;
          }

          this.oSipSessionCall.bHeld = true;
        }
        break;
      }
      case 'm_local_hold_nok': {
        if (e.session === this.oSipSessionCall) {
          this.oSipSessionCall.bTransfering = false;
        }
        break;
      }
      case 'm_local_resume_ok': {
        if (e.session === this.oSipSessionCall) {
          this.oSipSessionCall.bTransfering = false;

          this.oSipSessionCall.bHeld = false;
        }
        break;
      }
      case 'm_local_resume_nok': {
        if (e.session === this.oSipSessionCall) {
          this.oSipSessionCall.bTransfering = false;
        }
        break;
      }
      case 'o_ect_completed':
      case 'i_ect_completed': {
        if (e.session === this.oSipSessionCall) {
          if (this.oSipSessionTransferCall) {
            this.oSipSessionCall = this.oSipSessionTransferCall;
          }
          this.oSipSessionTransferCall = null;
        }
        break;
      }
      case 'o_ect_notify':
      case 'i_ect_notify': {
        if (e.session === this.oSipSessionCall) {
          if (e.getSipResponseCode() >= 300) {
            if (this.oSipSessionCall.bHeld) {
              this.oSipSessionCall.resume();
            }
          }
        }
        break;
      }

      default:
        break;
    }
  };

  logoutFromSession = () => {
    if (this.oSipStack) {
      this.oSipStack.stop();
    }
  };

  onSipEventStack = e => {
    switch (e.type) {
      case 'started': {
        try {
          this.oSipSessionRegister = this.oSipStack.newSession('register', {
            expires: 200,
            events_listener: { events: '*', listener: this.onSipEventSession.bind(this) },
            sip_caps: [
              { name: '+g.oma.sip-im', value: null },
              { name: '+audio', value: null },
              { name: 'language', value: '"en,fr"' },
            ],
          });
          this.isSipRegistered = true;
          const {
            eventCallbacks: { onRegistered },
          } = this._options;
          onRegistered();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          this.isSipRegistered = false;
          const {
            eventCallbacks: { onUnregistered },
          } = this._options;
          onUnregistered();
        }
        break;
      }
      case 'stopping':
      case 'stopped':
      case 'failed_to_start':
      case 'failed_to_stop': {
        const {
          eventCallbacks: { onFailed, pauseDialTone },
        } = this._options;
        onFailed();
        pauseDialTone();
        this.isSipRegistered = false;
        this.oSipStack = null;
        this.oSipSessionRegister = null;
        this.oSipSessionCall = null;
        this.logoutFromSession();
        break;
      }
      case 'm_permission_requested': {
        break;
      }
      case 'm_permission_refused': {
        this.uiCallTerminated('Media stream permission denied');
        const {
          eventCallbacks: { pauseDialTone },
        } = this._options;
        pauseDialTone();
        break;
      }
      default:
        break;
    }
  };

  call = (phoneNo, callerId) => {
    if (!this.isRegistered()) return;
    if (this.oSipStack && !this.oSipSessionCall) {
      _.set(this.oSipStack, ['o_stack', 'identity', 'o_uri_impu', 's_display_name'], callerId);
      this.oSipSessionCall = this.oSipStack.newSession('call-audio', this.oConfigCall);
      if (this.oSipSessionCall.call(phoneNo) !== 0) {
        this.oSipSessionCall = null;
      }
    } else if (this.oSipSessionCall) {
      this.oSipSessionCall.accept(this.oConfigCall);
    }
  };

  hangup = () => {
    if (!this.isRegistered()) return;
    if (this.oSipSessionCall) {
      this.oSipSessionCall.hangup({ events_listener: { events: '*', listener: this.onSipEventSession } });
    }
    if (!this.callDetails.startedRinging) {
      const {
        eventCallbacks: { onEnded },
      } = this._options;
      onEnded();
    }
  };

  toggleMute = () => {
    if (this.oSipSessionCall) {
      const bMute = !this.oSipSessionCall.bMute;
      const iret = this.oSipSessionCall.mute('audio', bMute);
      if (iret !== 0) {
        return;
      }
      this.oSipSessionCall.bMute = bMute;
    }
  };

  hold = () => {
    this.togglehold();
  };

  unhold = () => {
    this.togglehold();
  };

  togglehold = () => {
    if (this.oSipSessionCall) {
      const isHold = this.oSipSessionCall.bHeld;
      if (isHold) this.oSipSessionCall.resume();
      else this.oSipSessionCall.hold();
    }
  };

  sendDTMF = c => {
    if (this.oSipSessionCall && c) {
      this.oSipSessionCall.dtmf(c);
    }
  };

  isRegistered = () => {
    return this.isSipRegistered;
  };
}

export default AryaSIPML;
