import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Popover, Button, Checkbox } from 'antd';
import EmailStatsFilter from '../../EmailStatsFilter/EmailStatsFilter';
import FilterIconWithTooltip from '../../Common/FilterIconWithTooltip/FilterIconWithTooltip';
import './SecondaryListHeader.scss';

const { Search } = Input;

export default class SecondaryListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      isEmailNotSentFilterHidden: true,
      searchQuery: '',
      emailStatsFilterVisible: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.onApplyEmailStatsFilter = this.onApplyEmailStatsFilter.bind(this);
    this.onChangeEmailStatsFilters = this.onChangeEmailStatsFilters.bind(this);
    this.onClearEmailStatsFilter = this.onClearEmailStatsFilter.bind(this);
  }

  componentDidMount() {
    const { recentReadNotificationInfo } = this.props;
    const { PersonName } = recentReadNotificationInfo;

    if (PersonName) {
      this.setState({
        showSearch: true,
        searchQuery: PersonName,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { recentReadNotificationInfo } = this.props;
    const { recentReadNotificationInfo: prevRecentReadNotificationInfo } = prevProps;
    const { PersonName: prevPersonName, notificationId: prevNotificationId } = prevRecentReadNotificationInfo;
    const {
      PersonName: currentPersonName,
      notificationId: currentNotificationId,
      PersonName,
    } = recentReadNotificationInfo;
    if (currentPersonName && (currentPersonName !== prevPersonName || currentNotificationId !== prevNotificationId)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showSearch: true,
        searchQuery: PersonName,
      });
    }
  }

  handleClick() {
    this.setState({ showSearch: true });
  }

  handleBlur() {
    const { searchQuery } = this.state;
    if (searchQuery.trim() === '') this.setState({ showSearch: false, searchQuery: '' }, this.handleSearch(''));
  }

  handleChange(event) {
    const { onSearch, saveRecentReadNotificationInfo, recentReadNotificationInfo } = this.props;
    const { jobId } = recentReadNotificationInfo;
    if (jobId) saveRecentReadNotificationInfo('Connect', {});
    if (!event.target.value) {
      onSearch();
      this.setState({ showSearch: false });
    }
    this.setState({ searchQuery: event.target.value });
  }

  handleSearch(value) {
    const { onSearch } = this.props;
    onSearch(value);
    this.setState({ showSearch: false });
  }

  handleVisibleChange(emailStatsFilterVisible) {
    this.setState({ emailStatsFilterVisible });
  }

  onApplyEmailStatsFilter() {
    const { onApplyEmailStatsFilter, setEmailStatsFilterStatus, emailStatsfilterValues } = this.props;
    this.setState({
      emailStatsFilterVisible: false,
    });
    setEmailStatsFilterStatus(true);
    onApplyEmailStatsFilter(emailStatsfilterValues);
  }

  onChangeEmailStatsFilters(values) {
    const { onEmailStatsFilterChange } = this.props;
    onEmailStatsFilterChange(values);
  }

  onClearEmailStatsFilter() {
    const { onApplyEmailStatsFilter, setEmailStatsFilterStatus, onEmailStatsFilterChange } = this.props;
    this.setState({ emailStatsFilterVisible: false });
    setEmailStatsFilterStatus(false);
    onEmailStatsFilterChange([]);
    onApplyEmailStatsFilter([]);
  }

  render() {
    const {
      count,
      children,
      emailStatsForJob,
      isEmailStatsFilterApplied,
      emailStatsfilterValues,
      connectDetails,
      isLoading,
    } = this.props;
    const { showSearch, searchQuery, emailStatsFilterVisible, isEmailNotSentFilterHidden } = this.state;

    const isFilterApplied = isEmailStatsFilterApplied && emailStatsfilterValues && emailStatsfilterValues.length > 0;
    const emailStatsFilterTitle = (
      <div className="email-stats-filter-title">
        <div className="email-stats-filter-title-text">Filters</div>
        <div className="email-stats-filter-apply-clear">
          <Button className="clear-button" onClick={this.onClearEmailStatsFilter}>
            Clear
          </Button>
          <Button type="primary" className="apply-button" onClick={this.onApplyEmailStatsFilter}>
            Apply
          </Button>
        </div>
      </div>
    );

    const emailStatsFilterContent = (
      <div className="email-stats-filter-content">
        <Checkbox.Group
          className="checkbox-group"
          onChange={this.onChangeEmailStatsFilters}
          value={emailStatsfilterValues}
        >
          <h3>Email Communication</h3>
          <p>Total Candidates | Total Emails</p>
          <EmailStatsFilter
            emailStatsForJob={emailStatsForJob}
            connectDetails={connectDetails}
            totalCandidate={count}
            isLoading={isLoading}
            isEmailNotSentFilterHidden={isEmailNotSentFilterHidden}
          />
        </Checkbox.Group>
      </div>
    );

    return (
      <div className="secondaryCardHeader">
        {showSearch ? (
          <Search
            allowClear
            onBlur={this.handleBlur}
            value={searchQuery}
            onChange={this.handleChange}
            onSearch={this.handleSearch}
          />
        ) : (
          <div className="headerTitle">
            <div className="header-title">
              <b>Candidates ({count})</b>
            </div>
            <div className="secondary-list-extras">
              {count > 0 ? (
                <div>
                  <Popover
                    placement="bottom"
                    className="email-stats-filter"
                    title={emailStatsFilterTitle}
                    content={emailStatsFilterContent}
                    trigger="click"
                    visible={emailStatsFilterVisible}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <FilterIconWithTooltip isFilterApplied={isFilterApplied} style={{ fontSize: 18, marginTop: 5 }} />
                  </Popover>
                </div>
              ) : null}
              {children}
            </div>
            <div className="search">
              <Icon type="search" onClick={this.handleClick} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

SecondaryListHeader.propTypes = {
  count: PropTypes.string,
  onSearch: PropTypes.func,
};

SecondaryListHeader.defaultProps = {
  count: 0,
  onSearch: () => {},
};
