import reportEventTypes from './ReportEventTypes';

export function getEventNameByReportType(reportType, actionType) {
  switch (reportType) {
    case 'recruiters':
      return reportEventTypes.recruiterReports[actionType];
    case 'candidates':
      return reportEventTypes.candidateReports[actionType];
    case 'jobs':
      return reportEventTypes.jobReports[actionType];
    default:
      return null;
  }
}
