import React from 'react';
import { Avatar, Icon, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import './SettingsMenu.scss';
import LogOutButton from '../Auth/LogOutButton';
import { getConfiguration } from '../Collaborators/Collaborators';
import { JobCreditIcon } from '../../Icons/AryaIcons';
import LinkButton from '../Common/LinkButton/LinkButton';

const SettingsMenu = ({
  email,
  firstName,
  lastName,
  role,
  signOut,
  history,
  hidePopover,
  toggleClientAddDrawer,
  featureToggleList,
  visible,
  fetchOrgActivatedJobsCount,
  userConfig,
}) => {
  const { JobLimit: jobLimit } = userConfig;
  const { OrgActivatedJobsCount: orgActivatedJobsCount } = userConfig;
  React.useEffect(() => {
    if (visible) fetchOrgActivatedJobsCount();
  }, [visible]);
  const isApplicationSettingsEnabled = featureToggleList.ApplicationSettings.IsEnabled;
  const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
  const isNotificationSettingsEnabled = featureToggleList.SourcingNotificationEmails.IsEnabled;
  const config = getConfiguration([firstName, lastName].join(' '));
  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;

  const handleMenuItemClick = (path, toggleClientDrawer = false) => {
    history.push(path);
    hidePopover();
    if (toggleClientDrawer) {
      toggleClientAddDrawer();
    }
  };
  return (
    <div className="user-settings-content">
      <div className="user-settings-info">
        <div className="user-settings-avatar">
          <Avatar size={50} style={{ color: '#ffffff', backgroundColor: config.color }}>
            {config.text}
          </Avatar>
        </div>
        <div className="user-settings-primary-info">
          <div className="user-settings-name">
            <Tooltip placement="top" title={fullName}>
              {fullName}
            </Tooltip>
          </div>
          <div className="user-settings-email">{email}</div>
          <div className="user-settings-edit-profile">
            <LinkButton buttonName="Edit Profile" onClickButton={() => handleMenuItemClick('/settings/profile')} />
          </div>
        </div>
      </div>
      <div className="user-settings-options">
        {isApplicationSettingsEnabled ? (
          <div className="user-settings-option">
            <span role="presentation" onClick={() => handleMenuItemClick('/settings/app')}>
              Application Settings
            </span>
          </div>
        ) : null}
        {isPaidJobServiceEnabled ? (
          <div className="user-settings-option">
            <span role="presentation" onClick={() => handleMenuItemClick('/orders')}>
              View Order History
            </span>
          </div>
        ) : null}
        {role !== 'Recruiter' ? (
          <div className="user-settings-option">
            <span role="presentation" onClick={() => handleMenuItemClick('/settings/admin')}>
              Admin Settings
            </span>
          </div>
        ) : null}
        {/* {role !== 'Recruiter' ? (
          <div className="user-settings-option">
            <span>
              <FormattedMessage {...messages.users} />
            </span>
            <span className="add-new">+ Add new</span>
          </div>
        ) : null} */}
        {role !== 'Recruiter' ? (
          <div className="user-settings-option">
            <span role="presentation" onClick={() => handleMenuItemClick('/settings/clients')}>
              <FormattedMessage {...messages.clients} />
            </span>
            <div className="add-new">
              <LinkButton
                buttonName="Add Client"
                onClickButton={() => handleMenuItemClick('/settings/clients', true)}
              />
            </div>
          </div>
        ) : null}
        {/* <div className="user-settings-option">
        <span>
          <FormattedMessage {...messages.reportingPortal} />
        </span>
      </div> */}
        {isNotificationSettingsEnabled ? (
          <div className="user-settings-option">
            <span role="presentation" onClick={() => handleMenuItemClick('/settings/notifications')}>
              Notification Settings
            </span>
          </div>
        ) : null}
        <div className="user-settings-option logout-wrapper">
          <LogOutButton
            signOut={signOut}
            renderProp={(handleClick, logoutButtonText) => (
              <span className="logout" onClick={() => handleClick()} role="presentation">
                <Icon type="logout" className="logout-icon" />
                {logoutButtonText}
              </span>
            )}
          />
          {jobLimit !== undefined && orgActivatedJobsCount !== undefined && !isPaidJobServiceEnabled ? (
            <div className="job-activation-remaining-count-wrapper">
              <Tooltip title="No. of Job Activations left" placement="topRight">
                <JobCreditIcon />
                <span className="job-activation-remaining-count">{jobLimit - orgActivatedJobsCount}</span>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default SettingsMenu;
