import React from 'react';
import _ from 'lodash';
import {
  getIsAggregationFilterApplied,
  getFilteredCandidatesSearchResult,
  getdefaultFilterQueries,
} from '../../../Components/QuickSearchForm/QuickSearchFormUtils';
import { getSourceName } from '../../../Utils/SourceUtils';
import FilterPopoverComponent from '../../../Components/FilterPopoverComponent/FilterPopoverComponent';
import QuickSearchFilters from '../../../Components/QuickSearchFilters/QuickSearchFilters';
import SelectedAggregationFilters from '../../../Components/SelectedAggregationFilters/SelectedAggregationFilters';
import AryaFilterPopover from '../../../Components/FilterAryaSearch/AryaFilterPopover';

// const [isFilterFormVisible, setFilterFormVisible] = useState(false);

const getIsAggregationFilterEnabled = activeSource => {
  const aggregationFilterEnabledSources = ['careerbuilderedge'];
  const sourceName = getSourceName(activeSource)?.toLowerCase();
  return aggregationFilterEnabledSources.includes(sourceName);
};

const getIsAggregationFilterVisible = ({ candidateAggregations, activeSource }) => {
  const isAggregationFilterEnabled = getIsAggregationFilterEnabled(activeSource);
  return isAggregationFilterEnabled && !_.isEmpty(candidateAggregations);
};

function QuickSearchAggregationFilter(props) {
  const {
    form,
    activeSource,
    filterQueries,
    applyAggregationFilter,
    removeAggregation,
    clearAggregationFilter,
    candidateAggregations,
    showSelectedAggregations = true,
    jobId,
    candidateCollapsedFilters,
    isAdvancedSearchV2Enabled,
    isFilterApplied,
  } = props;
  const sourceName = getSourceName(activeSource);
  const currentFilterQueries = getdefaultFilterQueries({ ...filterQueries[sourceName] }, activeSource);
  const isAggregationFilterVisible = getIsAggregationFilterVisible({ candidateAggregations, activeSource });
  const isAggregationFilterApplied = getIsAggregationFilterApplied(currentFilterQueries, sourceName);

  return isAggregationFilterVisible || isAggregationFilterApplied ? (
    <div className="quick-search-candidate-filter">
      <div className="selected-aggregation-filter-wrapper">
        {showSelectedAggregations && isAggregationFilterApplied ? (
          <>
            <div className="filtered-candidates-search-result">
              {getFilteredCandidatesSearchResult(currentFilterQueries, sourceName)}
            </div>
            <SelectedAggregationFilters
              selectedAggregations={currentFilterQueries}
              sourceName={sourceName}
              removeAggregation={removeAggregation}
            />
          </>
        ) : null}
      </div>
      {isAggregationFilterVisible ? (
        <FilterPopoverComponent
          content={
            <QuickSearchFilters
              form={form}
              defaultSettings={currentFilterQueries}
              candidateAggregations={candidateAggregations}
              sourceName={getSourceName(activeSource)}
              candidateCollapsedFilters={candidateCollapsedFilters}
              isAdvancedSearchV2Enabled={isAdvancedSearchV2Enabled}
            />
          }
          onClearPayload={getSourceName(activeSource)}
          onApply={applyAggregationFilter}
          onClear={clearAggregationFilter}
          popoverTitle="Select Filters"
          // filterButtonTitle="Filters"
          isFilterApplied={isAggregationFilterApplied || isFilterApplied}
          buttonShape="circle"
          buttonStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        />
      ) : null}
    </div>
  ) : (
    <AryaFilterPopover jobId={jobId} />
  );
}

export default QuickSearchAggregationFilter;
