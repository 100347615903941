import React from 'react';
import { Form, Select } from 'antd';
import JobRecommendations from '../../Components/IdealCandidateIntel/JobRecommendations/JobRecommendations';
import { purifySelectedOptions } from '../../Utils/SelectComponentUtility';
import { onAddRecommendation } from '../../Utils/AdvanceRefineIntelUtils';
import EmploymentContext from './EmploymentContext';

const PreferredCompany = props => {
  const { initialValue } = props;
  const { form, companyRecommendations } = React.useContext(EmploymentContext);
  return (
    <div>
      <Form.Item>
        {form.getFieldDecorator('PreferredCompany', {
          initialValue,
        })(
          <Select
            mode="tags"
            key="PreferredCompany"
            placeholder="Add Company"
            style={{ width: '100%' }}
            dropdownStyle={{ display: 'none' }}
            onSelect={() => purifySelectedOptions(form, 'PreferredCompany')}
          />
        )}
      </Form.Item>
      <JobRecommendations
        key="CompanyRecommendation"
        recommendations={companyRecommendations}
        onAddRecommendation={onAddRecommendation(form, 'PreferredCompany')}
        recommendationTitle="We recommend these companies to make your search more relevant"
      />
    </div>
  );
};

export default PreferredCompany;
