import React from 'react';
import PropTypes from 'prop-types';
import 'pdfjs-dist/web/pdf_viewer.css';
import uuid from 'uuid/v4';
import _ from 'lodash';
import { highlightHtml } from '../../Utils/KeywordHighlightUtil';
import './PdfViewer.scss';

class PdfViewer extends React.Component {
  constructor(props) {
    super(props);
    this.pdfWrapperRef = React.createRef();
    this.pdfId = uuid();
    this.numPagesRendered = 0;
    this.totalPages = 0;
  }

  highlight = () => {
    const { highlights } = this.props;
    const textLayerNodes = document.querySelectorAll('.textLayer');
    highlightHtml(textLayerNodes, highlights, 'pdfmark');
  };

  componentDidMount() {
    import(/* webpackChunkName: "pdfjs" */ 'pdfjs-dist/build/pdf').then(pdfjs => {
      const pdfjsLib = pdfjs;
      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
      import(/* webpackChunkName: "pdf_viewer" */ 'pdfjs-dist/web/pdf_viewer').then(pdfjsViewer => {
        const container = document.getElementById(`viewerContainer-${this.pdfId}`);
        const pdfLinkService = new pdfjsViewer.PDFLinkService({ externalLinkTarget: pdfjsLib.LinkTarget.BLANK });
        const pdfViewer = new pdfjsViewer.PDFViewer({
          container,
          linkService: pdfLinkService,
        });
        pdfLinkService.setViewer(pdfViewer);
        document.addEventListener('pagesinit', () => {
          pdfViewer.currentScaleValue = 'page-width';
        });
        this.pdfjsLib = pdfjsLib;
        this.pdfViewer = pdfViewer;
        this.pdfLinkService = pdfLinkService;
        this.renderPDF();
      });
    });
  }

  componentWillUnmount() {
    if (this.pdfViewer) {
      this.pdfViewer.eventBus.off('textlayerrendered', this.highlightAfterRender); // remove the event listener
    }
  }

  shouldComponentUpdate(nextProps) {
    const { highlights, base64PdfContent } = this.props;
    if (!_.isEqual(highlights.sort(), nextProps.highlights.sort()) || base64PdfContent !== nextProps.base64PdfContent) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    this.renderPDF();
  }

  highlightAfterRender = () => {
    this.numPagesRendered += 1;
    if (this.numPagesRendered === this.totalPages) {
      this.highlight();
    }
  };

  renderPDF() {
    const { base64PdfContent } = this.props;
    if (this.pdfjsLib) {
      const loadingTask = this.pdfjsLib.getDocument({
        url: base64PdfContent,
      });
      loadingTask.promise.then(pdfDocument => {
        this.totalPages = pdfDocument.numPages;
        this.numPagesRendered = 0;
        this.pdfViewer.eventBus.on('textlayerrendered', this.highlightAfterRender); // add event listener for textlayerrendered event
        // Document loaded, specifying document for the viewer and
        // the (optional) linkService.
        this.pdfViewer.setDocument(pdfDocument);
        this.pdfLinkService.setDocument(pdfDocument, null);
      });
    }
  }

  render() {
    return (
      <div className="body-pdf">
        <div id={`viewerContainer-${this.pdfId}`}>
          <div className="pdfViewer" />
        </div>
      </div>
    );
  }
}
PdfViewer.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  base64PdfContent: PropTypes.string.isRequired,
};
export default PdfViewer;
