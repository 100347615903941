import React from 'react';
import { Tooltip } from 'antd';
import detectElementOverflow from 'detect-element-overflow';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { onlyUnique } from '../../CandidateListItem/CandidateListItem';
import { highlightKeyWords, getHighlightedKeyWords } from '../../../Utils/KeywordHighlightUtil';
import styles from './CandidateSkillsSection.module.scss';
import { setElementMaxHeightByLines } from '../../../Utils/CandidateCardUtils';

function CandidateSkillsSection(props) {
  const {
    keywordsToHighlight,
    skills = [],
    candidate,
    combinedKeywordsToHighlight = [],
    maxLinesToBeDisplayed,
    secondaryInfoStyle,
    skillsRef,
    keywordHighlightKey,
    shouldShowTooltip = true,
    tooltipPlacement = 'top',
    candidateEducation = [],
    candidateExperiences = [],
  } = props;

  const targetRef = React.useRef(null);
  const tooltipRef = React.useRef(null);
  const [placement, setPlacement] = React.useState(tooltipPlacement);
  const [tooltipHeight, setTooltipHeight] = React.useState();
  const [visibleTags, setVisibleTags] = React.useState([]);
  const [overflownTags, setOverflownTags] = React.useState([]);
  const [isSkillsTooltipVisible, setSkillsTooltipVisibility] = React.useState(false);
  const candidateCardContinerRef = React.useRef(null);

  const getRearrangedSkills = memoizeOne(() => {
    const newSkills = _.uniq([
      ...getHighlightedKeyWords(skills?.filter(onlyUnique), combinedKeywordsToHighlight).highlightedSkills,
      ...(skills || []),
    ]);
    const matchedHighlights = combinedKeywordsToHighlight;
    return { newSkills, matchedHighlights };
  });

  const highlightCandidateCard = () => {
    const { newSkills, matchedHighlights } = getRearrangedSkills();
    const candidateSkillsLength = newSkills.length;
    const keywordsLength = _.get(keywordsToHighlight, ['length'], 0);
    if (candidateSkillsLength && keywordsLength) {
      const domSelectors = [`div#candidate-card-skills${keywordHighlightKey}`];
      setTimeout(() => {
        highlightKeyWords(domSelectors, matchedHighlights);
      }, 100);
    }
  };

  React.useEffect(() => {
    highlightCandidateCard();
    if (targetRef) setElementMaxHeightByLines(targetRef, maxLinesToBeDisplayed, skillsRef);
  }, [combinedKeywordsToHighlight.length, skills.length, candidate.Id, visibleTags.length]);

  React.useEffect(() => {
    function handleResize() {
      const targetRect = targetRef.current?.getBoundingClientRect();
      const availableSpace = targetRect?.top;

      if (tooltipHeight) {
        const newPlacement = availableSpace < tooltipHeight ? 'bottom' : 'top';
        setPlacement(newPlacement);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSkillsTooltipVisible, placement, tooltipHeight]);

  const onTooltipVisibleChange = async isTooltipVisible => {
    await setSkillsTooltipVisibility(isTooltipVisible);
    const tooltipNode = tooltipRef.current?.getPopupDomNode();
    await setTooltipHeight(tooltipNode?.offsetHeight);
    if (isTooltipVisible) {
      const domSelectors = [`div.candidate-card-skills-tooltip-${keywordHighlightKey}`];
      highlightKeyWords(domSelectors, combinedKeywordsToHighlight);
    }
  };

  const { newSkills } = getRearrangedSkills();

  const filteredSkills = newSkills.filter(skill => skill.trim());
  const skillList = filteredSkills.map(skill => `${_.capitalize(skill)}`).join(',  ');

  React.useEffect(() => {
    if (shouldShowTooltip) {
      const limitedAggregationFiltersDiv = document.getElementById(`candidate-card-skills-${keywordHighlightKey}`);
      let currentOverflownTags = [];
      let currentVisibleTags = [];
      const index = filteredSkills.findIndex((skill, i) => {
        const aggregationFilterDiv = document.getElementById(`${keywordHighlightKey}-${skill}-${i}`);
        const isElementOverflown = detectElementOverflow(
          aggregationFilterDiv,
          limitedAggregationFiltersDiv
        ).collidedBottom;
        return isElementOverflown === true;
      });
      if (index !== -1) {
        currentOverflownTags = filteredSkills.slice(index);
        currentVisibleTags = filteredSkills.slice(0, index);
      } else {
        currentVisibleTags = filteredSkills;
      }
      const overflowTagNames = currentOverflownTags.map(skill => skill);
      const visibleTagNames = currentVisibleTags.map(skill => skill);
      setOverflownTags(overflowTagNames);
      setVisibleTags(visibleTagNames);
    }
  }, [skills.length, candidateEducation.length, candidateExperiences.length]);

  const candidateSkillsDivWidth = `${candidateCardContinerRef.current?.clientWidth - 20}px`;

  return (
    <div className={styles.candidateSkillsSection} ref={candidateCardContinerRef}>
      {shouldShowTooltip ? (
        <Tooltip
          title={skillList}
          overlayClassName={`candidate-card-skills-tooltip-${keywordHighlightKey} candidate-card-skills-tooltip`}
          overlayStyle={{ maxWidth: '550px' }}
          ref={tooltipRef}
          placement={placement}
          onVisibleChange={onTooltipVisibleChange}
          mouseEnterDelay={0.5}
        >
          <div
            className={styles.candidateCardSkills}
            id={`candidate-card-skills-${keywordHighlightKey}`}
            style={{ ...secondaryInfoStyle, maxWidth: candidateSkillsDivWidth }}
            ref={targetRef}
          >
            {filteredSkills.map((skill, index) => (
              <span style={{ minWidth: 'fit-content' }} id={`${keywordHighlightKey}-${skill}-${index}`}>
                {_.capitalize(skill)}
                {index === filteredSkills.length - 1 ? '' : ','}
              </span>
            ))}
          </div>
          <div className={styles.visibleSkills} id={`candidate-card-skills${keywordHighlightKey}`}>
            {visibleTags.map((skill, index) => (
              <span style={{ minWidth: 'fit-content' }}>
                {_.capitalize(skill)}
                {index === visibleTags.length - 1 ? '' : ','}
              </span>
            ))}
            {overflownTags?.length ? <span style={{ marginLeft: '-6px' }}>...</span> : null}
          </div>
        </Tooltip>
      ) : (
        <div
          className={styles.candidateCardSkillsContent}
          id={`candidate-card-skills${keywordHighlightKey}`}
          style={secondaryInfoStyle}
        >
          {skillList}
        </div>
      )}
    </div>
  );
}

export default CandidateSkillsSection;
