import React, { Component } from 'react';
import { Drawer } from 'antd';
import BotConfigContainer from '../BotConfig/BotConfig';

export default class BotConfigDrawerContainer extends Component {
  getDrawerTitle = () => {
    const { jobTitle } = this.props;
    const AppName = 'Chat';
    return (
      <span>
        <b>{`${AppName}bot Configuration`}&nbsp;</b>
        {jobTitle}
      </span>
    );
  };

  handleDrawerVisibleChange = isDrawerVisible => {
    const { toggleBotDrawerRenderFlag } = this.props;
    if (!isDrawerVisible) toggleBotDrawerRenderFlag();
  };

  render() {
    const { visible, jobId, onClose, botDrawerRenderFlag } = this.props;
    if (!botDrawerRenderFlag) return null;
    return (
      <Drawer
        title={this.getDrawerTitle()}
        bodyStyle={{ height: '82%', overflowY: 'scroll' }}
        width={640}
        visible={visible}
        onClose={onClose}
        destroyOnClose
        afterVisibleChange={this.handleDrawerVisibleChange}
      >
        <BotConfigContainer onClose={onClose} jobId={jobId} />
      </Drawer>
    );
  }
}
