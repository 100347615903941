import React from 'react';
import { Skeleton } from 'antd';
import styles from './CandidateCardTertiaryContent.module.scss';
import CandidateCardNotesIcon from './CandidateCardNotesIcon';
import SocialIcon from '../Common/SocialIcon/SocialIcon';
import CandidateCardConnectOptions from './CandidateCardConnectOptions';
import { getCandidateApplicationPreviewBtn } from '../CandidateListItem/CandidateListItem';

function CandidateCardTertiaryContent(props) {
  const {
    onCandidateCardClick,
    candidate,
    maskingConfig,
    candidateContext,
    featureToggleList,
    connectStatusLoaded,
    showCandidateDetail,
    setComposeEmailType,
    indexKey,
    currentJobDetails,
    handleContactPull,
    showConnectWidget,

    candidateType,
    version,
    isCandidateViewIconVisible,
  } = props;

  const jobGuid = currentJobDetails?.JobGuid;
  const candidateApplicationPreviewButton = getCandidateApplicationPreviewBtn(jobGuid, candidate, featureToggleList);
  const applicationPreviewBtn = candidateApplicationPreviewButton || null;

  return (
    <Skeleton active loading={!connectStatusLoaded} rows={1} title={false}>
      <div className={styles.candidateCardTertiaryContent}>
        {applicationPreviewBtn}
        <CandidateCardNotesIcon
          onCandidateCardClick={onCandidateCardClick}
          candidate={candidate}
          featureToggleList={featureToggleList}
          candidateType={candidateType}
          version={version}
        />
        <SocialIcon candidate={candidate} isActive={maskingConfig?.IsActive} />
        <CandidateCardConnectOptions
          featureToggleList={featureToggleList}
          connectStatusLoaded={connectStatusLoaded}
          showCandidateDetail={showCandidateDetail}
          setComposeEmailType={setComposeEmailType}
          candidate={candidate}
          indexKey={indexKey}
          currentJobDetails={currentJobDetails}
          handleContactPull={handleContactPull}
          showConnectWidget={showConnectWidget}
          candidateContext={candidateContext}
          isCandidateViewIconVisible={isCandidateViewIconVisible}
        />
      </div>
    </Skeleton>
  );
}

export default CandidateCardTertiaryContent;
