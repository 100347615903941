import React from 'react';
import { Button, Tooltip } from 'antd';
import { getTooltipContentForDisabledLocation } from '../../../Utils/MultiLocationUtils';
import MultiLocationContext from '../MultiLocationContext';

const EnhancedAddLocationButton = props => {
  const { isRemoteJobChecked, locationContext } = props;
  const {
    isLocationSelectionLimitReached,
    addLocationEnhanced,
    isCountryWideSearchEnabledCountry,
    isRegionDisabled,
    milesInputValidationError,
  } = React.useContext(MultiLocationContext);
  const isDisable =
    isLocationSelectionLimitReached ||
    isRemoteJobChecked ||
    isCountryWideSearchEnabledCountry ||
    (isRegionDisabled && locationContext === 'Region') ||
    milesInputValidationError;
  const isAddLocationDisabled = isLocationSelectionLimitReached || isDisable;
  const onAddLocation = () => {
    !isAddLocationDisabled && addLocationEnhanced();
  };

  return isAddLocationDisabled ? (
    <Tooltip
      title={getTooltipContentForDisabledLocation({
        isLocationSelectionLimitReached,
        isCountryWideSearchEnabledCountry,
      })}
    >
      <Button
        type="link"
        onClick={onAddLocation}
        style={{
          cursor: isDisable ? 'not-allowed' : '',
          color: isDisable ? '#51575e' : '',
        }}
      >
        Add Location
      </Button>
    </Tooltip>
  ) : (
    <Button
      type="link"
      onClick={onAddLocation}
      style={{ cursor: isRemoteJobChecked ? 'not-allowed' : '', color: isRemoteJobChecked ? '#51575e' : '' }}
      disabled={milesInputValidationError}
    >
      Add Location
    </Button>
  );
};

export default EnhancedAddLocationButton;
