/* eslint-disable class-methods-use-this */
import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { Tabs, Skeleton } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import PubSub from 'pubsub-js';
// import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ActivationDialogContainer from '../ActivationDialog/ActivationDialog';
import JobDetailHeader from '../../Components/JobDetailHeader/JobDetailHeader';
import NetworkIssueErrorBoundary from '../../Components/ErrorBoundary/NetworkIssue/NetworkIssue';
import BotConfigDrawerContainer from '../BotConfigDrawerContainer/BotConfigDrawerContainer';
import * as JobActions from '../../Actions/JobActions';
import * as JobStatusActions from '../../Actions/JobStatusActions';
import * as EmailTemplatesActions from '../../Actions/EmailTemplates';
import * as MessageTemplatesActions from '../../Actions/TextTemplates';
import * as DripTemplatesActions from '../../Actions/DripTemplates';
import * as ApiStatusActions from '../../Actions/ApiStatusActions';
import {
  getConfig,
  getConnectConfig,
  getJobDistributionPortals,
  getWhiteLabelInformation,
} from '../../Reducers/ConfigReducer';
import { getJobsById, getIndustrySuggestions } from '../../Reducers/JobReducer';
import { getJobStatus } from '../../Reducers/JobStatusReducer';
import { getUsersById } from '../../Reducers/UserReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';
import { getEmailTemplatesById, getEmailTemplatesCount, getEmailTemplateIds } from '../../Reducers/EmailTemplates';
import { getTextTemplatesById, getTextTemplatesCount, getTextTemplateIds } from '../../Reducers/TextTemplates';
import { getDripTemplatesById } from '../../Reducers/DripTemplates';
import { getScoutingAgentConfig } from '../../Reducers/ScoutingAgentReducer';
import AutomationEmailId from '../../Components/Automation/AutomationEmailId';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { PLAN_UPGRADE } from '../../PubSub/EventTypes';
import DistributeJobContainer from '../DistributeJob/DistributeJobContainer';
import BotConfigPopup from '../BotConfig/BotConfigPopup';
import JobPublishContainer from '../JobPublish/JobPublishContainer';
import JobDetail from '../../Components/JobDetail/JobDetail';
import JobIntelContainer from '../JobIntel/JobIntel';
import JobCandidatesTab from '../JobCandidatesTab/JobCandidatesTab';
import OrderCancellationContainer from '../OrderCancellation/OrderCancellationContainer';
import JobSettings from '../JobSettings/JobSettings';
import LoadingScreen from '../../Components/LoadingScreen/LoadingScreen';
import ManualSearchContainerV2 from '../ManualSearchV2/ManualSearchContainer/ManualSearchContainer';
import ManualSearchContainerV1 from '../ManualSearch/ManualSearchContainer/ManualSearchContainer';
import './JobDetail.scss';
import styles from './JobDetail.module.scss';
import * as jobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import ManualSearchWrapperV2 from '../ManualSearchV2/ManualSearchWrapper/ManualSearchWrapper';
import ManualSearchWrapperV1 from '../ManualSearch/ManualSearchWrapper/ManualSearchWrapper';
import JobNotesContainer from '../JobNotes/JobNotesContainer';
import { isPulseUser } from '../../Utils/ConfigUtils';
import ScoutingAgentDrawer from '../ManualSearch/ScoutingAgent/ScoutingAgentDrawer';
// import JobCandidateSupply from '../JobCandidateSupply/JobCandidateSupply'; disable candidate supply in job section till further decision
import * as ConfigActions from '../../Actions/ConfigActions';
import * as ScoutingAgentActions from '../../Actions/ScoutingAgentActions';

export function getActiveKeyAndStatus(match, location, featureToggleList) {
  let activeKey;
  let status;
  let connectionStatus;
  let publishedStatus;
  const activeKeyAndStatus = {};
  switch (match.params.tab) {
    case 'candidates':
      activeKey = 'candidates';
      break;
    case 'intel':
      activeKey = 'intel';
      break;
    case 'candidatesupply':
      activeKey = 'candidatesupply';
      break;
    case 'notes':
      activeKey = 'notes';
      break;
    default:
      activeKey = 'info';
  }
  activeKeyAndStatus.activeKey = activeKey;
  const values = queryString.parse(location.search);
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);

  const {
    AryaRanking: { IsEnabled: isAryaRankingEnabled, IsAllowed: isAryaRankingUnlocked },
    AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled },
  } = featureToggleList;
  const { id: candidateId, index: candidateIndexInList, activeTab } = values;

  const isAryaRankedRedirectionAllowed = isAryaRankingEnabled && isAryaRankingUnlocked && isAdvanceSearchV2Enabled;
  const isAryaRankedCandidateRedirection = candidateIndexInList && candidateId && !activeTab;

  if (activeKey === 'candidates') {
    switch (values.status) {
      case 'shortlisted':
        status = 'Shortlisted';
        connectionStatus = null;
        publishedStatus = 'Shortlisted';
        activeKeyAndStatus.isAryaRankedTab = false;
        break;
      case 'connected':
        status = null;
        connectionStatus = 'Connected';
        activeKeyAndStatus.isAryaRankedTab = false;
        break;
      case 'rejected':
        status = 'Rejected';
        connectionStatus = null;
        publishedStatus = 'Rejected';
        activeKeyAndStatus.isAryaRankedTab = false;
        break;
      case 'engaged':
        status = null;
        connectionStatus = 'Engaged';
        publishedStatus = 'Sourced';
        activeKeyAndStatus.isAryaRankedTab = false;
        break;
      case 'applied':
        status = 'Applied';
        connectionStatus = null;
        publishedStatus = 'Applied';
        activeKeyAndStatus.isAryaRankedTab = false;
        break;
      case 'sourced':
        status = 'Sourced';
        connectionStatus = null;
        publishedStatus = 'Sourced';
        activeKeyAndStatus.isAryaRankedTab = isAryaRankedRedirectionAllowed && isAryaRankedCandidateRedirection;
        break;
      default:
        status = 'Sourced';
        connectionStatus = null;
        publishedStatus = 'Sourced';
        activeKeyAndStatus.isAryaRankedTab = isAryaRankedRedirectionAllowed;
    }
    activeKeyAndStatus.status = isPaidJobServiceEnabled ? null : status;
    activeKeyAndStatus.connectionStatus = connectionStatus;
    activeKeyAndStatus.publishedStatus = isPaidJobServiceEnabled ? publishedStatus : null;
  }
  return activeKeyAndStatus;
}

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  jobStatus: getJobStatus(state),
  jobDetails: getJobsById(state),
  usersById: getUsersById(state),
  userConfig: getConfig(state),
  jobDetailsStatus: getApiStatus(state, 'jobDetailsStatus'),
  getJobQuestionApiStatus: getApiStatus(state, 'getJobQuestionApiStatus'),
  distributeJobPortalStatus: getApiStatus(state, 'distributeJobPortalStatus'),
  connectConfig: getConnectConfig(state),
  emailTemplatesCount: getEmailTemplatesCount(state),
  messageTemplatesCount: getTextTemplatesCount(state),
  emailTemplateIds: getEmailTemplateIds(state),
  dripTemplatesById: getDripTemplatesById(state),
  messageTemplateIds: getTextTemplateIds(state),
  emailTemplatesById: getEmailTemplatesById(state),
  messageTemplatesById: getTextTemplatesById(state),
  currentUserDetails: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
  productVariantrsById: getProductVariantsById(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  jobDistributionPortals: getJobDistributionPortals(state),
  industrySuggestions: getIndustrySuggestions(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  allTabCandidatesFetchApi: getApiStatus(state, 'allTabCandidatesFetchApi'),
});

const mapDispatchToProps = {
  fetchJobStatus: JobStatusActions.fetchJobStatus,
  fetchJobDistributionStatus: JobActions.fetchJobDistributionStatus,
  changeJobStatus: JobActions.changeJobStatus,
  fetchJobDetails: JobActions.fetchJobDetails,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  fetchActivationStatus: JobActions.fetchActivationStatus,
  fetchJobResumes: JobActions.fetchJobResumes,
  uploadJobResume: JobActions.uploadJobResume,
  removeJobResume: JobActions.deleteJobResume,
  downloadJobResume: JobActions.downloadJobResume,
  fetchJobConfig: JobActions.fetchJobConfig,
  updateJobConfig: JobActions.updateJobConfig,
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
  setJobCandidateTabActiveTab: jobCandidatesTabActions.setJobCandidateTabActiveTab,
  fetchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  fetchMessageTemplates: MessageTemplatesActions.searchTextTemplates,
  getDripTemplate: DripTemplatesActions.getDripTemplate,
  setJobDetailsApiStatus: ApiStatusActions.setJobDetailsApiStatus,
  fetchPortalCredentials: ConfigActions.fetchAllPortalCredentials,
  fetchIndustrySuggestions: JobActions.fetchIndustries,
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
  setJobCandidateTabActiveSourceName: jobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setManualSearchActiveSource: jobCandidatesTabActions.setManualSearchActiveSource,
};

class JobDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'info',
      status: 'Sourced',
      activationDialogVisibility: false,
      showBotConfigDrawer: false,
      settingsDrawerVisbility: false,
      distributeJobModalVisibility: false,
      botModalVisibility: false,
      toPublishPortal: undefined,
    };
    this.onTabChange = this.onTabChange.bind(this);
    this.showActivationDialog = this.showActivationDialog.bind(this);
    this.closeActivationDialog = this.closeActivationDialog.bind(this);
    this.onDuplicateJob = this.onDuplicateJob.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.toggleSettingsDrawer = this.toggleSettingsDrawer.bind(this);
    this.toggleDistributeJobModal = this.toggleDistributeJobModal.bind(this);
  }

  componentDidMount() {
    const { fetchPortalCredentials } = this.props;
    fetchPortalCredentials();
    this.fetchData();
  }

  componentWillUnmount() {
    const { setJobDetailsApiStatus } = this.props;
    setJobDetailsApiStatus(null);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { jobId } = match.params;
    const prevJobId = prevProps.match.params.jobId;
    if (jobId !== prevJobId) {
      this.fetchData();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { match, history, location, featureToggleList } = nextProps;
    const { jobId } = match.params;
    const jobErrorCode = _.get(nextProps, ['jobDetails', jobId, 'ErrorCode'], undefined);
    if (jobErrorCode === 403) {
      history.push(`/jobs/${match.params.jobId}/forbidden`);
    }
    if (jobErrorCode === 404 || jobErrorCode === 400) {
      history.push(`/jobs/${match.params.jobId}/notfound`);
    }
    return getActiveKeyAndStatus(match, location, featureToggleList);
  }

  fetchData() {
    const {
      match,
      fetchJobDetails,
      fetchJobSourcingStats,
      fetchActivationStatus,
      fetchJobStatus,
      fetchJobResumes,
      featureToggleList,
      fetchJobDistributionStatus,
      userConfig,
      fetchIndustrySuggestions,
      industrySuggestions,
    } = this.props;
    const jobId = parseInt(match.params.jobId, 10);
    const { PaidJobService } = featureToggleList;
    const { Role } = userConfig;
    if (PaidJobService.IsEnabled || Role === 'Service Rep') {
      fetchJobDistributionStatus(jobId);
    }
    fetchJobStatus();
    fetchJobDetails(jobId);
    fetchJobSourcingStats([jobId]);
    fetchActivationStatus([jobId]);
    fetchJobResumes(jobId);
    if (industrySuggestions.length === 0) {
      fetchIndustrySuggestions({
        SearchTerm: '',
        Size: 200,
      });
    }
  }

  setPublishPortal = portal => {
    this.setState({
      toPublishPortal: portal,
    });
  };

  onTabChange(key, event, defaultStatus) {
    const {
      match,
      history,
      allTabCandidatesFetchApi,
      setJobCandidateTabActiveSourceName,
      setManualSearchActiveSource,
    } = this.props;
    const { status, connectionStatus, publishedStatus, isAryaRankedTab } = this.state;
    const statusUrl = isAryaRankedTab ? 'ranked' : defaultStatus || status || connectionStatus || publishedStatus;
    if (key === 'candidates' && allTabCandidatesFetchApi === 'COMPLETED') {
      setManualSearchActiveSource({ manualSearchActiveSource: { Portal: 'All', Type: 'Primary' } });
      setJobCandidateTabActiveSourceName({ activeSourceName: 'All' });
    }

    const keyToUrl = {
      info: `/jobs/${match.params.jobId}`,
      candidates: `/jobs/${match.params.jobId}/candidates${statusUrl ? `?status=${statusUrl.toLowerCase()}` : ''}`,
      intel: `/jobs/${match.params.jobId}/intel`,
      candidatesupply: `/jobs/${match.params.jobId}/candidatesupply`,
      notes: `/jobs/${match.params.jobId}/notes`,
    };
    history.push(keyToUrl[key]);
  }

  onDuplicateJob() {
    const { history, match } = this.props;
    const { jobId } = match.params;
    history.push({
      pathname: '/jobs/create',
      state: {
        jobId,
      },
    });
  }

  showActivationDialog() {
    this.setState({
      activationDialogVisibility: true,
    });
  }

  closeActivationDialog() {
    this.setState({
      activationDialogVisibility: false,
    });
  }

  toggleSettingsDrawer() {
    const { settingsDrawerVisbility } = this.state;
    this.setState({
      settingsDrawerVisbility: !settingsDrawerVisbility,
    });
  }

  toggleBotDrawerRenderFlag = () => {
    const { botDrawerRenderFlag } = this.state;
    this.setState({ botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigDrawer = () => {
    const { showBotConfigDrawer, botDrawerRenderFlag } = this.state;
    if (showBotConfigDrawer) this.setState({ showBotConfigDrawer: !showBotConfigDrawer });
    else this.setState({ showBotConfigDrawer: !showBotConfigDrawer, botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigModal = () => {
    const { botModalVisibility } = this.state;
    this.setState({ botModalVisibility: !botModalVisibility });
  };

  toggleDistributeJobModal() {
    const { distributeJobModalVisibility } = this.state;
    this.setState({
      distributeJobModalVisibility: !distributeJobModalVisibility,
    });
  }

  upgradePlan = () => {
    const { history, match, jobDetails } = this.props;
    const { jobId } = match.params;
    const jobGuid = _.get(jobDetails[jobId], ['JobGuid']);
    const currentPlan = jobDetails[jobId]?.OrderInfo?.ProductVariantName;
    PubSub.publish(PLAN_UPGRADE, {
      currentPlan,
      jobGuid,
      sourceName: 'EngageCandidates',
    });
    history.push({
      pathname: '/select-plan',
      search: `?jobGuid=${jobGuid}&jobId=${jobId}&isUpgrade=${true}`,
    });
  };

  handleBackFromPublish = () => {
    this.setPublishPortal();
  };

  onSetUrl = url => {
    const { history } = this.props;
    history.push(url);
  };

  getjobCandidateTabOrOrderCanellationContainer = (currentJobDetails, jobId) => {
    const { status, connectionStatus, publishedStatus, isAryaRankedTab } = this.state;
    const { featureToggleList } = this.props;
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const orderStatus = currentJobDetails.OrderInfo?.Status;
    return isPaidJobServiceEnabled && ['openforcancellation', 'cancelled'].includes(orderStatus?.toLowerCase()) ? (
      <OrderCancellationContainer jobId={jobId} />
    ) : (
      <JobCandidatesTab
        jobId={jobId}
        jobGuid={currentJobDetails?.JobGuid}
        status={status}
        connectionStatus={connectionStatus}
        publishedStatus={publishedStatus}
        currentJobDetails={currentJobDetails}
        setUrl={this.onSetUrl}
        isAryaRankedTab={isAryaRankedTab}
      />
    );
  };

  onSearchCandidatesCallback = () => {
    const { setJobCandidateTabActiveTab } = this.props;
    setJobCandidateTabActiveTab({ activeTab: 'sourced' });
    this.onTabChange('candidates', null, 'sourced');
  };

  getManualSearchContainer = () => {};

  render() {
    const { settingsDrawerVisbility, botDrawerRenderFlag, distributeJobModalVisibility } = this.state;
    const { toggleSettingsDrawer } = this;
    const {
      match,
      jobDetails,
      jobStatus,
      changeJobStatus,
      usersById,
      uploadJobResume,
      removeJobResume,
      downloadJobResume,
      jobDetailsStatus,
      userConfig,
      connectConfig,
      updateJobConfig,
      featureToggleList,
      whiteLabelInfo,
      productVariantrsById,
      getJobQuestionApiStatus,
      jobDistributionPortals,
      fetchJobConfig,
      distributeJobPortalStatus,
      setScoutingAgentDrawerVisibility,
      fetchJobScoutingAgent,
      scoutingAgentConfig,
      onStatusChange,
    } = this.props;
    const { activationDialogVisibility, activeKey, showBotConfigDrawer, botModalVisibility, toPublishPortal } =
      this.state;
    const jobId = parseInt(match.params.jobId, 10);
    const currentJobDetails = jobDetails?.[jobId];
    if (!currentJobDetails) {
      return <LoadingScreen loading active />;
    }
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    const isJobTagEnabled = _.get(featureToggleList, ['JobTag', 'IsEnabled'], false);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const emailAutomationConfiguration = currentJobDetails.AutomationConfiguration?.Email;
    const workflowAutomationConfiguration = currentJobDetails.AutomationConfiguration?.Workflows?.[0];
    const messageAutomationConfiguration = currentJobDetails.AutomationConfiguration?.Message;
    const isAutoEmailEnabled =
      emailAutomationConfiguration?.IsEnabled ||
      (featureToggleList.Workflow.IsEnabled && workflowAutomationConfiguration?.IsEnabled);
    const isAutoMessageEnabled = messageAutomationConfiguration?.IsEnabled;
    const automationEmailId =
      emailAutomationConfiguration?.From || workflowAutomationConfiguration?.EmailActivityFromAddress;
    const sendToUserRecommended = emailAutomationConfiguration?.IsEnabled
      ? emailAutomationConfiguration?.SendToUserRecommended
      : workflowAutomationConfiguration?.TriggerToUserRecommended;
    const automationEmailSendTo = emailAutomationConfiguration?.IsEnabled
      ? emailAutomationConfiguration?.SendTo
      : workflowAutomationConfiguration?.TriggerTo?.RecommendedStatuses;
    const publishedStatuses = ['Sourced', 'Shortlisted', 'Rejected', 'Engaged'];
    let publishedStatusesCount = 0;
    const isPulse = isPulseUser();
    publishedStatuses.forEach(item => {
      publishedStatusesCount += _.get(currentJobDetails, ['sourcingStats', 'PublishedStats', item], 0);
    });

    const {
      JobIntel: { IsEnabled: isJobIntelEnabled },
      MarketIntelligence: { IsEnabled: isMarketIntelEnabled },
      CandidateManualSearch: { IsEnabled: isCandidateManualSearchEnabled },
    } = featureToggleList;

    if (toPublishPortal) {
      return (
        <JobPublishContainer
          jobId={jobId}
          enabledPortals={jobDistributionPortals}
          portal={toPublishPortal}
          goBack={this.handleBackFromPublish}
          AppName={AppName}
        />
      );
    }

    const jobCandidateTabOrOrderCanellationContainer = this.getjobCandidateTabOrOrderCanellationContainer(
      currentJobDetails,
      jobId
    );
    const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;
    const ManualSearchWrapper = isAdvancedSearchV2Enabled ? ManualSearchWrapperV2 : ManualSearchWrapperV1;
    const ManualSearchContainer = isAdvancedSearchV2Enabled ? ManualSearchContainerV2 : ManualSearchContainerV1;
    return (
      <div id="job-details">
        {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && <ScoutingAgentDrawer jobId={jobId} />}
        <Skeleton active paragraph={{ rows: 16 }} loading={!jobDetailsStatus || jobDetailsStatus === 'INPROGRESS'}>
          {!featureToggleList.CandidateManualSearch.IsEnabled ? (
            <ActivationDialogContainer
              jobId={parseInt(jobId, 10)}
              visible={activationDialogVisibility}
              handleCancel={this.closeActivationDialog}
              job={currentJobDetails}
              aryaVersion={_.get(userConfig, 'SubscriptionType')}
              type="job"
              onStatusChange={onStatusChange}
            />
          ) : null}
          <BotConfigDrawerContainer
            jobTitle={currentJobDetails.JobTitle ?? ''}
            visible={showBotConfigDrawer}
            onClose={this.toggleBotConfigDrawer}
            jobId={jobId}
            botDrawerRenderFlag={botDrawerRenderFlag}
            toggleBotDrawerRenderFlag={this.toggleBotDrawerRenderFlag}
            AppName={AppName}
          />
          <BotConfigPopup
            title="Arya Pulse PreQualified Questions"
            subTitle="Questions candidates will be asked"
            botModalVisibility={botModalVisibility}
            onBotClose={this.toggleBotConfigModal}
            jobId={jobId}
            onBotCancel={this.toggleBotConfigModal}
            cancelButtonText="Cancel"
            saveButtonText="Save"
            hideSelectTemplate
            getJobQuestionApiStatus={getJobQuestionApiStatus}
            closable
            readOnly
          />
          {_.get(featureToggleList, ['JobConfiguration', 'IsEnabled']) ? (
            <JobSettings
              jobId={jobId}
              jobDetails={currentJobDetails}
              SourcingConfiguration={currentJobDetails.SourcingConfiguration}
              AutomationConfiguration={currentJobDetails.AutomationConfiguration}
              drawerVisibility={settingsDrawerVisbility}
              toggleSettingsDrawer={this.toggleSettingsDrawer}
              updateJobConfig={updateJobConfig}
              AryaRejectionConfiguration={
                currentJobDetails.AryaRejectionConfiguration ?? {
                  IsEnabled: false,
                }
              }
              isSourcingAllowed
              isCandidateAutoRejectAllowed
              isSendToOptionAllowed
              isEmailAutomationNoteAllowed
              currentJobDetails={currentJobDetails}
            />
          ) : null}

          <JobDetailHeader
            scoutingAgentConfig={scoutingAgentConfig}
            job={currentJobDetails}
            usersById={usersById}
            jobStatus={jobStatus}
            changeJobStatus={changeJobStatus}
            showActivationDialog={this.showActivationDialog}
            duplicateJob={this.onDuplicateJob}
            showBotConfigDrawer={isPaidJobServiceEnabled ? this.toggleBotConfigModal : this.toggleBotConfigDrawer}
            toggleSettingsDrawer={toggleSettingsDrawer}
            isAutoEmailEnabled={isAutoEmailEnabled}
            isAutoMessageEnabled={isAutoMessageEnabled}
            userConfig={userConfig}
            connectConfig={connectConfig}
            featureToggleList={featureToggleList}
            productVariantrsById={productVariantrsById}
            upgradePlan={this.upgradePlan}
            toggleDistributeJobModal={this.toggleDistributeJobModal}
            AppName={AppName}
            publishedStatusesCount={publishedStatusesCount}
            fetchJobConfig={fetchJobConfig}
            fetchJobScoutingAgent={fetchJobScoutingAgent}
            setScoutingAgentDrawerVisibility={setScoutingAgentDrawerVisibility}
          />
          <DistributeJobContainer
            jobId={jobId}
            distributeJobModalVisibility={distributeJobModalVisibility}
            handleClose={this.toggleDistributeJobModal}
          />
          <ManualSearchWrapper jobId={jobId}>
            <Tabs
              tabBarStyle={{ marginBottom: '24px' }}
              defaultActiveKey="info"
              onTabClick={this.onTabChange}
              activeKey={activeKey}
              tabBarExtraContent={
                isAutoEmailEnabled && !isPaidJobServiceEnabled ? (
                  <div className="auto-email-tag-container">
                    <AutomationEmailId
                      sendToUserRecommended={sendToUserRecommended}
                      emailId={automationEmailId}
                      sendToStatuses={automationEmailSendTo}
                    />
                  </div>
                ) : null
              }
              animated={false}
            >
              <TabPane tab="Job Info" key="info">
                <NetworkIssueErrorBoundary>
                  {isCandidateManualSearchEnabled ? (
                    <div className={styles.manualSearchWrapper}>
                      <ManualSearchContainer
                        jobId={jobId}
                        onSearchCandidatesCallback={this.onSearchCandidatesCallback}
                        pageName="Job Detail"
                        candidateContext="job"
                      />
                    </div>
                  ) : null}
                  <JobDetail
                    job={currentJobDetails}
                    usersById={usersById}
                    uploadJobResume={uploadJobResume}
                    removeJobResume={removeJobResume}
                    downloadJobResume={downloadJobResume}
                    isJobTagEnabled={isJobTagEnabled}
                    featureToggleList={featureToggleList}
                    jobDistributionPortals={jobDistributionPortals}
                    onPublishPortal={this.setPublishPortal}
                    distributeJobPortalStatus={distributeJobPortalStatus}
                  />
                </NetworkIssueErrorBoundary>
              </TabPane>
              <TabPane tab="Candidate List" key="candidates">
                {currentJobDetails.JobGuid ? (
                  <NetworkIssueErrorBoundary>{jobCandidateTabOrOrderCanellationContainer}</NetworkIssueErrorBoundary>
                ) : null}
              </TabPane>
              {isJobIntelEnabled || isMarketIntelEnabled ? (
                <TabPane tab={isMarketIntelEnabled ? 'Market Intelligence' : 'Job Intel'} key="intel">
                  <NetworkIssueErrorBoundary>
                    <JobIntelContainer />
                  </NetworkIssueErrorBoundary>
                </TabPane>
              ) : null}
              {!isPulse ? (
                <TabPane tab="Notes" key="notes">
                  {currentJobDetails.JobGuid ? (
                    <NetworkIssueErrorBoundary>
                      <JobNotesContainer jobId={jobId} candidateContext="job" />
                    </NetworkIssueErrorBoundary>
                  ) : null}
                </TabPane>
              ) : null}
            </Tabs>
          </ManualSearchWrapper>
        </Skeleton>
      </div>
    );
  }
}

JobDetailContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({}),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})),
  jobDetails: PropTypes.shape({}),
  changeJobStatus: PropTypes.func.isRequired,
  fetchJobDetails: PropTypes.func.isRequired,
  fetchJobSourcingStats: PropTypes.func.isRequired,
  fetchActivationStatus: PropTypes.func.isRequired,
};

JobDetailContainer.defaultProps = {
  jobStatus: [],
  jobDetails: undefined,
};

export { JobDetailContainer as JobDetailContainerWithoutStore };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobDetailContainer));
