import _ from 'lodash';
import PubSub from 'pubsub-js';
import * as AryaNotifyActions from '../Actions/AryaNotifyActions';
import { fetchConnectStatus } from '../Actions/ConnectStatusActions';
import { fetchConfig } from '../Actions/ConfigActions';
import store from '../store';
import { getConfig } from '../Reducers/ConfigReducer';
import {
  getJobGuidToIdMapping,
  getJobGuidFromJobId,
  getCurrentJobId,
  getJobsById,
  getCurrentJobDetails,
} from '../Reducers/JobReducer';
import {
  getCandidateFetchContactStatus,
  getCandidates,
  getCurrentCandidateId,
  getCandidateDetails,
} from '../Reducers/CandidateReducer';
import * as connectRepository from '../Repository/ConnectRepository';
import * as OrderActions from '../Actions/OrderActions';
import { NOTIFICATION_RECEIVED } from '../PubSub/EventTypes';

import { fetchCandidateDetails, setBulkContactStatus } from '../Actions/CandidateActions';
import {
  updateCandidateRecommendedInfo,
  setCandidatePersonId,
  setCandidateConnectIds,
  setFetchContactStatus,
  addVaultCandidateIds,
  setSourcingNotification,
  setRecommendedStatusChangedNotification,
  setCandidateConnectionStatusByCandidateId,
  setBulkShortlistedNotification,
  setCandidatesPublishedNotification,
  setCandidateDownloadedDetails,
  setCandidateFavouriteStatus,
} from '../Actions/ActionCreators/CandidateActions';
import { fetchCandidateNotes, fetchCallNotes } from '../Actions/CandidateConnectActions';
import * as broadcastEventActions from '../Actions/BroadcastEventActions';
import { setPushCandidateAtsApiStatus } from '../Actions/AtsActions';
import { fetchJobDilatedIntel } from '../Actions/DilatedIntelActions';
import { getIsNotifcationFetchEnabled } from '../Reducers/AryaNotifyReducer';
import { getManualSearchCandidates } from '../Reducers/ManualSearchCandidateReducer';
import * as announcementActionCreator from '../Actions/ActionCreators/AnnouncementActionCreator';

function isCandidateDisplayedInList(currentCandidateId, jobId) {
  const currentStore = store.getState();
  const currentVisibleCandidateIds = Object.keys(getCandidates(currentStore, jobId));
  const manualSearchCandidateIds = Object.keys(getManualSearchCandidates(currentStore, jobId));
  return (
    currentVisibleCandidateIds.includes(currentCandidateId) || manualSearchCandidateIds.includes(currentCandidateId)
  );
}

export const isCandidateExtraInfoExists = currentCandidateId => {
  const currentStore = store.getState();
  const extraInfoCandidateIds = Object.keys(currentStore.CandidateExtraInfoReducer.CandidatesById || {}) || [];
  return extraInfoCandidateIds.includes(currentCandidateId);
};

function fetchConnectStatuses(jobGuid, personIds) {
  return dispatch => {
    return connectRepository.fetchConnectStatuses(jobGuid, personIds).then(connectStatusResponse => {
      dispatch({
        type: 'SET_CANDIDATE_CONNECT_INFO',
        payload: {
          connectInfo: {
            ...connectStatusResponse.data.ConnectStatuses,
          },
        },
      });
    });
  };
}

function getReplaceNotificationAction(source, payload) {
  if ((source || '').toUpperCase() === 'CONNECT' && payload) {
    return {
      type: 'REPLACE_CONNECT_NOTIFICATIONS',
      payload,
    };
    // eslint-disable-next-line no-else-return
  } else if ((source || '').toUpperCase() === 'ARYA' && payload) {
    return {
      type: 'REPLACE_ARYA_NOTIFICATIONS',
      payload,
    };
  }
  return null;
}

function fetchJobsOnEmailNotificationReceive(payload, dispatch) {
  const currentStore = store.getState();
  const notifications = payload.Notifications.filter(notification => notification.Type === 'Email');
  const notificationJobGuids = notifications.map(notification => notification.Payload.RefId);
  const uniqueNotificationJobGuids = [...new Set(notificationJobGuids)];
  const jobGuidToIdMapping = getJobGuidToIdMapping(currentStore);
  const jobGuids = new Set(Object.keys(jobGuidToIdMapping));
  const filteredJobGuids = uniqueNotificationJobGuids.filter(id => !jobGuids.has(id));
  if (filteredJobGuids?.length > 0)
    PubSub.publish(NOTIFICATION_RECEIVED, {
      filteredJobGuids,
      dispatch,
    });
}

function handleCandidateRecommendedNotification(dispatch, eventData) {
  const currentStore = store.getState();
  const currentJobId = getCurrentJobId(currentStore);
  const jobId = _.get(eventData, ['Payload', 'JobId']);
  if (!jobId || !currentJobId) {
    return;
  }
  const candidateId = _.get(eventData, ['Payload', 'CandidateId']);
  const recommendedInfo = _.get(eventData, ['Payload', 'RecommendedInfo'], {});
  if (candidateId && jobId.toString() === currentJobId.toString()) {
    dispatch(updateCandidateRecommendedInfo(candidateId, recommendedInfo, jobId));
  }
}

function handleEmailSentNotificationFromArya(dispatch, eventData) {
  const { Payload: payload } = eventData;
  const { EmailConversation, CandidateMetadata, JobMetadata } = payload;
  const { Id: id } = CandidateMetadata || {};
  const { JobId: jobId } = JobMetadata || {};
  const _store = store.getState();
  const currentJobId = getCurrentJobId(_store);
  if (!id || !jobId || !isCandidateDisplayedInList(id, jobId, false) || currentJobId?.toString() !== jobId?.toString())
    return;
  const _conversationId = _.get(EmailConversation, ['ConversationId']);
  const personId = _.get(CandidateMetadata, ['PersonId']);
  const candidateConnectIds = {
    [CandidateMetadata.Id]: {
      ConversationId: _conversationId,
      PersonId: personId,
    },
  };
  dispatch(setCandidateConnectIds(candidateConnectIds));
  if (_conversationId) {
    dispatch(fetchConnectStatus(_conversationId, personId));
  }
  dispatch({
    type: 'ADD_NEW_MAIL',
    payload: {
      conversationId: _conversationId,
      Mail: EmailConversation,
    },
  });
  dispatch(setCandidateConnectionStatusByCandidateId(id, 'Connected'));
}

function handleEmailEventFromConnect(connection, dispatch, eventData) {
  if (eventData.IsTestEmail) {
    return;
  }
  const _conversationId = eventData.ConversationId;
  const _store = store.getState();
  const targetCandidate = Object.values(_.get(_store, ['CandidateReducer', 'ById'], {})).find(
    candidateDetail => candidateDetail.ConversationId === _conversationId
  );
  const candidateId = targetCandidate?.Id;
  if (candidateId) {
    const _personId = _.get(_store, ['ConnectReducer', 'conversationsById', _conversationId, 'PersonId']);
    const _consentstatus = _.get(_store, [
      'ConnectReducer',
      'ConnectStatuses',
      _personId,
      'SmsStatus',
      'ConsentStatus',
    ]);
    if (_conversationId && (_consentstatus === 'Pending' || !_personId)) {
      dispatch(fetchConnectStatus(_conversationId, _personId));
    }
    dispatch({
      type: 'ADD_NEW_MAIL',
      payload: {
        conversationId: _conversationId,
        Mail: eventData,
      },
    });
    dispatch(setCandidateConnectionStatusByCandidateId(candidateId, 'Connected'));
  }
}

export function handleCandidatePushedEvent(eventData) {
  return dispatch => {
    const { VaultCandidateId, Id, VaultName, IsUnlocked, JobId } = eventData.Payload;
    const state = store.getState();
    const currentJobId = JobId || getCurrentJobId(state);
    if (isCandidateDisplayedInList(Id, currentJobId)) {
      const vaultCandidate = {
        Id,
        VaultCandidateId,
      };
      dispatch(addVaultCandidateIds(VaultName, [vaultCandidate], currentJobId));
      if (JobId) {
        const candidateDetails = getCandidateDetails(state, Id);
        if (IsUnlocked && !candidateDetails.IsUnlocked) {
          const jobDetails = getCurrentJobDetails(state);
          const pushCandidateInfo = { refId: jobDetails?.JobGuid, Country: jobDetails?.CountryCode };
          dispatch(
            fetchCandidateDetails(
              {
                jobId: JobId,
                candidateId: Id,
                Id,
              },
              pushCandidateInfo,
              candidateDetails.IsQuickSearchCandidate,
              false
            )
          );
        }
      }
      dispatch(setPushCandidateAtsApiStatus('COMPLETED'));
    }
  };
}

function handleAnnouncementEvent(dispatch, eventData) {
  const announcement = _.get(eventData, 'Announcement', {});
  if (announcement.Type === 'ReleaseAnnouncement') {
    dispatch(
      announcementActionCreator.setReleaseAnnouncements([
        {
          _id: eventData.AnnouncementId,
          Payload: announcement.Payload,
        },
      ])
    );
  }

  if (announcement.Type === 'DowntimeAnnouncement') {
    dispatch(
      announcementActionCreator.setDowntimeAnnouncements([
        {
          _id: eventData.AnnouncementId,
          Payload: announcement.Payload,
        },
      ])
    );
  }
}

async function handleNotificationEvent(socket, dispatch, eventType, eventData) {
  let jobOrSegmentSharedNotifications;
  let senderIds;
  let replaceNotificationAction;
  let jobId;
  let jobGuid;
  let candidateId;
  let fetchContactStatus;
  let currentJobId;
  let personId;
  let conversationId;
  let countryCode;
  const currentStore = store.getState();
  const isNotifcationFetchEnabled = getIsNotifcationFetchEnabled(currentStore);
  const aryaNotificationTypes = [
    'JobShare',
    'JobUnshare',
    'VaultJobFetched',
    'VaultJobNotFound',
    'Sourcing',
    'DilatedSourcing',
    'CandidatesPublished',
    'OrderPlaced',
    'BulkContactFetched',
    'CreditsExhausted',
    'ReportGenerated',
    'ReportGenerationFailed',
    'FindingCandidates',
    'SegmentShare',
    'SegmentUnshare',
    'PortalCredentialFailed',
  ];
  switch (eventType) {
    case 'notification':
      if (eventData.Source === 'Connect' && ['Email', 'SMS', 'Chat'].includes(eventData.Type)) {
        if (isNotifcationFetchEnabled) {
          socket.emit('GetNotifications', {
            Source: 'Connect',
            Types: ['SMS', 'Chat', 'Email'],
          });
        }
        dispatch({
          type: 'NEW_NOTIFICATION_STATUS',
          payload: {
            senderId: eventData.Payload.SenderId,
            showNotification: true,
            notificationType: 'Connect',
          },
        });
      } else if (eventData.Source === 'Arya') {
        if (aryaNotificationTypes.includes(eventData.Type)) {
          if (isNotifcationFetchEnabled) {
            socket.emit('GetNotifications', {
              Source: 'Arya',
            });
          }
          dispatch({
            type: 'NEW_NOTIFICATION_STATUS',
            payload: {
              senderId: eventData.Payload.SenderId,
              showNotification: true,
              notificationType: 'Arya',
            },
          });
          if (eventData.Type === 'CandidatesPublished') {
            dispatch(OrderActions.fetchJobsOrderDetails({ jobIds: [eventData.Payload.JobId] }));
            dispatch(setCandidatesPublishedNotification(eventData.Payload.JobId));
          }
          if (eventData.Type === 'FindingCandidates') {
            dispatch(OrderActions.fetchJobsOrderDetails({ jobIds: [eventData.Payload.JobId] }));
          }
        }
        if (eventData.Type === 'FeaturesUpdated') {
          await dispatch(fetchConfig());
        }

        if (eventData.Type === 'Sourcing' || eventData.Type === 'DilatedSourcing') {
          dispatch(setSourcingNotification(eventData.Payload.JobId));
          await dispatch(fetchJobDilatedIntel(eventData.Payload.JobId));
        }
        if (eventData.Type === 'BulkShortlisted' || eventData.Type === 'BulkRejected') {
          dispatch(setBulkShortlistedNotification(eventData.Payload.JobId));
        }
        if (eventData.Type === 'BulkContactFetched') {
          ({ JobId: jobId } = eventData.Payload);
          currentJobId = getCurrentJobId(currentStore);
          if (currentJobId && jobId?.toString() === currentJobId.toString()) {
            const currentPageCandidateIds = Object.keys(_.get(currentStore, ['CandidateReducer', 'ById'], {}));
            dispatch(setBulkContactStatus(currentPageCandidateIds));
          }
        }
        if (eventData.Type === 'RecommendedStatusChanged') {
          const { Sourced, Rejected, JobTitle } = eventData.Payload;
          ({ JobId: jobId } = eventData.Payload);
          currentJobId = getCurrentJobId(currentStore);
          if (currentJobId && jobId?.toString() === currentJobId?.toString()) {
            let message;
            if (Rejected) {
              message = `${Rejected} more candidates are rejected for ${JobTitle}`;
            } else if (Sourced) {
              message = `${Sourced} more candidates are unrejected for ${JobTitle}`;
            } else {
              message = 'No similar candidates found';
            }
            dispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                Type: 'SUCCESS',
                Message: message,
                Duration: 5,
              },
            });
            dispatch(setRecommendedStatusChangedNotification(eventData.Payload.JobId));
          }
        }

        if (eventData.Type === 'ContactUnavailable' && isCandidateDisplayedInList(eventData.Payload.CandidateId)) {
          currentJobId = getCurrentJobId(currentStore);
          jobId = eventData.Payload.JobId;
          personId = eventData.Payload.PersonId;
          if (!jobId || !currentJobId) {
            return;
          }
          dispatch(setFetchContactStatus(eventData.Payload.CandidateId, 'Completed', jobId));
          dispatch({
            type: 'SET_CANDIDATE_CONNECT_INFO',
            payload: {
              connectInfo: {
                [personId]: {
                  Contact: {
                    RequestStatus: 'Success',
                    ConsentStatus: 'Pending',
                  },
                },
              },
            },
          });
        }

        if (eventData.Type === 'ContactNotFound' && isCandidateDisplayedInList(eventData.Payload.CandidateId)) {
          const aryaVersion = _.get(getConfig(currentStore), 'SubscriptionType');
          candidateId = eventData.Payload.CandidateId;
          currentJobId = getCurrentJobId(currentStore);
          jobId = eventData.Payload.JobId;
          personId = eventData.Payload.PersonId;
          jobGuid = getJobGuidFromJobId(currentStore, jobId);
          if (candidateId && personId) {
            dispatch(setCandidatePersonId(candidateId, personId, jobId));
          }
          if (personId) await dispatch(fetchConnectStatuses(jobGuid, [personId]));
          dispatch(setFetchContactStatus(candidateId, 'Completed', jobId));
          if (!jobId || !currentJobId) {
            return;
          }
          if (aryaVersion !== 'Lite' && eventData.Payload.Action === 'CANDIDATE_PUSH') {
            const message = 'Push candidate failed as specified requirement did not match';
            dispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                Type: 'ERROR',
                Message: message,
                Duration: 5,
              },
            });
            dispatch(setPushCandidateAtsApiStatus('COMPLETED'));
          } else if (eventData.Payload.Action === 'CONTACT_FETCH' && aryaVersion === 'Lite') {
            personId = eventData.Payload.PersonId;
            candidateId = eventData.Payload.Id;
            jobId = eventData.Payload.JobId;
            jobGuid = getJobGuidFromJobId(currentStore, jobId);
            fetchContactStatus = getCandidateFetchContactStatus(currentStore, candidateId);
            if (fetchContactStatus === 'Completed' || fetchContactStatus === 'Failed') {
              return;
            }
            if (candidateId && jobId.toString() === currentJobId.toString()) {
              dispatch(setCandidatePersonId(candidateId, personId, jobId));
              dispatch(fetchConnectStatuses(jobGuid, [personId])).then(() => {
                dispatch(setFetchContactStatus(candidateId, 'Failed', jobId));
              });
            }
          }
        }
        if (
          eventData.Type === 'RecommendedInfoUpdated' &&
          (isCandidateDisplayedInList(
            _.get(eventData, ['Payload', 'CandidateId']),
            _.get(eventData, ['Payload', 'JobId'])
          ) ||
            isCandidateExtraInfoExists(_.get(eventData, ['Payload', 'CandidateId'])))
        ) {
          handleCandidateRecommendedNotification(dispatch, eventData);
        }
        if (
          eventData.Type === 'BookmarkInfoUpdated' &&
          (isCandidateDisplayedInList(
            _.get(eventData, ['Payload', 'CandidateIds'])[0],
            _.get(eventData, ['Payload', 'JobId'])
          ) ||
            isCandidateExtraInfoExists(_.get(eventData, ['Payload', 'CandidateIds'])[0]))
        ) {
          const { JobId, IsFavourite } = eventData.Payload;
          const _candidateId = _.get(eventData, ['Payload', 'CandidateIds'])[0];
          dispatch(setCandidateFavouriteStatus({ JobId, candidateId: _candidateId, isFavourite: IsFavourite }));
        }
        if (eventData.Type === 'CandidatePushed') {
          dispatch(handleCandidatePushedEvent(eventData));
        }
        if (eventData.Type === 'BulkCandidatesPushed') {
          const { VaultName, VaultCandidates, JobId } = eventData.Payload;
          dispatch(addVaultCandidateIds(VaultName, VaultCandidates, JobId));
        }
        if (eventData.Type === 'EmailSent') {
          handleEmailSentNotificationFromArya(dispatch, eventData);
        }
        if (eventData.Type === 'ContactFetched' && isCandidateDisplayedInList(_.get(eventData, ['Payload', 'Id']))) {
          personId = eventData.Payload.PersonId;
          jobId = eventData.Payload.JobId;
          jobGuid = getJobGuidFromJobId(currentStore, jobId);
          conversationId = eventData.Payload.ConversationId;
          currentJobId = getCurrentJobId(currentStore);
          candidateId = eventData.Payload.Id;
          fetchContactStatus = getCandidateFetchContactStatus(currentStore, candidateId);
          jobId = eventData.Payload.JobId;

          if (!jobId || !currentJobId) {
            return;
          }
          if (jobId.toString() !== currentJobId.toString()) {
            return;
          }
          if (candidateId && personId) {
            dispatch(setCandidatePersonId(candidateId, personId, jobId));
            dispatch(setCandidateDownloadedDetails(candidateId, true, undefined, jobId));
          }
          if ((conversationId && candidateId) || (jobGuid && personId)) {
            try {
              if (conversationId) await dispatch(fetchConnectStatus(conversationId, personId));
              else await dispatch(fetchConnectStatuses(jobGuid, [personId]));
              dispatch(setFetchContactStatus(candidateId, 'Completed', jobId));
            } catch {
              dispatch(setFetchContactStatus(candidateId, 'Failed', jobId));
            }
          }
        }
        if (
          (eventData.Type === 'NotesUpdated' || eventData.Type === 'CallNotesUpdated') &&
          isCandidateDisplayedInList(eventData.Payload.CandidateId)
        ) {
          jobId = eventData.Payload.JobId;
          conversationId = eventData.Payload.ConversationId;
          currentJobId = getCurrentJobId(currentStore);
          candidateId = eventData.Payload.CandidateId;
          if (!jobId || !currentJobId) {
            return;
          }
          if (jobId.toString() !== currentJobId.toString()) {
            return;
          }
          const fetchNotesPayload = {
            candidateId: eventData.Payload.CandidateId,
            jobId: eventData.Payload.JobId,
          };
          if (eventData.Type === 'NotesUpdated') dispatch(fetchCandidateNotes(fetchNotesPayload));
          if (eventData.Type === 'CallNotesUpdated' && conversationId)
            dispatch(fetchCallNotes(candidateId, conversationId, 0, -1));
        }
        if (
          eventData.Type?.toLowerCase() === 'resumegenerated' &&
          eventData.Payload.CandidateId === getCurrentCandidateId(currentStore)
        ) {
          currentJobId = getCurrentJobId(currentStore);
          const jobsById = getJobsById(currentStore);
          jobGuid = currentJobId ? jobsById[currentJobId].JobGuid : null;
          countryCode = currentJobId ? jobsById[currentJobId].CountryCode : null;
          const filter = {
            candidateId: eventData.Payload.CandidateId,
            jobId: currentJobId,
          };
          dispatch(fetchCandidateDetails(filter, { refId: jobGuid, Country: countryCode }));
        }
        if (eventData.Type === 'ResumeUnlocked' && eventData.Payload.Id === getCurrentCandidateId(currentStore)) {
          jobId = eventData.Payload.JobId;
          candidateId = eventData.Payload.Id;
          countryCode = eventData.Payload.CountryCode;
          currentJobId = getCurrentJobId(currentStore);
          if (!jobId || !currentJobId) {
            return;
          }
          if (jobId.toString() !== currentJobId.toString()) {
            return;
          }
          const unlockResumePayload = {
            candidateId,
            jobId,
            countryCode,
          };
          dispatch(broadcastEventActions.unlockCandidateResume(unlockResumePayload));
        }
      }
      break;
    case 'GetNotificationsResponse':
      replaceNotificationAction = getReplaceNotificationAction(eventData.Source, eventData.Response);
      if (eventData.Source?.toUpperCase() === 'CONNECT' && eventData.Response) {
        fetchJobsOnEmailNotificationReceive(eventData.Response, dispatch);
      }
      if (replaceNotificationAction) {
        dispatch(replaceNotificationAction);
      }
      jobOrSegmentSharedNotifications = eventData.Response.Notifications.filter(
        notification => notification.Type === 'JobShare' || notification.Type === 'SegmentShare'
      );
      senderIds = jobOrSegmentSharedNotifications.map(notification => notification.SenderId);
      if (senderIds.length) {
        dispatch(AryaNotifyActions.fetchUsersDetails(senderIds));
      }
      break;
    case 'NotificationsRead':
      replaceNotificationAction = getReplaceNotificationAction(eventData.Source);
      if (replaceNotificationAction) {
        dispatch(replaceNotificationAction);
      }
      break;
    case 'NotificationRead':
      dispatch({
        type: 'MARK_NOTIFICATION_READ',
        payload: {
          NotificationId: eventData.NotificationId,
          notificationType: eventData.Source,
        },
      });
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled notification event', eventData);
      break;
  }
}

function handleChatEvent(connection, dispatch, eventType, eventData) {
  if (eventData.IsTestMessage) {
    return;
  }
  const _conversationId = eventData.ConversationId;
  const _store = store.getState();
  const _personId = _.get(_store, ['ConnectReducer', 'conversationsById', _conversationId, 'PersonId'], undefined);
  const _consentstatus = _.get(
    _store,
    ['ConnectReducer', 'ConnectStatuses', _personId, 'SmsStatus', 'ConsentStatus'],
    undefined
  );
  const currentJobId = getCurrentJobId(_store);
  const targetCandidate = Object.values(_.get(_store, ['CandidateReducer', 'ById'], {})).find(
    candidateDetail => candidateDetail.ConversationId === _conversationId
  );
  const candidateId = targetCandidate?.Id;
  if (!isCandidateDisplayedInList(candidateId, currentJobId)) {
    return;
  }
  switch (eventType) {
    case 'chat':
      dispatch({
        type: 'ADD_CHAT_MESSAGE',
        payload: {
          conversationId: _conversationId,
          Message: eventData,
        },
      });
      if (candidateId) {
        dispatch(setCandidateConnectionStatusByCandidateId(candidateId, 'Connected'));
      }
      break;
    case 'sms':
      if (_conversationId && (_consentstatus === 'Pending' || !_personId)) {
        dispatch(fetchConnectStatus(_conversationId, _personId));
      }
      dispatch({
        type: 'ADD_NEW_MESSAGE',
        payload: {
          conversationId: _conversationId,
          Message: eventData,
        },
      });
      if (candidateId) {
        dispatch(setCandidateConnectionStatusByCandidateId(candidateId, 'Connected'));
      }
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled notification event', eventData);
      break;
  }
}

function handleEmailEvent(connection, dispatch, eventData) {
  if (eventData.IsTestEmail) {
    return;
  }
  const _conversationId = eventData.ConversationId;
  const _store = store.getState();
  const _personId = _.get(_store, ['ConnectReducer', 'conversationsById', _conversationId, 'PersonId'], undefined);
  const _consentstatus = _.get(
    _store,
    ['ConnectReducer', 'ConnectStatuses', _personId, 'SmsStatus', 'ConsentStatus'],
    undefined
  );
  if (_conversationId && (_consentstatus === 'Pending' || !_personId)) {
    dispatch(fetchConnectStatus(_conversationId, _personId));
  }
  dispatch({
    type: 'ADD_NEW_MAIL',
    payload: {
      conversationId: _conversationId,
      Mail: eventData,
    },
  });
}

export default {
  handleAnnouncementEvent,
  handleNotificationEvent,
  handleChatEvent,
  handleEmailEvent,
  handleEmailSentNotificationFromArya,
  handleEmailEventFromConnect,
};
