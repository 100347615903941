import React from 'react';
import { Form, Checkbox } from 'antd';
import LocationContext from '../LocationContext';

const FormItem = Form.Item;

const RemoteJob = props => {
  const { initialValue, label, setValidationStatus, isAdvanceSearchV2 = false } = props;
  const { form } = React.useContext(LocationContext);

  const onCheckboxChange = e => {
    if (isAdvanceSearchV2) {
      setValidationStatus(e.target.checked, null);
    }
  };

  return (
    <FormItem label={label} colon={false}>
      {form.getFieldDecorator('IsRemoteJob', {
        valuePropName: 'checked',
        initialValue,
      })(<Checkbox onChange={onCheckboxChange} />)}
    </FormItem>
  );
};

export default RemoteJob;
