import PropTypes from 'prop-types';
import _ from 'lodash';
import cleanSet from 'clean-set';
import { createSelector } from 'reselect';
import { klona as cloneDeep } from 'klona';
import { getSourceName } from '../Utils/SourceUtils';

import {
  SET_DISTRIBUTE_JOB_API_STATUS,
  SET_JOB_DISTRIBUTION_STATUS,
  SET_MUST_HAVES_FOR_JOB,
  SET_ADVANCED_JOB_FORM_DATA,
  UPDATE_FAVOURITE_AGGREGATION,
  SET_SHOW_CURRENT_USER_CANDIDATE_VIEWED_FLAG,
} from '../Actions/ActionCreators/JobActionCreator';
import {
  SET_JOB_NOTES,
  CREATE_JOB_NOTE,
  DELETE_JOB_NOTE,
  UPDATE_JOB_NOTE,
  ADD_JOB_NOTE_TAGS,
  DELETE_JOB_NOTE_TAG,
} from '../Actions/ActionCreators/NoteActionCreator';
import { SET_CANCEL_ORDER_API_STATUS, SET_UPDATE_ORDER_API_STATUS } from '../Actions/ActionCreators/OrderActionCreator';
import { SET_JOB_PUBLISH_HISTORY } from '../Actions/ActionCreators/JobDistributionActionCreator';
import { generateArrayFromBooleanString } from '../Utils/BooleanStringUtility';

const emptyObject = {};
const emptyList = [];

const initialState = {
  JobGuidToIdMapping: {},
  SegmentJobGuidToIdMapping: {},
  ById: {},
  segments: {},
  SourcingConfiguration: {},
  currentJobId: null,
};

function jobDetailsMergeCustomizer(objValue, srcValue, key) {
  if (key === 'SourcingLocations') {
    return srcValue?.length ? srcValue : objValue;
  }
  return undefined;
}

function JobReducer(state = initialState, action) {
  let index;
  let newState;
  let jobIds;
  let ById;
  let ListByStatus;
  let newAryaState = {};
  let id;
  let status;
  let existingJobIds = [];
  let existingCandidates = [];
  let params = {};
  let keywordParams = {};
  let sourceName;
  let jobIntel;
  let keywords;
  let keywordSynonyms;
  let sourceLimit;
  let jobConfig;
  let automationConfig;
  let autoRejectConfig;
  let extraTags;
  let newCount;
  let newTags;
  const keywordsDict = {};
  let mustHaves = [];
  const niceToHaves = [];
  const relevants = [];
  let candidateCountBySource;
  let jobDistributionStatuses;
  let emailStats;
  let dilatedStatuses = [];
  let bulkJobTags;
  let noteIds;
  let favouriteIndex;
  let nonFavouriteIndex;
  let temparray;

  switch (action.type) {
    case 'SET_CURRENT_JOB_ID':
      newState = cleanSet(state, 'currentJobId', action.payload);
      return newState;
    case 'SET_CONNECT_JOBS': {
      const newJobsById = {};
      action.payload.jobs.forEach(job => {
        newJobsById[job.JobId] = job;
      });
      jobIds = action.payload.jobs.map(job => {
        return job.JobId;
      });
      newState = cleanSet(state, 'ById', { ...state.ById, ...newJobsById });
      newState = cleanSet(newState, ['ListByStatus', action.payload.connectStatus], jobIds);
      return newState;
    }
    case 'APPEND_CONNECT_JOBS': {
      const newJobsById = {};
      action.payload.jobs.forEach(job => {
        newJobsById[job.JobId] = job;
      });
      jobIds = action.payload.jobs.map(job => {
        return job.JobId;
      });
      existingJobIds = _.get(state, ['ListByStatus', action.payload.connectStatus], []);
      newState = cleanSet(state, 'ById', { ...state.ById, ...newJobsById });
      newState = cleanSet(newState, ['ListByStatus', action.payload.connectStatus], [...existingJobIds, ...jobIds]);
      return newState;
    }
    case 'SET_JOB_GUID_TO_ID_MAPPING':
      return {
        ...state,
        JobGuidToIdMapping: _.merge(state.JobGuidToIdMapping, action.payload),
      };
    case 'SET_SEGMENT_JOB_GUID_TO_ID_MAPPING':
      return {
        ...state,
        SegmentJobGuidToIdMapping: _.merge(state.SegmentJobGuidToIdMapping, action.payload),
      };
    case 'SET_JOB_LIST':
      ById = {
        ...state.ById,
      };
      ListByStatus = {
        ...state.ListByStatus,
      };
      jobIds = action.payload.jobs.map(job => job.JobId);
      for (index = 0; index < jobIds.length; index += 1) {
        ById[jobIds[index]] = {
          ..._.get(ById, jobIds[index], {}),
          ...action.payload.jobs[index],
        };
      }

      ListByStatus[action.payload.statusId] = [...jobIds];
      return {
        ...state,
        ListByStatus,
        ById: {
          ...ById,
        },
      };

    case SET_JOB_NOTES: {
      noteIds = action.payload.notes.map(note => note.Id);
      let notes = _.get(state, ['ById', action.payload.jobId, 'NotesById'], {});
      for (index = 0; index < noteIds.length; index += 1) {
        notes = cleanSet(notes, [noteIds[index]], {
          ..._.get(notes, [noteIds[index]], {}),
          ...action.payload.notes[index],
        });
      }
      newState = cleanSet(state, ['ById', action.payload.jobId, 'NotesById'], notes);
      return newState;
    }

    case ADD_JOB_NOTE_TAGS:
      newState = cleanSet(
        state,
        ['ById', action.payload.jobId, 'NotesById', action.payload.noteId, 'Tags'],
        [
          ..._.get(state, ['ById', action.payload.jobId, 'NotesById', action.payload.noteId, 'Tags'], []),
          ...action.payload.tags,
        ]
      );
      return newState;

    case DELETE_JOB_NOTE_TAG: {
      const noteTags = [
        ..._.get(state, ['ById', action.payload.jobId, 'NotesById', action.payload.noteId, 'Tags'], []),
      ];
      _.remove(noteTags, {
        AssociationId: action.payload.tagAssociationId,
      });
      newState = cleanSet(state, ['ById', action.payload.jobId, 'NotesById', action.payload.noteId, 'Tags'], noteTags);
      return newState;
    }

    case CREATE_JOB_NOTE:
    case UPDATE_JOB_NOTE:
      newState = cleanSet(
        state,
        ['ById', action.payload.jobId, 'NotesById', action.payload.note.Id],
        action.payload.note
      );
      return newState;

    case DELETE_JOB_NOTE:
      newState = cleanSet(state, ['ById', action.payload.jobId, 'NotesById', action.payload.noteId], null);
      _.unset(newState, ['ById', action.payload.jobId, 'NotesById', action.payload.noteId]);
      return newState;

    case 'SET_JOB_COUNT':
      newState = cleanSet(state, 'CountByStatus', {
        [action.payload.status]: action.payload.count,
      });
      return newState;
    case 'FETCH_SEGMENT_COUNT':
      newState = cleanSet(state, 'FetchSegmentCount', action.payload.total);
      return newState;
    case 'SET_JOB_EMAIL_STATS':
      return {
        ...state,
        jobEmailStats: action.payload,
      };
    case 'SET_JOB_SOURCING_STATS':
      newState = { ...state };
      jobIds = Object.keys(action.payload);
      jobIds.forEach(jobId => {
        const sourcingStats = cloneDeep(_.get(state, ['ById', jobId, 'sourcingStats'], {}));
        newState = cleanSet(newState, ['ById', jobId, 'sourcingStats'], _.merge(sourcingStats, action.payload[jobId]));
      });
      return newState;
    case 'SET_JOB_ACTIVATION_STATUS':
      return {
        ...state,
        jobActivationStatus: action.payload,
      };
    case 'CHANGE_JOB_STATUS': {
      const job = {
        ...state.ById[action.payload.jobId],
        StatusId: action.payload.status.Id,
        Status: action.payload.status.Name,
        AryaStatus: action.payload.status.AryaStatus,
      };
      newState = cleanSet(state, ['ById', action.payload.jobId], job);
      return newState;
    }
    case 'SET_JOB_DETAILS': {
      id = action.payload.JobId;
      let job = cloneDeep(state.ById[id] ?? {});
      job = _.mergeWith(job, action.payload, jobDetailsMergeCustomizer);
      params = action.payload.quickSearchParams || job.quickSearchParams || {};
      if (action.payload.SharedWith) {
        job.SharedWith = action.payload.SharedWith;
      }
      keywordParams = params.Keywords;
      if (keywordParams && typeof keywordParams.Prefers === 'string') {
        keywordParams.Prefers = generateArrayFromBooleanString(keywordParams.Prefers)?.[0];
      }
      if (keywordParams) {
        params.Keywords = keywordParams;
      }
      job.quickSearchParams = params;
      newState = cleanSet(state, ['ById', id], job);
      return newState;
    }
    case 'SET_CANDIDATE_JOB_DETAILS':
      if (action.payload.fetchType === 'ON_LOAD') {
        existingCandidates = _.get(state, ['ById', action.payload.jobId, 'Candidates'], []);
      }
      newState = cleanSet(state, ['ById', action.payload.jobId], {
        ...state.ById[action.payload.jobId],
        Candidates: [...existingCandidates, ...action.payload.candidates],
      });
      return newState;
    case 'SET_JOB_ORDER_DETAILS':
      newState = { ...state };
      jobIds = Object.keys(action.payload);
      jobIds.forEach(jobId => {
        const orderInfo = cloneDeep(_.get(newState, ['ById', jobId, 'OrderInfo'], {}));
        newState = cleanSet(newState, ['ById', jobId, 'OrderInfo'], _.merge(orderInfo, action.payload[jobId][0]));
      });
      return newState;
    case 'SET_CANDIDATE_AGGS':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'CandidateAggs'], action.payload.aggs);
      return newState;

    case UPDATE_FAVOURITE_AGGREGATION:
      newState = { ...state };
      temparray = _.get(state, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], []);
      favouriteIndex = _.findIndex(_.get(state, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], []), {
        Value: '1',
      });
      if (favouriteIndex === -1) {
        temparray = temparray.concat({
          Value: '1',
          Count: 0,
        });
        newState = cleanSet(newState, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], temparray);
        favouriteIndex = _.findIndex(
          _.get(newState, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], []),
          {
            Value: '1',
          }
        );
      }
      nonFavouriteIndex = _.findIndex(
        _.get(state, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], []),
        {
          Value: '0',
        }
      );
      if (nonFavouriteIndex === -1) {
        temparray = temparray.concat({ Value: '0', Count: 0 });
        newState = cleanSet(newState, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], temparray);
        nonFavouriteIndex = _.findIndex(
          _.get(newState, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite'], []),
          {
            Value: '0',
          }
        );
      }
      if (action.payload.isFavourite) {
        newState = cleanSet(
          newState,
          ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', nonFavouriteIndex, 'Count'],
          _.get(
            newState,
            ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', nonFavouriteIndex, 'Count'],
            0
          ) - 1
        );
        if (favouriteIndex !== -1)
          newState = cleanSet(
            newState,
            ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', favouriteIndex, 'Count'],
            _.get(
              newState,
              ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', favouriteIndex, 'Count'],
              0
            ) + 1
          );
      } else {
        newState = cleanSet(
          newState,
          ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', favouriteIndex, 'Count'],
          _.get(newState, ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', favouriteIndex, 'Count'], 0) -
            1
        );
        if (nonFavouriteIndex !== -1)
          newState = cleanSet(
            newState,
            ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', nonFavouriteIndex, 'Count'],
            _.get(
              newState,
              ['ById', action.payload.jobId, 'CandidateAggs', 'IsFavourite', nonFavouriteIndex, 'Count'],
              0
            ) + 1
          );
      }

      return newState;

    case 'SET_ACTIVATION_STATES':
      newState = { ...state };
      Object.keys(action.payload).forEach(jobId => {
        let job = newState.ById?.[jobId];
        newAryaState = {};
        action.payload[jobId].Sources.forEach(source => {
          sourceName = getSourceName(source.Configuration.Source, false);
          if (!newAryaState[sourceName.toLowerCase()])
            newAryaState[sourceName.toLowerCase()] = {
              ...source.Configuration,
              IsActivated: source.IsActivated,
            };
        });
        if (!job) {
          job = {
            aryaState: newAryaState,
            recentSourcedTime: action.payload[jobId].RecentSourcedTime,
            lastChangeTime: action.payload[jobId].LastChangeTime,
          };
        } else {
          job = {
            ...job,
            aryaState: newAryaState,
            recentSourcedTime: action.payload[jobId].RecentSourcedTime,
            lastChangeTime: action.payload[jobId].LastChangeTime,
          };
        }
        newState = cleanSet(newState, ['ById', jobId], job);
      });
      return newState;
    case 'SET_FINAL_COMMIT_STATUS':
      newState = cleanSet(
        state,
        [ById, action.payload.jobId, 'OrderInfo', 'IsFinalCommit'],
        action.payload.IsFinalCommit
      );
      return newState;

    case 'UPDATE_ACTIVATION_STATES':
      newAryaState = state.ById[action.payload.jobId].aryaState ?? {};
      action.payload.sources.forEach(source => {
        sourceName = getSourceName(source.Source).toLowerCase();
        if (_.get(newAryaState, [sourceName], false)) {
          newAryaState = cleanSet(newAryaState, [sourceName, 'IsActivated'], source.IsActivated);
        }
      });
      newState = cleanSet(state, ['ById', action.payload.jobId, 'aryaState'], newAryaState);
      return newState;

    case 'SET_CANDIDATE_IDS':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'CandidateIds'], action.payload.ids);
      return newState;

    case 'SET_CANDIDATE_COUNT_BY_SOURCE': {
      candidateCountBySource = _.get(action, ['payload', 'candidateCountBySource'], {});
      if (action.payload.shouldAppendCandidateCount) {
        const exisistingCandidateCountBySource = _.get(
          state,
          ['ById', action.payload.jobId, 'candidateCountBySource'],
          {}
        );
        candidateCountBySource = { ...exisistingCandidateCountBySource, ...candidateCountBySource };
        newState = cleanSet(state, ['ById', action.payload.jobId, 'candidateCountBySource'], candidateCountBySource);
        return newState;
      }
      newState = cleanSet(state, ['ById', action.payload.jobId, 'candidateCountBySource'], candidateCountBySource);
      return newState;
    }
    case 'SET_INITIAL_CANDIDATE_COUNT_BY_SOURCE': {
      const initialCandidateCountBySource = _.get(action, ['payload', 'initialCandidateCountBySource'], {});
      const { shouldAppendCandidateCount } = action.payload;
      if (shouldAppendCandidateCount) {
        const exisistingInitialCandidateCountBySource = _.get(
          state,
          ['ById', action.payload.jobId, 'initialCandidateCountBySource'],
          {}
        );
        const updatedInitialCandidateCountBySource = {
          ...exisistingInitialCandidateCountBySource,
          ...initialCandidateCountBySource,
        };
        newState = cleanSet(
          state,
          ['ById', action.payload.jobId, 'initialCandidateCountBySource'],
          updatedInitialCandidateCountBySource
        );
        return newState;
      }
      newState = cleanSet(
        state,
        ['ById', action.payload.jobId, 'initialCandidateCountBySource'],
        initialCandidateCountBySource
      );
      return newState;
    }
    case 'SET_TITLE_SYNONYMS':
      newState = cleanSet(state, 'utilities.titleSynonyms', action.payload);
      return newState;

    case 'SET_SKILLS':
      newState = cleanSet(state, 'utilities.skills', action.payload?.skills ?? []);
      return newState;

    case 'SET_TITLES':
      newState = cleanSet(state, 'utilities.titles', action.payload?.titles ?? []);
      return newState;

    case 'SET_TAGS_FOR_JOB_FORM':
      extraTags = action.payload?.Tags ?? [];
      newCount = action.payload?.Total ?? 0;
      newTags = _.get(state, ['utilities', 'tags', 'Tags'], []).concat(extraTags);
      newState = cleanSet(state, ['utilities', 'tags'], {
        Tags: _.uniqBy(newTags, 'Id'),
        Total: newCount,
      });
      return newState;

    case 'UPDATE_TOTAL_TAGS_COUNT': {
      newCount = action.payload;
      newState = cleanSet(state, ['utilities', 'tags', 'Total'], newCount);
      return newState;
    }

    case 'SET_COUNTRIES':
      newState = cleanSet(state, 'utilities.countries', action.payload);
      return newState;

    case 'SET_COUNTRY_DETAILS_FOR_JOB':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'countryInfo'], action.payload.countryInfo);
      return newState;

    case SET_DISTRIBUTE_JOB_API_STATUS:
      newState = cleanSet(state, ['ById', action.payload.jobId, 'distributeJobApiStatus'], action.payload.status);
      return newState;

    case 'SET_LOCATIONS':
      newState = cleanSet(state, 'utilities.locations', action.payload);
      return newState;

    case 'SET_ZIP_CODES':
      newState = cleanSet(state, 'utilities.zipCodes', action.payload);
      return newState;

    case 'SET_MANUAL_ZIP_CODE':
      newState = cleanSet(state, 'utilities.manualLocation', action.payload);
      return newState;

    case 'SET_ZIP_CODE_STATUS':
      newState = cleanSet(state, 'utilities.isZipCodeInValid', action.payload);
      return newState;

    case 'SET_CLIENTS': {
      let clients = cloneDeep(_.get(state, 'utilities.clients'));
      if (!clients || clients?.searchTerm !== action.payload.searchTerm) {
        clients = {
          clients: [],
          total: 0,
        };
      }
      clients.clients = _.uniqBy([...clients.clients, ...action.payload.clients], 'Id');
      clients.total = action.payload.total;
      clients.searchTerm = action.payload.searchTerm;
      newState = cleanSet(state, 'utilities.clients', clients);
      return newState;
    }

    case 'SET_INDUSTRIES':
      newState = cleanSet(state, 'utilities.industries', action.payload);
      return newState;
    case 'SET_OCCUPATIONS':
      newState = cleanSet(state, 'utilities.occupations', action.payload);
      return newState;
    case 'SET_JOB_INTEL':
      jobIntel = cloneDeep(action.payload);

      if (jobIntel.Keywords) {
        jobIntel.SkillSet = {};
        keywords = jobIntel.Keywords || [];
        keywordSynonyms = jobIntel.KeywordSynonyms || [];

        keywords.forEach(keyword => {
          keywordsDict[keyword.Name] = keyword;
          if (keyword.Rank === 1) {
            mustHaves.push(keyword);
          } else if (keyword.Rank === 2) {
            relevants.push(keyword);
          } else if (keyword.Rank === 3) {
            niceToHaves.push(keyword);
          }
        });

        keywordSynonyms.forEach(keywordSynonym => {
          const rank = keywordsDict[keywordSynonym.KeywordName].Rank;
          if (rank === 1) {
            mustHaves.push(keywordSynonym);
          } else if (rank === 2) {
            relevants.push(keywordSynonym);
          } else if (rank === 3) {
            niceToHaves.push(keywordSynonym);
          }
        });

        jobIntel.SkillSet.MustHaves = _.uniqBy(mustHaves, 'Name');
        jobIntel.SkillSet.NiceToHaves = _.uniqBy(niceToHaves, 'Name');
        jobIntel.SkillSet.Relevants = _.uniqBy(relevants, 'Name');
      }

      return {
        ...state,
        jobIntel,
      };

    case 'SET_JOB_INTEL_LOCATION_DATA':
      return {
        ...state,
        jobIntelLocationData: action.payload,
      };

    case 'UPDATE_JOB_PUT':
      newState = cleanSet(state, ['ById', action.payload.JobId], action.payload);
      return newState;

    case 'CREATE_JOB':
    case 'DUPLICATE_JOB':
      newState = cleanSet(state, ['ById', action.payload.JobId], action.payload);
      return newState;
    case 'SET_JOB_SHARED_WITH': {
      const jobDetails = state.ById[action.jobId];
      const newJobDetails = {
        ...jobDetails,
        SharedWith: action.payload,
        AssignedTo: action.payload,
      };
      newState = cleanSet(state, ['ById', action.jobId], newJobDetails);
      return newState;
    }
    case 'UPDATE_SOURCING_STATS_COUNTS':
      status = action.payload.status[0].toUpperCase() + action.payload.status.slice(1);
      newState = cleanSet(state, ['ById', action.payload.JobId, 'sourcingStats', status], action.payload.count);
      return newState;
    case 'SET_RESUMES_FOR_JOB':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'Resumes'], action.payload.resumes);
      return newState;
    case 'ADD_RESUME_FOR_JOB':
      newState = cleanSet(
        state,
        ['ById', action.payload.jobId, 'Resumes'],
        state.ById[action.payload.jobId].Resumes.concat(action.payload.resume)
      );
      return newState;
    case 'DELETE_RESUMES_FOR_JOB': {
      const resumes = cloneDeep(state.ById[action.payload.jobId]?.Resumes ?? []);
      action.payload.resumeIds.forEach(resumeId => {
        index = resumes.findIndex(resume => resume.Id === resumeId);
        if (index !== -1) {
          resumes.splice(index, 1);
        }
      });
      newState = cleanSet(state, ['ById', action.payload.jobId, 'Resumes'], resumes);
      return newState;
    }
    case 'SET_JOB_CONFIG':
      jobConfig = _.get(state, ['ById', action.payload.jobId], {});
      jobConfig = {
        ...jobConfig,
        ...action.payload.data,
      };
      newState = cleanSet(state, ['ById', action.payload.jobId], jobConfig);
      return newState;
    case 'UPDATE_JOB_CONFIG': {
      let job = _.get(state, ['ById', action.payload.jobId]) ?? emptyObject;
      sourceLimit = _.get(action.payload, ['jobConfig', 'SourcingConfiguration', 'SourceLimit']);
      if (sourceLimit) {
        job = cleanSet(job, ['SourcingConfiguration', 'SourceLimit'], sourceLimit);
      }
      automationConfig = _.get(action.payload, ['jobConfig', 'AutomationConfiguration'], false);
      if (automationConfig) {
        job = cleanSet(job, ['AutomationConfiguration'], automationConfig);
      }
      autoRejectConfig = _.get(action.payload, ['jobConfig', 'AryaRejectionConfiguration']);
      if (autoRejectConfig) {
        job = cleanSet(job, ['AryaRejectionConfiguration'], autoRejectConfig);
      }
      newState = cleanSet(state, ['ById', action.payload.jobId], job);
      return newState;
    }
    case 'SET_UPDATE_JOB_CONFIG_API_STATUS':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'updateJobConfigApiStatus'], action.payload.status);
      return newState;
    case 'SET_ACTIVATION_STATE_CHANGED':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'activationStateChanged'], action.payload.status);
      return newState;
    case 'SET_TAGS_FOR_JOB_LIST':
      newState = cleanSet(state, 'Tags', action.payload);
      return newState;
    case 'TOGGLE_JOB_CARD':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'IsTagSelected'], action.payload.makeTagSelected);
      return newState;
    case 'TOGGLE_ALL_JOB_CARDS':
      newState = { ...state };
      Object.keys(newState.ById).forEach(jobId => {
        newState = cleanSet(newState, ['ById', jobId, 'IsTagSelected'], action.payload.isTagSelected);
      });
      return newState;
    case 'UPDATE_FILTER_TAGS':
      return {
        ...state,
        SelectedTags: action.payload.selectedTags,
      };
    case SET_JOB_DISTRIBUTION_STATUS:
      id = _.get(action, ['payload', 'jobId'], null);
      jobDistributionStatuses = _.get(action, ['payload', 'statuses'], {});
      newState = cleanSet(state, ['ById', id, 'JobDistributionStatuses'], jobDistributionStatuses);
      return newState;

    case SET_CANCEL_ORDER_API_STATUS:
      id = _.get(action, ['payload', 'jobId'], null);
      status = _.get(action, ['payload', 'status'], null);
      newState = cleanSet(state, ['ById', id, 'OrderInfo', 'cancelOrderApiStatus'], status);
      return newState;

    case SET_UPDATE_ORDER_API_STATUS:
      id = _.get(action, ['payload', 'jobId'], null);
      status = _.get(action, ['payload', 'status'], null);
      newState = cleanSet(state, ['ById', id, 'OrderInfo', 'updateOrderApiStatus'], status, Object);
      return newState;

    case 'SET_JOB_ORDER_STATUS':
      id = _.get(action, ['payload', 'jobId'], null);
      status = _.get(action, ['payload', 'status'], null);
      newState = cleanSet(state, ['ById', id, 'OrderInfo', 'Status'], status);
      return newState;
    case 'SET_BOT_CONTEXT_ID':
      newState = cleanSet(state, ['ById', action.payload.JobId, 'BotContextId'], action.payload.BotContextId);
      return newState;

    case 'SET_EMAIL_STATS':
      emailStats = _.get(action.payload, 'emailStats', emptyList);
      newState = cleanSet(state, ['ById', action.payload.jobId, 'EmailStats'], emailStats);
      return newState;

    case 'SET_JOB_LIST_PAGE_SIZE':
      newState = cleanSet(state, 'JobListPageSize', action.payload);
      return newState;
    case SET_ADVANCED_JOB_FORM_DATA:
      return {
        ...state,
        advancedJobFormData: action.payload,
      };

    case 'SET_JOB_LIST_PAGE_NUMBER':
      newState = cleanSet(state, 'JobListPageNumber', action.payload);
      return newState;
      case 'SET_JOB_LIST_SEARCH_TERM': {
        const { candidateContext, searchTerm } = action.payload;
        newState = cleanSet(state, ['JobListSearchTerm', candidateContext], searchTerm);
        return newState;
      }

    case 'SET_DEFAULT_HOME_PAGE_RENDER_FLAG':
      newState = cleanSet(state, 'DefaultHomePageRenderFlag', action.payload);
      return newState;

    case 'SET_JOB_LIST_SELECTED_USER':
      newState = cleanSet(state, 'JobListSelectedUser', action.payload);
      return newState;

    case 'SET_JOB_LIST_TAB_NAME':
      newState = cleanSet(state, 'JobListTabName', action.payload);
      return newState;

    case 'SET_DILATED_INTEL':
      newState = { ...state };
      dilatedStatuses =
        action.payload.statuses ||
        _.uniq(action.payload.dilatedIntel.DilatedCriteria.map(criteria => criteria.Status.toLowerCase()));

      newState = cleanSet(
        newState,
        ['ById', action.payload.jobId, 'DilatedIntel', 'CreatedTime'],
        action.payload.dilatedIntel.CreatedTime
      );
      dilatedStatuses.forEach(dilatedStatus => {
        const newStatus = dilatedStatus.toLowerCase();
        newState = cleanSet(
          newState,
          ['ById', action.payload.jobId, 'DilatedIntel', newStatus],
          action.payload.dilatedIntel.DilatedCriteria.filter(criteria => criteria.Status.toLowerCase() === newStatus)
        );
      });

      return newState;

    case 'SET_DILATED_CANDIDATE_COUNT':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'DilatedCandidateCount'], action.payload.Count);
      return newState;

    case 'SET_ALL_DILATED_CANDIDATE_COUNT':
      newState = cleanSet(state, ['ById', action.payload.jobId, 'AllDilatedCandidateCount'], action.payload.Count);
      return newState;

    case 'ADD_MUST_HAVES': {
      const existingMustHaves = _.get(state, ['ById', action.payload.jobId, 'MustHaves']);
      const newMustHaves = action.payload.mustHaves;
      mustHaves = existingMustHaves ? existingMustHaves.concat(' AND ', newMustHaves) : newMustHaves;
      newState = cleanSet(state, ['ById', action.payload.jobId, 'MustHaves'], mustHaves);
      return newState;
    }

    case 'SET_BULK_JOB_TAGS':
      newState = { ...state };
      bulkJobTags = _.get(action.payload, 'bulkJobTags', {});
      Object.entries(bulkJobTags).forEach(([jobId, tags]) => {
        newState = cleanSet(newState, ['ById', jobId, 'Tags'], tags);
      });
      return newState;

    case SET_MUST_HAVES_FOR_JOB:
      newState = cleanSet(state, ['ById', action.payload.jobId, 'MustHaves'], action.payload.mustHaves);
      return newState;

    case 'SET_TRYNOW_FORM_DATA': {
      newState = cloneDeep(state);
      const data = !action.payload.override
        ? _.merge(_.get(newState, ['trynowFormData'], {}), action.payload.data)
        : action.payload.data;
      _.set(newState, ['trynowFormData'], data);
      return newState;
    }

    case 'SET_JOB_UTILITIES':
      newState = cleanSet(state, 'utilities', action.payload);
      return newState;
    case SET_JOB_PUBLISH_HISTORY:
      newState = cloneDeep(state);
      newState.JobPublishHistory = action.payload;
      (action?.payload ?? []).forEach(publishInfo => {
        _.setWith(newState.ById, [publishInfo.JobId, 'DistributedInfo', publishInfo.Portal], publishInfo, Object);
      });
      return newState;
    case 'UPDATE_SEGMENT_PUT':
      newState = _.cloneDeep(state);
      _.set(newState, ['ById', action.payload.jobId, 'Description'], action.payload.jobDetails.Description);
      _.set(newState, ['ById', action.payload.jobId, 'JobTitle'], action.payload.jobDetails.JobTitle);
      newState = cleanSet(newState, ['segments', action.payload.jobId], action.payload.jobDetails);
      return newState;
    case 'CREATE_SEGMENT': {
      newState = cleanSet(state, ['segments', action.payload.JobId], action.payload);
      return newState;
    }

    case 'SET_SEGMENT_LIST':
      newState = cleanSet(state, ['segmentList'], action.payload);
      return newState;
    case 'SET_JOB_AUTOMATION_CONFIGURATION':
      newState = cleanSet(state, ['AutomationConfig'], action.payload);
      return newState;
    case SET_SHOW_CURRENT_USER_CANDIDATE_VIEWED_FLAG:
      newState = cleanSet(
        state,
        ['ById', action.payload.jobId, 'ShowCurrentUserViewedStatus'],
        action.payload.candidateViewStausFlag
      );
      return newState;
    default:
      return state;
  }
}

JobReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getSelectedTags(state) {
  return state.JobReducer.SelectedTags;
}

function getJobPublishHistory(state) {
  return state.JobReducer.JobPublishHistory ?? emptyList;
}

function getTagsForFilterPopUp(state) {
  return state.JobReducer.Tags ?? emptyList;
}

function getJobsCountByStatus(state) {
  return state.JobReducer.CountByStatus;
}

function getEmailStats(state) {
  return state.JobReducer.jobEmailStats;
}

function getSourcingStats(state, jobId) {
  return _.get(state, ['JobReducer', 'ById', jobId, 'sourcingStats'], emptyObject);
}

function getJobsIdsByStatus(state) {
  return state.JobReducer.ListByStatus;
}

function getJobNotesByJobId(state, jobId) {
  return _.get(state, ['JobReducer', 'ById', jobId, 'NotesById'], emptyList);
}

function getTotalJobNotes(state, jobId) {
  return _.get(state, ['JobReducer', 'ById', jobId, 'TotalNotes'], -1);
}

function getJobsById(state) {
  return _.get(state, ['JobReducer', 'ById'], emptyObject);
}

function getJobUtilities(state) {
  return state.JobReducer.utilities ?? emptyObject;
}

function getJobIntel(state) {
  return state.JobReducer.jobIntel;
}

function getJobIntelLocationData(state) {
  return state.JobReducer.jobIntelLocationData;
}

const getSharedUsers = createSelector([state => state.JobReducer.ById, (state, jobId) => jobId], (jobsById, jobId) => {
  let sharedUsers = [];
  if (jobId) {
    sharedUsers = sharedUsers.concat(jobsById[jobId]?.SharedWith || []).concat(jobsById[jobId]?.AssignedTo || []);
  }
  return _.uniq(sharedUsers);
});

const getSharedUsersForSegment = createSelector([state => state.JobReducer?.segmentList?.jobs, (state, jobId) => jobId], (jobs, jobId) => {
  const job = jobs?.find(job=> jobId===job.JobId)
  let sharedUsers = [];
    sharedUsers = sharedUsers.concat(job?.SharedWith || []).concat(job?.AssignedTo || []);
  return _.uniq(sharedUsers);
});

function getClient(state, clientId) {
  const { clients } = state.JobReducer.utilities;
  if (!clients) {
    return null;
  }
  return clients.find(client => client.Id === clientId);
}

function getJobGuidToIdMapping(state) {
  return state.JobReducer.JobGuidToIdMapping;
}
function getSegmentJobGuidToIdMapping(state) {
  return state.JobReducer.SegmentJobGuidToIdMapping;
}
function getJobOwner(state, jobId) {
  let jobOwner;
  const currentJobDetails = state.JobReducer.ById[jobId];
  if (jobId) {
    jobOwner = (currentJobDetails?.AssignedTo || []).find(value => value === currentJobDetails?.CreatedBy);
  }
  return jobOwner;
}

function getJobOwnerForSegment(state, jobId) {
  const currentJobDetails = state.JobReducer?.segmentList?.jobs;
  const job = currentJobDetails?.find(job=> jobId===job.JobId)
  return job?.CreatedBy;
}

function getJoblistPageResetStatus(state) {
  return state.JobReducer.ResetJobListPage;
}

function getCandidateNotes(state, jobId, candidateId) {
  const jobCandidates = _.get(state.JobReducer, ['ById', jobId, 'Candidates'], emptyList);
  const candidate = jobCandidates.find(c => c.Id === candidateId);
  return _.get(candidate, 'Notes', []);
}

const getMustHaves = createSelector([state => state.JobReducer.ById, (state, jobId) => jobId], (byId, jobId) => {
  const jobDetails = byId[jobId];
  let mustHaves = [];
  if (jobDetails && jobDetails.MustHaves) {
    mustHaves = generateArrayFromBooleanString(jobDetails.MustHaves);
  }
  return mustHaves;
});

function getNiceToHaves(state, jobId) {
  const jobDetails = _.get(state.JobReducer, ['ById', jobId], {});
  let niceToHaves = '';
  if (jobDetails && jobDetails.NiceToHaves) {
    niceToHaves = jobDetails.NiceToHaves;
  }
  return niceToHaves;
}

function getJobGuidFromJobId(state, jobId) {
  return _.get(state, ['JobReducer', 'ById', jobId, 'JobGuid']);
}

function getCurrentJobId(state) {
  return _.get(state, ['JobReducer', 'currentJobId'], null);
}

function getSkillSet(state) {
  return _.get(state, ['JobReducer', 'utilities', 'skills'], emptyList);
}

function getEmailStatsForJob(state) {
  const { currentJobId } = state.JobReducer;
  return _.get(state, ['JobReducer', 'ById', currentJobId, 'EmailStats'], emptyList);
}

function getJobListPageSize(state) {
  return _.get(state, ['JobReducer', 'JobListPageSize'], 10);
}
function getJobListPageNumber(state) {
  return _.get(state, ['JobReducer', 'JobListPageNumber'], 1);
}
function getJobListSearchTerm(state,candidateContext) {
  return _.get(state, ['JobReducer', 'JobListSearchTerm',candidateContext], '');
}

function getDefaultHomePageRenderFlag(state) {
  return _.get(state, ['JobReducer', 'DefaultHomePageRenderFlag']);
}

function getJobListSelectedUser(state) {
  return _.get(state, ['JobReducer', 'JobListSelectedUser']);
}

function getJobListTabName(state) {
  return _.get(state, ['JobReducer', 'JobListTabName']);
}

function getIndustrySuggestions(state) {
  return state?.JobReducer?.utilities?.industries ?? emptyList;
}

function getOccuaptionSuggestions(state) {
  return state?.JobReducer?.utilities?.occupations ?? emptyList;
}

function getJobTitles(state) {
  return _.get(state, ['JobReducer', 'utilities', 'titles'], emptyList);
}

function getTitleSynonyms(state) {
  return _.get(state, ['JobReducer', 'utilities', 'titleSynonyms'], emptyList);
}

function getTrynowFormData(state) {
  return state?.JobReducer?.trynowFormData ?? emptyObject;
}

function getAdvancedJobFormData(state) {
  return state?.JobReducer?.advancedJobFormData ?? emptyObject;
}

function getUserGivenIntel(state) {
  const currentJobId = getCurrentJobId(state);
  return state?.JobReducer?.ById?.[`${currentJobId}`]?.quickSearchParams ?? emptyObject;
}

function getCurrentJobDetails(state) {
  const currentJobId = getCurrentJobId(state);
  return state.JobReducer.ById[currentJobId];
}

function getJobDetails(state, jobId) {
  return state.JobReducer.ById[jobId] ?? emptyObject;
}

function getCountries(state) {
  return state?.JobReducer?.utilities?.countries ?? emptyList;
}

function getSegmentsById(state) {
  return _.get(state, ['JobReducer', 'segments'], emptyObject);
}
function getSegmentList(state) {
  return _.get(state, ['JobReducer', 'segmentList'], emptyObject);
}
function getJobAutomationConfiguration(state) {
  return _.get(state, ['JobReducer', 'AutomationConfig'], emptyObject);
}

function getCurrentUserViewedStatusFlag(state, jobId) {
  return state.JobReducer.ById[jobId]?.ShowCurrentUserViewedStatus;
}

function getInitialCandidateCountBySource(state, jobId) {
  return state.JobReducer.ById[jobId]?.initialCandidateCountBySource;
}

export {
  JobReducer,
  getJobsCountByStatus,
  getJobPublishHistory,
  getCandidateNotes,
  getEmailStats,
  getSourcingStats,
  getJobsIdsByStatus,
  getJobsById,
  getJobUtilities,
  getJobIntel,
  getJobIntelLocationData,
  getSharedUsers,
  getClient,
  getJobGuidToIdMapping,
  getJobOwner,
  getJoblistPageResetStatus,
  getMustHaves,
  getNiceToHaves,
  getSelectedTags,
  getTagsForFilterPopUp,
  getJobGuidFromJobId,
  getCurrentJobId,
  getSkillSet,
  getEmailStatsForJob,
  getJobListPageSize,
  getJobListPageNumber,
  getJobListSearchTerm,
  getDefaultHomePageRenderFlag,
  getJobListSelectedUser,
  getJobListTabName,
  getIndustrySuggestions,
  getJobTitles,
  getTitleSynonyms,
  getTrynowFormData,
  getAdvancedJobFormData,
  getUserGivenIntel,
  getCurrentJobDetails,
  getCountries,
  getOccuaptionSuggestions,
  getJobDetails,
  getSegmentsById,
  getSegmentList,
  getSegmentJobGuidToIdMapping,
  getJobNotesByJobId,
  getTotalJobNotes,
  getJobAutomationConfiguration,
  getCurrentUserViewedStatusFlag,
  getInitialCandidateCountBySource,
  getSharedUsersForSegment,
  getJobOwnerForSegment
};
