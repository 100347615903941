import React from 'react';
import styles from './CandidateAdvanceFilters.module.scss';
import CandidateAdvanceFilterGroup from './CandidateAdvanceFilterGroup';

function CandidateAdvanceFilterGroupHeader(props) {
  const { candidateAggs, filterFields, handleCandidateAdvanceFilters, isClearButtonClicked } = props;
  const [Include, setInclude] = React.useState([]);
  const [Exclude, setExclude] = React.useState([]);

  React.useEffect(() => {
    const filteredData1 = Include?.filter(item => item?.FilterItems?.length > 0);
    const filteredData2 = Exclude?.filter(item => item?.FilterItems?.length > 0);
    const mainarray = [...filteredData1, ...filteredData2];
    handleCandidateAdvanceFilters(mainarray);
  }, [Include, Exclude, handleCandidateAdvanceFilters]);

  return (
    <div className={styles.candidateFilterWrapper}>
      <div className={styles.filterGroupHeader}>
        <div className={styles.filterGroupHeaderLabel}>Include:</div>
        <CandidateAdvanceFilterGroup
          candidateAggs={candidateAggs}
          filterFields={filterFields}
          setObject={setInclude}
          isClearButtonClicked={isClearButtonClicked}
          component="include"
        />
      </div>
      <div className={styles.filterGroupHeaderBorder}></div>
      <div className={styles.filterGroupHeader}>
        <div className={styles.filterGroupHeaderLabel}>Exclude:</div>
        <CandidateAdvanceFilterGroup
          candidateAggs={candidateAggs}
          filterFields={filterFields}
          isClearButtonClicked={isClearButtonClicked}
          setObject={setExclude}
          component="exclude"
        />
      </div>
    </div>
  );
}

export default CandidateAdvanceFilterGroupHeader;
