import React from 'react';
import { Row, Col } from 'antd';
import JobMultilocationItem from '../JobMultiLocationItem/JobMultiLocationItem';
import styles from './JobMultiLocationInput.module.scss';

function JobMultiLocationInput(props) {
  const {
    locations,
    onChange,
    isLocationSelectionLimitReached,
    isRegionSelectionLimitReached,
    isHidden,
    countryOptions,
    isCountryWideSearchEnabledCountry,
  } = props;
  return (
    <div style={{ display: isHidden ? 'none' : 'block' }}>
      <Row className={styles.multiLocationLabelsWrapper}>
        <Col span={4}>
          <div className={styles.label}>Country *</div>
        </Col>
        <Col span={5}>
          <div className={styles.label}>Region</div>
        </Col>
        <Col span={9}>
          <div className={styles.label}>Location</div>
        </Col>
        <Col span={5}>
          <div className={styles.label}>Primary Location *</div>
        </Col>
      </Row>
      {locations?.map((location, index) => (
        <JobMultilocationItem
          onChange={onChange}
          key={location.Id}
          currentKey={location.Id}
          locations={locations}
          currentLocation={location}
          isLocationSelectionLimitReached={isLocationSelectionLimitReached}
          isRegionSelectionLimitReached={isRegionSelectionLimitReached}
          isFirstElement={index === 0}
          isLastElement={index === locations.length - 1}
          countryOptions={countryOptions}
          isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
          layers="neighbourhood,borough,locality,localadmin,county,macrocounty"
        />
      ))}
    </div>
  );
}

export default JobMultiLocationInput;
