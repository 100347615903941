import React from 'react';
import classNames from 'classnames';
import styles from './AppliedSourceTag.module.scss';

export default function AppliedSourceTag(props) {
  const { sourceName } = props;
  let appliedTagText = 'Applied';
  const joinedText = ' via ';
  if (sourceName) {
    appliedTagText = appliedTagText.concat(joinedText, sourceName);
  }

  return (
    <div className={classNames(styles.appliedSourceTag, styles.candidate360AppliedSourceTag)}>{appliedTagText}</div>
  );
}
