import FileSaver from 'file-saver';
import _ from 'lodash';
import * as reportPortalRepository from '../Repository/ReportPortalRepository';
import * as userRepository from '../Repository/UserRepository';
import { setNotification } from './ActionCreators/ConnectActions';
import * as clientRepository from '../Repository/ClientRepository';
import { fetchUsers } from './UserActions';
import {
  setFetchReportStatsApiStatus,
  setFetchReportSummaryApiStatus,
  setReportStats,
  setReportSummary,
  setClientsMaxCount,
  setRecruitersMaxCount,
  setClientListForDropdowns,
  appendClientListForDropdowns,
  setRecruiterListForDropdowns,
  appendRecruiterListForDropdowns,
  setFetchRecruiterApiStatus,
  setReportColumns,
  setFetchJobListApiStatus,
  appendJobListForDropdowns,
  setJobListForDropdowns,
  setJobsMaxCount,
  setExportReportApiStatus,
  setFetchReportColumnsApiStatus,
  setSaveReportQueryApiStatus,
  setFetchSavedQueriesApiStatus,
  setSavedReportQueries,
  setDeleteSavedQueryApiStatus,
  setFetchClientListApiStatus,
  setReportPortalOrgs,
  patchUpdateSavedReportQuery,
} from './ActionCreators/ReportPortalActionCreator';
import { mapReportStatsSourceName, modifyDisplayName } from '../Utils/ReportsDataUtils';

function getReportSummary(filter) {
  return dispatch => {
    dispatch(setFetchReportSummaryApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getReportSummary(filter)
      .then(response => {
        const payload = response.data;
        dispatch(setReportSummary(payload));
        dispatch(setFetchReportSummaryApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: _.get(err, 'response'),
        };
        dispatch(setFetchReportSummaryApiStatus(status));
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: 'Oops, something just went wrong. Please try again.',
          },
        });
      });
  };
}

function getReportStats(filter, reportStatsCategory) {
  return dispatch => {
    dispatch(setFetchReportStatsApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getReportStats(filter, reportStatsCategory)
      .then(response => {
        const payload = response.data;
        const modifiedPayload = reportStatsCategory === 'candidates' ? mapReportStatsSourceName(payload) : payload;;
        dispatch(setReportStats(modifiedPayload, reportStatsCategory));
        dispatch(setFetchReportStatsApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: _.get(err, 'response'),
        };
        dispatch(setFetchReportStatsApiStatus(status));
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: 'Oops, something just went wrong. Please try again.',
          },
        });
      });
  };
}

function generateReport(filter, reportStatsCategory) {
  return dispatch => {
    dispatch(setExportReportApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .generateReport(filter, reportStatsCategory)
      .then(() => {
        dispatch(setExportReportApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: _.get(err, 'response'),
        };
        dispatch(setExportReportApiStatus(status));
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: 'Oops, something just went wrong. Please try again.',
          },
        });
      });
  };
}

function getReportColumns(reportId, reportStatsCategory) {
  return dispatch => {
    dispatch(setFetchReportColumnsApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getReportColumnHeaders(reportId, reportStatsCategory)
      .then(response => {
        const payload = response.data;
        const modifiedPayload = modifyDisplayName(payload);
        dispatch(setReportColumns(modifiedPayload, reportStatsCategory));
        dispatch(setFetchReportColumnsApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'COLUMNS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setFetchReportColumnsApiStatus({ status: 'FAILED' }));
      });
  };
}

function getOrgReportColumns(filter, reportStatsCategory) {
  return dispatch => {
    dispatch(setFetchReportColumnsApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getOrgReportColumnHeaders(filter)
      .then(response => {
        const payload = response.data;
        const modifiedPayload = modifyDisplayName(payload);
        dispatch(setReportColumns(modifiedPayload, reportStatsCategory));
        dispatch(setFetchReportColumnsApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'COLUMNS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setFetchReportColumnsApiStatus({ status: 'FAILED' }));
      });
  };
}

function clearReportStats(reportStatsCategory) {
  return dispatch => {
    dispatch(setReportStats(null, reportStatsCategory));
  };
}

function clearFetchReportSummaryApiStatus() {
  return setFetchReportSummaryApiStatus(null);
}

function clearExportReportApiStatus() {
  return dispatch => {
    dispatch(setExportReportApiStatus(null));
  };
}

function clearFetchReportStatsApiStatus() {
  return setFetchReportStatsApiStatus(null);
}

function clearJobsForDropdownLists() {
  return setJobListForDropdowns([]);
}

function clearRecruitersForDropdownLists(reportStatsCategory) {
  return setRecruiterListForDropdowns([], reportStatsCategory);
}

function clearClientsForDropdownLists(reportStatsCategory) {
  return setClientListForDropdowns([], reportStatsCategory);
}

function fetchClientsForDropdownLists(filter, reportStatsCategory, fetchType = 'INITIAL') {
  return async dispatch => {
    dispatch(setFetchClientListApiStatus({ status: 'INPROGRESS' }));
    try {
      const response = await clientRepository.fetchClientList(filter, filter?.OrgId);
      const clients = response?.data?.Clients;
      const total = response?.data?.Total;
      if (fetchType === 'MORE') {
        dispatch(appendClientListForDropdowns(clients, reportStatsCategory));
      } else {
        dispatch(setClientListForDropdowns(clients, reportStatsCategory));
      }
      dispatch(setClientsMaxCount(total, reportStatsCategory));
      dispatch(setFetchClientListApiStatus({ status: 'COMPLETED' }));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'CLIENTS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setFetchClientListApiStatus({ status: 'FAILED' }));
      throw error;
    }
  };
}

function fetchRecruitersForDropdownLists(filter, reportStatsCategory, fetchType = 'INITIAL') {
  return async dispatch => {
    dispatch(setFetchRecruiterApiStatus({ status: 'INPROGRESS' }));
    try {
      const response = await userRepository.fetchUsers(filter, filter?.orgId);
      if (fetchType === 'MORE') {
        dispatch(appendRecruiterListForDropdowns(response?.data?.Users, reportStatsCategory));
      } else {
        dispatch(setRecruiterListForDropdowns(response?.data?.Users, reportStatsCategory));
      }
      dispatch(setRecruitersMaxCount(response?.data?.Total, reportStatsCategory));
      dispatch(setFetchRecruiterApiStatus({ status: 'COMPLETED' }));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'USERS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setFetchRecruiterApiStatus({ status: 'FAILED' }));
      throw error;
    }
  };
}

function fetchJobsForDropdownLists(filter, fetchType = 'INITIAL') {
  return dispatch => {
    dispatch(setFetchJobListApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getJobs(filter)
      .then(response => {
        if (fetchType === 'MORE') {
          dispatch(appendJobListForDropdowns(response?.data?.Jobs));
        } else {
          dispatch(setJobListForDropdowns(response?.data?.Jobs));
        }
        dispatch(setJobsMaxCount(response?.data?.Total));
        dispatch(setFetchJobListApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'USERS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setFetchJobListApiStatus({ status: 'FAILED' }));
      });
  };
}

function downloadReport(fileName) {
  return dispatch => {
    reportPortalRepository
      .downloadReport(fileName)
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const downloadedFileName = fileName;
        FileSaver.saveAs(blob, downloadedFileName);
      })
      .catch(() => {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: 'Oops, something just went wrong. Please try again.',
          },
        });
      });
  };
}

function saveReportQuery(query) {
  return dispatch => {
    dispatch(setSaveReportQueryApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .saveReportQuery(query)
      .then(() => {
        dispatch(setSaveReportQueryApiStatus({ status: 'COMPLETED' }));
        dispatch(setNotification('SUCCESS', 'Report Query saved successfully.'));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: err?.response,
        };
        dispatch(setSaveReportQueryApiStatus(status));
      });
  };
}

function fetchSavedReportQueries(filter) {
  return dispatch => {
    dispatch(setFetchSavedQueriesApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .fetchSavedQueries(filter)
      .then(response => {
        const payload = response.data;
        const savedReports = payload.SavedReports || [];
        dispatch(setSavedReportQueries(payload));
        let sharedWith = [];
        let ownerIds = [];
        savedReports.forEach(savedReport => {
          if (savedReport.SharedWith?.length) {
            sharedWith = sharedWith.concat(savedReport.SharedWith);
          }
          if (savedReport.CreatedBy) {
            ownerIds = ownerIds.concat(savedReport.CreatedBy);
          }
        });
        sharedWith = sharedWith.filter(userId => !!userId);
        ownerIds = ownerIds.filter(userId => !!userId);
        if (sharedWith?.length || ownerIds?.length) {
          const totalUserIds = _.uniq([...sharedWith, ...ownerIds]);
          dispatch(
            fetchUsers({
              userIds: totalUserIds,
            })
          );
        }
        dispatch(setFetchSavedQueriesApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: err?.response,
        };
        dispatch(setFetchSavedQueriesApiStatus(status));
      });
  };
}

function clearSaveReportQueryApiStatus() {
  return setSaveReportQueryApiStatus(null);
}

function getOrganizations({ page, searchTerm, orgIds }) {
  return async dispatch => {
    const res = await reportPortalRepository.fetchOrganizations({
      From: (page - 1) * 10,
      Size: 10,
      SearchKeyword: searchTerm,
      OrgIds: orgIds,
    });
    const orgData = res?.data;
    dispatch(setReportPortalOrgs(orgData, searchTerm));
  };
}

function deleteSavedQuery(queryId) {
  return dispatch => {
    dispatch(setDeleteSavedQueryApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .deleteSavedQuery(queryId)
      .then(() => {
        dispatch(setNotification('SUCCESS', 'Saved query deleted successfully.'));
        dispatch(setDeleteSavedQueryApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch(setDeleteSavedQueryApiStatus({ status: 'FAILED' }));
        dispatch(setNotification('ERROR', 'Oops, something just went wrong. Please try again.'));
      });
  };
}

function shareReportSavedQuery({ shareWith, unshareWith, isShareWithAll, isUnshareWithAll, reportId }) {
  return async dispatch => {
    try {
      await reportPortalRepository.shareSavedQuery({
        shareWith,
        unshareWith,
        isShareWithAll,
        isUnshareWithAll,
        reportId,
      });
      const updatedReportQuery = { SharedWith: shareWith, unSharedWith: unshareWith, SharedWithAll: isShareWithAll };
      dispatch(patchUpdateSavedReportQuery({ reportId, updatedReportQuery }));
      dispatch(setNotification('SUCCESS', 'Saved query shared successfully'));
    } catch {
      dispatch(setNotification('ERROR', 'Oops, something just went wrong. Please try again.'));
    }
  };
}

function clearDeleteSavedQueryApiStatus() {
  return setDeleteSavedQueryApiStatus(null);
}

export {
  getReportSummary,
  getReportStats,
  fetchClientsForDropdownLists,
  fetchRecruitersForDropdownLists,
  clearReportStats,
  downloadReport,
  getReportColumns,
  getOrgReportColumns,
  fetchJobsForDropdownLists,
  generateReport,
  clearExportReportApiStatus,
  clearFetchReportStatsApiStatus,
  clearJobsForDropdownLists,
  clearFetchReportSummaryApiStatus,
  saveReportQuery,
  fetchSavedReportQueries,
  clearSaveReportQueryApiStatus,
  clearRecruitersForDropdownLists,
  clearClientsForDropdownLists,
  getOrganizations,
  deleteSavedQuery,
  clearDeleteSavedQueryApiStatus,
  shareReportSavedQuery,
};
