export function getCandidatesPulishedNotificationContent(candidatesPublishedCount, minimumPublishCandidatesThreshold) {
  if (candidatesPublishedCount < minimumPublishCandidatesThreshold) {
    return 'Option to cancel as low number of candidates found.';
  }
  return `${candidatesPublishedCount} New Candidates have been sourced.`;
}

export function getOrderPlacedNotificationContent(UserName, UserId, IsUpgraded) {
  if (IsUpgraded) {
    return `Job has been upgraded by ${UserName} ${UserId}`;
  }
  return `Job has been created by ${UserName} ${UserId}`;
}
export function getFindingCandidatesNotificationContent() {
  return 'Hey! We started finding candidates for your job';
}

export function getPortalCredetialFailNotificationContent(jobBoard) {
  return `Unable to search from ${jobBoard} due to Invalid job board credentials, Please disconnect and reconnect ${jobBoard} under "Application Settings" for the search to work.`;
}
