import * as React from 'react';
import { Icon } from 'antd';

export const TextSuccessSvgV3 = props => (
  <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.1" d="M18.1712 5.83276C17.3728 5.03169 16.4253 4.39471 15.3821 3.95776C14.3122 3.50823 13.1629 3.2779 12.0024 3.28042H11.9626C9.62585 3.28979 7.43914 4.20385 5.8032 5.8562C4.16492 7.50854 3.27195 9.70229 3.28132 12.039C3.28835 13.4523 3.63992 14.8562 4.30085 16.096L4.40632 16.2929V19.5929H7.70632L7.9032 19.6984C9.14304 20.3593 10.5469 20.7109 11.9602 20.7179H12.0001C14.3204 20.7179 16.5001 19.8226 18.143 18.196C19.793 16.5601 20.7094 14.3734 20.7235 12.039C20.7282 10.8531 20.5009 9.70463 20.0462 8.62182C19.6089 7.57881 18.972 6.63134 18.1712 5.83276ZM7.31257 13.1242C7.01818 13.1182 6.73787 12.997 6.5318 12.7867C6.32572 12.5763 6.2103 12.2936 6.2103 11.9992C6.2103 11.7047 6.32572 11.422 6.5318 11.2117C6.73787 11.0013 7.01818 10.8802 7.31257 10.8742C7.60697 10.8802 7.88727 11.0013 8.09335 11.2117C8.29942 11.422 8.41484 11.7047 8.41484 11.9992C8.41484 12.2936 8.29942 12.5763 8.09335 12.7867C7.88727 12.997 7.60697 13.1182 7.31257 13.1242ZM12.0001 13.1242C11.7057 13.1182 11.4254 12.997 11.2193 12.7867C11.0132 12.5763 10.8978 12.2936 10.8978 11.9992C10.8978 11.7047 11.0132 11.422 11.2193 11.2117C11.4254 11.0013 11.7057 10.8802 12.0001 10.8742C12.2945 10.8802 12.5748 11.0013 12.7808 11.2117C12.9869 11.422 13.1023 11.7047 13.1023 11.9992C13.1023 12.2936 12.9869 12.5763 12.7808 12.7867C12.5748 12.997 12.2945 13.1182 12.0001 13.1242ZM16.6876 13.1242C16.3932 13.1182 16.1129 12.997 15.9068 12.7867C15.7007 12.5763 15.5853 12.2936 15.5853 11.9992C15.5853 11.7047 15.7007 11.422 15.9068 11.2117C16.1129 11.0013 16.3932 10.8802 16.6876 10.8742C16.982 10.8802 17.2623 11.0013 17.4683 11.2117C17.6744 11.422 17.7898 11.7047 17.7898 11.9992C17.7898 12.2936 17.6744 12.5763 17.4683 12.7867C17.2623 12.997 16.982 13.1182 16.6876 13.1242Z" fill="#83878C"/>
<path d="M15.5625 12C15.5625 12.2984 15.681 12.5845 15.892 12.7955C16.103 13.0065 16.3891 13.125 16.6875 13.125C16.9859 13.125 17.272 13.0065 17.483 12.7955C17.694 12.5845 17.8125 12.2984 17.8125 12C17.8125 11.7016 17.694 11.4155 17.483 11.2045C17.272 10.9935 16.9859 10.875 16.6875 10.875C16.3891 10.875 16.103 10.9935 15.892 11.2045C15.681 11.4155 15.5625 11.7016 15.5625 12ZM6.1875 12C6.1875 12.2984 6.30603 12.5845 6.517 12.7955C6.72798 13.0065 7.01413 13.125 7.3125 13.125C7.61087 13.125 7.89702 13.0065 8.108 12.7955C8.31897 12.5845 8.4375 12.2984 8.4375 12C8.4375 11.7016 8.31897 11.4155 8.108 11.2045C7.89702 10.9935 7.61087 10.875 7.3125 10.875C7.01413 10.875 6.72798 10.9935 6.517 11.2045C6.30603 11.4155 6.1875 11.7016 6.1875 12Z" fill="#83878C"/>
<path d="M21.6845 7.93125C21.1548 6.67266 20.3954 5.54297 19.4275 4.57266C18.4663 3.60796 17.3252 2.84114 16.0689 2.31563C14.7798 1.77422 13.4111 1.5 12.0001 1.5H11.9533C10.5329 1.50703 9.15716 1.78828 7.86341 2.34141C6.61785 2.87232 5.48747 3.64049 4.53529 4.60312C3.5767 5.57109 2.82435 6.69609 2.30404 7.95C1.76498 9.24844 1.4931 10.6289 1.50013 12.0492C1.50809 13.6769 1.89316 15.2806 2.62513 16.7344V20.2969C2.62513 20.5828 2.73872 20.857 2.94091 21.0592C3.1431 21.2614 3.41732 21.375 3.70326 21.375H7.2681C8.72192 22.107 10.3256 22.492 11.9533 22.5H12.0025C13.4064 22.5 14.7681 22.2281 16.0501 21.6961C17.3001 21.1768 18.4369 20.419 19.397 19.4648C20.365 18.5063 21.1267 17.3859 21.6587 16.1367C22.2119 14.843 22.4931 13.4672 22.5001 12.0469C22.5072 10.6195 22.2306 9.23438 21.6845 7.93125ZM18.1431 18.1969C16.5001 19.8234 14.3204 20.7188 12.0001 20.7188H11.9603C10.547 20.7117 9.1431 20.3602 7.90326 19.6992L7.70638 19.5938H4.40638V16.2938L4.30091 16.0969C3.63998 14.857 3.28841 13.4531 3.28138 12.0398C3.27201 9.70312 4.16498 7.50937 5.80326 5.85703C7.4392 4.20469 9.62591 3.29062 11.9626 3.28125H12.0025C13.1744 3.28125 14.3111 3.50859 15.3822 3.95859C16.4275 4.39687 17.365 5.02734 18.1712 5.83359C18.9751 6.6375 19.6079 7.57734 20.0462 8.62266C20.5009 9.70547 20.7283 10.8539 20.7236 12.0398C20.7095 14.3742 19.7931 16.5609 18.1431 18.1969Z" fill="#83878C"/>
<path d="M10.875 12C10.875 12.2984 10.9935 12.5845 11.2045 12.7955C11.4155 13.0065 11.7016 13.125 12 13.125C12.2984 13.125 12.5845 13.0065 12.7955 12.7955C13.0065 12.5845 13.125 12.2984 13.125 12C13.125 11.7016 13.0065 11.4155 12.7955 11.2045C12.5845 10.9935 12.2984 10.875 12 10.875C11.7016 10.875 11.4155 10.9935 11.2045 11.2045C10.9935 11.4155 10.875 11.7016 10.875 12Z" fill="#83878C"/>
<circle cx="24.68" cy="19.68" r="7.68" fill="#FFEBC2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.3616 15.84H23.4004V21.2676L26.7785 23.52L27.867 21.8883L25.3616 20.2184V15.84Z" fill="#FAAD14"/>
<circle cx="24.68" cy="19.68" r="7.68" fill="#E6F7FF"/>
<path d="M27.5111 17.5374C27.5639 17.5901 27.5938 17.6613 27.5938 17.736V23.1562C27.5938 23.3118 27.4681 23.4375 27.3125 23.4375H21.6875C21.5319 23.4375 21.4062 23.3118 21.4062 23.1562V15.8438C21.4062 15.6882 21.5319 15.5625 21.6875 15.5625H25.4202C25.4949 15.5625 25.567 15.5924 25.6197 15.6451L27.5111 17.5374ZM26.9451 17.8652L25.291 16.2111V17.8652H26.9451Z" fill="#007BFF"/>
</svg>
);

const TextSuccessIconV3 = props => (
  <Icon component={customProps => <TextSuccessSvgV3 {...props} {...customProps} />} {...props} />
);
export default TextSuccessIconV3;
