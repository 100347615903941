import { setApiStatus } from '../ApiStatusActions';

const SET_MANUAL_SEARCH_PAYLOAD = 'SET_MANUAL_SEARCH_PAYLOAD';
const SET_MANUAL_SEARCH_FORM_VALUES = 'SET_MANUAL_SEARCH_FORM_VALUES';
const SET_BOOLEAN_SEARCH_STRINGS = 'SET_BOOLEAN_SEARCH_STRINGS';
const SET_MANUAL_SEARCH_CRITERIA = 'SET_MANUAL_SEARCH_CRITERIA';
const SET_MANUAL_SEARCH_CRITERIA_BY_ID = 'SET_MANUAL_SEARCH_CRITERIA_BY_ID';
const SET_MANUAL_SEARCH_CRITERIA_RECORD_BY_ID = 'SET_MANUAL_SEARCH_CRITERIA_RECORD_BY_ID';
const SET_MANUAL_SEARCH_CRITERIA_IDENTIFIERS = 'SET_MANUAL_SEARCH_CRITERIA_IDENTIFIERS';
const SET_RETRIEVED_JOBACTIVITY_ID = 'SET_RETRIEVED_JOBACTIVITY_ID';
const RESET_MANUAL_SEARCH_JOBACTIVITY = 'RESET_MANUAL_SEARCH_JOBACTIVITY';
const SET_SELECTED_CRITERIA_NAME = 'SET_SELECTED_CRITERIA_NAME';
const SET_SELECTED_CRITERIA_TYPE = 'SET_SELECTED_CRITERIA_TYPE';
const SET_SELECTED_CRITERIA_ID = 'SET_SELECTED_CRITERIA_ID';
const SET_LAST_PERFORMED_MANUAL_SEARCH_TIME = 'SET_LAST_PERFORMED_MANUAL_SEARCH_TIME';
const CLEAR_MANUAL_SEARCH_CRITERIA = 'CLEAR_MANUAL_SEARCH_CRITERIA';
const SET_MANUAL_SEARCH_SOURCES = 'SET_MANUAL_SEARCH_SOURCES';
const SET_IS_REACTIVATION_ALLOWED_FLAG = 'SET_IS_REACTIVATION_ALLOWED_FLAG';
const SET_IS_DEFAULT_CRITERIA_FETCHED_FLAG = 'SET_IS_DEFAULT_CRITERIA_FETCHED_FLAG';
export const SET_IS_PREFETCH_REQUIRED_FETCHED_FLAG = 'SET_IS_PREFETCH_REQUIRED_FETCHED_FLAG';
export const SET_MANUAL_SEARCH_SELECTED_CRITERIA_INFO = 'SET_MANUAL_SEARCH_SELECTED_CRITERIA_INFO';

const setManualSearchDraftCriteriaFetchApiStatus = status =>
  setApiStatus({ apiName: 'fetchManualSearchDraftCriteriaApiStatus', status });

const setFetchManualSearchCriteriaIdentifiersApiStatus = status =>
  setApiStatus({ apiName: 'fetchManualSearchCriteriaIdentifiersApiStatus', status });

const setFetchAryaGeneratedCriteriaApiStatus = status =>
  setApiStatus({ apiName: 'fetchAryaGeneratedCriteriaApiStatus', status });

const setSaveManualSearchCriteriaApiStatus = status =>
  setApiStatus({ apiName: 'saveManualSearchCriteriaApiStatus', status });

const setApplyManualSearchCriteriaApiStatus = status =>
  setApiStatus({ apiName: 'applyManualSearchCriteriaApiStatus', status });

const setfetchManualSearchCriteriaRecordApiStatus = status =>
  setApiStatus({ apiName: 'fetchManualSearchCriteriaRecordApiStatus', status });

const setManualSearchPayload = ({ manualSearchPayload, jobId, isAdvancedSearchV2Enabled }) => ({
  type: SET_MANUAL_SEARCH_PAYLOAD,
  payload: { manualSearchPayload, jobId, isAdvancedSearchV2Enabled },
});

const setManualSearchFormValues = ({ manualSearchFormValues, jobId }) => ({
  type: SET_MANUAL_SEARCH_FORM_VALUES,
  payload: { manualSearchFormValues, jobId },
});

const setManualSearchCriteria = ({ criterias, jobId }) => ({
  type: SET_MANUAL_SEARCH_CRITERIA,
  payload: { criterias, jobId },
});
const setBooleanSearchStrings = payload => ({
  type: SET_BOOLEAN_SEARCH_STRINGS,
  payload,
});

const setManualSearchCriteriaIdentifiers = ({
  criteriaIdentifiers,
  agentIdentifiers,
  count,
  jobId,
  criteriaType,
  searchTerm,
}) => ({
  type: SET_MANUAL_SEARCH_CRITERIA_IDENTIFIERS,
  payload: { criteriaIdentifiers, agentIdentifiers, count, jobId, criteriaType, searchTerm },
});

const setManualSearchCriteriaById = ({ criteria, id }) => ({
  type: SET_MANUAL_SEARCH_CRITERIA_BY_ID,
  payload: { criteria, id },
});

const setfetchManualSearchCriteriaRecordById = ({ criteria, id, jobId }) => ({
  type: SET_MANUAL_SEARCH_CRITERIA_RECORD_BY_ID,
  payload: { id, criteria, jobId },
});

const setRetrievedJobActivityId = ({ jobActivityId, jobId }) => ({
  type: SET_RETRIEVED_JOBACTIVITY_ID,
  payload: { jobActivityId, jobId },
});

const resetManualSearchJobActivity = ({ jobId }) => ({
  type: RESET_MANUAL_SEARCH_JOBACTIVITY,
  payload: { jobId },
});

const clearManualSearchCriteria = ({ jobId }) => ({
  type: CLEAR_MANUAL_SEARCH_CRITERIA,
  payload: { jobId },
});

const setSelectedCriteriaName = ({ jobId, criteriaName }) => ({
  type: SET_SELECTED_CRITERIA_NAME,
  payload: { jobId, criteriaName },
});
const setSelectedCriteriaId = ({ jobId, criteriaId }) => ({
  type: SET_SELECTED_CRITERIA_ID,
  payload: { jobId, criteriaId },
});

const setSelectedCriteriaType = ({ jobId, selectedCriteriaType }) => ({
  type: SET_SELECTED_CRITERIA_TYPE,
  payload: { jobId, selectedCriteriaType },
});

const setManualSearchLastPerformedTime = ({ jobId }) => ({
  type: SET_LAST_PERFORMED_MANUAL_SEARCH_TIME,
  payload: { jobId },
});

const setManualSearchSources = ({ jobId, sources }) => ({
  type: SET_MANUAL_SEARCH_SOURCES,
  payload: { jobId, sources },
});

const setIsReactivationAllowedFlag = ({ jobId, isReactivationAllowedFlag }) => ({
  type: SET_IS_REACTIVATION_ALLOWED_FLAG,
  payload: { jobId, isReactivationAllowedFlag },
});

const setIsDefaultCriteriaFetchedFlag = ({ jobId, isDefaultCriteriaFetchedFlag }) => ({
  type: SET_IS_DEFAULT_CRITERIA_FETCHED_FLAG,
  payload: { jobId, isDefaultCriteriaFetchedFlag },
});

export const setIsPrefetchRequiredFlag = ({ jobId, isPrefetchRequiredFlag }) => ({
  type: SET_IS_PREFETCH_REQUIRED_FETCHED_FLAG,
  payload: { jobId, isPrefetchRequiredFlag },
});

export const setManualSearchSelectedCriteriaInfo = ({ jobId, criteriaInfo }) => ({
  type: SET_MANUAL_SEARCH_SELECTED_CRITERIA_INFO,
  payload: { jobId, criteriaInfo },
});

export {
  SET_MANUAL_SEARCH_PAYLOAD,
  SET_MANUAL_SEARCH_FORM_VALUES,
  SET_MANUAL_SEARCH_CRITERIA,
  SET_MANUAL_SEARCH_CRITERIA_BY_ID,
  SET_MANUAL_SEARCH_CRITERIA_IDENTIFIERS,
  SET_RETRIEVED_JOBACTIVITY_ID,
  RESET_MANUAL_SEARCH_JOBACTIVITY,
  SET_SELECTED_CRITERIA_NAME,
  SET_SELECTED_CRITERIA_TYPE,
  SET_SELECTED_CRITERIA_ID,
  SET_LAST_PERFORMED_MANUAL_SEARCH_TIME,
  SET_MANUAL_SEARCH_CRITERIA_RECORD_BY_ID,
  CLEAR_MANUAL_SEARCH_CRITERIA,
  SET_MANUAL_SEARCH_SOURCES,
  SET_IS_REACTIVATION_ALLOWED_FLAG,
  SET_BOOLEAN_SEARCH_STRINGS,
  SET_IS_DEFAULT_CRITERIA_FETCHED_FLAG,
  setManualSearchPayload,
  setManualSearchFormValues,
  setApplyManualSearchCriteriaApiStatus,
  setManualSearchCriteria,
  setManualSearchCriteriaById,
  setManualSearchCriteriaIdentifiers,
  setManualSearchDraftCriteriaFetchApiStatus,
  setFetchManualSearchCriteriaIdentifiersApiStatus,
  setFetchAryaGeneratedCriteriaApiStatus,
  setSaveManualSearchCriteriaApiStatus,
  setRetrievedJobActivityId,
  resetManualSearchJobActivity,
  setSelectedCriteriaName,
  setSelectedCriteriaType,
  setSelectedCriteriaId,
  setManualSearchLastPerformedTime,
  setfetchManualSearchCriteriaRecordApiStatus,
  setfetchManualSearchCriteriaRecordById,
  clearManualSearchCriteria,
  setManualSearchSources,
  setIsReactivationAllowedFlag,
  setBooleanSearchStrings,
  setIsDefaultCriteriaFetchedFlag,
};
