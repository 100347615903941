import React, { useCallback } from 'react';
import { FormInputComponent, FormRadioGroupComponent, FormCheckboxComponent } from '../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../Utils/MapperUtils';
import {
  clearMoreRejectReasonFormValues,
  locationMismatchChecked,
  educationMismatchChecked,
  certificationMismatchChecked,
  otherReasonChecked,
} from '../../Utils/CandidateRejectReasonsUtils';
import './MoreCandidateRejectReasonsContent.scss';

const educationMismatchOptions = { 'Less Qualified': 'lessQ', 'Over Qualified': 'overQ' };

export default function MoreCandidateRejectReasonsContent(props) {
  const {
    form,
    initialLocationMismatch,
    initialEducationMismatch,
    initialCertificationMismatch,
    initialMissingCertification,
    initialOtherReason,
    isLocationMismatchChecked,
    isEducationMismatchChecked,
    isCertificationMismatchChecked,
    isOtherReasonChecked,
  } = props;

  const moreRejectRef = React.useRef();
  const locationMismatchRef = React.useRef();
  const educationMismatchRef = React.useRef();
  const certificationMismatchRef = React.useRef();
  const otherReasonRef = React.useRef();

  const [currentScrollToRef, setCurrentScrollToRef] = React.useState();

  const fieldDecoratorToFieldWrapperRefMapper = {
    [locationMismatchChecked]: locationMismatchRef,
    [educationMismatchChecked]: educationMismatchRef,
    [certificationMismatchChecked]: certificationMismatchRef,
    [otherReasonChecked]: otherReasonRef,
  };

  const scrollToChildElement = currentElement => {
    moreRejectRef.current.scrollTop = currentElement.current.offsetTop;
  };

  const formValues = form.getFieldsValue();
  const isFormLocationMismatchChecked = formValues[locationMismatchChecked];
  const isFormEducationMismatchChecked = formValues[educationMismatchChecked];
  const isFormCertificationMismatchChecked = formValues[certificationMismatchChecked];
  const isFormOtherReasonChecked = formValues[otherReasonChecked];

  const _isLocationMismatchChecked = isFormLocationMismatchChecked ?? isLocationMismatchChecked;
  const _isEducationMismatchChecked = isFormEducationMismatchChecked ?? isEducationMismatchChecked;
  const _isCertificationMismatchChecked = isFormCertificationMismatchChecked ?? isCertificationMismatchChecked;
  const _isOtherReasonChecked = isFormOtherReasonChecked ?? isOtherReasonChecked;

  React.useEffect(() => {
    if (currentScrollToRef) scrollToChildElement(currentScrollToRef);
  }, [currentScrollToRef]);

  const onRejectReasonCheckboxClick = useCallback(
    event => {
      const fieldDecoratorKey = event.target.id;
      if (!event.target.checked) {
        clearMoreRejectReasonFormValues(form, fieldDecoratorKey);
        setCurrentScrollToRef(undefined);
      } else {
        setCurrentScrollToRef(fieldDecoratorToFieldWrapperRefMapper[fieldDecoratorKey]);
      }
    },
    [form]
  );

  return (
    <div className="more-reject-reasons-content" ref={moreRejectRef}>
      <div className="location-mismatch-wrapper" ref={locationMismatchRef}>
        <FormCheckboxComponent
          form={form}
          displayText="Location Mismatch"
          fieldDecoratorValue={locationMismatchChecked}
          formItemClassName="more-reject-reasons-checkbox"
          initialValue={isLocationMismatchChecked}
          onChange={onRejectReasonCheckboxClick}
        />
        {_isLocationMismatchChecked ? (
          <FormInputComponent
            placeholder="Eg. Raleigh, North Carolina, etc"
            form={form}
            fieldDecoratorValue="LOCATION_MISMATCH"
            initialValue={initialLocationMismatch}
          />
        ) : null}
      </div>
      <div className="education-mismatch-wrapper" ref={educationMismatchRef}>
        <FormCheckboxComponent
          form={form}
          displayText="Education Mismatch"
          fieldDecoratorValue={educationMismatchChecked}
          formItemClassName="more-reject-reasons-checkbox"
          initialValue={isEducationMismatchChecked}
          onChange={onRejectReasonCheckboxClick}
        />
        {_isEducationMismatchChecked ? (
          <FormRadioGroupComponent
            className="education-mismatch-radio-buttons"
            fieldDecoratorValue="EDUCATION_MISMATCH"
            form={form}
            initialValue={initialEducationMismatch}
            inputValues={formValuesMapper(educationMismatchOptions)}
          />
        ) : null}
      </div>
      <div className="certification-mismatch-wrapper" ref={certificationMismatchRef}>
        <FormCheckboxComponent
          form={form}
          displayText="Certification Mismatch"
          fieldDecoratorValue={certificationMismatchChecked}
          formItemClassName="more-reject-reasons-checkbox"
          initialValue={isCertificationMismatchChecked}
          onChange={onRejectReasonCheckboxClick}
        />
        {_isCertificationMismatchChecked ? (
          <>
            {' '}
            <FormInputComponent
              form={form}
              label="Irrelevant Certification"
              fieldDecoratorValue="IRRELEVANT_CERTIFICATION"
              initialValue={initialCertificationMismatch}
              placeholder="Eg. PMP, CSM, etc"
            />
            <FormInputComponent
              form={form}
              label="Missing required certification"
              fieldDecoratorValue="MISSING_REQUIRED_CERTIFICATION"
              initialValue={initialMissingCertification}
              placeholder="Eg. PMP, CSM, etc"
            />
          </>
        ) : null}
      </div>
      <div className="other-reason-mismatch-wrapper" ref={otherReasonRef}>
        <FormCheckboxComponent
          form={form}
          displayText="Other Reason"
          fieldDecoratorValue={otherReasonChecked}
          formItemClassName="more-reject-reasons-checkbox"
          initialValue={isOtherReasonChecked}
          onChange={onRejectReasonCheckboxClick}
        />
        {_isOtherReasonChecked ? (
          <FormInputComponent
            form={form}
            fieldDecoratorValue="OTHER_REASON"
            initialValue={initialOtherReason}
            placeholder="Enter other reasons"
          />
        ) : null}
      </div>
    </div>
  );
}
