import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { getConfig } from '../../../Reducers/ConfigReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getReportListPageSize, getReportColumns as _getReportColumns } from '../../../Reducers/ReportPortalReducer';
import { setReportListPageSize as _setReportListPageSize } from '../../../Actions/ActionCreators/ReportPortalActionCreator';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import ReportView from '../../../Components/ReportPortal/ReportStatsTab/ReportView/ReportView';

const mapStateToProps = state => {
  return {
    userConfig: getConfig(state),
    pageSize: getReportListPageSize(state),
    fetchReportColumnsApiStatus: getApiStatus(state, 'fetchReportColumnsApiStatus'),
    saveReportQueryApiStatus: getApiStatus(state, 'saveReportQueryApiStatus'),
    reportColumns: _getReportColumns(state),
  };
};

const mapDispatchToProps = {
  setReportListPageSize: _setReportListPageSize,
  clearExportReportApiStatus: ReportPortalActions.clearExportReportApiStatus,
  saveReportQuery: ReportPortalActions.saveReportQuery,
  clearSaveReportQueryApiStatus: ReportPortalActions.clearSaveReportQueryApiStatus,
};

export function ReportViewContainer(props) {
  const {
    pageSize,
    setReportListPageSize,
    title,
    subtitle,
    filterConfig,
    reportStats,
    getReportStats,
    fetchReportStatsApiStatus,
    fetchReportColumnsApiStatus,
    exportReport,
    exportReportApiStatus,
    clearExportReportApiStatus,
    reportColumns,
    savedReportDetails,
    onSaveQuerySuccess,
    saveReportQueryApiStatus,
    saveReportQuery,
    clearSaveReportQueryApiStatus,
    type,
  } = props;

  const [pageNumber, setReportListPageNumber] = useState(1);

  useEffect(() => {
    if (exportReportApiStatus?.status?.toUpperCase() === 'COMPLETED') {
      Modal.info({
        title: 'Your report is being generated.',
        content: 'This may take some time. You will receive a notification upon completion to download report.',
        okText: 'Close',
        okButtonProps: {
          shape: 'round',
        },
        onOk: clearExportReportApiStatus,
      });
    }
  }, [exportReportApiStatus]);

  const reportColumnList = reportColumns?.[type];

  return (
    <div>
      <ReportView
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setReportListPageSize={setReportListPageSize}
        setReportListPageNumber={setReportListPageNumber}
        title={title}
        subtitle={subtitle}
        filterConfig={filterConfig}
        reportStats={reportStats}
        getReportStats={getReportStats}
        exportReportApiStatus={exportReportApiStatus}
        exportReport={exportReport}
        reportColumns={reportColumnList}
        fetchReportColumnsApiStatus={fetchReportColumnsApiStatus}
        savedReportDetails={savedReportDetails}
        onSaveQuerySuccess={onSaveQuerySuccess}
        saveReportQueryApiStatus={saveReportQueryApiStatus}
        onSaveQuery={saveReportQuery}
        clearSaveReportQueryApiStatus={clearSaveReportQueryApiStatus}
        type={type}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewContainer);
