import React from 'react';
import { Form, Input, Button, Modal } from 'antd';
import styles from './AddPhoneNumber.module.scss';

class AddPhoneNumber extends React.Component {
  handleSubmit = e => {
    const { form, changeState, addPhone, onChangePhone, phones } = this.props;
    const { validateFields, getFieldValue } = form;
    const currentPhone = getFieldValue('phone');
    const trimmedCurrentPhone = currentPhone?.trim();
    const isPhoneNumberExist = !!phones.find(entry => entry.Number === trimmedCurrentPhone);

    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values.phone);
        if (changeState) changeState();
        if (!isPhoneNumberExist) addPhone(values.phone);
        if (onChangePhone) onChangePhone(values.phone);
      }
    });
  };

  validatePhone = (rule, value, callback) => {
    const { form, phones } = this.props;
    const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const phone = form.getFieldValue('phone');
    const trimmedPhone = phone?.trim();
    const isPhoneNumberExist = !!phones.find(entry => entry.Number === trimmedPhone);
    if (isPhoneNumberExist) {
      callback('Phone number already exists.');
    }
    if (!phone.trim().length || regex.test(phone)) {
      callback();
      // Valid international phone number
    }
    callback('Invalid number. Please start with country code.');
  };

  render() {
    const { form, skEventName, isAddPhoneFormVisible, setAddPhoneFormVisibility } = this.props;
    const { getFieldDecorator } = form;
    const phonePlaceHolder = 'Enter phone number';

    return (
      <Modal
        title="Add Phone"
        centered
        visible={isAddPhoneFormVisible}
        onCancel={() => setAddPhoneFormVisibility(false)}
        footer={[
          <Button shape="round" key="back" onClick={() => setAddPhoneFormVisibility(false)}>
            Cancel
          </Button>,
          <Button shape="round" key="submit" onClick={this.handleSubmit} type="primary" sk-event-name={skEventName}>
            Save
          </Button>,
        ]}
        zIndex={2223}
      >
        <Form>
          <div className={styles.modalContent}>
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    validator: this.validatePhone,
                  },
                ],
              })(<Input placeholder={phonePlaceHolder} className={styles.phoneInput} />)}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

export { AddPhoneNumber as AddPhoneNumberWithoutForm };

export default Form.create({ name: 'coordinated' })(AddPhoneNumber);
