import React from 'react';
import { Row, Col, Tooltip, Button, Steps } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SourcingProgressIndicator from '../SourcingProgressIndicator/SourcingProgressIndicator';
import './JobListItem.scss';
import JobStatus from '../JobStatus/JobStatus';
import CollaboratorsContainer from '../../Containers/CollaboratorsContainer/CollaboratorsContainer';
import JobTags from '../../Containers/JobTags/JobTags';
import { getSourceName, getShowSourcingProgress } from '../../Utils/SourceUtils';
import { ScoutingAgentFilledSvg } from '../../Icons/ScoutingAgentIcon';
import { TagSelectedIcon, TagNonSelectedIcon } from '../../Icons/AryaIcons';
import { getIsFeaturesEnabled } from '../../Utils/FeatureMapper.ts';
import CandidatePipeline from '../../Containers/CandidatePipeline/CandidatePipeline';
import { isPulseUser } from '../../Utils/ConfigUtils';
import eventTypes from '../../Analytics/EventTypes';
import { getScoutingAgentsofEnabledJobs } from '../../Reducers/ScoutingAgentReducer';

const { Step } = Steps;

export function ServiceStatusSteps({ orderStatusDetails, productVariantDisplayName }) {
  if (!orderStatusDetails) return null;
  const serviceStatusList = [
    { basicPlan: productVariantDisplayName },
    { VettedPending: 'Vetted Candidate Pending', VettedPublished: 'Vetted Candidate Published' },
    {
      premiumPlan: `${productVariantDisplayName}`,
      premiumEngagedPending: `${productVariantDisplayName} (Upgrade Pending)`,
      premiumEngagedRequested: `${productVariantDisplayName} (Engaged Requested)`,
    },
    { EngagedPending: 'Engaged Candidates Pending', EngagedPublished: 'Engaged Candidates Published' },
    {
      freePlan: `${productVariantDisplayName}`,
      freeVettedPending: 'Vetted Candidate Pending',
      freeVettedPublished: 'Vetted Candidate Published',
    },
  ];

  const jobSubscriptionPlan = orderStatusDetails.ProductVariantName;
  const jobOrderStatus = orderStatusDetails.Status;
  let currentStatus = 0;
  if (jobSubscriptionPlan === 'FreeTrial') {
    if (jobOrderStatus === 'OpenForCancellation' || jobOrderStatus === 'Completed') currentStatus = 1;
  }

  if (jobSubscriptionPlan === 'Basic') {
    if (jobOrderStatus === 'OpenForCancellation' || jobOrderStatus === 'Completed') currentStatus = 1;
  }

  if (jobSubscriptionPlan === 'Premium') {
    if (jobOrderStatus === 'OpenForCancellation' || jobOrderStatus === 'Completed') currentStatus = 1;
  }
  switch (jobSubscriptionPlan) {
    case 'FreeTrial':
      return (
        <div className="free-service-status-steps">
          <Steps size="small" current={currentStatus}>
            <Step title={serviceStatusList[4].freePlan} />
            <Step
              title={`${
                jobOrderStatus === 'OpenForCancellation' || jobOrderStatus === 'Completed'
                  ? serviceStatusList[4].freeVettedPublished
                  : serviceStatusList[4].freeVettedPending
              }`}
            />
            <Step title={serviceStatusList[2].premiumEngagedPending} />
          </Steps>
        </div>
      );

    case 'Basic':
      return (
        <div className="basic-service-status-steps">
          <Steps size="small" current={currentStatus}>
            <Step title={serviceStatusList[0].basicPlan} />
            <Step
              title={`${
                jobOrderStatus === 'OpenForCancellation' || jobOrderStatus === 'Completed'
                  ? serviceStatusList[1].VettedPublished
                  : serviceStatusList[1].VettedPending
              }`}
            />
            <Step title={serviceStatusList[2].premiumEngagedPending} />
            <Step title={serviceStatusList[3].EngagedPending} />
          </Steps>
        </div>
      );

    case 'Premium':
      return (
        <div className="premium-service-status-steps">
          <Steps size="small" current={currentStatus}>
            <Step title={serviceStatusList[2].premiumPlan} />
            <Step
              title={`${
                jobOrderStatus === 'OpenForCancellation' || jobOrderStatus === 'Completed'
                  ? serviceStatusList[3].EngagedPublished
                  : serviceStatusList[3].EngagedPending
              }`}
            />
          </Steps>
        </div>
      );

    default:
      return null;
  }
}

function handleJobCardClick(isPulse, history, isServiceRep, job, isDraftJob, redirectPath) {
  let link = `/jobs/${job.JobId}${redirectPath}`;
  if (job?.IsAdvancedJob && isDraftJob) link = `/jobs-v2/${job.JobId}/review`;
  if (isPulse && !isServiceRep) history.push(link);
}
function getJobCardStyle(isPulse, isServiceRep) {
  if (isPulse && !isServiceRep) {
    return { cursor: 'pointer' };
  }
  return { cursor: 'default' };
}

const mapStateToProps = (state, props) => ({
  scoutingActiveAgentCount: getScoutingAgentsofEnabledJobs(state, props.job.JobId),
});

function JobListItem(props) {
  const {
    job,
    onJobStatusChange,
    jobStatus,
    usersById,
    history,
    countries = [],
    userConfig,
    isTagSelected,
    toggleJobCard,
    featureToggleList,
    orderStatusDetails,
    productVariantsById,
    setShowDrawer,
    scoutingActiveAgentCount,
    scoutingAgentConfig,
  } = props;
  const [scoutingIconEnabled, setScoutingIconEnabled] = React.useState(false);

  React.useEffect(() => {
    setScoutingIconEnabled(scoutingActiveAgentCount > 0);
  }, [scoutingActiveAgentCount]);

  const { CountryCode, aryaState } = job;
  const sources = _.get(userConfig, 'PortalSources', []);
  const countryObject = countries.find(country => {
    if (country.Iso2Code === CountryCode) {
      return country;
    }
    return null;
  });
  const sourcingStatus = !sources.every(source => {
    const sourceName = getSourceName(source).toLowerCase();
    return !_.get(aryaState, [sourceName, 'IsActivated'], false);
  });

  let allUsers = []; // this list include shared user and user created that job
  if (usersById) {
    allUsers = allUsers.concat(job.SharedWith || []);
    allUsers = allUsers.concat(job.AssignedTo || []);
  }
  let users = _.uniq(allUsers).map(userId => {
    return {
      name: usersById[userId] ? usersById[userId].FullName : null,
      Id: userId,
      Email: usersById[userId] ? usersById[userId].Email : null,
    };
  });
  users = users.filter(user => !!user);

  const jobTagsCount = _.get(job, ['Tags'], []).length;

  const [isJobStatusEnabled, isJobSharingEnabled, isCandidatePipelineEnabled, isOrderProgressEnabled] =
    getIsFeaturesEnabled(featureToggleList, ['JobStatus', 'ShareJob', 'CandidatePipeline', 'OrderProgress']);

  const showSourcingProgress = getShowSourcingProgress(featureToggleList);
  const displayScoutingAgentIcon = scoutingIconEnabled && scoutingAgentConfig;

  const orderStatus = _.get(orderStatusDetails, 'Status', '');
  const onPipelineItemClick = pipelineName => {
    history.push(`/jobs/${job.JobId}/candidates?status=${pipelineName}`);
  };

  let orderProgressStep = -1;
  if (_.get(orderStatusDetails, 'Status', '').toLowerCase() === 'success') orderProgressStep = 0;
  else if (_.get(orderStatusDetails, 'Status', '').toLowerCase() === 'inprogress') orderProgressStep = 1;
  else if (['completed', 'openforcancellation'].includes(_.get(orderStatusDetails, 'Status', '').toLowerCase()))
    orderProgressStep = 2;

  const productVariantId = _.get(orderStatusDetails, 'ProductVariantId', '');
  const productVariant = productVariantsById[productVariantId];
  const productVariantName = _.get(productVariant, 'Name', null);
  const productVariantDisplayName = _.get(productVariant, 'DisplayName', null);
  const isDraftJob = job.Status === 'Draft';
  const redirectPath = isDraftJob ? `/edit` : '';
  const planClassName = productVariantName ? productVariantName.trim().replace(' ', '-').toLowerCase() : 'default';

  const jobTags = job.Tags ?? [];
  const { Role: role } = userConfig;
  const isPulse = isPulseUser();
  const isServiceRep = role === 'Service Rep';
  const onClickJobCard = () => handleJobCardClick(isPulse, history, isServiceRep, job, isDraftJob, redirectPath);

  const jobCardDivStyle = getJobCardStyle(isPulse, isServiceRep);
  const handleScoutingAgentIconClick = () => {
    history.push(`${history.location.pathname}?jobId=${job.JobId}`);
    setShowDrawer(true);
  };

  return (
    <div className="job-card-details" onClick={onClickJobCard} style={jobCardDivStyle} role="presentation">
      <Row key={job.name} className="job-card" type="flex" justify="space-between">
        <Col xs={24} sm={5}>
          <Link
            to={{
              pathname:
                job?.IsAdvancedJob && isDraftJob ? `/jobs-v2/${job.JobId}/review` : `/jobs/${job.JobId}${redirectPath}`,
            }}
          >
            <div className="job-title-wrapper" sk-event-name={eventTypes.job.viewJob.goToJobDetails}>
              <Tooltip title={job.JobTitle}>
                <span className="job-title" sk-event-name={eventTypes.job.viewJob.goToJobDetails}>
                  {job.JobTitle}
                </span>
              </Tooltip>
              {job.JobCode ? (
                <div className="job-external-source">
                  <Tooltip title={job.JobCode}>
                    <span className="job-external-source-text ">E</span>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </Link>
          <div className="job-id-time">
            <div className="job-id" display="inline">
              {job.Status === 'Draft' ? <p className="draft-job-icon">{job.Status}</p> : job.JobId}
            </div>
            <div className="job-id-time-dot" />
            <div className="job-time">{moment.utc(job.ModifiedDate).local().fromNow()}</div>
          </div>
        </Col>
        <Col xs={24} sm={5}>
          <div className="client-name">
            <Tooltip title={job.Client}>{job.Client}</Tooltip>
          </div>

          <div className="client-location">
            {job.Location ? `${job.Location}, ` : ''} {_.get(countryObject, 'Name', '')}
          </div>
        </Col>
        {isJobSharingEnabled ? (
          <Col className="shared-users" xs={24} sm={3}>
            <CollaboratorsContainer collaborators={users} jobId={job.JobId} shareSize="small" />
          </Col>
        ) : null}
        {isCandidatePipelineEnabled ? (
          <Col className="candidate-col" xs={24} sm={5}>
            <div style={{ width: '75%', cursor: 'pointer' }}>
              <CandidatePipeline
                onPipelineItemClick={onPipelineItemClick}
                size="small"
                activeByDefault
                jobId={job.JobId}
                showRejected={false}
                pipelineContext="jobsPage"
              />
            </div>
          </Col>
        ) : null}
        {showSourcingProgress ? (
          <Col xs={24} sm={3}>
            <div
              onClick={() => {
                if (!featureToggleList.CandidateManualSearch.IsEnabled) props.onSourcingProgressView(job.JobId);
              }}
              className="source-button"
              style={{
                marginTop: '-22px',
              }}
              role="presentation"
            >
              <div
                style={{
                  pointerEvents: featureToggleList.CandidateManualSearch.IsEnabled ? 'none' : 'all',
                }}
              >
                <SourcingProgressIndicator
                  jobSourcingStats={job.sourcingStats}
                  recentSourcedTime={job.recentSourcedTime}
                  lastChangeTime={job.lastChangeTime}
                  sourcingStatus={sourcingStatus}
                  featureToggleList={featureToggleList}
                  showSourcingProgress={showSourcingProgress}
                />
              </div>
              {displayScoutingAgentIcon && (
                <div className="scouting-content">
                  <div className="scouting-agent-icon" role="presentation" onClick={handleScoutingAgentIconClick}>
                    <ScoutingAgentFilledSvg width="16" height="16" fill="#83878C" />
                  </div>
                </div>
              )}
            </div>
          </Col>
        ) : null}
        {isJobStatusEnabled ? (
          <Col xs={24} sm={2}>
            <JobStatus
              jobStatus={jobStatus}
              onJobStatusChange={onJobStatusChange}
              jobId={job.JobId}
              status={job.Status}
              job={job}
            />
          </Col>
        ) : null}
        {isOrderProgressEnabled ? (
          <Col xs={24} sm={3}>
            <div className={`subscription-plan-label ${planClassName}-plan-label-color`}>
              <div className={`subscription-plan-text ${planClassName}-plan-text-color`}>
                {productVariantDisplayName || 'Not Selected'}
              </div>
            </div>
          </Col>
        ) : null}
        {isOrderProgressEnabled ? (
          <Col xs={24} sm={{ span: 11 }}>
            {orderStatus.toLowerCase() === 'cancelled' ? (
              <div className="order-status-cancelled-text">Order Cancelled</div>
            ) : (
              <div className="order-status-step">
                <Steps
                  current={orderProgressStep}
                  size="small"
                  direction="horizontal"
                  style={{ paddingLeft: '70px', paddingRight: '45px' }}
                  status={orderProgressStep === 2 ? 'finish' : 'process'}
                >
                  <Step />
                  <Step />
                  <Step />
                </Steps>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                  <div className={`status-text ${orderProgressStep === 0 ? 'status-text-color' : ''}`}>
                    Order Submitted
                  </div>
                  <div className={`status-text ${orderProgressStep === 1 ? 'status-text-color' : ''}`}>
                    Source Candidates
                  </div>
                  <div className="status-text">Review Candidates</div>
                </div>
              </div>
            )}
          </Col>
        ) : null}
        <Col xs={24} sm={1} className="status-tag">
          {jobTagsCount && featureToggleList.JobTag.IsEnabled ? (
            <Button
              className="tag-icon-button"
              onClick={() => toggleJobCard(job.JobId, _.get(job, ['IsTagSelected'], false))}
            >
              {jobTagsCount}
              {isTagSelected ? (
                <TagSelectedIcon className="tag-icon-image" />
              ) : (
                <TagNonSelectedIcon className="tag-icon-image" />
              )}
            </Button>
          ) : null}
        </Col>
      </Row>

      {featureToggleList.WhiteGloveServiceProgress.IsEnabled ? (
        <ServiceStatusSteps
          orderStatusDetails={orderStatusDetails}
          productVariantDisplayName={productVariantDisplayName}
        />
      ) : null}

      <CSSTransition in={isTagSelected} timeout={400} classNames="job-tags-transition" unmountOnExit appear>
        <JobTags tags={jobTags} />
      </CSSTransition>
    </div>
  );
}
JobListItem.propTypes = {
  job: PropTypes.shape({
    JobId: PropTypes.number,
    name: PropTypes.string,
    ModifiedDate: PropTypes.string,
    Client: PropTypes.string,
    Location: PropTypes.string,
  }).isRequired,
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onJobStatusChange: PropTypes.func.isRequired,
  onSourcingProgressView: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

// JobListItem.defaultProps = {
//   onSourcingProgressView: () => {},
// };

export default connect(mapStateToProps)(JobListItem);

export { JobListItem as JobListItemWithoutStore };
