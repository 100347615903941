const emailStatsTitles = {
  Sent: 'Email sent',
  Opened: 'Email opened',
  NotOpened: 'Email not opened',
  Clicked: 'Email clicked',
  Replied: 'Email replied',
  NotSent: 'Email not sent',
};

const emailStatsTitleForSegments = {
  Sent: 'Sent',
  Opened: 'Opened',
  Clicked: 'Clicked',
  NotContacted: 'Not Sent',
  Success: 'Not Opened',
  Skipped: 'Skipped',
  Failed: 'Failed',
};

const emailStatusValueForSegmemnts = {
  Sent: 'Sent',
  Opened: 'Opened',
  Success: 'NotViewed',
  Clicked: 'Clicked',
  NotContacted: 'NotContacted',
  Skipped: 'Skipped',
  Failed: 'Failed',
};

const emailStatsTooltipTexts = {
  Clicked: 'The total no. of candidates engaged with one or more actionable links within an email',
};
export { emailStatsTitles, emailStatsTooltipTexts, emailStatsTitleForSegments, emailStatusValueForSegmemnts };
