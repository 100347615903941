import { Button } from 'antd';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { ScoutingAgentOutlinedIcon } from '../../../Icons/AryaIcons';
import styles from './ScoutingAgentNotification.module.scss';

function getNotificationInfo(type, payload) {
  const { LatestFetchedCandidateCount, ScoutingAgentName, EndDate } = payload;
  switch (type) {
    case 'ScoutingAgent.ExpiringSoon': {
      return (
        <div className="message">{`${ScoutingAgentName} is expiring in ${moment.utc(EndDate).local().fromNow()}`}</div>
      );
    }
    case 'ScoutingAgent.CandidatesFound': {
      return (
        <div className="message">
          We have found <b>{LatestFetchedCandidateCount}</b> candiates based on the {ScoutingAgentName}
        </div>
      );
    }
    case 'ScoutingAgent.Deleted': {
      return <div className="message">{ScoutingAgentName} has been deleted</div>;
    }
    case 'ScoutingAgent.Stopped': {
      return <div className="message">{ScoutingAgentName} has been stopped</div>;
    }
    case 'ScoutingAgent.CandidatesFilled': {
      return <div className="message">{ScoutingAgentName} has reached the maximum candidate limit</div>;
    }
    default: {
      return <div></div>;
    }
  }
}

const ScoutingAgentNotification = props => {
  const { notification, publishedTime, history, onClick, setJobCandidateActiveTab } = props;
  const { Payload: payload, Type: type } = notification;
  const { JobId, JobTitle } = payload;
  const handleViewCandidate = async () => {
    await setJobCandidateActiveTab({ activeTab: 'sourced' });
    history.push(`/jobs/${JobId}/candidates?status=sourced&activeTab=scoutingAgent`);
    onClick(notification);
  };

  return (
    <div className={classNames(styles.scoutingAgentNotificationContainer, 'notification')} role="presentation">
      <div className={styles.scoutingAgentNotificationIconWrapper}>
        <div className={styles.scoutingAgentIcon}>
          <ScoutingAgentOutlinedIcon width="20" height="20" style={{ fontSize: '26px', color: '#B4B7BA' }} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.notificationTitle}>
          <span className="bold">
            {JobTitle}, {JobId}
          </span>
        </div>
        {getNotificationInfo(type, payload)}
        <div className="actions" />
        <div className="since">{publishedTime}</div>
        <div className={styles.actionButton}>
          <Button shape="round" onClick={handleViewCandidate}>
            View Candidates
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ScoutingAgentNotification);
export { ScoutingAgentNotification as ScoutingAgentNotificationWithoutRouter };
