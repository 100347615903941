import React from 'react';
import { connect } from 'react-redux';
import { getUsersById, getMaxCount, getUserListForShareJob } from '../../Reducers/UserReducer';
import {
  getSharedUsers,
  getJobOwner,
  getSharedUsersForSegment,
  getJobOwnerForSegment,
} from '../../Reducers/JobReducer';
import ShareJob from '../../Components/ShareJob/ShareJob';
import * as UserActions from '../../Actions/UserActions';
import * as JobActions from '../../Actions/JobActions';
import Collaborators from '../../Components/Collaborators/Collaborators';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';

const mapStateToProps = (state, { jobId, candidateContext }) => ({
  usersById: getUsersById(state),
  userListForShareJob: getUserListForShareJob(state),
  sharedUsers: candidateContext === 'segment' ? getSharedUsersForSegment(state, jobId) : getSharedUsers(state, jobId),
  jobOwner: candidateContext === 'segment' ? getJobOwnerForSegment(state, jobId) : getJobOwner(state, jobId),
  maxCount: getMaxCount(state),
  CurrentUser: getCurrentUser(state),
});
const mapDispatchToProps = {
  fetchUsersForShare: UserActions.fetchUsersForDropdownLists,
  clearDropdownLists: UserActions.clearDropdownLists,
  shareJob: JobActions.shareJob,
};

class CollaboratorsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUsersPopup: false,
    };
    this.onAddNewCollaborator = this.onAddNewCollaborator.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onShare = this.onShare.bind(this);
  }

  onAddNewCollaborator() {
    const { fetchUsersForShare } = this.props;
    this.setState({
      showUsersPopup: true,
    });
    fetchUsersForShare({ page: 1, isActive: true, listFor: 'ShareJob' });
  }

  onClosePopup() {
    const { fetchUsersForShare } = this.props;
    this.setState({
      showUsersPopup: false,
    });
    fetchUsersForShare({ page: 1, isActive: true, listFor: 'ShareJob' });
  }

  onShare(sharedWith, unsharedWith) {
    const { shareJob, jobId, parent, toShareJob } = this.props;
    if (parent !== 'job-form') {
      shareJob(jobId, { sharedWith, unsharedWith });
    } else {
      toShareJob(sharedWith, unsharedWith);
    }
    this.setState({
      showUsersPopup: false,
    });
  }

  render() {
    const {
      collaborators,
      usersById,
      parent,
      shareSize,
      maxCount,
      fetchUsersForShare,
      userListForShareJob,
      CurrentUser,
      jobOwner,
      candidateContext,
    } = this.props;
    let { sharedUsers } = this.props;
    const { showUsersPopup } = this.state;
    const currentUserEmailId = CurrentUser.email;
    if (!showUsersPopup) {
      return (
        <Collaborators
          collaborators={collaborators}
          onAddNew={this.onAddNewCollaborator}
          shareSize={shareSize}
          currentUserEmailId={currentUserEmailId}
          jobOwner={jobOwner}
        />
      );
    }
    if (parent === 'job-form') {
      sharedUsers = collaborators?.map(collaborator => collaborator.Id);
    }

    return (
      <ShareJob
        userIds={userListForShareJob}
        isVisible={showUsersPopup}
        fetchUsersForShare={fetchUsersForShare}
        onClose={this.onClosePopup}
        sharedUsers={sharedUsers}
        onShare={this.onShare}
        maxCount={maxCount}
        usersById={usersById}
        currentUserEmailId={currentUserEmailId}
        jobOwner={jobOwner}
        candidateContext={candidateContext}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsContainer);

export { CollaboratorsContainer };
