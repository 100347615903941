import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Pagination, Tabs } from 'antd';
import _ from 'lodash';
import BaseTemplateContainer from '../BaseTemplatesContainer';
import TemplateHeader from '../../../Components/Connect/TemplateHeader/TemplateHeader';
import CreateAryabotTemplate from '../../../Components/Connect/BaseTemplateForm/CreateAryabotTemplate/CreateAryabotTemplate';
import UserAryaBotTemplateRow from '../../../Components/Connect/TemplateRow/UserAryabotTemplateRow/UserAryabotTemplateRow';
import SharedAryaBotTemplateRow from '../../../Components/Connect/TemplateRow/SharedAryabotTemplateRow/SharedAryabotTemplateRow';
import * as AryabotTemplatesActions from '../../../Actions/AryabotTemplates';
import {
  getCurrentAryabotTemplateIds,
  getAryabotTemplatesById,
  getAryabotTemplatesCount,
} from '../../../Reducers/AryabotTemplates';
import * as UserActions from '../../../Actions/UserActions';
import {
  getConnectUsersById,
  getUsersByGuId,
  getUserListForShareTemplate,
  getConnectMaxCount,
} from '../../../Reducers/UserReducer';
import { getWhiteLabelInformation } from '../../../Reducers/ConfigReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { connectTemplateNameByType } from '../../../Utils/ConnectUtils';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  currentAryabotTemplateIds: getCurrentAryabotTemplateIds(state),
  aryabotTemplatesById: getAryabotTemplatesById(state),
  aryabotTemplatesCount: getAryabotTemplatesCount(state),
  connectUsersById: getConnectUsersById(state),
  usersByGuId: getUsersByGuId(state),
  userListForShareTemplate: getUserListForShareTemplate(state),
  maxCount: getConnectMaxCount(state),
  CurrentUser: getCurrentUser(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
});

const mapDispatchToProps = {
  searchAryabotTemplates: AryabotTemplatesActions.searchAryabotTemplates,
  createAryabotTemplate: AryabotTemplatesActions.createAryabotTemplate,
  duplicateAryabotTemplate: AryabotTemplatesActions.duplicateAryabotTemplate,
  editAryabotTemplate: AryabotTemplatesActions.editAryabotTemplate,
  getAryabotTemplate: AryabotTemplatesActions.getAryabotTemplate,
  getQuestions: AryabotTemplatesActions.getQuestions,
  deleteAryabotTemplate: AryabotTemplatesActions.deleteAryabotTemplate,
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
  shareAryabotTemplate: AryabotTemplatesActions.shareAryabotTemplate,
};

class AryabotTemplatesContainer extends BaseTemplateContainer {
  constructor(props) {
    super(props);
    this.state = {
      createDrawerVisibility: false,
      editDrawerVisibility: false,
      page: 1,
      pageSize: 10,
      searchQuery: '',
      shared: false,
    };

    this.searchTemplates = this.searchTemplates.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
  }

  componentDidMount() {
    this.searchTemplates();
  }

  showEditDrawer(templateId) {
    const { getQuestions } = this.props;
    getQuestions(templateId);
    this.setEditTemplateId(templateId);
    this.setState({
      editDrawerVisibility: true,
    });
  }

  searchTemplates() {
    const { page, pageSize, searchQuery, shared } = this.state;
    const { searchAryabotTemplates } = this.props;
    searchAryabotTemplates({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    });
  }

  createTemplate(templateName, isPublic, questions) {
    const { createAryabotTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    createAryabotTemplate(templateName, isPublic, questions, filter);
    this.closeCreateDrawer();
  }

  duplicateTemplate(templateId) {
    const { duplicateAryabotTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    duplicateAryabotTemplate(templateId, filter);
  }

  editTemplate(templateName, isPublic, questions) {
    const { editAryabotTemplate } = this.props;
    const { page, pageSize, searchQuery, shared, editTemplateId } = this.state;
    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };
    editAryabotTemplate(editTemplateId, templateName, isPublic, questions, filter);
    this.closeEditDrawer();
  }

  deleteTemplate(templateId) {
    const { deleteAryabotTemplate } = this.props;
    const { page, pageSize, searchQuery, shared } = this.state;

    const filter = {
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchQuery,
      Shared: shared,
    };

    deleteAryabotTemplate(templateId, filter);
  }

  render() {
    const {
      aryabotTemplatesById,
      currentAryabotTemplateIds,
      aryabotTemplatesCount,
      connectUsersById,
      maxCount,
      CurrentUser,
      usersByGuId,
      userListForShareTemplate,
      shareAryabotTemplate,
      fetchUsersForShare,
      whiteLabelInfo,
    } = this.props;
    const { createDrawerVisibility, editDrawerVisibility, editTemplateId, page, pageSize, shared } = this.state;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');

    return (
      <div className="aryabot-templates">
        <TemplateHeader
          onClick={this.showCreateDrawer}
          // onSearch={this.onSearch}
          onChange={event => this.onSearch(event.target.value)}
          heading="Chatbot Templates"
          placeholder="Search Chatbot Templates"
          type={connectTemplateNameByType.bot}
        />
        {createDrawerVisibility ? (
          <CreateAryabotTemplate
            visible={createDrawerVisibility}
            onClose={this.closeCreateDrawer}
            title="Chatbot Template"
            onSubmit={this.createTemplate}
            initialValues={{}}
            AppName={AppName}
          />
        ) : null}
        {editDrawerVisibility ? (
          <CreateAryabotTemplate
            visible={editDrawerVisibility}
            onClose={this.closeEditDrawer}
            title="Chatbot Template"
            onSubmit={this.editTemplate}
            initialValues={aryabotTemplatesById[editTemplateId]}
            viewOnly={shared}
            AppName={AppName}
          />
        ) : null}

        <Tabs defaultActiveKey="my-templates" onChange={this.onTabChange}>
          <TabPane tab="My Templates" key="my-templates">
            <Row className="aryabot template-table-header">
              <Col span={8}>TEMPLATE</Col>
              <Col span={6}>SHARED</Col>
              <Col span={6}>UPDATED ON</Col>
            </Row>
            {currentAryabotTemplateIds.map(templateId => {
              const aryabotTemplate = aryabotTemplatesById[templateId];
              return (
                <UserAryaBotTemplateRow
                  key={aryabotTemplate.Id}
                  title={aryabotTemplate.Name}
                  createdTime={aryabotTemplate.CreatedTime}
                  updatedTime={aryabotTemplate.UpdatedTime}
                  duplicateTemplate={this.duplicateTemplate}
                  deleteTemplate={this.deleteTemplate}
                  editTemplate={this.showEditDrawer}
                  templateId={templateId}
                  isPublic={aryabotTemplate.IsPublic}
                  shareAryabotTemplate={shareAryabotTemplate}
                  usersByGuId={usersByGuId}
                  userListForShareTemplate={userListForShareTemplate}
                  sharedUsers={_.uniq(aryabotTemplatesById[templateId].SharedWith || [])}
                  maxCount={maxCount}
                  CurrentUser={CurrentUser}
                  fetchUsersForShare={fetchUsersForShare}
                />
              );
            })}
            <Pagination
              showSizeChanger
              size="small"
              total={aryabotTemplatesCount}
              onChange={this.onPageChange}
              onShowSizeChange={this.onShowSizeChange}
              hideOnSinglePage={aryabotTemplatesCount <= 10}
              current={page}
              pageSize={pageSize}
              className="connect-templates-pagination"
            />
          </TabPane>
          <TabPane tab="Shared Templates" key="shared-templates">
            <Row className="aryabot template-table-header">
              <Col span={8}>TEMPLATE</Col>
              <Col span={5}>CREATED BY</Col>
              <Col span={5}>UPDATED ON</Col>
            </Row>
            {currentAryabotTemplateIds.map(templateId => {
              const aryabotTemplate = aryabotTemplatesById[templateId];
              const firstName = _.get(connectUsersById, [aryabotTemplate.CreatedBy, 'FirstName'], '');
              const lastName = _.get(connectUsersById, [aryabotTemplate.CreatedBy, 'LastName'], '');
              return (
                <SharedAryaBotTemplateRow
                  key={aryabotTemplate.Id}
                  id={aryabotTemplate.Id}
                  title={aryabotTemplate.Name}
                  createdBy={`${firstName} ${lastName}`}
                  createdTime={aryabotTemplate.CreatedTime}
                  updatedTime={aryabotTemplate.UpdatedTime}
                  cloneTemplate={this.duplicateTemplate}
                  viewTemplate={this.showEditDrawer}
                />
              );
            })}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

AryabotTemplatesContainer.defaultProps = {
  aryabotTemplatesCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(AryabotTemplatesContainer);
export { AryabotTemplatesContainer as AryabotTemplatesContainerWithoutStore };
