import { setApiStatus } from '../ApiStatusActions';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
const ADD_CONVERSATION_DETAILS = 'ADD_CONVERSATION_DETAILS';
export const SET_ORG_LEVEL_CONVERSATIONS = 'SET_ORG_LEVEL_CONVERSATIONS';
export const APPEND_ORG_LEVEL_CONVERSATIONS = 'APPEND_ORG_LEVEL_CONVERSATIONS';
export const SET_ORG_LEVEL_CONVERSATIONS_TOTAL_COUNT = 'SET_ORG_LEVEL_CONVERSATIONS_TOTAL_COUNT';
export const SET_ORG_LEVEL_PERSON_DETAILS = 'SET_ORG_LEVEL_PERSON_DETAILS';
export const APPEND_ORG_LEVEL_PERSON_DETAILS = 'APPEND_ORG_LEVEL_PERSON_DETAILS';

const SET_LATEST_MESSAGES = 'SET_LATEST_MESSAGES';

export function setNotification(type, message) {
  return {
    type: SET_NOTIFICATION,
    payload: {
      Type: type,
      Message: message,
    },
  };
}

function setConversationDetails(conversationDetails) {
  return {
    type: ADD_CONVERSATION_DETAILS,
    payload: conversationDetails,
  };
}

function setLatestMessages(conversationId, messages, userId) {
  return {
    type: SET_LATEST_MESSAGES,
    payload: {
      conversationId,
      messages,
      userId,
    },
  };
}

const setFetchConversationMailsApiStatus = status => {
  const apiName = 'SetConversationMailsApiStatus';
  return setApiStatus({ apiName, status });
};
const setFetchConversationMessagesApiStatus = status => {
  const apiName = 'SetConversationMessagesApiStatus';
  return setApiStatus({ apiName, status });
};

export const setFetchOrgLevelConversations = status => {
  return {
    apiName: 'fetchOrgLevelConversations',
    status,
  };
};

export const appendOrgLevelConversations = payload => {
  return {
    type: APPEND_ORG_LEVEL_CONVERSATIONS,
    payload,
  };
};

export const appendOrgLevelPersonDetails = payload => {
  return {
    type: APPEND_ORG_LEVEL_PERSON_DETAILS,
    payload,
  };
};

export const setOrgLevelConversations = payload => {
  return {
    type: SET_ORG_LEVEL_CONVERSATIONS,
    payload,
  };
};

export const setOrgLevelConversationsCount = payload => {
  return {
    type: SET_ORG_LEVEL_CONVERSATIONS_TOTAL_COUNT,
    payload,
  };
};
export const setOrgLevelPersonDetails = payload => {
  return {
    type: SET_ORG_LEVEL_PERSON_DETAILS,
    payload,
  };
};

export {
  setConversationDetails,
  ADD_CONVERSATION_DETAILS,
  setFetchConversationMailsApiStatus,
  setFetchConversationMessagesApiStatus,
  setLatestMessages,
};
