import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getMergeNodeIdOfRuleNode, getIncomingLeafEdgesToTargetFromRuleNode } from './GraphUtils';
import { getLayoutedElements } from './WorkflowLayoutUtils';

const position = { x: 0, y: 0 };

function sortDecendingByYPosition(node1, node2) {
  const yPositionOfNode1 = _.get(node1, 'position.y');
  const yPositionOfNode2 = _.get(node2, 'position.y');

  if (yPositionOfNode1 > yPositionOfNode2) {
    return -1;
  }
  if (yPositionOfNode1 < yPositionOfNode2) {
    return 1;
  }
  return 0;
}

const sortNodesByYPosition = clonePaylaod => {
  const { nodes, edges } = clonePaylaod;
  const { layoutElements } = getLayoutedElements([...nodes, ...edges]);
  const elements = [...layoutElements];
  const layoutNodes = elements.filter(x => x.position);
  const layoutEdges = elements.filter(x => !x.position);

  let sortedLayoutNodes = [...layoutNodes];
  sortedLayoutNodes.sort(sortDecendingByYPosition);

  sortedLayoutNodes = sortedLayoutNodes.map(currentNode => {
    const node = currentNode;
    delete node.sourcePosition;
    delete node.targetPosition;
    node.position = position;
    return node;
  });

  return { nodes: sortedLayoutNodes, edges: layoutEdges };
};

const addMergeNodesForRuleNodes = payload => {
  const { nodes, edges } = payload;
  const cloneNodes = _.cloneDeep(nodes);
  const cloneEdges = _.cloneDeep(edges);

  nodes.forEach(node => {
    const currentNode = cloneNodes.find(cloneNode => cloneNode.id === node.id);
    if (currentNode.type === 'Decision') {
      const targetRuleNodeId = getMergeNodeIdOfRuleNode({
        targetNodeId: currentNode?.id,
        nodes: cloneNodes,
        edges: cloneEdges,
      });

      if (targetRuleNodeId) {
        const incomingLeafEdgesToTargetFromRuleNode = getIncomingLeafEdgesToTargetFromRuleNode(
          currentNode,
          targetRuleNodeId,
          cloneNodes,
          cloneEdges
        );
        const emptyNode1 = {
          id: uuidv4(),
          type: 'Empty',
          data: {},
          position,
          height: 6,
        };
        const emptyNode2 = {
          id: uuidv4(),
          type: 'Empty',
          data: {},
          position,
          height: 6,
        };

        const emptyNodeIds = [emptyNode1.id, emptyNode2.id];
        incomingLeafEdgesToTargetFromRuleNode.forEach((incomingRuleEdge, index) => {
          const targetEdgeIndex = cloneEdges.findIndex(x => x.id === incomingRuleEdge?.id);
          const targetEdge = cloneEdges[targetEdgeIndex];
          const updatedTargetEdge = {
            ...targetEdge,
            target: emptyNodeIds[index],
          };
          cloneEdges[targetEdgeIndex] = updatedTargetEdge;
        });

        const mergeNode = {
          id: uuidv4(),
          type: 'Merge',
          data: {},
          position,
          height: 6,
        };

        const newEmptyNode1ToMergeNodeEdge = {
          id: uuidv4(),
          source: emptyNode1?.id,
          target: mergeNode?.id,
          type: 'default',
          data: { isAddButtonHidden: true },
        };

        const newEmptyNode2ToMergeNodeEdge = {
          id: uuidv4(),
          source: emptyNode2?.id,
          target: mergeNode?.id,
          type: 'default',
          data: { isAddButtonHidden: true },
        };

        const mergeNodeToTargerRuleNodeEdge = {
          id: uuidv4(),
          source: mergeNode?.id,
          target: targetRuleNodeId,
          type: 'default',
          data: {},
        };

        cloneNodes.push(...[emptyNode1, emptyNode2, mergeNode]);
        cloneEdges.push(...[newEmptyNode1ToMergeNodeEdge, newEmptyNode2ToMergeNodeEdge, mergeNodeToTargerRuleNodeEdge]);
      }
    }
  });
  return [...cloneNodes, ...cloneEdges];
};

const generateInitialElements = payload => {
  let clonePaylaod = _.cloneDeep(payload);
  clonePaylaod = sortNodesByYPosition(clonePaylaod);
  clonePaylaod = addMergeNodesForRuleNodes(clonePaylaod);
  return clonePaylaod;
};

export { generateInitialElements, addMergeNodesForRuleNodes };
