import { setApiStatus } from '../ApiStatusActions';

export function setPostQuestionsApiStatus(status) {
  const apiName = 'PostQuestionsApiStatus';
  return setApiStatus({ apiName, status });
}

export function setPostAnswersApiStatus(status) {
  const apiName = 'PostAnswersApiStatus';
  return setApiStatus({ apiName, status });
}
