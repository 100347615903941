import React from 'react';
import { Popover, Icon } from 'antd';
import StarIcon from '../../../../../Icons/StarIcon';
import AdditionalSkillsContent from './AdditionalSkillsContent';
import styles from '../SkillsSection.module.scss';
import eventTypes from '../../../../../Analytics/EventTypes';

const SkillTag = ({
  skill,
  handleProminentSkillClick,
  handleSkillClick,
  handleTilesIconHover,
  addNewSkill,
  handleAliasSkillSelection,
  handleAliasSkillSectionCheckboxClick,
  skillIntelApiStatus,
  isTitlesExists,
}) => {
  const { id, isProminent, name, isPreferred } = skill;

  const handleVisibleChange = visible => {
    if (visible && !(skill.aliasSkills?.length || skill.alternateSkills?.length) && isTitlesExists)
      handleTilesIconHover(id, name);
  };

  return (
    <div
      className={`${styles.skillTag} ${isPreferred ? styles.selected : styles.unselected} ${
        isProminent ? styles.prominent : ''
      }`}
    >
      <div
        className={styles.skillTagItem}
        style={{ fontSize: '12px' }}
        role="button"
        tabIndex={0}
        onClick={() => handleSkillClick(id, !isPreferred)}
        onKeyPress={() => handleSkillClick(id, !isPreferred)}
        sk-event-name={
          isPreferred
            ? eventTypes.job.manualSearch.deselectSuggestedSkills
            : eventTypes.job.manualSearch.selectSuggestedSkills
        }
      >
        {name}
      </div>
      <div
        className={styles.skillTagItem}
        role="button"
        tabIndex={0}
        onClick={() => handleProminentSkillClick(id, !isProminent)}
        onKeyPress={() => handleProminentSkillClick(id, !isProminent)}
        sk-event-name={
          isProminent ? eventTypes.job.manualSearch.unmarkProminent : eventTypes.job.manualSearch.markProminent
        }
      >
        <StarIcon
          fillColor={isProminent ? '#83878c' : '#FFFFFF'}
          strokeColor="#83878c"
          sk-event-name={
            isProminent ? eventTypes.job.manualSearch.unmarkProminent : eventTypes.job.manualSearch.markProminent
          }
        />
      </div>
      <Popover
        content={
          <AdditionalSkillsContent
            aliasSkills={skill.aliasSkills}
            alternateSkills={skill.alternateSkills}
            skillIntelApiStatus={skillIntelApiStatus}
            addNewSkill={addNewSkill}
            handleAliasSkillSelection={handleAliasSkillSelection}
            handleAliasSkillSectionCheckboxClick={handleAliasSkillSectionCheckboxClick}
            isTitlesExists={isTitlesExists}
            id={id}
          />
        }
        placement="bottomLeft"
        mouseEnterDelay={0.5}
        onVisibleChange={handleVisibleChange}
        destroyTooltipOnHide
      >
        <div className={styles.skillTagItem}>
          <Icon type="more" />
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(SkillTag);
