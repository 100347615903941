import React from 'react';
import _ from 'lodash';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';
import styles from './BulkCandidatesCriteria.module.scss';

export default function BulkCandidatesDownloadFilterInfo(props) {
  const { candidateDownloadFilterConfig, candidateCommunicationFilterConfig } = props;

  if (!candidateDownloadFilterConfig && !candidateCommunicationFilterConfig) {
    return null;
  }

  const downloadconfigurations = candidateDownloadFilterConfig?.FilterConfigurations;
  const commnunicationconfigurations = candidateCommunicationFilterConfig?.FilterConfigurations;
  let forbidenPortalsfromDownload;
  let forbidenPortalsfromCommunication;
  let forbidenStatesfromDownload;
  let forbidenStatesfromCommunication;
  if (downloadconfigurations?.length) {
    forbidenPortalsfromDownload = downloadconfigurations[0]?.ForbidenPortals;
    forbidenStatesfromDownload = downloadconfigurations[1]?.ForbidenStates?.map(forbidenState => forbidenState?.Name);
  }
  if (commnunicationconfigurations?.length) {
    forbidenPortalsfromCommunication = commnunicationconfigurations[0]?.ForbidenPortals;
    forbidenStatesfromCommunication = commnunicationconfigurations[0]?.ForbidenStates?.map(
      forbidenState => forbidenState?.Name
    );
  }
  let forbidenPortals;
  let forbidenStates;

  forbidenPortals = _.uniq((forbidenPortalsfromDownload ?? []).concat(forbidenPortalsfromCommunication ?? []));
  forbidenStates = _.uniq((forbidenStatesfromDownload ?? []).concat(forbidenStatesfromCommunication ?? []));

  forbidenPortals = forbidenPortals?.join(',') || '';
  forbidenStates = forbidenStates?.join(',') || '';

  return (
    <div className={styles.bulkOperationsCandidateCriteriaWrapper}>
      <div className={styles.bulkOperationsCandidateCriteriaInfoIcon}>
        <InfoCircleIcon />
      </div>
      <div className={styles.bulkOperationsCandidatesMain}>
        <div className={styles.bulkOperationsCandidateCriteria}>
          <b>
            <u>Note:</u>
          </b>{' '}
          The bulk operation will be applied to all candidates, except the candidates who meet the below criteria
        </div>
        <div className={styles.bulkOperationsCandidateCriteria}>
          <ul style={{ listStyle: 'disc', paddingInlineStart: '40px' }}>
            {forbidenPortals ? (
              <li>Candidates from {forbidenPortals} Job board whose profiles are not downloaded already</li>
            ) : null}
            {forbidenStates ? <li>Candidates from the State of {forbidenStates}</li> : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
