import { batch } from 'react-redux';
import {
  setFetchAryaGeneratedCriteriaApiStatus,
  setFetchManualSearchCriteriaIdentifiersApiStatus,
  setManualSearchCriteria,
  setBooleanSearchStrings,
  setManualSearchCriteriaIdentifiers,
  setManualSearchDraftCriteriaFetchApiStatus,
  setSaveManualSearchCriteriaApiStatus,
  setManualSearchPayload as _setManualSearchPayload,
  setRetrievedJobActivityId as _setRetrievedJobActivityId,
  resetManualSearchJobActivity as _resetManualSearchJobActivity,
  clearManualSearchCriteria as _clearManualSearchCriteria,
  setSelectedCriteriaName as _setSelectedCriteriaName,
  setSelectedCriteriaType as _setSelectedCriteriaType,
  setSelectedCriteriaId as _setSelectedCriteriaId,
  setManualSearchLastPerformedTime as _setManualSearchLastPerformedTime,
  setIsReactivationAllowedFlag as _setIsReactivationAllowedFlag,
  setIsDefaultCriteriaFetchedFlag as _setIsDefaultCriteriaFetchedFlag,
  setManualSearchFormValues as _setManualSearchFormValues,
  setManualSearchSources,
  setManualSearchCriteriaById,
  setApplyManualSearchCriteriaApiStatus,
  setfetchManualSearchCriteriaRecordApiStatus,
  setfetchManualSearchCriteriaRecordById,
  setIsPrefetchRequiredFlag as _setIsPrefetchRequiredFlag,
  setManualSearchSelectedCriteriaInfo as _setManualSearchSelectedCriteriaInfo,
} from './ActionCreators/ManualSearchActionCreators';
import { getSourceName } from '../Utils/SourceUtils';
import * as manualSearchRepository from '../Repository/ManualSearchRepository';
import { setNotification } from './ActionCreators/ConnectActions';
import { setAlertNotification } from './ActionCreators/AppNotificationActions';
import { getManualSearchPayload } from '../Reducers/ManualSearchReducer';
import {
  getFetchedCandidatesCountBySource,
  getManualSearchCandidatesFetchApiSecondsElapsed,
} from '../Reducers/ManualSearchCandidateReducer';
import { getManualSearchLabelForFormKey } from '../Utils/ManualSearchUtils';

const INPROGRESS = 'INPROGRESS';
const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';

function setManualSearchPayload({ manualSearchPayload, jobId, isAdvancedSearchV2Enabled }) {
  return _setManualSearchPayload({ manualSearchPayload, jobId, isAdvancedSearchV2Enabled });
}

function setRetrievedJobActivityId({ jobActivityId, jobId }) {
  return _setRetrievedJobActivityId({ jobActivityId, jobId });
}

function setManualSearchFormValues({ manualSearchFormValues, jobId }) {
  return _setManualSearchFormValues({ manualSearchFormValues, jobId });
}

function fetchBooleanSearchStrings({ jobId, size }) {
  return async dispatch => {
    try {
      const response = await manualSearchRepository.fetchBooleanSearchStrings({ jobId, size });
      dispatch(setBooleanSearchStrings(response.data));
    } catch (error) {
      dispatch(setBooleanSearchStrings({}));
    }
  };
}

function isNumericString(str) {
  return /^\d+$/.test(str);
}

function fetchManualSearchCriteriaById({ id }) {
  return async dispatch => {
    try {
      let response;

      if (!isNumericString(id)) {
        response = await manualSearchRepository.fetchManualSearchCriteriaAgentById({ id });
      } else {
        response = await manualSearchRepository.fetchManualSearchCriteriaById({ id });
      }
      const responseData = response.data;
      const { Id, CriteriaName, Criteria, ScoutingAgentSearchCriteria } = responseData;
      const responseCriteria = ScoutingAgentSearchCriteria ?? Criteria;
      const criteria = { Id: !isNumericString(id) ? id : Id, CriteriaName, ...responseCriteria };
      dispatch(setManualSearchCriteriaById({ criteria, id }));
      return criteria;
    } catch (error) {
      throw error;
    }
  };
}

function fetchManualSearchDraftCriteria({ jobId }) {
  return async dispatch => {
    try {
      dispatch(setManualSearchDraftCriteriaFetchApiStatus(INPROGRESS));
      const response = await manualSearchRepository.fetchManualSearchCriteria({ jobId, isDraft: true });
      batch(() => {
        dispatch(setManualSearchCriteria({ criterias: response.data.Criterias, jobId }));
        dispatch(setManualSearchDraftCriteriaFetchApiStatus(COMPLETED));
      });
    } catch (error) {
      dispatch(setManualSearchDraftCriteriaFetchApiStatus(FAILED));
    }
  };
}

function fetchAryaGeneratedIntel({ jobId, candidateContext }) {
  return async dispatch => {
    try {
      dispatch(setFetchAryaGeneratedCriteriaApiStatus(INPROGRESS));
      if (candidateContext === 'job') {
        const response = await manualSearchRepository.fetchAryaGeneratedIntel({ jobId });
        dispatch(setManualSearchCriteria({ criterias: response.data.Criterias, jobId }));
      } else if (candidateContext === 'segment') dispatch(setManualSearchCriteria({ criterias: [], jobId }));
      dispatch(setFetchAryaGeneratedCriteriaApiStatus(COMPLETED));
    } catch (error) {
      dispatch(setFetchAryaGeneratedCriteriaApiStatus(FAILED));
    }
  };
}

function fetchManualSearchCriteriaIdentifiers({ payload, isOrgLevelSearch, criteriaType }) {
  return async dispatch => {
    try {
      dispatch(setFetchManualSearchCriteriaIdentifiersApiStatus(INPROGRESS));
      const response = await manualSearchRepository.fetchManualSearchCriteriaIdentifiers({
        ...payload,
        jobId: isOrgLevelSearch ? null : payload.jobId,
      });
      dispatch(
        setManualSearchCriteriaIdentifiers({
          criteriaIdentifiers: response.data.Criterias,
          agentIdentifiers: response.data.Agents,
          count: response.data.TotalCount,
          jobId: payload.jobId,
          searchTerm: payload.searchTerm,
          criteriaType,
        })
      );
      dispatch(setFetchManualSearchCriteriaIdentifiersApiStatus(COMPLETED));
    } catch (error) {
      const isRequestCancelled = error.message === 'Request Cancelled';
      if (!isRequestCancelled) dispatch(setFetchManualSearchCriteriaIdentifiersApiStatus(FAILED));
    }
  };
}

function clearManualSearchCriteriaIdentifiers({ jobId, criteriaType }) {
  return setManualSearchCriteriaIdentifiers({
    criteriaIdentifiers: [],
    count: 0,
    jobId,
    criteriaType,
  });
}

function fetchManualSearchCriteriaByRecordId({ id, jobId }) {
  return async dispatch => {
    try {
      dispatch(setfetchManualSearchCriteriaRecordApiStatus(INPROGRESS));
      const response = await manualSearchRepository.fetchManualSearchCriteriaByRecordId({ id });
      const responseData = response.data;
      const { CriteriaName, Criteria, IsAryaSuggested } = responseData;
      const criteria = { Id: id, CriteriaName, ...Criteria, IsAryaSuggested };
      batch(() => {
        dispatch(setfetchManualSearchCriteriaRecordById({ criteria, id, jobId }));
        dispatch(setfetchManualSearchCriteriaRecordApiStatus(COMPLETED));
      });
    } catch (error) {
      dispatch(setfetchManualSearchCriteriaRecordApiStatus(FAILED));
    }
  };
}

function saveManualSearchCriteria({ criteria }) {
  return async dispatch => {
    try {
      dispatch(setSaveManualSearchCriteriaApiStatus(INPROGRESS));
      const response = await manualSearchRepository.saveManualSearchCriteria({ criteria });
      batch(() => {
        dispatch(setManualSearchCriteria({ criterias: [response.data], jobId: criteria.JobId }));
        dispatch(setSaveManualSearchCriteriaApiStatus(COMPLETED));
      });
      return { isSuccess: true };
    } catch (error) {
      const errorResponse = error.response ?? {};
      const { status, data } = errorResponse;
      dispatch(setSaveManualSearchCriteriaApiStatus(FAILED));
      return { isSuccess: false, status, code: data?.Error?.Code, id: data?.Error?.Info };
    }
  };
}

function updateManualSearchCriteria({ id, criteria }) {
  return async dispatch => {
    try {
      batch(() => {
        dispatch(setSaveManualSearchCriteriaApiStatus(INPROGRESS));
        dispatch(setManualSearchCriteria({ criterias: [criteria], jobId: criteria.JobId }));
      });
      await manualSearchRepository.updateManualSearchCriteria({ id, criteria });
      dispatch(setSaveManualSearchCriteriaApiStatus(COMPLETED));
    } catch (error) {
      dispatch(setSaveManualSearchCriteriaApiStatus(FAILED));
    }
  };
}

function fetchSkillIntel({ skills, titles }) {
  return async () => {
    try {
      const response = await manualSearchRepository.fetchSkillIntel({ skills, titles });
      return response.data;
    } catch {
      return {};
    }
  };
}

function applyManualSearchCriteria({ jobId, manualSearchCriteria }) {
  return async dispatch => {
    try {
      dispatch(setApplyManualSearchCriteriaApiStatus(INPROGRESS));
      await manualSearchRepository.applyManualSearchCriteria({ jobId, manualSearchCriteria });
      dispatch(setApplyManualSearchCriteriaApiStatus(COMPLETED));
      return { isSuccess: true };
    } catch {
      dispatch(setNotification('ERROR', "Oops! Something wen't worng while Reactivating Arya"));
      dispatch(setApplyManualSearchCriteriaApiStatus(FAILED));
      return { isSuccess: false };
    }
  };
}

function resetManualSearchApiStatuses() {
  return dispatch => {
    batch(() => {
      dispatch(setManualSearchDraftCriteriaFetchApiStatus(undefined));
      dispatch(setFetchAryaGeneratedCriteriaApiStatus(undefined));
    });
  };
}

function resetManualSearchJobActivity({ jobId }) {
  return _resetManualSearchJobActivity({ jobId });
}

function clearManualSearchCriteria({ jobId }) {
  return _clearManualSearchCriteria({ jobId });
}

function setSelectedCriteriaName({ jobId, criteriaName }) {
  return _setSelectedCriteriaName({ jobId, criteriaName });
}

function setSelectedCriteriaId({ jobId, criteriaId }) {
  return _setSelectedCriteriaId({ jobId, criteriaId });
}

function setSelectedCriteriaType({ jobId, selectedCriteriaType }) {
  return _setSelectedCriteriaType({ jobId, selectedCriteriaType });
}

function setManualSearchLastPerformedTime({ jobId }) {
  return _setManualSearchLastPerformedTime({ jobId });
}

function setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag }) {
  return _setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag });
}

function setIsDefaultCriteriaFetchedFlag({ jobId, isDefaultCriteriaFetchedFlag }) {
  return _setIsDefaultCriteriaFetchedFlag({ jobId, isDefaultCriteriaFetchedFlag });
}

function setManualSearchFormErrorNotification({ errorFields }) {
  const errorFieldsText = errorFields.map(x => `‘${getManualSearchLabelForFormKey({ key: x })}’`).join(', ');
  return setAlertNotification({
    alertNotification: {
      Type: 'ALERT_OPEN',
      Message: 'Please check for Errors',
      Description: `Please check for error in ${errorFieldsText} and make necessary edits to continue searching`,
    },
  });
}

function reorderManualSearchSources({ jobId }) {
  return (dispatch, getState) => {
    const store = getState();
    const manualSearchPayload = getManualSearchPayload(store, { jobId });
    const manualSearchSources = manualSearchPayload?.Sources?.filter(x => x.Portal !== 'All') || [];
    let nonEmptyResultSources = [];
    const emptyResultSources = [];
    const sortedNonEmptyResultSources = [];
    manualSearchSources.forEach(source => {
      const sourceName = getSourceName(source);
      const fetchedCandidatesCount = getFetchedCandidatesCountBySource(store, { jobId, source: sourceName });
      const candidatesFetchApiElapsedTimeInSeconds = getManualSearchCandidatesFetchApiSecondsElapsed(store, {
        jobId,
        source: sourceName,
      });
      if (fetchedCandidatesCount > 0) {
        sortedNonEmptyResultSources.push({
          candidatesFetchApiRequestElapsedTime: candidatesFetchApiElapsedTimeInSeconds,
          source,
        });
      } else emptyResultSources.push(source);
    });
    sortedNonEmptyResultSources.sort(
      (a, b) => a.candidatesFetchApiRequestElapsedTime - b.candidatesFetchApiRequestElapsedTime
    );
    nonEmptyResultSources = sortedNonEmptyResultSources.map(s => s.source);
    const reorderedSources = [{ Portal: 'All', Type: 'Primary' }, ...nonEmptyResultSources, ...emptyResultSources];
    dispatch(setManualSearchSources({ jobId, sources: reorderedSources }));
  };
}

export const setIsPrefetchRequiredFlag = ({ jobId, isPrefetchRequiredFlag }) => {
  return _setIsPrefetchRequiredFlag({ jobId, isPrefetchRequiredFlag });
};

export function setManualSearchSelectedCriteriaInfo({ jobId, criteriaInfo }) {
  return _setManualSearchSelectedCriteriaInfo({ jobId, criteriaInfo });
}

export {
  setManualSearchPayload,
  setRetrievedJobActivityId,
  fetchAryaGeneratedIntel,
  fetchManualSearchDraftCriteria,
  fetchManualSearchCriteriaById,
  saveManualSearchCriteria,
  updateManualSearchCriteria,
  fetchSkillIntel,
  fetchManualSearchCriteriaIdentifiers,
  clearManualSearchCriteriaIdentifiers,
  applyManualSearchCriteria,
  resetManualSearchApiStatuses,
  resetManualSearchJobActivity,
  setManualSearchFormErrorNotification,
  setSelectedCriteriaName,
  setSelectedCriteriaId,
  setSelectedCriteriaType,
  setManualSearchLastPerformedTime,
  fetchManualSearchCriteriaByRecordId,
  clearManualSearchCriteria,
  reorderManualSearchSources,
  setIsReactivationAllowedFlag,
  fetchBooleanSearchStrings,
  setIsDefaultCriteriaFetchedFlag,
  setManualSearchFormValues,
};
