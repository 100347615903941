import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { Tabs, Empty, Skeleton } from 'antd';
import CallDialogContainer from '../CallDialog/CallDialog';
import CareerportalConversationsContainer from '../CareerportalConversationsContainer/CareerportalConversationsContainer';
import BotConfigDrawerContainer from '../BotConfigDrawerContainer/BotConfigDrawerContainer';
import TemplatesContainer from '../TemplatesContainer/TemplatesContainer';
import WorkflowContainer from '../WorkflowContainer/WorkflowContainer';
import ContentPaneHeader from '../../Components/Connect/ContentPaneHeader/ContentPaneHeader';
import ContentPaneBody from '../../Components/Connect/ContentPaneBody/ContentPaneBody';
import ConversationListItem from '../../Components/Connect/ConversationListItem/ConversationListItem';
import PrimaryListHeader from '../../Components/Connect/PrimaryListHeader/PrimaryListHeader';
import SecondaryListHeader from '../../Components/Connect/SecondaryListHeader/SecondaryListHeader';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as CandidateConnectActions from '../../Actions/CandidateConnectActions';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import * as JobActions from '../../Actions/JobActions';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import {
  getJobsById,
  getJobsIdsByStatus,
  getJobsCountByStatus,
  getEmailStatsForJob,
  getJobGuidToIdMapping,
  getMustHaves,
  getNiceToHaves,
} from '../../Reducers/JobReducer';
import { getUsersById } from '../../Reducers/UserReducer';
import { getCandidates, getCandidateCount, getConnectDetails } from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getRecentReadNotificationInfo } from '../../Reducers/AryaNotifyReducer';

import {
  getConversationsById,
  getCandidatesConnectInfo,
  getJobsUnreadConversationCount,
  getCreditsRefunded,
  getOrgLevelPersonDetailsById,
  getOrglevelConversationsById,
  getOrglevelConversationsCount,
} from '../../Reducers/ConnectReducer';
import './Connect.scss';
import { AryaBotIcon, EmptyConnectIcon, EmptyCandidatesIcon } from '../../Icons/AryaIcons';
import { getConfig, getConnectConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import PageNotAllowed from '../../Components/PageNotAllowed/PageNotAllowed';
import { getInternalSourceWithCandidateId } from '../../Utils/SourceUtils';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import CandidateDrawerWrapper from '../CandidateDrawerWrapper/CandidateDrawerWrapper';
import WorkflowTemplateCreationContainer from '../WorkflowTemplateSelection/WorkflowTemplateSelectionContainer';
import ComposeEmailAndMessageButton from '../../Components/Connect/ComposeEmailAndMessageButton/ComposeEmailAndMessageButton';
import { getKeyWordsToHighlight } from '../../Utils/CandidateListUtils';
import ConnectV2Redirect from '../../Components/Connect/ConnectV2/ConnectV2Redirect';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  jobsIdsByStatus: getJobsIdsByStatus(state),
  JobGuidToIdMapping: getJobGuidToIdMapping(state),
  userConfig: getConfig(state),
  jobsById: getJobsById(state),
  jobsCountByStatus: getJobsCountByStatus(state),
  _getCandidatesById: jobId => getCandidates(state, jobId),
  connectInfo: getCandidatesConnectInfo(state),
  getPersonalityStatus: getApiStatus(state, 'getPersonalityStatus'),
  fetchPersonalityStatus: getApiStatus(state, 'fetchPersonalityStatus'),
  conversationsById: getConversationsById(state),
  jobsUnreadConversationCount: getJobsUnreadConversationCount(state),
  resumeUnlockStatus: getApiStatus(state, 'resumeUnlockStatus'),
  connectConfig: getConnectConfig(state),
  candidateListApiStatus: getApiStatus(state, 'candidateListStatus'),
  candidateDetailsStatus: getApiStatus(state, 'candidateDetailsStatus'),
  usersById: getUsersById(state),
  featureToggleList: getFeatureToggleList(state),
  candidatesTotal: getCandidateCount(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  connectDetails: getConnectDetails(state),
  creditsRefunded: getCreditsRefunded(state),
  emailStatsForJob: getEmailStatsForJob(state),
  candidateAllNotesFetchStatus: getApiStatus(state, 'fetchBulkCandidateAllNotesApiStatus'),
  jobListApiStatus: getApiStatus(state, 'jobListStatus'),
  searchConversationApiStatus: getApiStatus(state, 'searchConversationApiStatus'),
  recentReadNotificationInfo: getRecentReadNotificationInfo(state, 'Connect'),
  getJobMustHaves: jobId => getMustHaves(state, jobId),
  getJobNiceToHaves: jobId => getNiceToHaves(state, jobId),
  currentUserDetails: getCurrentUserDetails(state),
  orgLevelConversationsFetchApiStatus: getApiStatus(state, 'fetchOrgLevelConversations'),
  orgLevelPersonDetailsById: getOrgLevelPersonDetailsById(state),
  orglevelConversationsById: getOrglevelConversationsById(state),
  orglevelConversationsCount: getOrglevelConversationsCount(state),
});

const mapDispatchToProps = {
  downloadResume: CandidateActions.downloadResume,
  fetchConnectedJobs: JobActions.fetchConnectedJobs,
  fetchJobList: JobActions.fetchConnectedJobs,
  fetchJobCount: JobActions.fetchJobCount,
  fetchContactedCandidates: CandidateActions.fetchContactedCandidates,
  fetchCandidateDetails: CandidateActions.fetchCandidateDetails,
  unlockCandidateResume: CandidateActions.unlockCandidateResume,
  postCandidateNotes: CandidateActions.postCandidateNotes,
  fetchPersonality: ConnectActions.fetchPersonality,
  fetchCallNotes: CandidateConnectActions.fetchCallNotes,
  getConversationReference: ConnectActions.getConversationReference,
  fetchCandidateNotes: CandidateConnectActions.fetchCandidateNotes,
  fetchContact: ConnectActions.fetchContact,
  addContact: ConnectActions.addContact,
  getRefundForInvalidContacts: ConnectActions.getRefundForInvalidContacts,
  pushCandidateToConnectAndFetchContact: ConnectActions.pushCandidateToConnectAndFetchContact,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
  setCurrentJobId: JobActions.setCurrentJobId,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  fetchEmailStats: ConnectActions.fetchEmailStats,
  setCandidateRejectStatus: CandidateActions.setCandidateRejectStatus,
  changeCandidateStatus: CandidateActions.changeCandidateStatus,
  saveRecentReadNotificationInfo: AryaNotifyActions.saveRecentReadNotificationInfo,
  resetCandidates: CandidateActions.resetCandidates,
  fetchJobDetails: JobActions.fetchJobDetails,
  searchOrgLevelConversations: ConnectActions.searchOrgLevelConversations,
};

class ConnectContainer extends React.Component {
  constructor(props) {
    super(props);
    const activeTab = props.defaultActiveTab || 'conversations';
    this.connectMessageRef = React.createRef();
    this.connectMailRef = React.createRef();
    this.state = {
      selectedJob: {
        Guid: null,
        Id: null,
      },
      selectedCandidate: {
        Id: null,
      },
      activeTab,
      showBotConfigDrawer: false,
      candidateDrawerVisiblity: false,
      isJobSearched: false,
      isOrgConversationsFilterApplied: false,
      orgConversationsFilterValues: [],
      isEmailStatsFilterApplied: false,
      emailStatsfilterValues: [],
      drawerRenderFlag: false,
      subTabKey: 'profile',
      isComposeEmailModalVisible: false,
      isComposeMessageModalVisible: false,
      activeConnectContentTab: props.recentReadNotificationInfo.FromAddress ? 'email' : 'message',
      currentJobDetails: {},
    };

    this.onJobClick = this.onJobClick.bind(this);
    this.onCandidateClick = this.onCandidateClick.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.showCandidateDrawer = this.showCandidateDrawer.bind(this);
    this.hideCandidateDrawer = this.hideCandidateDrawer.bind(this);
    this.onCandidateStatusChange = this.onCandidateStatusChange.bind(this);
    this.handleJobSearch = this.handleJobSearch.bind(this);
    this.handleCandidateSearch = this.handleCandidateSearch.bind(this);
    this.loadMoreJobs = this.loadMoreJobs.bind(this);
    this.loadMoreCandidates = this.loadMoreCandidates.bind(this);
    this.onClickAtsPush = this.onClickAtsPush.bind(this);
    this.getCandidateList = this.getCandidateList.bind(this);
    this.postAtsCallNotes = this.postAtsCallNotes.bind(this);
    this.onApplyEmailStatsFilter = this.onApplyEmailStatsFilter.bind(this);
    this.setEmailStatsFilterStatus = this.setEmailStatsFilterStatus.bind(this);
    this.setEmailStatsFilterValues = this.setEmailStatsFilterValues.bind(this);
  }

  componentDidMount() {
    this.fetchconnectDetails();
  }

  fetchconnectDetails() {
    const { fetchJobList, fetchJobCount, fetchEmailConfig, recentReadNotificationInfo } = this.props;
    const { RefId } = recentReadNotificationInfo;

    const filter = {
      count: 10,
      skip: 0,
      connectStatus: 'contacted',
      sortBy: 'ConversationTime',
      sortOrder: 'descending',
      isAssigned: true,
      isJobStatsRequired: false,
    };
    if (RefId) filter.jobGuids = [RefId];
    fetchJobList(filter);
    fetchJobCount(filter);
    fetchEmailConfig({ isAdmin: false });
    fetchEmailConfig({ isAdmin: true });
  }

  componentDidUpdate(prevProps) {
    const {
      jobsById,
      JobGuidToIdMapping,
      _getCandidatesById,
      jobListApiStatus,
      candidateListApiStatus,
      recentReadNotificationInfo,
      saveRecentReadNotificationInfo,
    } = this.props;

    const { selectedJob } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);
    const { recentReadNotificationInfo: prevRecentReadNotificationInfo } = prevProps;
    const { RefId: prevRefId, notificationId: prevNotificationId } = prevRecentReadNotificationInfo;

    const {
      RefId: currentRefId,
      ConversationId: currConversationId,
      notificationId: currNotificationId,
    } = recentReadNotificationInfo;
    const nextjobListApiStatus = jobListApiStatus;
    const prevjobListApiStatus = prevProps.jobListApiStatus;
    const nextCandidateListApiStatus = candidateListApiStatus;
    const prevCandidateListApiStatus = prevProps.candidateListApiStatus;

    if (currentRefId && (currentRefId !== prevRefId || currNotificationId !== prevNotificationId)) {
      this.fetchconnectDetails();
    }

    if (currentRefId && prevjobListApiStatus !== 'COMPLETED' && nextjobListApiStatus === 'COMPLETED') {
      const jobId = JobGuidToIdMapping[currentRefId];
      const job = jobsById[jobId];
      saveRecentReadNotificationInfo('Connect', { jobId: job.JobId });
      this.onJobClick(job.JobGuid, job.JobId, currConversationId);
    }

    if (currentRefId && prevCandidateListApiStatus !== 'COMPLETED' && nextCandidateListApiStatus === 'COMPLETED') {
      const jobId = JobGuidToIdMapping[currentRefId];
      const job = jobsById[jobId];
      const candidates = Object.values(candidatesById) || [];

      const reqCandidate = candidates.find(candidate => candidate.ConversationId === currConversationId);

      this.onCandidateClick(jobId, reqCandidate?.Id, currConversationId, job.JobGuid);
    }
  }

  setEmailComposeModalVisibility = value => {
    this.setState({
      isComposeEmailModalVisible: value,
    });
  };

  setMessageComposeModalVisibility = value => {
    this.setState({
      isComposeMessageModalVisible: value,
    });
  };

  onConnectContentTabClick = activeKey => {
    this.setState({
      activeConnectContentTab: activeKey,
    });
  };

  onTabChange(key) {
    const { setUrl, location } = this.props;
    const { pathname } = location;
    const keyToUrl = {
      conversations: `${pathname}?tab=conversations`,
      aryabot: `${pathname}?tab=aryabot`,
      templates: `${pathname}?tab=templates`,
      workflows: `${pathname}?tab=workflows`,
    };
    this.setState({ activeTab: key });
    setUrl(keyToUrl[key]);
  }

  componentWillUnmount() {
    const { saveRecentReadNotificationInfo, resetCandidates } = this.props;
    saveRecentReadNotificationInfo('Connect', {});
    resetCandidates();
  }

  fetchConnectCandidates({ jobId, jobGuid, emailStatuses }, conversationId = null) {
    const { fetchContactedCandidates } = this.props;
    const filter = {
      jobId,
      jobGuid,
      Statuses: [],
      ConnectionStatus: 'Contacted',
      From: 0,
      Size: 10,
      EmailStats: emailStatuses,
    };
    if (conversationId) {
      filter.conversationId = conversationId;
    }
    fetchContactedCandidates(filter);
  }

  async onJobClick(guid, id, conversationId = null) {
    const { getConversationReference, jobClickCallback, jobsById, setCurrentJobId, fetchJobDetails } = this.props;
    setCurrentJobId(id);
    this.fetchConnectCandidates({ jobId: id, jobGuid: guid }, conversationId);
    getConversationReference(id, guid);
    if (jobClickCallback) jobClickCallback(_.get(jobsById, [id, 'JobCode'], -1));
    this.setState({
      selectedJob: {
        Guid: guid,
        Id: id,
      },
      candidateSearchTerm: undefined,
      selectedCandidate: {
        Id: null,
      },
    });
    this.setEmailStatsFilterStatus(false);
    this.setEmailStatsFilterValues([]);
    const jobDetails = await fetchJobDetails(id);
    this.setCurrentJobDetails({ jobDetails });
  }

  onCandidateClick(jobId, id, conversationId, currentJobGuid) {
    this.setState({
      selectedCandidate: {
        JobId: jobId,
        Id: id,
        ConversationId: conversationId,
        currentJobGuid,
      },
    });
  }

  onCandidateStatusChange(filter, size) {
    const { selectedJob, selectedCandidate } = this.state;
    const { Id: jobId } = selectedJob;
    const { Id: candidateId } = selectedCandidate;
    const { changeCandidateStatus } = this.props;
    const { candidate, status, rejectReasons, includeSimilar, mustHaves: _mustHaves } = filter;
    const mustHaves = _mustHaves?.split(',') ?? [];
    changeCandidateStatus({
      status,
      jobId,
      candidateId,
      reasons: rejectReasons,
      mustHaves,
      includeSimilar,
      jobTitle: this.getJobTitle(),
    });
    if (status !== 'Shortlisted' && (status === 'Rejected' || candidate.Status === 'Rejected')) {
      this.onCandidateReject(candidate, size);
    }
  }

  getJobTitle = () => {
    const { jobsById } = this.props;
    const { selectedJob } = this.state;
    return _.get(jobsById, [selectedJob.Id, 'JobTitle'], '');
  };

  toggleBotDrawerRenderFlag = () => {
    const { botDrawerRenderFlag } = this.state;
    this.setState({ botDrawerRenderFlag: !botDrawerRenderFlag });
  };

  toggleBotConfigDrawer = () => {
    const { selectedJob, showBotConfigDrawer, botDrawerRenderFlag } = this.state;
    if (selectedJob && selectedJob.Id) {
      if (showBotConfigDrawer) this.setState({ showBotConfigDrawer: !showBotConfigDrawer });
      else this.setState({ showBotConfigDrawer: !showBotConfigDrawer, botDrawerRenderFlag: !botDrawerRenderFlag });
    }
  };

  showCandidateDrawer(tab) {
    const { fetchCandidateDetails, fetchCallNotes, fetchCandidateNotes, _getCandidatesById, jobsById } = this.props;
    const { selectedCandidate, selectedJob } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);
    const candidateDetails = _.get(candidatesById, [selectedCandidate.Id], {});
    fetchCandidateDetails(
      {
        jobId: selectedJob.Id,
        candidateId: selectedCandidate.Id,
      },
      { refId: selectedJob.Guid, Country: jobsById[selectedJob.Id].CountryCode }
    );
    if (tab === 'notes') {
      fetchCandidateNotes({
        jobId: selectedJob.Id,
        candidateId: selectedCandidate.Id,
      });
      fetchCallNotes(selectedCandidate.Id, candidateDetails.ConversationId, 0, 10);
    }
    this.setState({
      candidateDrawerVisiblity: true,
      drawerRenderFlag: true,
      subTabKey: tab,
    });
  }

  hideCandidateDrawer() {
    this.setState({
      candidateDrawerVisiblity: false,
    });
  }

  handleJobSearch(value) {
    const { fetchJobList, fetchJobCount } = this.props;
    this.setState({
      jobSearchTerm: value,
      isJobSearched: true,
    });
    const filter = {
      count: 10,
      skip: 0,
      connectStatus: 'contacted',
      searchTerm: value,
      sortBy: 'ConversationTime',
      sortOrder: 'descending',
      isAssigned: true,
      isJobStatsRequired: true,
    };
    fetchJobList(filter);
    fetchJobCount(filter);
  }

  handleCandidateSearch(value) {
    const { selectedJob } = this.state;
    if (selectedJob.Id) {
      const { fetchContactedCandidates } = this.props;
      this.setState({
        candidateSearchTerm: value,
        selectedCandidate: {
          Id: null,
        },
      });
      fetchContactedCandidates({
        jobId: selectedJob.Id,
        jobGuid: selectedJob.Guid,
        Statuses: [],
        ConnectionStatus: 'Contacted',
        SearchQuery: value,
        From: 0,
        Size: 10,
      });
    }
  }

  getCandidateList(candidates, candidatesTotal) {
    const { selectedJob, candidateSearchTerm, selectedCandidate } = this.state;
    const { candidateListApiStatus, conversationsById } = this.props;
    if (!selectedJob.Guid) {
      return (
        <Empty
          description={
            <span>
              <b>No job selected</b>
              <br />
              Select a job to see active conversations.
            </span>
          }
          style={{ marginTop: 100 }}
          image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
        />
      );
    }

    if (candidateListApiStatus === 'INPROGRESS' || candidates.length) {
      return (
        <Skeleton loading={candidateListApiStatus === 'INPROGRESS' && !candidates.length}>
          <InfiniteScroll
            pageStart={1}
            key={`${selectedJob.Guid} ${candidateSearchTerm || ''}`}
            loadMore={this.loadMoreCandidates}
            initialLoad={false}
            hasMore={
              candidateListApiStatus !== 'INPROGRESS' &&
              candidatesTotal !== undefined &&
              candidates.length < candidatesTotal
            }
            useWindow={false}
            loader="Loading..."
          >
            {candidates.map(candidate => (
              <ConversationListItem
                key={candidate.Id}
                title={candidate.Name}
                primaryInfo={`${candidate.Title || ''} ${candidate.Title && candidate.Company ? '@' : ''} ${
                  candidate.Company || ''
                }`}
                secondaryInfo={candidate.Location}
                onClick={() =>
                  this.onCandidateClick(selectedJob.Id, candidate.Id, candidate.ConversationId, selectedJob.Guid)
                }
                isActive={selectedCandidate.Id === candidate.Id}
                isoDateTime={_.get(candidate, 'RecentConversationTime')}
                badgeCount={
                  _.get(conversationsById, [candidate.ConversationId, 'UnreadSmsCount'], 0) +
                  _.get(conversationsById, [candidate.ConversationId, 'UnreadChatCount'], 0)
                }
              />
            ))}
          </InfiniteScroll>
        </Skeleton>
      );
    }
    return (
      <Empty
        image={<EmptyCandidatesIcon className="connect-candidate-list-empty-icon" />}
        description={
          <span>
            <b>No candidates found</b>
            <br />
            All connected candidates will appear here
          </span>
        }
        className="connect-candidate-list-empty"
      />
    );
  }

  loadMoreJobs(page) {
    const { fetchJobList } = this.props;
    const { jobSearchTerm } = this.state;
    const filter = {
      count: 10,
      skip: (page - 1) * 10,
      connectStatus: 'contacted',
      searchTerm: jobSearchTerm,
      sortBy: 'ConversationTime',
      sortOrder: 'descending',
      isAssigned: true,
      isJobStatsRequired: false,
    };
    fetchJobList(filter, 'ON_LOAD');
  }

  loadMoreCandidates(page) {
    const { fetchContactedCandidates } = this.props;
    const { selectedJob, candidateSearchTerm, emailStatuses } = this.state;
    if (selectedJob.Id) {
      fetchContactedCandidates(
        {
          jobId: selectedJob.Id,
          jobGuid: selectedJob.Guid,
          Statuses: [],
          ConnectionStatus: 'Contacted',
          SearchQuery: candidateSearchTerm,
          From: (page - 1) * 10,
          Size: 10,
          EmailStats: emailStatuses,
        },
        'ON_LOAD'
      );
    }
  }

  onClickAtsPush(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal) {
    const { openAtsView } = this.props;
    if (openAtsView) openAtsView(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal);
  }

  postAtsCallNotes(note, candidateId) {
    const { postAtsCallNotes, jobsById, _getCandidatesById } = this.props;
    const { selectedJob } = this.state;
    const candidatesById = _getCandidatesById(selectedJob.Id);

    const sources = _.get(candidatesById, ['Internal', candidateId, 'Sources'], []);
    const internalSource = getInternalSourceWithCandidateId(sources);

    postAtsCallNotes(note, _.get(internalSource, ['CandidateId'], 0), _.get(jobsById, [selectedJob.id, 'JobCode'], -1));
  }

  openCandidateView = () => {
    const { openAryaCandidateView } = this.props;
    const { selectedCandidate } = this.state;
    const { JobId, Id } = selectedCandidate;
    if (openAryaCandidateView) openAryaCandidateView(JobId, Id, '3');
    else window.open(`/jobs/${JobId}/candidates/${Id}?v=3`);
  };

  onApplyEmailStatsFilter(emailStatuses) {
    const { selectedJob } = this.state;
    this.fetchConnectCandidates({
      jobId: selectedJob.Id,
      jobGuid: selectedJob.Guid,
      emailStatuses,
    });
    this.setState({
      emailStatuses,
      selectedCandidate: {
        Id: null,
      },
    });
  }

  setEmailStatsFilterStatus(isEmailStatsFilterApplied) {
    this.setState({
      isEmailStatsFilterApplied,
    });
  }

  setOrgConversationFilterStatus = isOrgConversationsFilterApplied => {
    this.setState({
      isOrgConversationsFilterApplied,
    });
  };

  setOrgConversationFilterValues = orgConversationsFilterValues => {
    this.setState({ orgConversationsFilterValues });
  };

  setEmailStatsFilterValues(emailStatsfilterValues) {
    this.setState({ emailStatsfilterValues });
  }

  toggleDrawerRenderFlag = () => {
    const { drawerRenderFlag } = this.state;
    this.setState({ drawerRenderFlag: !drawerRenderFlag });
  };

  onCandidateReject = (candidate, size) => {
    const { setCandidateRejectStatus } = this.props;
    setCandidateRejectStatus(candidate.Id, size);
  };

  keywordsToHighlight = memoize((mustHaves, niceToHaves, currentJobDetails = {}) =>
    getKeyWordsToHighlight({ mustHaves, niceToHaves, currentJobDetails })
  );

  setCurrentJobDetails = ({ jobDetails }) => {
    this.setState({ currentJobDetails: jobDetails });
  };

  render() {
    const {
      jobsIdsByStatus,
      jobsById,
      _getCandidatesById,
      conversationsById,
      jobsCountByStatus,
      connectInfo,
      jobsUnreadConversationCount,
      userConfig,
      dialingTone,
      ringingTone,
      callNotesContainer,
      version,
      featureToggleList,
      candidatesTotal,
      whiteLabelInfo,
      connectDetails,
      emailStatsForJob,
      candidateDetailsStatus,
      candidateListApiStatus,
      candidateAllNotesFetchStatus,
      notesContainer,
      recentReadNotificationInfo,
      downloadResume,
      saveRecentReadNotificationInfo,
      jobListApiStatus,
      setUrl,
      location,
      openSipCallWindowsApp,
      getJobMustHaves,
      getJobNiceToHaves,
      currentUserDetails,
      searchOrgLevelConversations,
      orgLevelConversationsFetchApiStatus,
      orgLevelPersonDetailsById,
      orglevelConversationsById,
      orglevelConversationsCount,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
    } = this.props;
    const {
      selectedJob,
      selectedCandidate,
      activeTab,
      showBotConfigDrawer,
      candidateDrawerVisiblity,
      jobSearchTerm,
      isJobSearched,
      botDrawerRenderFlag,
      isEmailStatsFilterApplied,
      emailStatsfilterValues,
      subTabKey,
      drawerRenderFlag,
      isComposeEmailModalVisible,
      isComposeMessageModalVisible,
      activeConnectContentTab,
      currentJobDetails,
      orgConversationsFilterValues,
      isOrgConversationsFilterApplied,
    } = this.state;

    const candidatesById = _getCandidatesById(selectedJob.Id);
    const jobIds = jobsIdsByStatus.contacted || [];
    const jobDetails = jobsById[selectedJob.Id];
    const jobs = jobIds.map(jobId => jobsById[jobId]);
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    const jobsTotal = _.get(jobsCountByStatus, 'contacted', undefined);
    const candidates = Object.values(candidatesById) || [];
    const candidateDetails = _.get(candidatesById, [selectedCandidate.Id], {});
    const connectStatuses = connectInfo.ConnectStatuses || {};
    const connectStatus = candidateDetails ? _.get(connectStatuses, candidateDetails.PersonId, {}) : {};
    const availableProviders = _.get(connectStatus, 'Contact.AvailableProviders', null);
    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    const jobCountryCode = _.get(jobsById, [selectedJob.Id, 'CountryCode']);
    const mustHaves = getJobMustHaves(selectedJob.Id);
    const niceToHaves = getJobNiceToHaves(selectedJob.Id);
    const { connectMessageRef, connectMailRef } = this;

    const contextualMessageCount = connectMessageRef.current?.state.messages?.length || 0;
    const isMessageCommunicationAllowed = connectMessageRef.current?.state?.isCommunicationAllowed;
    const isMessageComposeButtonVisible =
      activeConnectContentTab === 'message' && contextualMessageCount && isMessageCommunicationAllowed;

    const contextualEmailCount = connectMailRef.current?.state.allEmails?.length || 0;
    const isMailCommunicationAllowed = connectMailRef.current?.state?.isCommunicationAllowed;
    const { setDefaultEmailTemplateValues } = connectMailRef.current || {};
    const { setCurrentReplyingToEmail } = connectMailRef.current || {};
    const { setSubjectDisability } = connectMailRef.current || {};
    const isEmailComposeButtonVisible =
      activeConnectContentTab === 'email' && contextualEmailCount && isMailCommunicationAllowed;
    const isComposeButtonVisible = isEmailComposeButtonVisible || isMessageComposeButtonVisible;

    const {
      IsCareerportalChatViewEnabled: { IsEnabled: isCareerportalChatViewEnabled },
    } = featureToggleList;

    const alternateState = jobsIdsByStatus.contacted ? (
      <Empty
        description={
          <div>
            <b>No conversation found</b>
            <br />
            All the candidates with whom you started conversation will appear here.
          </div>
        }
        style={{ marginTop: '24vh' }}
        image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
      />
    ) : (
      <Skeleton />
    );

    let conversationsTab =
      _.get(jobsIdsByStatus, ['contacted', 'length'], false) || isJobSearched ? (
        <div className="connect-container">
          <div className="main-column grid-column" id="grid-1">
            <PrimaryListHeader
              count={jobsTotal !== undefined ? jobsTotal.toString() : '-'}
              onSearch={this.handleJobSearch}
              recentReadNotificationInfo={recentReadNotificationInfo}
              saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
            />
            <div className="card-list">
              {jobsIdsByStatus.contacted.length ? (
                <InfiniteScroll
                  pageStart={1}
                  key={jobSearchTerm || 1}
                  loadMore={this.loadMoreJobs}
                  hasMore={jobListApiStatus !== 'INPROGRESS' && jobsTotal !== undefined && jobIds.length < jobsTotal}
                  useWindow={false}
                  initialLoad={false}
                  loader="Loading..."
                >
                  {jobs.map(job => (
                    <ConversationListItem
                      key={job.JobGuid}
                      jobCode={job.JobCode}
                      isActive={selectedJob.Guid === job.JobGuid}
                      title={job.JobTitle}
                      primaryInfo={job.Client}
                      secondaryInfo={job.JobId.toString()}
                      isoDateTime={_.get(jobsUnreadConversationCount, [job.JobGuid, 'RecentConversationTime'])}
                      onClick={() => this.onJobClick(job.JobGuid, job.JobId)}
                      badgeCount={_.get(jobsUnreadConversationCount, [job.JobGuid, 'UnreadConversationCount'], 0)}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <Empty
                  description={
                    <span>
                      <b>No jobs found</b>
                    </span>
                  }
                  style={{ marginTop: 100 }}
                  image={
                    <img
                      src={`${process.env.PUBLIC_URL}/static/Images/empty-jobs.svg`}
                      height="100px"
                      width="115px"
                      alt="No jobs found"
                    />
                  }
                />
              )}
            </div>
          </div>
          <div className="sub-column grid-column" id="grid-2">
            <SecondaryListHeader
              count={candidatesTotal !== undefined ? candidatesTotal.toString() : '-'}
              onSearch={this.handleCandidateSearch}
              emailStatsForJob={emailStatsForJob}
              onApplyEmailStatsFilter={this.onApplyEmailStatsFilter}
              selectedJobId={selectedJob.Id}
              isEmailStatsFilterApplied={isEmailStatsFilterApplied}
              setEmailStatsFilterStatus={this.setEmailStatsFilterStatus}
              emailStatsfilterValues={emailStatsfilterValues}
              onEmailStatsFilterChange={this.setEmailStatsFilterValues}
              recentReadNotificationInfo={recentReadNotificationInfo}
              saveRecentReadNotificationInfo={saveRecentReadNotificationInfo}
              connectDetails={connectDetails}
              isLoading={candidateListApiStatus === 'INPROGRESS'}
            >
              {featureToggleList.BotRead.IsEnabled ? (
                <div className="botIcon">
                  <AryaBotIcon
                    onClick={this.toggleBotConfigDrawer}
                    style={{
                      color: _.get(jobsById, [selectedJob.Id, 'IsBotEnabled'], false) ? '#01cd75' : '#83878c',
                    }}
                  />
                </div>
              ) : null}
            </SecondaryListHeader>
            <div className="card-list candidate">{this.getCandidateList(candidates, candidatesTotal)}</div>
          </div>
          <div className="content-column grid-column" id="grid-3">
            {selectedCandidate.Id ? (
              <div>
                <ContentPaneHeader
                  candidateDetails={_.get(candidatesById, [selectedCandidate.Id], {})}
                  showProfile={this.showCandidateDrawer}
                />
                <div className="content-pane-and-compose-email-button">
                  <ContentPaneBody
                    showBotConfigDrawer={this.toggleBotConfigDrawer}
                    candidate={_.get(candidatesById, [selectedCandidate.Id], {})}
                    currentJobDetails={selectedJob}
                    jobId={selectedCandidate.JobId}
                    jobGuid={selectedCandidate.currentJobGuid}
                    availableProviders={availableProviders}
                    smsCount={_.get(conversationsById, [selectedCandidate.ConversationId, 'UnreadSmsCount'], 0)}
                    chatCount={_.get(conversationsById, [selectedCandidate.ConversationId, 'UnreadChatCount'], 0)}
                    isBotEnabled={featureToggleList.BotRead.IsEnabled}
                    FromEmailAddress={recentReadNotificationInfo.FromAddress}
                    jobCountryCode={jobCountryCode}
                    version={version}
                    openSipCallWindowsApp={openSipCallWindowsApp}
                    isComposeEmailModalVisible={isComposeEmailModalVisible}
                    isComposeMessageModalVisible={isComposeMessageModalVisible}
                    setEmailComposeModalVisibility={this.setEmailComposeModalVisibility}
                    setMessageComposeModalVisibility={this.setMessageComposeModalVisibility}
                    activeConnectContentTab={activeConnectContentTab}
                    onConnectContentTabClick={this.onConnectContentTabClick}
                    connectMessageRef={connectMessageRef}
                    connectMailRef={connectMailRef}
                  />
                  {isComposeButtonVisible ? (
                    <div className="compose-email-message-button-360">
                      <ComposeEmailAndMessageButton
                        setComposeModalVisibility={
                          activeConnectContentTab === 'email'
                            ? this.setEmailComposeModalVisibility
                            : this.setMessageComposeModalVisibility
                        }
                        activeTab={activeConnectContentTab}
                        setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
                        setCurrentReplyingToEmail={setCurrentReplyingToEmail}
                        setSubjectDisability={setSubjectDisability}
                        shouldChangeMailValues
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <Empty
                description={
                  <span>
                    <b>No candidate selected</b>
                    <br />
                    Select a candidate to see the conversation history
                  </span>
                }
                style={{ marginTop: 152 }}
                image={<EmptyConnectIcon style={{ fontSize: '128px' }} />}
              />
            )}
          </div>
        </div>
      ) : (
        alternateState
      );

    if (featureToggleList.Connect.IsAllowed === false) {
      conversationsTab = <PageNotAllowed page="connect" />;
    }

    const atsJobCode = _.get(jobsById, [selectedJob.Id, 'JobCode'], 0);
    const candidateSources = _.get(candidateDetails, ['Sources'], undefined);
    const atsCandidateId = _.get(getInternalSourceWithCandidateId(candidateSources), ['CandidateId'], 0);
    const displayWorkflowContainer =
      activeTab === 'workflows' ? <WorkflowContainer setUrl={setUrl} location={location} /> : null;
    const displayChooseWorkflowContainer = location?.state?.chooseDefaultWorkflow ? (
      <WorkflowTemplateCreationContainer location={location} />
    ) : null;
    const displayCareerportalConversationsContainer =
      activeTab === 'careerPortalConversations' ? (
        <CareerportalConversationsContainer
          featureToggleList={featureToggleList}
          currentUserDetails={currentUserDetails}
          searchConversations={searchOrgLevelConversations}
          orgLevelConversationsFetchApiStatus={orgLevelConversationsFetchApiStatus}
          orgLevelPersonDetailsById={orgLevelPersonDetailsById}
          orglevelConversationsById={orglevelConversationsById}
          orglevelConversationsCount={orglevelConversationsCount}
          setOrgConversationFilterValues={this.setOrgConversationFilterValues}
          setOrgConversationFilterStatus={this.setOrgConversationFilterStatus}
          orgConversationsFilterValues={orgConversationsFilterValues}
          isOrgConversationsFilterApplied={isOrgConversationsFilterApplied}
        />
      ) : null;

    return (
      <>
        {version === 'ats' ? (
          <CallDialogContainer
            callNotesContainer={callNotesContainer}
            atsJobCode={atsJobCode}
            atsCandidateId={atsCandidateId}
            postAtsCallNotes={this.postAtsCallNotes}
            dialingTone={dialingTone}
            ringingTone={ringingTone}
            openSipCallWindowsApp={openSipCallWindowsApp}
          />
        ) : null}
        <div className="connect-container-wrapper">
          <BotConfigDrawerContainer
            jobTitle={this.getJobTitle()}
            visible={showBotConfigDrawer}
            onClose={this.toggleBotConfigDrawer}
            jobId={selectedJob.Id}
            botDrawerRenderFlag={botDrawerRenderFlag}
            toggleBotDrawerRenderFlag={this.toggleBotDrawerRenderFlag}
            AppName={AppName}
          />
          <CandidateDrawerWrapper
            drawerRenderFlag={drawerRenderFlag}
            subTabKey={subTabKey}
            version={version}
            onTabClick={this.onDrawerTabChange}
            toggleDrawerRenderFlag={this.toggleDrawerRenderFlag}
            onClose={this.hideCandidateDrawer}
            visible={candidateDrawerVisiblity}
            candidateId={selectedCandidate.Id}
            candidate={candidateDetails}
            currentJobDetails={selectedJob}
            jobId={selectedJob.Id}
            jobGuid={selectedJob.Guid}
            candidateDetailsStatus={candidateDetailsStatus}
            onCandidateReject={this.onCandidateReject}
            onCandidateStatusChange={this.onCandidateStatusChange}
            fetchCandidateAllDetails={this.fetchCandidateAllDetails}
            toggleBotConfigDrawer={this.toggleBotConfigDrawer}
            onClickAtsPush={this.onClickAtsPush}
            callNotesContainer={callNotesContainer}
            notesContainer={notesContainer}
            dialingTone={dialingTone}
            ringingTone={ringingTone}
            onPageChange={this.onPageChange}
            unlockCandidateResume={this.unlockResume}
            showPushCandidateButton={false}
            showContactInfoButton={featureToggleList.ContactFetch.IsEnabled}
            aryaVersion={userConfig.SubscriptionType}
            candidateAllNotesFetched={candidateAllNotesFetched}
            isConnectOptionsEnabled
            isJobActionsAllowed={!!selectedJob.Id}
            openCandidateView={this.openCandidateView}
            showNextPrevButtons={false}
            closable={false}
            showCandidateDetailTabs={false}
            downloadResume={downloadResume}
            jobCountryCode={jobCountryCode}
            openSipCallWindowsApp={openSipCallWindowsApp}
            keywordsToHighlight={this.keywordsToHighlight(mustHaves, niceToHaves, currentJobDetails)}
            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            onClickAddJobCallBack={onClickAddJobCallBack}
          />
          {displayChooseWorkflowContainer}

          {displayChooseWorkflowContainer ? null : (
            <div className="connect-tab-wrapper">
              <ConnectV2Redirect isVisible={featureToggleList.ConnectV2.IsEnabled} />
              <Tabs
                tabBarStyle={{ marginBottom: '24px' }}
                defaultActiveKey="conversations"
                onTabClick={this.onTabChange}
                activeKey={activeTab}
              >
                <TabPane tab="Conversations" key="conversations">
                  {activeTab === 'conversations' ? conversationsTab : null}
                </TabPane>
                {isCareerportalChatViewEnabled && (
                  <TabPane tab="Career Portal Conversations" key="careerPortalConversations">
                    {displayCareerportalConversationsContainer}
                  </TabPane>
                )}
                <TabPane tab="Templates" key="templates">
                  {activeTab === 'templates' ? (
                    <TemplatesContainer
                      isBotEnabled={featureToggleList.BotRead.IsEnabled}
                      AppName={AppName}
                      version={version}
                      jobDetails={jobDetails}
                      isCandidate360View
                    />
                  ) : null}
                </TabPane>
                {featureToggleList.Workflow.IsEnabled ? (
                  <TabPane tab="Workflows" key="workflows">
                    {displayWorkflowContainer}
                  </TabPane>
                ) : null}
              </Tabs>
            </div>
          )}
        </div>
      </>
    );
  }
}

ConnectContainer.propTypes = {
  jobsIdsByStatus: PropTypes.shape({}),
  jobsById: PropTypes.shape({}),
};

ConnectContainer.defaultProps = {
  jobsIdsByStatus: {},
  jobsById: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectContainer));

export { ConnectContainer as ConnectContainerWithoutStore };
