const maxAllowedRegions = 1;
const maxAllowedLocations = 5;
const maxAllowedLocationsV2 = 6;

const getLocationSectionConfig = (value, countryOptions, isManualSearchV2Enable = false) => {
  const selectedRegionsCount = value?.filter(x => x?.Region?.RegionId)?.length;
  const isRegionSelectionLimitReached = selectedRegionsCount >= maxAllowedRegions;
  const isLocationSelectionLimitReached = isManualSearchV2Enable
    ? value?.length >= maxAllowedLocationsV2
    : value?.length >= maxAllowedLocations;
  const isCountryWideSearchEnabledCountry =
    countryOptions?.find(country => country?.Iso2Code === value?.[0]?.CountryCode)?.StatsLevel === 'Country';

  return {
    isRegionSelectionLimitReached,
    isLocationSelectionLimitReached,
    isCountryWideSearchEnabledCountry,
  };
};

const getTooltipContentForDisabledLocation = ({
  isLocationSelectionLimitReached,
  isCountryWideSearchEnabledCountry,
}) => {
  if (isLocationSelectionLimitReached) return 'You have reached maximum location limit';
  if (isCountryWideSearchEnabledCountry) return 'The selected country will allow only countrywide search';
  return null;
};

const getLocationTagTooltipContent = ({ isAutoExpandExists, isRemoteJob, countryCode, isLocationActive }) => {
  if (isLocationActive) return null;
  if (isRemoteJob) return `Sourcing happens throughout (${countryCode}) as remote job is enabled`;
  if (isAutoExpandExists)
    return 'Sourcing will give precedence to the location with more than 100 miles over other locations';
  return null;
};

const getLocationSectionTooltipContent = ({
  isAutoExpandExists,
  isRemoteJob,
  isLocationSelectionLimitReached,
  isRegionSelectionLimitReached,
  isRegionSelected,
  isLocationSelected,
  isPrimaryLocation,
  isCountryWideSearchEnabledCountry,
}) => {
  const remoteJobTooltip = 'Remote job is enabled';
  const autoExpandTooltip = 'You have already selected a location with more than 100 miles';
  const regionSelectionLimitExceededTooltip = 'You can select Region only once';
  const locationSelectionLimitExceededTooltip = 'You have reached maximum location limit';
  const primaryLocationTooltip = "Region can't be selected for Primary Location";
  const eitherRegionOrLocationTooltip = 'You can select either a Region or Location';
  const cwsEnabledCountryRegionTooltip = 'The selected country will allow only countrywide search';
  const cwsEnabledCountryLocationTooltip = 'The selected country will allow only countrywide search';
  const cwsEnabledCountryZipcodeTooltip = 'The selected country will allow only countrywide search';
  let content = { Region: '', Location: '', Zipcode: '' };
  switch (true) {
    case isCountryWideSearchEnabledCountry:
      content = {
        Region: cwsEnabledCountryRegionTooltip,
        Location: cwsEnabledCountryLocationTooltip,
        Zipcode: cwsEnabledCountryZipcodeTooltip,
      };
      break;
    case isRemoteJob:
      content = { Region: remoteJobTooltip, Location: remoteJobTooltip, Zipcode: remoteJobTooltip };
      break;
    case isAutoExpandExists:
      content = { Region: autoExpandTooltip, Location: autoExpandTooltip, Zipcode: autoExpandTooltip };
      break;
    case isPrimaryLocation:
      content = { Region: primaryLocationTooltip };
      break;
    case isLocationSelectionLimitReached:
      content = {
        Region: locationSelectionLimitExceededTooltip,
        Location: locationSelectionLimitExceededTooltip,
        Zipcode: locationSelectionLimitExceededTooltip,
      };
      break;
    case isRegionSelected:
      content = {
        Location: eitherRegionOrLocationTooltip,
        Zipcode: eitherRegionOrLocationTooltip,
      };
      break;
    case isRegionSelectionLimitReached:
      content = {
        Region: regionSelectionLimitExceededTooltip,
      };
      break;
    case isLocationSelected:
      content = {
        Region: eitherRegionOrLocationTooltip,
      };
      break;
    default:
      break;
  }

  return content;
};

const getLocationSectionDisabledStatus = ({
  isMultiLocationEnabled,
  isLocationSelectionLimitReached,
  isRegionSelectionLimitReached,
  isCountryWideSearchEnabledCountry,
  isRegionSelected,
  isLocationOrZipcodeSelected,
  isRemoteJob,
  isAutoExpandExists,
  isCountryWide,
  autoExpandChecked,
  isStateWideSearchChecked,
}) => {
  const isDisabled =
    (isMultiLocationEnabled && isCountryWideSearchEnabledCountry) ||
    isRemoteJob ||
    isAutoExpandExists ||
    isCountryWide ||
    isLocationSelectionLimitReached;
  const isLocationDisabled = isDisabled || isRegionSelected;
  const isZipcodeDisabled = isDisabled || isRegionSelected || isStateWideSearchChecked;
  const isRegionDisabled =
    isDisabled || isRegionSelectionLimitReached || isLocationOrZipcodeSelected || isStateWideSearchChecked;
  const isDistanceDisabled =
    isDisabled || autoExpandChecked || isRegionSelected || !isLocationOrZipcodeSelected || isStateWideSearchChecked;
  const isAutoExpandDisabled = isDisabled || !isLocationOrZipcodeSelected || isStateWideSearchChecked;
  return { isRegionDisabled, isLocationDisabled, isZipcodeDisabled, isDistanceDisabled, isAutoExpandDisabled };
};

const getLocationTagDisplayText = (location = {}) => {
  const { Region, CountryCode } = location;
  if (Region?.RegionName) {
    return `${Region.RegionName}, ${CountryCode}`;
  }
  const { Location, Postalcode, Distance, DistanceUnit, State } = location || {};
  const displayText = [Location, State, Postalcode];
  if ((Location || State || Postalcode) && Distance) {
    const distance = `${Distance} ${DistanceUnit}`;
    displayText.push(distance);
  }
  return [...displayText, CountryCode].filter(x => x).join(', ');
};

export {
  getLocationSectionConfig,
  getTooltipContentForDisabledLocation,
  getLocationSectionDisabledStatus,
  getLocationTagDisplayText,
  getLocationTagTooltipContent,
  getLocationSectionTooltipContent,
};
