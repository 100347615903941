import React from 'react';
import uuid from 'uuid';
import _ from 'lodash';
import { Tooltip } from 'antd';
import './TextUtils.scss';

function replaceNewline(text) {
  const newline = '\n';

  if (typeof text !== 'string') {
    return [''];
  }

  const lines = text.split(newline);
  return lines.map(line => {
    return (
      <span key={uuid.v4()}>
        {line}
        <br />
      </span>
    );
  });
}

function getTitleCase(title) {
  const titleSplitArray = (title || '').split(' ');
  const newTitleSplitArray = titleSplitArray.map(str => {
    if (str.length < 3) return _.toUpper(str);
    return _.capitalize(str);
  });
  return newTitleSplitArray.join(' ');
}

function getStringWithoutWhiteSpaces(text = '') {
  let textWithoutWhiteSpaces = text.split(' ');
  textWithoutWhiteSpaces = textWithoutWhiteSpaces.map(number => number.trim());
  textWithoutWhiteSpaces = textWithoutWhiteSpaces.join('');
  return textWithoutWhiteSpaces;
}

function getCountableNounForm(singular, plural, count) {
  return count > 1 ? plural : singular;
}

function EllipsedText(props) {
  const { title, text = '', maxTextLength, toolTipOverlayStyle, isTooltipRequired, tooltipPlacement } = props;
  if (title || text?.length > maxTextLength) {
    const displayedText = text?.length > maxTextLength ? `${text.slice(0, maxTextLength)}...` : text;
    return (
      <Tooltip
        title={title || text}
        overlayClassName="ellipsed-text-tooltip"
        overlayStyle={toolTipOverlayStyle}
        zIndex={2500}
        placement={tooltipPlacement}
      >
        {displayedText}
      </Tooltip>
    );
  }
  if (isTooltipRequired)
    return (
      <Tooltip title={text} overlayClassName="ellipsed-text-tooltip" overlayStyle={toolTipOverlayStyle} zIndex={2500}>
        {text}
      </Tooltip>
    );
  return <span>{text}</span>;
}

function getPasswordToolTipText() {
  return (
    <>
      Password requirements:
      <div>Between 8 and 20 characters</div>
      <div>At least one capital letter</div>
      <div>At least one small letter</div>
      <div>At least one numeral</div>
      <div>At least one special character</div>
    </>
  );
}

function getErrorMessage(errorStatus) {
  switch (errorStatus) {
    case 'INVALID_NUMBER':
      return {
        errorTitle: 'Invalid Credit Card Number',
        errorDescription: (
          <>
            <p>The transaction cannot be processed.</p>
            <p>Please enter a valid credit card number.</p>
          </>
        ),
      };
    case 'INVALID_CREDITS':
      return {
        errorTitle: 'Invalid Data',
        errorDescription: (
          <>
            <p>The transaction cannot be processed. The amount exceeds </p>
            <p>the limit set on the account. Please enter a valid amount</p>
          </>
        ),
      };
    case 'INVALID_DATE':
      return {
        errorTitle: 'Invalid Expiration Date',
        errorDescription: (
          <>
            <p>The transaction cannot be processed.</p>
            <p>Please enter a valid expiration date.</p>
          </>
        ),
      };
    case 'INVALID_BILLINGADDRESS':
      return {
        errorTitle: 'Invalid Billing Address',
        errorDescription: 'The billing address provided does not match with the billing address of the card.',
      };
    case 'INVALID_CVV':
      return {
        errorTitle: 'Invalid CVV',
      };
    case 'INVALID_ITEMS':
    case 'INVALID_PROFILEID':
    case 'INVALID_PAYMENTCOMMAND':
      return {
        errorTitle: 'Transaction Declined',
        errorDescription: (
          <div className="order-error-modal-description">
            Please reach out to <a href="mailto:support@leoforce.com">support@leoforce.com</a> if this issue persists
          </div>
        ),
      };
    case 'CONFLICTING_CREATEPAYMENTCOMMAND':
      return {
        errorTitle: null,
        errorDescription: (
          <>
            <p>It seems you have already placed this order before.</p>
            <p> Hold tight while we put together a candidate list for you or</p>{' '}
            <p>contact us if this issue persists.</p>
          </>
        ),
      };
    case 'DUPLICATE_TRANSACTION':
      return {
        errorTitle: null,
        errorDescription: (
          <>
            <p>Sorry, we are not ready to process your order just yet. </p>
            <p>Please wait a couple of minutes and submit your order again.</p>
          </>
        ),
      };
    case 'AUTHORIZENET_GATEWAY_ERROR':
      return {
        errorTitle: 'Transaction Declined',
        errorDescription: 'Oh no! The credit card information was declined.',
      };
    default:
      return {};
  }
}

const getPlainTextFromHTML = htmlText => {
  if (!htmlText) return '';
  return htmlText
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/&nbsp;/g, '')
    .trim();
};

export function getEllipsedText({ text, maxTextLength }) {
  if (text.length > maxTextLength) {
    const maxPrefixLength = maxTextLength - 10;
    const domainStartIndex = text.indexOf('@');
    const emailLastPart = text.slice(domainStartIndex);
    const domainNameSuffixIndex = emailLastPart.indexOf('.');
    let displayedDomainSuffix = emailLastPart.slice(domainNameSuffixIndex);
    let displayedDomainPrefix = text.slice(0, domainStartIndex);
    const domainName = emailLastPart.slice(0, domainNameSuffixIndex);
    if (displayedDomainSuffix.length > 4) displayedDomainSuffix = displayedDomainSuffix.slice(0, 4).concat('...');
    if (displayedDomainPrefix.length > maxPrefixLength)
      displayedDomainPrefix = displayedDomainPrefix.slice(0, maxPrefixLength).concat('...');
    const displayedText = displayedDomainPrefix.concat(domainName).concat(displayedDomainSuffix);
    if (displayedText === text) return { text };
    return { displayedText, text };
  }
  return { text };
}

export const convertHyperlinkToPlainText = htmlString => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  if (tempElement.querySelector('a')) {
    const plainText = tempElement.textContent || tempElement.innerText || '';
    const regex = /<\/?[^>]+(>|$)|\{|\}/g;
    return plainText.replace(regex, '');
  }
  return htmlString;
};

function formatTextMessage(inputString) {
  const regexPattern = /(https?:\/\/mycareers[^/\s]+\/)[st]\/\S+?(\s|$)/g;
  return inputString?.replace(regexPattern, maskURL);
}

function maskURL(match, prefix, suffix) {
  const maskedLength = match.length - prefix.length - suffix.length;
  const maskedText = '*'.repeat(maskedLength);
  return prefix + maskedText + suffix;
}
export const removeCommaFromEnd = str => (str.endsWith(',') ? str.slice(0, -1) : str);

export const removeEmptyParagraphWithBreak = str => {
  const emptyParagraphRegex = /^<p>\s*<br>\s*<\/p>$/;
  return emptyParagraphRegex.test(str) ? '' : str;
};

export {
  replaceNewline,
  getCountableNounForm,
  getStringWithoutWhiteSpaces,
  EllipsedText,
  getPasswordToolTipText,
  getTitleCase,
  getErrorMessage,
  getPlainTextFromHTML,
  formatTextMessage,
};
