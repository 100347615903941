import React, { useState } from 'react';
import { Empty } from 'antd';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import SampleCandidateList from '../../Components/SampleCandidateList/SampleCandidateList';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { getSampleCandidatesDisplayFlag } from '../../Reducers/JobStatusReducer';
import { getCandidates, getCandidateCount, getCandidateNotesByCandidateId } from '../../Reducers/CandidateReducer';
import * as CandidateActions from '../../Actions/CandidateActions';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import CandidateDrawerWrapper from '../CandidateDrawerWrapper/CandidateDrawerWrapper';
import { BenefitCard } from '../../Components/BenefitCard/BenefitCard';
import './SampleCandidateListConatiner.scss';
import SampleCandidatePipeline from '../../Components/SampleCandidatePipeline/SampleCandidatePipeline';
import selectPlanTooltip from '../../Utils/TooltipUtils';
import { EmptyCandidatesIcon } from '../../Icons/AryaIcons';

const socialIconsTooltipText = 'to unlock candidate contact and connect with them directly';

const mapStateToProps = state => {
  return {
    tryNowCandidateApiStatus: getApiStatus(state, 'fetchTryNowCandidateApiStatus'),
    candidatesById: getCandidates(state),
    totalCandidates: getCandidateCount(state),
    featureToggleList: getFeatureToggleList(state),
    currentUser: getCurrentUser(state),
    usersByGuid: getUsersByGuId(state),
    showSampleCandidates: getSampleCandidatesDisplayFlag(state),
    getCandidate360Notes: candidateId => getCandidateNotesByCandidateId(state, candidateId),
  };
};

const mapDispatchToProps = {
  fetchTryNowCandidateDetails: CandidateActions.fetchTryNowCandidateDetails,
  fetchCandidate360Notes: CandidateActions.getAllCandidateNotes,
};

function SampleCandidateListContainer(props) {
  const [candidateDrawerVisiblity, setCandidateDrawerVisiblity] = useState(false);
  const [drawerRenderFlag, setDrawerRenderFlag] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState();

  const {
    candidatesById,
    featureToggleList,
    totalCandidates,
    tryNowCandidateApiStatus,
    history,
    showBanner,
    selectPlan,
    showSampleCandidates,
    userConfig,
    fetchCandidate360Notes,
    getCandidate360Notes,
  } = props;

  const setCandidate = prop => {
    setCandidateDrawerVisiblity(visiblity => !visiblity);
    setDrawerRenderFlag(drawerFlag => !drawerFlag);
    setSelectedCandidateId(prop.Id);
  };

  const onClickNext = () => {
    const candidateIds = Object.keys(props.candidatesById);
    const selectedCandidateIndex = candidateIds.indexOf(selectedCandidateId);
    if (selectedCandidateIndex !== candidateIds.length - 1) {
      setSelectedCandidateId(candidateIds[selectedCandidateIndex + 1]);
    }
  };

  const onClickPrev = () => {
    const candidateIds = Object.keys(props.candidatesById);
    const selectedCandidateIndex = candidateIds.indexOf(selectedCandidateId);
    if (selectedCandidateIndex !== 0) {
      setSelectedCandidateId(candidateIds[selectedCandidateIndex - 1]);
    }
  };

  const toggleDrawerRenderFlag = () => {
    setDrawerRenderFlag(drawerFlag => !drawerFlag);
  };

  const toggleCandidateDrawerVisiblity = () => {
    setCandidateDrawerVisiblity(visiblity => !visiblity);
    setDrawerRenderFlag(drawerFlag => !drawerFlag);
  };

  const getCandidateDrawerWrapper = () => {
    const {
      quickSearchcandidateDetails,
      downloadResume,
      notesContainer,
      callNotesContainer,
      postAtsCallNotes,
      dialingTone,
      ringingTone,
      version,
      openInNewTab,
      candidateListStatus,
      candidateAllNotesFetchStatus,
      fetchTryNowCandidateDetails,
      jobDetails,
    } = props;
    const candidateDetails = { ..._.get(candidatesById, [selectedCandidateId], {}) };
    const candidateIds = Object.keys(candidatesById);
    const selectedCandidateIndex = candidateIds.indexOf(selectedCandidateId);

    const disablePrev = !selectedCandidateIndex;
    const disableNext = selectedCandidateIndex === candidateIds.length - 1;

    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    return (
      <CandidateDrawerWrapper
        selectPlan={selectPlan}
        allowResumeDownload
        onClose={toggleCandidateDrawerVisiblity}
        visible={candidateDrawerVisiblity}
        toggleDrawerRenderFlag={toggleDrawerRenderFlag}
        drawerRenderFlag={drawerRenderFlag}
        candidateId={_.get(candidateDetails, ['Id'], '')}
        activeKey="profile"
        subTabKey="profile"
        candidateDetailsStatus={quickSearchcandidateDetails}
        candidate={candidateDetails}
        downloadResume={downloadResume}
        showPushCandidateButton={false}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postAtsCallNotes}
        dialingTone={dialingTone}
        ringingTone={ringingTone}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        disableNext={disableNext}
        disablePrev={disablePrev}
        aryaVersion={_.get(userConfig, 'SubscriptionType', null)}
        showPushError={false}
        version={version}
        candidateType="quick-search"
        openInNewTab={openInNewTab}
        candidateListStatus={candidateListStatus}
        candidateAllNotesFetched={candidateAllNotesFetched}
        isConnectOptionsEnabled // !Note this to make display connect icons
        showContactInfoButton={false}
        isJobActionsAllowed
        showCandidateDetailTabs
        callTabTooltipTitle={selectPlanTooltip(selectPlan, socialIconsTooltipText)}
        botTabTooltipTitle={selectPlanTooltip(selectPlan, socialIconsTooltipText)}
        mailTabTooltipTitle={selectPlanTooltip(selectPlan, socialIconsTooltipText)}
        smsTabTooltipTitle={selectPlanTooltip(selectPlan, socialIconsTooltipText)}
        banner={<BenefitCard history={history} redirectPath={selectPlan} type="CandidateDrawer" />}
        featureToggleList={{
          ...featureToggleList,
          ShortlistReject: { Name: 'ShortlistReject', IsEnabled: true, IsAllowed: false },
        }}
        isTryNow
        showPortalIcon={false}
        showCandidateSource={false}
        fetchTryNowCandidateDetails={fetchTryNowCandidateDetails}
        tryNowData={jobDetails}
      />
    );
  };

  return (
    <>
      <div className="sample-list-candidate-status">
        <SampleCandidatePipeline selectPlan={selectPlan} sourcedCount={totalCandidates} />
      </div>
      <div style={{ paddingBottom: '40px' }}>
        {totalCandidates ? (
          <>
            {candidateDrawerVisiblity ? getCandidateDrawerWrapper() : null}
            <SampleCandidateList
              selectPlan={selectPlan}
              candidatesById={candidatesById}
              featureToggleList={featureToggleList}
              totalCandidates={totalCandidates}
              tryNowCandidateApiStatus={tryNowCandidateApiStatus}
              showCandidateDetail={setCandidate}
              showSampleCandidates={showSampleCandidates}
              userConfig={userConfig}
              getCandidate360Notes={getCandidate360Notes}
              fetchCandidate360Notes={fetchCandidate360Notes}
            />
          </>
        ) : (
          <div>
            <div className="sample-candidate-empty-list-benefit-card">
              {!showBanner && <BenefitCard history={history} key="ad-card" />}
            </div>
            <Empty
              image={<EmptyCandidatesIcon className="sample-candidate-empty-list" />}
              description={
                <div className="sample-candidate-not-found-content">
                  No candidates based on the job created.
                  <p>
                    Please review and make changes to Job Title, Location, Miles or Skills to widen your search and get
                    relevant sample candidates.
                  </p>
                </div>
              }
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SampleCandidateListContainer));
export { SampleCandidateListContainer as SampleCandidateListContainerWithoutStore };
