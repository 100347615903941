import React, { useEffect } from 'react';
import { Icon, Dropdown, Menu, Form, Skeleton } from 'antd';
import uuid from 'uuid';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { fetchSuggestions } from '../../Actions/SuggestionsActions';
import InputSearch from '../InputSearch/InputSearch';
import BooleanDisplay from '../BooleanDisplay/BooleanDisplay';
import './SkillMissingContent.scss';

const { Item } = Form;

export default function SkillMissingContent(props) {
  const {
    value: currentMissingSkills = {},
    jobId,
    candidateId,
    onChange,
    rejectReasonKey,
    form,
    suggestions,
    suggestionsFetchApiStatus,
  } = props;
  const dispatch = useDispatch();
  const [inputVisibilityKey, setInputVisibilityKey] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    const clonedCurrentMissingSkills = _.cloneDeep(currentMissingSkills);
    const mergedSkills = { ...clonedCurrentMissingSkills, ...suggestions };
    onChange(mergedSkills);
  }, [suggestions]);

  const addMissingSkills = currentSkills => {
    const skills = {};
    currentSkills.split(',').forEach(currentSkill => {
      const currentIndex = uuid.v4();
      skills[currentIndex] = currentSkill;
    });
    dispatch(
      fetchSuggestions({
        jobId,
        candidateId,
        skills,
        suggestionsType: rejectReasonKey,
      })
    );
    form.setFieldsValue({ [`${rejectReasonKey}-Input`]: '' });
  };

  const updateCurrentSkills = ({
    clonedCurrentMissingSkills,
    currentIndex,
    updatedCurrentKeySelectedSkills,
    updatedCurrentKeySuggestedSkills,
  }) => {
    _.setWith(clonedCurrentMissingSkills, [currentIndex, 'selectedSkills'], updatedCurrentKeySelectedSkills, Object);
    _.setWith(clonedCurrentMissingSkills, [currentIndex, 'suggestedSkills'], updatedCurrentKeySuggestedSkills, Object);
    onChange(clonedCurrentMissingSkills);
  };

  const deSelectSkill = (reason, currentIndex) => {
    const clonedCurrentMissingSkills = _.cloneDeep(currentMissingSkills);
    const currentIndexMissingSkills = clonedCurrentMissingSkills[currentIndex];
    const updatedCurrentKeySelectedSkills = currentIndexMissingSkills.selectedSkills.filter(
      currentSkill => currentSkill !== reason
    );
    const updatedCurrentKeySuggestedSkills = (currentIndexMissingSkills.suggestedSkills ?? []).concat(reason);
    updateCurrentSkills({
      clonedCurrentMissingSkills,
      currentIndex,
      updatedCurrentKeySelectedSkills,
      updatedCurrentKeySuggestedSkills,
    });
  };

  const selectSkill = (reason, currentIndex) => {
    const clonedCurrentMissingSkills = _.cloneDeep(currentMissingSkills);
    const currentIndexMissingSkills = clonedCurrentMissingSkills[currentIndex];
    if (
      !currentIndexMissingSkills.selectedSkills.find(
        selectedSkill => selectedSkill.toLowerCase() === reason.toLowerCase()
      )
    ) {
      const updatedCurrentKeySelectedSkills = currentIndexMissingSkills.selectedSkills.concat(reason);
      const updatedCurrentKeySuggestedSkills = currentIndexMissingSkills.suggestedSkills?.filter(
        currentSkill => currentSkill !== reason
      );
      updateCurrentSkills({
        clonedCurrentMissingSkills,
        currentIndex,
        updatedCurrentKeySelectedSkills,
        updatedCurrentKeySuggestedSkills,
      });
    }
  };

  const suggestedSkillsDropdown = (skills, currentIndex) => {
    const { allSkills, suggestedSkills, selectedSkills } = skills;
    const filteredSkills = allSkills?.filter(
      skill =>
        !(suggestedSkills?.includes(skill) || selectedSkills?.includes(skill)) &&
        skill?.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (!filteredSkills?.length) return <div />;
    return (
      <Menu className="reject-reasons-skill-suggestions-menu">
        {filteredSkills.map(filteredSkill => (
          <Menu.Item
            key={filteredSkill}
            onClick={() => {
              selectSkill(filteredSkill, currentIndex);
            }}
          >
            {filteredSkill}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <div className="skill-missing-content-wrapper">
      <div className="skill-missing-title-input-wrapper">
        <div className="skill-missing-content-title">Add Missing Mandatory Skills</div>
        <Item>
          {form.getFieldDecorator(`${rejectReasonKey}-Input`)(
            <InputSearch
              className="add-missing-skill-input"
              placeholder="Add Skills (ex: XML)"
              enterButton={<Icon type="plus" />}
              onSearch={addMissingSkills}
            />
          )}
        </Item>
      </div>
      <div className="selected-skills-wrapper">
        {Object.keys(currentMissingSkills).map(skillKey => (
          <Dropdown
            overlay={suggestedSkillsDropdown(currentMissingSkills[skillKey], skillKey)}
            visible={skillKey === inputVisibilityKey}
            overlayClassName="reject-reasons-skill-suggestions-dropdown"
            key={skillKey}
          >
            <BooleanDisplay
              key={skillKey}
              selectedTags={currentMissingSkills[skillKey].selectedSkills}
              suggestedTags={currentMissingSkills[skillKey].suggestedSkills}
              currentIndex={skillKey}
              deSelectSkill={deSelectSkill}
              selectSkill={selectSkill}
              selectedTagClassName="boolean-display-selected-tag"
              suggestedTagClassName="boolean-display-suggested-tag"
              inputVisibility={inputVisibilityKey === skillKey}
              setInputVisibilityKey={setInputVisibilityKey}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </Dropdown>
        ))}
        {suggestionsFetchApiStatus === 'INPROGRESS' ? <Skeleton active paragraph={{ rows: 1 }} /> : null}
      </div>
    </div>
  );
}
