import React from 'react';
import uuid from 'uuid';
import { Form, Icon, Tag } from 'antd';
import classNames from 'classnames';
import InputSearch from '../../../../../Components/InputSearch/InputSearch';
import MustHavesDisplay from '../../../../../CompoundComponents/MustHavesWrapper/MustHavesDisplaySection';
import MustHavesInput from '../../../../../CompoundComponents/MustHavesWrapper/MustHavesInput';
import MustHavesWrapper from '../../../../../CompoundComponents/MustHavesWrapper/MustHavesWrapper';
import { DownArrowIcon, UpArrowIcon } from '../../../../../Icons/AryaIcons';
import TextWithInfoIcon from '../../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import styles from './MoreSkillsSection.module.scss';

const { Item } = Form;

const MustHaves = ({ value, onChange, shouldAddClassname, disabledButtonClassname, enabledButtonClassname }) => (
  <MustHavesWrapper mustHaves={value} onChange={onChange}>
    <MustHavesInput
      shouldAddClassname={shouldAddClassname}
      disabledButtonClassname={disabledButtonClassname}
      enabledButtonClassname={enabledButtonClassname}
    />
    <div className={styles.mustHavesDisplay}>
      <MustHavesDisplay />
    </div>
  </MustHavesWrapper>
);

const ExcludeSkills = props => {
  const { value, onChange } = props;

  const onRemoveExcludedSkills = skillId => {
    const currentExcludedSkills = value;
    const updateVisaStatuses = currentExcludedSkills.filter(skill => skill.id !== skillId);
    onChange(updateVisaStatuses);
  };

  const excludedKeywords = value;

  if (!excludedKeywords?.length) return null;
  return (
    <div className={styles.displayedExludedSkills}>
      {excludedKeywords?.map(skill => (
        <Tag key={skill.id} className={styles.excludedSkillTag}>
          <div className={styles.excludedSkillTagText}>{skill?.name}</div>
          <Icon type="close" onClick={() => onRemoveExcludedSkills(skill.id)} />
        </Tag>
      ))}
    </div>
  );
};

export default function MoreSkillsSection(props) {
  const { form, defaultMustHaves, defaultExcludes = [], isManualSearchFormMinimized } = props;
  const [isMoreSkillsSectionVisible, setMoreSkillsSectionVisiblity] = React.useState(false);
  const [excludeSkillInput, setExcludeSkillInput] = React.useState('');

  const handleSearch = skill => {
    const currentExcludedSkills = form.getFieldValue('ExcludedSkills') ?? [];
    const lowerCasedSkill = skill.toLowerCase();
    const updatedExcludedSkills = currentExcludedSkills
      .filter(x => x.name.toLowerCase() !== lowerCasedSkill)
      .concat({
        id: uuid.v4(),
        isSelected: true,
        name: skill,
      });
    form.setFieldsValue({ ExcludedSkills: updatedExcludedSkills });
    setExcludeSkillInput('');
  };
  const containerClassNames = isMoreSkillsSectionVisible ? styles.displayBlock : styles.displayInlineBlock;
  const additionalSkillInputClassname = !excludeSkillInput.trim()
    ? styles.disabledSkillsButton
    : styles.enabledSkillsButton;

  React.useEffect(() => {
    if ((defaultMustHaves && Object?.keys(defaultMustHaves)?.length) || defaultExcludes?.length) {
      setMoreSkillsSectionVisiblity(true);
    }
  }, []);

  const moreSkillsContainerStyles = isManualSearchFormMinimized ? { display: 'none' } : {};

  const hideExcludeAndMustHavesSkills = !isMoreSkillsSectionVisible ? { display: 'none' } : {};

  return (
    <div className={classNames(styles.moreSkillsSection, containerClassNames)} style={moreSkillsContainerStyles}>
      <div
        className={styles.showMoreLessButton}
        role="button"
        tabIndex={0}
        onClick={() => setMoreSkillsSectionVisiblity(!isMoreSkillsSectionVisible)}
        onKeyPress={() => setMoreSkillsSectionVisiblity(!isMoreSkillsSectionVisible)}
      >
        <span>See {isMoreSkillsSectionVisible ? 'less' : 'more'} options</span>
        {isMoreSkillsSectionVisible ? <UpArrowIcon /> : <DownArrowIcon />}
      </div>
      <div className={styles.moreSkillsWrapperSection} style={hideExcludeAndMustHavesSkills}>
        <div className={classNames(styles.addSkills)}>
          <TextWithInfoIcon
            text={<div className={styles.label}>Exclude</div>}
            tooltipTitle="Exclude Keywords feature is not available for Monster Power"
          />
          <InputSearch
            placeholder="+ Add Keywords"
            className={styles.addSkillsInput}
            enterButton={<Icon type="plus" style={{ display: 'flex' }} />}
            onSearch={handleSearch}
            value={excludeSkillInput}
            onChange={setExcludeSkillInput}
            shouldAddClassname
            additionalSkillInputClassname={additionalSkillInputClassname}
          />
          {form.getFieldDecorator('ExcludedSkills', { initialValue: defaultExcludes })(<ExcludeSkills />)}
        </div>

        <div className={styles.mustHavesContainer}>
          <TextWithInfoIcon
            text={<div className={styles.label}>Must Haves</div>}
            tooltipTitle="Adding multiple Must haves will result in yielding fewer results."
          />
          <Item colon={false} className={styles.mustsInput}>
            {form.getFieldDecorator('MustHaves', { initialValue: defaultMustHaves })(
              <MustHaves
                shouldAddClassname
                disabledButtonClassname={styles.disabledSkillsButton}
                enabledButtonClassname={styles.enabledSkillsButton}
              />
            )}
          </Item>
        </div>
      </div>
    </div>
  );
}
