import React from 'react';
import { connect } from 'react-redux';
import ReportPortalComponent from '../../Components/ReportPortal/ReportPortalComponent';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import styles from './ReportPortalContainer.module.scss';

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
  };
};

export function ReportPortalContainer(props) {
  const { featureToggleList } = props;
  return (
    <div className={styles.reportingPortalContainer}>
      <ReportPortalComponent featureToggleList={featureToggleList} />
    </div>
  );
}

export default connect(mapStateToProps)(ReportPortalContainer);
