import { Form, Input, Modal, Button, Skeleton } from 'antd';
import React from 'react';
import styles from './AddListModal.module.scss';
import { validateSegmentDescription, validateSegmentTitle } from '../../../Utils/SegmentValidationUtils';

function AddListModal(props) {
  const {
    isModalVisible,
    handleCancel,
    handleOk,
    title,
    buttonName,
    form,
    isLoading,
    inputText,
    characterLimit,
    onDescriptionInputChange,
    jobDetailsStatus
  } = props;
  const handleChange = event => {
    onDescriptionInputChange(event.target.value);
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      centered
      onCancel={handleCancel}
      footer={[
        <Button shape="round" key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button shape="round" key="submit" type="primary" onClick={handleOk} loading={isLoading}>
          {buttonName}
        </Button>,
      ]}
    >
      <Skeleton active paragraph={{ rows: 4 }} loading={jobDetailsStatus === 'INPROGRESS'}>
        <Form>
          <div className={styles.modalContent}>
            <Form.Item rules={[{ required: true }]}>
              {form?.getFieldDecorator('listName', {
                rules: [{ required: true, validator: validateSegmentTitle }],
              })(<Input placeholder="Enter a name" />)}
            </Form.Item>
            <br />
            <br />
            <div className={styles.modalTextArea}>
              <Form.Item>
                {form?.getFieldDecorator('listDescription', {
                  rules: [{ validator: validateSegmentDescription }],
                })(
                  <Input.TextArea
                    placeholder="Enter short description"
                    style={{ height: '100px' }}
                    onChange={handleChange}
                  />
                )}
              </Form.Item>
              {(inputText?.trim()?.length || 0) <= characterLimit ? (
                <p style={{ textAlign: 'right' }}>
                  {inputText?.trim()?.length || 0}/{characterLimit}
                </p>
              ) : (
                <p style={{ textAlign: 'right', color: '#f5222d' }}>
                  {characterLimit - inputText?.trim()?.length}/{characterLimit}
                </p>
              )}
            </div>
          </div>
        </Form>
      </Skeleton>
    </Modal>
  );
}

export default AddListModal;
