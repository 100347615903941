import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Input, Pagination, Skeleton, Empty } from 'antd';
import classNames from 'classnames';
import styles from './JobMatchingModal.module.scss';
import { JobMatchingModalListItem } from './JobMatchingListItem';
import { EmptyJobsIcon } from '../../Icons/AryaIcons';
import CandidateJobsMatchingFilter from '../CandidateJobsFilter/CandidateJobsMatchingFilter';
import ClearFilters from '../Common/ClearFilters/ClearFilters';
import { candidateJobMatchingfilters } from '../../Utils/CandidateJobMatchingUtils';
import { scrollToCandidate360Top } from '../../Utils/CandidateDrawerUtils';
import { setFetchCandidateMatchingJobsApiStatus } from '../../Actions/ActionCreators/CandidateActions';

const MAX_TOTAL_JOB_DISPLAY_COUNT = 2000;

const getTotalJobDisplayCount = totalCount => Math.min(totalCount, MAX_TOTAL_JOB_DISPLAY_COUNT);

export const JobMatchingModal = props => {
  const {
    setCandidateMatchingJobModalVisiblity,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    candidateMatchingJobsTotalCount,
    candidateMatchingJobs,
    candidateMatchingJobsApiStatus,
    fetchCandidateMatchingJobs,
    candidateMatchingJobHighlights,
    candidate,
    recommendCandidate,
    sourceCandidateStatusUpdate,
    matchingJobsAggregations,
    version,
    isCandidateViewHeaderVisible,
    isFilterPopoverVisible,
    setIsFilterPopoverVisbility,
    countries,
    candidateJobMatchingInitialAggregation,
    candidateJobsTabRef,
    currentUser,
    setIsFindMathingJobClicked,
    searchTerm,
    setSearchTerm,
    setCandidateMatchingJobFilters,
    selectedMatchingJobTitleFilterOptions = [],
    selectedMatchingJobLocationFilterOptions = [],
    selectedMatchingJobIndustryFilterOptions = [],
    selectedMatchingJobMinExperienceFilterOptions = null,
    selectedMatchingJobMaxExperienceFilterOptions = null,
  } = props;

  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize] = React.useState(10);
  const [recommendCandidateMatchingJobsFetch, setRecommendCandidateMatchingJobsFetch] = React.useState(false);
  const [minExperience, setMinExperience] = React.useState(null);
  const [maxExperience, setMaxExperience] = React.useState(null);
  const [selectedFilterOptionsGroup, setFilterOptionsGroup] = React.useState(candidateJobMatchingfilters.title);

  const [isSearchBoxFocused, setIsSearchBoxFocused] = React.useState(false);

  let newMatchingJobText = `New Matching Jobs`;
  if (candidateMatchingJobsApiStatus === 'COMPLETED')
    newMatchingJobText = `New Matching Jobs (${getTotalJobDisplayCount(candidateMatchingJobsTotalCount)})`;

  const getJobListFetchPayload = ({ _page, _size, _searchTerm }) => {
    return {
      candidateId: candidate.Id,
      size: _size,
      from: _page >= 1 ? (_page - 1) * _size : 0,
      searchTerm: _searchTerm ?? searchTerm,
      location: selectedMatchingJobLocationFilterOptions,
      candidateExperience: selectedMatchingJobMinExperienceFilterOptions,
      industries: selectedMatchingJobIndustryFilterOptions,
      jobStatuses: [],
      excludeJobStatuses: [],
      titles: selectedMatchingJobTitleFilterOptions,
      candidateTitle: candidate.Title,
      skills: candidate.Skills?.slice(0, 200),
      countryCodes: candidate.Country,
    };
  };

  const getMatchingJobs = (_page, _size, _searchTerm, shouldShowLoader) => {
    const jobListFetchPayload = getJobListFetchPayload({
      _page,
      _size,
      _searchTerm,
    });
    fetchCandidateMatchingJobs(jobListFetchPayload, shouldShowLoader);
  };

  const onPageChange = _page => {
    setPageNumber(_page);
    getMatchingJobs(_page, pageSize);
    scrollToCandidate360Top(candidateJobsTabRef);
  };

  const onSearch = value => {
    if (searchTerm === value) {
      return;
    }
    setPageNumber(1);
    getMatchingJobs(1, pageSize, value?.trim());
    setSearchTerm(value?.trim());
  };

  const onSearchBlur = () => {
    setIsSearchBoxFocused(false);
  };

  const onSearchFocus = () => {
    setIsSearchBoxFocused(true);
  };

  const onClickBackToRecentJobs = () => {
    setCandidateMatchingJobModalVisiblity(false);
    setIsFindMathingJobClicked(false);
    if (!candidateMatchingJobs?.length) {
      dispatch(setFetchCandidateMatchingJobsApiStatus('INPROGRESS'));
    }
  };

  const isFilterSelected =
    selectedMatchingJobLocationFilterOptions?.length ||
    selectedMatchingJobIndustryFilterOptions?.length ||
    selectedMatchingJobTitleFilterOptions?.length ||
    selectedMatchingJobMaxExperienceFilterOptions ||
    selectedMatchingJobMinExperienceFilterOptions > 0;

  const onClearFilter = () => {
    if (isFilterSelected || !_.isEqual(candidateJobMatchingInitialAggregation, matchingJobsAggregations)) {
      setCandidateMatchingJobFilters('Industry', []);
      setCandidateMatchingJobFilters('JobTitle', []);
      setCandidateMatchingJobFilters('Location', []);
      setCandidateMatchingJobFilters('MinExperience', null);
      setCandidateMatchingJobFilters('MaxExperience', null);
      setMaxExperience(null);
      setMinExperience(null);
      setFilterOptionsGroup(candidateJobMatchingfilters.title);
      fetchCandidateMatchingJobs({
        candidateId: candidate.Id,
        size: pageSize,
        from: 0,
        searchTerm,
        location: [],
        candidateExperience: null,
        industries: [],
        jobStatuses: [],
        excludeJobStatuses: [],
        titles: [],
        candidateTitle: candidate.Title,
        skills: candidate.Skills?.slice(0, 200),
        countryCodes: candidate.Country,
        exactTitleMatch: false,
      });
    }
    setIsFilterPopoverVisbility(false);
  };

  const getNoJobMessage = () => {
    if (!isFilterSelected)
      return <span className={styles.jobListNoJobFoundMessage}>All the matching jobs appear here</span>;
    return null;
  };

  const noJobMessage = getNoJobMessage();
  const clearAllFilters = isFilterSelected ? <ClearFilters onClearFilter={onClearFilter} /> : null;

  return (
    <div>
      <div
        className={classNames(styles.jobCardInfo, { [styles.extendJobMatchingView]: !isCandidateViewHeaderVisible })}
      >
        <div className={styles.recentJobsWrapper}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <div
              className={styles.seeRecentJobs}
              role="button"
              tabIndex={0}
              onClick={onClickBackToRecentJobs}
              onKeyPress={onClickBackToRecentJobs}
            >
              Recent Jobs
            </div>

            <div className={styles.textDivider}>&nbsp;/&nbsp;</div>
            {newMatchingJobText}
          </div>
          <div className={styles.jobsTabHeader}>
            <div className={styles.searchAndFilter}>
              <Input.Search
                size="default"
                placeholder={isSearchBoxFocused ? 'Search with title or id' : 'Search'}
                allowClear
                className={styles.jobsInputSearch}
                onSearch={onSearch}
                onBlur={onSearchBlur}
                onFocus={onSearchFocus}
                defaultValue={searchTerm}
              />
              <CandidateJobsMatchingFilter
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                pageSize={pageSize}
                candidate={candidate}
                fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
                candidateMatchingJobsApiStatus={candidateMatchingJobsApiStatus}
                candidateMatchingJobsTotalCount={candidateMatchingJobsTotalCount}
                searchTerm={searchTerm}
                matchingJobsAggregations={matchingJobsAggregations}
                selectedMatchingJobLocationFilterOptions={selectedMatchingJobLocationFilterOptions}
                selectedMatchingJobTitleFilterOptions={selectedMatchingJobTitleFilterOptions}
                selectedMatchingJobIndustryFilterOptions={selectedMatchingJobIndustryFilterOptions}
                isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                setCandidateMatchingJobFilters={setCandidateMatchingJobFilters}
                selectedMatchingJobMinExperienceFilterOptions={selectedMatchingJobMinExperienceFilterOptions}
                selectedMatchingJobMaxExperienceFilterOptions={selectedMatchingJobMaxExperienceFilterOptions}
                isFilterPopoverVisible={isFilterPopoverVisible}
                setIsFilterPopoverVisbility={setIsFilterPopoverVisbility}
                setFilterOptionsGroup={setFilterOptionsGroup}
                candidateJobMatchingfilters={candidateJobMatchingfilters}
                onClearFilter={onClearFilter}
                selectedFilterOptionsGroup={selectedFilterOptionsGroup}
                minExperience={minExperience}
                setMinExperience={setMinExperience}
                maxExperience={maxExperience}
                setMaxExperience={setMaxExperience}
                isFilterSelected={isFilterSelected}
                countries={countries}
                candidateCountry={candidate.Country}
                candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
              />
            </div>
          </div>
        </div>
        <Skeleton loading={candidateMatchingJobsApiStatus === 'INPROGRESS'} paragraph={{ rows: 1 }}>
          {candidateMatchingJobsTotalCount > 0 && candidateMatchingJobsApiStatus === 'COMPLETED' ? (
            candidateMatchingJobs.map(matchingJob => {
              const matchingJobHighlights = candidateMatchingJobHighlights.find(
                x => x.JobId === matchingJob.JobId
              )?.Highlights;
              return (
                <React.Fragment key={`${_.get(matchingJob, 'JobId')}`}>
                  <div className={styles.jobMultiSelect}>
                    <div className={styles.jobListItemWrapper} key={_.get(matchingJob, 'JobId')}>
                      <JobMatchingModalListItem
                        matchingJob={matchingJob}
                        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
                        onClickAddJobCallBack={onClickAddJobCallBack}
                        matchingJobHighlights={matchingJobHighlights}
                        candidate={candidate}
                        recommendCandidate={recommendCandidate}
                        sourceCandidateStatusUpdate={sourceCandidateStatusUpdate}
                        setRecommendCandidateMatchingJobsFetch={setRecommendCandidateMatchingJobsFetch}
                        recommendCandidateMatchingJobsFetch={recommendCandidateMatchingJobsFetch}
                        getMatchingJobs={getMatchingJobs}
                        version={version}
                        countries={countries}
                        currentUser={currentUser}
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div className={styles.emptyScreen}>
              <Empty
                image={<EmptyJobsIcon className={styles.jobListEmpty} />}
                description={
                  <span>
                    <span className={styles.jobListNoJobFound}> No Jobs found</span>
                    <br />
                    {noJobMessage}
                    {clearAllFilters}
                  </span>
                }
              />
            </div>
          )}
        </Skeleton>
      </div>
      {candidateMatchingJobsApiStatus === 'COMPLETED' ? (
        <div className={styles.paginationContainer}>
          <Pagination
            size="small"
            pageSize={pageSize}
            total={getTotalJobDisplayCount(candidateMatchingJobsTotalCount)}
            onChange={onPageChange}
            hideOnSinglePage={candidateMatchingJobsTotalCount <= 10}
            current={pageNumber}
          />
        </div>
      ) : null}
    </div>
  );
};
