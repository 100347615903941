import { defineMessages } from 'react-intl';

const messages = defineMessages({
  aryaBotSettings: {
    id: 'Chatbot Settings',
    values: {
      AppName: 'Arya',
    },
    defaultMessages: 'Chatbot Settings',
  },
  descriptionText: {
    id: 'BotSettings.DescriptionText',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Configure basic settings for Aryabot.',
  },
  uiSettings: {
    id: 'BotSettings.Title.UiSettings',
    defaultMessage: 'UI Settings',
  },
  chatbotTitle: {
    id: 'BotSettings.Label.ChatbotTitle',
    defaultMessage: 'Chatbot Title',
  },
  botIcon: {
    id: 'BotSettings.Label.BotIcon',
    defaultMessage: 'Boticon',
  },
  favicon: {
    id: 'BotSettings.Label.Favicon',
    defaultMessage: 'Favicon',
  },
  botSettings: {
    id: 'BotSettings.Title.ChatbotSettings',
    defaultMessage: 'Chatbot Settings',
  },
  botName: {
    id: 'BotSettings.Label.ChatbotName',
    defaultMessage: 'Chatbot Name',
  },
  botLocation: {
    id: 'BotSettings.Label.ChatbotLocation',
    defaultMessage: 'Chatbot Location',
  },
  botDescription: {
    id: 'BotSettings.Label.ChatbotDescription',
    defaultMessage: 'Chatbot Description',
  },
  botGreetingMessage: {
    id: 'BotSettings.Label.GreetingMessage',
    defaultMessage: 'Greeting Message',
  },
  botGoodbyeMessage: {
    id: 'BotSettings.Label.GoodbyeMessage',
    defaultMessage: 'Goodbye Message',
  },
  botTitlePlaceholder: {
    id: 'BotSettings.Placeholder.BotTitle',
    defaultMessage: 'Enter title name',
  },
  botNamePlaceholder: {
    id: 'BotSettings.Placeholder.ChatbotName',
    defaultMessage: 'Enter Chatbot Name',
  },
  botLocationPlaceholder: {
    id: 'BotSettings.Placeholder.ChatbotLocation',
    defaultMessage: 'Enter Chatbot Location',
  },
  botDescriptionPlaceholder: {
    id: 'BotSettings.Placeholder.BotDescription',
    defaultMessage: 'Enter description',
  },
  botGreetingPlaceholder: {
    id: 'BotSettings.Placeholder.ChatbotGreeting',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: "Hello, I'm Chatbot! how can I help you achieve your career goals?",
  },
  botGoodbyePlaceholder: {
    id: 'BotSettings.Placeholder.BotGoodbye',
    defaultMessage: 'Thank you for your time! I look forward to chatting with you again.',
  },
  submitButtonText: {
    id: 'BotSettings.SubmitButton.Text',
    values: {
      AppName: 'Arya',
    },
    defaultMessage: 'Preview Aryabot',
  },
});

export default messages;
