import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import CandidateSourceAndName from '../CandidateCard/CandidateSourceAndName/CandidateSourceAndName';
import styles from './EnhancedCandidateInformationCard.module.scss';
import CandidateBookmark from '../CandidateCard/CandidateBookmark';
import { getCandidateScoreContent } from '../CandidateCard/CandidateScore/CandidateScore';
import { CandidateScoreIcon, WorkExperienceIcon } from '../../Icons/AryaIcons';
import { getJoinedDisplayedInfo } from '../../Utils/CandidateCardUtils';
import FindMatchingJobWidgetWrapper from '../ConnectCard/FindMatchingJobWidget/FindMatchingJobWidgetWrapper';
import SocialIcon from '../Common/SocialIcon/SocialIcon';
import FetchContactWidgetWrapper from '../ConnectCard/FetchContactWidget/FetchContactWidgetWrapper';
import SourceInfoWidgetWrapper from '../ConnectCard/SourceInfoWidget/SourceInfoWidgetWrapper';
import { checkAccessConfig, getSourceData, getCandidateStatusFromSources } from '../../Utils/CandidateDrawerUtils';
import { getLatestProfileDateMarkup } from '../../Utils/CandidateListUtils';
import AtsPushComponent from './AtsPushComponent';
import PushCandidateComponent from './PushCandidateComponent';
import CandidateViewIconV2 from '../../Icons/CandidateViewIconV2';
import { highlightKeyWords, getUniqueHighlightKeywords } from '../../Utils/KeywordHighlightUtil';

function EnhancedCandidateInformationCard(props) {
  const {
    candidate = {},
    userConfig = {},
    version,
    whiteLabelInfo,
    featureToggleList,
    openCandidateView,
    candidateContext,
    jobId,
    updateCandidateBookmarkStatus,
    candidateType,
    candidateBookmarkApiStatus,
    status,
    isCandidateMatchingJobsEnabled,
    isCandidateMatchingJobModalVisible,
    fetchCandidateMatchingJobs,
    onClickFindMatchingJob,
    setCandidateMatchingJobModalVisiblity,
    setCandidateJobMatchingInitialAggregation,
    maskingConfig,
    showCandidateDetailTabs,
    availableProviders,
    contact,
    onCandidate360TabChange,
    currentJobDetails,
    onPushCandToAts,
    pushCandToAtsStatus,
    atsConfig,
    onClickAtsPush,
    isCandidateDownloaded,
    showContactInfoButton,
    showPushCandidateButton,
    showPushError,
    isTryNow,
    openInNewTab,
    isInternalIcon,
    internalOriginalSource,
    candidateSourceName,
    candidateOriginalSourceName,
    isAlertMessage,
    isCandidateViewHeaderVisible = true,
    candidateListStatus,
    candidateDetailsStatus,
    keywordsToHighlight,
    activeSourceName,
    activeTab,
    setIsFindMathingJobClicked,
  } = props;

  const highlights = getUniqueHighlightKeywords(keywordsToHighlight, activeTab, activeSourceName, candidate.Keywords);

  React.useEffect(() => {
    if (isCandidateViewHeaderVisible)
      setTimeout(() => {
        highlightKeyWords(['div#candidate-360-title-v2'], highlights);
      }, 100);
  }, [highlights.length, isCandidateViewHeaderVisible, candidateListStatus, candidateDetailsStatus, highlights]);

  const candidateTitle =
    candidate.Title && candidate.Company ? (
      <div className={styles.candidateDesignationInformation}>
        <span className={styles.highlightedText}>{candidate.Title}</span> at {candidate.Company}
      </div>
    ) : (
      <span className={styles.highlightedText}> {candidate.Title || candidate.Company}</span>
    );
  const displayedCandidateTitle =
    candidate.Title || candidate.Company ? <Tooltip title={candidateTitle}>{candidateTitle}</Tooltip> : null;
  const candidateLocation = (
    <Tooltip title={candidate.Location}>
      <div className={styles.candidateLocation}>{candidate.Location}</div>
    </Tooltip>
  );
  const displayedCandidateLocation = candidate.Location ? candidateLocation : null;

  const isBookmarkVisible = candidateType !== 'quick-search';
  const candidateExperience = _.get(candidate, ['YearsOfExperience'], null);
  const displayedCandidateExperience =
    candidateExperience === 0 ? '< 1year of Overall Experience' : `${candidateExperience}+ Years of Overall Experience`;
  const candidateExperienceWithIcon =
    candidateExperience !== null ? (
      <div className={styles.workExperienceWithIcon}>
        <WorkExperienceIcon className={styles.workExperienceIcon} />{' '}
        <Tooltip title={displayedCandidateExperience}>{displayedCandidateExperience}</Tooltip>
      </div>
    ) : null;
  const candidateBookmark = isBookmarkVisible ? (
    <CandidateBookmark
      candidate={candidate}
      updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
      jobId={jobId}
      candidateBookmarkApiStatus={candidateBookmarkApiStatus}
      width={14}
      height={15}
    />
  ) : null;
  const maxCandidateScore = userConfig?.ScoreConfig?.Max || 3;
  const maxScoreWithSlashIcon = (
    <span>
      <span className={styles.slashIcon}>/</span>
      <span className={styles.maxCandidateScore}>{maxCandidateScore}</span>
    </span>
  );
  const showCandidateScore = userConfig.IsCandidateScoreVisible;
  const isCandidateScoreVisible = candidateContext === 'job' && showCandidateScore && candidate.Score;

  const candidateScore = isCandidateScoreVisible ? (
    <div className={styles.candidateScoreSection}>
      <CandidateScoreIcon className={styles.candidateScoreIcon} />
      <div className={styles.candidateScore} data-testid="candidate-drawer-score">
        {getCandidateScoreContent({ whiteLabelInfo, candidateScore: candidate.Score, candidate, status })}
        {maxScoreWithSlashIcon}
      </div>
    </div>
  ) : null;

  const candidateProfessionalInfoList = [candidateExperienceWithIcon, candidateScore, candidateBookmark];
  const displayedProfessionalInfo = getJoinedDisplayedInfo(candidateProfessionalInfoList, '|');
  const isValidInfo = candidateProfessionalInfoList.some(value => value);
  const showProfessionalInfo = isValidInfo ? (
    <div className={styles.displayedProfessionalInfo}>{displayedProfessionalInfo}</div>
  ) : null;

  const fetchContactWidgetWrapper = showCandidateDetailTabs ? (
    <FetchContactWidgetWrapper
      fetchContactStatus={candidate.fetchContactStatus}
      currentJobDetails={currentJobDetails}
      candidate={candidate}
      availableProviders={availableProviders}
      contact={contact}
      onCandidate360TabChange={onCandidate360TabChange}
    />
  ) : null;

  const handleCandidateView = e => {
    openCandidateView(candidate);
    e.stopPropagation();
  };

  const latestProfileDataMarkup = getLatestProfileDateMarkup(candidate, false, candidateSourceName?.toLowerCase());
  const sourceInfoText =
    isInternalIcon || internalOriginalSource ? (
      <SourceInfoWidgetWrapper
        candidate={candidate}
        isInternalIcon={isInternalIcon}
        sourceInfostyle={{ width: 'max-content' }}
        candidateOriginalSourceName={candidateOriginalSourceName}
      />
    ) : (
      latestProfileDataMarkup
    );

  const { atsSource, pushedCandidateSource, originalSource } = getSourceData(candidate, version);
  const { isPushAllowed, isRecreateCandidateAllowed } = checkAccessConfig(candidate, atsConfig);

  const pushComponent =
    version === 'ats' ? (
      <AtsPushComponent
        candidate={candidate}
        atsSource={atsSource}
        onPushCandToAts={onPushCandToAts}
        isLoading={pushCandToAtsStatus === 'INPROGRESS'}
        isPushAllowed={isPushAllowed}
        onClickAtsPush={onClickAtsPush}
        isCandidateDownloaded={isCandidateDownloaded}
        showContactInfoButton={showContactInfoButton}
        showPushCandidateButton={showPushCandidateButton}
        originalSource={originalSource}
        showPushError={showPushError}
      />
    ) : (
      <PushCandidateComponent
        atsSource={pushedCandidateSource}
        onPushCandToAts={onPushCandToAts}
        loading={pushCandToAtsStatus === 'INPROGRESS'}
        isPushAllowed={isPushAllowed}
        showContactInfoButton={showContactInfoButton}
        showPushCandidateButton={showPushCandidateButton}
        isRecreateCandidateAllowed={isRecreateCandidateAllowed}
      />
    );

  return (
    <div className={styles.enhancedCandidateInformationCard}>
      <div className={styles.informationCardHeader}>
        <div className={styles.candidateSourceAndNameDiv}>
          <CandidateSourceAndName
            candidate={candidate}
            userConfig={userConfig}
            version={version}
            whiteLabelInfo={whiteLabelInfo}
            featureToggleList={featureToggleList}
            isCandidateDrawer
          />
        </div>
        {getCandidateStatusFromSources(candidate)}
        <div className={styles.informationHeaderIcons}>
          <SocialIcon candidate={candidate} isActive={maskingConfig?.IsActive} />
          <div className={styles.pushComponent}>{pushComponent}</div>
          {!openInNewTab && !isTryNow ? (
            <div
              className={styles.candidateViewIcon}
              role="presentation"
              onClick={handleCandidateView}
              onKeyPress={handleCandidateView}
            >
              <CandidateViewIconV2 />
            </div>
          ) : null}
        </div>
      </div>
      <div id="candidate-360-title-v2">{displayedCandidateTitle}</div>
      {displayedCandidateLocation}
      {showProfessionalInfo}
      <div className={styles.jobMatchingAndFetchContact}>
        {fetchContactWidgetWrapper}
        {isCandidateMatchingJobsEnabled ? (
          <FindMatchingJobWidgetWrapper
            candidate={candidate}
            isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
            fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
            onClickFindMatchingJob={onClickFindMatchingJob}
            setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
            setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
            isAlertMessage={isAlertMessage}
            setIsFindMathingJobClicked={setIsFindMathingJobClicked}
          />
        ) : null}
      </div>
      <div className={styles.latestMarkupDate}>{sourceInfoText}</div>
    </div>
  );
}

export default EnhancedCandidateInformationCard;
