import {
  setCandidateAdvanceFields,
  setCampaignCreationApiStatus,
  setCampaignStats,
  setCandidateAdvanceFilterPortals,
} from './ActionCreators/CandidateAdvanceActionCreators';
import { setFilterContextId } from './ActionCreators/CandidateActions';
import * as candidateAdvanceFiltersRepo from '../Repository/CandidateAdvanceFilterRepository';
import * as emailTemplatesApi from '../Repository/EmailTemplates';
import { setNotification } from './ActionCreators/ConnectActions';

function fetchCandidateAdvanceFilterFields() {
  return dispatch => {
    candidateAdvanceFiltersRepo.fetchCandidateAdvanceFilterFields().then(filterFields => {
      dispatch(setCandidateAdvanceFields({ fields: filterFields.data }));
    });
  };
}

function fetchCandidateAdvanceFilterPortals() {
  return dispatch => {
    candidateAdvanceFiltersRepo.fetchCandidateAdvanceFilterPortals().then(portals => {
      dispatch(setCandidateAdvanceFilterPortals({ portals: portals.data }));
    });
  };
}

function fetchCandidateAdvanceFilterIndustries(payload) {
  return async () => {
    try {
      const response = await candidateAdvanceFiltersRepo.fetchCandidateAdvanceFilterIndustries(payload);
      return response?.data;
    } catch (error) {
      return {};
    }
  };
}

function fetchCampaignTitleSuggestions(payload) {
  return async () => {
    try {
      const response = await candidateAdvanceFiltersRepo.fetchCampaignTitleSuggestions(payload);
      return response.data;
    } catch (error) {
      return {};
    }
  };
}

function runCampaign(jobId, payload, campaignId = 0) {
  return async dispatch => {
    try {
      const response = await candidateAdvanceFiltersRepo.runCampaign(jobId, campaignId, payload);
      dispatch(setFilterContextId(undefined));
      dispatch(setNotification('SUCCESS', 'Campaign run intiated.'));
      return response;
    } catch (error) {
      return {};
    }
  };
}

function fetchCampaignStats(jobId) {
  return async dispatch => {
    try {
      const response = await candidateAdvanceFiltersRepo.fetchCampaignStats(jobId);
      dispatch(setCampaignStats(response?.data));
      return response;
    } catch (error) {
      return {};
    }
  };
}

function fetchCampaignStatsByCampaignId(jobId, campaignId) {
  return async () => {
    try {
      const response = await candidateAdvanceFiltersRepo.fetchCampaignStatsByCampaignId(jobId, campaignId);
      return response?.data;
    } catch (error) {
      return {};
    }
  };
}

async function fetchEmails(searchTerm) {
  try {
    const response = await emailTemplatesApi.searchEmailTemplates({
      searchTerm,
      From: 0,
      size: 10,
      IncludeSystemTemplate: true,
      IncludeDrip: true,
      Excludes: ['workFlow'],
      LanguageCode: 'en-US',
    });
    return response.data;
  } catch (error) {
    return {};
  }
}

function createCampaign(config, jobId) {
  return async dispatch => {
    try {
      dispatch(setCampaignCreationApiStatus('INPROGRESS'));
      const response = await candidateAdvanceFiltersRepo.createCampaign(config, jobId);
      dispatch(setCampaignCreationApiStatus('COMPLETED'));
      return response.data;
    } catch (error) {
      dispatch(setCampaignCreationApiStatus('FAILED'));
      dispatch(setNotification('ERROR', 'Campaign creation failed, please try again'));
      return {};
    }
  };
}

export {
  fetchCandidateAdvanceFilterFields,
  fetchCandidateAdvanceFilterPortals,
  fetchCampaignTitleSuggestions,
  createCampaign,
  fetchEmails,
  fetchCampaignStats,
  runCampaign,
  fetchCampaignStatsByCampaignId,
  fetchCandidateAdvanceFilterIndustries,
};
