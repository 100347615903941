import React from 'react';
import { LinkedinIcon, XingIcon, SocialIcon } from '../Icons/AryaIcons';
import LinkedInGreyIcon from '../Icons/LinkedInGrey';

export default function getSourceSvg(sourceName, props = {}) {
  let icon;
  switch (sourceName) {
    case 'linkedin':
      icon = <LinkedinIcon {...props} />;
      break;
    case 'grey-linkedin':
      icon = <LinkedInGreyIcon {...props} />;
      break;
    case 'xing':
      icon = <XingIcon {...props} />;
      break;
    default:
      icon = <SocialIcon {...props} />;
  }

  return icon;
}
