import React from 'react';
import MilesInput from '../../../../../Components/Common/MilesInput/MilesInput';
import styles from './ManualSearchMiles.module.scss';

export default function ManualSearchMiles(props) {
  const {
    form,
    locationType,
    isCountryWideSearchEnabled,
    defaultRange,
    candidateContext,
    isMultiLocationSectionVisible,
    isManualSearchFormMinimized,
    shouldHideManualSearchMiles,
  } = props;
  const isRemoteJob = form.getFieldValue('IsRemoteJob');
  const isStateWideSearchChecked = form.getFieldValue('IsStateWideSearch');
  const isMilesDisabled =
    isCountryWideSearchEnabled ||
    (isRemoteJob && locationType === 'Location') ||
    (isStateWideSearchChecked && locationType !== 'MultiLocation');
  return (
    <div className={styles.manualSearchMiles} style={{ display: shouldHideManualSearchMiles ? 'none' : 'block' }}>
      <MilesInput
        form={form}
        initialRange={defaultRange}
        isDistanceDisabled={isMilesDisabled}
        maxAllowedRange={999}
        distanceFieldName="Range"
        candidateContext={candidateContext}
        placeholder="Maximum miles for sourcing"
        style={{ width: isMultiLocationSectionVisible || isManualSearchFormMinimized ? '15rem' : '50rem' }}
        isManualSearchV2
      />
    </div>
  );
}
