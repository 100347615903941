import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as NotificationActions from '../../Actions/NotificationActions';
import MessagePopup from '../../Components/Connect/MessagePopup/MessagePopup';
import { getMessageConversations } from '../../Reducers/MessageConversationsReducer';
import { getJobGuidToIdMapping, getJobsById } from '../../Reducers/JobReducer';
import { getMessages } from '../../Reducers/ChatConversationsReducer';
import * as ChatActions from '../../Actions/ChatActions';
import { getCandidatesConnectInfo, getConversationPersonMapping, getPersonsById } from '../../Reducers/ConnectReducer';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import { getNotifications } from '../../Reducers/AryaNotifyReducer';
import { readPhoneNumberSMS } from '../../Repository/ConnectRepository';

const mapStateToProps = (state, ownProps) => ({
  messageConversations: getMessageConversations(state, ownProps.conversationId),
  chatMessages: getMessages(state, ownProps.conversationId),
  candidatesConnectInfo: getCandidatesConnectInfo(state),
  connectInfo: getCandidatesConnectInfo(state),
  conversationPersonMapping: getConversationPersonMapping(state),
  personsById: getPersonsById(state),
  connectNotifications: getNotifications(state, 'Connect'),
  jobsById: getJobsById(state),
  jobGuidToIdMapping: getJobGuidToIdMapping(state),
});

const mapDispatchToProps = {
  setMessagePopupVisibility: NotificationActions.setMessagePopupVisibility,
  fetchChatMessages: ChatActions.fetchMessages,
  fetchMessages: ConnectActions.fetchMessages,
  fetchConnectStatus: ConnectActions.fetchConnectStatus,
  sendChatMessage: ChatActions.sendMessage,
  addNewMessageToConversation: ConnectActions.addNewMessageToConversation,
  markConversationAsRead: ConnectActions.markConversationAsRead,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
};

class MessagePopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: null,
      phones: null,
      selectedPhone: null,
      consentStatus: null,
    };

    this.onCloseMessagePopup = this.onCloseMessagePopup.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.markConversationRead = this.markConversationRead.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    const updatedState = {};
    const { messageConversations, chatMessages, messageType, personId, connectInfo, personNumber } = props;
    const { selectedPhone } = prevState;

    let messages = messageType === 'SMS' ? messageConversations[selectedPhone] || [] : chatMessages || [];

    const candidateConnectedInfo = _.get(connectInfo, ['ConnectStatuses', personId], null);
    const phones = _.get(candidateConnectedInfo, ['Contact', 'Phones'], []);

    const phoneNumbers = phones.map(p => p.Number);
    let phoneDefaultValue = selectedPhone || personNumber;

    if (messages) {
      // Set message direction
      messages = messages.map(message => {
        const isByCandidate = !phoneNumbers.includes(message.To);
        return {
          ...message,
          isByCandidate,
        };
      });

      // Filter current dropdown messages
      if (!phoneDefaultValue) {
        if (!messages || messages.length === 0) {
          [phoneDefaultValue] = phoneNumbers;
        } else {
          phoneDefaultValue = phoneNumbers.includes(messages[0].To) ? messages[0].To : messages[0].From;
        }
      }
      if (messageType === 'SMS') {
        messages = messages.filter(m => m.To === phoneDefaultValue || m.From === phoneDefaultValue);
      }
      if (messages.length > 0) messages.reverse();
    }
    const consentStatus = MessagePopupContainer.getMessageConsentStatus(phoneDefaultValue, phones);
    updatedState.messages = messages;
    updatedState.consentStatus = consentStatus;
    updatedState.selectedPhone = phoneDefaultValue;
    updatedState.phones = phones;
    return updatedState;
  }

  //! TODO: move to util? Or a component?
  static getMessageConsentStatus(selectedPhone, phones) {
    const phone = phones.find(p => p.Number === selectedPhone);
    return phone ? phone.MessageConsentStatus : undefined;
  }

  componentDidMount() {
    const { conversationId } = this.props;
    const { selectedPhone } = this.state;
    this.fetchData();
    this.markConversationRead();
    readPhoneNumberSMS(conversationId, selectedPhone);
  }

  componentDidUpdate(prevProps) {
    const { conversationId } = this.props;
    if (prevProps.conversationId !== conversationId) {
      this.markConversationRead();
    }
  }

  componentWillUnmount() {
    const { conversationId } = this.props;
    const { selectedPhone } = this.state;
    this.markConversationRead();
    readPhoneNumberSMS(conversationId, selectedPhone);
  }

  onCloseMessagePopup(id) {
    const { setMessagePopupVisibility, personId, messageType } = this.props;
    const { selectedPhone } = this.state;
    setMessagePopupVisibility(id, personId, false, messageType, selectedPhone);
  }

  onChangePhone(phone) {
    this.setState({
      selectedPhone: phone,
    });
    const { conversationId, fetchMessages } = this.props;
    if (conversationId) {
      fetchMessages(conversationId, 'SMS', phone);
    }
  }

  markConversationRead() {
    const { conversationId, markConversationAsRead, markNotificationAsRead, messageType } = this.props;
    markConversationAsRead(conversationId, messageType);
    markNotificationAsRead({
      Source: 'Connect',
      Types: [messageType],
      RefId: conversationId,
    });
  }

  fetchData() {
    const {
      conversationId,
      fetchMessages,
      messageType,
      fetchChatMessages,
      personId,
      personNumber,
      conversationPersonMapping,
      fetchConnectStatus,
    } = this.props;
    if (conversationId) {
      fetchMessages(conversationId, messageType, personNumber);
      fetchChatMessages(conversationId);
      fetchConnectStatus(conversationId, personId || conversationPersonMapping[conversationId]);
    }
  }

  sendMessage(message, selectedPhone) {
    const { conversationId, messageType, addNewMessageToConversation, sendChatMessage } = this.props;

    if (messageType === 'Chat') {
      sendChatMessage({ message, conversationId });
    } else {
      addNewMessageToConversation(conversationId, {
        MessageType: messageType,
        Body: message,
        IsByPerson: false,
        To: [selectedPhone],
      });
    }
  }

  getHeaderSubText = () => {
    const { conversationId, connectNotifications, jobGuidToIdMapping, jobsById, messageType } = this.props;
    if (!conversationId || !['Chat', 'SMS'].includes(messageType)) return '';
    const currentConversationNotification = connectNotifications?.find(
      notification => notification?.Payload?.ConversationId === conversationId
    );
    const jobGuid = currentConversationNotification?.Payload.RefId;
    if (!jobGuid) return '';
    const jobDetails = _.get(jobsById, [jobGuidToIdMapping[jobGuid]], {});
    if (!jobDetails?.JobTitle || !jobDetails?.JobId) return '';
    return `${jobDetails.JobTitle} - ${jobDetails.JobId}`;
  };

  render() {
    const { conversationId, personId, personsById, conversationPersonMapping, messageType, connectInfo } = this.props;
    const { messages, consentStatus, selectedPhone, phones } = this.state;
    return (
      <MessagePopup
        className="chat-message-popup"
        conversationId={conversationId}
        consentStatus={consentStatus}
        selectedPhone={selectedPhone}
        onChangePhone={this.onChangePhone}
        messages={messages}
        phones={phones}
        onClose={this.onCloseMessagePopup}
        personDetails={_.get(personsById, personId || [conversationPersonMapping[conversationId]], {})}
        messageType={messageType}
        sendMessage={this.sendMessage}
        connectInfo={_.get(connectInfo, ['ConnectStatuses', personId || conversationPersonMapping[conversationId]], {})}
        headerSubText={this.getHeaderSubText()}
      />
    );
  }
}

export { MessagePopupContainer as MessagePopupContainerWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessagePopupContainer));
