import React from 'react';
import _ from 'lodash';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import qs from 'query-string';
import styles from './Candidate360ViewWrapper.module.scss';
import CandidateViewTabs from '../CandidateView/CandidateViewTabs';
import { getSourceDisplayName } from '../../Utils/SourceUtils';
import { getCandidatePrimarySourceNameToDisplay } from '../../Utils/CandidateListUtils';
import {
  isInternalIconVisible,
  getCandidateSourceName,
  getCandidateOriginalSourceName,
} from '../../Utils/DownloadedCandidatesSourceUtils';
import CandidateRejectCard from '../CandidateRejectCard/CandidateRejectCard';
import AlertMessage from '../AlertMessage/AlertMessage';
import NewCandidateShortlistReject from '../CandidateCard/NewCandidateShortlistReject/NewCandidateShortlistReject';
import EnhancedCandidate360SecondaryInfoSection from '../EnhancedCandidate360SecondaryInfo/EnhancedCandidate360SecondaryInfoSection';
import EnhancedCandidateInformationCard from './EnhancedCandidateInformationCard';
import { getCandidateStatus } from '../../Utils/CandidateDrawerUtils';

function Candidate360ViewWrapper(props) {
  const {
    candidate = {},
    openCandidateView,
    currentJobDetails,
    connectInfo,
    candidateType,
    onCandidateStatusChange,
    onCandidateReject,
    candidateContext,
    featureToggleList,
    jobId,
    keywordsToHighlight,
    isTryNow,
    downloadResume,
    allowResumeDownload,
    version,
    jobGuid,
    candidateBookmarkApiStatus,
    updateCandidateBookmarkStatus,
    candidateDetailsStatus,
    jobCountryCode,
    toggleBotConfigDrawer,
    tabName,
    onCandidate360TabChange,
    appName,
    isDiversityAttributesVisible,
    activeSubTab,
    onSubTabClick,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    candidateListStatus,
    maskingConfig,
    fetchCandidateMatchingJobs,
    ignoreSimilar,
    userConfig,
    isJobActionsAllowed,
    unlockCandidateResume,
    resumeUnlockStatus,
    selectPlan,
    creditsRefunded,
    history,
    candidateJobsTotalCount,
    fetchCandidateJobsApiStatus,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    pushCandToAtsStatus,
    atsConfig,
    showPushError,
    showContactInfoButton,
    onClickAtsPush,
    showPushCandidateButton,
    openInNewTab,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    isCandidateDownloaded,
    onPushCandToAts,
    notesContainer,
    onNotesChange,
    currentNotes,
    candidateId,
    postCandidateNotes,
    usersById,
    connectUsersById,
    showCandidateDetailTabs,
    openSipCallWindowsApp,
    activeTab,
    activeSourceName,
    candidateStatusUpdate,
    candidateSegmentsTotalCount,
    bulkNotesFetchApiStatus,
    fetchBulkCandidateAllNotes,
    candidateIntel,
    fetchCandidateIntel,
    productVariantsById,
    jobsById,
    composeEmailType,
    setComposeEmailType,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
  } = props;

  const [candidateJobMatchingInitialAggregation, setCandidateJobMatchingInitialAggregation] = React.useState({});
  const [isFindMathingJobClicked, setIsFindMathingJobClicked] = React.useState(false);

  const candidateHeaderRef = React.useRef(null);
  const candidateHeaderHeight = candidateHeaderRef?.current?.clientHeight;
  const connectStatuses = connectInfo?.ConnectStatuses ?? {};
  const connectStatus = connectStatuses?.[candidate?.PersonId] ?? {};
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);

  const {
    showSettingsLink: isSettingsLink,
    Message: alertMessage,
    ErrorDescription,
    showMessageAsWarning,
  } = candidate || {};
  const isAlertMessage = !!alertMessage;
  const showSettingsLink = isSettingsLink && version !== 'ats';
  const description = ErrorDescription;
  const alertType = showMessageAsWarning === true ? 'warning' : 'error';

  const { showContactTab, connectionStatus, redirectTo } = qs.parse(window.location.search);
  const isContactTabRedirection =
    showContactTab?.trim().toLowerCase() === 'true' ||
    connectionStatus?.trim().toLowerCase() === 'connected' ||
    redirectTo?.trim().toLowerCase();
  React.useEffect(() => {
    if (isContactTabRedirection) {
      onCandidate360TabChange('contact');
    }
  }, []);

  React.useEffect(() => {
    if (candidateDetailsStatus === 'COMPLETED') fetchCandidateIntel(jobId, candidateId);
  }, [candidateId, candidateDetailsStatus]);

  const onClick360TabChange = activeTabName => {
    if (activeTabName !== 'jobs') {
      setCandidateMatchingJobModalVisiblity(false);
    }
    onCandidate360TabChange(activeTabName);
  };
  const status = candidate?.Status;
  const isAddVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      (status
        ? status.toLowerCase() === 'sourced' || status.toLowerCase() === 'rejected' || activeTab === 'rejected'
        : true));
  const isDeleteVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      activeTab !== 'rejected' &&
      status &&
      (status.toLowerCase() === 'sourced' || status.toLowerCase() === 'shortlisted'));
  const isCandidateIntelVisible = candidateContext === 'job' && featureToggleList.CandidateIntel?.IsEnabled;
  const {
    CandidateCRM: { IsEnabled: isSegmentAndCampaignsTabVisible },
  } = featureToggleList;
  const onClickFindMatchingJob = value => {
    onCandidate360TabChange(value);
  };
  const isCandidateMatchingJobsEnabled = featureToggleList.CandidateMatchingJobs.IsEnabled;
  const isSRPulseUser = featureToggleList?.WhiteGloveServiceProgress?.IsEnabled;
  const isInternalIcon = isInternalIconVisible(candidate, isSRPulseUser, revealActiveChannelSourceName);
  const internalOriginalSource =
    getCandidateSourceName(candidate, revealActiveChannelSourceName) === 'internal'
      ? getCandidateOriginalSourceName(candidate.Sources)
      : false;

  const candidateHiddenHeaderStyle = isCandidateViewHeaderVisible ? {} : { marginTop: -candidateHeaderHeight };

  const candidatePrimaryInfoStyle = { minWidth: '35%' };
  const candidateSecondaryInfoStyle = { width: '100%' };
  const { ShowVaultName: shouldShowVaultName } = userConfig;
  const showVaultName = shouldShowVaultName ?? false;
  const { candidateSourceName } = getCandidatePrimarySourceNameToDisplay(
    candidate,
    version,
    showVaultName,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    userConfig
  );
  const candidateOriginalSourceName = internalOriginalSource
    ? getSourceDisplayName(internalOriginalSource,userConfig, showVaultName, {}, whiteLabelInfo, revealActiveChannelSourceName)
    : null;
  const showCandidateAppliedTag = getCandidateStatus(candidate, featureToggleList, candidateContext);

  return (
    <div className={styles.candidateView}>
      {isAlertMessage ? (
        <AlertMessage
          message={alertMessage}
          description={description}
          showSettingsLink={showSettingsLink}
          type={alertType}
          history={history}
          isClosable
        />
      ) : null}

      <div
        className={classNames(styles.candidateHeader, {
          [styles.hideCandidateHeader]: !isCandidateViewHeaderVisible,
        })}
        style={candidateHiddenHeaderStyle}
        ref={candidateHeaderRef}
      >
        <Skeleton
          active
          paragraph={{ rows: 3 }}
          loading={candidateDetailsStatus === 'INPROGRESS' || candidateListStatus === 'INPROGRESS'}
        >
          {!candidate.drawerRejectFlag ? (
            <>
              <div className={styles.candidatePrimaryInfo} style={candidatePrimaryInfoStyle}>
                {showCandidateAppliedTag}
                <EnhancedCandidateInformationCard
                  candidate={candidate}
                  userConfig={userConfig}
                  version={version}
                  whiteLabelInfo={whiteLabelInfo}
                  featureToggleList={featureToggleList}
                  openCandidateView={openCandidateView}
                  candidateContext={candidateContext}
                  jobId={jobId}
                  updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
                  candidateType={candidateType}
                  candidateBookmarkApiStatus={candidateBookmarkApiStatus}
                  status={status}
                  isCandidateMatchingJobsEnabled={isCandidateMatchingJobsEnabled}
                  isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
                  fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
                  onClickFindMatchingJob={onClickFindMatchingJob}
                  setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
                  setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
                  maskingConfig={maskingConfig}
                  showCandidateDetailTabs={showCandidateDetailTabs}
                  availableProviders={connectStatus?.Contact?.AvailableProviders}
                  contact={connectStatus?.Contact ?? {}}
                  onCandidate360TabChange={onCandidate360TabChange}
                  currentJobDetails={currentJobDetails}
                  onPushCandToAts={onPushCandToAts}
                  pushCandToAtsStatus={pushCandToAtsStatus}
                  atsConfig={atsConfig}
                  onClickAtsPush={onClickAtsPush}
                  isCandidateDownloaded={isCandidateDownloaded}
                  showContactInfoButton={showContactInfoButton}
                  showPushCandidateButton={showPushCandidateButton}
                  showPushError={showPushError}
                  openInNewTab={openInNewTab}
                  isTryNow={isTryNow}
                  isInternalIcon={isInternalIcon}
                  internalOriginalSource={internalOriginalSource}
                  candidateSourceName={candidateSourceName}
                  candidateOriginalSourceName={candidateOriginalSourceName}
                  isAlertMessage={isAlertMessage}
                  isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                  candidateListStatus={candidateListStatus}
                  candidateDetailsStatus={candidateDetailsStatus}
                  keywordsToHighlight={keywordsToHighlight}
                  activeSourceName={activeSourceName}
                  activeTab={activeTab}
                  setIsFindMathingJobClicked={setIsFindMathingJobClicked}
                />
              </div>
              <div className={styles.candidateSecondaryInfo} style={candidateSecondaryInfoStyle}>
                <EnhancedCandidate360SecondaryInfoSection
                  candidate={candidate}
                  keywordsToHighlight={keywordsToHighlight}
                  activeTab={activeTab}
                  activeSourceName={activeSourceName}
                  isDiversityAttributesVisible={isDiversityAttributesVisible}
                  candidateContext={candidateContext}
                  jobId={jobId}
                  candidateDetailsStatus={candidateDetailsStatus}
                />
              </div>
              <div className={styles.candidateActions}>
                <NewCandidateShortlistReject
                  isPaidJobServiceEnabled={isPaidJobServiceEnabled}
                  activeTab={activeTab}
                  featureToggleList={featureToggleList}
                  onCandidateStatusChange={onCandidateStatusChange}
                  candidateContext={candidateContext}
                  isAddVisible={isAddVisible}
                  isDeleteVisible={isDeleteVisible}
                  candidateStatusUpdate={candidateStatusUpdate}
                  candidate={candidate}
                  size="medium"
                  onCandidateReject={onCandidateReject}
                  jobId={jobId}
                  candidateId={candidateId}
                  className={styles.enhancedShortlistContainer}
                  version={version}
                />
              </div>
            </>
          ) : (
            <CandidateRejectCard
              key={_.get(candidate, 'Id')}
              candidate={candidate}
              onCandidateReject={onCandidateReject}
              onCandidateStatusChange={onCandidateStatusChange}
              ignoreSimilar={ignoreSimilar}
              size="small"
              isPaidJobServiceEnabled={isPaidJobServiceEnabled}
              isCandidateListRejectCard
              jobId={jobId}
              candidateContext={candidateContext}
            />
          )}
        </Skeleton>
      </div>
      <div className={styles.candidateBody}>
        <CandidateViewTabs
          candidate={candidate}
          jobId={jobId}
          keywordsToHighlight={keywordsToHighlight}
          isTryNow={isTryNow}
          downloadResume={downloadResume}
          allowResumeDownload={allowResumeDownload}
          version={version}
          jobGuid={jobGuid}
          currentJobDetails={currentJobDetails}
          candidateDetailsStatus={candidateDetailsStatus}
          jobCountryCode={jobCountryCode}
          toggleBotConfigDrawer={toggleBotConfigDrawer}
          onCandidate360TabChange={onClick360TabChange}
          tabName={tabName}
          candidateType={candidateType}
          isCandidateIntelVisible={isCandidateIntelVisible}
          candidateContext={candidateContext}
          appName={appName}
          activeSubTab={activeSubTab}
          onSubTabClick={onSubTabClick}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
          candidateListStatus={candidateListStatus}
          isSegmentAndCampaignsTabVisible={isSegmentAndCampaignsTabVisible}
          isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
          setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
          isJobActionsAllowed={isJobActionsAllowed}
          unlockCandidateResume={unlockCandidateResume}
          resumeUnlockStatus={resumeUnlockStatus}
          featureToggleList={featureToggleList}
          connectInfo={connectStatus}
          selectPlan={selectPlan}
          creditsRefunded={creditsRefunded}
          candidateJobsTotalCount={candidateJobsTotalCount}
          fetchCandidateJobsApiStatus={fetchCandidateJobsApiStatus}
          notesContainer={notesContainer}
          onNotesChange={onNotesChange}
          currentNotes={currentNotes}
          candidateId={candidateId}
          postCandidateNotes={postCandidateNotes}
          usersById={usersById}
          connectUsersById={connectUsersById}
          showCandidateDetailTabs={showCandidateDetailTabs}
          openSipCallWindowsApp={openSipCallWindowsApp}
          isContactTabRedirection={isContactTabRedirection}
          activeTab={activeTab}
          activeSourceName={activeSourceName}
          candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
          candidateSegmentsTotalCount={candidateSegmentsTotalCount}
          bulkNotesFetchApiStatus={bulkNotesFetchApiStatus}
          fetchBulkCandidateAllNotes={fetchBulkCandidateAllNotes}
          candidateIntel={candidateIntel}
          isAlertMessage={isAlertMessage}
          productVariantsById={productVariantsById}
          jobsById={jobsById}
          composeEmailType={composeEmailType}
          setComposeEmailType={setComposeEmailType}
          openSegmentAtsView={openSegmentAtsView}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          onClickAddJobCallBack={onClickAddJobCallBack}
          setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
          setIsFindMathingJobClicked={setIsFindMathingJobClicked}
          isFindMathingJobClicked={isFindMathingJobClicked}
        />
      </div>
    </div>
  );
}
export default Candidate360ViewWrapper;
