import React from 'react';
import { Icon } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import SourcingNotification from './SourcingNotification/SourcingNotification';
import JobPullNotification from './JobPullNotification/JobPullNotification';
import JobShareNotification from './JobShareNotification/JobShareNotification';
import ContactFetchedNotification from './ContactFetchedNotification/ContactFetchedNotification';
import CreditsExhaustedNotification from './CreditsExhaustedNotification/CreditsExhaustedNotification';
import BasicSystemNotification from './BasicSystemNotification/BasicSystemNotification';
import ReportExportNotificationContainer from '../../Containers/ReportExportNotificationContainer/ReportExportNotificationContainer';
import ErrorSystemNotification from './ErrorSystemNotification/ErrorSystemNotification';
import {
  getCandidatesPulishedNotificationContent,
  getOrderPlacedNotificationContent,
  getFindingCandidatesNotificationContent,
  getPortalCredetialFailNotificationContent,
} from '../../Utils/NotificationUtils';
import { getRelativeTimeSpanFromNow } from '../../Utils/RelativeTimeCalculator';
import ScoutingAgentNotification from './ScoutingAgentNotification/ScoutingAgentNotification';

export function getNotificationComponent(props) {
  const { notification, onClick, setJobCandidateTabActiveSourceName, setJobCandidateActiveTab } = props;
  const { Payload, UpdatedTime, UnreadCount } = notification;
  const isViewed = UnreadCount > 0;
  const publishedTime = getRelativeTimeSpanFromNow(UpdatedTime);

  switch (notification.Type) {
    case 'CreditsExhausted':
      return <CreditsExhaustedNotification {...props} />;
    case 'PortalCredentialFailed': {
      const { Portal } = Payload;
      const title = 'Portal Credential Failed!';
      const content = getPortalCredetialFailNotificationContent(Portal);
      const onNotificationClick = () => onClick(notification);
      const notificationData = {
        title,
        content,
        isViewed,
        publishedTime,
        onClick: onNotificationClick,
      };
      return <ErrorSystemNotification key={notification.Id} {...notificationData} />;
    }
    case 'BulkContactFetched':
      return <ContactFetchedNotification {...props} />;
    case 'Sourcing':
    case 'DilatedSourcing':
      return <SourcingNotification key={notification.Id} {...props} />;
    case 'VaultJobFetched':
    case 'VaultJobNotFound':
      return <JobPullNotification key={notification.Id} {...props} />;
    case 'SegmentShare':
    case 'SegmentUnshare':
      return <JobShareNotification key={notification.Id} {...props} />;
    case 'JobShare':
    case 'JobUnshare':
      return <JobShareNotification key={notification.Id} {...props} />;
    case 'ReportGenerated':
    case 'ReportGenerationFailed':
      return <ReportExportNotificationContainer key={notification.Id} {...props} />;
    case 'FindingCandidates': {
      const { JobId, JobTitle } = Payload;
      const title = JobTitle;
      const subTitle = JobId;
      const content = getFindingCandidatesNotificationContent();
      const onNotificationClick = () => onClick(notification);
      const notificationData = {
        title,
        subTitle,
        content,
        isViewed,
        publishedTime,
        onClick: onNotificationClick,
      };
      return <BasicSystemNotification key={notification.Id} {...notificationData} />;
    }
    case 'CandidatesPublished': {
      const { JobTitle, JobId, CandidatesPublishedCount, MinimumPublishCandidatesThreshold } = Payload;
      const title = JobTitle;
      const subTitle = JobId;
      const content = getCandidatesPulishedNotificationContent(
        CandidatesPublishedCount,
        MinimumPublishCandidatesThreshold
      );
      const onNotificationClick = () => onClick(notification);
      const notificationData = {
        title,
        subTitle,
        content,
        isViewed,
        publishedTime,
        onClick: onNotificationClick,
      };
      return <BasicSystemNotification key={notification.Id} {...notificationData} />;
    }
    case 'OrderPlaced': {
      const { JobTitle, JobId, UserId, UserName, IsUpgraded } = Payload;
      const title = JobTitle;
      const subTitle = JobId;
      const content = getOrderPlacedNotificationContent(UserName, UserId, IsUpgraded);
      const onNotificationClick = () => onClick(notification);
      const notificationData = {
        title,
        subTitle,
        content,
        isViewed,
        publishedTime,
        onClick: onNotificationClick,
      };
      return <BasicSystemNotification key={notification.Id} {...notificationData} />;
    }
    case 'ScoutingAgent.CandidatesFound':
      return (
        <ScoutingAgentNotification
          setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
          setJobCandidateActiveTab={setJobCandidateActiveTab}
          publishedTime={publishedTime}
          {...props}
        />
      );
    case 'ScoutingAgent.CandidatesFilled':
      return (
        <ScoutingAgentNotification
          setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
          setJobCandidateActiveTab={setJobCandidateActiveTab}
          type="filled"
          publishedTime={publishedTime}
          {...props}
        />
      );
    case 'ScoutingAgent.Deleted':
      return (
        <ScoutingAgentNotification
          setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
          setJobCandidateActiveTab={setJobCandidateActiveTab}
          type="deleted"
          publishedTime={publishedTime}
          {...props}
        />
      );
    case 'ScoutingAgent.Stopped':
      return (
        <ScoutingAgentNotification
          setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
          setJobCandidateActiveTab={setJobCandidateActiveTab}
          type="stopped"
          publishedTime={publishedTime}
          {...props}
        />
      );
    case 'ScoutingAgent.ExpiringSoon':
      return (
        <ScoutingAgentNotification
          setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
          setJobCandidateActiveTab={setJobCandidateActiveTab}
          type="expiringSoon"
          publishedTime={publishedTime}
          {...props}
        />
      );
    default:
      return null;
  }
}

export default function AryaNotifications(props) {
  const {
    fetchAryaNotifications,
    aryaNotifications,
    aryaNotificationsTotalCount,
    onClick,
    userByGuId,
    aryaVersion,
    whiteLabelInfo,
    setJobCandidateTabActiveSourceName,
    setJobCandidateActiveTab,
  } = props;
  return (
    <div className="system-notifications">
      <InfiniteScroll
        useWindow={false}
        loadMore={page => fetchAryaNotifications(page * 10, 10)}
        initialLoad={false}
        hasMore={aryaNotifications.length < aryaNotificationsTotalCount}
        loader={
          <div key="loader" style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
            <Icon style={{ fontSize: 20 }} type="loading" spin />
          </div>
        }
        threshold={226}
      >
        {aryaNotifications.map(notification =>
          getNotificationComponent({
            notification,
            onClick,
            userByGuId,
            aryaVersion,
            whiteLabelInfo,
            setJobCandidateTabActiveSourceName,
            setJobCandidateActiveTab,
          })
        )}
      </InfiniteScroll>
    </div>
  );
}
