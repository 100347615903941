import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import { getConfig } from '../../../Reducers/ConfigReducer';
import {
  getClientList,
  getRecruiterList,
  getJobList,
  getJobsMaxCount,
  getOrganizations,
  getReportColumns as _getReportColumns,
} from '../../../Reducers/ReportPortalReducer';
import { fetchFilteredJobsPayload, getDate, getReportType } from '../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import ReportViewFilter from '../../../Components/ReportPortal/ReportStatsTab/ReportView/ReportViewFilter/ReportViewFilter';
import { getDateRange } from '../../../Components/ReportPortal/ReportStatsTab/ReportView/ReportViewFilter/TimeRangeSelect/TimeRangeSelect';

const mapStateToProps = state => {
  return {
    userConfig: getConfig(state),
    clients: getClientList(state),
    recruiters: getRecruiterList(state),
    jobs: getJobList(state),
    fetchClientListApiStatus: getApiStatus(state, 'fetchClientListApiStatus'),
    fetchReportColumnsApiStatus: getApiStatus(state, 'fetchReportColumnsApiStatus'),
    currentUserDetails: getCurrentUserDetails(state),
    jobsMaxCount: getJobsMaxCount(state),
    orgs: getOrganizations(state),
    reportColumns: _getReportColumns(state),
  };
};

const mapDispatchToProps = {
  fetchJobs: ReportPortalActions.fetchJobsForDropdownLists,
  clearJobsForDropdownLists: ReportPortalActions.clearJobsForDropdownLists,
  clearRecruitersForDropdownLists: ReportPortalActions.clearRecruitersForDropdownLists,
  clearClientsForDropdownLists: ReportPortalActions.clearClientsForDropdownLists,
  fetchClients: ReportPortalActions.fetchClientsForDropdownLists,
  fetchRecruiters: ReportPortalActions.fetchRecruitersForDropdownLists,
  fetchOrganizations: ReportPortalActions.getOrganizations,
  getReportColumns: ReportPortalActions.getOrgReportColumns,
};

export function ReportViewFilterContainer(props) {
  const {
    form,
    filterConfig,
    savedReportDetails,
    fetchClients,
    fetchRecruiters,
    fetchJobs,
    clients,
    recruiters,
    jobs,
    fetchClientListApiStatus,
    userConfig,
    currentUserDetails,
    jobsMaxCount,
    clearJobsForDropdownLists,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
    orgs,
    fetchOrganizations,
    type,
    reportColumns,
    fetchReportColumnsApiStatus,
    getReportColumns,
  } = props;

  const recruiterList = recruiters?.[type];
  const clientList = clients?.[type];
  const reportColumnList = reportColumns?.[type];

  const selectedOrgId = form?.getFieldValue('orgId');
  const canUserImpersonate = userConfig.CanImpersonate;

  const getRecruiterListWithCurrentUser = () => {
    let newRecruiterList = recruiterList || [];
    if ((canUserImpersonate && selectedOrgId && currentUserDetails?.OrgId === selectedOrgId) || !canUserImpersonate) {
      newRecruiterList = newRecruiterList?.concat([currentUserDetails]);
    }
    return _.uniqBy(newRecruiterList, recruiter => recruiter?.Id)?.filter(recruiter => recruiter?.Id);
  };
  const recruitersWithCurrentUser = getRecruiterListWithCurrentUser();
  const currentUserId = _.get(currentUserDetails, 'Id');
  const isAdmin = ['admin', 'superadmin'].includes(userConfig?.Role?.toLowerCase()) || canUserImpersonate;

  const fetchClientsWithDelay = debounce(fetchClients, 600);
  const onClientSearch = value =>
    fetchClientsWithDelay({ SearchTerm: value, From: 0, Size: 200, OrgId: selectedOrgId }, type, 'MORE');

  const fetchUsersWithDelay = debounce(fetchRecruiters, 600);
  const onRecruiterSearch = value =>
    fetchUsersWithDelay({ searchTerm: value, from: 0, size: 200, orgId: selectedOrgId }, type, 'MORE');

  const onJobScroll = (filter, fetchType) => {
    const payload = fetchFilteredJobsPayload(filter);
    fetchJobs(payload, fetchType);
  };

  const debouncedFetchJobs = debounce(fetchJobs, 600);
  const onJobSearch = filter => {
    const payload = fetchFilteredJobsPayload(filter);
    debouncedFetchJobs(payload, 'MORE');
  };

  const fetchInitialRecruiterList = () => {
    if (!canUserImpersonate || selectedOrgId) {
      if (!recruiterList?.length) {
        fetchRecruiters(
          {
            from: 0,
            size: 200,
            orgId: selectedOrgId,
          },
          type
        );
      } else {
        fetchRecruiters(
          {
            from: 0,
            size: 200,
            orgId: selectedOrgId,
          },
          type,
          'MORE'
        );
      }
    }
  };

  const fetchInitialClientList = () => {
    if (!canUserImpersonate || selectedOrgId) {
      if (!clientList?.length) {
        fetchClients(
          {
            From: 0,
            Size: 200,
            OrgId: selectedOrgId,
          },
          type
        );
      } else {
        fetchClients(
          {
            From: 0,
            Size: 200,
            OrgId: selectedOrgId,
          },
          type,
          'MORE'
        );
      }
    }
  };

  React.useEffect(() => {
    fetchInitialClientList();
    fetchInitialRecruiterList();
    if (canUserImpersonate) {
      fetchOrganizations({ page: 1, searchTerm: '' });
    }
  }, []);

  React.useEffect(() => {
    if (selectedOrgId) {
      if (!savedReportDetails) {
        clearRecruitersForDropdownLists(type);
        clearClientsForDropdownLists(type);
      }
      fetchInitialRecruiterList();
      fetchInitialClientList();
    }
  }, [selectedOrgId]);

  React.useEffect(() => {
    if (type && !savedReportDetails) {
      const defaultDateRange = getDateRange('month');
      const { fromDate, toDate } = getDate({ dateRange: defaultDateRange });
      const filter = {
        ReportType: getReportType(type),
        OrgId: selectedOrgId,
        FromDate: fromDate,
        ToDate: toDate,
      };
      getReportColumns(filter, type);
    }
  }, [type]);

  return (
    <ReportViewFilter
      form={form}
      filterConfig={filterConfig}
      savedReportDetails={savedReportDetails}
      onClientSearch={onClientSearch}
      onRecruiterSearch={onRecruiterSearch}
      onJobSearch={onJobSearch}
      onJobScroll={onJobScroll}
      clients={clientList}
      recruiters={recruitersWithCurrentUser}
      jobs={jobs}
      fetchClientListApiStatus={fetchClientListApiStatus}
      isAdmin={isAdmin}
      canUserImpersonate={canUserImpersonate}
      currentUserId={currentUserId}
      jobsMaxCount={jobsMaxCount}
      clearJobsForDropdownLists={clearJobsForDropdownLists}
      clearRecruitersForDropdownLists={clearRecruitersForDropdownLists}
      clearClientsForDropdownLists={clearClientsForDropdownLists}
      currentUserDetails={currentUserDetails}
      orgs={orgs}
      orgMaxCount={orgs?.Total}
      fetchOrgs={fetchOrganizations}
      type={type}
      reportColumns={reportColumnList}
      fetchReportColumnsApiStatus={fetchReportColumnsApiStatus}
      getReportColumns={getReportColumns}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewFilterContainer);
