import axios from 'axios';
import { apiV3Client, apiV4Client } from './BaseRepository';

function quickSearchWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return (filter, isAdvancedRejectReasonsEnabled) => {
    if (cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return (isAdvancedRejectReasonsEnabled ? apiV4Client : apiV3Client)
      .post(`/jobs/${filter.jobId}/candidates/_quicksearch`, filter.candidateFilter, {
        cancelToken: new CancelToken(c => {
          cancelRequest = c;
        }),
      })
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

export function fetchCandidateDetails(candidateDetails, Id) {
  return apiV3Client.post(
    `/candidates/${Id}/_fetch?countrycode=${candidateDetails.CountryCode}&jobId=${candidateDetails.JobId}`
  );
}

export function changeCandidateRecommendedStatus(jobId, changeCandidateStatusPayload, isAdvancedRejectReasonsEnabled) {
  return (isAdvancedRejectReasonsEnabled ? apiV4Client : apiV3Client).post(
    `/jobs/${jobId}/candidates/_recommend`,
    changeCandidateStatusPayload
  );
}

export function addPersonIdToQuickSearchCandidate(jobId, candidateId, candidateSource, personId, conversationId) {
  return apiV3Client.post(`jobs/${jobId}/candidates/_connect`, {
    CandidateId: candidateId,
    Source: candidateSource,
    PersonId: personId,
    ConversationId: conversationId,
  });
}

export const quickSearch = quickSearchWithCancellation();
