import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from './CandidatePipeline.module.scss';
import { getSourcingStats, getJobsById } from '../../Reducers/JobReducer';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { Pipeline, PipelineItem } from '../../Components/Pipeline/Pipeline';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getPipelineData } from '../../Utils/CandidatePipelineUtil';
import { getActualSourcingStats } from '../../Utils/JobSourcingStatsUtil';
import { getAllTabTotalCandidatesCount } from '../../Reducers/ManualSearchCandidateReducer';
import { Rejectedicon } from '../../Icons/AryaIcons';

const mapStateToProps = (state, props) => ({
  userConfig: getConfig(state),
  sourcingStats: getSourcingStats(state, props.jobId),
  featureToggleList: getFeatureToggleList(state),
  jobsById: getJobsById(state),
  productVariantsById: getProductVariantsById(state),
  allTabTotalCandidatesCount: getAllTabTotalCandidatesCount(state, { jobId: props.jobId }),
});

function CandidatePipeline({
  intl,
  sourcingStats,
  activeTab,
  onPipelineItemClick,
  showRejected,
  size,
  activeByDefault,
  featureToggleList,
  jobId,
  userConfig,
  jobsById,
  productVariantsById,
  allTabTotalCandidatesCount,
  pipelineContext,
  smartRecruiterVersion,
}) {
  const candidateSearchMaxLimit = 1000;
  const getTotalCandidateDisplayCount = () => {
    return Math.min(allTabTotalCandidatesCount, candidateSearchMaxLimit);
  };

  const aryaSearchDisplayCount = getTotalCandidateDisplayCount();
  const pipelineDataFilter = {
    activeByDefault,
    featureToggleList,
    jobId,
    userConfig,
    sourcingStats,
    intl,
    size,
    onPipelineItemClick,
    jobsById,
    productVariantsById,
    aryaSearchDisplayCount,
    pipelineContext,
    smartRecruiterVersion,
  };

  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const { pipelineData } = getPipelineData(pipelineDataFilter);
  const { rejectedCount, allCandidatesCount } = getActualSourcingStats(sourcingStats, isPaidJobServiceEnabled);
  const rejectButtonContent = (
    <div role="presentation" className={styles.rejectButton} onClick={() => onPipelineItemClick('rejected')}>
      {activeTab === 'rejected' ? (
        <span className={styles.rejectedCount}>{rejectedCount}</span>
      ) : (
        <>
          <span className={styles.rejectButtonCount}>
            <span className={styles.rejectedCount}>{rejectedCount}</span>
          </span>
          <Rejectedicon className={styles.icon} />
        </>
      )}
    </div>
  );

  if (isPaidJobServiceEnabled && allCandidatesCount === 0 && size !== 'small') {
    return null;
  }

  return (
    <>
      <div className={showRejected ? styles.pipelineWrapper : ''}>
        <Pipeline activePipelineItem={activeTab} size={size}>
          {pipelineData.map(data => (
            <PipelineItem {...data} key={data.name} smartRecruiterVersion={smartRecruiterVersion}>
              {data.content}
            </PipelineItem>
          ))}
        </Pipeline>
      </div>
      {showRejected ? rejectButtonContent : null}
    </>
  );
}

export default connect(mapStateToProps)(injectIntl(CandidatePipeline));
