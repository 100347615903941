import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Divider } from 'antd';
import { scaleLinear, scaleTime } from 'd3';
import _ from 'lodash';
import CandidateStats from './CandidateStats/CandidateStats';
import ConnectStats from './ConnectStats/ConnectStats';
import JobStats from './JobStats/JobStats';
import { parseReportStatsSummary, getDate, getDateDiff } from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import { mergeLists, fetchMinMaxInList } from '../../../../Utils/ListUtils';
import SummaryReportInsights from './SummaryReportInsights/SummaryReportInsights';
import { getLocalDateFormat } from '../../../../Utils/RelativeTimeCalculator';
import styles from './SummaryDetails.module.scss';

const defaultAppWidth = 1366;
const defaultAppPadding = 100;
const appWidth = window.innerWidth > defaultAppWidth ? defaultAppWidth : window.innerWidth;
const width = appWidth - defaultAppPadding;
const height = 500;
const margin = { top: 20, bottom: 75, left: 75, right: 175 };

const theme = {
  xAxisLabelFill: '#07101a',
  yAxisLabelFill: '#07101a',
  xAxisLabelFontSize: '14px',
  yAxisLabelFontSize: '14px',
  xAxisTickFontSize: '14px',
  yAxisTickFontSize: '14px',
  xAxisTickLineStroke: 'black',
  yAxisTickLineStroke: 'black',
  xAxisTickDensity: 150,
  yAxisTickDensity: 100,
  xAxisThickness: '0.8px',
  yAxisThickness: '0.8px',
  gridLinesThickness: '0.1px',
  gridLinesDashes: 8,
};

export function calculateYScale(data) {
  const newData = data?.map(ele => (ele.isVisible ? ele : {}));
  const yMinMaxOfAllLines = mergeLists(newData, ['minMaxYScale']);
  const globalMinMax = fetchMinMaxInList(yMinMaxOfAllLines);
  const yScale = scaleLinear()
    .domain(globalMinMax)
    .range([height - margin.bottom, margin.top])
    .nice();

  return { yScale, globalMinMax };
}

export function calculateXScale(dateRange) {
  const { fromDate, toDate } = getDate(dateRange);

  return scaleTime()
    .domain([
      moment(fromDate).toDate(),
      moment(toDate)
        .startOf('day')
        .toDate(),
    ])
    .range([margin.left, width - margin.right]);
}

export function calculateScales(dateRange, data) {
  const xScale = calculateXScale(dateRange);
  const { yScale, globalMinMax } = calculateYScale(data);
  return { xScale, yScale, globalMinMax };
}

export function filterLineData(value, lineGraphsMeta) {
  const { data, activeLines, setActiveLines } = lineGraphsMeta;

  return data?.map(val => {
    const { points, lineName, minMaxYScale } = val;
    let { isVisible } = val;

    if (value === lineName) {
      const currentActiveLines = isVisible ? activeLines - 1 : activeLines + 1;
      setActiveLines(currentActiveLines);
      isVisible = !isVisible;
    }

    return { lineName, isVisible, points, minMaxYScale };
  });
}

export function getStatsSubtitleText(filter, recruiters, currentUserId) {
  const { reportUser } = filter;
  const { fromDate, toDate } = getDate(filter);
  const localDateFormat = getLocalDateFormat();
  const startDate = moment(fromDate).format(localDateFormat);
  const endDate = moment(toDate).format(localDateFormat);
  let userReportText;
  if (reportUser === 'ALL') {
    userReportText = 'All Reports';
  } else {
    const currentUser = _.find(recruiters, recruiter => recruiter?.Id === reportUser);
    const currentUserName = currentUser?.FirstName ?? 'User';
    userReportText = currentUserId === reportUser ? 'My Report' : `${currentUserName}'s Report`;
  }
  return `${userReportText} (${startDate} ~ ${endDate})`;
}

export default function SummaryDetails(props) {
  const { reportSummary, formDetails, recruiters, currentUserId } = props;

  const [jobData, setJobData] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [textsData, setTextsData] = useState([]);
  const [callsData, setCallsData] = useState([]);
  const [botSessionData, setBotSessionData] = useState([]);
  const [jobInsightsData, setJobInsightsData] = useState([]);
  const [sourcingInsightsData, setSourcingInsightsData] = useState([]);
  const [candidateInsightsData, setCandidateInsightsData] = useState([]);
  const [statsSubtitleText, setStatsSubtitleText] = useState('');
  const [statsDateRangeKey, setStatsDateRangeKey] = useState('');
  const [statsDateRangeSelection, setStatsDateRangeSelection] = useState();

  const [activeJobLines, setActiveJobLines] = useState(0);
  const [activeCandidateLines, setActiveCandidateLines] = useState(0);
  const [xTickFormat, setXTickFormat] = useState('');

  useEffect(() => {
    const {
      jobStats,
      candidateStats,
      emailStats,
      textStats,
      callStats,
      botStats,
      jobInsights,
      candidateSourcingInsights,
      candidateInsights,
    } = parseReportStatsSummary(reportSummary);

    const { dateRangeKey } = formDetails;

    setJobData(jobStats);
    setActiveJobLines(jobStats.length);
    setCandidateData(candidateStats);
    setActiveCandidateLines(candidateStats.length);
    setEmailData(emailStats);
    setTextsData(textStats);
    setCallsData(callStats);
    setBotSessionData(botStats);
    setJobInsightsData(jobInsights);
    setSourcingInsightsData(candidateSourcingInsights);
    setCandidateInsightsData(candidateInsights);
    setStatsSubtitleText(getStatsSubtitleText(formDetails, recruiters, currentUserId));
    setStatsDateRangeKey(dateRangeKey);
    setStatsDateRangeSelection(formDetails);
    setXTickFormat(getDateDiff(formDetails));
  }, []);

  function setFilterCriteria(value, key) {
    const lineGraphsMeta = {};

    switch (key) {
      case 'job': {
        lineGraphsMeta.data = jobData;
        lineGraphsMeta.activeLines = activeJobLines;
        lineGraphsMeta.setActiveLines = setActiveJobLines;
        lineGraphsMeta.setData = setJobData;
        break;
      }
      case 'candidate': {
        lineGraphsMeta.data = candidateData;
        lineGraphsMeta.activeLines = activeCandidateLines;
        lineGraphsMeta.setActiveLines = setActiveCandidateLines;
        lineGraphsMeta.setData = setCandidateData;
        break;
      }
      default:
        lineGraphsMeta.data = [];
        lineGraphsMeta.activeLines = 0;
        lineGraphsMeta.setActiveLines = () => {};
        lineGraphsMeta.setData = () => {};
    }

    lineGraphsMeta.setData(filterLineData(value, lineGraphsMeta));
  }

  const { yScale: jobYInitScale, globalMinMax: jobGlobalMinMax } = calculateYScale(jobData);
  const { yScale: candidateYInitScale, globalMinMax: candidateGlobalMinMax } = calculateYScale(candidateData);

  return (
    <div className={styles.summaryDetails}>
      <SummaryReportInsights
        jobInsights={jobInsightsData}
        candidateSourcingInsights={sourcingInsightsData}
        candidateInsights={candidateInsightsData}
        dateRangeKey={statsDateRangeKey}
        subtitle={statsSubtitleText}
      />
      <Divider className={styles.summaryDetailsDivider} />
      <JobStats
        setFilterCriteria={setFilterCriteria}
        data={jobData}
        activeLines={activeJobLines}
        subtitle={statsSubtitleText}
        xScale={calculateXScale(statsDateRangeSelection)}
        yScale={jobYInitScale}
        width={width}
        height={height}
        margin={margin}
        theme={theme}
        yMinMax={jobGlobalMinMax}
        xTickFormat={xTickFormat}
      />
      <Divider className={styles.summaryDetailsDivider} />
      <CandidateStats
        setFilterCriteria={setFilterCriteria}
        data={candidateData}
        activeLines={activeCandidateLines}
        subtitle={statsSubtitleText}
        xScale={calculateXScale(statsDateRangeSelection)}
        yScale={candidateYInitScale}
        width={width}
        height={height}
        margin={margin}
        theme={theme}
        yMinMax={candidateGlobalMinMax}
        xTickFormat={xTickFormat}
      />
      <Divider className={styles.summaryDetailsDivider} />
      <ConnectStats
        emailStats={emailData}
        textStats={textsData}
        callStats={callsData}
        botSessionStats={botSessionData}
        subtitle={statsSubtitleText}
      />
      <Divider className={styles.summaryDetailsDivider} />
    </div>
  );
}
