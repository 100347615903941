import React from 'react';
import { connect } from 'react-redux';
import * as ApiStatusReducer from '../../Reducers/ApiStatusReducer';
import * as candidateActions from '../../Actions/CandidateActions';
import {
  getCandidateNotesByCandidateId,
  getCandidateSuggestedTags,
  getCandidateSuggestedTagsCount,
} from '../../Reducers/CandidateReducer';
import Candidate360Note from '../../Components/CandidateNotes/Candidate360Note';
import { setNotification as _setNotification } from '../../Actions/ActionCreators/ConnectActions';
import { getUsersById } from '../../Reducers/UserReducer';
import { getUserProperty, getUserId } from '../../Utils/UserInfo';

const mapStateToProps = (state, props) => ({
  notesData: getCandidateNotesByCandidateId(state, props.candidate?.Id),
  candidateNoteFetchApiStatus: ApiStatusReducer.getApiStatus(state, 'candidateNoteFetchApiStatus'),
  candidateNoteDeleteApiStatuses: ApiStatusReducer.getApiStatus(state, 'candidateNoteDeleteApiStatus'),
  candidateNoteCreateApiStatus: ApiStatusReducer.getApiStatus(state, 'candidateNoteCreateApiStatus'),
  suggestedTagsApiStatus: ApiStatusReducer.getApiStatus(state, 'suggestedTagsApiStatus'),
  tagCloseApiStatus: ApiStatusReducer.getApiStatus(state, 'tagDeleteApiStatus'),
  candidateSuggestedTags: getCandidateSuggestedTags(state),
  candidateSuggestedTagsCount: getCandidateSuggestedTagsCount(state),
  usersById: getUsersById(state),
});

const mapDispatchToProps = {
  getAllCandidateNotes: candidateActions.getAllCandidateNotes,
  createCandidateNote: candidateActions.createCandidateNote,
  deleteCandidateNote: candidateActions.deleteCandidateNote,
  updateCandidateNote: candidateActions.updateCandidateNote,
  fetchTagsForCandidate: candidateActions.fetchTagsForCandidate,
  addTagForCandidateNote: candidateActions.addTagForCandidateNote,
  deleteTagFromCandidateNote: candidateActions.deleteTagFromCandidateNote,
  setNotification: _setNotification,
};

const getCallNotes = (callNotes = [], usersById) => {
  return callNotes?.map(note => {
    return {
      Description: note.Content,
      CreatedByName: getUserProperty(usersById, note.CreatedBy, 'FullName'),
      Id: note.Id,
      CreatedDate: note.CreatedDate,
      notesContext: 'Call',
      CreatedBy: getUserId(usersById, note.CreatedBy),
    };
  });
};

function CandidateNotesContainer(props) {
  const {
    candidate = {},
    notesData,
    updateCandidateNote,
    getAllCandidateNotes,
    deleteCandidateNote,
    createCandidateNote,
    candidateNoteFetchApiStatus,
    candidateNoteDeleteApiStatuses,
    candidateNoteCreateApiStatus,
    jobId,
    fetchTagsForCandidate,
    candidateSuggestedTags,
    candidateSuggestedTagsCount,
    addTagForCandidateNote,
    tagCloseApiStatus,
    deleteTagFromCandidateNote,
    setNotification,
    suggestedTagsApiStatus,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    usersById,
    candidateDetailsStatus,
    candidateListStatus,
    fetchBulkCandidateAllNotes,
  } = props;

  const callNotes = getCallNotes(candidate.CallNotes, usersById);

  return (
    <Candidate360Note
      candidate={candidate}
      updateCandidateNote={updateCandidateNote}
      deleteCandidateNote={deleteCandidateNote}
      createCandidateNote={createCandidateNote}
      getAllCandidateNotes={getAllCandidateNotes}
      notesData={notesData}
      candidateNoteFetchApiStatus={candidateNoteFetchApiStatus}
      candidateNoteDeleteApiStatuses={candidateNoteDeleteApiStatuses}
      candidateNoteCreateApiStatus={candidateNoteCreateApiStatus}
      jobId={jobId}
      fetchTagsForCandidate={fetchTagsForCandidate}
      candidateSuggestedTags={candidateSuggestedTags}
      candidateSuggestedTagsCount={candidateSuggestedTagsCount}
      addTagForCandidateNote={addTagForCandidateNote}
      tagCloseApiStatus={tagCloseApiStatus}
      deleteTagFromCandidateNote={deleteTagFromCandidateNote}
      setNotification={setNotification}
      suggestedTagsApiStatus={suggestedTagsApiStatus}
      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
      setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
      callNotes={callNotes}
      candidateListStatus={candidateListStatus}
      candidateDetailsStatus={candidateDetailsStatus}
      fetchBulkCandidateAllNotes={fetchBulkCandidateAllNotes}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateNotesContainer);
