import React, { useState } from 'react';
import { Modal, Button, Checkbox, Icon } from 'antd';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import styles from './ContactPuller.module.scss';

export default function ContactPuller(props) {
  const {
    visible,
    onCancel,
    candidateCount,
    eventCallbacks,
    availableCredits,
    isJobBoardSourcePresent,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    userConfig,
  } = props;
  const [checked, setChecked] = useState(false);
  const activeTabDisplayName = userConfig?.ActiveChannelAlias || 'active';
  const lowCreditMessage =
    isJobBoardSourcePresent && availableCredits < 1 ? (
      <span className={styles.lowCreditMessage}>
        Current available credits {availableCredits} may not pull contacts for some candidates. Please contact{' '}
        <a href="mailto:support@leoforce.com">support@leoforce.com</a> to secure more credits.
      </span>
    ) : null;

  const showCreditDeductionMessage = !(
    typeof availableCredits === 'string' && availableCredits?.toLowerCase() === 'unlimited'
  );

  return (
    <div>
      <Modal visible={visible} footer={null} closable={false} centered>
        <div className={styles.modalContainer}>
          <div className={styles.iconDiv}>
            <Icon type="question-circle" theme="filled" />
          </div>
          <div>
            <div className={styles.bulkConfirmation}>
              Are you sure you want to pull contacts for {candidateCount} candidates?
            </div>
            {showCreditDeductionMessage ? (
              <p className={styles.deductionMessage}>
                You will be charged 1 credit for each unique {activeTabDisplayName} profile view or contact pull per job.
              </p>
            ) : null}
            <p>{lowCreditMessage}</p>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
              Exclude candidates that already have contacts
            </Checkbox>
            <BulkCandidatesDownloadFilterInfo
              candidateDownloadFilterConfig={candidateDownloadFilterConfig}
              candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
            />
            <div className={styles.actionButton}>
              <Button
                type="default"
                shape="round"
                style={{ marginRight: '8px' }}
                key="cancel-button"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="primary" shape="round" key="ok-button" onClick={() => eventCallbacks.onSend(checked)}>
                Yes, Pull Contact
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
