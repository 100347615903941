import React from 'react';
import { Row, Col } from 'antd';
import Country from '../../../../../CompoundComponents/LocationWrapper/Country/Country';
import Location from '../../../../../CompoundComponents/LocationWrapper/Location/Location';
import Zipcode from '../../../../../CompoundComponents/LocationWrapper/Zipcode/Zipcode';
import RemoteJob from '../../../../../CompoundComponents/LocationWrapper/RemoteJob/RemoteJob';

function ManualSearchLocationInput(props) {
  const {
    form,
    isPaidJobServiceEnabled,
    isCountryWideSearchEnabled,
    defaultIsStateWideSearchChecked,
    defaultIsRemoteLocation,
    defaultLocationObject = {},
    AppName,
    candidateContext,
    manualSearchMiles,
    isManualSearchFormMinimized,
  } = props;
  const { CountryCode: defaultCountryCode, Location: defaultLocation, Zipcode: defaultZipcode } = defaultLocationObject;
  const isRemoteJobChecked = form.getFieldValue('IsRemoteJob');
  const isDisabled = candidateContext === 'job' || isCountryWideSearchEnabled || isRemoteJobChecked;

  const zipCodeCol = isManualSearchFormMinimized ? 6 : 4;
  const locationColumnValue = isManualSearchFormMinimized ? 8 : 7;
  const countryColumnValue = isManualSearchFormMinimized ? 6 : 4;

  return (
    <>
      {!isPaidJobServiceEnabled ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <RemoteJob initialValue={defaultIsRemoteLocation} />
          <span>Remote Job</span>
          <span style={{ opacity: '0.49' }}>({AppName} will search throughout the country)</span>
        </div>
      ) : null}
      {form.getFieldDecorator('IsStateWideSearch', { initialValue: defaultIsStateWideSearchChecked })}
      <Row gutter="16">
        <Col span={countryColumnValue}>
          <Country size="large" initialValue={defaultCountryCode} isDisabled={isDisabled} />
        </Col>
        <Col span={locationColumnValue}>
          <Location
            size="large"
            initialValue={defaultLocation}
            required={isPaidJobServiceEnabled}
            disabled={isCountryWideSearchEnabled || isRemoteJobChecked}
            placeholder="City, State or State"
          />
        </Col>
        <Col span={zipCodeCol}>
          <Zipcode
            size="large"
            initialValue={defaultZipcode}
            required={isPaidJobServiceEnabled}
            disabled={isCountryWideSearchEnabled || isRemoteJobChecked}
            isManualSearchFormMinimized={isManualSearchFormMinimized}
          />
        </Col>
        {!isManualSearchFormMinimized ? <Col span={7}>{manualSearchMiles}</Col> : null}
      </Row>
    </>
  );
}

export default ManualSearchLocationInput;
