import React, { useCallback } from 'react';
import _ from 'lodash';
import { Affix, Form } from 'antd';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import isEuropeanCountry from '../../Utils/GDPRcountry';
import CandidateBulkActionsContainer from '../CandidateBulkActionsContainer/CandidateBulkActionsContainer';
import ManualSearchCandidateList from '../ManualSearchCandidateList/ManualSearchCandidateList';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as candidateActionCreators from '../../Actions/ActionCreators/CandidateActions';
import * as jobActions from '../../Actions/JobActions';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import {
  getBulkShortlistedNotification,
  getCandidateCount,
  getCandidates,
  getCandidatesAggregations,
  getSourcingNotification,
  getMergedAllTabDuplicateCandidates,
} from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getCurrentPage,
  getFilterQueries,
  getPageSize,
  getSelectedBulkActivity,
  getSelectedCandidates as _getSelectedCandidates,
  getSelectedCandidateIds,
  getCandidateCollapsedFilters,
} from '../../Reducers/JobCandidatesTabReducer';
import { getCandidateSource, getCandidateSourceName, getSourceName } from '../../Utils/SourceUtils';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { geLastPerformedManualSearchTime, getManualSearchPayload } from '../../Reducers/ManualSearchReducer';
import ReactivateArya from '../CandidateFilters/ReactivateArya/ReactivateArya';
import JobReactivationTree from '../CandidateFilters/JobReactivationTree/JobReactivationTree';
import styles from './ManualSearchCandidatesTab.module.scss';
import AggregationFilter from '../QuickSearch/QuickSearchAggregationFilter/QuickSearchAggregationFilter';
import {
  getFilterQueriesPayload,
  clearFilterQueries,
  clearAggregation,
} from '../../Components/QuickSearchForm/QuickSearchFormUtils';
import SelectedCandidatesText from '../CandidateFilters/SelectedCandidatesText';
import { getAllNonGroupHeadDuplicateCandidateIds } from '../../Reducers/ManualSearchCandidateReducer';
import { getIsDeDuplicationAllowed } from '../../Utils/DeDuplicationUtils';
import { ScoutingAgentIcon } from '../../Icons/AryaIcons';

const mapStateToProps = (state, props) => {
  const sourceName = getSourceName(props.activeSource);
  return {
    candidatesById: getCandidates(state),
    candidateListStatus: getApiStatus(state, 'candidateListStatus'),
    featureToggleList: getFeatureToggleList(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    totalCandidate: getCandidateCount(state),
    currentSelectedAllCandidates: _getSelectedCandidates(state, 'NonRecommended'),
    selectedCandidateIds: getSelectedCandidateIds(state, 'NonRecommended'),
    bulkActivitySelection: getSelectedBulkActivity(state, 'NonRecommended'),
    manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
    config: getConfig(state),
    candidateAggregations: getCandidatesAggregations(state),
    manualSearchLastPerformedTime: geLastPerformedManualSearchTime(state, { jobId: props.jobId }),
    filterQueries: getFilterQueries(state, sourceName),
    sourcingNotification: getSourcingNotification(state),
    bulkShortlistedNotification: getBulkShortlistedNotification(state),
    whiteLabelInfo: getWhiteLabelInformation(state),
    nonGroupHeadDuplicateCandidateIds: getAllNonGroupHeadDuplicateCandidateIds(state, props.jobId, sourceName),
    mergedAllTabDuplicateCandidates: getMergedAllTabDuplicateCandidates(state, props.jobId),
    candidateCollapsedFilters: getCandidateCollapsedFilters(state, props.jobId),
  };
};

const mapDispatchToProps = {
  setSelectedCandidates: JobCandidatesTabActions.setSelectedCandidates,
  setSelectedBulkActivity: JobCandidatesTabActions.setSelectedBulkActivity,
  setJobCandidateTabActiveSourceName: JobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setFilterQueries: JobCandidatesTabActions.setFilterQueries,
  setCandidateListType: candidateActionCreators.setCandidateListType,
  fetchJobSourcingStats: jobActions.fetchJobSourcingStats,
  seCandidateCollapsedFilters: JobCandidatesTabActions.seCandidateCollapsedFilters,
  setCandidateCollapsedFilterStatus: JobCandidatesTabActions.setCandidateCollapsedFilterStatus,
};

function ManualSearchCandidatesTab(props) {
  const {
    candidatesById,
    activeSource,
    jobId,
    featureToggleList,
    candidateListStatus,
    setSelectedCandidates,
    setSelectedBulkActivity,
    currentSelectedAllCandidates,
    bulkActivitySelection,
    currentPage,
    pageSize,
    config,
    currentJobDetails,
    totalCandidate,
    manualSearchPayload,
    atsJobCandidateListProps,
    bulkOptionsOffestTop,
    candidateAggregations,
    form,
    fetchManualSearchCandidates,
    manualSearchLastPerformedTime,
    setFilterQueries,
    filterQueries,
    setCandidateListType,
    sourcingNotification,
    fetchJobSourcingStats,
    bulkShortlistedNotification,
    whiteLabelInfo,
    candidateContext = 'job',
    allTabCandidateFetchApiStatus,
    version,
    onPageChange,
    isSegmentCandidateSearchLimitFeatureEnabled,
    selectedCandidateIds,
    isNonInternalPortalForSegmentEnabled,
    openSipCallWindowsApp,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    nonGroupHeadDuplicateCandidateIds,
    mergedAllTabDuplicateCandidates,
    smartRecruiterVersion,
    onStatusChange,
    activeTab,
    candidateCollapsedFilters,
    seCandidateCollapsedFilters,
    setCandidateCollapsedFilterStatus,
  } = props;

  const sourceName = getSourceName(activeSource);
  const segmentCandidateSearchMaxLimit = 1000;
  const maxBulkCandidatesSelectionCount = candidateContext === 'segment' ? segmentCandidateSearchMaxLimit : 500;
  const [isDragging, setIsDragging] = React.useState(false);
  const [isFilterApplied, setisFilterApplied] = React.useState(false);

  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  React.useEffect(() => {
    setCandidateListType('ManualSearchCandidateList');
    return () => {
      setCandidateListType(null);
    };
  }, []);

  React.useEffect(() => {
    if (jobId?.toString() === sourcingNotification?.jobId?.toString()) {
      fetchJobSourcingStats([jobId]);
    }
  }, [sourcingNotification]);

  React.useEffect(() => {
    if (jobId?.toString() === bulkShortlistedNotification?.jobId?.toString()) {
      fetchJobSourcingStats([jobId]);
    }
  }, [bulkShortlistedNotification]);
  const _fetchManualSearchCandidates = (_filterQueries = {}) => {
    const currentPortalQueries = manualSearchPayload.PortalQueries?.[sourceName];
    const currentFilterQueries = _filterQueries;
    const updatedPortalQueries = { ...currentPortalQueries, ...currentFilterQueries };
    const newManualSearchPayload = {
      ...manualSearchPayload,
      Sources: [activeSource],
      PortalQueries: { [sourceName]: updatedPortalQueries },
    };
    const fetchRemoteDataOnly = true;
    fetchManualSearchCandidates({
      manualSearchPayload: newManualSearchPayload,
      fetchRemoteDataOnly,
    });
  };

  const collapsedFilterField = 'collapsedFilter';
  const selectedKeys = form.getFieldValue(collapsedFilterField) || [];
  const applyAggregationFilter = async () => {
    const _filterQueries = getFilterQueriesPayload(form.getFieldsValue(), activeSource);
    _fetchManualSearchCandidates(_filterQueries);
    setFilterQueries({ filterQueries: _filterQueries, sourceName });
    if (isAdvancedSearchV2Enabled) {
      setCandidateCollapsedFilterStatus('INPROGRESS');
      await seCandidateCollapsedFilters({ jobId, filters: selectedKeys });
      setCandidateCollapsedFilterStatus('COMPLETED');
      if (selectedKeys.length) setisFilterApplied(true);
      else setisFilterApplied(false);
    }
  };

  const resetAggregationFilter = useCallback(() => {
    clearFilterQueries(form, sourceName);
    setFilterQueries({ filterQueries: {}, sourceName });
  }, [sourceName]);

  const clearAggregationFilter = useCallback(async () => {
    resetAggregationFilter();
    _fetchManualSearchCandidates();
    if (isAdvancedSearchV2Enabled) {
      form.setFieldsValue({ collapsedFilter: [] });
      setCandidateCollapsedFilterStatus('INPROGRESS');
      await seCandidateCollapsedFilters({ jobId, filters: [] });
      setCandidateCollapsedFilterStatus('COMPLETED');
      setisFilterApplied(false);
    }
  });

  const removeAggregation = (_sourceName, aggregationKey) => {
    const updatedFilterQueries = _.omit(filterQueries, [aggregationKey]);
    clearAggregation(form, sourceName, aggregationKey);
    _fetchManualSearchCandidates(updatedFilterQueries);
    setFilterQueries({ filterQueries: updatedFilterQueries, sourceName });
  };

  React.useEffect(() => {
    if (!_.isEmpty(filterQueries)) resetAggregationFilter();
  }, [manualSearchLastPerformedTime]);

  const {
    AdvanceSearchBulkRecommend: { IsEnabled: isBulkRecommendEnabled },
    AdvanceSearchBulkConnect: { IsEnabled: isBulkConnectEnabled },
    CandidateBulkSelection: { IsEnabled: isBulkSelectionAllowed },
    BulkSms: { IsEnabled: isBulkMessageEnabled },
  } = featureToggleList;
  const isCandidateListLoading = candidateListStatus === 'INPROGRESS';

  const resetMultiSelect = useCallback(() => {
    setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: [] });
    setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: {} });
  }, []);

  const getCurrentSourceConfig = useCallback(
    candidate => {
      const candidateSource = getCandidateSource(candidate);
      const candidateSourceName = getCandidateSourceName(candidateSource, true);
      return config?.SourceConfigsByName?.[candidateSourceName?.toLowerCase()];
    },
    [config]
  );

  const getCurrentPageCandidates = () => {
    if (Object.keys(candidatesById).length === 0 || !activeSource) {
      return [];
    }
    const currentPageCandidateIds = candidatesById ? Object.keys(candidatesById) : [];
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    return currentPageCandidateIds.map((Id, index) => {
      const candidateIndex = (currentPage - 1) * pageSize + index;
      const candidateInfo = getIsDeDuplicationAllowed(sourceName) ? mergedAllTabDuplicateCandidates?.[Id] : {};
      const candidate = candidatesById?.[Id]?.Id ? candidatesById?.[Id] : candidateInfo;
      const sourceConfiguration = !_.isEmpty(candidate) ? getCurrentSourceConfig(candidate) : null;
      return {
        CandidateId: Id,
        RecommendedInfo: {
          SourceConfigId: sourceConfiguration?.Id ?? null,
          SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
        },
        index: candidateIndex,
        source: currentSource,
      };
    });
  };

  const setCurrentSelectedAllCandidates = (candidates, isChecked) => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    const currentSourceBulkActivitySelection = newBulkActivitySelection[currentSource] ?? {};
    const { bulkActivitySearchCriteria } = currentSourceBulkActivitySelection;
    const currentPageCandidates = getCurrentPageCandidates();
    if (!bulkActivitySearchCriteria) {
      const isDeDuplicationAllowed = getIsDeDuplicationAllowed(sourceName);
      const filteredCandidates = isDeDuplicationAllowed
        ? _.uniqBy(candidates, 'CandidateId').filter(
            obj => !nonGroupHeadDuplicateCandidateIds.includes(obj.CandidateId)
          )
        : candidates;
      const selectedCandidates = filteredCandidates;
      setSelectedCandidates({
        candidateType: 'NonRecommended',
        selectedCandidates,
      });
      return;
    }
    let newcurrentSelectedAllCandidates = _.cloneDeep(currentSelectedAllCandidates) ?? [];
    let excludedCandidates = currentSourceBulkActivitySelection?.excludedCandidates ?? [];
    const { Size: currentSearchCriteriaSize = 0 } = bulkActivitySearchCriteria;
    const inRangeCandidates = currentPageCandidates.filter(x => x.index < currentSearchCriteriaSize);
    const inRangeCandidateIds = inRangeCandidates.map(x => x.CandidateId);
    const outOfRangeCandidates = currentPageCandidates.filter(x => x.index >= currentSearchCriteriaSize);
    if (isChecked && inRangeCandidates?.length > 0) {
      excludedCandidates = excludedCandidates?.filter(x => !inRangeCandidateIds.includes(x.CandidateId));
    } else if (inRangeCandidates?.length > 0) {
      excludedCandidates = excludedCandidates?.concat(inRangeCandidates);
    }
    if (outOfRangeCandidates?.length > 0) {
      newcurrentSelectedAllCandidates = currentSelectedAllCandidates.concat(outOfRangeCandidates);
    }
    if (!_.isEmpty(bulkActivitySearchCriteria) && excludedCandidates?.length < bulkActivitySearchCriteria.Size) {
      currentSourceBulkActivitySelection.excludedCandidates = excludedCandidates;
      newBulkActivitySelection[currentSource] = currentSourceBulkActivitySelection;
    } else if (newBulkActivitySelection[currentSource]) {
      delete newBulkActivitySelection[currentSource];
    }
    setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: newcurrentSelectedAllCandidates });
    setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: newBulkActivitySelection });
  };

  const currentPageCandidates = getCurrentPageCandidates();

  const getSelectedCandidatesCount = () => {
    let bulkSelectionCandidatesCount = 0;
    const bulkActivities = Object.values(bulkActivitySelection);
    bulkActivities.forEach(element => {
      bulkSelectionCandidatesCount =
        bulkSelectionCandidatesCount +
        (element.bulkActivitySearchCriteria?.Size ?? 0) -
        (element.excludedCandidates?.length ?? 0);
    });
    return (currentSelectedAllCandidates ?? []).length + bulkSelectionCandidatesCount;
  };

  const getSelectedActiveCandidatesCount = () => {
    const selectedActiveCandidates =
      currentSelectedAllCandidates?.filter(x => x.source?.toLowerCase() === 'active') ?? [];
    const selectedActiveCandidatesCount = selectedActiveCandidates.length;
    const activeBulkSelection = bulkActivitySelection?.Active;
    if (_.isEmpty(activeBulkSelection)) {
      return selectedActiveCandidatesCount;
    }
    const { bulkActivitySearchCriteria, excludedCandidates } = activeBulkSelection;
    const bulkSelectedActiveCandidates = bulkActivitySearchCriteria?.Size - excludedCandidates?.length;
    return selectedActiveCandidatesCount + bulkSelectedActiveCandidates;
  };

  const onSelectBulkCandidates = event => {
    const isChecked = event.target.checked;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const bulkActivitySourceConfiguration = getCurrentSourceConfig({ Source: activeSource });
    const size = Math.min(maxBulkCandidatesSelectionCount, totalCandidate);
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    if (isChecked) {
      const newCurrentSelectedAllCandidates = currentSelectedAllCandidates.filter(
        x => x.source !== currentSource || x.index > size
      );
      newBulkActivitySelection[currentSource] = {
        bulkActivitySearchCriteria: { ...manualSearchPayload, Sources: [activeSource], From: 0, Size: size },
        bulkActivitySourceConfiguration,
      };
      setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: newCurrentSelectedAllCandidates });
    } else {
      delete newBulkActivitySelection[currentSource];
    }
    setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: newBulkActivitySelection });
  };

  const getIsBulkSelectionChecked = () => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    if (!currentBulkSelection) {
      return false;
    }
    const { bulkActivitySearchCriteria, excludedCandidates } = currentBulkSelection;
    return !_.isEmpty(bulkActivitySearchCriteria) && !excludedCandidates?.length;
  };

  const getIsSelectThisPageChecked = () => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    const { bulkActivitySearchCriteria, excludedCandidates } = currentBulkSelection ?? {};
    if (_.isEmpty(bulkActivitySearchCriteria)) {
      return false;
    }
    const currentPageCandidatesCount = Object.keys(candidatesById).length;
    if (!currentPageCandidatesCount) {
      return false;
    }
    const firstCandidateIndex = (currentPage - 1) * pageSize;
    const lastCandidateIndex = (currentPage - 1) * pageSize + currentPageCandidatesCount - 1;
    if (lastCandidateIndex >= bulkActivitySearchCriteria.Size) {
      return false;
    }
    const isExcludedCandidatesExistsInCurrentPage = excludedCandidates?.some(
      x => x.index >= firstCandidateIndex && x.index <= lastCandidateIndex
    );
    return !isExcludedCandidatesExistsInCurrentPage;
  };

  const selectedCandidatesCount = getSelectedCandidatesCount();
  const selectedActiveCandidatesCount = getSelectedActiveCandidatesCount();
  const isBulkOptionsEnabled = isBulkRecommendEnabled || isBulkConnectEnabled;

  const getBulkOptions = () => {
    const isBulkAddAvailable = candidateContext === 'segment';
    const isBulkDeleteAvailable = candidateContext === 'segment';
    const bulkOptions = (
      <CandidateBulkActionsContainer
        jobId={jobId}
        visiblityFlag
        candidateListType="manualsearch"
        isBulkRecommendEnabled={isBulkRecommendEnabled}
        isBulkEmailEnabled={isBulkConnectEnabled}
        isBulkMessageEnabled={isBulkConnectEnabled && isBulkMessageEnabled}
        selectedCandidates={selectedCandidateIds}
        currentPageCandidates={currentPageCandidates}
        _currentSelectedAllCandidates={currentSelectedAllCandidates}
        selectedActiveCandidateCount={selectedActiveCandidatesCount}
        resetMultiSelect={resetMultiSelect}
        setCurrentSelectedAllCandidates={setCurrentSelectedAllCandidates}
        isConsentRequired={isEuropeanCountry(currentJobDetails?.CountryCode)}
        onSelectBulkCandidates={onSelectBulkCandidates}
        bulkActivitySelection={bulkActivitySelection}
        isBulkSelectionAllowed={isBulkSelectionAllowed}
        selectedCandidatesCount={selectedCandidatesCount}
        isBulkSelectionChecked={getIsBulkSelectionChecked()}
        isCurrentPageChecked={getIsSelectThisPageChecked()}
        isSelectThisPageDisabled={isCandidateListLoading}
        isBulkSelectionDisabled={isCandidateListLoading}
        candidateContext={candidateContext}
        isBulkAddAvailable={isBulkAddAvailable}
        isBulkDeleteAvailable={isBulkDeleteAvailable}
        version={version}
        jobDetails={currentJobDetails}
        maxBulkCandidatesSelectionCount={maxBulkCandidatesSelectionCount}
        activeSourceName={sourceName}
      />
    );
    const offsetTop = currentSelectedAllCandidates?.length ? bulkOptionsOffestTop : 0;
    return <Affix offsetTop={offsetTop}>{bulkOptions}</Affix>;
  };
  const showSelectedCandidatesText = !isCandidateListLoading && totalCandidate && !_.isEmpty(filterQueries);

  const eventControl = event => {
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  const getAggregationFilter = () => {
    return (
      <AggregationFilter
        candidateAggregations={candidateAggregations}
        applyAggregationFilter={applyAggregationFilter}
        removeAggregation={removeAggregation}
        clearAggregationFilter={clearAggregationFilter}
        form={form}
        activeSource={activeSource}
        filterQueries={{ [sourceName]: filterQueries }}
        showSelectedAggregations={false}
        jobId={jobId}
        candidateCollapsedFilters={candidateCollapsedFilters}
        isAdvancedSearchV2Enabled={isAdvancedSearchV2Enabled}
        isFilterApplied={isFilterApplied}
      />
    );
  };

  return (
    <div>
      <div className={styles.candidateFilters}>
        <div>{showSelectedCandidatesText ? <SelectedCandidatesText candidatesCount={totalCandidate} /> : null}</div>
        {candidateContext === 'job' ? (
          <div className={styles.rightSection}>
            <Draggable
              defaultClassName={isAdvancedSearchV2Enabled ? styles.dragged : ''}
              axis="both"
              positionOffset={isAdvancedSearchV2Enabled ? { x: '-50%', y: '-75%' } : {}}
              onDrag={eventControl}
              onStop={eventControl}
              disabled={!isAdvancedSearchV2Enabled}
              scale={1}
            >
              <div>
                <ReactivateArya
                  jobId={jobId}
                  currentJobDetails={currentJobDetails}
                  AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
                  smartRecruiterVersion={smartRecruiterVersion}
                  isDragging={isDragging}
                  onStatusChange={onStatusChange}
                  activeTab={activeTab}
                />
              </div>
            </Draggable>
            <JobReactivationTree
              jobId={jobId}
              currentJobDetails={currentJobDetails}
              AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
            />
            {getAggregationFilter()}
          </div>
        ) : (
          <div className={styles.rightSection}>{getAggregationFilter()}</div>
        )}
      </div>
      {isBulkOptionsEnabled ? getBulkOptions() : null}
      <ManualSearchCandidateList
        {...atsJobCandidateListProps}
        activeSource={activeSource}
        jobId={jobId}
        isBulkOptionsEnabled={isBulkOptionsEnabled}
        getCurrentSourceConfig={getCurrentSourceConfig}
        filterQueries={{ [sourceName]: filterQueries }}
        clearAggregationFilter={clearAggregationFilter}
        candidateContext={candidateContext}
        allTabCandidateFetchApiStatus={allTabCandidateFetchApiStatus}
        onPageChange={onPageChange}
        isSegmentCandidateSearchLimitFeatureEnabled={isSegmentCandidateSearchLimitFeatureEnabled}
        activeSourceName={sourceName}
        openSipCallWindowsApp={openSipCallWindowsApp}
        isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
      />
    </div>
  );
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ManualSearchCandidatesTab)));
export { ManualSearchCandidatesTab as ManualSearchCandidatesTabWithoutStore };
