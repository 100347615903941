import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { Button, Form } from 'antd';
import styles from './TitlesSection.module.scss';
import CustomCheckableTags from '../../../../Components/Common/CustomCheckableTags/CustomCheckableTags';
import AutocompleteSearch from '../../../../Components/AutocompleteSearch/AutocompleteSearch';
import * as autocompleteActions from '../../../../Actions/AutocompleteActions';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import eventTypes from '../../../../Analytics/EventTypes';
import { getEventNameByFeatureType } from '../../../../Analytics/Job/JobEventUtils';

const { Item } = Form;

const mapDispatchToProps = {
  fetchJobTitleAutocomplete: autocompleteActions.fetchJobTitleAutocomplete,
};

const DisplayedTitles = props => {
  const { value, onChange } = props;

  const handleOnChange = updatedValues => {
    onChange(updatedValues);
  };

  return (
    <CustomCheckableTags
      tags={value}
      onChange={handleOnChange}
      tagsClassName={isSelected => `${styles.titleTag} ${isSelected ? styles.selected : styles.unselected}`}
      className={styles.titleTagsContainer}
      getSkEventName={isSelected =>
        getEventNameByFeatureType('manualSearch', isSelected ? 'deselectSuggestedTitles' : 'selectSuggestedTitles')
      }
    />
  );
};

function TitlesSection(props) {
  const { form, defaultTitles, isManualSearchFormMinimized, fetchJobTitleAutocomplete } = props;
  const [titleInput, setTitleInput] = React.useState(undefined);
  const [options, setOptions] = React.useState(undefined);
  const [jobTitleAutocompleteApiStatus, setJobTitleAutocompleteApiStatus] = React.useState();

  const titlesRef = React.useRef();

  const handleSearch = React.useCallback(async value => {
    try {
      setJobTitleAutocompleteApiStatus('INPROGRESS');
      const response = await fetchJobTitleAutocomplete({ from: 0, size: 10, title: value });
      setOptions(response?.titles?.map(title => ({ value: title, text: title })));
      setJobTitleAutocompleteApiStatus('COMPLETED');
    } catch {
      //! handle failure
    }
  }, []);

  const scrollToLatestTitle = () => {
    titlesRef.current.scrollTop = titlesRef.current.scrollHeight;
  };

  const handleSelect = title => {
    const currentTitles = form.getFieldValue('Titles');
    const lowerCasedTitle = title.toLowerCase();
    const updatedTitles = currentTitles
      .filter(x => x.name.toLowerCase() !== lowerCasedTitle)
      .concat({ id: uuid.v4(), isSelected: true, name: title });
    form.setFieldsValue({ Titles: updatedTitles }, scrollToLatestTitle);
    setTitleInput(undefined);
  };

  const getContentNotFound = () => {
    if (titleInput) return jobTitleAutocompleteApiStatus === 'INPROGRESS' ? 'Loading...' : '';
    return 'Type to search';
  };

  const onClear = () => {
    const currentTitles = form.getFieldValue('Titles');
    const updatedTitles = currentTitles.map(title => ({ ...title, isSelected: false }));
    form.setFieldsValue({ Titles: updatedTitles });
  };

  const notFoundContent = getContentNotFound();

  const clearAllButton = (
    <div className={styles.clearAll}>
      <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearTitlesFilter} />
    </div>
  );

  const minimizedViewClearButton = (
    <div className={styles.manualSearchPopoverFooter}>
      <Button className={styles.clearButton} onClick={onClear} type="link">
        Clear
      </Button>
    </div>
  );

  const displayJobTitle = isManualSearchFormMinimized ? null : <div className={styles.titleLabel}>Job Title</div>;

  return (
    <>
      <div className={`${isManualSearchFormMinimized ? styles.popoverTitleSectionContainer : ''}`}>
        <div className={`${isManualSearchFormMinimized ? styles.minimizedTitleSeclection : styles.titleSelection}`}>
          <div className={styles.titleClearAll}>
            {displayJobTitle}
            {!isManualSearchFormMinimized && clearAllButton}
          </div>
          <AutocompleteSearch
            style={{ width: '300px', marginTop: '5px' }}
            placeholder="Add a job title"
            onSearch={handleSearch}
            value={titleInput}
            onChange={setTitleInput}
            options={options}
            onSearchInput={handleSelect}
            notFoundContent={notFoundContent}
            onSelect={handleSelect}
            maunalSearchAutoCompleteInputClassName={styles.maunalSearchAutoCompleteInput}
          />
          <div
            style={{
              marginTop: '10px',
              maxHeight: `${isManualSearchFormMinimized ? '250px' : '122px'}`,
              overflow: 'auto',
            }}
            ref={titlesRef}
          >
            <Item colon={false}>
              {form.getFieldDecorator('Titles', { initialValue: defaultTitles })(<DisplayedTitles />)}
            </Item>
          </div>
        </div>{' '}
      </div>{' '}
      {isManualSearchFormMinimized && minimizedViewClearButton}
    </>
  );
}
export default connect(null, mapDispatchToProps)(TitlesSection);
export { TitlesSection as TitlesSectionWithoutStore };
