import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { getSourcingStats } from '../../../Reducers/JobReducer';
import { getActualSourcingStats } from '../../../Utils/JobSourcingStatsUtil';

const { TabPane } = Tabs;

const mapStateToProps = (state, props) => ({
  sourcingStats: getSourcingStats(state, props.jobId),
});

export function CandidateStatusTab(props) {
  const {
    onStatusChange,
    activeTab,
    setStatus,
    setConnectionStatus,
    setPublishedStatus,
    setJobCandidateTabActiveTab,
    setUrl,
    jobId,
    sourcingStats,
    isCandidateCRMEnabled,
    changeTabStatus,
  } = props;

  const onTabChange = activeKey => {
    if (!changeTabStatus) {
      switch (activeKey) {
        case 'sourced':
          setStatus('Sourced');
          setConnectionStatus(null);
          setPublishedStatus('Sourced');
          onStatusChange(activeKey);
          break;
        case 'shortlisted':
          setStatus('Shortlisted');
          setConnectionStatus(null);
          setPublishedStatus('Shortlisted');
          onStatusChange(activeKey);
          break;
        case 'rejected':
          setStatus('Rejected');
          setConnectionStatus(null);
          setPublishedStatus('Rejected');
          onStatusChange(activeKey);
          break;
        case 'notes':
          setJobCandidateTabActiveTab({ activeTab: 'notes' });
          setUrl(`/segments/${jobId}/notes`);
          setStatus('notes');
          break;
        default:
          break;
      }
    } else {
      switch (activeKey) {
        case 'notes':
          setJobCandidateTabActiveTab({ activeTab: 'notes' });
          changeTabStatus(activeKey);
          break;
        default:
          changeTabStatus(activeKey);
          onStatusChange(activeKey);
          break;
      }
    }
  };

  const { shortlistedCount, exactRejectedCount } = getActualSourcingStats(sourcingStats);

  return isCandidateCRMEnabled ? (
    <div>
      <Tabs activeKey={activeTab} tabBarStyle={{ marginBottom: '24px' }} onChange={onTabChange}>
        <TabPane tab="Search" key="sourced" />
        <TabPane tab={`Included Candidates (${shortlistedCount})`} key="shortlisted" />
        <TabPane tab={`Removed Candidates (${exactRejectedCount})`} key="rejected" />
        <TabPane tab="Notes" key="notes" />
      </Tabs>
    </div>
  ) : null;
}

export default connect(mapStateToProps, null)(CandidateStatusTab);
export { CandidateStatusTab as CandidateStatusTabWithoutStore };
