import _ from 'lodash';
import * as AryabotTemplatesApi from '../Repository/AryabotTemplates';
import { fetchConnectUsers } from './UserActions';

export function searchAryabotTemplates(filter) {
  return dispatch => {
    AryabotTemplatesApi.searchAryabotTemplates(filter).then(response => {
      dispatch({
        type: 'SET_ARYABOT_TEMPLATES',
        payload: response.data.Templates,
      });
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(response.data.Templates.map(template => template.CreatedBy)),
        })
      );
      dispatch({
        type: 'SET_ARYABOT_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

export function getAryabotTemplate(templateId) {
  return dispatch => {
    AryabotTemplatesApi.getAryabotTemplate(templateId).then(response => {
      dispatch({
        type: 'SET_ARYABOT_TEMPLATE_DETAILS',
        payload: {
          templateId,
          template: response.data,
        },
      });
    });
  };
}

export function createAryabotTemplate(templateName, isPublic, questions, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Creating Chatbot template',
      },
    });
    AryabotTemplatesApi.createAryabotTemplate(templateName, isPublic, questions).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Chatbot template created',
        },
      });
    });
  };
}

export function editAryabotTemplate(templateId, templateName, isPublic, questions, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Updating Chatbot Template',
      },
    });
    AryabotTemplatesApi.editAryabotTemplate(templateId, templateName, isPublic, questions).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Chatbot Template Updated',
        },
      });
    });
  };
}

export function shareAryabotTemplate(templateId, shareOptions) {
  let successMessage = 'Template Shared Successfully';
  let loadingMessage = 'Sharing Template';
  if (shareOptions.isShareWithAll) {
    loadingMessage = 'Sharing Template With All';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessage = 'Template Un-shared Successfully';
    loadingMessage = 'Un-sharing Template With All';
  }
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: loadingMessage,
      },
    });
    AryabotTemplatesApi.shareAryabotTemplate(templateId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_ARYABOT_TEMPLATE_SHARED_WITH',
          payload: shareOptions,
          templateId,
        });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: successMessage,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_TEMPLATE_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'FAILURE',
            Message: 'Template sharing Failed',
          },
        });
      });
  };
}

export function duplicateAryabotTemplate(templateId, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Duplicating Chatbot template',
      },
    });
    AryabotTemplatesApi.duplicateAryabotTemplate(templateId).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Chatbot template duplicated',
        },
      });
    });
  };
}

export function getQuestions(templateId, callback) {
  return dispatch => {
    AryabotTemplatesApi.getQuestions(templateId).then(response => {
      dispatch({
        type: 'SET_QUESTIONS',
        payload: {
          templateId,
          questions: response.data,
        },
      });
      if (callback) {
        callback(response.data);
      }
    });
  };
}

export function postQuestions(templateId, questions, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Updating Chatbot template',
      },
    });
    AryabotTemplatesApi.postQuestions(templateId, questions).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Chatbot template updated',
        },
      });
    });
  };
}

export function getAnswers(templateId) {
  return dispatch => {
    AryabotTemplatesApi.getAnswers(templateId).then(response => {
      dispatch({
        type: 'SET_ANSWERS',
        payload: response.data,
      });
    });
  };
}

export function postAnswers(templateId, answers) {
  return () => {
    AryabotTemplatesApi.postAnswers(templateId, answers);
  };
}

export function putAnswers(templateId, answers) {
  return () => {
    AryabotTemplatesApi.putAnswers(templateId, answers);
  };
}

export function deleteAryabotTemplate(templateId, filter) {
  return dispatch => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        Type: 'LOADING',
        Message: 'Deleting Chatbot template',
      },
    });
    AryabotTemplatesApi.deleteTemplate(templateId).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'SUCCESS',
          Message: 'Chatbot template deleted',
        },
      });
    });
  };
}
