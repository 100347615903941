import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import ResumeViewer from '../../Components/ResumeView/ResumeViewer';
import ResumeDownloadButton from '../../Components/ResumeView/ResumeDownloadButton';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getActiveTab, getActiveSourceName } from '../../Reducers/JobCandidatesTabReducer';
import styles from './ResumeViewContainer.module.scss';
import {
  isResumeUnlockable,
  isSocialResumeGenerationAllowed,
  isSocialResumeGenerationEnabled,
} from '../../Utils/ResumeUtils';

const mapStateToProps = state => ({
  resumeDownloadApiStatus: getApiStatus(state, 'resumeDownloadStatus'),
  activeTab: getActiveTab(state),
  activeSourceName: getActiveSourceName(state),
});

function ResumeViewContainer(props) {
  const {
    candidate = {},
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    keywordsToHighlight,
    candidateListStatus,
    candidateDetailsStatus,
    isTryNow,
    downloadResume,
    allowResumeDownload = true,
    version,
    jobGuid,
    currentJobDetails,
    resumeDownloadApiStatus,
    isJobActionsAllowed,
    jobId,
    unlockCandidateResume,
    resumeUnlockStatus,
    featureToggleList,
    connectInfo,
    selectPlan,
    activeTab,
    activeSourceName,
  } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    ref.current.addEventListener('scroll', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);

  const isSocialResumeDownloadAllowed = isSocialResumeGenerationAllowed(candidate, featureToggleList, connectInfo);
  const isSocialResumeDownloadEnabled = isSocialResumeGenerationEnabled(candidate, featureToggleList);

  const resumes = candidate.Resumes;
  let resume;
  if (resumes && resumes.length > 0 && resumes[0].Content) {
    [resume] = resumes;
  }
  return (
    <div
      className={classNames(styles.resumeViewerContainer, { [styles.extendView]: !isCandidateViewHeaderVisible })}
      ref={ref}
    >
      <div>
        <div className={styles.resumeViewer}>
          <ResumeViewer
            highlights={_.uniq([
              ...(keywordsToHighlight || []),
              ...(activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || []),
            ])}
            resume={resume}
            candidate={candidate}
            candidateDetailsStatus={candidateDetailsStatus}
            candidateListStatus={candidateListStatus}
            isSocialResumeDownloadAllowed={isSocialResumeDownloadAllowed}
            isSocialResumeGenerationEnabled={isSocialResumeDownloadEnabled}
            portal={candidate.Portal}
            version={version}
          />
        </div>
        <div className={styles.resumeDownload}>
          <ResumeDownloadButton
            resume={resume}
            isDisable={isTryNow}
            downloadResume={downloadResume}
            candidate={candidate}
            portal={candidate.Portal}
            version={version}
            jobGuid={jobGuid}
            currentJobDetails={currentJobDetails}
            resumeDownloadApiStatus={resumeDownloadApiStatus}
            resumes={candidate.Resumes}
            isUnlockable={isJobActionsAllowed && isResumeUnlockable(candidate)}
            isUnlocked={candidate.IsUnlocked}
            jobId={jobId}
            candidateDetails={candidate}
            unlockCandidateResume={unlockCandidateResume}
            resumeUnlockStatus={resumeUnlockStatus}
            resumeDownloadStatus={resumeDownloadApiStatus}
            allowResumeDownload={allowResumeDownload !== undefined ? allowResumeDownload : true}
            highlights={_.uniq([...(keywordsToHighlight || []), ...(candidate.Keywords || [])])}
            isSocialCandidate={isSocialResumeDownloadAllowed}
            isTryNow={isTryNow}
            selectPlan={selectPlan}
            featureToggleList={featureToggleList}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ResumeViewContainer);
export { ResumeViewContainer as ResumeViewContainerWithoutStore };
