import { message } from 'antd';
import _ from 'lodash';
import config from '../Config/Config';
import * as connectSettingsRepository from '../Repository/ConnectSettingsRepository';
import { mapErrorCodes } from '../Utils/ContactProviderApiErrorResponseMapper';
import { setContactProviderAuthenticationApiStatus } from './ActionCreators/ContactProviderActionCreator';
import { setEmailConfigurationApiStatus, setEmailSmtpSettingsApiStatus } from './ActionCreators/ConnectSettingsActions';

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

function setNotification(type, messageToDisplay) {
  return {
    type: 'SET_NOTIFICATION',
    payload: {
      Type: type,
      Message: messageToDisplay,
    },
  };
}

export function authenticateContactProvider({ providerId, payload }) {
  return async dispatch => {
    try {
      dispatch(setContactProviderAuthenticationApiStatus({ providerId, status: 'INPROGRESS' }));
      await connectSettingsRepository.authenticateContactProvider({ providerId, payload });
      dispatch(setNotification('SUCCESS', `Authenticated Successfully`));
      dispatch(setContactProviderAuthenticationApiStatus({ providerId, status: 'COMPLETED' }));
      return { isSuccess: true };
    } catch (error) {
      const errorMessage = mapErrorCodes(error);
      dispatch(setNotification('ERROR', errorMessage));
      dispatch(setContactProviderAuthenticationApiStatus({ providerId, status: 'FAILED' }));
      return { isSuccess: false };
    }
  };
}

export function disconnectContactProvider({ providerId }) {
  return async dispatch => {
    try {
      dispatch(setContactProviderAuthenticationApiStatus({ providerId, status: 'INPROGRESS' }));
      await connectSettingsRepository.disconnectContactProvider({ providerId });
      dispatch(setNotification('SUCCESS', `Disconnected Successfully`));
      dispatch(setContactProviderAuthenticationApiStatus({ providerId, status: 'COMPLETED' }));
      return { isSuccess: true };
    } catch {
      dispatch(setNotification('ERROR', `Oops,something went wrong.Please try again`));
      dispatch(setContactProviderAuthenticationApiStatus({ providerId, status: 'FAILED' }));
      return { isSuccess: false };
    }
  };
}

export function fetchEmailConfig({ isAdmin }, setCurrentEmailConfigId) {
  return async dispatch => {
    dispatch(setEmailConfigurationApiStatus('INPROGRESS'));
    try {
      const response = await connectSettingsRepository.fetchEmailConfig(isAdmin);
      const responseData = response.data;
      let providers = responseData.Providers;
      providers = _.sortBy(providers, provider => new Date(provider.CreatedTime));
      const payload = {
        isAdmin,
        providers,
      };
      dispatch({
        payload,
        type: 'SET_EMAIL_CONFIG',
      });
      if (isAdmin) {
        const { IsThirdPartyEmailConfigurationAllowed, IsMultipleSenderAddressConfigurationAllowed } = responseData;
        const emailSmtpSettingsPayload = {
          IsThirdPartyEmailConfigurationAllowed,
          IsMultipleSenderAddressConfigurationAllowed,
        };
        dispatch({
          type: 'SET_EMAIL_SMTP_SETTINGS',
          payload: emailSmtpSettingsPayload,
        });
      }
      if (setCurrentEmailConfigId) {
        const currentGmailProviderId = _.last(providers).Id;
        setCurrentEmailConfigId(currentGmailProviderId);
      }
      dispatch(setEmailConfigurationApiStatus('COMPLETED'));
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse && errorResponse.status === 404) {
        const payload = {
          isAdmin,
          providers: [],
        };
        dispatch({
          payload,
          type: 'SET_EMAIL_CONFIG',
        });
      } else {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timestamp: new Date(),
          },
        });
      }
      dispatch(setEmailConfigurationApiStatus('COMPLETED'));
    }
  };
}

export function updateEmailSmtpConfig(configUpdate, { isAdmin, providerId, isNewSmtpSettings }, callback) {
  return async dispatch => {
    try {
      dispatch(setEmailConfigurationApiStatus('INPROGRESS'));
      let response;
      let newConfig = configUpdate;
      const providers = _.get(config, ['SMTP', 'Providers'], []);
      const manualProviderName = _.get(
        _.find(providers, provider => provider.AuthScheme === 'Manual'),
        ['ProviderName'],
        ''
      );
      const oAuthProviderName = _.get(
        _.find(providers, provider => provider.AuthScheme === 'OAuth'),
        ['ProviderName'],
        ''
      );
      if ((isAdmin || isNewSmtpSettings) && configUpdate.AuthScheme === 'Manual') {
        response = await connectSettingsRepository.updateEmailSmtpConfig(configUpdate, isAdmin);
        newConfig = { ...newConfig, ProviderName: manualProviderName };
      } else if (configUpdate.AuthScheme === 'Manual') {
        await connectSettingsRepository.updateManualSmtpConfig(configUpdate, providerId);
        newConfig = { ...newConfig, ProviderName: manualProviderName };
      } else {
        await connectSettingsRepository.updateOAuthSmtpConfig(configUpdate, providerId);
        newConfig = { ProviderName: oAuthProviderName, Name: configUpdate.Name };
      }
      const payload = {
        isAdmin,
        newConfig: {
          ...newConfig,
          Id: providerId,
        },
      };
      if (response && response.data) {
        if (!payload.newConfig.Id) payload.newConfig.Id = response.data.Id;
      }
      if (!isAdmin) {
        const emailProviderResponse = await connectSettingsRepository.getEmailConfig(payload.newConfig.Id);
        payload.newConfig = emailProviderResponse.data;
      }
      dispatch({
        payload,
        type: 'UPDATE_EMAIL_CONFIG',
      });
      dispatch(setEmailConfigurationApiStatus('COMPLETED'));
      if (callback) callback({ updatedProviderId: response?.data?.Id });
      return Promise.resolve();
    } catch {
      dispatch(setEmailConfigurationApiStatus('COMPLETED'));
      return Promise.reject();
    }
  };
}

export function deleteEmailConfig(providerId, { isAdmin }, callback) {
  return async dispatch => {
    try {
      dispatch(setEmailConfigurationApiStatus('INPROGRESS'));
      await connectSettingsRepository.deleteEmailConfig(providerId, isAdmin);
      dispatch({
        type: 'DELETE_EMAIL_CONFIG',
        payload: { providerId },
        isAdmin,
      });
      if (callback) callback();
      dispatch(setEmailConfigurationApiStatus('COMPLETED'));
      return Promise.resolve();
    } catch {
      dispatch(setEmailConfigurationApiStatus('COMPLETED'));
      return Promise.reject();
    }
  };
}

export function fetchCallerIds() {
  return dispatch => {
    dispatch({
      type: 'SET_FETCH_CALLER_IDS_API_STATUS',
      payload: {
        status: 'IN_PROGRESS',
      },
    });
    return connectSettingsRepository
      .fetchCallerIds()
      .then(response => {
        dispatch({
          type: 'SET_CALLER_IDS',
          payload: response.data.CallerIds,
        });
        dispatch({
          type: 'SET_FETCH_CALLER_IDS_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(err => {
        dispatch({
          type: 'SET_FETCH_CALLER_IDS_API_STATUS',
          payload: {
            error: err,
            status: 'FAILED',
          },
        });
      });
  };
}

export function updateCallerIds(updateActions) {
  return dispatch => {
    connectSettingsRepository.updateCallerIds(updateActions).then(() => {
      dispatch(fetchCallerIds());
    });
  };
}

export function fetchContactType() {
  return dispatch => {
    connectSettingsRepository.fetchContactType().then(response => {
      dispatch({
        type: 'SET_CONTACT_TYPE',
        payload: response.data.ContactType,
      });
    });
  };
}

export function updateContactType(contactType) {
  return dispatch => {
    connectSettingsRepository
      .updateContactType(contactType)
      .then(() => {
        dispatch({
          type: 'SET_CONTACT_TYPE',
          payload: contactType,
        });
        message.success('Saved successfully');
      })
      .catch(() => {
        message.error('Failed to save');
      });
  };
}

export function fetchContactProviders() {
  return dispatch => {
    connectSettingsRepository.fetchContactProviders().then(response => {
      dispatch({
        type: 'SET_CONTACT_PROVIDERS',
        payload: response.data.Providers,
      });
    });
  };
}

export function bulkSubscribeContactProviders(providers) {
  return dispatch => {
    connectSettingsRepository
      .bulkSubscribeContactProviders(providers)
      .then(() => {
        dispatch(fetchContactProviders());
        message.success('Saved successfully');
      })
      .catch(() => {
        message.error('Failed to save');
      });
  };
}

export function fetchBotDetails() {
  return dispatch => {
    connectSettingsRepository.fetchBotDetails().then(response => {
      dispatch({
        type: 'SET_BOT_DETAILS',
        payload: response.data,
      });
    });
  };
}

export function updateBotDetails(botFormData, botDetails) {
  return dispatch => {
    dispatch({
      type: 'SET_UPDATE_BOT_DETAILS_API_STATUS',
      payload: 'InProgress',
    });
    connectSettingsRepository
      .updateBotDetails(botFormData)
      .then(() => {
        dispatch({
          type: 'SET_BOT_DETAILS',
          payload: botDetails,
        });
        dispatch({
          type: 'SET_UPDATE_BOT_DETAILS_API_STATUS',
          payload: 'Completed',
        });
        message.success('Saved successfully');
      })
      .catch(() => {
        dispatch({
          type: 'SET_UPDATE_BOT_DETAILS_API_STATUS',
          payload: 'Failed',
        });
        message.error('Failed to save');
      });
  };
}

function setRetestNotification(dispatch, emailId, testStatus) {
  if (testStatus) {
    dispatch(setNotification('SUCCESS', `${emailId} tested successfully`));
  } else {
    dispatch(setNotification('ERROR', `${emailId} testing failed`));
  }
}

function setTestSmtpApiStatus(type) {
  return {
    type: 'SET_TEST_SMTP_API_STATUS',
    payload: type,
  };
}

export function testSmtpEmail(testEmailDetails) {
  const { emailId, providerId, isAdmin, senderName, showTestEmailNotification } = testEmailDetails;
  return async dispatch => {
    dispatch(setTestSmtpApiStatus('INPROGRESS'));
    try {
      const response = await connectSettingsRepository.testSmtpEmail(emailId, providerId, senderName);
      dispatch({
        type: 'UPDATE_TESTED_EMAIL_STATUS',
        payload: { providerId, isAdmin, emailResponse: response.data },
      });
      if (showTestEmailNotification) setRetestNotification(dispatch, emailId, response.data.IsTestEmailSuccessfull);
      dispatch(setTestSmtpApiStatus('SUCCESS'));
      Promise.resolve();
    } catch {
      if (showTestEmailNotification) setRetestNotification(dispatch, emailId, false);
      dispatch(setTestSmtpApiStatus('SUCCESS'));
      Promise.reject();
    }
  };
}

export function deleteSmtpEmail(deleteEmailDetails) {
  const { emailId, providerId, isAdmin } = deleteEmailDetails;
  return async dispatch => {
    try {
      await connectSettingsRepository.deleteSmtpEmail(emailId, providerId);
      dispatch({
        type: 'UPDATE_TESTED_EMAIL_STATUS',
        payload: { emailResponse: { Email: { EmailId: emailId } }, providerId, isAdmin, actionType: 'delete' },
      });
    } catch {
      dispatch(setNotification('ERROR', `Failed to delete ${emailId} provider`));
    }
  };
}

export function updateEmailSmtpSettings(emailSmtpSettings) {
  return async dispatch => {
    dispatch(setEmailSmtpSettingsApiStatus('INPROGRESS'));
    try {
      await connectSettingsRepository.updateEmailSmtpSettings(emailSmtpSettings);
      const { IsThirdPartyEmailConfigurationAllowed, IsMultipleSenderAddressConfigurationAllowed } = emailSmtpSettings;
      const emailSmtpSettingsPayload = {
        IsThirdPartyEmailConfigurationAllowed,
        IsMultipleSenderAddressConfigurationAllowed,
      };
      dispatch({
        type: 'SET_EMAIL_SMTP_SETTINGS',
        payload: emailSmtpSettingsPayload,
      });
      dispatch(setEmailSmtpSettingsApiStatus('COMPLETED'));
      return Promise.resolve();
    } catch {
      dispatch(setEmailSmtpSettingsApiStatus('COMPLETED'));
      return Promise.reject();
    }
  };
}
