import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MessagePopupContainer from './MessagePopup';
import { getVisibleConversations } from '../../Reducers/MessagePopupReducer';
import './MessagePopups.scss';

const mapStateToProps = state => ({
  visibleConversations: getVisibleConversations(state),
});

const mapDispatchToProps = {};

function MessagePopupsContainer(props) {
  const { visibleConversations } = props;
  if (visibleConversations.length === 0) {
    return null;
  }
  return (
    <div id="message-popup-wrapper">
      <div id="horizontal-scroll">
        {visibleConversations.map(conversation => (
          <MessagePopupContainer
            key={`${conversation.conversationId}-${conversation.messageType}`}
            conversationId={conversation.conversationId}
            personId={conversation.personId}
            personNumber={conversation.personNumber}
            messageType={conversation.messageType}
          />
        ))}
      </div>
    </div>
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MessagePopupsContainer)
);
