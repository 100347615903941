import PropTypes from 'prop-types';
import _ from 'lodash';
import { getFeatureToggleList } from './FeatureToggleReducer.ts';
import { getCurrentUser, getImpersonatedUserDetails } from './UserSessionReducer';
import { CLEAR_USER_LIST } from '../Actions/ActionCreators/UserActionCreator';

const initialState = {
  connectUsersById: {},
  userEmails: [],
};

const emptyList = [];
const emptyObject = {};

const listForValueToKeyMapper = { RecruiterSelect: 'userListForRecruiterSelect', ShareJob: 'userListForShareJob' };

function UserReducer(state = initialState, action) {
  let userIds = [];
  let userGuIds;
  let ById;
  let ByGuId;
  let maxCount;
  let updateState = {};
  const newState = _.cloneDeep(state);
  let ConnectMaxCount;
  let { userListForShareTemplate } = newState;
  let users;
  let currentUserGuid;
  let isTryNowUser;
  let userId;
  switch (action.type) {
    case 'SET_USERS':
      ById = {
        ...state.ById,
      };

      userIds = action.payload.Users.map(user => user.Id);
      userIds.forEach((user, index) => {
        ById[user] = {
          ...action.payload.Users[index],
        };
      });

      return {
        ...state,
        ById: {
          ...ById,
        },
      };
    case 'SET_GUID_USERS':
      ByGuId = {
        ...state.ByGuId,
      };

      userGuIds = action.payload.Users.map(user => user.Id);
      userGuIds.forEach((user, index) => {
        ByGuId[user] = {
          ...action.payload.Users[index],
        };
      });

      return {
        ...state,
        ByGuId: {
          ...ByGuId,
        },
      };
    case 'SET_USERS_LIST_FOR_DROPDOWNS': {
      const { listFor } = action.payload;
      let existingUserIds = [];
      if (action.payload.page !== 1) {
        existingUserIds = listFor ? newState[listForValueToKeyMapper[listFor]] : newState.userIds;
      }
      const updatedUserIds = (existingUserIds || []).concat(...action.payload.users.map(user => user.Id));
      if (listFor) {
        updateState[listForValueToKeyMapper[listFor]] = updatedUserIds;
      } else {
        updateState.userIds = updatedUserIds;
      }
      return {
        ...state,
        ...updateState,
        searchTerm: action.payload.searchTerm,
        orgId: action.payload.orgId,
      };
    }

    case 'SET_GUID_USERS_LIST_FOR_DROPDOWNS':
      if (userListForShareTemplate === undefined || action.payload.page === 1) {
        userListForShareTemplate = [];
      }
      userListForShareTemplate.push(...action.payload.users.map(user => user.Id));
      updateState = {
        userListForShareTemplate,
      };
      return {
        ...state,
        ...updateState,
        searchTerm: action.payload.searchTerm,
        orgId: action.payload.orgId,
      };

    case 'RESET_USERS_LIST_FOR_DROPDOWNS':
      if (action.payload.type === 'ShareJob') {
        _.set(newState, 'userListForShareJob', []);
      } else if (action.payload.type === 'RecruiterSelect') {
        _.set(newState, 'userListForRecruiterSelect', []);
      } else if (action.payload.type === 'ShareTemplate') {
        _.set(newState, 'userListForShareTemplate', []);
      }
      return newState;
    case 'SET_MAX_COUNT':
      maxCount = action.payload;
      return {
        ...state,
        maxCount,
      };
    case 'SET_TOTAL_USERS_COUNT':
      return {
        ...state,
        totalUsersCount: action.payload.count,
      };

    case 'SET_CONNECT_USERS':
      ById = _.clone(state.connectUsersById);

      action.payload.forEach(user => {
        _.set(ById, [user.Id], user);
      });

      return {
        ...state,
        connectUsersById: ById,
      };

    case 'SET_EMAIL_SIGNATURES':
      return {
        ...state,
        emailSignatures: action.payload,
      };

    case 'SET_USER_EMAILS':
      return {
        ...state,
        userEmails: action.payload,
      };

    case 'SET_NOTIFICATIONS_USERS':
      ByGuId = {
        ...state.ByGuId,
      };
      action.payload.forEach(user => {
        _.set(ByGuId, [user.UserGuid], user);
      });

      return {
        ...state,
        ByGuId: {
          ...ByGuId,
        },
      };

    case 'SET_CONNECT_MAX_COUNT':
      ConnectMaxCount = action.payload;
      return {
        ...state,
        ConnectMaxCount,
      };

    case 'SET_USERS_BY_GUID':
      users = _.get(action, ['payload', 'Users'], []);
      users.forEach(user => {
        const userGuid = _.get(user, 'UserGuid', null);
        _.setWith(newState, ['ByGuId', userGuid], user, Object);
      });
      return newState;

    case 'SET_USER_LOCATIONS':
      newState.utilities = _.get(newState, 'utilities', {});
      newState.utilities.locations = action.payload;
      return newState;

    case 'SET_USER_ZIP_CODES':
      newState.utilities = _.get(newState, 'utilities', {});
      newState.utilities.zipCodes = action.payload;
      return newState;

    case 'SET_USER_ZIP_CODE_STATUS':
      newState.utilities = _.get(newState, 'utilities', {});
      newState.utilities.isZipCodeInValid = action.payload;
      return newState;

    case 'SET_USER_IP_INFO':
      newState.ipInfo = action.payload;
      return newState;
    case 'SET_CURRENT_USER_TRYNOW_FLAG':
      ({ currentUserGuid, isTryNowUser } = action.payload);
      ByGuId = _.cloneDeep(state.ByGuId);
      ById = _.cloneDeep(state.ById);
      _.set(ByGuId, [currentUserGuid, 'IsTryNow'], isTryNowUser);
      userId = _.get(ByGuId, [currentUserGuid, 'Id']);
      if (userId) {
        _.set(ById, [userId, 'IsTryNow'], isTryNowUser);
      }
      newState.ByGuId = ByGuId;
      newState.ById = ById;
      return newState;
    case CLEAR_USER_LIST:
      return {
        ...state,
        userListForShareJob: [],
      };

    default:
      return state;
  }
}

UserReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getUsersById(state) {
  return state.UserReducer.ById ?? emptyObject;
}

function getUserListForShareJob(state) {
  return state.UserReducer.userListForShareJob ?? emptyList;
}

function getUserListForRecruiterSelect(state) {
  return state.UserReducer.userListForRecruiterSelect ?? emptyList;
}

function getUserListForShareTemplate(state) {
  return state.UserReducer.userListForShareTemplate ?? emptyList;
}

function getUserIds(state) {
  return state.UserReducer.userIds ?? emptyList;
}
function getMaxCount(state) {
  return state.UserReducer.maxCount ?? -1;
}

function getTotalUsersCount(state) {
  return state.UserReducer.totalUsersCount;
}

function getConnectUsersById(state) {
  return state.UserReducer.connectUsersById;
}

function getSignatures(state) {
  return state.UserReducer.emailSignatures;
}

function getRecruiterDropdownSelectedOrg(state) {
  return state.UserReducer.orgId;
}

function getUsersByGuId(state) {
  return state.UserReducer.ByGuId ?? emptyObject;
}

function getConnectMaxCount(state) {
  return state.UserReducer.ConnectMaxCount === undefined ? -1 : state.UserReducer.ConnectMaxCount;
}

function getUserEmails(state) {
  return _.get(state.UserReducer, ['userEmails'], []);
}

function getFilteredUserEmails(state) {
  const featureToggles = getFeatureToggleList(state);
  const isPrimaryFromEmailEnabled = featureToggles?.PrimaryFromEmail?.IsEnabled;
  const impersonatedUserDetails = getImpersonatedUserDetails(state);
  const currentUserEmailId = impersonatedUserDetails
    ? _.get(impersonatedUserDetails, ['Email'], '').toLowerCase()
    : _.get(getCurrentUser(state), ['email'], '').toLowerCase();
  if (isPrimaryFromEmailEnabled) {
    return getUserEmails(state);
  }
  return getUserEmails(state).filter(email => email?.EmailId?.toLowerCase() !== currentUserEmailId);
}

function getUserLocationUtilities(state) {
  return state.UserReducer.utilities;
}

function getUserIpInfo(state) {
  return state.UserReducer.ipInfo;
}

export {
  UserReducer,
  getUsersById,
  getUserListForShareJob,
  getUserListForRecruiterSelect,
  getUserListForShareTemplate,
  getMaxCount,
  getTotalUsersCount,
  getUserIds,
  getConnectUsersById,
  getSignatures,
  getRecruiterDropdownSelectedOrg,
  getUsersByGuId,
  getConnectMaxCount,
  getUserEmails,
  getFilteredUserEmails,
  getUserLocationUtilities,
  getUserIpInfo,
};
