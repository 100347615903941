import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AutomationEmailIcon } from '../../Icons/AryaIcons';
import './AutomationEmailId.scss';

export function getSendToText(sendToStatuses, sendToUserRecommended) {
  if (sendToUserRecommended) {
    return 'User Given';
  }
  const SEND_TO = {
    All: ['shortlisted', 'sourced'],
    Shortlisted: ['shortlisted'],
    allIdentifier: 'ALL',
    shortlistedIdentifier: 'SHORTLISTED',
    userGivenIdentifier: 'USERGIVEN',
  };

  let sendTo = sendToStatuses ? sendToStatuses.map(sendTostatus => sendTostatus.toLowerCase()) : [];
  if (_.isEqual([...sendTo].sort(), SEND_TO.Shortlisted)) {
    sendTo = SEND_TO.shortlistedIdentifier;
  } else if (_.isEqual([...sendTo].sort(), SEND_TO.All)) {
    sendTo = SEND_TO.allIdentifier;
  } else {
    sendTo = null;
  }
  return sendTo;
}

export default function AutomationEmailId(props) {
  const { emailId, sendToStatuses, sendToUserRecommended } = props;
  const sendTo = getSendToText(sendToStatuses, sendToUserRecommended);

  return (
    <div className="auto-email-id-container">
      <AutomationEmailIcon className="auto-email-icon" />
      <div className="auto-email-label-text">Automation Email:</div>
      <div className="auto-email-id">{emailId}</div>
      {sendTo ? <div className="auto-email-send-to">{`| ${sendTo}`}</div> : null}
    </div>
  );
}

AutomationEmailId.propTypes = {
  emailId: PropTypes.string.isRequired,
  sendToStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  sendToUserRecommended: PropTypes.bool,
};

AutomationEmailId.defaultProps = {
  sendToUserRecommended: false,
};
