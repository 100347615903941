import React from 'react';
import { Layout, Menu } from 'antd';
import RecruiterReportsContainer from '../../../Containers/ReportPortalContainer/RecruiterReportsContainer/RecruiterReportsContainer';
import CandidateReportsContainer from '../../../Containers/ReportPortalContainer/CandidateReportsContainer/CandidateReportsContainer';
import JobReportsContainer from '../../../Containers/ReportPortalContainer/JobReportsContainer/JobReportsContainer';
import styles from './ReportStatsTabComponent.module.scss';
import SavedReportQueriesContainer from '../../../Containers/ReportPortalContainer/SavedReportQueriesContainer/SavedReportQueriesContainer';

const { Content, Sider } = Layout;

const reportTypes = [
  {
    key: 'recruiters',
    displayText: 'Recruiter Report',
  },
  {
    key: 'candidates',
    displayText: 'Candidate Report',
  },
  {
    key: 'jobs',
    displayText: 'Job Report',
  },
  {
    key: 'saved',
    displayText: 'Saved Report Queries',
  },
];

export default function ReportStatsTabComponent(props) {
  const {
    clearFetchReportStatsApiStatus,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
    clearReportStats,
  } = props;

  const [activeReportType, setActiveReportType] = React.useState('recruiters');

  const onReportMenuClick = async ({ key }) => {
    const reportKey = key?.toLowerCase();
    if (reportKey !== 'saved' && reportKey !== activeReportType) {
      await Promise.all([
        clearReportStats(reportKey),
        clearRecruitersForDropdownLists(reportKey),
        clearClientsForDropdownLists(reportKey),
        clearFetchReportStatsApiStatus(),
      ]);
    }
    setActiveReportType(reportKey?.toLowerCase());
  };

  const getReportsContainer = () => {
    switch (activeReportType) {
      case 'recruiters':
        return <RecruiterReportsContainer />;
      case 'candidates':
        return <CandidateReportsContainer />;
      case 'jobs':
        return <JobReportsContainer />;
      case 'saved':
        return <SavedReportQueriesContainer />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.reportsTabComponent}>
      <Layout className={styles.reportsTabLayout}>
        <Sider width={276} className={styles.reportsTabSider}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[activeReportType]}
            style={{ height: '100%' }}
            onClick={onReportMenuClick}
            className={styles.reportsTabMenu}
          >
            {reportTypes?.map(reportType => (
              <Menu.Item key={reportType?.key}>{reportType?.displayText}</Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className={styles.reportsTabContent}>{getReportsContainer()}</Content>
      </Layout>
    </div>
  );
}
