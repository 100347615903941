import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Collapse, Skeleton, Timeline, Empty } from 'antd';
import { getCampaignStats } from '../../Reducers/CandidateAdvanceFiltersReducer';
import * as CandidateAdvanceFilterActions from '../../Actions/CandidateAdvanceFiltersActions';
import styles from '../CandidateFilters/CandidateAggregationFilter/CandidateAdvanceFilters/CandidateAdvanceFilters.module.scss';

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#FFFFFF',
};

const mapStateToProps = state => ({
  campaignStats: getCampaignStats(state),
});

const mapDispatchToProps = {
  fetchCampaignStatsByCampaignId: CandidateAdvanceFilterActions.fetchCampaignStatsByCampaignId,
};

const CampaignStats = props => {
  const { campaignStats, fetchCampaignStatsByCampaignId, jobId } = props;
  const [globalApiStatus, setGloblaApiStatus] = React.useState();
  const [byCampaignResults, setByCampaignResults] = React.useState([]);

  const handleCampaign = value => {
    const {
      CampaignName,
      CreatedByName,
      CandidatesCount,
      CreatedOn,
      SentCount,
      FailedCount,
      OpenedCount,
      ClickCount,
      RepliedCount,
    } = value;
    return (
      <div className={styles.campaignStatsHeader}>
        <div className={styles.campaignStats}>
          <div className={styles.campaignAttributes}>{CampaignName ?? 'Bulk email'}</div>
          <div>|</div>
          <div>
            Created by: <span className={styles.campaignAttributes}>{CreatedByName}</span>
          </div>
          <div>|</div>
          <div>
            Candidates: <span className={styles.campaignAttributes}>{CandidatesCount}</span>
          </div>
        </div>
        <div className={styles.campaignStats}>
          <div className={styles.campaignType}>Email</div>
          <div>Created on: {moment.utc(CreatedOn).local().format('ll')}</div>
        </div>
        <div className={styles.campaignStats}>
          <div className={styles.campaignAttributes}>&mdash;Overall stats: </div>
          <div>Sent: ({SentCount})</div>
          <div>|</div>
          <div>Failed: ({FailedCount})</div>
          <div>|</div>
          <div>Opened: ({OpenedCount})</div>
          <div>|</div>
          <div>Clicked: ({ClickCount})</div>
          <div>|</div>
          <div>Replied: ({RepliedCount})</div>
        </div>
      </div>
    );
  };

  const handleCampaignPanelContent = (value, index) => {
    const { CreatedByName, CandidatesCount, CreatedOn, SentCount, FailedCount, OpenedCount, ClickCount, RepliedCount } =
      value;
    return (
      <div className={styles.campaignStatsHeader}>
        <div className={styles.campaignStats}>
          <div>
            Added by: <span className={styles.campaignAttributes}>{CreatedByName}</span>
          </div>
          <div>|</div>
          <div>Date: {moment.utc(CreatedOn).local().format('ll')}</div>
          <div>|</div>
          <div>
            Candidates: <span className={styles.campaignAttributes}>{CandidatesCount}</span>
          </div>
        </div>
        <div className={styles.campaignStats}>
          <div> &mdash;Sent: ({SentCount})</div>
          <div>|</div>
          <div>Failed: ({FailedCount})</div>
          <div>|</div>
          <div>Opened: ({OpenedCount})</div>
          <div>|</div>
          <div>Clicked: ({ClickCount})</div>
          <div>|</div>
          <div>Replied: ({RepliedCount})</div>
        </div>
        {index + 1 !== byCampaignResults?.length ? <div className={styles.timelineItem}></div> : null}
      </div>
    );
  };

  const handleChange = async key => {
    if (key) {
      const campaignValue = campaignStats[key];
      setGloblaApiStatus('INPROGRESS');
      const results = await fetchCampaignStatsByCampaignId(jobId, campaignValue?.CampaignId);
      setByCampaignResults(results);
      setGloblaApiStatus('COMPLETED');
    }
  };

  return (
    <div className={styles.campaignStatsPanel}>
      {campaignStats?.length > 0 ? (
        <Collapse expandIconPosition="right" onChange={handleChange} accordion>
          {campaignStats?.map((campaign, index) => (
            <Panel
              header={handleCampaign(campaign)}
              key={index}
              style={customPanelStyle}
              disabled={campaignStats[index]?.CampaignId === 0}
              showArrow={campaignStats[index]?.CampaignId !== 0}
            >
              <Skeleton active title={false} paragraph={{ rows: 3 }} loading={globalApiStatus === 'INPROGRESS'}>
                <Timeline color="#52C41A">
                  {byCampaignResults?.map((campaignResult, index) => (
                    <Timeline.Item>{handleCampaignPanelContent(campaignResult, index)}</Timeline.Item>
                  ))}
                  <div className={styles.hiddenTimeline}>
                    <Timeline.Item dot={<div />}></Timeline.Item>
                  </div>
                </Timeline>
              </Skeleton>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 100 }}
          description={<p className={styles.emptyText}>Please run a campaign or bulk to view the data</p>}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignStats);
