import cleanSet from 'clean-set';
import { SET_JOB_ACTIVITY } from '../Actions/ActionCreators/JobActivityActionCreator';

const initialState = {
  ById: {},
};

const emptyList = [];

function JobActivityReducer(state = initialState, action) {
  let payload;
  let newState;
  switch (action.type) {
    case SET_JOB_ACTIVITY: {
      ({ payload } = action);
      const { activity, jobId, totalCount } = payload;
      newState = cleanSet(state, ['ById', jobId, 'JobActivity'], activity);
      newState = cleanSet(newState, ['ById', jobId, 'TotalCount'], totalCount);
      return newState;
    }
    default:
      return state;
  }
}

function getJobActivity(state, { jobId }) {
  return state.JobActivityReducer.ById?.[jobId]?.JobActivity ?? emptyList;
}

function getJobActivityTotalCount(state, { jobId }) {
  return state.JobActivityReducer.ById?.[jobId]?.TotalCount;
}

export { JobActivityReducer, getJobActivity, getJobActivityTotalCount };
