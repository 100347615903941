import React from 'react';
import _ from 'lodash';
import { Modal } from 'antd';
import WorkflowLayout from '../WorkflowLayout/WorkflowLayout';
import WorkflowReportModal from '../../WorkflowReport/ReportModal';
import {
  getTitleAndDescription,
  getUpdatedElementsAfterNodeAddition,
  getUpdatedElementsAfterNodeDeletion,
  getPayloadElements,
  hideNodesAndEdgesAfterEndNode,
} from '../WorkflowUtils/WorkflowElementUtils';
import { actionTypes } from '../../../Utils/WorkflowUtils';
import { generateInitialElements } from '../WorkflowUtils/ElementGenerationUtility';
import WorkflowNodeModal from '../../../Components/WorkflowNodeModal/WorkflowNodeModal';
import styles from './WorkflowLayoutContainer.scss';

const WorkflowLayoutContainer = ({
  elements,
  setElements,
  initialElements,
  mergeTags,
  displayMode,
  isDisabled,
  isWorkflowReportModalVisible,
  setWorkflowReportModalVisibility,
  version,
  workflowType,
  workflowReportApiStatus,
}) => {
  const [nodeDetails, setNodeDetails] = React.useState({ nodeId: null, nodeType: null, initialValues: {} });
  const [workflowModalVisibility, setWorkflowModalVisibility] = React.useState(false);

  const elementsRef = React.useRef();

  React.useEffect(() => {
    elementsRef.current = elements;
  }, [JSON.stringify(elements)]);

  const getFirstNodeId = (edges, startNodeId) => {
    return edges.find(x => x.source === startNodeId).target;
  };

  const getStartNodeId = nodes => {
    return nodes.find(x => x.type === actionTypes.Start).id;
  };

  const updateFirstNodeAndFirstEdge = _elements => {
    const _nodes = _elements.filter(x => !x.target);
    const _edges = _elements.filter(x => x.target);
    const startNodeId = getStartNodeId(_nodes);
    const firstNodeId = getFirstNodeId(_edges, startNodeId);
    const nodes = _nodes.map(x => ({ ...x, data: { ...x.data, isFirstNode: x.id === firstNodeId } }));
    const edges = _edges.map(x => ({ ...x, data: { ...x.data, isFirstEdge: x.source === startNodeId } }));
    return [...nodes, ...edges];
  };

  const deleteNode = ({ id, type }) => {
    const updatedElementsAfterNodeDeletion = getUpdatedElementsAfterNodeDeletion({
      elements: elementsRef.current,
      targetNodeId: id,
      type,
    });
    setElements(updateFirstNodeAndFirstEdge(updatedElementsAfterNodeDeletion));
  };
  const onDeleteNodeCallback = ({ id, type }) => {
    const modalOptions = {
      title: 'Are you sure to delete this item?',
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      okText: 'Delete',
      onOk: () => deleteNode({ id, type }),
    };
    Modal.confirm(modalOptions);
  };

  const onNodeClickCallback = ({ id, type }) => {
    const updatedElement = elementsRef.current.find(element => element.id === id);
    const { properties, isFirstNode } = updatedElement?.data || {};
    setNodeDetails({ ...nodeDetails, nodeId: id, nodeType: type, initialValues: properties, isFirstNode });
    setWorkflowModalVisibility(true);
  };

  const onSave = payload => {
    const { type, properties } = payload;
    const updatedElements = _.cloneDeep(elementsRef.current);
    const { title, description } = getTitleAndDescription({ type, properties });
    const elementIndex = elements?.findIndex(element => element.id === nodeDetails.nodeId);
    const updatedElement = elements?.find(element => element.id === nodeDetails.nodeId);
    updatedElement.data.title = title;
    updatedElement.data.description = description;
    updatedElement.data.properties = properties;
    updatedElements[elementIndex] = updatedElement;
    setElements(updatedElements);
    setNodeDetails({ ...nodeDetails, nodeId: null, nodeType: null });
    setWorkflowModalVisibility(false);
  };

  const onCancel = () => {
    setNodeDetails({ ...nodeDetails, nodeId: null, nodeType: null, initialValues: null });
    setWorkflowModalVisibility(false);
  };

  const onAddNodeCallback = ({ id, type, properties }) => {
    const updatedElementsAfterNodeAddition = getUpdatedElementsAfterNodeAddition({
      elements: elementsRef.current,
      targetEdgeId: id,
      type,
      properties,
      onDeleteNodeCallback,
      onNodeClickCallback,
      onAddNodeCallback,
    });
    setElements(updateFirstNodeAndFirstEdge(updatedElementsAfterNodeAddition));
  };

  React.useEffect(() => {
    const payload = getPayloadElements(initialElements);
    const generatedElements = generateInitialElements(payload);
    const elementsAfterEndActionUpdate = hideNodesAndEdgesAfterEndNode({ elements: generatedElements });
    const _nodes = elementsAfterEndActionUpdate.filter(x => !x.target);
    const startNodeId = getStartNodeId(_nodes);
    const edges = elementsAfterEndActionUpdate
      .filter(x => x.target)
      .map(x => ({
        ...x,
        data: { ...x.data, type: workflowType, onAddNodeCallback, isFirstEdge: x.source === startNodeId },
      }));
    const firstNodeId = getFirstNodeId(edges, startNodeId);
    const nodes = _nodes.map(x => ({
      ...x,
      data: {
        ...x.data,
        type: workflowType,
        onDeleteNodeCallback,
        onNodeClickCallback,
        isFirstNode: x.id === firstNodeId,
      },
    }));
    setElements([...nodes, ...edges]);
  }, []);

  const { nodeType, initialValues, isFirstNode } = nodeDetails;

  return (
    <div>
      <div className={styles.workflowLayoutContainer}>
        <WorkflowLayout elements={elements} version={version} workflowReportApiStatus={workflowReportApiStatus} />
        <WorkflowNodeModal
          type={nodeType}
          displayMode={displayMode}
          initialValue={initialValues}
          isFirstNode={isFirstNode}
          visible={workflowModalVisibility}
          onSaveCallback={onSave}
          onCancelCallback={onCancel}
          setWorkflowModalVisibility={setWorkflowModalVisibility}
          mergeTags={mergeTags}
          isDisabled={isDisabled}
        />
        <WorkflowReportModal
          isVisible={isWorkflowReportModalVisible}
          setWorkflowReportModalVisibility={setWorkflowReportModalVisibility}
        />
      </div>
    </div>
  );
};

export default WorkflowLayoutContainer;
