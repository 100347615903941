import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import CandidateSourceFilter from './CandidateSourceFilter/CandidateSourceFilter';
import SelectedCandidatesText from './SelectedCandidatesText';
import CandidateSearchFilter from './CandidateSearchFilter/CandidateSearchFilter';
import CandidateAggregationFilter from './CandidateAggregationFilter/CandidateAggregationFilter';
import CandidateSortFilter from './CandidateSortFilter/CandidateSortFilter';
import CandidateDiversityFilter from './CandidateDiversityFilter/CandidateDiversityFilter';
import JobReactivationTree from './JobReactivationTree/JobReactivationTree';
import ReactivateArya from './ReactivateArya/ReactivateArya';
import CandidateRejectionFilter from './CandidateRejectFilter/CandidateRejectFilter';
import { getWhiteLabelInformation, getConfig } from '../../Reducers/ConfigReducer';
import { getConnectDetails } from '../../Reducers/CandidateReducer';
import { getEmailStatsForJob } from '../../Reducers/JobReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { isPulseUser } from '../../Utils/ConfigUtils';
import { filterConfiguration, showCandidateRejectFilter } from '../../Utils/CandidateFilterUtils';
import './CandidateFilters.scss';

function CandidateFilters(props) {
  const {
    showIntelBanners,
    jobId,
    onChange,
    featureToggleList,
    currentJobDetails,
    currentPipelineFilter,
    candidates,
    isCandidateListLoading,
    isCandidateAggregationsLoading,
    applyCandidateFilter,
    isDiversityAttributesVisible,
    activeTab,
    totalCandidate,
    isClearFilterApplied,
    setClearFilterApplied,
    resetMultiSelect,
    currentPageCandidateIds,
    fetchBulkCandidateViewStatus,
    setCurrentUserCandidateViewedFlag,
    currentUserViewedStatusFlag,
    isAryaRankedTab,
    smartRecruiterVersion,
    onStatusChange,
    activeSourceName,
  } = props;

  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [isFilteredCandidatesCountVisible, setIsFilteredCandidatesCountVisible] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  const selectedCandidates = currentJobDetails.CandidateIds ?? [];
  const showSelectedCandidatesText =
    !isCandidateListLoading &&
    !isCandidateAggregationsLoading &&
    (currentPipelineFilter?.Portals?.length || isFilterApplied || isFilteredCandidatesCountVisible) &&
    selectedCandidates.length;

  React.useEffect(() => {
    if (isClearFilterApplied) {
      setIsFilteredCandidatesCountVisible(false);
      setIsFilterApplied(false);
      if (setClearFilterApplied) setClearFilterApplied(false);
    }
    resetMultiSelect();
  }, [isClearFilterApplied, showSelectedCandidatesText]);
  const { candidateCountBySource, CandidateAggs: candidateAggs } = currentJobDetails;
  const whiteLabelInfo = useSelector(state => getWhiteLabelInformation(state));
  const emailStatsForJob = useSelector(state => getEmailStatsForJob(state));
  const connectDetails = useSelector(state => getConnectDetails(state));
  const candidateListApiStatus = useSelector(state => getApiStatus(state, 'candidateListStatus'));
  const candidateAggregations = candidateAggs ?? [];
  const isReactivateAryaAndJobReactivationTreeAllowed =
    featureToggleList.CandidateManualSearch.IsEnabled && showIntelBanners;
  const config = useSelector(state => getConfig(state));

  const isPulse = isPulseUser();
  const isServiceRep = config.Role === 'Service Rep';
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');

  const eventControl = event => {
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  const isScoutingAgentTab = activeSourceName === 'scoutingAgent';

  return (
    <div className="candidate-filter-row">
      <div className="candidate-source-filter-wrapper">
        {featureToggleList.SourceFilter.IsEnabled ? (
          <CandidateSourceFilter
            applyCandidateFilter={applyCandidateFilter}
            candidateCountBySource={candidateCountBySource}
            whiteLabelInfo={whiteLabelInfo}
            selectedSources={currentPipelineFilter.Portals}
            sourcesConfig={config?.Sources}
            sourceConfigsByName={config?.SourceConfigsByName ?? []}
            portalSources={config?.PortalSources ?? []}
            showVaultName={config?.ShowVaultName}
            jobCountryCode={currentJobDetails.CountryCode}
            featureToggleList={featureToggleList}
          />
        ) : null}

        {showSelectedCandidatesText ? (
          <div className="candidate-filter-selected-portal-text">
            <SelectedCandidatesText candidatesCount={selectedCandidates.length} />
          </div>
        ) : null}
      </div>

      <div className="candidate-control-icons">
        {featureToggleList.CandidateSearch.IsEnabled ? (
          <div className="candidate-search-box-wrapper">
            <CandidateSearchFilter
              onChange={onChange}
              applyCandidateFilter={applyCandidateFilter}
              setIsFilteredCandidatesCountVisible={setIsFilteredCandidatesCountVisible}
              searchTerm={currentPipelineFilter.SearchQuery}
            />
          </div>
        ) : null}

        {featureToggleList.Diversity.IsEnabled && !isScoutingAgentTab ? (
          <div>
            <CandidateDiversityFilter
              applyCandidateFilter={applyCandidateFilter}
              currentPipelineFilter={currentPipelineFilter}
              candidateAggs={candidateAggregations}
              isDiversityAttributesVisible={isDiversityAttributesVisible}
            />
          </div>
        ) : null}

        {isReactivateAryaAndJobReactivationTreeAllowed? (
          <>
            <Draggable
              defaultClassName={isAdvancedSearchV2Enabled && 'draggedReactivateArya'}
              axis="both"
              positionOffset={isAdvancedSearchV2Enabled ? { x: '110%', y: '75%' } : {}}
              onDrag={eventControl}
              onStop={eventControl}
              disabled={!isAdvancedSearchV2Enabled}
              scale={1}
            >
              <div>
                <ReactivateArya
                  jobId={jobId}
                  currentJobDetails={currentJobDetails}
                  AppName={AppName}
                  smartRecruiterVersion={smartRecruiterVersion}
                  isDragging={isDragging}
                  onStatusChange={onStatusChange}
                  activeTab={activeTab}
                />
              </div>
            </Draggable>
            {isAryaRankedTab ? null : (
              <JobReactivationTree jobId={jobId} currentJobDetails={currentJobDetails} AppName={AppName} />
            )}
          </>
        ) : null}

        {featureToggleList.CandidateFilter.IsEnabled ? (
          <div className="candidate-filter-icon">
            <CandidateAggregationFilter
              applyCandidateFilter={applyCandidateFilter}
              currentPipelineFilter={currentPipelineFilter}
              setIsFilterApplied={setIsFilterApplied}
              candidateAggs={candidateAggregations}
              whiteLabelInfo={whiteLabelInfo}
              isFilterApplied={isFilterApplied}
              showVaultName={config?.ShowVaultName}
              candidates={candidates}
              emailStatsForJob={emailStatsForJob}
              totalCandidate={totalCandidate}
              connectDetails={connectDetails}
              isLoading={candidateListApiStatus === 'INPROGRESS'}
              activeTab={activeTab}
              currentPageCandidateIds={currentPageCandidateIds}
              fetchBulkCandidateViewStatus={fetchBulkCandidateViewStatus}
              jobId={jobId}
              currentJobDetails={currentJobDetails}
              setCurrentUserCandidateViewedFlag={setCurrentUserCandidateViewedFlag}
              currentUserViewedStatusFlag={currentUserViewedStatusFlag}
            />
          </div>
        ) : null}

        {showCandidateRejectFilter(activeTab, isPulse, isServiceRep) ? (
          <div className="candidate-filter-icon">
            <CandidateRejectionFilter
              applyCandidateFilter={applyCandidateFilter}
              candidateAggs={candidateAggregations}
              currentPipelineFilter={currentPipelineFilter}
              setIsFilterApplied={setIsFilterApplied}
              isFilterApplied={isFilterApplied}
              candidates={candidates}
              filterConfiguration={filterConfiguration}
              totalCandidate={totalCandidate}
            />
          </div>
        ) : null}
        {featureToggleList.CandidateSort.IsEnabled && !isScoutingAgentTab ? (
          <div className="candidate-sort-icon">
            <CandidateSortFilter applyCandidateFilter={applyCandidateFilter} candidates={candidates} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CandidateFilters;
