import React from 'react';
import _ from 'lodash';
import { Icon, Button, Alert, Checkbox, Popover, message } from 'antd';
import classNames from 'classnames';
import { getEmailAddressWithEmailTypeIcon, getSortedEmailsByEmailTypes } from '../../Utils/EmailUtils/EmailUtils';
import styles from './AvailableContactInformation.module.scss';
import AddEmail from '../PullContact/AddEmail/AddEmail';
import {
  validateStatus,
  checkIfAnyNonRefundedPulledContactsExists,
  getLastRefundTime,
  getSortedPhonesByTypes,
} from '../../Utils/ContactUtils';
import AddPhoneNumber from '../PullContact/AddPhoneNumber/AddPhoneNumber';
import SMSIcon from '../../Icons/SMSIcon';
import CopyIcon from '../../Icons/CopyIcon';
import CallIcon from '../../Icons/CallIcon';
import { getEllipsedText } from '../../Utils/TextUtils';
import { getCandidateOptOutReason } from '../../Utils/CandidateRejectReasonsUtils';
import { getIsCandidateCommunicationDisabled } from '../../Utils/ConnectUtils';
import { isPulseUser } from '../../Utils/ConfigUtils';

function AvailableContactInformation(props) {
  const {
    candidate,
    connectInfo,
    addContact,
    jobGuid,
    getRefundForInvalidContacts,
    creditsRefunded,
    mailRef,
    smsRef,
    callRef,
    onSubTabClick,
    setEmailComposeModalVisibility,
    setMessageComposeModalVisibility,
    setCurrentSelectedEmail,
  } = props;
  const [isAddEmailFormVisible, setAddEmailFormVisibility] = React.useState(false);
  const [isAddPhoneFormVisible, setAddPhoneFormVisibility] = React.useState(false);
  const [isDetailsVisible, setDetailsVisibility] = React.useState(true);
  const [requestCreditFlag, setRequestCreditFlag] = React.useState(true);
  const [isRefundedCreditsVisible, setRefundedCreditsVisibility] = React.useState(false);
  const [emailSeeMore, setEmailSeeMore] = React.useState(true);
  const [phoneSeeMore, setPhoneSeeMore] = React.useState(true);

  const isPulse = isPulseUser();
  const maxEmailLength = 30;
  const connectStatuses = connectInfo.ConnectStatuses || {};
  const connectStatus = candidate ? _.get(connectStatuses, candidate.PersonId, {}) : {};
  const areContactsAvailable =
    _.get(connectStatus, ['Contact', 'Emails'], []).length || _.get(connectStatus, ['Contact', 'Phones'], []).length;
  const phones = _.get(connectStatus, 'Contact.Phones', []);

  const isCandidateRequestStatusSuccessful = _.get(connectStatus, ['Contact', 'RequestStatus'], '') === 'Success';
  const maxContactToBeShownInitially = 4;

  const onEmailAddClick = () => {
    setAddEmailFormVisibility(true);
  };

  const addEmail = value => {
    const contactObject = {
      Emails: [
        {
          EmailAddress: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
    setAddEmailFormVisibility(false);
  };

  const onPhoneAddClick = () => {
    setAddPhoneFormVisibility(true);
  };

  const addPhone = value => {
    const contactObject = {
      Phones: [
        {
          Number: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
    setAddPhoneFormVisibility(false);
  };

  const handleCopyContact = contact => {
    const value = contact.EmailAddress || contact.Number;
    navigator.clipboard.writeText(value);
    const successMessage = contact.EmailAddress ? 'Email address copied' : 'Phone number copied';
    message.success(successMessage, 1);
  };

  const isCandidateCommunicationDisabled = getIsCandidateCommunicationDisabled(candidate?.PersonId, connectInfo);

  const handleSendEmail = async email => {
    await onSubTabClick('email');
    mailRef.current.onChangeMail(email.EmailAddress);
    setCurrentSelectedEmail(email.EmailAddress);
    if (!isCandidateCommunicationDisabled) setEmailComposeModalVisibility(true);
  };

  const handleSendSMS = async phone => {
    await onSubTabClick('message');
    smsRef.current.onChangePhone(phone.Number);
    if (!isCandidateCommunicationDisabled) setMessageComposeModalVisibility(true);
  };
  const candidateOptOutReason = getCandidateOptOutReason(candidate);
  const isCommunicationAllowed = !(phones || []).some(phone => !phone.IsCommunicationAllowed) && !candidateOptOutReason;

  const handleCall = async phone => {
    await onSubTabClick('call');
    callRef.current.onPhoneSelection(phone.Number);
    if (isCommunicationAllowed && !isCandidateCommunicationDisabled) callRef.current.initiateCall();
  };

  const getEmailPopoverContent = ({ email, emailTooltip }) => {
    return (
      <div className={styles.actionButtons}>
        <Button type="link" onClick={() => handleSendEmail(email)}>
          <div className={styles.smsIconAndText}>
            <SMSIcon />
            <span>Send Email</span>
          </div>
        </Button>
        <Button type="link" onClick={() => handleCopyContact(email)}>
          <div className={styles.copyIconAndText}>
            <CopyIcon />
            <span>Copy Email</span>
          </div>
        </Button>
        <span className={styles.emailTooltip}>{emailTooltip}</span>
      </div>
    );
  };

  const getEmails = () => {
    const emails = connectStatus?.Contact?.Emails ?? [];
    const sortedEmails = getSortedEmailsByEmailTypes({ emails });
    const updatedSortedEmails = emailSeeMore ? sortedEmails?.slice(0, maxContactToBeShownInitially) : sortedEmails;
    return updatedSortedEmails.map(email => {
      const emailRefundStatus = _.get(email, ['IsRefunded'], false);
      const { text, displayedText } = getEllipsedText({ text: email.EmailAddress, maxTextLength: maxEmailLength });
      const emailAddress = displayedText || text;
      const updatedEmail = { ...email, EmailAddress: emailAddress, Type: email.Type };
      const emailTooltip = displayedText ? text : null;
      const popoverContent = getEmailPopoverContent({ email, emailTooltip });
      const mail = (
        <div className={styles.emailContent}>
          <div className={validateStatus(email.ValidityStatus) ? 'invalid-spam-email' : null}>
            {getEmailAddressWithEmailTypeIcon({
              email: updatedEmail,
              popoverContent,
            })}
          </div>
          {email.IsAddedByUser ? <div className={styles.externalContact}>M</div> : null}
          <div className={styles.forwardSlash}>&nbsp;/&nbsp;</div>
        </div>
      );
      return requestCreditFlag || _.get(email, ['IsAddedByUser'], false) || emailRefundStatus ? (
        mail
      ) : (
        <Checkbox className={styles.checkbox} defaultChecked disabled>
          {mail}
        </Checkbox>
      );
    });
  };

  const getPhones = () => {
    const sortedPhones = getSortedPhonesByTypes({ phones });
    const updatedPhones = phoneSeeMore ? sortedPhones?.slice(0, maxContactToBeShownInitially) : sortedPhones;
    return updatedPhones.map(phone => {
      const phoneRefundStatus = _.get(phone, ['IsRefunded'], false);
      const currentPhone = (
        <div className={styles.phoneContent}>
          <div className={validateStatus(phone.ValidityStatus) ? `${styles.invalidNumber}` : null}>{phone.Number}</div>
          {phone.IsAddedByUser ? <div className={styles.externalContact}>M</div> : null}
          <div className={styles.forwardSlash}>&nbsp;/&nbsp;</div>
        </div>
      );
      const phoneDisplayed =
        requestCreditFlag || phone.IsAddedByUser || phoneRefundStatus ? (
          currentPhone
        ) : (
          <Checkbox className={styles.checkbox} defaultChecked disabled>
            {currentPhone}
          </Checkbox>
        );
      return !phone.IsRefunded ? (
        <Popover
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.5}
          overlayStyle={{ paddingBottom: '0px' }}
          placement="topLeft"
          content={
            <div className={styles.actionButtons}>
              {isPulse ? null : (
                <Button type="link" onClick={() => handleCall(phone)}>
                  <div className={styles.callIconAndText}>
                    <CallIcon />
                    <span>Make a call</span>
                  </div>
                </Button>
              )}
              {isPulse ? null : (
                <Button
                  type="link"
                  onClick={() => {
                    handleSendSMS(phone);
                  }}
                >
                  <div className={styles.smsIconAndText}>
                    <SMSIcon />
                    <span>Send Text</span>
                  </div>
                </Button>
              )}

              <Button type="link" onClick={() => handleCopyContact(phone)}>
                <div className={styles.copyIconAndText}>
                  <CopyIcon />
                  <span>Copy Number</span>
                </div>
              </Button>
            </div>
          }
        >
          {phoneDisplayed}
        </Popover>
      ) : (
        phoneDisplayed
      );
    });
  };

  const onExpand = () => {
    setAddEmailFormVisibility(false);
    setAddPhoneFormVisibility(false);
    setDetailsVisibility(!isDetailsVisible);
  };

  const requestCredit = () => {
    setRequestCreditFlag(!requestCreditFlag);
  };

  const onConfirmCreditRequest = () => {
    getRefundForInvalidContacts(jobGuid, candidate.PersonId);
    setRequestCreditFlag(true);
    setRefundedCreditsVisibility(true);
    setTimeout(() => setRefundedCreditsVisibility(false), 5000);
  };

  const getRefundTime = () => {
    const lastRefundTime = getLastRefundTime(connectStatus);
    if (!lastRefundTime) return null;
    if (isRefundedCreditsVisible) return <div>{`Refunded Time: ${lastRefundTime}`}</div>;
    return null;
  };

  const getAvailableContactHeader = () => {
    return isCandidateRequestStatusSuccessful ? (
      <div>Not valid?</div>
    ) : (
      <div className={styles.availableContactText}>Available Contact Details</div>
    );
  };

  const getRequestRefundButton = () => {
    const isButtonDisabled = !checkIfAnyNonRefundedPulledContactsExists(connectStatus);
    return isCandidateRequestStatusSuccessful ? (
      <div className={classNames(styles.disabledRefundRequestButton, { [styles.disabledButton]: isButtonDisabled })}>
        <Button type="link" onClick={requestCredit} disabled={isButtonDisabled} className={styles.linkButton}>
          <span>Request Refund</span>
        </Button>
      </div>
    ) : null;
  };

  const getCreditRefundTextWithIcon = () => {
    return isRefundedCreditsVisible && creditsRefunded[candidate.PersonId] ? (
      <div className={styles.refundedCredit}>
        <Icon type="check-circle" />
        {`  ${creditsRefunded[candidate.PersonId]} credit(s) refunded`}
      </div>
    ) : null;
  };

  const getCreditRefundHeader = () => {
    return requestCreditFlag ? (
      <div className={styles.creditsRefundedHeader}>
        {getAvailableContactHeader()}
        {getRequestRefundButton()}
        {getCreditRefundTextWithIcon()}
        {getRefundTime()}
      </div>
    ) : (
      <div>
        <div className={styles.pullRequestConfirmationContainer}>
          <div className={styles.invalidInformationConfirmation}> Confirm refund request</div>
          <div className={styles.refundRequestButtonContainer}>
            <Button className={styles.refundRequestButton} onClick={requestCredit}>
              Cancel
            </Button>
            <Button type="primary" className={styles.refundRequestButton} onClick={onConfirmCreditRequest}>
              Confirm
            </Button>
          </div>
        </div>
        <Alert
          className={styles.refundRequestAlert}
          message="All the communications related to selected contacts will be deleted when the refund request is complete"
          type="warning"
          showIcon
        />
      </div>
    );
  };

  const emails = connectStatus?.Contact?.Emails ?? [];
  const handleButtonClick = () => setEmailSeeMore(!emailSeeMore);
  const buttonName = emailSeeMore ? `+${emails.length - maxContactToBeShownInitially}` : 'Show Less';
  const isShowButtonVisible = emails?.length > maxContactToBeShownInitially;

  const showButton = isShowButtonVisible ? (
    <span>
      <span className={styles.seeMoreButton} onClick={handleButtonClick} role="presentation">
        {buttonName}
      </span>
      &nbsp;|&nbsp;
    </span>
  ) : null;

  const handlePhoneSeeMoreClick = () => setPhoneSeeMore(!phoneSeeMore);
  const showMorePhoneButtonName = phoneSeeMore ? `+${phones.length - maxContactToBeShownInitially}` : 'Show Less';
  const isShowMorePhoneVisible = phones?.length > maxContactToBeShownInitially;

  const showMorePhoneButton = isShowMorePhoneVisible ? (
    <span>
      <span className={styles.seeMoreButton} onClick={handlePhoneSeeMoreClick} role="presentation">
        {showMorePhoneButtonName}
      </span>
      &nbsp;|&nbsp;
    </span>
  ) : null;

  const getAddEmailModal = () => {
    return isAddEmailFormVisible ? (
      <AddEmail
        addEmail={addEmail}
        height={21}
        isAddEmailFormVisible={isAddEmailFormVisible}
        setAddEmailFormVisibility={setAddEmailFormVisibility}
        emails={emails}
      />
    ) : null;
  };

  const getAddPhoneModal = () => {
    return isAddPhoneFormVisible ? (
      <AddPhoneNumber
        addPhone={addPhone}
        height={21}
        isAddPhoneFormVisible={isAddPhoneFormVisible}
        setAddPhoneFormVisibility={setAddPhoneFormVisibility}
        phones={phones}
      />
    ) : null;
  };

  const availableContactInformationHeader = areContactsAvailable ? (
    getCreditRefundHeader()
  ) : (
    <div className={styles.exclamationIconAndText}>
      <Icon type="exclamation-circle" className={styles.exclamationIcon} />
      <div className={styles.title} data-testid="no-contacts-available">
        No contacts available
      </div>
    </div>
  );

  const shrinkContactSectionButtonName = isDetailsVisible ? 'See less' : 'See more';

  return (
    <div className={styles.contactDetails}>
      <div className={styles.contactDetailsContent}>
        <div>{availableContactInformationHeader}</div>
        {isDetailsVisible ? (
          <div>
            <div className={styles.email}>
              <div className={styles.title}>Email: &nbsp;</div>
              <div className={styles.emailContainer}>
                {getEmails()}
                {getAddEmailModal()}
                <div>
                  <span>{showButton}</span>
                  <span className={styles.addOption} role="presentation" onClick={onEmailAddClick}>
                    Add
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.phone}>
              <div className={styles.title}>Phone: &nbsp;</div>
              <div className={styles.phoneContainer}>
                {getPhones()}
                {getAddPhoneModal()}
                <div className={styles.phoneActionButtons}>
                  <span>{showMorePhoneButton}</span>
                  <span className={styles.addOption} role="presentation" onClick={onPhoneAddClick}>
                    Add
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.expand}>
        <div role="presentation" onClick={onExpand} className={styles.expandIcon}>
          <Button type="link" className={styles.seeMore}>
            {shrinkContactSectionButtonName}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AvailableContactInformation;
