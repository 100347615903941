import React from 'react';
import { connect } from 'react-redux';
import { Popover, Button, Timeline, Skeleton, Icon } from 'antd';
import * as jobActivityActions from '../../../Actions/JobActivityActions';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import { getJobActivity } from '../../../Reducers/JobActivityReducer';
// import { ClockCircleIcon } from '../../../Icons/AryaIcons';
import { getLocaleDateStringWithTimeStamp } from '../../../Utils/RelativeTimeCalculator';
import RetrieveCriteriaModal from '../RetrieveCriteriaModal/RetrieveCriteriaModal';
import styles from './JobReactivationTree.module.scss';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getUsersById } from '../../../Reducers/UserReducer';

const { Item } = Timeline;

const eventTypeToDisplayNameMapper = {
  'AryaSuggestedCandidateSearchCriteria.Created': 'Saved',
  'AryaSuggestedCandidateSearchCriteria.Updated': 'Updated',
  'CandidateSearchCriteria.Created': 'Saved',
  'CandidateSearchCriteria.Updated': 'Updated',
  'CandidateSearchCriteria.Activated': 'Reactivated',
};

const getJobActivityItemContent = ({ activity, usersById, AppName }) => {
  const content = {};
  content.username = [
    'AryaSuggestedCandidateSearchCriteria.Created',
    'AryaSuggestedCandidateSearchCriteria.Updated',
  ].includes(activity.EventName)
    ? `${AppName} recommended`
    : usersById[activity.CreatedBy]?.FirstName ?? activity.CreatedBy;
  content.jobActivityId = activity.RefId;
  content.dateTime = getLocaleDateStringWithTimeStamp(activity.CreatedTime);
  content.event = eventTypeToDisplayNameMapper[activity.EventName];
  if (
    ['AryaSuggestedCandidateSearchCriteria.Created', 'AryaSuggestedCandidateSearchCriteria.Updated'].includes(
      activity.EventName
    )
  )
    return content;
  content.criteriaName = activity.EventData?.CriteriaName;
  return content;
};

const mapStateToProps = (state, props) => ({
  jobActivity: getJobActivity(state, { jobId: props.jobId }),
  fetchJobActivityApiStatus: getApiStatus(state, 'fetchJobActivityStatus'),
  usersById: getUsersById(state),
});

const mapDispatchToProps = {
  fetchJobActivity: jobActivityActions.fetchJobActivity,
  fetchManualSearchCriteriaByRecordId: manualSearchActions.fetchManualSearchCriteriaByRecordId,
};

function JobReactivationTree(props) {
  const {
    jobActivity,
    fetchJobActivity,
    jobId,
    currentJobDetails,
    fetchManualSearchCriteriaByRecordId,
    fetchJobActivityApiStatus,
    usersById,
    AppName,
  } = props;
  const [jobActivityId, setJobActivityId] = React.useState();
  const [isModalVisible, setModalVisibility] = React.useState(false);
  const [isPopoverVisible, setPopoverVisibility] = React.useState(false);

  const { JobTitle: jobTitle } = currentJobDetails;

  const handleOnClick = _jobActivityId => {
    setModalVisibility(true);
    setPopoverVisibility(false);
    fetchManualSearchCriteriaByRecordId({ id: _jobActivityId, jobId });
    setJobActivityId(_jobActivityId);
  };

  const handleCancel = () => {
    setModalVisibility(false);
  };

  const handleVisibleChange = visibilty => {
    if (visibilty) {
      setModalVisibility(false);
      fetchJobActivity({ jobId });
    }
    setPopoverVisibility(visibilty);
  };

  const getJobActivityTimeline = () => {
    return (
      <Timeline>
        {jobActivity.map(activity => {
          const content = getJobActivityItemContent({ activity, jobId, usersById, AppName });
          return (
            <Item color={content.jobId ? 'blue' : 'green'} key={content.jobActivityId} className="test">
              <span>{content.username}</span>
              <span> | {content.dateTime}</span>
              {content.criteriaName ? <span> | {content.criteriaName}</span> : null}
              {content.jobId ? (
                <span>
                  | <span style={{ color: '#1890ff' }}>Job ID: {content.jobId}</span>
                </span>
              ) : null}
              {content.event ? (
                <>
                  <span> | </span>
                  <Button
                    onClick={() => handleOnClick(content.jobActivityId)}
                    type="link"
                    className={styles.eventTypeButton}
                    size="small"
                  >
                    <span className={styles.eventName}>{content.event}</span>
                  </Button>
                </>
              ) : null}
            </Item>
          );
        })}
      </Timeline>
    );
  };

  return (
    <div className={styles.jobActivity}>
      <Popover
        className={styles.popover}
        placement="bottomRight"
        visible={isPopoverVisible}
        mouseEnterDelay={0.5}
        onVisibleChange={handleVisibleChange}
        destroyTooltipOnHide
        content={
          <div className={styles.jobActivityContentWrapper}>
            <Skeleton
              loading={fetchJobActivityApiStatus === 'INPROGRESS' && !jobActivity?.length}
              active
              paragraph={{ rows: 3 }}
            >
              <div className={styles.jobActivityContent}>
                {jobActivity.length ? (
                  getJobActivityTimeline()
                ) : (
                  <div className={styles.emptyCriteriaContent}>No criterias available</div>
                )}
              </div>
            </Skeleton>
          </div>
        }
      >
        <Icon type="clock-circle" theme="filled" className={styles.clockIcon} style={{ color: '#83878C' }} />
      </Popover>
      <RetrieveCriteriaModal
        isModalVisible={isModalVisible}
        handleOnClick={handleOnClick}
        handleCancel={handleCancel}
        jobId={jobId}
        jobTitle={jobTitle}
        jobActivityId={jobActivityId}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobReactivationTree);
export { JobReactivationTree as JobReactivationTreeWithoutStore };
