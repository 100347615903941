import React from 'react';
import _ from 'lodash';
import {
  getCandidatePrimarySource,
  getInternalSourceWithCandidateId,
  getOriginalSource,
  getPushedAtsSource,
  getPortalName,
} from './SourceUtils';
import CandidateAssignedStatus from '../Components/CandidateStatus/CandidateAssignedStatus';
import AppliedSourceTag from '../Components/CandidateListItem/AppliedSourceTag/AppliedSourceTag';

export function getSourceData(candidate, version) {
  let atsSource;
  let pushedCandidateSource;
  let originalSource;
  if (version === 'ats') {
    atsSource = getInternalSourceWithCandidateId(candidate.Sources);
    originalSource = getOriginalSource(candidate.Sources);
  } else {
    pushedCandidateSource = getPushedAtsSource(candidate.Sources);
  }
  return {
    atsSource,
    pushedCandidateSource,
    originalSource,
  };
}

export function checkAccessConfig(candidate, atsConfig) {
  const accessConfiguration = atsConfig?.AccessConfiguration;
  const allowedPortalsToPush = _.get(atsConfig, ['AccessConfiguration', 'AllowedPortals']);
  const candidatePrimarySource = getCandidatePrimarySource(candidate.Sources);
  const isPushAllowed =
    _.get(atsConfig, ['AccessConfiguration', 'IsCreateCandidateAllowed'], false) &&
    _.get(atsConfig, ['AccessConfiguration', 'IsCreateCandidateManuallyAllowed'], false) &&
    (!allowedPortalsToPush ||
      allowedPortalsToPush.length === 0 ||
      allowedPortalsToPush.includes(
        _.get(candidatePrimarySource, ['Portal']) || _.get(candidatePrimarySource, ['Group'])
      ));
  const isRecreateCandidateAllowed = accessConfiguration?.IsRecreateCandidateAllowed;

  return { isPushAllowed, isRecreateCandidateAllowed };
}
export const getCandidateDrawerRedirectedTab = (
  candidate,
  revealActiveChannelSourceName,
  activeSourceName,
  tabName
) => {
  const allowedRedirectedTabs = ['profile', 'work-history'];
  if (!tabName || allowedRedirectedTabs.includes(tabName)) {
    const candidateSourcePortal = getPortalName(candidate.Sources, revealActiveChannelSourceName);
    const redirectTabCandidateSources = ['social', 'passive'];
    return redirectTabCandidateSources.includes(candidateSourcePortal?.toLowerCase()) ||
      redirectTabCandidateSources.includes(activeSourceName?.toLowerCase())
      ? 'work-history'
      : 'profile';
  }
  return tabName;
};
export const scrollToCandidate360Top = candidate360TabRef => {
  const candidate360TabElement = candidate360TabRef.current;
  if (candidate360TabElement)
    candidate360TabElement.scrollTo({
      top: 1, // Adding top as 1 because in case of 0 it will set the candidate drawer header visibility to true
      left: 0,
      behavior: 'smooth',
    });
};
export const getCandidateStatus = (candidate, featureToggleList, candidateContext) => {
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const candidateOrigin = candidate?.CandidateOrigin ?? '';

  const showCandidateAppliedTag = candidate?.AppliedTime && !isPaidJobServiceEnabled;
  const showCandidateAssignedStatus = candidate?.AssignedStatus;
  if (showCandidateAssignedStatus) {
    return <CandidateAssignedStatus assignedStatus={candidate.AssignedStatus} candidateOrigin={candidateOrigin} />;
  }
  if (showCandidateAppliedTag) {
    return <AppliedSourceTag sourceName={candidateOrigin} />;
  }
  return null;
};
export function getCandidateStatusFromSources(candidate) {
  const atsSource = getInternalSourceWithCandidateId(candidate.Sources);
  const candidateStatus = _.get(atsSource, 'Status');
  if (candidateStatus) {
    return <div className="candidate-detail-candidate-status">{candidateStatus}</div>;
  }
  return null;
}
