import React from 'react';
import { Checkbox, Skeleton, Tooltip } from 'antd';
import { emailStatsTitles, emailStatsTooltipTexts } from '../../Utils/EmailStatsUtils';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';

function EmailStatsFilter({
  emailStatsForJob,
  totalCandidate,
  connectDetails,
  isLoading,
  isEmailNotSentFilterHidden = false,
}) {
  const notSentCandidateCount = emailStatsForJob?.find(emailStatus => emailStatus?.Status === 'NotSent')
    ?.ConversationCount;
  const emailNotSentCount = totalCandidate - connectDetails?.length + notSentCandidateCount;

  return (
    <Skeleton loading={isLoading} paragraph={{ rows: 1 }}>
      {emailStatsForJob.map(emailStatus =>
        (!isEmailNotSentFilterHidden && emailStatus?.Status === 'NotSent' && emailNotSentCount > 0) ||
        (emailStatus?.EmailCount > 0 && emailStatus?.ConversationCount > 0) ? (
          <div key={emailStatus.Status}>
            <Tooltip title={emailStatsTooltipTexts[emailStatus?.Status]}>
              <Checkbox style={{ marginBottom: 8 }} value={emailStatus.Status}>
                {emailStatsTitles[emailStatus.Status]}{' '}
                {emailStatus?.Status === 'NotSent' ? (
                  <span style={{ display: 'inline-flex', gap: 2 }}>
                    {`(${emailNotSentCount})`}
                    <span style={{ marginTop: '1px' }}>
                      <InfoIconWithTooltip
                        tooltipTitle={<span>Total number of candidates to whom email not sent</span>}
                      />
                    </span>
                  </span>
                ) : (
                  `(${emailStatus?.ConversationCount} | ${emailStatus?.EmailCount})`
                )}
              </Checkbox>
            </Tooltip>
          </div>
        ) : null
      )}
    </Skeleton>
  );
}

export default EmailStatsFilter;
