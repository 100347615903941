import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Skeleton } from 'antd';
import { getManualSearchCriteriaRecordById } from '../../../Reducers/ManualSearchReducer';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import styles from './RetrieveCriteriaModal.module.scss';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';

const mapStateToProps = (state, props) => ({
  jobActivityPayload: getManualSearchCriteriaRecordById(state, {
    jobId: props.jobId,
    jobActivityId: props.jobActivityId,
  }),
  fetchManualSearchCriteriaRecordApiStatus: getApiStatus(state, 'fetchManualSearchCriteriaRecordApiStatus'),
});

const mapDispatchToProps = {
  setRetrievedJobActivityId: manualSearchActions.setRetrievedJobActivityId,
};

const RetrieveCriteriaModal = props => {
  const {
    handleCancel,
    isModalVisible,
    jobActivityPayload = {},
    jobActivityId,
    setRetrievedJobActivityId,
    jobId,
    jobTitle,
    fetchManualSearchCriteriaRecordApiStatus,
  } = props;

  const { IsAryaSuggested: isAryaSuggested } = jobActivityPayload;
  const titles = jobActivityPayload.Titles?.filter(x => x.IsPreferred === true);
  const prominentSkills = jobActivityPayload.Skills?.Prefers?.filter(
    skill => skill?.IsProminent === true && skill?.IsPreferred === true
  );
  const otherSkills = jobActivityPayload.Skills?.Prefers?.filter(
    i => i?.IsProminent === false && i?.IsPreferred === true
  );
  const skillsExcluded = jobActivityPayload.Skills?.Excludes;
  const resumeLastUpdated = jobActivityPayload.LastUpdatedFrom;
  const educationDegree = jobActivityPayload.Education?.Degree;
  const industry = jobActivityPayload.Industries?.Musts?.filter(industries => industries.IsPreferred === true);
  const indsutryExcluded = jobActivityPayload.Industries?.Excludes;
  const company = jobActivityPayload.Companies?.Musts?.filter(companies => companies.IsPreferred === true);
  const companyExcluded = jobActivityPayload.Companies?.Excludes;
  const portalQueries = jobActivityPayload?.PortalQueries;
  const isPortalExist = portalQueries && Object.keys(portalQueries)?.length;
  const handleClick = () => {
    setRetrievedJobActivityId({ jobActivityId, jobId });
    handleCancel();
  };

  return (
    <Modal
        zIndex={2101}
        visible={isModalVisible}
        title={
          <div className={styles.retrieveCriteriaModalTitle}>
            <span style={{ color: '#00C760', fontSize: '24px' }}>{jobTitle}</span> -
            <span style={{ fontSize: '20px' }}>These are the criteria saved !</span>
          </div>
        }
        onCancel={handleCancel}
        width="890px"
        footer={null}
        bodyStyle={{ padding: '0px' }}
      >
        <div className={styles.retrieveCriteriaModal}>
          <Skeleton loading={fetchManualSearchCriteriaRecordApiStatus === 'INPROGRESS'} active paragraph={{ rows: 10 }}>
            <div className={styles.retrieveCriteriaModalContent}>
              <div className={styles.jobTitle}>
                <span className={styles.text}>Job Title:</span>
                <br />
                {titles?.length ? titles?.map(title => title.Title)?.join(', ') : `NA`}
              </div>
              <div className={styles.skills}>
                <span className={styles.text}> Keywords:</span>
                <br />
                <span className={styles.text}>Prominent keywords-</span>{' '}
                {prominentSkills?.length ? prominentSkills?.map(skills => skills.SkillName)?.join(', ') : `NA`}
                <br />
                <span className={styles.text}>Other preferences-</span>{' '}
                {otherSkills?.length ? otherSkills?.map(skills => skills.SkillName)?.join(', ') : `NA`}
                <br />
                <span className={styles.text}>Must Haves-</span>{' '}
                {jobActivityPayload.Skills?.Musts ? jobActivityPayload.Skills.Musts : `NA`}
                <br />
                <span className={styles.text}>Exclude-</span>{' '}
                {skillsExcluded?.length ? skillsExcluded?.join(', ') : `NA`}
              </div>
              {!isAryaSuggested ? (
                <div className={styles.resumeLastUpdated}>
                  <span className={styles.text}>Resume Last Updated:</span>
                  <br />
                  <span className={styles.text}>Within-</span> {resumeLastUpdated ? `${resumeLastUpdated} days` : `NA`}
                </div>
              ) : null}

              {!isAryaSuggested ? (
                <div className={styles.education}>
                  <span className={styles.text}>Education:</span>
                  <br />
                  <span className={styles.text}>Education/Degree-</span>{' '}
                  {educationDegree ? educationDegree?.join(', ') : `NA`}
                  <br />
                  <span className={styles.text}>School/University-</span>{' '}
                  {jobActivityPayload?.Education?.School ? jobActivityPayload?.Education?.School : `NA`}
                </div>
              ) : null}
              {!isAryaSuggested ? (
                <div className={styles.employment}>
                  <span className={styles.text}>Employment :</span>
                  <br />
                  <span className={styles.text}>Industry-</span>{' '}
                  {industry?.length ? industry?.map(industries => industries.Name)?.join(', ') : `NA`}
                  <br />
                  <span className={styles.text}>Industry exclusion-</span>{' '}
                  {indsutryExcluded ? indsutryExcluded?.join(', ') : `NA`}
                  <br />
                  <span className={styles.text}>Company-</span>{' '}
                  {company ? company?.map(companies => companies.Name)?.join(', ') : `NA`}
                  <br />
                  <span className={styles.text}>Company exclusion-</span>{' '}
                  {companyExcluded ? companyExcluded.join(', ') : `NA`}
                </div>
              ) : null}
            </div>
            <div className={styles.retrieveCriteriaModalFooter}>
              {isPortalExist ? (
                <div className={styles.note}>
                  Note: Filters at job bord level are applied to {Object.keys(portalQueries)?.join(', ')}
                </div>
              ) : (
                <div></div>
              )}
              <Button type="primary" shape="round" size="large" className={styles.button} onClick={handleClick}>
                Edit/Use these criteria
              </Button>
            </div>
          </Skeleton>
        </div>
      </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveCriteriaModal);
export { RetrieveCriteriaModal as RetrieveCriteriaModalWithoutStore };
