import React from 'react';
import { Checkbox, Form } from 'antd';
import styles from './FilterAryaSearch.module.scss';

const FilterAryaSearch = props => {
  const { form, candidateCollapsedFilters, inlineStyles } = props;

  const tags = [
    { label: 'Shortlisted', value: 'shortlisted' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Viewed by me', value: 'viewedByMe' },
    { label: 'Viewed by others', value: 'viewedByOthers' },
    { label: 'Contacted', value: 'contacted' },
  ];

  const collapsedFilterField = 'collapsedFilter';

  const selectedKeys = form.getFieldValue(collapsedFilterField) || [];

  const isAllSelected = tags.length === selectedKeys.length;

  const onCheckAllChange = e => {
    if (e.target.checked) form.setFieldsValue({ [collapsedFilterField]: tags.map(tag => tag.value) });
    else form.setFieldsValue({ [collapsedFilterField]: [] });
  };

  return (
    <div className={styles.candidateFilterPopoverContent} style={inlineStyles}>
      <Form>
        <div className={styles.candidateFilterPopoverContent} style={inlineStyles}>
          <div className={styles.candidateFilterPopoverContentHeader}>Collapse Candidates</div>
          <Form.Item className={styles.aryaFilterFormItem}>
            <Checkbox
              onChange={onCheckAllChange}
              checked={isAllSelected}
              key="selectAll"
              data-testid="selectAll-checkbox"
            >
              Select all
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <div className="candidate-filter-tags">
              <div className={styles.tagFilterPopoverCheckboxes}>
                <div className={styles.aryaSearchCheckboxGroup}>
                  {form.getFieldDecorator('collapsedFilter', { initialValue: candidateCollapsedFilters })(
                    <Checkbox.Group>
                      {tags.map(tag => (
                        <Checkbox value={tag.value} key={tag.value}>
                          {tag.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  )}
                </div>
              </div>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default FilterAryaSearch;
