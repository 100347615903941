import React from 'react';
import InfoIconWithTooltip from '../../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import LocationWrapper from '../../../CompoundComponents/LocationWrapper/LocationWrapper';
import Country from '../../../CompoundComponents/LocationWrapper/Country/Country';
import Location from '../../../CompoundComponents/LocationWrapper/Location/Location';
import Zipcode from '../../../CompoundComponents/LocationWrapper/Zipcode/Zipcode';
import RemoteJob from '../../../CompoundComponents/LocationWrapper/RemoteJob/RemoteJob';
import styles from './JobLocationInput.module.scss';

function JobLocationInput(props) {
  const {
    form,
    isPaidJobServiceEnabled,
    isCountryWideSearchEnabled,
    getFormItemLabelText,
    countryOptions,
    jobDetails,
    AppName,
  } = props;
  const isHidden = form.getFieldValue('LocationType') !== 'Location';
  const initialCountryOptions = countryOptions?.find(option => option.Iso2Code === jobDetails.CountryCode)
    ? jobDetails.CountryCode ?? 'US'
    : jobDetails.Country ?? 'US';
  const layers = 'neighbourhood,borough,locality,localadmin,county,macrocounty';
  return (
    <div style={{ display: isHidden ? 'none' : 'block' }}>
      <LocationWrapper {...props} layers={layers}>
        <div className={styles.locationWrapper}>
          <div className={styles.country}>
            <Country label={getFormItemLabelText('Country')} initialValue={initialCountryOptions} />
          </div>
          <div className={styles.location}>
            <Location
              label={getFormItemLabelText('Location')}
              initialValue={jobDetails.Location}
              required={isPaidJobServiceEnabled}
              disabled={isCountryWideSearchEnabled}
              placeholder="City, State"
            />
          </div>
          <div className={styles.zipcode}>
            <Zipcode
              label={getFormItemLabelText('Postal Code')}
              disabled={isCountryWideSearchEnabled}
              required={isPaidJobServiceEnabled}
              initialValue={jobDetails.Zipcode}
            />
          </div>
          {!isPaidJobServiceEnabled ? (
            <>
              <div className={styles.remoteJobInput}>
                <RemoteJob initialValue={jobDetails.IsRemoteJob} />
              </div>
              <span className={styles.remoteJobInfo}>
                <span className={styles.remoteJob}>Remote Job</span>
                <InfoIconWithTooltip tooltipTitle={`${AppName} will source throughout the country`} />
              </span>
            </>
          ) : null}
        </div>
      </LocationWrapper>
    </div>
  );
}

export default JobLocationInput;
