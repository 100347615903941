import React from 'react';
import _ from 'lodash';
import { Empty } from 'antd';
import CallHistoryItem from './CallHistoryItem';
import './CallHistory.scss';
import { CallIcon } from '../../Icons/AryaIcons';

function CallHistory({ callHistory, currentUserEmailId, connectUsersById }) {
  if (!callHistory) {
    return null;
  }
  const { Order: list, ByCallId: byId } = callHistory;
  if (!list || !byId) return null;
  return (
    <div className="call-history">
      <div className="title">Call History</div>
      {list.length ? (
        <div className="list">
          {list.map(id => (
            <CallHistoryItem
              key={id}
              callDetails={byId[id]}
              currentUserEmailId={currentUserEmailId}
              createdUser={_.get(connectUsersById, byId[id].CreatedBy, {})}
            />
          ))}
        </div>
      ) : (
        <div>
          <Empty description="No Call History Available" image={<CallIcon className="empty-call-icon" />} />
        </div>
      )}
    </div>
  );
}

export default CallHistory;
