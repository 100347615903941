import React from 'react';
import { Icon, Tooltip } from 'antd';
import './Style.scss';
import { actionTypes } from '../../../Utils/WorkflowUtils';
import {
  WorkflowActionsSvgIcon,
  WorkflowDelayActionSvgIcon,
  WorkflowRuleActionSvgIcon,
  WorkflowEndActionSvgIcon,
} from '../../../Icons/AryaIcons';

const getNodeColor = type => {
  switch (type) {
    case 'Action':
    case actionTypes.SendEmail:
    case actionTypes.SendSMS:
    case actionTypes.AddTag:
    case actionTypes.AddCustomField:
      return '#722ED1';
    case 'Start':
      return '#13C26B';
    case actionTypes.Delay:
      return '#FAAD14';
    case actionTypes.Decision:
      return '#007BFF';
    case actionTypes.End:
      return '#F5222D';
    default:
      return null;
  }
};

const getIcon = type => {
  switch (type) {
    case 'Action':
    case actionTypes.SendEmail:
    case actionTypes.SendSMS:
    case actionTypes.AddTag:
    case actionTypes.AddCustomField:
      return <WorkflowActionsSvgIcon />;
    case actionTypes.Delay:
      return <WorkflowDelayActionSvgIcon />;
    case actionTypes.Decision:
      return <WorkflowRuleActionSvgIcon />;
    case actionTypes.End:
      return <WorkflowEndActionSvgIcon />;
    default:
      return null;
  }
};

export const BaseNode = ({ type, data, isCloseIconHidden, onNodeClick, onCloseIconClick, customClassName }) => {
  const { title, description, disabled } = data;
  const content = (
    <div className="NodeContent">
        <div className="NodeTitle" style={{ color: getNodeColor(type) }}>
          {getIcon(type)} &nbsp;
          {title}
        </div>
        <Tooltip placement="bottom" title={description}>
          <div className="NodeDesc">{description}</div>
        </Tooltip>
      </div>
  );
  return (
    <div
      aria-disabled={disabled}
      className={`NodeInnerWrapper ${customClassName}`}
      style={{ color: getNodeColor(type) }}
      {...(onNodeClick && { onClick: () => onNodeClick(type, data) })}
    >
      {content}
      {isCloseIconHidden ? null : (
        <Icon
          type="close"
          style={{ fontSize: '12px', color: '#83878C' }}
          className="closeIcon"
          onClick={onCloseIconClick}
        />
      )}
    </div>
  );
};

export const EmptyBaseNode = () => {
  return <div className="EmptyNodeInnerWrapper"></div>;
};
