import React from 'react';
import { Button, Skeleton } from 'antd';
import styles from './SavedReportQueries.module.scss';
import RecruiterReportsContainer from '../../../../Containers/ReportPortalContainer/RecruiterReportsContainer/RecruiterReportsContainer';
import CandidateReportsContainer from '../../../../Containers/ReportPortalContainer/CandidateReportsContainer/CandidateReportsContainer';
import JobReportsContainer from '../../../../Containers/ReportPortalContainer/JobReportsContainer/JobReportsContainer';
import ReportQueriesListWrapper from './ReportQueriesListWrapper/ReportQueriesListWrapper';
import { getDate, getReportViewType, savedQueryMapper } from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';

export default function SavedReportQueries(props) {
  const {
    fetchSavedQueriesApiStatus,
    fetchSavedReportQueries,
    savedReportQueries,
    setSavedQueriesPageSize,
    setSavedQueriesPageNumber,
    pageSize,
    pageNumber,
    deleteSavedQuery,
    deleteSavedQueryApiStatus,
    fetchClients,
    fetchRecruiters,
    fetchOrganizations,
    clearDeleteSavedQueryApiStatus,
    getReportColumns,
    shareReportSavedQuery,
    fetchUsersForShare,
    fetchUsers,
  } = props;

  const [activeReportType, setActiveReportType] = React.useState('saved');
  const [runSavedQueryFlag, setRunSavedQueryFlag] = React.useState(false);
  const [savedQueryParam, setSavedQueryParam] = React.useState();
  const [reportLoadingStatus, setReportLoadingStatus] = React.useState(false);

  React.useEffect(() => {
    fetchSavedReportQueries({ from: 0, size: pageSize });
  }, []);

  const resetState = () => {
    setRunSavedQueryFlag(false);
    setSavedQueryParam();
    setActiveReportType('saved');
  };

  const fetchQueryDetails = async queryDetails => {
    setReportLoadingStatus(true);
    const savedQuery = JSON.parse(queryDetails?.ReportQuery);
    const mappedSavedQuery = savedQueryMapper(savedQuery);
    const type = getReportViewType(queryDetails?.ReportType);
    const { recruiters, clients, orgId, dateRange } = mappedSavedQuery;
    const recruiterIds = recruiters?.filter(recruiter => recruiter !== 'ALL');
    const clientIds = clients?.filter(client => client !== 'ALL');
    const { fromDate, toDate } = getDate({ dateRange });

    const promises = [];
    const additionalPromises = [];
    additionalPromises.push(
      fetchClients(
        {
          From: 0,
          Size: 200,
          OrgId: orgId,
        },
        type
      )
    );
    additionalPromises.push(
      fetchRecruiters(
        {
          from: 0,
          size: 200,
          orgId,
        },
        type
      )
    );

    if (clientIds) {
      promises.push(
        fetchClients(
          {
            From: 0,
            Size: 200,
            OrgId: orgId,
            VaultClientIds: [...clientIds],
          },
          type,
          'MORE'
        )
      );
    }
    if (recruiterIds) {
      promises.push(
        fetchRecruiters(
          {
            from: 0,
            size: 200,
            orgId,
            userIds: recruiterIds,
          },
          type,
          'MORE'
        )
      );
    }
    if (orgId) {
      promises.push(fetchOrganizations({ page: 1, orgIds: [orgId] }));
    }
    if (fromDate && toDate) {
      const filter = {
        ReportType: queryDetails?.ReportType,
        OrgId: orgId,
        FromDate: fromDate,
        ToDate: toDate,
      };
      promises.push(getReportColumns(filter, type));
    }
    await Promise.all(additionalPromises);
    await Promise.all(promises);
    setReportLoadingStatus(false);
  };

  const useSavedQuery = async (queryDetails = {}) => {
    await fetchQueryDetails(queryDetails);
    setSavedQueryParam(queryDetails);
    setActiveReportType(queryDetails?.ReportType);
  };

  const loadReportQuery = async (queryDetails = {}) => {
    await useSavedQuery(queryDetails);
    setRunSavedQueryFlag(true);
  };

  const onSaveQuerySuccess = () => {
    fetchSavedReportQueries({ from: 0, size: 10 });
    setSavedQueriesPageNumber(1);
    resetState();
  };

  const getReportsContainer = () => {
    switch (activeReportType?.toLowerCase()) {
      case 'recruiter':
        return (
          <RecruiterReportsContainer
            savedQueryConfig={{ runSavedQueryFlag, queryDetails: savedQueryParam }}
            onSaveQuerySuccess={onSaveQuerySuccess}
          />
        );
      case 'candidate':
        return (
          <CandidateReportsContainer
            savedQueryConfig={{ runSavedQueryFlag, queryDetails: savedQueryParam }}
            onSaveQuerySuccess={onSaveQuerySuccess}
          />
        );
      case 'job':
        return (
          <JobReportsContainer
            savedQueryConfig={{ runSavedQueryFlag, queryDetails: savedQueryParam }}
            onSaveQuerySuccess={onSaveQuerySuccess}
          />
        );
      case 'saved':
      default:
        return (
          <ReportQueriesListWrapper
            pageSize={pageSize}
            pageNumber={pageNumber}
            setSavedQueriesPageSize={setSavedQueriesPageSize}
            setSavedQueriesPageNumber={setSavedQueriesPageNumber}
            fetchSavedReportQueries={fetchSavedReportQueries}
            fetchSavedQueriesApiStatus={fetchSavedQueriesApiStatus}
            loadReportQuery={loadReportQuery}
            editSavedQuery={useSavedQuery}
            deleteSavedQuery={deleteSavedQuery}
            deleteSavedQueryApiStatus={deleteSavedQueryApiStatus}
            savedReportQueries={savedReportQueries}
            clearDeleteSavedQueryApiStatus={clearDeleteSavedQueryApiStatus}
            shareReportSavedQuery={shareReportSavedQuery}
            fetchUsersForShare={fetchUsersForShare}
            fetchUsers={fetchUsers}
          />
        );
    }
  };

  return (
    <div>
      <div className={styles.backButton}>
        {activeReportType !== 'saved' ? (
          <Button size="small" shape="round" icon="arrow-left" onClick={resetState}>
            Back
          </Button>
        ) : null}
      </div>
      <Skeleton
        active
        paragraph={{ rows: 16 }}
        loading={reportLoadingStatus}
        className={styles.savedReportQueriesLoader}
      >
        {getReportsContainer()}
      </Skeleton>
    </div>
  );
}

SavedReportQueries.defaultProps = {
  pageSize: 10,
};
