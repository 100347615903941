import React from 'react';
import uuid from 'uuid';
import { Form, Icon } from 'antd';
import classNames from 'classnames';
import InputSearch from '../../../../../Components/InputSearch/InputSearch';
import CustomCheckableTags from '../../../../../Components/Common/CustomCheckableTags/CustomCheckableTags';
import MustHavesDisplay from '../../../../../CompoundComponents/MustHavesWrapper/MustHavesDisplaySection';
import MustHavesInput from '../../../../../CompoundComponents/MustHavesWrapper/MustHavesInput';
import MustHavesWrapper from '../../../../../CompoundComponents/MustHavesWrapper/MustHavesWrapper';
import { DownArrowIcon, UpArrowIcon } from '../../../../../Icons/AryaIcons';
import TextWithInfoIcon from '../../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import styles from './MoreSkillsSection.module.scss';

const { Item } = Form;

const MustHaves = ({ value, onChange }) => (
  <MustHavesWrapper mustHaves={value} onChange={onChange}>
    <MustHavesInput />
    <div className={styles.mustHavesDisplay}>
      <MustHavesDisplay />
    </div>
  </MustHavesWrapper>
);

const ExcludeSkills = props => {
  const { value, onChange } = props;
  return (
    <CustomCheckableTags
      tags={value}
      onChange={onChange}
      tagsClassName={isSelected => `${styles.skillTag} ${isSelected ? styles.selected : styles.unselected}`}
      className={styles.excludeSkillTagsContainer}
    />
  );
};

export default function MoreSkillsSection(props) {
  const { form, defaultMustHaves, defaultExcludes = [] } = props;
  const [isMoreSkillsSectionVisible, setMoreSkillsSectionVisiblity] = React.useState(false);
  const [excludeSkillInput, setExcludeSkillInput] = React.useState('');

  const handleSearch = skill => {
    const currentExcludedSkills = form.getFieldValue('ExcludedSkills') ?? [];
    const lowerCasedSkill = skill.toLowerCase();
    const updatedExcludedSkills = currentExcludedSkills
      .filter(x => x.name.toLowerCase() !== lowerCasedSkill)
      .concat({
        id: uuid.v4(),
        isSelected: true,
        name: skill,
      });
    form.setFieldsValue({ ExcludedSkills: updatedExcludedSkills });
    setExcludeSkillInput('');
  };
  const containerClassNames = isMoreSkillsSectionVisible ? styles.displayBlock : styles.displayInlineBlock;
  const inputContainerClassNames = isMoreSkillsSectionVisible ? styles.displayBlock : styles.displayNone;
  React.useEffect(() => {
    if ((defaultMustHaves && Object?.keys(defaultMustHaves)?.length) || defaultExcludes?.length) {
      setMoreSkillsSectionVisiblity(true);
    }
  }, []);
  return (
    <div className={classNames(styles.moreSkillsSection, containerClassNames)}>
      <div
        className={styles.showMoreLessButton}
        role="button"
        tabIndex={0}
        onClick={() => setMoreSkillsSectionVisiblity(!isMoreSkillsSectionVisible)}
        onKeyPress={() => setMoreSkillsSectionVisiblity(!isMoreSkillsSectionVisible)}
      >
        <span>See {isMoreSkillsSectionVisible ? 'less' : 'more'} options</span>
        {isMoreSkillsSectionVisible ? <UpArrowIcon /> : <DownArrowIcon />}
      </div>

      <div className={classNames(styles.addSkills, inputContainerClassNames)}>
        <TextWithInfoIcon
          text={<div className={styles.label}>Exclude</div>}
          tooltipTitle="Exclude Keywords feature is not available for Monster Power"
        />
        <InputSearch
          placeholder="+ Add Keywords"
          className={styles.addSkillsInput}
          enterButton={<Icon type="plus" style={{ display: 'flex' }} />}
          onSearch={handleSearch}
          value={excludeSkillInput}
          onChange={setExcludeSkillInput}
        />

        {form.getFieldDecorator('ExcludedSkills', { initialValue: defaultExcludes })(<ExcludeSkills />)}
      </div>

      <div className={inputContainerClassNames}>
        <TextWithInfoIcon
          text={<div className={styles.label}>Must Haves</div>}
          tooltipTitle="Adding multiple Must haves will result in yielding fewer results."
        />
        <Item colon={false} className={styles.mustsInput}>
          {form.getFieldDecorator('MustHaves', { initialValue: defaultMustHaves })(<MustHaves />)}
        </Item>
      </div>
    </div>
  );
}
