import { apiV3Client } from './BaseRepository';

export function deleteNote({ noteId }) {
  return apiV3Client.delete(`notes/${noteId}`);
}

export function updateNote({ noteId, payload }) {
  return apiV3Client.put(`notes/${noteId}`, {
    Context: payload.Context,
    Description: payload.Description,
    SubjectLine: payload.SubjectLine,
  });
}

export const fetchBulkNotes = ({ jobId, filter }) => {
  return apiV3Client.post(`jobs/${jobId}/notes/_bulksearch`, {
    From: filter.from,
    Size: filter.size,
    CandidateIds: filter.candidateIds,
  });
};

export const getNotes = ({ jobId, candidateId }) => {
  let url = '';
  if (jobId && candidateId) url = `notes?jobId=${jobId}&candidateId=${candidateId}`;
  else if (jobId) url = `notes?jobId=${jobId}`;
  else url = `notes?candidateId=${candidateId}`;
  return apiV3Client.get(url);
};

export const createNote = ({ jobId, candidateId, payload }) => {
  const filter = {
    Context: payload.Context,
    Description: payload.Description,
    SubjectLine: payload.SubjectLine,
  };
  if (jobId && candidateId) return apiV3Client.post(`/notes?jobId=${jobId}&candidateId=${candidateId}`, filter);
  if (jobId) return apiV3Client.post(`/notes?jobId=${jobId}`, filter);
  return apiV3Client.post(`/notes?candidateId=${candidateId}`, filter);
};
