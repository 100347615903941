import { defineMessages } from 'react-intl';

const messages = defineMessages({
  off: {
    id: 'AryaSourceButton.off',
    defaultMessage: 'Off',
  },
  sourcing: {
    id: 'AryaSourceButton.sourcing',
    defaultMessage: 'Sourcing',
  },
  review: {
    id: 'AryaSourceButton.review',
    defaultMessage: 'Review',
  },
  sourced: {
    id: 'AryaSourceButton.sourced',
    defaultMessage: 'Sourced',
  },
});

export default messages;
