import React from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { getEmailTemplateIdentifier } from '../Common/EmailTemplateIdentifier/EmailTemplateIdentifier';
import '../../Containers/JobSettings/JobSettings.scss';
import SelectDropdownWithPagination from '../Common/SelectDropdownWithPagination/SelectDropdownWithPagination';

const { Option } = Select;

class ConnectTemplateSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fetchTemplates = debounce(this.fetchTemplates, 600);
    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.fetchTemplatesInstantly = debounce(this.fetchTemplatesInstantly, 150);
    this.renderOnlySelect = this.renderOnlySelect.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    const updatedState = {};
    const { selectedTemplateId, selectedTemplateName } = prevState;
    const { selectedTemplateId: updatedTemplateId, selectedTemplateName: updatedTemplateName } = props;
    if (!selectedTemplateId && updatedTemplateId !== selectedTemplateId) {
      updatedState.selectedTemplateId = updatedTemplateId;
      updatedState.selectedTemplateName = updatedTemplateName;
    } else if (updatedTemplateName !== selectedTemplateName || updatedTemplateId !== selectedTemplateId) {
      updatedState.selectedTemplateName = updatedTemplateName;
      updatedState.selectedTemplateId = updatedTemplateId;
    }
    return updatedState;
  }

  componentDidMount() {
    const { emailTemplates, setDefaultEmailTemplateValues, candidateContext = 'job' } = this.props;
    if (setDefaultEmailTemplateValues && emailTemplates?.length > 0 && candidateContext === 'job')
      setDefaultEmailTemplateValues();
  }

  fetchTemplates = (filter, isFilterUpdated) => {
    const { fetchTemplates, isPulse, excludeTags } = this.props;
    this.setState({
      SearchTerm: filter.searchTerm,
    });
    fetchTemplates({ ...filter, IncludeSystemTemplate: !isPulse, ExcludeTags: excludeTags }, isFilterUpdated);
  };

  fetchTemplatesInstantly = filter => {
    const { fetchTemplates, isPulse, excludeTags } = this.props;
    this.setState({
      SearchTerm: filter.SearchTerm,
    });
    fetchTemplates({ ...filter, IncludeSystemTemplate: !isPulse, ExcludeTags: excludeTags }, false);
  };

  handleChange = (value, option) => {
    const newSelectedTemplateId = option && option.key ? option.key : undefined;
    const newSelectedTemplateName = option ? option.props.children[0].props.children : undefined;
    const { selectedTemplateId } = this.state;
    let isFilterUpdated = false;
    if (selectedTemplateId !== newSelectedTemplateId) {
      isFilterUpdated = true;
    }
    this.setState({
      selectedTemplateId: newSelectedTemplateId,
      selectedTemplateName: newSelectedTemplateName,
    });

    if (!value) {
      this.fetchTemplates({ SearchTerm: value, From: 0 }, isFilterUpdated);
      this.setState({
        selectedTemplateId: undefined,
        selectedTemplateName: undefined,
      });
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(newSelectedTemplateId);
    }
  };

  handleScroll(e) {
    const { SearchTerm } = this.state;
    const { templateIds, templatesCount } = this.props;
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      templateIds.length < templatesCount
    ) {
      this.fetchTemplatesInstantly({ SearchTerm, From: templateIds.length });
    }
  }

  renderOnlySelect() {
    const {
      templatesById,
      templateIds,
      cssClassname,
      placeholderValue,
      isDisabled,
      isClearFieldAllowed,
      templatesCount,
      templateFetchApiStatus,
      inlineSelectDropdownStyles,
    } = this.props;
    const { selectedTemplateId, selectedTemplateName } = this.state;
    let initialValue = !selectedTemplateName ? 'This template is not shared with you' : selectedTemplateName;
    if (!selectedTemplateId && !selectedTemplateName) {
      initialValue = undefined;
    }
    return (
      <SelectDropdownWithPagination
        showSearch
        placeholder={placeholderValue}
        className={cssClassname}
        value={initialValue}
        totalDropdownOptionsCount={templatesCount}
        currentDropdownOptionsCount={templateIds.length}
        defaultActiveFirstOption={false}
        filterOption
        isLoading={templateFetchApiStatus === 'INPROGRESS'}
        optionFilterProp="label"
        fetchDropdownOptions={value => this.fetchTemplates(value, true)}
        onChange={this.handleChange}
        onPopupScroll={this.handleScroll}
        isDisabled={isDisabled}
        allowClear={isClearFieldAllowed}
        dropdownClassName="connect-templates-dropdown"
        inlineStyles={inlineSelectDropdownStyles}
      >
        {selectedTemplateId && selectedTemplateName ? (
          <Option key={selectedTemplateId} value={selectedTemplateId} label={templatesById[selectedTemplateId].Name}>
            <span className="connect-template-name">{selectedTemplateName}</span>
            {getEmailTemplateIdentifier(
              templatesById[selectedTemplateId].IsDrip,
              templatesById[selectedTemplateId].IsSystemTemplate,
              templatesById[selectedTemplateId].IsDefault
            )}
          </Option>
        ) : null}
        {templateIds.map(templateId =>
          templateId !== selectedTemplateId ? (
            <Option
              key={templateId}
              value={templateId}
              title={templatesById[templateId].Name}
              label={templatesById[templateId].Name}
            >
              <span className="connect-template-name">{templatesById[templateId].Name}</span>
              {getEmailTemplateIdentifier(
                templatesById[templateId].IsDrip,
                templatesById[templateId].IsSystemTemplate,
                templatesById[templateId].IsDefault
              )}
            </Option>
          ) : null
        )}
      </SelectDropdownWithPagination>
    );
  }

  render() {
    const { label } = this.props;
    if (label) {
      return (
        <div className="template-selector-with-label-wrapper">
          <div className="job-settings-template-from">From</div>
          {this.renderOnlySelect()}
        </div>
      );
    }
    return <div>{this.renderOnlySelect()}</div>;
  }
}

export default ConnectTemplateSelect;
