import React from 'react';
import { Tooltip } from 'antd';
import styles from './CandidateSourceAndName.module.scss';
import SourceImageIcon from '../../CandidateListItem/SourceImageIcon/SourceImageIcon';
import {
  getInternalSourceWithCandidateId,
  getAtsSource,
  getSourceDisplayName,
  getPushedAtsSource,
  getCandidateSource,
  getPortalName,
} from '../../../Utils/SourceUtils';
import { isInternalIconVisible } from '../../../Utils/DownloadedCandidatesSourceUtils';
import CandidateViewIconV2 from '../../../Icons/CandidateViewIconV2';
import eventTypes from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import CandidateCardShowDuplicate from '../CandidateCardShowDuplicate/CandidateCardShowDuplicate';

function CandidateSourceAndName(props) {
  const {
    candidate = {},
    userConfig = {},
    version,
    whiteLabelInfo,
    featureToggleList,
    isCandidateViewIconVisible,
    openCandidateView,
    isCandidateGroupHead,
    duplicateCandidateIds,
  } = props;

  const { Name: candidateName, Sources: candidateSources } = candidate;
  const { RevealPortalsUnderGroup: revealPortalsUnderGroup, WhiteGloveServiceProgress: whiteGloveServiceProgress } =
    featureToggleList;
  const { ShowVaultName: shouldShowVaultName } = userConfig;
  const showVaultName = shouldShowVaultName ?? false;
  const revealActiveChannelSourceName = revealPortalsUnderGroup.IsEnabled;
  const isSRPulseUser = whiteGloveServiceProgress?.IsEnabled;

  const isAtsVersion = version === 'ats';

  const atsSource = getAtsSource(candidateSources);
  const candidateSource = isAtsVersion
    ? getInternalSourceWithCandidateId(candidateSources)
    : getPushedAtsSource(candidateSources);

  const sourceDisplayName = getSourceDisplayName(
    atsSource || getCandidateSource(candidate),
    userConfig,
    showVaultName,
    {},
    whiteLabelInfo,
    revealActiveChannelSourceName
  );

  const candidatePortalName = getPortalName(candidateSources, revealActiveChannelSourceName);

  const candidateSourcePortal =
    candidatePortalName?.toLowerCase() === 'internal' && isAtsVersion
      ? getSourceDisplayName(candidateSource,userConfig, showVaultName, {}, whiteLabelInfo, revealActiveChannelSourceName)
      : sourceDisplayName;

  const showInternalIcon = isInternalIconVisible(candidate, isSRPulseUser);
  const sourceIconStyle = { fontSize: 18, display: 'flex' };

  const showPortalIcons = (
    <div className={styles.overlappingSourceIcons}>
      <div className={styles.sourceImageContainer}>
        <SourceImageIcon
          tooltipVisibility
          placement="top"
          sourceName={candidateSourcePortal}
          sourcePortal={candidatePortalName}
          iconStyle={sourceIconStyle}
          showVaultName={showVaultName}
        />
      </div>
      {showInternalIcon && (
        <div className={styles.overlappingSourceImage}>
          <SourceImageIcon
            tooltipVisibility
            placement="top"
            sourceName="Internal"
            sourcePortal="InternalForDownloadedCandidates"
            iconStyle={sourceIconStyle}
            showVaultName={showVaultName}
          />
        </div>
      )}
    </div>
  );

  const handleCandidateView = e => {
    e.stopPropagation();
    openCandidateView(candidate);
  };

  const candidateViewIcon = isCandidateViewIconVisible ? (
    <CandidateViewIconV2
      onClick={handleCandidateView}
      sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateView.viewInNewTab, candidate)}
    />
  ) : null;

  const candidateShowDuplicateOption =
    isCandidateGroupHead && duplicateCandidateIds.length ? <CandidateCardShowDuplicate /> : null;

  return (
    <div className={styles.candidateSourceAndNameWrapper}>
      <div className={styles.candidateSourceAndName}>
        {showPortalIcons}
        <Tooltip title={candidateName}>
          <span>{candidateName}</span>
        </Tooltip>
      </div>
      {candidateShowDuplicateOption}
      {candidateViewIcon}
    </div>
  );
}

export default CandidateSourceAndName;
