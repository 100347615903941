import React from 'react';
import { Affix } from 'antd';
import ContactInformationContainer from '../../Containers/CandidateDrawer/ContactInformationContainer';
import CandidateProfileContactsContainer from '../../Containers/CandidateDrawer/CandidateProfileContactsContainer';
import styles from './CandidateContactsWrapper.module.scss';
import ComposeEmailAndMessageButton from '../Connect/ComposeEmailAndMessageButton/ComposeEmailAndMessageButton';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';

function CandidateContactsWrapper(props) {
  const {
    candidate,
    jobGuid,
    jobId,
    currentJobDetails,
    jobCountryCode,
    toggleBotConfigDrawer,
    candidateContext,
    activeSubTab,
    onSubTabClick,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
    creditsRefunded,
    openSipCallWindowsApp,
    isContactTabRedirection,
    isAlertMessage,
    isConnectIconsEnabled,
    isAudioReadVisible,
    isMessageReadVisible,
    isEmailReadVisible,
    composeEmailType,
    setComposeEmailType,
  } = props;

  const ref = React.useRef(null);
  const mailRef = React.useRef(null);
  const smsRef = React.useRef(null);
  const callRef = React.useRef(null);

  const [isComposeEmailModalVisible, setEmailComposeModalVisibility] = React.useState(false);
  const [isComposeMessageModalVisible, setMessageComposeModalVisibility] = React.useState(false);
  const [currentSelectedEmail, setCurrentSelectedEmail] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    ref.current.addEventListener('scroll', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);

  const contextualEmailCount = mailRef.current?.state.allEmails?.length || 0;
  const contextualMessageCount = smsRef.current?.state.messages?.length || 0;
  const isMailCommunicationAllowed = mailRef.current?.state?.isCommunicationAllowed;
  const isEmailComposeButtonVisible = activeSubTab === 'email' && contextualEmailCount && isMailCommunicationAllowed;
  const isMessageCommunicationAllowed = smsRef.current?.state?.isCommunicationAllowed;
  const isMessageComposeButtonVisible =
    activeSubTab === 'message' && contextualMessageCount && isMessageCommunicationAllowed;
  const isComposeButtonVisible = isEmailComposeButtonVisible || isMessageComposeButtonVisible;
  const setComposeModalVisibility =
    activeSubTab === 'email' ? setEmailComposeModalVisibility : setMessageComposeModalVisibility;
  const contactsWrapperStyle = isAlertMessage ? { height: 'calc(100vh - 200px)' } : {};
  const consentInforBanner = mailRef.current?.getConsentStatusInfoBanner();
  const { setDefaultEmailTemplateValues } = mailRef.current || {};
  const { setCurrentReplyingToEmail } = mailRef.current || {};
  const { setSubjectDisability } = mailRef.current || {};
  const composeEventName =
    activeSubTab === 'email'
      ? eventTypes.candidate.candidateConnect.composeMail
      : eventTypes.candidate.candidateConnect.composeSms;

  return (
    <div className={styles.candidateContactsWrapper}>
      <div
        className={isCandidateViewHeaderVisible ? styles.contacts : styles.extendView}
        style={contactsWrapperStyle}
        ref={ref}
      >
        <div className={styles.candidateContactInfo}>
          <ContactInformationContainer
            candidate={candidate}
            jobGuid={jobGuid}
            creditsRefunded={creditsRefunded}
            currentJobDetails={currentJobDetails}
            mailRef={mailRef}
            smsRef={smsRef}
            callRef={callRef}
            onSubTabClick={onSubTabClick}
            setEmailComposeModalVisibility={setEmailComposeModalVisibility}
            setMessageComposeModalVisibility={setMessageComposeModalVisibility}
            setCurrentSelectedEmail={setCurrentSelectedEmail}
          />
          <CandidateProfileContactsContainer
            candidate={candidate}
            jobId={jobId}
            currentJobDetails={currentJobDetails}
            jobCountryCode={jobCountryCode}
            toggleBotConfigDrawer={toggleBotConfigDrawer}
            candidateContext={candidateContext}
            activeSubTab={activeSubTab}
            onSubTabClick={onSubTabClick}
            version={version}
            mailRef={mailRef}
            smsRef={smsRef}
            callRef={callRef}
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            isComposeEmailModalVisible={isComposeEmailModalVisible}
            setEmailComposeModalVisibility={setEmailComposeModalVisibility}
            isComposeMessageModalVisible={isComposeMessageModalVisible}
            setMessageComposeModalVisibility={setMessageComposeModalVisibility}
            currentSelectedEmail={currentSelectedEmail}
            setCurrentSelectedEmail={setCurrentSelectedEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            isMailCommunicationAllowed={isMailCommunicationAllowed}
            isMessageCommunicationAllowed={isMessageCommunicationAllowed}
            openSipCallWindowsApp={openSipCallWindowsApp}
            isContactTabRedirection={isContactTabRedirection}
            setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
            isConnectIconsEnabled={isConnectIconsEnabled}
            isEmailReadVisible={isEmailReadVisible}
            isAudioReadVisible={isAudioReadVisible}
            isMessageReadVisible={isMessageReadVisible}
            composeEmailType={composeEmailType}
            setComposeEmailType={setComposeEmailType}
            contactsTabRef={ref}
          />
        </div>
      </div>
      {isComposeButtonVisible ? (
        <Affix offsetBottom={0} className={styles.composeButtonWrapper}>
          <div className={styles.composeButton}>
            <ComposeEmailAndMessageButton
              setComposeModalVisibility={setComposeModalVisibility}
              activeTab={activeSubTab}
              composeEmailType={composeEmailType}
              setComposeEmailType={setComposeEmailType}
              isDisabled={activeSubTab === 'email' && !!consentInforBanner}
              setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
              setCurrentReplyingToEmail={setCurrentReplyingToEmail}
              setSubjectDisability={setSubjectDisability}
              shouldChangeMailValues
              skEventName={getEventNameByCandidateStatus(composeEventName, candidate)}
            />
          </div>
        </Affix>
      ) : null}
    </div>
  );
}

export default CandidateContactsWrapper;
