import { defineMessages } from 'react-intl';
import { isPulseUser } from '../../Utils/ConfigUtils';

const messages = defineMessages({
  title: {
    id: isPulseUser() ? 'JobList.Heading.title' : 'TITLE',
    defaultMessage: 'TITLE',
  },
  client: {
    id: isPulseUser() ? 'JobList.Heading.client' : 'CLIENT',
    defaultMessage: 'CLIENT',
  },
  candidates: {
    id: 'JobList.Heading.candidates',
    defaultMessage: 'CANDIDATES',
  },
  status: {
    id: 'JobList.Heading.status',
    defaultMessage: 'STATUS',
  },
  arya: {
    id: 'JobList.Heading.arya',
    values: {
      AppName: 'Arya',
    },
  },
  plan: {
    id: 'JobList.Heading.plan',
    defaultMessage: 'PLAN',
  },
});

export default messages;
