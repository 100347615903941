import _ from 'lodash';
import { FREE_TRIAL } from './PlanSelectionUtils';
import { getCandidateSource } from './SourceUtils';

const getIsCreditInfoPopoverVisible = ({ candidate, currentUser, featureToggleList, operationToBePerformed }) => {
  const {
    PaidJobService: { IsEnabled: isPaidJobServiceEnabled },
  } = featureToggleList;
  if (
    isPaidJobServiceEnabled ||
    candidate?.IsDownloaded ||
    (candidate?.Status && candidate.Status.toLowerCase() !== 'sourced') ||
    operationToBePerformed
  ) {
    return false;
  }
  const candidateSource = getCandidateSource(candidate);
  const sourceGroup = candidateSource?.Group?.toLowerCase();
  if (sourceGroup !== 'active') {
    return false;
  }
  const { sub } = currentUser;
  const creditInfoShowAgainStatuses = JSON.parse(localStorage.getItem('CreditInfoShowAgainStatuses'));
  return !creditInfoShowAgainStatuses?.[sub];
};

export function getTotalPaidCredits(planDetailsByProductVariantId, productVariantsById) {
  let totalPaidCredits = 0;
  const filteredPlanIds = Object.keys(productVariantsById).filter(
    productVariantId =>
      productVariantsById[productVariantId].IsHidden !== true &&
      productVariantsById[productVariantId].Name !== 'Premium Upgrade'
  );
  (filteredPlanIds ?? []).forEach(productVariantId => {
    const planAvailableCredits = _.get(planDetailsByProductVariantId, [productVariantId, 'AvailableCredits'], 0);
    const planValue = productVariantsById[productVariantId].UnitPrice;
    if (planValue !== 0) {
      totalPaidCredits += planAvailableCredits;
    }
  });

  return totalPaidCredits;
}

export function getFreeJobCreditsCount(planDetailsByProductVariantId, productVariantsById) {
  const freeTrialVariant = Object.keys(productVariantsById).filter(
    productVariantId => productVariantsById[productVariantId].Name === FREE_TRIAL
  );
  return _.get(planDetailsByProductVariantId, [freeTrialVariant, 'AvailableCredits'], 0);
}
export function getTotalCredits(planDetailsByProductVariantId, productVariantsById) {
  const availableCredits = {};
  const filteredPlanIds = Object.keys(productVariantsById).filter(
    productVariantId => productVariantsById[productVariantId].Name !== 'Premium Upgrade'
  );
  (filteredPlanIds ?? []).forEach(productVariantId => {
    const planAvailableCredits = _.get(planDetailsByProductVariantId, [productVariantId, 'AvailableCredits'], 0);
    const planName = productVariantsById[productVariantId].Name;
    const plan = { planName, availableCredits: planAvailableCredits };
    availableCredits[planName] = plan;
  });
  return availableCredits;
}

export const getAvailableCreditsById = (planDetailsByProductVariantId, productVariantId) => {
  return planDetailsByProductVariantId[productVariantId]?.AvailableCredits;
};
export { getIsCreditInfoPopoverVisible };
