import React from 'react';
import { Modal, Alert } from 'antd';
import styles from './DeleteCandidateModal.module.scss';

function DeleteCandidateModal(props) {
  const { isVisible, onCancel, onOk } = props;
  const message = `Deleting candidate will remove him/her from the on-going drip campaign. Continue to delete?`;
  return (
    <div>
      <Modal
        visible={isVisible}
        title="Delete Candidate"
        onCancel={onCancel}
        onOk={onOk}
        okButtonProps={{ type: 'primary', size: 'medium', shape: 'round' }}
        cancelButtonProps={{ size: 'medium', shape: 'round' }}
        okText="Yes"
        zIndex={2002}
      >
        <div className={styles.alertMessage}>
          <Alert message={message} type="warning" showIcon />
        </div>
      </Modal>
    </div>
  );
}

export default DeleteCandidateModal;
