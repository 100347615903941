import React from 'react';
import { Tabs, Skeleton, Affix } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import * as JobActions from '../../../Actions/JobActions';
import * as CandidateActions from '../../../Actions/CandidateActions';
import {
  setCandidateAllDetailsApiStatus as _setCandidateAllDetailsApiStatus,
  setCandidateListType as _setCandidateListType,
} from '../../../Actions/ActionCreators/CandidateActions';
import * as ContactFetchActions from '../../../Actions/ContactFetchActions';
import CandidateList from '../../../Components/CandidateList/CandidateList';
import CandidateListPagination from '../../../Components/CandidateListPagination/CandidateListPagination';
import CandidateBulkActionsContainer from '../../CandidateBulkActionsContainer/CandidateBulkActionsContainer';
import isEuropeanCountry from '../../../Utils/GDPRcountry';
import { getJobUtilities } from '../../../Reducers/JobReducer';
import { getCandidatesConnectInfo } from '../../../Reducers/ConnectReducer';
import {
  getCandidates,
  getCandidateCount,
  getLatestCandidateSearchSuccessPage,
} from '../../../Reducers/CandidateReducer';
import CandidateDrawerWrapper from '../../CandidateDrawerWrapper/CandidateDrawerWrapper';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getConfig, getPortalsConfig, getWhiteLabelInformation } from '../../../Reducers/ConfigReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import {
  getSourceName,
  getSourceDisplayName,
  getCandidateSource,
  getAtsSource,
  getCandidateSourceName,
} from '../../../Utils/SourceUtils';
import '../QuickSearch.scss';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getIsCreditInfoPopoverVisible } from '../../../Utils/CreditInfoUtils';
import ActiveChannelCreditInfo from '../../ActiveChannelCreditInfo/ActiveChannelCreditInfo';

const { TabPane } = Tabs;

const mapStateToProps = (state, props) => ({
  config: getConfig(state),
  jobUtilities: getJobUtilities(state),
  candidatesConnectInfo: getCandidatesConnectInfo(state),
  candidateCount: getCandidateCount(state) ?? 0,
  candidatesById: getCandidates(state, props.jobId),
  candidateListStatus: getApiStatus(state, 'candidateListStatus'),
  candidateAllNotesFetchStatus: getApiStatus(state, 'fetchBulkCandidateAllNotesApiStatus'),
  resumeUnlockStatus: getApiStatus(state, 'resumeUnlockStatus'),
  quickSearchcandidateDetails: getApiStatus(state, 'candidateDetailsStatus'),
  quickSearchCandidateStatusUpdate: getApiStatus(state, 'sourceCandidateStatusUpdate'),
  sources: getFeatureToggleList(state).RevealPortalsUnderGroup.IsEnabled
    ? (getConfig(state)?.PortalSources ?? []).concat(getConfig(state)?.ActiveChannelSources ?? [])
    : getConfig(state)?.PortalSources ?? [],
  userConfig: getConfig(state),
  portalsConfig: getPortalsConfig(state),
  featureToggleList: getFeatureToggleList(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  currentUser: getCurrentUser(state),
  getLatestSuccessfulCandidateSearchPage: source =>
    getLatestCandidateSearchSuccessPage(state, {
      jobId: props.jobId,
      source,
    }),
});

const mapDispatchToProps = {
  searchCandidates: CandidateActions.fetchCandidateList,
  fetchCandidateDetails: CandidateActions.fetchCandidateDetails,
  changeCandidateStatus: CandidateActions.changeCandidateStatus,
  resetCandidates: CandidateActions.resetCandidates,
  setCandidateRejectStatus: CandidateActions.setCandidateRejectStatus,
  unlockQuickSearchCandidateResume: CandidateActions.unlockCandidateResume,
  downloadResume: CandidateActions.downloadResume,
  setCurrentJobId: JobActions.setCurrentJobId,
  setCandidateAllDetailsApiStatus: _setCandidateAllDetailsApiStatus,
  onFetchContact: ContactFetchActions.onFetchContact,
  setCandidateListType: _setCandidateListType,
};

const CandidateShortlist = 'CandidateShortlist';
const OpenCandidateDrawer = 'OpenCandidateDrawer';
const FetchContact = 'FetchContact';
const OpenCandidateView = 'OpenCandidateView';

const maxBulkCandidatesSelectionCount = 500;

export class QuickSearchCandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateDrawerVisiblity: false,
      drawerRenderFlag: false,
      defaultActiveKey: 'profile',
      defaultSubTabKey: 'profile',
      currentSelectedAllCandidates: [],
      bulkActivitySelection: {},
    };
  }

  componentDidMount() {
    const { openInNewTab, jobId, setCurrentJobId, setCandidateListType } = this.props;
    if (openInNewTab) {
      this.fetchCandidateViewDetails();
    }
    if (jobId && openInNewTab) setCurrentJobId(jobId);
    setCandidateListType('QuickSearchCandidateList');
  }

  fetchCandidateViewDetails = async () => {
    const { currentJobDetails, jobId, fetchCandidateDetails, setCandidateAllDetailsApiStatus, candidateId } =
      this.props;
    setCandidateAllDetailsApiStatus('INPROGRESS');
    const { JobGuid, CountryCode } = currentJobDetails;
    this.setState({ candidateId, defaultActiveKey: 'profile', defaultSubTabKey: 'profile' });
    const candidateInfo = { Id: candidateId, jobId, candidateId };
    fetchCandidateDetails(candidateInfo, { refId: JobGuid, Country: CountryCode }, true);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { candidatesById, openInNewTab } = nextProps;
    const { candidateId, activeSource } = prevState;
    if (activeSource && _.isEqual(activeSource, nextProps.activeSource)) {
      return prevState;
    }
    const newState = _.cloneDeep(prevState);
    newState.activeSource = nextProps.activeSource;
    const candidateDetails = candidatesById?.[candidateId];
    if (openInNewTab && (candidateDetails?.Source || candidateDetails?.Sources)) {
      const candidateSource = getCandidateSource(candidateDetails);
      const atsPrimarySource = getAtsSource(candidateDetails.Sources);
      newState.activeSource = atsPrimarySource || candidateSource;
    }
    // if (
    //   !_.isEmpty(bulkActivitySearchCriteria) &&
    //   !_.isEqual(_.omit(bulkActivitySearchCriteria, ['From', 'Size']), _.omit(searchFilter, ['From', 'Size']))
    // ) {
    //   newState.bulkActivitySearchCriteria = null;
    //   newState.bulkActivitySourceConfiguration = null;
    //   if (bulkActivityCallbacks?.onBulkSelectionCallback) {
    //     bulkActivityCallbacks.onBulkSelectionCallback(false);
    //   }
    // }
    return newState;
  }

  componentDidUpdate(prevProps) {
    const { candidateListStatus, candidatesById } = this.props;
    if (
      prevProps.candidateListStatus === 'INPROGRESS' &&
      candidateListStatus === 'COMPLETED' &&
      this.indexToFetch !== undefined
    ) {
      const candidateList = Object.values(candidatesById);
      this.showCandidateDrawer(candidateList[this.indexToFetch], 'profile', this.indexToFetch);
      this.indexToFetch = undefined;
    }
    const { nextOperation, creditInfoVisibility, candidate, key, candidateFilter, size, index, subKey } = this.state;
    if (nextOperation && !creditInfoVisibility) {
      if (nextOperation === OpenCandidateDrawer) {
        this.showCandidateDrawer(candidate, key, index, subKey);
      } else if (nextOperation === FetchContact) {
        this.handleContactPull({ candidate });
      } else if (nextOperation === CandidateShortlist) {
        this.onCandidateStatusChange(candidateFilter, size);
      } else if (nextOperation === OpenCandidateView) {
        this.openCandidateView(candidate);
      }
    }
  }

  componentWillUnmount() {
    const { resetCandidates, setCandidateListType } = this.props;
    const { activeSource } = this.state;
    if (activeSource) {
      resetCandidates(activeSource);
    }
    setCandidateListType(null);
  }

  onCreditInfoModalCancel = () => {
    this.setState({
      creditInfoVisibility: false,
      nextOperation: null,
    });
  };

  setCreditInfoVisibility = creditInfoVisibility => {
    this.setState({
      creditInfoVisibility,
    });
  };

  getIsCreditInfoPopoverVisible = ({ candidate }) => {
    const { currentUser, featureToggleList } = this.props;
    const { nextOperation: operationToBePerformed } = this.state;
    return getIsCreditInfoPopoverVisible({
      candidate,
      currentUser,
      featureToggleList,
      operationToBePerformed,
    });
  };

  onCandidateReject = (candidate, size) => {
    const { setCandidateRejectStatus } = this.props;
    setCandidateRejectStatus(candidate.Id, size);
  };

  getCurrentSourceConfig = candidate => {
    const { config } = this.props;
    const candidateSource = getCandidateSource(candidate);
    const candidateSourceName = getCandidateSourceName(candidateSource, true);
    return config?.SourceConfigsByName?.[candidateSourceName.toLowerCase()];
  };

  onCandidateStatusChange = (filter, size) => {
    const { changeCandidateStatus, jobId } = this.props;
    const { nextOperation } = this.state;
    const { candidate, status, rejectReasons } = filter;
    const isCreditInfoPopoverVisible =
      filter.status.toLowerCase() === 'shortlisted' &&
      this.getIsCreditInfoPopoverVisible({
        candidate,
      });
    if (isCreditInfoPopoverVisible) {
      this.setState({ nextOperation: CandidateShortlist, creditInfoVisibility: true, candidateFilter: filter, size });
      return;
    }
    const mustHaves = filter?.mustHaves?.split(',') ?? [];
    const sourceConfiguration = this.getCurrentSourceConfig(candidate);
    const postData = {
      candidateId: candidate.Id,
      metadata: candidate,
      status,
      jobId,
      sourceConfigId: sourceConfiguration?.Id ?? null,
      sourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
      reasons: rejectReasons,
      mustHaves,
    };
    changeCandidateStatus(postData, null, true);
    if (status !== 'Shortlisted') {
      if (status === 'Rejected' || candidate.Status === 'Rejected') {
        this.onCandidateReject(candidate, size);
      }
    }
    if (nextOperation) {
      this.setState({
        nextOperation: null,
      });
    }
  };

  onTabChange = activeKey => {
    const { sourcesSearched, pageSize, updateSearchFilters, fetchCandidates } = this.props;
    const activeSource = sourcesSearched.find(source => getSourceName(source) === activeKey);
    updateSearchFilters({
      currentPage: 1,
      activeSource,
    });
    const { resetCandidates } = this.props;
    resetCandidates();
    fetchCandidates(0, pageSize, activeSource);
  };

  resetMultiSelect = () => {
    const { bulkActivityCallbacks } = this.props;
    this.setState({
      currentSelectedAllCandidates: [],
      bulkActivitySelection: {},
    });
    if (bulkActivityCallbacks?.onClearSelectionCallback) {
      bulkActivityCallbacks.onClearSelectionCallback();
    }
  };

  isCandidateChecked = (candidate, index) => {
    const { currentSelectedAllCandidates, bulkActivitySelection, activeSource } = this.state;
    const { searchFilter } = this.props;
    if (currentSelectedAllCandidates?.find(selectedCandidate => selectedCandidate.CandidateId === candidate.Id)) {
      return true;
    }
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const bulkActivitySearchCriteria = bulkActivitySelection?.[currentSource]?.bulkActivitySearchCriteria;
    if (!bulkActivitySearchCriteria) {
      return false;
    }
    const { excludedCandidates } = bulkActivitySelection[currentSource];
    const isCandidateExcluded = excludedCandidates?.find(x => x.CandidateId === candidate.Id);
    if (isCandidateExcluded) {
      return false;
    }
    const { From: from, Size: size } = bulkActivitySearchCriteria;
    return from <= searchFilter?.From + index && searchFilter?.From + index < from + size;
  };

  onCandidateCheckBoxClick = (event, index) => {
    const isChecked = event.target.checked;
    const candidate = event.target.value;
    const candidateId = candidate.Id;
    const { currentSelectedAllCandidates, activeSource, bulkActivitySelection } = this.state;
    const { bulkActivityCallbacks, pageSize, currentPage } = this.props;
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    const candidateIndex = (currentPage - 1) * pageSize + index;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentSourceBulkSelection = newBulkActivitySelection[currentSource] ?? {};
    const sourceConfiguration = this.getCurrentSourceConfig(candidate);
    const targetCandidate = {
      CandidateId: candidateId,
      RecommendedInfo: {
        SourceConfigId: sourceConfiguration?.Id ?? null,
        SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
      },
      source: currentSource,
      index: candidateIndex,
    };
    let _currentSelectedAllCandidates = [...currentSelectedAllCandidates];
    const isCandidateExistsinBulkSelectionRange =
      !_.isEmpty(currentSourceBulkSelection.bulkActivitySearchCriteria) &&
      candidateIndex < maxBulkCandidatesSelectionCount;
    let bulkExcludedCandidates = currentSourceBulkSelection.excludedCandidates ?? [];
    if (isChecked) {
      if (isCandidateExistsinBulkSelectionRange) {
        bulkExcludedCandidates = bulkExcludedCandidates.filter(x => x.CandidateId !== candidateId);
      } else {
        _currentSelectedAllCandidates.push(targetCandidate);
      }
    } else if (isCandidateExistsinBulkSelectionRange) {
      bulkExcludedCandidates = bulkExcludedCandidates.concat(targetCandidate);
    } else {
      _currentSelectedAllCandidates = _currentSelectedAllCandidates.filter(
        selectedCandidate => selectedCandidate?.CandidateId !== candidateId
      );
    }
    if (
      !_.isEmpty(currentSourceBulkSelection?.bulkActivitySearchCriteria) &&
      bulkExcludedCandidates?.length < currentSourceBulkSelection.bulkActivitySearchCriteria.Size
    ) {
      currentSourceBulkSelection.excludedCandidates = bulkExcludedCandidates;
      newBulkActivitySelection[currentSource] = currentSourceBulkSelection;
    } else if (newBulkActivitySelection[currentSource]) {
      delete newBulkActivitySelection[currentSource];
    }
    this.setState({
      currentSelectedAllCandidates: _currentSelectedAllCandidates,
      bulkActivitySelection: newBulkActivitySelection,
    });
    if (bulkActivityCallbacks?.onCandidateCheckBoxClickCallback && !isCandidateExistsinBulkSelectionRange) {
      bulkActivityCallbacks.onCandidateCheckBoxClickCallback({ candidate: targetCandidate, isChecked });
    }
    if (bulkActivityCallbacks?.onBulkSelectionCallback) {
      bulkActivityCallbacks.onBulkSelectionCallback({ [currentSource]: !!newBulkActivitySelection[currentSource] });
    }
  };

  getEmptyScreen = source => {
    const { version, emptyScreenComponent, candidateListStatus } = this.props;
    if (candidateListStatus === 'INPROGRESS') {
      return null;
    }
    return emptyScreenComponent({ source, version });
  };

  getMergedQuickSearchCandidateDetails = candidates => {
    const { candidatesConnectInfo } = this.props;
    const connectStatuses = candidatesConnectInfo?.ConnectStatuses;
    return candidates.map(candidate => {
      const connectInfo = connectStatuses?.[candidate.PersonId] ?? {};
      return {
        ...candidate,
        connectInfo,
      };
    });
  };

  getCurrentPageCandidates = () => {
    const { candidatesById, currentPage, pageSize } = this.props;
    const { activeSource } = this.state;
    if (Object.keys(candidatesById).length === 0 || !activeSource) {
      return [];
    }
    const currentPageCandidateIds = candidatesById ? Object.keys(candidatesById) : [];
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    return currentPageCandidateIds.map((Id, index) => {
      const candidateIndex = (currentPage - 1) * pageSize + index;
      const sourceConfiguration = this.getCurrentSourceConfig(candidatesById?.[Id]);
      return {
        CandidateId: Id,
        RecommendedInfo: {
          SourceConfigId: sourceConfiguration?.Id ?? null,
          SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
        },
        index: candidateIndex,
        source: currentSource,
      };
    });
  };

  getSelectedCandidates = () => {
    const { currentSelectedAllCandidates } = this.state;
    return currentSelectedAllCandidates.map(selectedCandidate => selectedCandidate.CandidateId);
  };

  getIsBulkSelectionChecked = () => {
    const { bulkActivitySelection, activeSource } = this.state;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    if (!currentBulkSelection) {
      return false;
    }
    const { bulkActivitySearchCriteria, excludedCandidates } = currentBulkSelection;
    return !_.isEmpty(bulkActivitySearchCriteria) && !excludedCandidates?.length;
  };

  getIsSelectThisPageChecked = () => {
    const { bulkActivitySelection, activeSource } = this.state;
    const { currentPage, pageSize, candidatesById = {} } = this.props;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    const { bulkActivitySearchCriteria, excludedCandidates } = currentBulkSelection ?? {};
    if (_.isEmpty(bulkActivitySearchCriteria)) {
      return false;
    }
    const currentPageCandidatesCount = Object.keys(candidatesById).length;
    if (!currentPageCandidatesCount) {
      return false;
    }
    const firstCandidateIndex = (currentPage - 1) * pageSize;
    const lastCandidateIndex = (currentPage - 1) * pageSize + currentPageCandidatesCount - 1;
    if (lastCandidateIndex >= bulkActivitySearchCriteria.Size) {
      return false;
    }
    const isExcludedCandidatesExistsInCurrentPage = excludedCandidates?.some(
      x => x.index >= firstCandidateIndex && x.index <= lastCandidateIndex
    );
    return !isExcludedCandidatesExistsInCurrentPage;
  };

  setCurrentSelectedAllCandidates = (candidates, isChecked) => {
    const { bulkActivityCallbacks, candidatesById } = this.props;
    const { bulkActivitySelection, activeSource, currentSelectedAllCandidates } = this.state;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    const currentSourceBulkActivitySelection = newBulkActivitySelection[currentSource] ?? {};
    const { bulkActivitySearchCriteria } = currentSourceBulkActivitySelection;
    const candidateIds = Object.keys(candidatesById);
    if (!bulkActivitySearchCriteria) {
      this.setState({
        currentSelectedAllCandidates: candidates,
      });
      if (bulkActivityCallbacks?.onCurrentPageSelectionCallback) {
        bulkActivityCallbacks.onCurrentPageSelectionCallback({ candidateIds, isChecked });
      }
      return;
    }
    const currentPageCandidates = this.getCurrentPageCandidates();
    let newcurrentSelectedAllCandidates = _.cloneDeep(currentSelectedAllCandidates) ?? [];
    let excludedCandidates = currentSourceBulkActivitySelection?.excludedCandidates ?? [];
    const { Size: currentSearchCriteriaSize = 0 } = bulkActivitySearchCriteria;
    const inRangeCandidates = currentPageCandidates.filter(x => x.index < currentSearchCriteriaSize);
    const inRangeCandidateIds = inRangeCandidates.map(x => x.CandidateId);
    const outOfRangeCandidates = currentPageCandidates.filter(x => x.index >= currentSearchCriteriaSize);
    const outOfRangeCandidateIds = outOfRangeCandidates.map(x => x.CandidateId);
    if (isChecked && inRangeCandidates?.length > 0) {
      excludedCandidates = excludedCandidates?.filter(x => !inRangeCandidateIds.includes(x.CandidateId));
    } else if (inRangeCandidates?.length > 0) {
      excludedCandidates = excludedCandidates?.concat(inRangeCandidates);
    }
    if (outOfRangeCandidates?.length > 0) {
      newcurrentSelectedAllCandidates = currentSelectedAllCandidates.concat(outOfRangeCandidates);
    }
    if (!_.isEmpty(bulkActivitySearchCriteria) && excludedCandidates?.length < bulkActivitySearchCriteria.Size) {
      currentSourceBulkActivitySelection.excludedCandidates = excludedCandidates;
      newBulkActivitySelection[currentSource] = currentSourceBulkActivitySelection;
    } else if (newBulkActivitySelection[currentSource]) {
      delete newBulkActivitySelection[currentSource];
    }
    this.setState({
      bulkActivitySelection: newBulkActivitySelection,
      currentSelectedAllCandidates: newcurrentSelectedAllCandidates,
    });
    if (bulkActivityCallbacks?.onCurrentPageSelectionCallback && outOfRangeCandidates?.length) {
      bulkActivityCallbacks.onCurrentPageSelectionCallback({ candidateIds: outOfRangeCandidateIds, isChecked });
    }
    if (bulkActivityCallbacks?.onBulkSelectionCallback) {
      bulkActivityCallbacks.onBulkSelectionCallback({ [currentSource]: !!newBulkActivitySelection[currentSource] });
    }
  };

  getCandidateRejectedStatusUpdate = candidate => {
    const { quickSearchCandidateStatusUpdate } = this.props;
    return quickSearchCandidateStatusUpdate?.[candidate.Id]?.Rejected;
  };

  getCandidateShortlistedStatusUpdate = candidate => {
    const { quickSearchCandidateStatusUpdate } = this.props;
    return quickSearchCandidateStatusUpdate?.[candidate.Id]?.Shortlisted;
  };

  getAryaLiteCandidatesHeader = () => {
    const { candidateCount } = this.props;
    return candidateCount && candidateCount > 0 ? (
      <h4 style={{ float: 'right', margin: '10px' }}>Total Candidates : {candidateCount}</h4>
    ) : null;
  };

  getCandidateList = source => {
    const {
      candidateCount,
      version,
      featureToggleList,
      candidatesById,
      candidateAllNotesFetchStatus,
      whiteLabelInfo,
      currentJobDetails,
      candidateListStatus,
      config,
      userConfig,
      jobId,
      currentPage,
      pageSize,
      onShowSizeChangeCallBack,
      onPageChange,
      keywordsToHighlight,
      customCheckBoxClassName,
      showShortlistIconReject,
      bulkOptionsSelection,
      aggregationFilterComponent,
      isBulkOptionsEnabled,
      showCandidateCheckboxSelection,
      getLatestSuccessfulCandidateSearchPage,
      candidateContext,
    } = this.props;

    const { bulkActivitySearchCriteria, activeSource } = this.state;
    const activeSourceName = getSourceName(activeSource);
    const previousSuccessfulCandidateFetchedPage = getLatestSuccessfulCandidateSearchPage(activeSourceName);
    const { isCheckboxDisabled } = bulkOptionsSelection ?? {};
    const candidateAllNotesFetched =
      candidateAllNotesFetchStatus === 'COMPLETED' || candidateAllNotesFetchStatus === 'FAILED';
    const candidatesFullDetails = this.getMergedQuickSearchCandidateDetails(Object.values(candidatesById));
    return (
      <>
        {this.getBulkOptions()}
        {aggregationFilterComponent}
        {userConfig?.SubscriptionType === 'Lite' ? this.getAryaLiteCandidatesHeader() : null}
        <Skeleton active paragraph={{ rows: 8 }} loading={candidateListStatus === 'INPROGRESS'}>
          {candidatesFullDetails.length === 0 && candidateCount === 0 ? (
            this.getEmptyScreen(source)
          ) : (
            <div className="quick-search-candidate-list">
              {!_.isEmpty(candidatesById) ? (
                <CandidateList
                  featureToggleList={featureToggleList}
                  isCandidateChecked={this.isCandidateChecked}
                  candidates={candidatesFullDetails}
                  showCandidateDetail={this.showCandidateDrawer}
                  onCandidateCheckBoxClick={this.onCandidateCheckBoxClick}
                  showCandidateScore={config.IsCandidateScoreVisible}
                  showVaultName={config.ShowVaultName}
                  onCandidateStatusChange={this.onCandidateStatusChange}
                  onCandidateReject={this.onCandidateReject}
                  candidateType="quick-search"
                  onClickAtsPush={this.onClickAtsPush}
                  handleContactPull={this.handleContactPull}
                  showConnectWidget={featureToggleList.AdvanceSearchConnectOptions.IsEnabled}
                  connectStatusLoaded
                  size="quick-search-list"
                  ignoreSimilar={false}
                  isQuickSearchCandidate
                  getCandidateRejectedStatusUpdate={this.getCandidateRejectedStatusUpdate}
                  getCandidateShortlistedStatusUpdate={this.getCandidateShortlistedStatusUpdate}
                  aryaVersion={userConfig?.SubscriptionType}
                  version={version}
                  showCandidateCheckboxSelection={showCandidateCheckboxSelection || isBulkOptionsEnabled}
                  keywordsToHighlight={keywordsToHighlight}
                  openCandidateView={this.openCandidateView}
                  candidateAllNotesFetched={candidateAllNotesFetched}
                  whiteLabelInfo={whiteLabelInfo}
                  revealActiveChannelSourceName={featureToggleList.RevealPortalsUnderGroup.IsEnabled}
                  jobId={jobId}
                  currentJobDetails={currentJobDetails}
                  customCheckBoxClassName={customCheckBoxClassName}
                  isCheckboxDisabled={isCheckboxDisabled || !_.isEmpty(bulkActivitySearchCriteria)}
                  showShortlistIconReject={showShortlistIconReject}
                />
              ) : (
                this.getEmptyScreen(source)
              )}
              <CandidateListPagination
                totalCandidate={candidateCount > 1000 ? 1000 : candidateCount}
                onChange={onPageChange}
                onShowSizeChangeCallBack={onShowSizeChangeCallBack}
                disabled={candidateListStatus === 'INPROGRESS'}
                pageSize={pageSize}
                currentPage={currentPage}
                previousSuccessfulCandidateFetchedPage={previousSuccessfulCandidateFetchedPage}
                paginationId={candidateContext}
              />
            </div>
          )}
        </Skeleton>
      </>
    );
  };

  getTabPane = (source, maxCandCount) => {
    const { activeSource } = this.state;
    const { config, whiteLabelInfo, featureToggleList ,userConfig} = this.props;
    const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
    const activeSourceName = getSourceName(activeSource);
    const sourceName = getSourceName(source);
    return (
      <TabPane
        tab={`${getSourceDisplayName(
          source,
          userConfig,
          config.ShowVaultName,
          { Name: 'quickSearchCandidateTabs' },
          whiteLabelInfo,
          revealActiveChannelSourceName
        )} ${activeSourceName === sourceName ? `(${maxCandCount || 0})` : ''}`}
        key={sourceName}
        destroyInactiveTabPane
      >
        {sourceName === activeSourceName ? this.getCandidateList(source) : null}
      </TabPane>
    );
  };

  openCandidateView = candidate => {
    const { jobId, openAryaCandidateView, keywordsToHighlight } = this.props;
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
    });
    if (isCreditInfoPopoverVisible) {
      this.setState({ nextOperation: OpenCandidateView, candidate, creditInfoVisibility: true });
      return;
    }
    const { nextOperation } = this.state;
    const queryParamQuantum = candidate?.RecommendationId ? 'v=3' : 'src=qs';
    const queryParamAts = candidate?.RecommendationId ? '3' : 'qs';

    const stringifiedHighlights = queryString.stringify(
      { highlights: keywordsToHighlight ?? [] },
      { arrayFormat: 'comma' }
    );
    if (openAryaCandidateView) {
      openAryaCandidateView(
        jobId,
        candidate.Id,
        null,
        stringifiedHighlights?.replace('highlights=', ''),
        queryParamAts
      );
    } else {
      window.open(`/jobs/${jobId}/candidates/${candidate.Id}?${stringifiedHighlights}&${queryParamQuantum}`);
    }
    if (nextOperation) {
      this.setState({ nextOperation: null });
    }
  };

  showCandidateDrawer = (candidate, key, index, subKey) => {
    const { jobId, fetchCandidateDetails, currentJobDetails } = this.props;
    const { nextOperation } = this.state;
    const jobGuid = currentJobDetails.JobGuid;
    const candidateInfo = { ...candidate, jobId, candidateId: candidate.Id };
    const pushCandidateInfo = { refId: jobGuid, Country: currentJobDetails.CountryCode };
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
    });
    if (isCreditInfoPopoverVisible) {
      this.setState({
        candidate,
        key,
        index,
        subKey,
        nextOperation: OpenCandidateDrawer,
        creditInfoVisibility: true,
      });
      return;
    }
    fetchCandidateDetails(candidateInfo, pushCandidateInfo, true);
    this.setState({
      candidateDrawerVisiblity: true,
      defaultActiveKey: key,
      index,
      defaultSubTabKey: subKey || 'profile',
      drawerRenderFlag: true,
      candidateId: candidate.Id,
    });
    if (nextOperation) {
      this.setState({ nextOperation: null });
    }
  };

  toggleDrawerRenderFlag = () => {
    const { drawerRenderFlag } = this.state;
    this.setState({ drawerRenderFlag: !drawerRenderFlag });
  };

  unlockResume = candidate => {
    const { currentJobDetails } = this.props;
    const { unlockQuickSearchCandidateResume, jobId } = this.props;
    const jobGuid = currentJobDetails.JobGuid;
    const pushCandidateInfo = { refId: jobGuid, Country: currentJobDetails.CountryCode };
    const candidateSource = getCandidateSource(candidate);
    unlockQuickSearchCandidateResume(
      {
        jobId,
        source: candidateSource,
        candidateId: candidate.Id,
      },
      pushCandidateInfo
    );
  };

  handleContactPull = ({ candidate }) => {
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
    });
    if (isCreditInfoPopoverVisible) {
      this.setState({ nextOperation: FetchContact, candidate, creditInfoVisibility: true });
      return;
    }
    const { currentJobDetails, onFetchContact } = this.props;
    const { nextOperation } = this.state;
    onFetchContact({ candidate, currentJobDetails });
    if (nextOperation) {
      this.setState({ nextOperation: null });
    }
  };

  onClickAtsPush = (atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal) => {
    const { openAtsView } = this.props;
    if (openAtsView) openAtsView(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal);
  };

  closeCandidateDrawer = () => {
    this.setState({
      candidateDrawerVisiblity: false,
    });
  };

  onTabClick = key => {
    this.setState({ defaultActiveKey: key });
  };

  onClickNext = () => {
    let { index } = this.state;
    const { currentPage, pageSize, onPageChange } = this.props;
    const { candidatesById } = this.props;
    const candidateList = Object.values(candidatesById);
    if (index < pageSize - 1) {
      index += 1;
      this.showCandidateDrawer(candidateList[index], 'profile', index);
    } else {
      this.indexToFetch = 0;
      onPageChange(currentPage + 1, pageSize);
    }
  };

  onClickPrev = () => {
    let { index } = this.state;
    const { candidatesById, onPageChange, currentPage, pageSize } = this.props;
    const candidateList = Object.values(candidatesById);
    if (index > 0) {
      index -= 1;
      this.showCandidateDrawer(candidateList[index], 'profile', index);
    } else {
      this.indexToFetch = pageSize - 1;
      onPageChange(currentPage - 1, pageSize);
    }
  };

  getSelectedCandidatesCount = () => {
    const { currentSelectedAllCandidates, bulkActivitySelection } = this.state;
    let bulkSelectionCandidatesCount = 0;
    const bulkActivities = Object.values(bulkActivitySelection);
    bulkActivities.forEach(element => {
      bulkSelectionCandidatesCount =
        bulkSelectionCandidatesCount +
        (element.bulkActivitySearchCriteria?.Size ?? 0) -
        (element.excludedCandidates?.length ?? 0);
    });
    return (currentSelectedAllCandidates ?? []).length + bulkSelectionCandidatesCount;
  };

  getSelectedActiveCandidatesCount = () => {
    const { currentSelectedAllCandidates, bulkActivitySelection } = this.state;
    const selectedActiveCandidates =
      currentSelectedAllCandidates?.filter(x => x.source?.toLowerCase() === 'active') ?? [];
    const selectedActiveCandidatesCount = selectedActiveCandidates.length;
    const activeBulkSelection = bulkActivitySelection?.Active;
    if (_.isEmpty(activeBulkSelection)) {
      return selectedActiveCandidatesCount;
    }
    const { bulkActivitySearchCriteria, excludedCandidates } = activeBulkSelection;
    const bulkSelectedActiveCandidates = bulkActivitySearchCriteria?.Size - excludedCandidates?.length;
    return selectedActiveCandidatesCount + bulkSelectedActiveCandidates;
  };

  getBulkOptions = () => {
    const { currentSelectedAllCandidates, bulkActivitySelection } = this.state;
    const {
      jobId,
      currentJobDetails,
      isBulkRecommendEnabled,
      isBulkEmailEnabled,
      isBulkMessageEnabled,
      onBulkActivityPerformCallback,
      bulkOptionsOffestTop,
      isBulkSelectionAllowed,
      candidateListStatus,
      isBulkOptionsEnabled,
      showBulkActivityText,
      version,
    } = this.props;
    if (!isBulkOptionsEnabled) {
      return null;
    }
    const currentPageCandidates = this.getCurrentPageCandidates();
    if (!currentPageCandidates?.length) {
      return null;
    }
    const selectedCandidatesCount = this.getSelectedCandidatesCount();
    const selectedActiveCandidatesCount = this.getSelectedActiveCandidatesCount();
    const isCandidateListLoading = candidateListStatus === 'INPROGRESS';
    return (
      <Affix offsetTop={bulkOptionsOffestTop}>
        <CandidateBulkActionsContainer
          jobId={jobId}
          visiblityFlag
          candidateListType="quicksearch"
          isBulkRecommendEnabled={isBulkRecommendEnabled}
          isBulkEmailEnabled={isBulkEmailEnabled}
          isBulkMessageEnabled={isBulkMessageEnabled}
          selectedCandidates={this.getSelectedCandidates()}
          currentPageCandidates={currentPageCandidates}
          _currentSelectedAllCandidates={currentSelectedAllCandidates}
          selectedActiveCandidateCount={selectedActiveCandidatesCount}
          resetMultiSelect={this.resetMultiSelect}
          setCurrentSelectedAllCandidates={this.setCurrentSelectedAllCandidates}
          isConsentRequired={isEuropeanCountry(currentJobDetails?.CountryCode)}
          onSelectBulkCandidates={this.onSelectBulkCandidates}
          bulkActivitySelection={bulkActivitySelection}
          onBulkActivityPerformCallback={onBulkActivityPerformCallback}
          isBulkSelectionAllowed={isBulkSelectionAllowed}
          selectedCandidatesCount={selectedCandidatesCount}
          isBulkSelectionChecked={this.getIsBulkSelectionChecked()}
          isCurrentPageChecked={this.getIsSelectThisPageChecked()}
          isSelectThisPageDisabled={isCandidateListLoading}
          isBulkSelectionDisabled={isCandidateListLoading}
          showBulkActivityText={showBulkActivityText}
          version={version}
          jobDetails={currentJobDetails}
          maxBulkCandidatesSelectionCount={maxBulkCandidatesSelectionCount}
        />
      </Affix>
    );
  };

  getCandidateListTabs = maxCandCount => {
    const { sourcesSearched, emptyTabsScreen } = this.props;
    const { activeSource } = this.state;
    return sourcesSearched?.length ? (
      <Tabs onChange={this.onTabChange} activeKey={getSourceName(activeSource)}>
        {sourcesSearched.map(source => this.getTabPane(source, maxCandCount))}
      </Tabs>
    ) : (
      emptyTabsScreen
    );
  };

  getMaxCandidateCount = () => {
    const { candidateCount } = this.props;
    let maxCandCount = candidateCount;
    if (maxCandCount > 1000) {
      maxCandCount = 1000;
    }
    return maxCandCount;
  };

  getCandidateDrawerWrapper = () => {
    const { candidateDrawerVisiblity, defaultActiveKey, index, drawerRenderFlag, candidateId, defaultSubTabKey } =
      this.state;
    const {
      jobId,
      quickSearchcandidateDetails,
      downloadResume,
      userConfig,
      candidatesById,
      notesContainer,
      callNotesContainer,
      postAtsCallNotes,
      dialingTone,
      ringingTone,
      version,
      currentJobDetails,
      openInNewTab,
      candidateListStatus,
      candidateAllNotesFetchStatus,
      featureToggleList,
      keywordsToHighlight,
      pageSize,
      currentPage,
      candidateContext = 'job',
      openSipCallWindowsApp,
      openAryaCandidateView,
      history,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
    } = this.props;
    const jobGuid = currentJobDetails?.JobGuid;
    const jobCountryCode = currentJobDetails?.CountryCode;
    const maxCandCount = this.getMaxCandidateCount();
    let candidateDetails = { ...(candidatesById?.[candidateId] ?? {}), jobId, candidateId };
    if (candidateDrawerVisiblity) {
      candidateDetails = this.getMergedQuickSearchCandidateDetails([candidateDetails]);
      [candidateDetails] = candidateDetails;
    }
    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    const currentCandidateIndex = (currentPage - 1) * pageSize + index;
    const disableNext =
      index === undefined || this.indexToFetch !== undefined || currentCandidateIndex >= maxCandCount - 1;
    const disablePrev = index === undefined || this.indexToFetch !== undefined || currentCandidateIndex <= 0;
    const isConnectOptionsEnabled = featureToggleList.AdvanceSearchConnectOptions.IsEnabled;
    return (
      <CandidateDrawerWrapper
        allowResumeDownload
        onTabClick={this.onTabClick}
        onClose={this.closeCandidateDrawer}
        visible={candidateDrawerVisiblity}
        toggleDrawerRenderFlag={this.toggleDrawerRenderFlag}
        drawerRenderFlag={drawerRenderFlag}
        isQuickSearchCandidate
        candidateId={candidateDetails?.Id}
        jobId={jobId}
        jobGuid={jobGuid}
        activeKey={defaultActiveKey}
        candidateDetailsStatus={quickSearchcandidateDetails}
        onCandidateReject={this.onCandidateReject}
        onCandidateStatusChange={this.onCandidateStatusChange}
        fetchCandidateAllDetails={this.fetchCandidateAllDetails}
        candidate={candidateDetails}
        downloadResume={downloadResume}
        unlockCandidateResume={this.unlockResume}
        getCandidateRejectedStatusUpdate={this.getCandidateRejectedStatusUpdate}
        getCandidateShortlistedStatusUpdate={this.getCandidateShortlistedStatusUpdate}
        showPushCandidateButton={false}
        onClickAtsPush={this.onClickAtsPush}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postAtsCallNotes}
        dialingTone={dialingTone}
        ringingTone={ringingTone}
        onClickNext={this.onClickNext}
        onClickPrev={this.onClickPrev}
        disableNext={disableNext}
        disablePrev={disablePrev}
        aryaVersion={userConfig?.SubscriptionType}
        showPushError={false}
        version={version}
        keywordsToHighlight={keywordsToHighlight}
        candidateType="quick-search"
        openCandidateView={this.openCandidateView}
        openInNewTab={openInNewTab}
        candidateListStatus={candidateListStatus}
        candidateAllNotesFetched={candidateAllNotesFetched}
        subTabKey={defaultSubTabKey}
        isConnectOptionsEnabled={isConnectOptionsEnabled}
        showContactInfoButton={isConnectOptionsEnabled}
        isJobActionsAllowed={!!jobId}
        revealActiveChannelSourceName={featureToggleList.RevealPortalsUnderGroup.IsEnabled}
        ignoreSimilar={false}
        jobCountryCode={jobCountryCode}
        currentJobDetails={currentJobDetails}
        hideRefineIntelBanner
        candidateContext={candidateContext}
        openSipCallWindowsApp={openSipCallWindowsApp}
        history={history}
        openAryaCandidateView={openAryaCandidateView}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
      />
    );
  };

  onSelectBulkCandidates = event => {
    const isChecked = event.target.checked;
    const { activeSource, bulkActivitySelection, currentSelectedAllCandidates } = this.state;
    const { searchFilter, bulkActivityCallbacks, candidateCount } = this.props;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const bulkActivitySourceConfiguration = this.getCurrentSourceConfig({ Source: activeSource });
    const size = Math.min(maxBulkCandidatesSelectionCount, candidateCount);
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    if (isChecked) {
      const newCurrentSelectedAllCandidates = currentSelectedAllCandidates.filter(
        x => x.source !== currentSource || x.index > size
      );
      newBulkActivitySelection[currentSource] = {
        bulkActivitySearchCriteria: { ...searchFilter, From: 0, Size: size },
        bulkActivitySourceConfiguration,
      };
      this.setState({
        bulkActivitySelection: newBulkActivitySelection,
        currentSelectedAllCandidates: newCurrentSelectedAllCandidates,
      });
    } else {
      delete newBulkActivitySelection[currentSource];
      this.setState({
        bulkActivitySelection: newBulkActivitySelection,
      });
    }
    if (bulkActivityCallbacks?.onBulkSelectionCallback) {
      bulkActivityCallbacks.onBulkSelectionCallback({ [currentSource]: !!newBulkActivitySelection[currentSource] });
    }
  };

  render() {
    const { userConfig, openInNewTab, candidateListClassName } = this.props;
    if (openInNewTab) {
      return <>{this.getCandidateDrawerWrapper()}</>;
    }
    const { creditInfoVisibility } = this.state;
    const maxCandCount = this.getMaxCandidateCount();
    return (
      <>
        <ActiveChannelCreditInfo
          creditInfoVisibility={creditInfoVisibility}
          onCreditInfoModalCancel={this.onCreditInfoModalCancel}
          setCreditInfoVisibility={this.setCreditInfoVisibility}
        />
        {this.getCandidateDrawerWrapper()}
        <div className={candidateListClassName}>
          {userConfig?.SubscriptionType?.toLowerCase() === 'lite' ? (
            <div style={{ position: 'relative' }}>{this.getCandidateList({ Name: 'Social', Type: 'Internal' })}</div>
          ) : (
            this.getCandidateListTabs(maxCandCount)
          )}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchCandidateList);
export { QuickSearchCandidateList as QuickSearchCandidateListWithoutStore };
