import React from 'react';
import { Tag } from 'antd';
import _ from 'lodash';
import { SmartIcon } from '../../Icons/AryaIcons';
import { highlightKeyWords } from '../../Utils/KeywordHighlightUtil';
import styles from './CandidateSkills.module.scss';

function CandidateSkills(props) {
  const { skills = [], keywordsToHighlight = [], candidate = {}, candidateDetailsStatus } = props;

  React.useEffect(() => {
    const allHighlights = _.uniq([...keywordsToHighlight, ...(candidate.Keywords || [])]);
    const domSelectors = ['div#candidate-360-skills-tag-section'];
    highlightKeyWords(domSelectors, allHighlights);
  }, [candidateDetailsStatus, keywordsToHighlight?.length]);

  return (
    <div className={styles.tagSection} id="candidate-360-skills-tag-section">
      {skills.length ? (
        skills.map(skill => {
          const trimmedSkill = skill.trim();
          if (trimmedSkill) return <Tag key={trimmedSkill}>{trimmedSkill}</Tag>;
          return null;
        })
      ) : (
        <div>
          <SmartIcon />
          <span>No skills available</span>
        </div>
      )}
    </div>
  );
}

export default CandidateSkills;
