/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import Mark from 'mark.js';

const stopwords = [
  'i',
  'me',
  'my',
  'myself',
  'we',
  'our',
  'ours',
  'ourselves',
  'you',
  'your',
  'yours',
  'yourself',
  'yourselves',
  'he',
  'him',
  'his',
  'himself',
  'she',
  'her',
  'hers',
  'herself',
  'it',
  'its',
  'itself',
  'they',
  'them',
  'their',
  'theirs',
  'themselves',
  'what',
  'which',
  'who',
  'whom',
  'this',
  'that',
  'these',
  'those',
  'am',
  'is',
  'are',
  'was',
  'were',
  'be',
  'been',
  'being',
  'have',
  'has',
  'had',
  'having',
  'do',
  'does',
  'did',
  'doing',
  'a',
  'an',
  'the',
  'and',
  'but',
  'if',
  'or',
  'because',
  'as',
  'until',
  'while',
  'of',
  'at',
  'by',
  'for',
  'with',
  'about',
  'against',
  'between',
  'into',
  'through',
  'during',
  'before',
  'after',
  'above',
  'below',
  'to',
  'from',
  'up',
  'down',
  'in',
  'out',
  'on',
  'off',
  'over',
  'under',
  'again',
  'further',
  'then',
  'once',
  'here',
  'there',
  'when',
  'where',
  'why',
  'how',
  'all',
  'any',
  'both',
  'each',
  'few',
  'more',
  'most',
  'other',
  'some',
  'such',
  'no',
  'nor',
  'not',
  'only',
  'own',
  'same',
  'so',
  'than',
  'too',
  'very',
  's',
  't',
  'can',
  'will',
  'just',
  'don',
  'should',
  'now',
];

const seperators = [
  ',',
  '.',
  '/',
  '&',
  ')',
  '(',
  ']',
  '[',
  '{',
  '}',
  '·',
  ':',
  ';',
  '-',
  '',
  "'",
  '"',
  '”',
  '“',
  '*',
  '?',
  '#',
  '\u2018',
  '\u2019',
  '\u201A',
  '\u201B',
  '\u2022',
  '\u2023',
  '\u2043',
  '\u0022',
  '\u02BA',
  '\u02DD',
  '\u02EE',
  '\u02F6',
  '\u05F2',
  '\u05F4',
  '\u1CD3',
  '\u201C',
  '\u201D',
  '\u201E',
  '\u201F',
  '\u2033',
  '\u2036',
  '\u3003',
  '\uFF02',
  '\u2043',
  '\u204C',
  '\u204D',
  '\u2219',
  '\u25D8',
  '\u25E6',
  '\u2619',
  '\u2765',
  '\u2767',
  '\u29BE',
  '\u29BF',
  '\u275D',
  '\u275E',
  '\u275C',
  '\u275B',
];

const keywordSeperators = [
  ',',
  '.',
  '/',
  '&',
  ')',
  '(',
  '\\]',
  '\\[',
  '{',
  '}',
  '·',
  ':',
  ';',
  '\\-',
  '',
  "'",
  '"',
  '”',
  '“',
  '*',
  '?',
  '#',
  '\\s',
  '\u2018',
  '\u2019',
  '\u201A',
  '\u201B',
  '\u2022',
  '\u2023',
  '\u2043',
  '\u0022',
  '\u02BA',
  '\u02DD',
  '\u02EE',
  '\u02F6',
  '\u05F2',
  '\u05F4',
  '\u1CD3',
  '\u201C',
  '\u201D',
  '\u201E',
  '\u201F',
  '\u2033',
  '\u2036',
  '\u3003',
  '\uFF02',
  '\u2043',
  '\u204C',
  '\u204D',
  '\u2219',
  '\u25D8',
  '\u25E6',
  '\u2619',
  '\u2765',
  '\u2767',
  '\u29BE',
  '\u29BF',
  '\u275D',
  '\u275E',
  '\u275C',
  '\u275B',
];

const regexSkipLatinCharacters = [
  '\u00C1',
  '\u00E1',
  '\u00C0',
  '\u00E0',
  '\u00C2',
  '\u00E2',
  '\u00C4',
  '\u00E4',
  '\u00C3',
  '\u00E3',
  '\u0100',
  '\u0101',
  '\u00C6',
  '\u00E6',
  '\u00C7',
  '\u00E7',
  '\u00C9',
  '\u00E9',
  '\u00C8',
  '\u00E8',
  '\u00CA',
  '\u00EA',
  '\u00CB',
  '\u00EB',
  '\u0112',
  '\u0113',
  '\u00CD',
  '\u00ED',
  '\u00CC',
  '\u00EC',
  '\u00CE',
  '\u00EE',
  '\u00CF',
  '\u00EF',
  '\u012A',
  '\u012B',
  '\u00D1',
  '\u00F1',
  '\u00D3',
  '\u00F3',
  '\u00D2',
  '\u00F2',
  '\u00D4',
  '\u00F4',
  '\u00D6',
  '\u00F6',
  '\u00D5',
  '\u00F5',
  '\u014C',
  '\u014D',
  '\u00DA',
  '\u00FA',
  '\u00D9',
  '\u00F9',
  '\u00DB',
  '\u00FB',
  '\u00DC',
  '\u00FC',
  '\u016A',
  '\u016B',
  '\u00DD',
  '\u00FD',
  '\u0178',
  '\u00FF',
  '\u00DF',
  '\u0152',
  '\u0153',
  '\u00E6',
  '\u0141',
  '\u0142',
  '\u00D8',
  '\u00F8',
  '\u00D0',
  '\u00F0',
  '\u00DE',
  '\u00FE',
  '\uFB00',
  '\uFB01',
  '\uFB02',
  '\uFB03',
  '\uFB04',
  '\uFB05',
  '\uFB06',
];

const regexEscapeChacaters = [`[`, `]`, `{`, `}`, `(`, `)`, `\\`, `^`, `$`, `.`, `|`, `?`, `*`, `+`];
const htmlTagsRegex = /<[\s\S\n]+?>/g;

function getCleanedHighlights(highlights) {
  return _.without(highlights, ...stopwords)
    .filter(highlight => highlight && highlight.trim() !== '')
    .map(highlight => highlight.trim());
}

function createHighlightMatchingRegex(highlight, skipLatinCharacters) {
  const separatorRegex = `(?:甮*)?`;
  let highlightMatchingRegex = '(?:\\b';
  for (const character of highlight) {
    const _character = regexEscapeChacaters.includes(character) ? `\\${character}` : character;
    highlightMatchingRegex = `${highlightMatchingRegex}${_character}${separatorRegex}`;
  }
  if (skipLatinCharacters) {
    const excludedCharacters = regexSkipLatinCharacters.map(character => `\\${character}`).join('');
    highlightMatchingRegex += `(?![\\w\\d_${excludedCharacters}]))`;
  } else highlightMatchingRegex += '(?![\\w\\d_]))';
  return highlightMatchingRegex;
}

function createAllHighlightMatchingRegex(highlights, skipLatinCharacters) {
  let allHighlightMatchingRegex = '';
  for (const highlight of highlights) {
    const highlightRegex = createHighlightMatchingRegex(highlight, skipLatinCharacters);
    allHighlightMatchingRegex = `${allHighlightMatchingRegex}${highlightRegex}|`;
  }
  allHighlightMatchingRegex = allHighlightMatchingRegex.replace(/\|$/, '');
  return allHighlightMatchingRegex;
}

function getHighlightMatches(keywords, replacedHtml, skipLatinCharacters) {
  const highlightMatches = [];
  const allHighlightsRegexStr = createAllHighlightMatchingRegex(keywords, skipLatinCharacters);
  const allHighlightsRegex = new RegExp(allHighlightsRegexStr, 'gim');
  const y = replacedHtml.matchAll(allHighlightsRegex);
  highlightMatches.push(...y);
  return highlightMatches;
}

function hasMatchingKeywords(text, keywords) {
  if (!keywords || keywords.length === 0) return false;
  const cleanedHighlights = getCleanedHighlights(keywords);
  const highlightMatches = getHighlightMatches(cleanedHighlights, text, true);
  return highlightMatches.length > 0;
}

async function getHighlightedHtml(resumeHtml, keyword, customElement) {
  await new Promise(resolve => setTimeout(resolve, 0));
  const htmlTagsMatches = resumeHtml.matchAll(htmlTagsRegex);
  const matchedTags = [];
  for (const match of htmlTagsMatches) {
    const tagWithIndex = { tag: match[0], index: match.index };
    matchedTags.push(tagWithIndex);
  }
  let replacedHtml = resumeHtml.replace(htmlTagsRegex, '甮');
  replacedHtml = replacedHtml.replace(/甮(\s|\n)*甮/gim, '甮甮');
  replacedHtml = replacedHtml.replace(/\n+/gim, ' ');
  const highlightParts = [];
  const highlightMatches = getHighlightMatches([keyword], replacedHtml);

  for (const matchedHighlight of highlightMatches) {
    const tagWithIndex = {
      tag: matchedHighlight[0],
      index: matchedHighlight.index,
    };
    highlightParts.push(tagWithIndex);
  }

  for (const highlightPart of highlightParts) {
    let markedHighlight = `<${customElement}>${highlightPart.tag}</${customElement}>`;
    markedHighlight = markedHighlight.replace(/(甮+)/gi, `</${customElement}>$1<${customElement}>`);
    highlightPart.markedHighlight = markedHighlight;
  }

  let highlightedHtml = '';
  let index = 0;
  for (const highlightPart of highlightParts) {
    const resumePart = replacedHtml.slice(index, highlightPart.index);
    highlightedHtml += resumePart;
    highlightedHtml += highlightPart.markedHighlight;
    const highlightLength = highlightPart.tag.length;
    index = highlightPart.index + highlightLength;
  }
  highlightedHtml += replacedHtml.slice(index);
  for (const matchedTag of matchedTags) {
    highlightedHtml = highlightedHtml.replace(/甮/, matchedTag.tag);
  }
  return highlightedHtml;
}

async function highlightHtml(domNodes = [], highlights = [], customElement = 'mark') {
  if (highlights.length === 0 || domNodes.length === 0) return;
  domNodes.forEach(async element => {
    const elementHtml = element.innerHTML;
    const cleanedHighlights = getCleanedHighlights(highlights);
    let updatedHtml = elementHtml;
    for (const keyword of cleanedHighlights) {
      // eslint-disable-next-line no-await-in-loop
      updatedHtml = await getHighlightedHtml(updatedHtml, keyword, customElement);
    }
    // eslint-disable-next-line no-param-reassign
    element.innerHTML = updatedHtml;
  });
}

const joinedKeywordSeparators = keywordSeperators.join('');

function highlightKeyWords(selectors = [], highlights = [], customElement = 'mark') {
  if (highlights.length !== 0 && selectors.length !== 0) {
    const cleanedHighlights = getCleanedHighlights(highlights);
    const domNodes = selectors.map(selector => document.querySelector(selector)).filter(domNode => !!domNode);
    if (domNodes.length === 0) {
      return;
    }
    const markJsInstance = new Mark(domNodes);
    const markJsOptions = {
      ignoreJoiners: true,
      separateWordSearch: false,
      accuracy: {
        value: 'exactly',
        limiters: seperators,
      },
      exclude: ['mark', 'pdfmark'],
      ignorePunctuation: ["'"],
      element: customElement,
    };
    markJsInstance.mark(cleanedHighlights, markJsOptions);
  }
}

function getHighlightedKeyWords(skills = [], highlights = []) {
  if (skills.length === 0 || highlights.length === 0) {
    return { highlightedSkills: [], matchedHighlights: [] };
  }
  const sanitizedHighlights = highlights.map(keyword => keyword.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&'));
  const hightlightRegexes1 = {};
  const hightlightRegexes2 = {};
  const hightlightRegexes3 = {};
  const hightlightRegexes4 = {};
  sanitizedHighlights.forEach(highlight => {
    hightlightRegexes1[highlight] = new RegExp(`^${highlight}$`, 'i');
    hightlightRegexes2[highlight] = new RegExp(`^${highlight}[${joinedKeywordSeparators}]`, 'i');
    hightlightRegexes3[highlight] = new RegExp(`[${joinedKeywordSeparators}]${highlight}$`, 'i');
    hightlightRegexes4[highlight] = new RegExp(
      `[${joinedKeywordSeparators}]${highlight}[${joinedKeywordSeparators}]`,
      'i'
    );
  });

  let matchedHighlights = [];

  const highlightedSkills = skills.filter(skill => {
    const skillMatchedHighlights = sanitizedHighlights.filter(highlight => {
      return (
        skill.match(hightlightRegexes1[highlight]) ||
        skill.match(hightlightRegexes2[highlight]) ||
        skill.match(hightlightRegexes3[highlight]) ||
        skill.match(hightlightRegexes4[highlight])
      );
    });
    matchedHighlights.push(...skillMatchedHighlights);
    matchedHighlights = _.uniq(matchedHighlights);
    return skillMatchedHighlights.length > 0;
  });
  return { highlightedSkills, matchedHighlights };
}

export const getUniqueHighlightKeywords = (keywordsToHighlight, activeTab, activeSourceName, candidateKeywords) => {
  const combinedKeywords = [
    ...(keywordsToHighlight || []),
    ...(activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidateKeywords || []),
  ];

  return _.uniq(combinedKeywords);
};

export {
  stopwords,
  highlightKeyWords,
  getCleanedHighlights,
  getHighlightedKeyWords,
  highlightHtml,
  hasMatchingKeywords,
};
