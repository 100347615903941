import React from 'react';
import { Select, Tooltip } from 'antd';
import MultiLocationContext from '../MultiLocationContext';

const LocationSelect = props => {
  const { placeholder, isRemoteJobChecked } = props;
  const {
    locationTooltipContent,
    Location,
    onChangeLocation,
    onSearchLocation,
    isLocationDisabled,
    filteredLocations,
    locationDropDownStatus,
    State,
  } = React.useContext(MultiLocationContext);
  return (
    <Tooltip title={locationTooltipContent}>
      <Select
        value={Location ?? State}
        style={{ width: '100%' }}
        placeholder={placeholder}
        notFoundContent={locationDropDownStatus}
        onChange={onChangeLocation}
        onSearch={onSearchLocation}
        disabled={isLocationDisabled || isRemoteJobChecked}
        showArrow={false}
        showSearch
        allowClear
      >
        {filteredLocations?.map(optionValue => (
          <Select.Option key={optionValue.value ?? optionValue} value={optionValue.value ?? optionValue}>
            {optionValue.label ?? optionValue}
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );
};

export default LocationSelect;
