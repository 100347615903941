import React from 'react';
import uuid from 'uuid';
import { getGroupedLocations } from '../Utils/LocationUtils';

export default function useSetDefaultSourcingLocations(props) {
  const {
    defaultSourcingLocations,
    fetchRegionsByCountryCode,
    value,
    form,
    defaultAutoExpand,
    isAdvanceSearchV2 = false,
  } = props;
  const defaultSourcingLocationsLength = defaultSourcingLocations?.length ?? 0;

  const countryCode = value?.[0]?.CountryCode;
  React.useEffect(() => {
    if (countryCode) fetchRegionsByCountryCode({ countryCode });
  }, [countryCode]);

  React.useEffect(() => {
    if (defaultSourcingLocationsLength) {
      const mergedLocations = getGroupedLocations(defaultSourcingLocations, defaultAutoExpand);
      const updatedmergedLocations = mergedLocations?.map(location => ({
        ...location,
        isActive: true,
      }));
      form.setFieldsValue({
        SourcingLocations: isAdvanceSearchV2
          ? [...updatedmergedLocations, { Id: uuid.v4(), CountryCode: countryCode }]
          : mergedLocations,
      });
    }
  }, [JSON.stringify(defaultSourcingLocations)]);
}
