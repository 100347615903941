import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { Tooltip } from 'antd';
import { generateKeywordsFromBooleanString } from './BooleanStringUtility';
import { getCandidateListPageSize } from './LocalStorageUtils';
import {
  getInternalSourceWithCandidateId,
  getAtsSource,
  getSourceDisplayName,
  getPushedAtsSource,
  getCandidateSource,
  getPortalName,
} from './SourceUtils';
import { isPulseUser } from './ConfigUtils';
import { NotesFilledIcon, NotesAvailableIcon, ClockCircleIcon } from '../Icons/AryaIcons';

export const REJECTED = 'rejected';
export const SERVICE_REP = 'Service Rep';
export const PULSE_USER = 'PulseUser';
export const allowedDeDuplicationSources = ['All', 'Active'];

const isPulse = isPulseUser();
const getQuickSearchKeywords = currentJobDetails => {
  const jobQuickSearchParams = currentJobDetails.quickSearchParams;
  const keywords = jobQuickSearchParams?.Keywords;
  const musts = keywords?.Musts;
  const prefres = keywords?.Prefers;
  const quickSearchTitles = jobQuickSearchParams?.Titles ?? [];
  const quickSearchQueryString = (jobQuickSearchParams?.QueryString ?? '')
    .replace(/[()"']/gi, '')
    .replace(/(\s+OR\s+)|(\s+AND\s+)/gi, ',')
    .split(',')
    .filter(keyword => keyword.trim() !== '');
  const quickSearchPrefers =
    (typeof prefres === 'string' ? prefres.split('OR') : prefres)
      ?.map(keyword => _.trim(keyword, '"() '))
      .filter(keyword => keyword.trim() !== '') ?? [];
  const quickSearchMusts = generateKeywordsFromBooleanString(musts);
  return [...quickSearchTitles, ...quickSearchQueryString, ...quickSearchMusts, ...quickSearchPrefers];
};

const getKeyWordsToHighlight = ({ mustHaves, niceToHaves, currentJobDetails }) => {
  const quickSearchKeywordsToHighlight = getQuickSearchKeywords(currentJobDetails);
  const jobTitle = currentJobDetails.JobTitle;
  const jobSynonyms = currentJobDetails.Synonyms;
  return [
    ..._.flatten(mustHaves),
    ...(niceToHaves ? niceToHaves.split(',') : []),
    jobTitle || '',
    ...(jobSynonyms || []),
    ...quickSearchKeywordsToHighlight,
  ];
};

const getCandidatesFilterWithStatus = (candidatesFilter, status) => {
  const candidateFilterWithStatus = _.cloneDeep(candidatesFilter);
  if (status?.toLowerCase() === 'applied') {
    candidateFilterWithStatus.Applied = true;
    candidateFilterWithStatus.Statuses = [];
  } else candidateFilterWithStatus.Statuses = status ? [status] : [];
  return candidateFilterWithStatus;
};

const getDefaultPageSize = (currentUser, candidateContext = 'job') => {
  const currentUserEmail = currentUser?.email;
  return getCandidateListPageSize({ currentUserEmail, candidateContext });
};

const getCandidateStatusForSegment = candidateStatus => {
  switch (candidateStatus?.toLowerCase()) {
    case 'shortlisted':
      return 'Applied';
    case 'rejected':
      return 'Removed';
    default:
      return 'Sourced';
  }
};

export function getCandidateBucketStatus(candidate) {
  const atsSource = getInternalSourceWithCandidateId(candidate?.Sources);
  const candidateStatus = _.get(atsSource, 'Status');
  if (candidateStatus || candidate?.AssignedStatus || candidate?.AppliedTime) {
    return 'Applied';
  }
  if (candidate?.ConnectionStatus?.toLowerCase() === 'connected') {
    return 'Connected';
  }
  return candidate?.Status;
}

export function getCurrentCandidateBucket(location) {
  const regex = /jobs\/\d+\/candidates$/;
  if (location && regex.test(location.pathname)) {
    const params = queryString.parse(location.search);
    const { status } = params;
    return status;
  }
  return null;
}

export function getCandidatePrimarySourceNameToDisplay(
  candidate,
  version,
  showVaultName,
  whiteLabelInfo,
  revealActiveChannelSourceName,
  userConfig
) {
  let candidateSourceName;
  let atsSource;
  let _atsSource;
  let candidateSource;
  if (version === 'ats') {
    _atsSource = getInternalSourceWithCandidateId(candidate.Sources);
    atsSource = getAtsSource(candidate.Sources);
    const _candidateSourceName = getSourceDisplayName(
      atsSource || getCandidateSource(candidate),
      userConfig,
      showVaultName,
      {},
      whiteLabelInfo,
      revealActiveChannelSourceName
    );
    const candidatePortalName = getPortalName(candidate.Sources || []);
    candidateSourceName =
      candidatePortalName && candidatePortalName.toLowerCase() === 'internal' && _atsSource
        ? getSourceDisplayName(_atsSource, userConfig, showVaultName, {}, whiteLabelInfo, revealActiveChannelSourceName)
        : _candidateSourceName;
    candidateSource = _atsSource;
  } else {
    atsSource = getAtsSource(candidate.Sources);
    candidateSource = getPushedAtsSource(candidate.Sources);
    candidateSourceName = getSourceDisplayName(
      atsSource || getCandidateSource(candidate),
      userConfig,
      showVaultName,
      {},
      whiteLabelInfo,
      revealActiveChannelSourceName
    );
  }
  return { candidateSourceName, candidateSource };
}

const isCandidateStateChangeAllowed = candidate => {
  return ((candidate.Status !== 'Rejected' || !candidate.IsPublished) && isPulse) || !isPulse;
};

const isPublishIconDisplayAllowed = (isBulkRecommendEnabled, activeTab, candidateContext) => {
  return isBulkRecommendEnabled && candidateContext === 'job' && ((isPulse && activeTab !== 'rejected') || !isPulse);
};

const getCandidateCardCursorStyle = (candidateStatus, publishedStatus) => {
  return publishedStatus === 'Rejected' && candidateStatus === 'Rejected' && isPulseUser()
    ? { cursor: 'default' }
    : { cursor: 'pointer' };
};

const getExperienceTimeline = experience => {
  return `${moment(experience.From).format('MMM YYYY ')} - ${
    moment().isSame(experience.To, 'day') ? ' Present' : moment(experience.To).format(' MMM YYYY')
  }`;
};

const getExperienceDuration = experience => {
  const years = moment(experience.To).diff(moment(experience.From), 'years');
  const refinedYears = Math.abs(years);
  return years === 0 ? '< 1 year' : `${refinedYears}+ yrs`;
};

const getCandidate360NotesIcon = ({ isCandidateNotesAvailable }) => {
  return isCandidateNotesAvailable ? <NotesAvailableIcon /> : <NotesFilledIcon />;
};

export const getLastActive = candidate => {
  const { ProfileDate, LastActivityDate } = candidate;
  let lastActive = ProfileDate ?? LastActivityDate;
  const isProfileDateValid = moment.utc().local().diff(moment.utc(ProfileDate).local(), 'seconds') > 0;

  const isLastActivityDateValid = moment.utc().local().diff(moment.utc(LastActivityDate).local(), 'seconds') > 0;

  if (LastActivityDate && ProfileDate && isProfileDateValid && isLastActivityDateValid) {
    lastActive =
      moment.utc(LastActivityDate).local().diff(moment.utc(ProfileDate).local(), 'seconds') > 0
        ? LastActivityDate
        : ProfileDate;
  }

  if (LastActivityDate && ProfileDate && isProfileDateValid && !isLastActivityDateValid) {
    lastActive = ProfileDate;
  }

  if (LastActivityDate && ProfileDate && !isProfileDateValid && isLastActivityDateValid) {
    lastActive = LastActivityDate;
  }
  return lastActive;
};

export const getLatestProfileDateMarkup = (
  candidate,
  showClockIcon = true,
  isLatestCandidateCardVisible = false,
  style = {}
) => {
  const lastActive = getLastActive(candidate);
  if (!lastActive) {
    return null;
  }
  const localCurrentDate = moment();
  const localLastActive = moment.utc(lastActive).local();
  const diffInMonths = localCurrentDate.diff(localLastActive, 'months');
  if (diffInMonths >= 60) {
    return null;
  }
  let lastActiveText = <span style={style}>{` Updated: ${localLastActive.fromNow()}`}</span>;

  lastActiveText = ['social', 'arya passive'].includes(isLatestCandidateCardVisible) ? (
    <span style={style}>{` Refreshed by Arya: ${localLastActive.fromNow()}`}</span>
  ) : (
    lastActiveText
  );

  const inlineStyle = { display: 'flex', alignItems: 'center', gap: '4px' };

  return (
    <Tooltip
      overlayClassName="last-activity-tooltip"
      title={`${localLastActive.format(
        'll'
      )}: Activity is measured by resume upload or profile refresh or job board engagement. (Whichever is the most recent)`}
      mouseEnterDelay={0.5}
    >
      <span className="resume-date-info" style={inlineStyle}>
        {showClockIcon ? <ClockCircleIcon className="resume-date-icon" /> : null}
        {lastActiveText}
      </span>
    </Tooltip>
  );
};

export const getIsSelectThisPageChecked = (
  currentSelectedAllCandidates,
  aggregatedCandidates,
  isCurrentPageChecked,
  activeSourceName,
  duplicateCandidateIds
) => {
  const updatedAggregatedCandidates = allowedDeDuplicationSources.includes(activeSourceName)
    ? aggregatedCandidates.filter(candidate => {
        return !duplicateCandidateIds.some(filterItem => filterItem === candidate.CandidateId);
      })
    : aggregatedCandidates;

  return (
    (currentSelectedAllCandidates?.length && _.isEqual(currentSelectedAllCandidates, updatedAggregatedCandidates)) ||
    isCurrentPageChecked
  );
};

export {
  getKeyWordsToHighlight,
  getCandidatesFilterWithStatus,
  getDefaultPageSize,
  isCandidateStateChangeAllowed,
  isPublishIconDisplayAllowed,
  getCandidateStatusForSegment,
  getExperienceTimeline,
  getExperienceDuration,
  getCandidateCardCursorStyle,
  getCandidate360NotesIcon,
};
