import React from 'react';
import _ from 'lodash';
import { Form, Select, Input, Switch, InputNumber, Checkbox, Radio } from 'antd';
import * as MonsterPowerUtils from '../../../../../Utils/AdvancedOptionsUtils/MonsterPowerUtils';
import * as MonsterClassicUtils from '../../../../../Utils/AdvancedOptionsUtils/MonsterClassicUtils';
import isIncludeResumeSwitchDisabled from '../../../../../Utils/AdvancedOptionsUtils/MonsterAdvancedOptionsUtils';
import { maxSalaryValidator } from '../MonsterFormValidators';
import './PowerAdvancedOptions.scss';
import styles from './PowerAdvancedOptions.module.scss';
import LanguageFilter from '../LanguageFilter/LanguageFilter';

const { Item } = Form;
const { Option } = Select;

class PowerAdvancedOptions extends React.Component {
  constructor(props) {
    super(props);
    this.isFieldCleared = this.isFieldCleared.bind(this);
    this.clearMinMaxSalary = this.clearMinMaxSalary.bind(this);
  }

  isFieldCleared(value, fieldName) {
    if (!value) {
      if (fieldName === 'monster-salary-currency') {
        this.clearMinMaxSalary();
      }
    }
  }

  clearMinMaxSalary() {
    const { form } = this.props;
    form.setFieldsValue({
      // 'monster-min-salary': undefined,
      'monster-max-salary': undefined,
    });
  }

  render() {
    const { form, defaultSettings } = this.props;
    const salaryTypeSelect = form.getFieldDecorator('monster-salary-type', {
      initialValue: defaultSettings.SalaryType || 'Yearly',
    })(
      <Select
        key="monster-power-salary-type"
        placeholder="Select type"
        dropdownClassName="popover-select"
        className="salary-type-select"
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Option value="Yearly">Yearly</Option>
        <Option value="Hourly">Hourly</Option>
      </Select>
    );

    return (
      <div className="monster-power">
        <div className="monster-advanced-options">
          <Item label="Candidate Name" colon={false}>
            {form.getFieldDecorator('monster-candidate-name', { initialValue: defaultSettings.CandidateName })(
              <Input
                key="monster-candidate-name"
                placeholder="Enter candidate name"
                className="monster-advance-options-input"
              />
            )}
          </Item>
          {/*
           // ! Commenting out the code for future use
           <Item label="Career Level" colon={false}>
            {form.getFieldDecorator('monster-career-level', {
              initialValue: defaultSettings.CareerLevels,
            })(
              <Select
                key="monster-career-level"
                placeholder="Select levels"
                dropdownClassName="popover-select"
                getPopupContainer={trigger => trigger.parentNode}
                className="monster-advance-options-select"
                allowClear
              >
                {MonsterPowerUtils.CareerLevels.map(careerLevel => (
                  <Option value={careerLevel}>{careerLevel}</Option>
                ))}
              </Select>
            )}
          </Item> */}
          <div className="salary-form-item-label">Salary Range {salaryTypeSelect}</div>
          <div className="salary-form-item">
            <Item>
              {form.getFieldDecorator('monster-salary-currency', {
                initialValue: defaultSettings.SalaryCurrency,
              })(
                <Select
                  key="monster-salary-currency"
                  placeholder="Select types"
                  dropdownClassName="popover-select monster-currency"
                  className="monster-salary-currency"
                  getPopupContainer={trigger => trigger.parentNode}
                  allowClear
                  onChange={value => this.isFieldCleared(value, 'monster-salary-currency')}
                >
                  {MonsterPowerUtils.CurrencyCodes.map(currency => (
                    <Option value={currency} key={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
            {/*
             // ! Commenting out the code for future use
             <Item>
              {form.getFieldDecorator('monster-min-salary', {
                initialValue: defaultSettings.MinSalary,
                rules: [{ validator: (...args) => minSalaryValidator(form, args) }],
              })(
                <InputNumber key="monster-min-salary" placeholder="Min Salary" className="monster-min-salary" min={0} />
              )}
            </Item> */}
            <Item>
              {form.getFieldDecorator('monster-max-salary', {
                initialValue: defaultSettings.MaxSalary && parseInt(defaultSettings.MaxSalary, 10),
                rules: [{ validator: (...args) => maxSalaryValidator(form, args) }],
              })(
                <InputNumber
                  key="monster-max-salary"
                  placeholder="Max Salary"
                  className="monster-max-salary"
                  min={0}
                  max={
                    form.getFieldValue('monster-salary-type')?.toLowerCase() === 'hourly'
                      ? MonsterPowerUtils.SalaryMaxLimit.Hourly
                      : MonsterPowerUtils.SalaryMaxLimit.Yearly
                  }
                />
              )}
            </Item>
          </div>

          <Item label="Include resumes with no salary info" colon={false} className="switch-form-item">
            {form.getFieldDecorator('monster-include-resumes-with-no-salary-info', {
              initialValue:
                defaultSettings.IncludeResumesWithNoSalaryInfo !== undefined
                  ? defaultSettings.IncludeResumesWithNoSalaryInfo === '1'
                  : true,
              valuePropName: 'checked',
            })(
              <Switch
                key="monster-include-resumes"
                defaultChecked
                disabled={isIncludeResumeSwitchDisabled(
                  form,
                  defaultSettings.MinSalary ?? defaultSettings.MinNormalisedSalary,
                  defaultSettings.MaxSalary ?? defaultSettings.MinNormalisedSalary
                )}
              />
            )}
          </Item>

          <LanguageFilter
            form={form}
            languageSkills={defaultSettings.LanguageSkills}
            supportedLanguages={MonsterClassicUtils.Languages}
            jobBoardFormDecorator="monster"
            languageProficiencyLevels={MonsterPowerUtils.LanguageProficiencyLevels}
            defaultProficiencyLevel="Unknown"
          />

          <Item label="Job Types" colon={false}>
            {form.getFieldDecorator('monster-job-type', {
              initialValue: defaultSettings.DesiredJobType ? defaultSettings.DesiredJobType.split(',') : undefined,
            })(
              <Select
                key="monster-job-type"
                placeholder="Select types"
                dropdownClassName="popover-select"
                className="monster-advance-options-select"
                getPopupContainer={trigger => trigger.parentNode}
                mode="multiple"
                showArrow
              >
                {MonsterPowerUtils.TargetJobTypes.map(type => (
                  <Option key={type.value} value={type.value} title={type.displayText}>
                    {type.displayText}
                  </Option>
                ))}
              </Select>
            )}
          </Item>

          <Item label="Job Duration" colon={false}>
            {form.getFieldDecorator('monster-job-duration', {
              initialValue: defaultSettings.JobDuration && parseInt(defaultSettings.JobDuration, 10),
            })(
              <Select
                key="monster-job-duration"
                placeholder="Select duration"
                dropdownClassName="popover-select"
                className="monster-advance-options-select"
                getPopupContainer={trigger => trigger.parentNode}
                showArrow
                allowClear
              >
                {MonsterPowerUtils.JobDuration.map(duration => (
                  <Option value={duration} key={duration}>
                    {duration} {duration === 1 ? 'year' : 'years'}
                  </Option>
                ))}
              </Select>
            )}
          </Item>

          <Item label="Willing to Relocate" colon={false} className="switch-form-item">
            {form.getFieldDecorator('monster-relocation', {
              initialValue: Boolean(defaultSettings.WillingToRelocate),
              valuePropName: 'checked',
            })(<Switch key="monster-relocation" />)}
          </Item>

          <Item label="Willing to Travel" colon={false}>
            {form.getFieldDecorator('monster-willing-to-travel', { initialValue: defaultSettings.WillingnessToTravel })(
              <Select
                key="monster-willing-to-travel"
                placeholder="Select up to %"
                dropdownClassName="popover-select"
                className="monster-advance-options-select"
                getPopupContainer={trigger => trigger.parentNode}
                allowClear
              >
                {MonsterPowerUtils.WillingnessesToTravel.map(willingnessPerc => (
                  <Option value={willingnessPerc} key={willingnessPerc}>
                    Up to {willingnessPerc}%
                  </Option>
                ))}
              </Select>
            )}
          </Item>

          <Item label="Security Clearance" colon={false}>
            {form.getFieldDecorator('monster-security-clearance', {
              initialValue: defaultSettings.SecurityClearance
                ? defaultSettings.SecurityClearance.split(',')
                : undefined,
            })(
              <Select
                key="monster-security-clearance"
                placeholder="Select security clearance status"
                dropdownClassName="popover-select"
                className="monster-advance-options-select"
                getPopupContainer={trigger => trigger.parentNode}
                mode="multiple"
                showArrow
                allowClear
              >
                {MonsterPowerUtils.SecurityClearance.map(securityClearance => (
                  <Option value={securityClearance.value} key={securityClearance.value}>
                    {securityClearance.displayText}
                  </Option>
                ))}
              </Select>
            )}
          </Item>

          <Item label="Veteran Status" colon={false}>
            {form.getFieldDecorator('monster-veteran-status', {
              initialValue: _.get(defaultSettings, 'VateranStatus', undefined),
            })(
              <Radio.Group key="monster-veteran-status">
                <Radio value="1">Yes</Radio>
                <Radio value="0">No</Radio>
                <Radio>Does not matter</Radio>
              </Radio.Group>
            )}
          </Item>

          <Item label="Work Authorization" colon={false}>
            {form.getFieldDecorator('monster-work-authorization', {
              initialValue: _.get(defaultSettings, 'WorkAuthorization', 'ShowAll'),
            })(
              <Radio.Group key="monster-work-authorization">
                {MonsterPowerUtils.WorkAuthorizationTypes.map(workAuthorization => (
                  <Radio className={styles.radioButton} value={workAuthorization.value} key={workAuthorization.value}>
                    <span className={styles.radioButtonText}>{workAuthorization.displayText}</span>
                  </Radio>
                ))}
              </Radio.Group>
            )}
          </Item>

          <Item label="Experience Type" colon={false}>
            {form.getFieldDecorator('monster-experience-type', {
              initialValue: _.get(defaultSettings, 'ExperienceType', 'NiceToHave'),
            })(
              <Radio.Group key="monster-experience-type">
                <Radio value="MustHave">Must Have</Radio>
                <Radio value="NiceToHave">Nice To Have</Radio>
              </Radio.Group>
            )}
          </Item>

          <Item label="Citizenship" colon={false}>
            {form.getFieldDecorator('monster-power-citizenship', {
              initialValue: defaultSettings.Citizenship ? defaultSettings.Citizenship.split(',') : [],
            })(
              <Checkbox.Group key="monster-power-citizenship">
                <Checkbox value="USCitizen">US Citizen</Checkbox>
                <Checkbox value="PermanentResident">Permanent Resident</Checkbox>
              </Checkbox.Group>
            )}
          </Item>
        </div>
      </div>
    );
  }
}

export default PowerAdvancedOptions;
